import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MuiDialog from '@mui/material/Dialog';
import { blue } from '@mui/material/colors';
import { makeStyles, withStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import Button from '../elements/Button';

import actions from '../redux/actions';
import { RootState } from '../redux/store';
import { UserRole } from '../types/UserRole';

import { Modal, Typography } from '@mui/material';
import config from '../config';
import close from '../public/images/icons/close.svg';
import logoWhite from '../public/images/icons/wetest-logo-white.svg';
import logo from '../public/images/icons/wetest-logo.svg';

const useStyles = makeStyles(() => ({
  header: {
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 3,
    width: '100%',
    minHeight: 97,
    margin: '0 auto',
    textAlign: 'center',
    backgroundColor: '#ffffff',
  },
  box: {
    minHeight: 97,
    maxWidth: 1440,
  },
  hamburgerIcon: {
    width: 32,
    height: 24,
    cursor: 'pointer',
  },
  headerDark: {
    backgroundColor: blue[800],
  },
  verticalMenu: {
    color: '#ffffff',
    textAlign: 'center',
    fontSize: '32px !important',
  },
  bottomVerticalMenu: {
    fontSize: '26px !important',
  },
}));

const Dialog = withStyles({
  paper: {
    paddingTop: 36,
    color: '#ffffff',
    paddingBottom: 46,
    backgroundColor: blue[800],
  },
})(MuiDialog);


const Header: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();

  const popUpStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 3,
    display: 'flex',
    flexDirection: 'column'
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const history = useHistory();

  const [showDiaog, setShowDialog] = useState<boolean>(false);

  const { user, resolution } = useSelector((state: RootState) => state);

  const { role } = user;

  const isMobile = resolution.device === 'mobile';
  const isTablet = resolution.device === 'mobile-tablet';

  const isWizardDisabled = isMobile || isTablet;

  const isRegistrationPage = ['/login', '/login/', '/signup', '/reset-password-req', '/reset-fb-password-req', '/reset-password', '/unsubscribe'].includes(location.pathname);

  const isAccountPage =
    location.pathname.includes('/account/customer')
    || location.pathname.includes('/account/tester')

  const hideDialog = () =>
    setShowDialog(false);

  const logout = (e) => {
    e.preventDefault();
    hideDialog();
    dispatch(actions.logout());
    history.push('/');
  };

  const marketSiteContactPage = `${config.marketingSiteBaseURL}/contact`;
  const marketSiteHomePage = `${config.marketingSiteBaseURL}`;

  return (
    <header
      className={classNames({
        [classes.header]: true,
        [classes.headerDark]: isRegistrationPage,
      })}
    >
      <Container maxWidth={isAccountPage ? 'xl' : 'lg'}>
        <Box
          display="flex"
          alignItems="center"
          className={classes.box}
          justifyContent="space-between"
        >
          <a href={marketSiteHomePage}>
            <img
              alt="Testpoint Logo"
              title="Testpoint Logo"
              src={isRegistrationPage ? logoWhite : logo}
            />
          </a>

          {!isRegistrationPage && role !== UserRole.Anonymous && (
            <Menu text as="nav">
              {!isMobile && (
                <>
                  <Menu.Item>
                    <a href={marketSiteContactPage} className="link-info">Contact</a>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/account" className="link-info">{(user.firstName || '') + ' ' + (user.lastName || '')}</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/logout" onClick={logout} title="Logout" className="link-info">
                      Logout
                    </Link>
                  </Menu.Item>
                </>
              )}
              {isMobile && (
                <Menu.Item onClick={() => setShowDialog(true)}>
                  <MenuIcon className={classes.hamburgerIcon} />
                </Menu.Item>
              )}
            </Menu>
          )}

          {!isRegistrationPage && role === UserRole.Anonymous && (
            <Menu text as="nav">
              {!isMobile && (
                <>
                  <Menu.Item>
                    <Link to="/contact" className="link-info">Contact</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/login" className="link-info">Login</Link>
                  </Menu.Item>
                  <Menu.Item>

                    {isWizardDisabled && (
                      <div >
                        <Button onClick={handleOpen}>Get Started</Button>
                        <Modal
                          open={open}
                          onClose={handleClose}
                        >
                          <Box sx={popUpStyle}>
                            <Typography variant="h6" component="h2">
                              Almost there...
                            </Typography>
                            <Typography variant="h6" component="h3">
                              Switch to your pc to submit your test
                            </Typography>
                          </Box>
                        </Modal>
                      </div>
                    )}

                    {!isWizardDisabled && (
                      <Link to="/wizard">
                        <Button>Get Started</Button>
                      </Link>
                    )}

                  </Menu.Item>
                </>
              )}
              {isMobile && (
                <Menu.Item onClick={() => setShowDialog(true)}>
                  <MenuIcon className={classes.hamburgerIcon} />
                </Menu.Item>
              )}
            </Menu>
          )}

          {isRegistrationPage && (
            <Menu text as="nav">
              <Menu.Item>
                <a
                  href={marketSiteHomePage}
                  onClick={(e) => {
                    e.preventDefault();
                    history.goBack();
                  }}
                >
                  {!isMobile && (
                    <Button inverted>Back to website</Button>
                  )}
                  {isMobile && (
                    <img alt="Close" title="Close" src={close} />
                  )}
                </a>
              </Menu.Item>
            </Menu>
          )}
        </Box>
      </Container>

      {isMobile && (
        <Dialog
          fullScreen
          open={showDiaog}
          onClose={hideDialog}
        >
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            style={{ height: '100%' }}
            justifyContent="space-between"
          >
            <Container maxWidth="lg">
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Link to="/" onClick={hideDialog}>
                  <img alt="Testpoint Logo" title="Testpoint Logo" src={logoWhite} />
                </Link>
                <Link
                  to="/hide-dialog"
                  onClick={(e) => {
                    e.preventDefault();
                    hideDialog();
                    localStorage.removeItem('redirectAfterLogin');
                  }}
                >
                  <img alt="Close" title="Close" src={close} />
                </Link>
              </Box>
            </Container>

            {role === UserRole.Anonymous && (
              <Menu text vertical fluid as="nav" className={classes.verticalMenu}>
                <Menu.Item>
                  <Link to="/" onClick={hideDialog}>Home</Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to="/contact" onClick={hideDialog}>Contact</Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to="/login" onClick={hideDialog}>Login</Link>
                </Menu.Item>
              </Menu>
            )}

            {role !== UserRole.Anonymous && (
              <Menu text vertical fluid as="nav" className={classes.verticalMenu}>
                <Menu.Item>
                  <Link to="/account" onClick={hideDialog}>My Account</Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to="/logout" onClick={logout}>Logout</Link>
                </Menu.Item>
              </Menu>
            )}

            <Menu
              text vertical fluid as="nav"
              className={classNames({
                [classes.verticalMenu]: true,
                [classes.bottomVerticalMenu]: true,
              })}
            >
            </Menu>
          </Box>
        </Dialog>
      )}
    </header>
  );
};

export default Header;