import jwt from 'jsonwebtoken';
import { toast } from 'react-toastify';
import config from '../../config';
import { platformArr } from '../../types/NewPlatformModel';
import { NewWizardCycle } from '../../types/NewWizardCycle';
import { Result } from '../../util/Result';

function updateCycle(newCycleData) {

  //Store cycle data per any update call!
  const newCycleJson = JSON.stringify(newCycleData);
  localStorage.setItem('newCycle', newCycleJson);

  return {
    type: 'UPDATE_WIZARD_CYCLE',
    newCycleData,
  };
}

//Refers to Inner step increment only, the check for Inner vs Major already done
//Need to only validate current InnerStep
function switchNewCycleStepForward() {
  return (dispatch, getState) => {
    const { newWizardCycle, newCycleCurrentMainStep, newCycleCurrentInnerStep } = getState();

    //Check Name & Description are not empty
    if (1 === newCycleCurrentMainStep && 0 === newCycleCurrentInnerStep) {
      const step5errors = validateStep5(newWizardCycle);

      if (step5errors.size) {
        return dispatch({
          type: 'SWITCH_NEW_CYCLE_STEP_FAIL',
          errors: Array.from(step5errors),
        });
      }
    }

    //Store also the step we left with      
    localStorage.setItem('newCycle_innerStep', newCycleCurrentInnerStep + 1);
    return dispatch({
      type: 'SWITCH_NEW_WIZARD_INNER_STEP',
      newCycleCurrentInnerStep: newCycleCurrentInnerStep + 1,
    });
  };
}

function validateStep5(newCycle: NewWizardCycle): Set<string> {
  const errors: Set<string> = new Set();

  ['title', 'description', 'startingPointURL'].forEach((el) => {
    if (!newCycle[el]) {
      errors.add(el);
    }
  });

  return errors;
}

//Refers to Inner step decrement only, the check for Inner vs Major already done
//No validation required
function switchNewCycleStepBackward() {
  return (dispatch, getState) => {
    const { newCycleCurrentInnerStep } = getState();

    //Store also the step we left with      
    localStorage.setItem('newCycle_innerStep', newCycleCurrentInnerStep + 1);
    return dispatch({
      type: 'SWITCH_NEW_WIZARD_INNER_STEP',
      newCycleCurrentInnerStep: newCycleCurrentInnerStep - 1,
    });
  };
}


//Refers to a "jump" backward, from current to any previous Inner step
//No validation required
function switchNewCycleInnerStep(prevInnerStep) {
  return (dispatch, getState) => {

    //Store also the step we left with      
    localStorage.setItem('newCycle_innerStep', prevInnerStep);
    return dispatch({
      type: 'SWITCH_NEW_WIZARD_INNER_STEP',
      newCycleCurrentInnerStep: prevInnerStep,
    });
  };
}


//Relevant for "Step" forward & for a random Jump on the Main Stepper
//Here we need to check conditions to determine the direction and whether or not impose validation (Currently no validations)
function switchNewCycleStep(nextStep) {
  return (dispatch, getState) => {
    const { newWizardCycle, newCycleCurrentMainStep, newCycleCurrentInnerStep } = getState();


    //Upon direction "forward"
    if (nextStep > newCycleCurrentMainStep) {
      //Epty testPlan check
      if (1 === newCycleCurrentMainStep && 2 === newCycleCurrentInnerStep) {
        const isTestPlanEmpty = checkForEmptyTestPlan(newWizardCycle);

        if (isTestPlanEmpty) {
          return dispatch({
            type: 'SWITCH_NEW_CYCLE_STEP_FAIL',
            errors: ["emptyTestPlan"],
          });
        }
      }

      //"Place order"
      if (2 === newCycleCurrentMainStep && 2 === newCycleCurrentInnerStep) {
        //Do we wantit here...?


      }
    }


    //Store also the step we left with (Unless it's the -1 initialization, so that the cache remains) 
    //And all we have to do is to delete it upon submit

    if (-1 < parseInt(nextStep)) {
      localStorage.setItem('newCycle_mainStep', nextStep);
    }
    return dispatch({
      type: 'SWITCH_NEW_WIZARD_MAJOR_STEP',
      newCycleCurrentMainStep: nextStep,
    });
  };
}

function checkForEmptyTestPlan(newCycle: NewWizardCycle) {
  if (0 === newCycle.testPlan?.length) {
    return true;
  }

  return false;
}


function submitCycle(cycleData) {
  return async (dispatch, getState) => {
    const { newCycleCurrentMainStep, newCycleCurrentInnerStep } = getState();
    dispatch({ type: 'CREATE_CYCLE_START' });

    try {
      const response = await fetch(`${config.apiEndpoint}/api/newcycle`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(cycleData)
          .replace(/\u2028/g, '\\u2028')
          .replace(/\u2029/g, '\\u2029'),
      });

      if (response.status !== 201) {

        if (response.status === 402) {
          //Here we need to receive the payment required and proceed to the "Checkout form"          
          dispatch({
            type: 'PAYMENT_ORDER_PROPERTIES',
            paymentOrderProperties: await (await response.json()).orderProperties,
          });

          //Store also the step we left with      
          localStorage.setItem('newCycle_mainStep', newCycleCurrentMainStep);
          return dispatch({
            type: 'SWITCH_NEW_WIZARD_MAJOR_STEP',
            newCycleCurrentMainStep: newCycleCurrentMainStep + 1,
          });
        }


        if (response.status === 401 || response.status === 403) {
          return dispatch({
            type: 'UNAUTHORIZED_REQUEST',
          });
        }

        dispatch({
          type: 'CREATE_CYCLE_FAIL',
          errorMessage: await response.json(),
        });

      }

      const cycle = await response.json();

      //Success, let's clear the caches data
      localStorage.removeItem('newCycle');
      localStorage.removeItem('newCycle_mainStep');
      localStorage.removeItem('newCycle_innerStep');
      dispatch({
        type: 'CREATE_WIZARD_CYCLE_SUCCESS',
        cycle,
      });

    } catch (e) {
      dispatch({
        type: 'CREATE_CYCLE_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });

    }
  };
}

function goToCycleDashboard(cycle) {
  return async (dispatch) => {
    dispatch({
      type: 'CREATE_WIZARD_CYCLE_SUCCESS',
      cycle,
    });
  }

}

function restructureSystemPlatforms() {
  return (dispatch, getState) => {
    const { devices, browsers } = getState();

    const platforms = [...platformArr];

    console.log('calling: restructureSystemPlatforms');

    //TODO: change browsers to be per Platform!!
    //For now, we set all system browsers as belong to all platforms, same list for all
    let systemBrowsers = extractBrowsers(browsers);

    platforms.map(platform => {
      platform["OSVersions"] = extractOSesForPlatformById(platform.id, devices.oses);
      platform["Devices"] = extractDevicesForPlatformById(platform.id, devices.models);
      platform["browsers"] = systemBrowsers;
    });

    return dispatch({
      type: 'EXTRACT_SYSTEM_PLATFORM_COMPLETE',
      systemPlatforms: platforms,
    });

  };
}

function fetchCoupon(couponCode: string) {
  return async (dispatch) => {
    const userIdResult = getUserIdFromToken();

    if (!userIdResult.isSuccess) {
      toast.error('Missing session token. Please login first');
      return dispatch({
        type: 'FETCH_COUPON_FAIL',
      });
    }

    const userId = userIdResult.getValue();

    dispatch({ type: 'FETCH_COUPON_START' });

    try {
      const response = await fetch(
        `${config.apiEndpoint}/api/newCycle/fetchCoupon`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
            mode: 'cors',

          },
          body: JSON.stringify({ couponCode }),
        }
      );

      if (response.status !== 201 && response.status !== 200) {

        if (response.status === 401 || response.status === 403) {
          return dispatch({
            type: 'UNAUTHORIZED_REQUEST',
          });
        }

        dispatch({
          type: 'FETCH_COUPON_FAIL',
          errorMessage: (await response.json()).errorMessage
        });

      }

      const responseResult = await response.json();

      dispatch({
        type: 'FETCH_COUPON_SUCCESS',
        couponData: responseResult,
      });

    }
    catch {
      dispatch({
        type: 'FETCH_COUPON_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });
    }
  }
}

function extractOSesForPlatformById(platformId, allOses) {
  let platformOSes = allOses.filter((itemOs) => itemOs.platformIds.includes(platformId));
  return platformOSes;
}

function extractDevicesForPlatformById(platformId, allDevices) {
  let platformDevices = allDevices.filter((itemModel) => itemModel.platformId === platformId);
  return platformDevices;
}

function extractBrowsers(allBrowsers) {
  let browserTypes = [...allBrowsers.names];

  browserTypes.map(browser => {
    browser["versionsAvailable"] = allBrowsers.versions.filter((browserVersion) => browserVersion.browserId === browser.id)
  });

  return browserTypes;
}

function getUserIdFromToken(): Result<number> {
  try {
    const token = <string>localStorage.getItem('token');

    if (!token) {
      throw new Error('Missing token');
    }

    const decoded = <Record<string, any>>jwt.decode(token);
    return Result.ok(decoded.id!);
  } catch (e) {
    console.error(e);
    return Result.fail(e);
  }
}

export default {
  switchNewCycleStep,
  switchNewCycleInnerStep,
  switchNewCycleStepForward,
  switchNewCycleStepBackward,
  updateCycle,
  submitCycle,
  goToCycleDashboard,
  restructureSystemPlatforms,
  fetchCoupon,
};
