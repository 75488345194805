import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Icon, Table, Segment } from 'semantic-ui-react';
import { WorkCategory } from '../../types/WorkCategory';
import { CycleTotal } from '../../components/Cycles/CycleTotal';
import { NewCycle } from '../../types/NewCycle';
import { Gender } from '../../types/Gender';
import { UserRole } from '../../types/UserRole';
import { UserStatus } from '../../types/UserStatus';
import { OperationStatus } from '../../types/OperationStatus';
import actions from '../../redux/actions';
import cycleActions from '../../redux/actions/cycleActions';
import { calculateCycleTotalCost } from '../../services/financeService';
import styled from 'styled-components';

const Wtf = styled.div`
  h1,
  h2,
  h3 {
    color: initial;
    margin: 10px;
  }
  h4,
  h5,
  h6 {
    color: initial;
    margin: 5px;
  }
`;

const mapStateToProps = (state) => {
  return {
    newCycle: state.newCycle,
    user: state.user,
    cycleCreateStatus: state.cycleCreateStatus.status,
    devices: state.devices,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    prevStep: () => dispatch(cycleActions.switchNewCycleStep(2)),
    submitCycle: (cycleData) => dispatch(cycleActions.submitCycle(cycleData)),
    registerModalOpen: () => dispatch(actions.registerModalOpen()),
  };
};

class StepThree extends Component<any> {
  onPrevStep = () => {
    this.props.prevStep();
  };

  onCycleCreate = () => {
    if (this.props.user.role !== UserRole.Customer) {
      return this.props.registerModalOpen();
    }

    if (this.props.user.status !== UserStatus.Active) {
      return this.props.history.push('account/customer');
    }

    this.props.submitCycle(this.props.newCycle);
  };

  render() {
    const newCycle: NewCycle = this.props.newCycle;
    const user = this.props.user;

    let workCategory;

    switch (newCycle.workCategory) {
      case WorkCategory.FeedbackAndRating:
        workCategory = 'Get feedback and star rating';
        break;
      case WorkCategory.FindBugsExploratory:
        workCategory = 'Find bugs - exploratory';
        break;
      case WorkCategory.FindBugsTestPlan:
        workCategory = 'Find bugs - test plan';
        break;
    }

    const optionalBlockVisible =
      newCycle.workCategory === WorkCategory.FeedbackAndRating ? 'visible' : '';

    const gender = Object.keys(Gender).reduce((result, el) => {
      if (Gender[el] === +newCycle.gender) {
        result = el.charAt(0).toUpperCase() + el.slice(1);
      }

      return result;
    }, 'Any');

    const platformNames = newCycle.testEnvs
      .filter((el) => el.type === 'Platform')
      .map((el) => el.name)
      .join(', ');

    const deviceNames = newCycle.testEnvs
      .filter((el) => el.type !== 'Platform')
      .map((el) => el.name)
      .join(', ');

    const countries = newCycle.countries.length ? newCycle.countries.join(', ') : 'All';

    const totalTesters = newCycle.testEnvs.reduce((result, el) => {
      result += el.requiredTesters;
      return result;
    }, 0);

    const totalCost = calculateCycleTotalCost(newCycle);

    let vat = 0;

    if (totalCost > user.balance) {
      if (user.country === 'Israel') {
        vat = parseFloat(((totalCost - user.balance) * 0.17).toFixed(2));
      }
    }

    return (
      <div className="cycle-step cycle-step3">
        <h2>Summary &amp; checkout</h2>

        <Segment.Group>
          <Segment text="true" content={newCycle.title} />
          <Segment text="true" className="cycle-summary">
            <Wtf dangerouslySetInnerHTML={{ __html: newCycle.description }}></Wtf>
          </Segment>
          <Segment color="green">
            <Table celled definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Delivery time</Table.Cell>
                  <Table.Cell>{newCycle.deliveryTime}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Scope</Table.Cell>
                  <Table.Cell>{workCategory}</Table.Cell>
                </Table.Row>
                {optionalBlockVisible ? (
                  <Table.Row>
                    <Table.Cell>Gender</Table.Cell>
                    <Table.Cell>{gender}</Table.Cell>
                  </Table.Row>
                ) : null}
                {optionalBlockVisible ? (
                  <Table.Row>
                    <Table.Cell>Minimal age</Table.Cell>
                    <Table.Cell>{newCycle.minAge}</Table.Cell>
                  </Table.Row>
                ) : null}
                {optionalBlockVisible ? (
                  <Table.Row>
                    <Table.Cell>Maximal age</Table.Cell>
                    <Table.Cell>{newCycle.maxAge}</Table.Cell>
                  </Table.Row>
                ) : null}
                <Table.Row>
                  <Table.Cell>Platforms</Table.Cell>
                  <Table.Cell>{platformNames}</Table.Cell>
                </Table.Row>
                {deviceNames.length > 0 && (
                  <Table.Row>
                    <Table.Cell>Devices/Browsers</Table.Cell>
                    <Table.Cell>{deviceNames}</Table.Cell>
                  </Table.Row>
                )}
                <Table.Row>
                  <Table.Cell>Testers location</Table.Cell>
                  <Table.Cell>{countries}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Use your favourite testers?</Table.Cell>
                  <Table.Cell>{newCycle.useFavoriteTesters ? 'Yes' : 'No'}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Required testers</Table.Cell>
                  <Table.Cell>{totalTesters}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Hours per tester</Table.Cell>
                  <Table.Cell>{newCycle.hoursPerTester}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Price per hour</Table.Cell>
                  <Table.Cell>${newCycle.hourlyRate}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Segment>

          <CycleTotal total={totalCost} vat={vat} balance={user.balance} />
        </Segment.Group>

        <Button
          icon
          className="next-step-button nav-button"
          size="huge"
          labelPosition="right"
          onClick={this.onCycleCreate}
          loading={this.props.cycleCreateStatus === OperationStatus.processing}
          disabled={this.props.cycleCreateStatus === OperationStatus.processing}
        >
          <Icon name="arrow right" />
          Proceed to checkout
        </Button>

        <Button icon className="nav-button" onClick={this.onPrevStep} labelPosition="right">
          <Icon name="arrow left" />
          Back
        </Button>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StepThree));
