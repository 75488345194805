import React from 'react';
import { withRouter } from 'react-router-dom';

const EmailPending = () => {
  return (
    <div className="registerconfirm page">
      <div className="heading">
        <h1>Email confirmation pending</h1>
      </div>

      <div className="inner">
        <div className="makepay">
          <div>
            <h2>Your email is not confirmed yed</h2>
            <p>Please check your inbox and follow the link in the message</p>
            <p>Double check your spam folder</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(EmailPending);
