import { Alert, Box, Button, Chip as ChipMUI, MobileStepper, Button as MuiButton, TextField, Theme, Typography } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import trim from 'lodash/trim';
import values from 'lodash/values';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { SpecificationType } from '../../types/WizardCategoriesTypes';
import SharedStyle from '../Wizard/InnerForms/SharedStyle';


import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { CssVarsProvider } from '@mui/joy';
import Checkbox from '@mui/joy/Checkbox';
import Chip from '@mui/joy/Chip';
import wizardActions from '../../redux/actions/wizardActions';
import { NewWizardCycle } from '../../types/NewWizardCycle';
import { UserRole } from '../../types/UserRole';
import { WorkCategory } from '../../types/WorkCategory';
import { Mixpanel } from '../../util/mixpanel';
import reportingTypes from '../../util/options/reportingTypes';
import PlanFormSlim from '../Wizard/InnerForms/PlanFormSlim';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginTop: SharedStyle.innerContent.marginTop,
        marginLeft: SharedStyle.innerContent.marginLeft,
    },
    internalBox: {
        marginTop: '20px',
    },
    descriptionCardText: {
        fontSize: '14px',
        color: '#171725',
    },
}));

const StyledButton = withStyles({
    root: {
        backgroundColor: '#F1F1F5',
        borderRadius: 10,
        border: 0,
        color: '#696974',
        height: 40,
        whiteSpace: "nowrap"
    },
    text: {
        fontSize: '12px',
        paddingLeft: '10px',
        paddingRight: '10px',
    },
})(Button);

const allReportingTypes = [...reportingTypes];

const errorsInitialState = {
    title: '',
    description: '',
    startingPointURL: '',
    testPlan: '',
};

const isValidURL = (url) => {

    const regexp = /^(ftp|http|https):\/\/*/;
    if (regexp.test(url) === false) {
        url = 'http://' + url;
    }

    try {
        new URL(url);
        return true;
    } catch (error) {
        return false;
    }
}

const validateErrorsForm = (isWithTestPlan, { title, description, startingPointURL, testPlanArr }) => {
    const errors = { ...errorsInitialState };
    errors.title = isEmpty(trim(title)) ? 'required field*' : '';
    errors.description = isEmpty(trim(description)) ? 'required field*' : '';
    errors.startingPointURL = (isEmpty(trim(startingPointURL)) || !isValidURL(trim(startingPointURL))) ? 'requires a valid URL*' : '';
    errors.testPlan = '';
    if (isWithTestPlan && !testPlanArr.length) {
        errors.testPlan = 'Your test plan is empty, Please define at least one test plan item';
    }
    return errors;
};


export default function StepTwoForm(props) {

    const { pricingType, next, back } = props;

    const newWizardCycle: NewWizardCycle = useSelector((state: RootState) => state).newWizardCycle;
    const { user } = useSelector((state: RootState) => state);

    const dispatch = useDispatch();

    const classes = useStyles();

    const [formErrors, setFormErrors] = useState({ ...errorsInitialState });

    useEffect(() => {
        if (newWizardCycle.testPlan && newWizardCycle.testPlan.length > 0) {
            formErrors['testPlan'] = '';
        }
    }, [newWizardCycle.testPlan, newWizardCycle?.testPlan?.length]);

    const onChange = (event) => {
        newWizardCycle[event.name] = event.value;
        dispatch(wizardActions.updateCycle(newWizardCycle));
    }

    const resetErrorStateForInput = (event) => {
        formErrors[event.name] = '';
    }

    const onCheckUrl = () => {
        let url = newWizardCycle.startingPointURL || '';

        if (2 > url.length) {
            return;
        }

        const regexp = /^(ftp|http|https):\/\/*/;

        if (regexp.test(url) === false) {
            url = 'http://' + url;
        }

        window.open(url, '_blank');
    };

    const projectURLTitle = 'Project URL to your '
        + newWizardCycle.mainTestPlatform.name
        + ' ' + (newWizardCycle.testSpecification === SpecificationType.NativeApp ? 'Application:' : 'WebApp:');


    const tryNext = () => {
        const errors = validateErrorsForm(newWizardCycle.workCategory === WorkCategory.FindBugsTestPlan,
            { title: newWizardCycle.title, description: newWizardCycle.description, startingPointURL: newWizardCycle.startingPointURL, testPlanArr: newWizardCycle.testPlan });
        if (some(values(errors))) {
            return setFormErrors(errors);
        }

        next();
    }

    useEffect(() => {
        let userId = user.role == UserRole.Anonymous ? -1 : user.id;
        Mixpanel.track('Entered Pricing Step 2', { userId, pricingType });
    }, []);

    return (
        <React.Fragment>
            <Box sx={{ m: 2 }} /* sx={{ m: { md: 2, lg: 5 } }} for responsiveness - per breakpoint! */>
                <Typography variant="h2" /* color={isEmpty(trim(newWizardCycle.title)) ? 'red' : ''} */>
                    Title:
                </Typography>
                <Box sx={{ mt: 1 }}>
                    <TextField
                        required
                        margin="none"
                        style={{ width: "60%" }}
                        size='small'
                        autoComplete='off'
                        label="Give your test a name"
                        variant="outlined"
                        name="title"
                        value={newWizardCycle.title}
                        /* error={isEmpty(trim(newWizardCycle.title))} */
                        onChange={e => { onChange(e.target); resetErrorStateForInput(e.target); }}
                        helperText={formErrors.title}
                    /* error={errors.includes('title')} */
                    /* InputLabelProps={{ shrink: true }} */
                    />
                    {/* <Alert style={{ width: "60%" }} severity="error">Required field</Alert> */}
                </Box>

                <Box sx={{ mt: 4 }}>
                    <Typography variant="h2">
                        Tell us more, describe your test:
                    </Typography>
                    <Box sx={{ mt: 1 }}>
                        <TextField
                            required
                            margin="none"
                            autoComplete='off'
                            multiline rows={((pricingType === 'speedtest') || (pricingType === 'firstcycle')) ? 8 : 5} style={{ width: "80%", minWidth: "50%" }}
                            label="Tell our testers about your test"
                            variant="outlined"
                            name="description"
                            value={newWizardCycle.description}
                            onChange={e => { onChange(e.target); resetErrorStateForInput(e.target); }}
                            helperText={formErrors.description}
                        //helperText={isEmpty(trim(newWizardCycle.description)) ? 'Required field*' : ""}
                        /* error={this.state.errors.includes('description')} */
                        /* error={isEmpty(trim(newWizardCycle.description))} */
                        />
                        {/* {isEmpty(trim(newWizardCycle.description)) && <Alert severity="error">Required field*</Alert>} */}
                    </Box>
                </Box>


                {((pricingType === 'speedtest') || (pricingType === 'firstcycle')) &&
                    <Box sx={{ mt: 4 }}>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <Box>
                                <Typography variant="h2">
                                    Select what the testers should focus on:
                                </Typography>
                                <Box
                                    role="group"
                                    aria-labelledby="fav-movie"
                                    sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 1, width: '80%' }}
                                >
                                    <CssVarsProvider>
                                        {allReportingTypes.map((ChipData) => {
                                            const checked = newWizardCycle.reportingCategories.includes(ChipData.key);
                                            return (
                                                <Chip
                                                    key={ChipData.key}
                                                    variant={checked ? 'soft' : 'plain'}
                                                    color={checked ? 'primary' : 'neutral'}
                                                    startDecorator={
                                                        checked && <CheckIcon sx={{ zIndex: 1, pointerEvents: 'none' }} />
                                                    }
                                                >
                                                    <Checkbox
                                                        key={ChipData.key}
                                                        variant="outlined"
                                                        color={checked ? 'primary' : 'neutral'}
                                                        disableIcon
                                                        overlay
                                                        label={ChipData.text}
                                                        checked={checked}
                                                        onClick={() => {
                                                            newWizardCycle.reportingCategories =
                                                                checked
                                                                    ? newWizardCycle.reportingCategories.filter((n) => n !== ChipData.key)
                                                                    : [...newWizardCycle.reportingCategories, ChipData.key];
                                                            dispatch(wizardActions.updateCycle(newWizardCycle));
                                                        }}
                                                    />
                                                </Chip>
                                            )
                                        })}
                                    </CssVarsProvider>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                }




                {pricingType === 'popular' &&
                    <React.Fragment>
                        <Box sx={{ mt: 4 }}>
                            <Typography variant="h2" >
                                {projectURLTitle}
                            </Typography>
                            <Box sx={{ mt: 1 }}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    margin="none"
                                    style={{ width: "80%" }}
                                    label="Type the URL starting point for the tester"
                                    variant="outlined"
                                    name="startingPointURL"
                                    value={newWizardCycle.startingPointURL}
                                    onChange={e => { onChange(e.target); resetErrorStateForInput(e.target); }}
                                    autoComplete='off'
                                    InputProps={{ endAdornment: <ChipMUI color="primary" variant="outlined" style={{ cursor: "pointer" }} onClick={onCheckUrl} label="Check my URL"></ChipMUI > }}
                                    helperText={formErrors.startingPointURL}
                                />
                            </Box>
                        </Box>
                        <PlanFormSlim></PlanFormSlim>
                        {/* Adding this two extra && after the simple error check to enforce the immediate error reset
                        The planFormSlim update the testPlan and the effect occurs after the render */}
                        {formErrors.testPlan && newWizardCycle.testPlan && !newWizardCycle.testPlan.length && <Alert style={{ width: "80%" }} variant='outlined' severity="error">{formErrors.testPlan}</Alert>}
                    </React.Fragment>
                }




                {((pricingType === 'speedtest') || (pricingType === 'firstcycle')) &&
                    <Box sx={{ mt: 4 }}>
                        <Typography variant="h2" >
                            {projectURLTitle}
                        </Typography>
                        <Box sx={{ mt: 1 }}>
                            <TextField
                                fullWidth
                                margin="none"
                                style={{ width: "80%" }}
                                label="Type the URL starting point for the tester"
                                variant="outlined"
                                name="startingPointURL"
                                value={newWizardCycle.startingPointURL}
                                onChange={e => { onChange(e.target); resetErrorStateForInput(e.target); }}
                                autoComplete='off'
                                InputProps={{ endAdornment: <ChipMUI color="primary" variant="outlined" style={{ cursor: "pointer" }} onClick={onCheckUrl} label="Check my URL"></ChipMUI > }}
                                helperText={formErrors.startingPointURL}
                            />
                        </Box>
                    </Box>
                }


            </Box >

            <Box sx={{ width: '75%' }}>
                <MobileStepper
                    variant="text"
                    steps={4}
                    position="static"
                    activeStep={1}
                    nextButton={
                        <MuiButton
                            size="large"
                            onClick={tryNext}
                        /* disabled={newWizardCycle.title === '' || newWizardCycle.description === '' || newWizardCycle.startingPointURL === ''} */
                        >
                            Next
                            <KeyboardArrowRight />
                        </MuiButton>
                    }
                    backButton={
                        <MuiButton size="large" onClick={back}>
                            <KeyboardArrowLeft />
                            Back
                        </MuiButton>
                    }
                />
            </Box>

        </React.Fragment>
    )
}
