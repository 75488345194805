import React from 'react';

const SidemenuSummary = (props) => {
  const { companyName, balance, avatarUrl } = props;

  return (
    <div className="summary">
      <img className="avatar" src={avatarUrl} alt="" height="64" width="64" />
      <div className="details">
        <span className="name">{companyName}</span>
        <span className="balance">Balance: ${balance}</span>
      </div>
    </div>
  );
};

export { SidemenuSummary };
