import classNames from 'classnames';
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import TesterCard from './TesterCard';
import { TesterStatusInCycle } from '../../../../types/TesterStatusInCycle';
import { CycleStatus } from '../../../../types/CycleStatus';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginTop: 30,
    },
    filterButtosContainer: {
        marginTop: 30,
        marginBottom: 50,
    },
    filterButton: {
        border: 'none',
        marginRight: 15,
        boxShadow: 'none',
        padding: '2px 20px',
        transition: 'all 0.4s',
        '&:hover': {
            color: '#ffffff',
            boxShadow: 'none',
        },
    },
    filterButtonInactive: {
        backgroundColor: '#ffffff',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
    },
    card: {
        minWidth: 210,
        marginRight: 20,
        marginBottom: 30,
    },
}));

type TestFilterType = 'complete' | 'inprogress' | 'all';

const TestersList: React.FC<{ testers: any[], cycleStatus?: CycleStatus }> = ({ testers, cycleStatus }) => {
    const classes = useStyles();

    let filterButtons: { label: string; id: TestFilterType }[] = [
        { label: 'All', id: 'all' },
        { label: 'In progress', id: 'inprogress' },
        { label: 'Complete', id: 'complete' },
    ];
    
    const [filter, setFilter] = useState<TestFilterType>('all');

    const visibleTesters = testers.filter((tester) => {
        //Assuming we're handling only the three states, excluding 'pending' & 'rejected'
        if (filter === 'all') {
            return [TesterStatusInCycle.Finished, TesterStatusInCycle.AwaitingReview, TesterStatusInCycle.Approved].includes(tester.statusInCycle);
        }
        if (filter === 'complete') {
            return [TesterStatusInCycle.Finished, TesterStatusInCycle.AwaitingReview].includes(tester.statusInCycle);
        }
        if (filter === 'inprogress') {
            return [TesterStatusInCycle.Approved].includes(tester.statusInCycle);
        }
        return false;
    });

    const allCount = testers.length;
    const completeCount = testers.filter( (tester) => [TesterStatusInCycle.Finished, TesterStatusInCycle.AwaitingReview].includes(tester.statusInCycle) ).length;
    const inprogressCount = testers.filter( tester => [TesterStatusInCycle.Approved].includes(tester.statusInCycle) ).length;
    
    if(cycleStatus === CycleStatus.Completed){
        filterButtons = filterButtons.filter( ({id}) => id === 'all' );        
    }

    return (
        <div className={classes.root}>
            <Typography variant="h6" component="h2">Testers list</Typography>

            <Box display="flex" className={classes.filterButtosContainer}>
                {filterButtons.map(({ label, id }) => (
                    <Button 
                        key={label}
                        size="small" 
                        color="primary"
                        variant="contained"
                        onClick={() => setFilter(id)}
                        className={classNames(classes.filterButton, {
                            [classes.filterButtonInactive]: id !== filter,
                        })}
                    >
                        {label + ' (' + eval(`${id}Count`) + ')' }
                    </Button>         
                ))}
            </Box>

            <Box display="flex" flexWrap="wrap">
                {visibleTesters.map((tester) => (
                    <TesterCard
                        key={tester.id}
                        cycleStatus={cycleStatus}
                        country={tester.country}
                        avatarUrl={tester.avatarUrl}
                        lastName={tester.lastName}
                        firstName={tester.firstName}
                        status={tester.statusInCycle}
                        className={classes.card}
                    />
                ))}
            </Box>
        </div>
    );
};

export default TestersList;
