import React, { useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';

interface Props {
  src: string;
  manual?: boolean;
  label?: string;
  index?: number;
  onDelete?: (event: any, index: any) => void;
}

const BugVideoDisplay = ({ src, manual, label, index, onDelete }: Props) => {
  const [active, setActive] = useState(!manual);

  if (!active) {
    return (
      // <button type="button" onClick={() => setActive(true)}>
      //   {label || 'Unlabeled'}
      // </button>
      <div className="bug-attachment">
        <button type="button" className="non-image-attachment" onClick={() => setActive(true)}>
          <Icon name="video play" />
          {label || 'Video'}
        </button>
      </div>
    );
  }

  return (
    <div className="bug-attachment">
      {src && (
        <a target="_blank" href={src}>
          <video controls width="240">
            <source src={src} type="video/mp4" />
          </video>
        </a>
      )}
      {onDelete && (
        <Button
          basic
          color="red"
          className="delete-upload"
          size="mini"
          icon="trash alternate"
          onClick={onDelete}
          data-index={index}
        />
      )}
    </div>
  );
};

export default BugVideoDisplay;
