import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import App from './App';
import history from './customHistory';
import { store } from './redux/store';
// import registerServiceWorker from './registerServiceWorker';

import 'semantic-ui-css/semantic.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'flag-icon-css/css/flag-icon.css';
import 'react-table/react-table.css';
import 'react-toastify/dist/ReactToastify.min.css';
import './index.css';

import { ThemeProvider, Theme} from '@mui/material/styles';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import theme from './components/Wizard/theme';


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


window['store'] = store; // for debug

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </StyledEngineProvider>
    </Router>
  </Provider>,
  document.getElementById('root')
);
//
// registerServiceWorker();
