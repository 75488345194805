import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import StarRatingComponent from 'react-star-rating-component';
import Sidemenu from './Sidemenu';

import noavatar from '../../../public/images/noavatar.png';

const mapStateToProps = (state) => {
  return {
    favoriteTesters: state.favoriteTesters,
  };
};

const FavoriteTesterPreview = (props) => {
  const tester = props.tester;

  return (
    <Link className="favorite-tester tester-preview" to={`/account/customer/favorite/${tester.id}`}>
      <img className="avatar" src={tester.avatarUrl || noavatar} alt="" height="64" width="64" />
      <div style={{ flexDirection: 'column' }}>
        <span className="name">
          {tester.firstName} {tester.lastName}
        </span>
        <br />
        <span className="country">{tester.country}</span>
        <div className="rating">
          <StarRatingComponent name="rate1" starCount={5} value={parseInt(tester.rating, 10)} />
          <span>{parseFloat(tester.rating).toFixed(2)}</span>
        </div>
      </div>
    </Link>
  );
};

class FavoriteTesters extends Component<any> {
  render() {
    const testerIds = Object.keys(this.props.favoriteTesters.byId);

    return (
      <div className="account-customer page">
        <div className="heading">
          <h1>Favorite Testers</h1>
        </div>

        <div className="inner">
          <Sidemenu />
          <main className="favorite-list">
            {testerIds.length > 0 ? (
              testerIds.map((testerId) => {
                const tester = this.props.favoriteTesters.byId[testerId];

                return <FavoriteTesterPreview key={tester.id} tester={tester} />;
              })
            ) : (
              <p>No favorite testers so far</p>
            )}
          </main>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(FavoriteTesters));
