import React from 'react';
import { Redirect } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';

import sampleReport1 from '../public/images/sampleReport1.png';
import sampleReport2 from '../public/images/sampleReport2.png';
import sampleReport3 from '../public/images/sampleReport3.png';
import sampleReport4 from '../public/images/sampleReport4.png';
import sampleReport5 from '../public/images/sampleReport5.png';
import sampleReport6 from '../public/images/sampleReport6.png';

const SampleReports = () => {
  const panes = [
    {
      menuItem: 'Select work',
      render: () => (
        <Tab.Pane>
          <p>Select your required work type and test devices</p>
          <img src={sampleReport1} alt="" />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Provide details',
      render: () => (
        <Tab.Pane>
          <p>Provide details about your project and test goals</p>
          <img src={sampleReport2} alt="" />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Receive details',
      render: () => (
        <Tab.Pane>
          <p>Receive detailed bug reports in real time</p>
          <img src={sampleReport3} alt="" />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'View details',
      render: () => (
        <Tab.Pane>
          <p>View bug details including screenshots</p>
          <img src={sampleReport4} alt="" />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'General feedback',
      render: () => (
        <Tab.Pane>
          <p>View general product feedback report to your choice of pre-defined questions</p>
          <img src={sampleReport5} alt="" />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'View rating',
      render: () => (
        <Tab.Pane>
          <p>View 'star rating' of your mobile app or website</p>
          <img src={sampleReport6} alt="" />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'START NOW',
      render: () => (
        <Tab.Pane>
          <Redirect to="/cycles" />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div className="samplereports page">
      <div className="heading">
        <h1>Simple as that... </h1>
      </div>

      <Tab
        menu={{ secondary: true, pointing: true }}
        className="sample-report-tabs"
        panes={panes}
      />
    </div>
  );
};

export default SampleReports;
