import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Icon, Popup, Label, Input } from 'semantic-ui-react';
import ReactTable from 'react-table';
import actions from '../../../../redux/actions';
import { OperationStatus } from '../../../../types/OperationStatus';

const mapStateToProps = (state) => {
  return {
    user: state.user,
    finance: state.userFinance,
    withdrawalStatus: state.withdrawalStatus,
    listOwnFinanceStatus: state.listOwnFinanceStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (data) => dispatch(actions.updateUser(data)),
    listOwnFinance: () => dispatch(actions.listOwnFinance()),
    saveUser: (data: any, notify?: boolean) => dispatch(actions.saveUser(data, notify)),
    withdraw: () => dispatch(actions.withdraw()),
  };
};

class Finance extends Component<any> {
  public state: any;

  constructor(props) {
    super(props);

    this.state = {
      errors: new Set(),
    };
  }

  resetErrorState = (event) => {
    const name = event.target.name;
    const errors = this.state.errors;

    if (errors.has(name)) {
      errors.delete(name);
      this.setState({ errors });
    }
  };

  onChange = (event, data) => {
    const user = {};

    if (data.name !== 'paypal') {
      return;
    }

    user[data.name] = data.value;

    this.props.updateUser(user);
    this.props.saveUser(user, false); // TODO fix later
  };

  onWithdraw = (event, data) => {
    if (!this.props.user.paypal || !this.props.user.paypal.length) {
      return this.setState({
        errors: new Set(['paypal']),
      });
    }

    this.props.withdraw();
  };

  componentDidMount() {
    this.props.listOwnFinance();
  }

  render() {
    const finance = this.props.finance || []; // TODO rename finance -> cycleSummaries

    const availableForWithdrawalGross = finance.reduce((result, el) => {
      if (!el.paidAt && moment().isSameOrAfter(el.withdrawalAvailableFrom)) {
        result += el.amountToPay;
      }

      return result;
    }, 0);

    const paypalComission =
      (availableForWithdrawalGross / 100) * 2 > 1 ? 1 : (availableForWithdrawalGross / 100) * 2;

    const availableForWithdrawalNet = availableForWithdrawalGross - paypalComission;

    const pendingTotal = finance.reduce((result, el) => {
      if (!el.paidAt && moment().isBefore(el.withdrawalAvailableFrom)) {
        result += el.amountToPay;
      }

      return result;
    }, 0);

    const withdrewTotal = finance.reduce((result, el) => {
      if (el.paidAt) {
        result += el.amountToPay;
      }

      return result;
    }, 0);

    const columns = [
      {
        Header: 'Credit Summary',
        columns: [
          {
            Header: 'Project',
            accessor: 'title',
          },
          {
            Header: 'Accepted On',
            id: 'createdAt',
            accessor: (row) => moment(row.createdAt).format('DD.MM.YYYY'),
            getProps: () => ({ style: { textAlign: 'center' } }),
            sortMethod: (a, b) => {
              if (moment(a).isAfter(moment(b))) {
                return 1;
              }

              if (moment(a).isBefore(moment(b))) {
                return -1;
              }

              return 0;
            },
            maxWidth: 120,
          },
          {
            Header: 'My bid',
            accessor: 'testerBid',
            getProps: () => ({ style: { textAlign: 'center' } }),
            maxWidth: 80,
          },
          {
            Header: 'Hours',
            accessor: 'hoursPerTester',
            getProps: () => ({ style: { textAlign: 'center' } }),
            maxWidth: 80,
          },
          {
            Header: () => (
              <span>
                Amount
                <br />
                To Pay
              </span>
            ),
            accessor: 'amountToPay',
            getProps: () => ({ style: { textAlign: 'center' } }),
            maxWidth: 80,
          },
          {
            Header: 'Status',
            id: 'status',
            accessor: (row) => {
              if (row.paidAt) {
                return `Paid at ${moment(row.paidAt).format('DD.MM.YYYY')}`;
              } else if (moment().isBefore(moment(row.withdrawalAvailableFrom))) {
                return `Will be available at ${moment(row.withdrawalAvailableFrom).format(
                  'DD.MM.YYYY'
                )}`;
              } else {
                return (
                  <Label horizontal color="green">
                    Available for withdrawal
                  </Label>
                );
              }
            },
            getProps: () => ({
              style: {
                textAlign: 'center',
                whiteSpace: 'pre-wrap',
              },
            }),
            maxWidth: 180,
          },
        ],
      },
    ];

    const withdrawDisabled =
      availableForWithdrawalNet <= 0 ||
      this.props.withdrawalStatus.status === OperationStatus.processing ||
      this.props.listOwnFinanceStatus.status === OperationStatus.processing;

    return (
      <div>
        <p>
          Your PayPal account: &nbsp;
          <Input
            name="paypal"
            value={this.props.user.paypal}
            onChange={this.onChange}
            error={this.state.errors.has('paypal')}
          />
        </p>
        <p>
          Total credit available for withdrawal: ${availableForWithdrawalNet} &nbsp;
          <Popup
            trigger={<Button circular icon="question" size="mini" compact />}
            content="PayPal commission of 2% of your total available credit was deducted"
          />
          <Button
            icon
            labelPosition="left"
            color="yellow"
            style={{
              marginLeft: '1rem',
            }}
            onClick={this.onWithdraw}
            loading={this.props.withdrawalStatus.status === OperationStatus.processing}
            disabled={withdrawDisabled}
          >
            Withdraw with PayPal
            <Icon name="paypal" />
          </Button>
        </p>
        <p>Total credit withdrew: ${withdrewTotal}</p>
        <p>
          Total pending credit: ${pendingTotal} &nbsp;
          <Popup
            trigger={<Button circular icon="question" size="mini" compact />}
            content={`
                            Credits are available to withdraw for unpaid cycles which ended
                            prior to the 15th of the previous month. For example: If we're
                            on the 1st of some month, you can withdraw completed cycles that
                            ended prior to the 15th of the previous month. Any cycle which
                            completed after the last 15th will be listed as Pending credits,
                            and will be available to withdraw after the 15th of this month.
                    `}
          />
        </p>
        <ReactTable data={finance} columns={columns} noDataText="" />
        <p>
          Have any questions? <a>Contact us</a>
        </p>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Finance));
