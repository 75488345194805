import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Select, Input, Checkbox, Button, Icon } from 'semantic-ui-react';
import * as _ from 'lodash';
import TesterList from '../../components/TesterList';
import { WorkCategory } from '../../types/WorkCategory';
import { DevicePlatform } from '../../types/DevicePlatform';
import { NewCycle } from '../../types/NewCycle';
import {
  TestEnv,
  Platform,
  MobileDevice,
  DesktopBrowser,
  isMobileDevice,
} from '../../types/TestingEnvironment';
import deliveryTime from '../../util/options/deliveryTime';
import genders from '../../util/options/genders';
import hourlyRates from '../../util/options/hourlyRates';
import countryCodes from '../../util/options/countryCodes';
import platformOptions from '../../util/options/platforms';
import cycleActions from '../../redux/actions/cycleActions';
import { getPlatformPrettyName } from '../../services/deviceService';
import { searchFromStart } from '../../util/utils';

type TestEnvWithoutCountry = Omit<TestEnv, 'country'>;

const mapStateToProps = (store) => {
  return {
    newCycle: store.newCycle as NewCycle,
    newCycleTestersPage: store.newCycleTestersPage,
    errors: store.newCycleCurrentStepErrors,
    devices: store.devices,
    browsers: store.browsers,
    potentialTesters: store.potentialTesters,
    potentialTestersTotal: store.potentialTestersTotal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    nextStep: () => dispatch(cycleActions.switchNewCycleStep(2)),
    paginateTesters: (page) => dispatch(cycleActions.paginateTesters(page)),
    updateCycle: (data) => dispatch(cycleActions.updateCycle(data)),
    listPotentialTesters: (cycleData, page) =>
      dispatch(cycleActions.listPotentialTesters(cycleData, page)),
  };
};

const allCountryCodes = [{ key: 'All', value: 'All', text: 'All' }, ...countryCodes];

const stepOneDefaultWorkCategories = [
  {
    text: 'Find bugs',
    key: WorkCategory.FindBugsExploratory,
    value: WorkCategory.FindBugsExploratory,
  },
  {
    disabled: true,
    text: 'Get feedback and star rating',
    key: WorkCategory.FeedbackAndRating,
    value: WorkCategory.FeedbackAndRating,
  },
];

const stepOneTestPlanWorkCategories = [
  {
    text: 'Find bugs',
    key: WorkCategory.FindBugsTestPlan,
    value: WorkCategory.FindBugsTestPlan,
  },
  {
    disabled: true,
    text: 'Get feedback and star rating',
    key: WorkCategory.FeedbackAndRating,
    value: WorkCategory.FeedbackAndRating,
  },
];

class StepOne extends Component<any> {
  public state: any;
  private allMobileDeviceEnvs: MobileDevice[] = [];
  private allDesktopBrowserEnvs: DesktopBrowser[] = [];

  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      modelOptions: [],
    };
  }

  componentDidMount() {
    this.props.listPotentialTesters(this.props.newCycle, this.props.newCycleTestersPage);
    this.allMobileDeviceEnvs = this.props.devices.models.map((model) => ({
      modelId: model.id,
      name: model.name,
      type: 'MobileDevice',
      vendorId: model.manufacturerId,
      platformId: model.platformId,
      url: '',
      requiredTesters: 3,
    }));

    this.allDesktopBrowserEnvs = this.props.browsers.names.map((browser) => ({
      browserId: browser.id,
      name: browser.name,
      type: 'DesktopBrowser',
      platformId: DevicePlatform.Desktop,
      url: '',
      requiredTesters: 3,
    }));

    const nonPlatformEnvs = this.props.newCycle.testEnvs
      .map((el) => el.platformId)
      .reduce((result, platformId) => {
        switch (platformId) {
          case DevicePlatform.AndroidMobile:
          case DevicePlatform.AndroidTablet:
          case DevicePlatform.Ipad:
          case DevicePlatform.Iphone:
          case DevicePlatform.WindowsPhone:
          case DevicePlatform.WindowsDesktop:
          case DevicePlatform.WindowsLaptop:
          case DevicePlatform.MACDesktop:
          case DevicePlatform.MACNotebook: {
            const mobileDeviceEnvs = this.allMobileDeviceEnvs.filter(
              (env) => env.platformId === platformId
            );

            result = result.concat(mobileDeviceEnvs);
            return result;
          }

          case DevicePlatform.Desktop: {
            const browserEnvs = this.allDesktopBrowserEnvs;

            result = result.concat(browserEnvs);
            return result;
          }

          default:
            throw new Error(`Unknown platformId ${platformId}`);
        }
      }, [] as (MobileDevice | DesktopBrowser)[]);

    const nonPlatformEnvOptions = nonPlatformEnvs.map((el) => {
      if (el.type === 'MobileDevice') {
        return {
          key: el.name,
          text: el.name,
          value: `${el.type}_${el.modelId}`,
        };
      } else {
        return {
          key: el.name,
          text: el.name,
          value: `${el.type}_${el.browserId}`,
        };
      }
    });

    this.setState({
      modelOptions: nonPlatformEnvOptions,
    });
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.errors, this.props.errors)) {
      this.setState({ errors: this.props.errors });
    }

    const newCycle: NewCycle = this.props.newCycle;

    [
      'workCategory',
      'gender',
      'minAge',
      'maxAge',
      'platformIds',
      'countries',
      'hourlyRate',
    ].forEach((el) => {
      if (prevProps.newCycle[el] !== newCycle[el]) {
        this.props.listPotentialTesters(newCycle);
      }
    });

    if (prevProps.newCycle.testEnvs.length !== this.props.newCycle.testEnvs.length) {
      const nonPlatformEnvs = this.props.newCycle.testEnvs
        .map((el) => el.platformId)
        .reduce((result, platformId) => {
          switch (platformId) {
            case DevicePlatform.AndroidMobile:
            case DevicePlatform.AndroidTablet:
            case DevicePlatform.Ipad:
            case DevicePlatform.Iphone:
            case DevicePlatform.WindowsPhone:
            case DevicePlatform.WindowsDesktop:
            case DevicePlatform.WindowsLaptop:
            case DevicePlatform.MACDesktop:
            case DevicePlatform.MACNotebook: {
              const mobileDeviceEnvs = this.allMobileDeviceEnvs.filter(
                (env) => env.platformId === platformId
              );
              result = result.concat(mobileDeviceEnvs);
              return result;
            }

            case DevicePlatform.Desktop: {
              const browserEnvs = this.allDesktopBrowserEnvs;

              result = result.concat(browserEnvs);
              return result;
            }

            default:
              throw new Error(`Unknown platformId ${platformId}`);
          }

          return result;
        }, [] as (MobileDevice | DesktopBrowser)[]);

      const nonPlatformEnvOptions = nonPlatformEnvs.map((el) => {
        if (el.type === 'MobileDevice') {
          const vendorName = this.props.devices.manufacturers.find((m) => m.id === el.vendorId)
            ?.name;

          return {
            key: `${el.type}_${el.modelId}`,
            text: `${vendorName} ${el.name}`,
            value: `${el.type}_${el.modelId}`,
          };
        } else {
          const vendorName = this.props.browsers.names.find((b) => b.id === el.browserId)?.name;

          return {
            key: `${el.type}_${el.browserId}`,
            text: `${vendorName} ${el.name}`,
            value: `${el.type}_${el.browserId}`,
          };
        }
      });

      nonPlatformEnvOptions.sort((a, b) => {
        if (a.text > b.text) {
          return 1;
        }
        if (a.text === b.text) {
          return 0;
        }
        if (a.text < b.text) {
          return -1;
        }
      });

      this.setState({
        modelOptions: nonPlatformEnvOptions,
      });
    }
  }

  resetErrorStateForInput = (event, data) => {
    if (this.state.errors.includes(data.name)) {
      const errors = [...this.state.errors];

      errors.splice(this.state.errors.indexOf(data.name), 1);
      this.setState({ errors });
    }
  };

  onChange = (event, data) => {
    const newCycle: NewCycle = { ...this.props.newCycle };

    switch (data.name) {
      case 'deliveryTime':
      case 'workCategory':
      case 'gender':
      case 'minAge':
      case 'maxAge':
      case 'hourlyRate':
        newCycle[data.name] = data.value;
        break;

      case 'countries': {
        const countries = [...data.value];
        const testEnvsWithoutCountries: TestEnvWithoutCountry[] = newCycle.testEnvs.map((el) => {
          const testEnv = { ...el };
          delete testEnv.country;

          return testEnv;
        });

        let testEnvs: Set<TestEnv> = new Set();

        if (countries.includes('All')) {
          newCycle['countries'] = ['All'];

          testEnvs = newCycle.testEnvs.reduce((result, el) => {
            result.add({
              ...el,
              country: 'All',
            });

            return result;
          }, testEnvs);
        } else {
          if (countries.includes('All')) {
            countries.splice(countries.indexOf('All'), 1);
          }

          if (countries.length) {
            for (const country of countries) {
              const testEnvsForCountry = testEnvsWithoutCountries.map((el) => {
                return {
                  ...el,
                  country,
                };
              });

              for (const el of testEnvsForCountry) {
                testEnvs.add(el);
              }
            }
          } else {
            for (const el of newCycle.testEnvs) {
              testEnvs.add({
                ...el,
                country: 'All',
              });
            }
          }
        }

        newCycle.countries = countries;
        const testEnvsArr = Array.from(testEnvs);
        newCycle.testEnvs = testEnvsArr.flat();

        break;
      }

      case 'useFavoriteTesters':
        newCycle['useFavoriteTesters'] = data.checked;
        break;

      default:
        break;
    }
    const newCycleJson = JSON.stringify(newCycle);
    localStorage.setItem('newCycle', newCycleJson);
    this.props.updateCycle(newCycle);
  };

  recalculatePlatformUrls = () => {
    const platformIdsFromPlatforms = this.props.newCycle.testEnvs
      .filter((el) => el.type === 'Platform')
      .map((el) => el.platformId);

    const platformIdsFromNonPlatforms = this.props.newCycle.testEnvs
      .filter((el) => el.type !== 'Platform')
      .map((el) => el.platformId);

    const uniquePlatformIds = new Set([
      ...platformIdsFromPlatforms,
      ...platformIdsFromNonPlatforms,
    ]);

    const platformUrls = Array.from(uniquePlatformIds).map((el) => ({
      text: getPlatformPrettyName(el),
      key: el,
      value: el,
    }));

    this.props.updateCycle({ platformUrls });
  };

  onPlatformEnvsChange = (event, data) => {
    const newCycle: NewCycle = { ...this.props.newCycle };
    const countries: string[] = newCycle.countries.length ? newCycle.countries : ['All'];

    const platformEnvs: TestEnv[] = data.value
      .map((el) => {
        const platformEnvsWithCountries: TestEnv[] = [];

        for (const country of countries) {
          platformEnvsWithCountries.push({
            platformId: DevicePlatform[el],
            name: getPlatformPrettyName(el),
            type: 'Platform',
            country,
            requiredTesters: 3,
          });
        }

        return platformEnvsWithCountries;
      })
      .flat();

    const platformIds = platformEnvs.reduce((result, el) => {
      result.add(el.platformId);
      return result;
    }, new Set<DevicePlatform>());

    const platformIdsArr = Array.from(platformIds);
    newCycle.platformUrls = platformIdsArr.map((el) => ({
      platformId: el,
      url: '',
    }));

    const nonPlatformEnvs = newCycle.testEnvs.filter((el) => {
      if (el.type === 'Platform') {
        return false;
      }

      if (!platformIds.has(el.platformId)) {
        return false;
      }

      return true;
    });

    newCycle.testEnvs = [...platformEnvs, ...nonPlatformEnvs];
    const newCycleJson = JSON.stringify(newCycle);
    localStorage.setItem('newCycle', newCycleJson);
    this.props.updateCycle(newCycle);
  };

  onNonPlatformEnvsChange = (event, data) => {
    const newCycle: NewCycle = { ...this.props.newCycle };
    const countries: string[] = newCycle.countries.length ? newCycle.countries : ['All'];

    const nonPlatformEnvPlatforms = new Set();

    const nonPlatformEnvs = data.value
      .map((el: string): MobileDevice | DesktopBrowser => {
        const parsed = el.split('_');
        const type = parsed[0];
        const id = parseInt(parsed[1]);

        const nonPlatformEnvs: any = [];

        for (const country of countries) {
          switch (type) {
            case 'MobileDevice': {
              const model = this.props.devices.models.find((model) => model.id === id);
              nonPlatformEnvPlatforms.add(model.platformId);

              nonPlatformEnvs.push({
                platformId: model.platformId,
                name: model.name,
                type,
                country,
                requiredTesters: 3,
                vendorId: model.manufacturerId,
                modelId: id,
              });
              break;
            }

            case 'DesktopBrowser': {
              const browser = this.props.browsers.names.find((browser) => browser.id === id);
              nonPlatformEnvPlatforms.add(DevicePlatform.Desktop);

              nonPlatformEnvs.push({
                platformId: DevicePlatform.Desktop,
                type,
                country,
                requiredTesters: 3,
                name: browser.name,
                browserId: id,
              });
              break;
            }

            default:
              throw new Error(`Unknown TestingEnvironmentType ${type}`);
          }
        }

        return nonPlatformEnvs;
      })
      .flat();

    // TODO handle situation when we need delete non-platform-env and should add platform
    const platformEnvs = newCycle.testEnvs.filter(
      (el) => el.type === 'Platform' && !nonPlatformEnvPlatforms.has(el.platformId)
    );

    newCycle.testEnvs = [...platformEnvs, ...nonPlatformEnvs];

    const newCycleJson = JSON.stringify(newCycle);
    localStorage.setItem('newCycle', newCycleJson);
    this.props.updateCycle(newCycle);
  };

  onNextStep = () => {
    this.props.nextStep(this.props.newCycle);
  };

  onTestersPagination = async (event, data) => {
    await this.props.listPotentialTesters(this.props.newCycle, data.activePage);
    this.props.paginateTesters(data.activePage);
  };

  render() {
    const newCycle = this.props.newCycle;

    const genderAgeBlockVisible =
      newCycle.workCategory === WorkCategory.FeedbackAndRating ? 'visible' : '';

    const platformValues = newCycle.platformUrls.map((el) => el.platformId);

    const nonPlatformEnvIds: DevicePlatform[] = newCycle.testEnvs
      .filter((el) => el.type !== 'Platform')
      .map((el: MobileDevice | DesktopBrowser) => {
        if (isMobileDevice(el)) {
          return `${el.type}_${el.modelId}`;
        } else {
          return `${el.type}_${el.browserId}`;
        }
      });

    return (
      <div className="cycle-step cycle-step1">
        <aside>
          <div>
            <h3>Delivery Time</h3>
            <label>
              <Select
                name="deliveryTime"
                placeholder="Select delivery time"
                value={this.props.newCycle.deliveryTime}
                options={deliveryTime}
                onChange={this.onChange}
                onOpen={this.resetErrorStateForInput}
                error={this.state.errors.includes('deliveryTime')}
              />
            </label>
          </div>
          <div>
            <h3>Required Work</h3>
            <label>
              <Select
                name="workCategory"
                placeholder="Select category of work"
                value={this.props.newCycle.workCategory}
                options={
                  this.props.newCycle.workCategory === WorkCategory.FindBugsTestPlan
                    ? stepOneTestPlanWorkCategories
                    : stepOneDefaultWorkCategories
                }
                onChange={this.onChange}
                onOpen={this.resetErrorStateForInput}
                error={this.state.errors.includes('workCategory')}
              />
            </label>
          </div>

          <div className={`optional-block ${genderAgeBlockVisible}`}>
            <h3>Gender</h3>
            <label>
              <Select
                name="gender"
                placeholder="Select gender"
                value={this.props.newCycle.gender}
                options={genders}
                onChange={this.onChange}
                onOpen={this.resetErrorStateForInput}
                error={this.state.errors.includes('gender')}
              />
            </label>

            <div className="age-block">
              <h3>Age</h3>
              <label>
                <Input
                  type="number"
                  name="minAge"
                  placeholder="From 16"
                  value={this.props.newCycle.minAge || ''}
                  error={this.state.errors.includes('minAge')}
                  onChange={this.onChange}
                />
              </label>
              <label>
                <Input
                  type="number"
                  name="maxAge"
                  placeholder="To 120"
                  value={this.props.newCycle.maxAge || ''}
                  error={this.state.errors.includes('maxAge')}
                  onChange={this.onChange}
                />
              </label>
            </div>
          </div>

          <div>
            <h3>Use your favourite testers?</h3>
            <label>
              <Checkbox
                name="useFavoriteTesters"
                label={this.props.newCycle.useFavoriteTesters ? 'Yes' : 'No'}
                checked={this.props.newCycle.useFavoriteTesters}
                toggle
                onChange={this.onChange}
              />
            </label>
          </div>
          <div>
            <h3>Device Platforms</h3>
            <label>
              <Select
                name="platformEnvs"
                value={platformValues}
                placeholder="Select a platform"
                options={platformOptions}
                onChange={this.onPlatformEnvsChange}
                onOpen={this.resetErrorStateForInput}
                error={this.state.errors.includes('platformIds')}
                multiple
              />
            </label>
          </div>
          <div>
            <h3>Device/Browser Details</h3>
            <Select
              name="nonPlatformEnvs"
              value={nonPlatformEnvIds}
              className="browser-details"
              placeholder="Most common devices"
              options={this.state.modelOptions}
              onChange={this.onNonPlatformEnvsChange}
              onOpen={this.resetErrorStateForInput}
              error={this.state.errors.includes('modelIds')}
              search
              multiple
            />
          </div>
          <div>
            <h3>Tester Location</h3>
            <label>
              <Select
                name="countries"
                value={this.props.newCycle.countries}
                placeholder="Select tester location"
                options={allCountryCodes}
                onChange={this.onChange}
                onOpen={this.resetErrorStateForInput}
                error={this.state.errors.includes('countries')}
                search={searchFromStart}
                multiple
              />
            </label>
          </div>
          <div>
            <h3>Hourly Rate</h3>
            <label>
              <Select
                name="hourlyRate"
                value={
                  this.props.newCycle.hourlyRate >= 15 ? this.props.newCycle.hourlyRate : undefined
                }
                placeholder="Select your hourly rate (15-80 USD)"
                options={hourlyRates}
                onChange={this.onChange}
                onOpen={this.resetErrorStateForInput}
                error={this.state.errors.includes('hourlyRate')}
              />
            </label>
          </div>
          <Button
            icon
            className="next-step-button"
            size="huge"
            onClick={this.onNextStep}
            labelPosition="right"
          >
            <Icon name="arrow right" />
            Next step
          </Button>
        </aside>

        <TesterList
          testers={this.props.potentialTesters}
          total={this.props.potentialTestersTotal}
          page={this.props.newCycleTestersPage}
          onPageChange={this.onTestersPagination}
        />
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StepOne));
