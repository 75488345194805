import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import Chip from '@mui/material/Chip';
import { useSelector, useDispatch } from 'react-redux';
import Table from '@mui/material/Table';
import {BugSeverityLabel, BugStatusLabel} from './BugSeverity';
import Toolbar from '@mui/material/Toolbar';
import { grey } from '@mui/material/colors';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import React, { useState, useEffect } from 'react';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import { yellow, green, blue } from '@mui/material/colors';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
//import Button from '../../../../elements/Button';
import {Box, Button} from '@mui/material';
import GetApp from '@mui/icons-material/GetApp';
import exportFromJSON from 'export-from-json';
import { Select as SmUISelect, Button as SmButton, Label } from 'semantic-ui-react';

import BugDrawer from './BugDrawer';
import { RootState } from '../../../../redux/store';
import { BugStatus } from '../../../../types/BugStatus';
import { BugSeverity } from '../../../../types/BugSeverity';
import chatService from '../../../../services/chatService';
import { createDeviceOsField, getApprovedDevicePrettyName } from '../../../../services/deviceService';
import { getCycleBugsByStatus, getBugStatus } from '../../../../services/bugsService';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import {bugStatusOptions} from '../../../../services/bugsService';
import config from '../../../../config';
import { TesterStatusInCycle } from '../../../../types/TesterStatusInCycle';
import TesterReviewModal from '../../../../components/Account/Customer/Cycle/TesterReviewModal';
import actions from '../../../../redux/actions';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { UserRole } from '../../../../types/UserRole';

type Order = 'asc' | 'desc';

interface Data {
    bug_id: string;
    name: string;
    status: string;
    severity: BugSeverity | 'n_a';
    // type: string;
    device_os: string;
    hasAttachment: boolean;
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

interface IBugsTable {
    cycleId: string;
    order: Order;
    orderBy: string;
    classes: ReturnType<typeof useStyles>;
    isMobile: boolean;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
}

const StyledTableRow = withStyles(() => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: grey[50],
      },
    },
  }),
)(TableRow);

const StyledTableHeader = withStyles(() => ({
    root: {
        borderBottom: 'none',
        fontSize: 12,
    },
  }),
)(TableCell);

const StyledTableCell = withStyles(() => ({
    root: {
        maxWidth: 200,
        cursor: 'pointer',
        borderBottom: 'none',
        transition: 'opaccity 0.4s',
        '&:hover': {
          opacity: 0.9,
        },
    },
  }),
)(TableCell);

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) { return -1; }
  if (b[orderBy] > a[orderBy]) { return 1; }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells: HeadCell[] = [
  { id: 'bug_id', numeric: false, disablePadding: true, label: 'Bug ID' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'severity', numeric: false, disablePadding: false, label: 'Severity' },
  // { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
  { id: 'device_os', numeric: false, disablePadding: false, label: 'Device & OS' },
];

const BugsTableHead: React.FC<IBugsTable> = ({ classes, cycleId, order, orderBy, isMobile, onRequestSort }) => {
  const { cycleBugs } = useSelector((state: RootState) => state);

  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  if (isEmpty(cycleBugs?.byCycleId[cycleId])) {
    return null;
  }
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableHeader
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id && (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              )}
            </TableSortLabel>
          </StyledTableHeader>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) => ({
    root: {
        //border: "1px solid red",
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        //paddingLeft: theme.spacing(2),
        //paddingRight: theme.spacing(1),
    },
    leftPanel:{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    reviewControls:{   
      border: '1px solid #ccc',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
      borderRadius: '4px',
      display: 'flex',
      justifyContent: 'center',
      margin: '0.5rem 0 1rem',
      padding: '0.75rem',
      position: 'relative',
      maxWidth: '360px',
      '&:after': {
        borderTop: '1px solid #ccc',
        borderLeft: '1px solid #ccc',
        background: '#fff',
        content: "''",
        height: '12px',
        position: 'absolute',
        top: '-7px',
        transform: 'rotate(45deg)',
        left: '90%',
        width: '12px',
      },      
    },    
    reviewControlsButton: {
      flex: '1 0 auto',
    },
  }),
);

const BugsTableToolbar: React.FC<{ cycleId: string, filterByTesterId: number, setFilterByTesterId: any }> = ({ cycleId, filterByTesterId, setFilterByTesterId }) => {
  const classes = useToolbarStyles();
  const dispatch = useDispatch();

  const { cycleBugs } = useSelector((state: RootState) => state);
  const { cycleTesters } = useSelector((state: RootState) => state);
  const { favoriteTesters } = useSelector((state: RootState) => state);
  const { user } = useSelector((state: RootState) => state);
  const {role } = user;

  const currentCycleBugs = cycleBugs?.byCycleId[cycleId] || [];
  const currentCycleTesters = cycleTesters?.byCycleId[cycleId] || [];
  const unreadBugs = getCycleBugsByStatus(cycleBugs, cycleId, BugStatus.New);

  const currentTester = currentCycleTesters.find( (tester) => tester.id === filterByTesterId );
  const reviewButtonsVisible = currentTester && 
                          ( currentTester.statusInCycle == TesterStatusInCycle.Finished ||  currentTester.statusInCycle === TesterStatusInCycle.AwaitingReview||  currentTester.statusInCycle === TesterStatusInCycle.Approved );
  const testerIsFavorite = filterByTesterId in favoriteTesters.byId;  

  const filterByTesterOptions: {
    text: string;
    key: number;
    value: number;
    content?: any;
  }[] = [
    {
      text: 'All testers',
      key: 0,
      value: 0,
      content: null,
    },
  ];

  for (const tester of currentCycleTesters) {
    const firstName = tester.firstName || 'Unknown tester';
    const lastName = tester.lastName || 'Unknown tester';
    const statusInCycle = tester.statusInCycle || TesterStatusInCycle.Approved;
    const testerName = `${firstName} ${lastName}`;
    const testerBugsCount = currentCycleBugs.filter((el) => el.testerId === tester.id).length;
    filterByTesterOptions.push({
      text: testerName,
      key: tester.id,
      value: tester.id,
      content: (
        <Box className={classes.root}>
            <Typography variant="body2" color="textPrimary">{testerName}</Typography>
            <Box>
            {statusInCycle === TesterStatusInCycle.AwaitingReview && (
              <Label basic horizontal size="tiny" color="green">
                finished
              </Label>
            )}
            <Label basic horizontal size="tiny" color="grey">
              {testerBugsCount} bugs
            </Label>
            </Box>
        </Box>
      ),
    });

    filterByTesterOptions.sort((a, b) => {
      const testerA = currentCycleTesters.find(
        (tester) => tester.id === a.value
      );
      const testerB = currentCycleTesters.find(
        (tester) => tester.id === b.value
      );

      if (!testerA || !testerB) {
        return 0;
      }

      if (testerA.statusInCycle === TesterStatusInCycle.AwaitingReview) {
        if (testerB.statusInCycle === TesterStatusInCycle.AwaitingReview) {
          return 0;
        } else {
          return -1;
        }
      } else {
        if (testerB.statusInCycle === TesterStatusInCycle.AwaitingReview) {
          return 1;
        } else {
          return 0;
        }
      }
    });
  }

  const onExport = (event) => {    
    let exportData;
    
    exportData = currentCycleBugs.map( (bug) => {
      const tester = currentCycleTesters.find((tester) => tester.id === bug.testerId);
      const deviceName = getApprovedDevicePrettyName(bug.device);
      const attachments = [...bug.imageFilePaths, ...bug.videoFilePaths];

      const result = {
        'Bug ID': bug.publicId,
        'Step ID': bug.testPlanStepId,
        Created: bug.createdAt,
        Author: `${tester.firstName} ${tester.lastName}`,
        Location: tester.country,
        'Browser/Device': deviceName,
        'PC type': tester.desktopTypes.length ? tester.desktopTypes.length[0] : '',
        'Bug topic': bug.topic,
        'Bug status': bug.status,
        'Steps to reproduce': bug.stepsToReproduce,
        'Expected result': bug.expectedResult,
        'Actual result': bug.actualResult,
        Severity: bug.severity,
        Comment: bug.comment,
      };

    if (attachments.length) {
      for (let i = 0; i < attachments.length; i++) {
        const url = new URL(attachments[i], config.apiEndpoint);
        result[`Attachment ${i + 1}`] = `<a href="${url.href}">Attachment ${i + 1}</a>`;
      }
    }
    return result;
    });    

  if (!exportData || !exportData.length) {
    return;
  }

  exportFromJSON({
    data: exportData,
    fileName: 'feedbackReport',
    exportType: 'xls',
  });
};

  const onTesterSelChanged = (event, data) => {
    setFilterByTesterId(data.value);
  }

  const onToggleFavorite = (event, data) => {        
    if (favoriteTesters.byId[filterByTesterId]) {
      dispatch(actions.removeTesterFromFavorites(filterByTesterId))
    } else {
      dispatch(actions.addTesterToFavorites(filterByTesterId))      
    }
  };

  if( currentCycleBugs.length > 0 ) {
    return (
      <React.Fragment>
        <SmUISelect style={{width:"25%"}}
                name="filterByTester"
                placeholder="Filter by tester"
                className="testers-filter"
                options={filterByTesterOptions}
                onChange={onTesterSelChanged}
                value={filterByTesterId}
                search
                fluid
            />
            {reviewButtonsVisible && (
                <div className={classes.reviewControls}>
                  <SmButton
                    className={classes.reviewControlsButton}
                    primary={!testerIsFavorite} basic={testerIsFavorite}
                    size="tiny"
                    content={testerIsFavorite ? 'Remove from favorites' : 'Add to favorites'}
                    disabled={role === UserRole.Viewer}
                    onClick={onToggleFavorite}
                  />
                  <TesterReviewModal cycleId={cycleId} testerId={filterByTesterId} />
                </div>
              )}
        <Toolbar className={classes.root}>
          <Box className={classes.leftPanel}>
            <Typography variant="h6" component="h3">
                Open Bugs
            </Typography>
            <Typography variant="body2" component="h4" color="textSecondary" style={{marginLeft:"10px"}}>
                {unreadBugs.length} unread bugs
            </Typography>
          </Box>          
            <Button
                style={{ borderRadius: 6, textTransform: "unset" }}
                variant="outlined"
                color="primary"
                size="large"            
                onClick={onExport}
                startIcon={<GetApp />}>
              Export</Button>        
        </Toolbar>      
      </React.Fragment>
    );
  }
  else{
    return null;
  }
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
      width: '100%',
      marginBottom: 12,
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      top: 20,
      width: 1,
      border: 0,
      height: 1,
      margin: -1,
      padding: 0,
      overflow: 'hidden',
      position: 'absolute',
      clip: 'rect(0 0 0 0)',
    },
    QuestionAnswer: {
      opacity: 0.82,
      cursor: 'pointer',
      transition: 'opacity 0.4s',
      '&:hover': {
        opacity: 1,
      },
    },    
}));

const BugsTable: React.FC<{ cycleId: string }> = ({ cycleId }) => {
  const classes = useStyles();

  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof Data>('bug_id');
  const [openedDrawerTab, setOpenedDrawerTab] = useState<number>(0);
  const [activeBugId, setActiveBugId] = useState<number | null>(null);
  const [unreadCountByChatId, setUnreadCountByChatId] = useState<Record<number, number>>({});
  const [filterByTesterId, setFilterByTesterId] = useState<number>(0);

  const { cycleBugs, resolution } = useSelector((state: RootState) => state);

  const currentCycleBugs = cycleBugs?.byCycleId[cycleId] || [];

  const isMobile = resolution.device === 'mobile' || resolution.device === 'mobile-tablet';

  const chatIdByBugId = (currentCycleBugs.filter(cb => !!cb?.chatId) || []).reduce((acc, bug) => ({ ...acc, [bug.id]: bug.chatId }), {});  

  const filterBugsByTester = (allBugs) => {
    if( filterByTesterId ){
      return allBugs.filter((bug) => bug.testerId === filterByTesterId);
    }
    return allBugs;
  }

  const bugs = filterBugsByTester( currentCycleBugs ).map((bug) => ({
    bug_id: bug.id,
    name: bug.topic,
    status: bug.status,
    type: bug.type,
    severity: bug.severity,
    device_os: getApprovedDevicePrettyName(bug.device),
    hasAttachment: (bug.imageFilePaths.length + bug.videoFilePaths.length) > 0 ? true : false,
  }));

  const handleRequestSort = (_: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }; 

  const changeUnreadCountByChatId = async () => {
      const unreadByChatId = await chatService.getBugUndreadMessagesCount(values(chatIdByBugId));
      setUnreadCountByChatId(unreadByChatId);
  };

  useEffect(function onChatIdByBugIdChanged() {
    if (!isEmpty(chatIdByBugId) && isEmpty(unreadCountByChatId)) {
      changeUnreadCountByChatId();
    }
  }, [chatIdByBugId, unreadCountByChatId]);

  if( currentCycleBugs.length == 0 ){
    return null;
  }

  else {
    return (
    <div className={classes.root}>
      <BugDrawer 
        cycleId={cycleId} 
        bugId={activeBugId}
        openedTab={openedDrawerTab}
        setOpenedTab={setOpenedDrawerTab}
        onClose={() => {
          setActiveBugId(null);
          setOpenedDrawerTab(0);
        }} 
      /> 
    
      {!isMobile && 
        <BugsTableToolbar cycleId={cycleId} filterByTesterId={filterByTesterId} setFilterByTesterId={setFilterByTesterId}/>
      }
      {isMobile &&
        <Typography variant="h6" component="h3">
          Open Bugs
        </Typography>
      }

      <TableContainer>
          <Table
              size="medium"
              className={classes.table}
              aria-label="enhanced table"
              aria-labelledby="tableTitle"
          >
              <BugsTableHead
                  order={order}
                  cycleId={cycleId}
                  orderBy={orderBy}
                  classes={classes}
                  isMobile
                  onRequestSort={handleRequestSort}
              />
              <TableBody>
                  {stableSort(bugs, getComparator(order, orderBy)).map((bug, index) => (
                      <StyledTableRow 
                        hover tabIndex={-1} 
                        key={bug.bug_id} 
                        onClick={() => setActiveBugId(+bug.bug_id)}
                      >
                          <StyledTableCell 
                            scope="row" 
                            component="th" 
                            id={`enhanced-table-checkbox-${index}`}
                          >
                              {bug.bug_id}
                          </StyledTableCell>
                          <StyledTableCell>{bug.name}</StyledTableCell>
                          <StyledTableCell>
                            <BugStatusLabel status={bug.status} />
                          </StyledTableCell>
                          <StyledTableCell>
                            <BugSeverityLabel severity={bug.severity as BugSeverity | 'n_a'} />
                          </StyledTableCell>                          
                          <StyledTableCell>{bug.device_os}</StyledTableCell>
                          {!isMobile &&
                            <React.Fragment>
                              <StyledTableCell>
                                <QuestionAnswerIcon  
                                  className={classes.QuestionAnswer}
                                  color={(unreadCountByChatId[chatIdByBugId[bug.bug_id]] || 0) > 0 ? 'primary' : 'disabled'}
                                  onClick={async () => {
                                    setOpenedDrawerTab(1);
                                    setActiveBugId(+bug.bug_id);
                                    const chatId = chatIdByBugId[bug.bug_id];
                                    if ((unreadCountByChatId[chatId] || 0) > 0) {
                                      await chatService.setBugChatAsRead(chatId);
                                      if (!isEmpty(chatIdByBugId)) {
                                        changeUnreadCountByChatId();
                                      }
                                    }
                                  }}
                                />
                              </StyledTableCell>
                              <StyledTableCell>
                                {bug.hasAttachment && (<AttachmentIcon color='primary'/>) }
                              </StyledTableCell>
                            </React.Fragment>
                        }
                      </StyledTableRow>
                  ))}
              </TableBody>
          </Table>
      </TableContainer> 
    </div>
  );
}
};

export default BugsTable;
