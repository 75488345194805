import { BugSeverity } from '../../types/BugSeverity';

export default [
  {
    text: 'Low',
    key: BugSeverity.Low,
    value: BugSeverity.Low,
  },
  {
    text: 'Medium',
    key: BugSeverity.Medium,
    value: BugSeverity.Medium,
  },
  {
    text: 'High',
    key: BugSeverity.High,
    value: BugSeverity.High,
  },
  {
    text: 'Critical',
    key: BugSeverity.Critical,
    value: BugSeverity.Critical,
  },
];
