import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import CycleDetailsSidemenu from './Sidemenu';
import { TestPlanTable } from './TesterTestPlanTable';
import { TestPlanTableViewOnly } from './TesterTestPlanTableViewOnly';
import { TesterStatusInCycle } from '../../../../types/TesterStatusInCycle';
import actions from '../../../../redux/actions';
import cycleActions from '../../../../redux/actions/cycleActions';

const mapStateToProps = (state) => {
  return {
    user: state.user,
    cycles: state.cycles,
    newBug: state.newBug,
    device: state.resolution.device,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTestPlanStep: (cycleId, testPlanStepId, status) =>
      dispatch(cycleActions.updateTestPlanStep(cycleId, testPlanStepId, status)),
    updateNewBug: (newBug) => dispatch(actions.updateNewBug(newBug)),
  };
};

class TesterTestPlan extends Component<any> {
  public state: any;

  constructor(props) {
    super(props);

    this.state = {
      cycleId: +props.match.params.id,
    };
  }

  /* This method for "passed" and "blocked" steps only, because they don't require to open new bug */
  onTestPlanStepUpdate = (event, data) => {
    this.props.updateTestPlanStep(this.state.cycleId, data['data-id'], data['data-status']);
  };

  onTestPlanStepFail = (event, data) => {
    const cycle = this.props.cycles.find((el) => el.id === this.state.cycleId);
    const testPlanStep = cycle.testPlan.find((step) => step.id === data['data-id']);

    this.props.updateNewBug({
      testPlanStepId: data['data-id'],
      stepsToReproduce: testPlanStep.description,
      expectedResult: testPlanStep.expectedResult,
    });

    this.props.history.push(`/account/tester/cycle/${cycle.id}/newbug?testPlan=true`);
  };

  render() {
    const cycle = this.props.cycles.find((el) => el.id === this.state.cycleId);

    if (!cycle || !cycle.testPlan) {
      return null;
    }

    const testPlanEditable = [
      TesterStatusInCycle.Approved,
      TesterStatusInCycle.AwaitingReview,
      TesterStatusInCycle.Finished,
    ].includes(cycle.testerStatus);

    const isMobile = this.props.device === 'mobile' || this.props.device === 'mobile-tablet';

    if( isMobile ) {
      return(
        <div>
          <CycleDetailsSidemenu cycleId={cycle.id} />
          <div className="tester-test-plan" style={{marginLeft: 5, marginRight: 5}}>
            <h2>Test plan</h2>

            {testPlanEditable && (
              <Button
                icon
                className="new-cycle"
                positive
                as={Link}
                to="newbug"
                labelPosition="right"
              >
                <Icon name="plus" />
                Add bug without step
              </Button>
            )}

            {testPlanEditable ? (
              <TestPlanTable
                testPlan={cycle.testPlan}
                testPlanResults={cycle.testPlanResults}
                onTestPlanStepUpdate={this.onTestPlanStepUpdate}
                onTestPlanStepFail={this.onTestPlanStepFail}
              />
            ) : (
              <TestPlanTableViewOnly testPlan={cycle.testPlan} />
            )}
          </div>
        </div>      
      )
    }
    return (
      <div className="account-tester page">
        <div className="heading">
          <h1>Cycle Details</h1>
        </div>

        <div className="inner" style={{ display: 'flex' }}>
          <CycleDetailsSidemenu cycleId={cycle.id} />
          <main className="tester-test-plan">
            <h2>Test plan</h2>

            {testPlanEditable && (
              <Button
                icon
                className="new-cycle"
                positive
                as={Link}
                to="newbug"
                labelPosition="right"
              >
                <Icon name="plus" />
                Add bug without step
              </Button>
            )}

            {testPlanEditable ? (
              <TestPlanTable
                testPlan={cycle.testPlan}
                testPlanResults={cycle.testPlanResults}
                onTestPlanStepUpdate={this.onTestPlanStepUpdate}
                onTestPlanStepFail={this.onTestPlanStepFail}
              />
            ) : (
              <TestPlanTableViewOnly testPlan={cycle.testPlan} />
            )}
          </main>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TesterTestPlan));
