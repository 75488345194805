import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DiscountIcon from '@mui/icons-material/Discount';
import { Box, Chip, Grid, IconButton, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { Input } from 'semantic-ui-react';
import wizardActions from '../../redux/actions/wizardActions';
import { NewWizardCycle } from '../../types/NewWizardCycle';
import { OperationStatus } from '../../types/OperationStatus';
import { UserRole } from '../../types/UserRole';
import { PayoutRatesAndTestersQuota, QuestionTimeCategory, SpecificationType } from '../../types/WizardCategoriesTypes';
import { WorkCategory } from '../../types/WorkCategory';
import SharedStyle from '../Wizard/InnerForms/SharedStyle';

const styles = (theme) => createStyles({
    root: {
        marginTop: SharedStyle.innerContent.marginTop,
        marginLeft: SharedStyle.innerContent.marginLeft,
    },
    rightSection: {
        marginLeft: SharedStyle.innerContent.marginLeft,
    },
    SpreadBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
    },
    devider: {
        borderRight: '1px solid #DEEEFF',
    },

    AttributeName: {
        fontSize: '14px',
        color: theme.palette.common.black,
        fontWeight: 'normal',
        letterSpacing: '0.1px',
    },

    AttributeNameBold: {
        fontSize: '14px',
        color: theme.palette.common.black,
        fontWeight: 'bold',
        letterSpacing: '0.1px',
    },
    checkout: {
        position: 'absolute',
        bottom: '130px',
        right: '30px',
        width: '40%',
    },
    strikedText: {
        /* fontSize: '18px', */
        /* fontStyle: 'italic', */
        textDecoration: 'line-through',
    },
    customLabel: {
        borderRadius: 5,
        opacity: 0.75,
    }

});


const mapStateToProps = (store) => {
    return {
        newCycle: store.newWizardCycle as NewWizardCycle,
        user: store.user,
        cycleCreateStatus: store.cycleCreateStatus,
        couponFetchStatus: store.couponFetchStatus,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        updateCycle: (data) => dispatch(wizardActions.updateCycle(data)),
        submitCycle: (data) => dispatch(wizardActions.submitCycle(data)),
        fetchCoupon: (couponCode) => dispatch(wizardActions.fetchCoupon(couponCode)),
    };
};

class SummaryForm extends Component<any> {
    public state: any;

    constructor(props) {
        super(props);

        this.state = {
            couponChecked: false,
        }
    }

    componentDidMount() {
    };

    componentDidUpdate(prevProps) {
        if (this.props.couponFetchStatus.status !== prevProps.couponFetchStatus.status) {
            if (this.props.couponFetchStatus.status === OperationStatus.fail) {
                toast.error(this.props.couponFetchStatus.errorMessage);
            }
            else if (this.props.couponFetchStatus.status === OperationStatus.success) {
                ;
                //Need to tie the coupon id from the response!                
            }
        }
    }

    timeTxtFromDeliveryTime = () => {
        let deliveryTimeTxt
        switch (this.props.newCycle.deliveryTime) {
            case '24hours': deliveryTimeTxt = '24 hours'; break;
            case '3days': deliveryTimeTxt = '3 days'; break;
            case '7days': deliveryTimeTxt = '7 days'; break;
        }
        return deliveryTimeTxt;
    }

    timeTxtFromQuestionTime = () => {
        let QuestionTimeTxt
        switch (this.props.newCycle.questionsTime) {
            case '24hours': QuestionTimeTxt = '24 hours'; break;
            case '2days': QuestionTimeTxt = '2 days'; break;
            case '4days': QuestionTimeTxt = '4 days'; break;
        }
        return QuestionTimeTxt;
    }

    retrieveExtraPayoutPerHours = (hoursPerTester) => {
        switch (hoursPerTester) {
            case 2: { return PayoutRatesAndTestersQuota._2hrsTestingExtra; }
            case 3: { return PayoutRatesAndTestersQuota._3hrsTestingExtra; }
            case 4: { return PayoutRatesAndTestersQuota._4hrsTestingExtra; }
        }
        return 0;
    }

    retrieveExtraPayoutPerQtime = (questionsTime) => {
        switch (questionsTime) {
            case QuestionTimeCategory._2days: { return PayoutRatesAndTestersQuota._2daysQtimeExtra; }
            case QuestionTimeCategory._4days: { return PayoutRatesAndTestersQuota._4daysQtimeExtra; }
        }
        return 0;
    }


    calcTotalAmountPerTester = () => {
        let hourlyRate = PayoutRatesAndTestersQuota.baseHrlyRate;
        const newCycle = this.props.newCycle as NewWizardCycle;

        if (newCycle.workCategory === WorkCategory.FindBugsTestPlan) {
            hourlyRate += PayoutRatesAndTestersQuota.testPlanExtra;
        }

        hourlyRate += this.retrieveExtraPayoutPerHours(newCycle.hoursPerTester);
        hourlyRate += this.retrieveExtraPayoutPerQtime(newCycle.questionsTime);

        return hourlyRate;

    }

    combineDurationTxt = () => {
        const newCycle = this.props.newCycle as NewWizardCycle;
        let txt = `${newCycle.hoursPerTester}` + (newCycle.hoursPerTester > 1 ? ' hours' : ' hour') + ' duration';

        let hoursDurationExtra = this.retrieveExtraPayoutPerHours(newCycle.hoursPerTester);
        if (hoursDurationExtra) {
            txt += ` (+$${hoursDurationExtra})`;
        }

        return txt;
    }

    combinePeriodTxt = () => {
        const newCycle = this.props.newCycle as NewWizardCycle;
        let txt = this.timeTxtFromDeliveryTime() + ' overall time, ' + this.timeTxtFromQuestionTime() + ' Questions time';
        let qTimeExtra = this.retrieveExtraPayoutPerQtime(newCycle.questionsTime)
        if (qTimeExtra) {
            txt += ` (+$${qTimeExtra})`;
        }

        return txt;
    }

    calcCyclePrice = () => {
        const newCycle = this.props.newCycle as NewWizardCycle;

        const numberOfTesters = newCycle.testEnvs.reduce((result, el) => {
            result += el.requiredTesters;
            return result;
        }, 0);

        const price = numberOfTesters * newCycle.hoursPerTester * newCycle.hourlyRate;

        return price;
    }

    onChangeCouponCode = (event, data) => {
        const newCycle = {
            ...this.props.newCycle,
            couponDetails: {
                ...this.props.newCycle.couponDetails,
                code: data.value
            }
        };
        return this.props.updateCycle(newCycle);
    };

    useCoupon = () => {
        this.props.fetchCoupon(this.props.newCycle.couponDetails.code);
    }

    removeCoupon = () => {
        const newCycle = {
            ...this.props.newCycle,
            couponDetails: {
                id: -1,
                code: '',
                discount: 0
            }
        };
        return this.props.updateCycle(newCycle);
    }

    render() {
        const { classes } = this.props;
        const newCycle = this.props.newCycle as NewWizardCycle;
        const typeTxt = (newCycle.workCategory === WorkCategory.FindBugsTestPlan) ? `Test plan (+$${PayoutRatesAndTestersQuota.testPlanExtra})` : 'Exploratory';
        const specificationTxt = (newCycle.testSpecification === SpecificationType.Website) ? 'Website' : 'Native app';
        const durationTxt = this.combineDurationTxt();
        const periodTxt = this.combinePeriodTxt();
        const countriesTxt = newCycle.isWorldwide ? (<Typography variant="h4">Worldwide</Typography>) : newCycle.countries.map(c => <Typography variant="h4">{c}</Typography>);
        const platformTxtName = newCycle.mainTestPlatform.name;

        const numberOfTesters = newCycle.testEnvs.reduce((result, el) => {
            result += el.requiredTesters;
            return result;
        }, 0);

        const hourlyRatePerTesterTxt = '$' + this.calcTotalAmountPerTester();

        const totalPrice = parseFloat((this.calcCyclePrice()).toFixed(2));                                       //Total price of the test
        const totalPriceUsingCoupon = parseFloat((totalPrice * (1 - (newCycle.couponDetails.discount / 100.0))).toFixed(2));    //Actual Total using coupon
        let userBalance: number = 0;
        if (this.props.user.role != UserRole.Anonymous) {
            userBalance = parseFloat((this.props.user.balance).toFixed(2));                            //Player wallet    
        }
        const amountWithoutVat: number = parseFloat((totalPriceUsingCoupon - userBalance).toFixed(2));                      //Payment amountWithoutVat
        const isPaymentRequired = amountWithoutVat > 0;                           //isPaymentRequired
        const discountStr = `Valid coupon, ${newCycle.couponDetails.discount}% off!`;

        const isWithVat = (this.props.user.country === 'Israel');                 //isWithVat
        const vatPercent: number = isWithVat ? 17 : 0;
        const vatAmount: number = parseFloat((amountWithoutVat * vatPercent / 100).toFixed(2));
        const amountIncludingVat: number = parseFloat((amountWithoutVat + vatAmount).toFixed(2)); //Total amount to pay:!

        const userBalanceStr = `$${userBalance}`;
        const totalPriceStr = `$${totalPrice}`;
        const totalPriceUsingCouponStr = `$${totalPriceUsingCoupon}`;
        const amountWithoutVatStr = `$${amountWithoutVat}`;
        const amountIncludingVatStr = `$${amountIncludingVat}`;
        const VatAmountStr = `$${vatAmount}`;

        const couponPreset = newCycle.couponDetails.id < 0;
        const couponSet = newCycle.couponDetails.id > 0;

        return (
            <React.Fragment>

                <Box>
                    <Box style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "left",
                        paddingBottom: 15,
                    }}>
                        <Input
                            required
                            margin="none"
                            style={{ width: "22%" }}
                            size='small'
                            autoComplete='off'
                            placeholder="Have a coupon?"
                            maxLength="17"
                            variant="outlined"
                            name="couponCode"
                            value={newCycle.couponDetails.code}
                            onChange={this.onChangeCouponCode}
                            disabled={couponSet}
                        />
                        {couponPreset ? (
                            <IconButton sx={{ ml: 2 }}
                                color="secondary"
                                disabled={newCycle.couponDetails.code.length != 17 || this.props.couponFetchStatus.status === OperationStatus.processing}
                                onClick={this.useCoupon}
                            >
                                <DiscountIcon />
                            </IconButton>

                        ) : (
                            <IconButton sx={{ ml: 2 }}
                                onClick={this.removeCoupon}
                                color="secondary"
                            >
                                <DeleteForeverIcon />
                            </IconButton>
                        )}
                    </Box>
                    {couponSet &&
                        <Chip label={discountStr} color="success" className={classes.customLabel} style={{ width: "22%" }} />
                    }

                </Box>

                <Grid container style={{ paddingTop: 25 }}>

                    <Grid item xs={6} className={classes.devider}>
                        <Grid container spacing={2}>

                            {/* LEFT PANEL */}

                            <Grid item xs={12}>
                                <Typography align="left" variant="h5">Test Settings</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography className={classes.AttributeName}>Test type</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h4">{typeTxt}</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography className={classes.AttributeName}>Test duration</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h4">{durationTxt}</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography className={classes.AttributeName}>Test period</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h4">{periodTxt}</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography className={classes.AttributeName}>Test locations</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                {countriesTxt}
                            </Grid>

                            <Grid item xs={6}>
                                <Typography className={classes.AttributeNameBold}>Total amount per tester</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h4">{hourlyRatePerTesterTxt}</Typography>
                            </Grid>

                            <Grid item xs={12}></Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={12}></Grid>

                            <Grid item xs={12}>
                                <Typography align="left" variant="h5">My test brief</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography className={classes.AttributeName}>Test specification</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h4">{specificationTxt}</Typography>
                            </Grid>

                        </Grid>
                    </Grid>




                    <Grid item xs={6} className={classes.SpreadBox}>

                        {/* RIGHT PANEL */}
                        <Grid container spacing={2} style={{ paddingLeft: "35px" }}>
                            {/* UPPER BOX */}

                            <Grid item xs={12}>
                                <Typography align="left" variant="h5">Devices & Testers</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography className={classes.AttributeName}>Platform</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h4">{platformTxtName}</Typography>
                            </Grid>

                            {newCycle.isWorldwide &&
                                <React.Fragment>
                                    {newCycle.isSpecificDevices &&
                                        <React.Fragment>
                                            {this.props.newCycle.relevantDevices.map((deviceRow, index) => (
                                                <React.Fragment key={index}>
                                                    <Grid item xs={4}>
                                                        <Typography className={classes.AttributeName}>{deviceRow.device.name}</Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography className={classes.AttributeName}>{deviceRow.country}</Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography variant="h4">{deviceRow.numberOfTesters}</Typography>
                                                    </Grid>
                                                </React.Fragment>
                                            ))
                                            }
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            }

                            {!newCycle.isWorldwide &&
                                <React.Fragment>
                                    <Grid item xs={4}>
                                        <Typography variant="h2">Devices</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="h2">Country</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="h2">#Testers</Typography>
                                    </Grid>

                                    {this.props.newCycle.specificationPerCountry.map((countryRow, index) => (
                                        <React.Fragment key={index}>
                                            <Grid item xs={4}>
                                                <Typography className={classes.AttributeName}>{countryRow.device.name}</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography className={classes.AttributeName}>{countryRow.country}</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="h4">{countryRow.numberOfTesters}</Typography>
                                            </Grid>
                                        </React.Fragment>
                                    ))
                                    }

                                    {newCycle.isSpecificDevices &&
                                        <React.Fragment>
                                            {this.props.newCycle.relevantDevices.map((deviceRow, index) => (
                                                <React.Fragment key={index}>
                                                    <Grid item xs={4}>
                                                        <Typography className={classes.AttributeName}>{deviceRow.device.name}</Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography className={classes.AttributeName}>{deviceRow.country}</Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography variant="h4">{deviceRow.numberOfTesters}</Typography>
                                                    </Grid>
                                                </React.Fragment>
                                            ))
                                            }
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            }

                        </Grid>

                        <Grid container spacing={2} style={{ paddingLeft: "35px", marginTop: "50px" }}>
                            {/* BOTTOM BOX */}

                            {/* Total summary for all cases loggedIn or not */}
                            <Grid item xs={6}>
                                <Typography className={classes.AttributeNameBold}>Amount of testers</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h4">{numberOfTesters}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.AttributeNameBold}>Total per test</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h4">{hourlyRatePerTesterTxt}</Typography>
                            </Grid>
                            {newCycle.couponDetails.id < 0 ? (
                                <React.Fragment>
                                    <Grid item xs={6}>
                                        <Typography className={classes.AttributeNameBold}>Total price</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="h4">{totalPriceStr}</Typography>
                                    </Grid>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Grid item xs={6}>
                                        <Typography className={classes.AttributeNameBold}>Total price*</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="h4">{totalPriceUsingCouponStr}</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography className={classes.strikedText} variant="h4">{totalPriceStr}</Typography>
                                    </Grid>
                                </React.Fragment>
                            )}

                            {/* For logged-in customers */}
                            {(this.props.user.role === UserRole.Customer || this.props.user.role === UserRole.Viewer) &&
                                <React.Fragment>
                                    {/* Users with some balance */}
                                    {userBalance > 0 &&
                                        <React.Fragment>

                                            <Grid item xs={6}>
                                                <Typography className={classes.AttributeNameBold}>Your balance</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="h4">{userBalanceStr}</Typography>
                                            </Grid>

                                            {isPaymentRequired &&
                                                <React.Fragment>
                                                    <Grid item xs={6}>
                                                        <Typography className={classes.AttributeNameBold}>Payment left:</Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h4">{amountWithoutVatStr}</Typography>
                                                    </Grid>

                                                    {/* Payment with Vat*/}
                                                    {isWithVat &&
                                                        <React.Fragment>
                                                            <Grid item xs={6}>
                                                                <Typography className={classes.AttributeNameBold}>Vat (17%)</Typography>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Typography variant="h4">{VatAmountStr}</Typography>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Typography className={classes.AttributeNameBold}>Amount to pay:</Typography>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Typography variant="h4" style={{ textDecoration: 'underline' }}>{amountIncludingVatStr}</Typography>
                                                            </Grid>
                                                        </React.Fragment>
                                                    }
                                                </React.Fragment>
                                            }
                                            {!isPaymentRequired &&
                                                <React.Fragment>
                                                    <Grid item xs={6}>
                                                        <Typography className={classes.AttributeNameBold}>Amount to pay:</Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h4" style={{ textDecoration: 'underline' }}>$0</Typography>
                                                    </Grid>
                                                </React.Fragment>
                                            }

                                        </React.Fragment>
                                    }
                                    {/* Users with zero balance */}
                                    {(userBalance <= 0) &&
                                        <React.Fragment>
                                            {isWithVat &&
                                                <React.Fragment>
                                                    <Grid item xs={6}>
                                                        <Typography className={classes.AttributeNameBold}>Vat (17%)</Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h4">{VatAmountStr}</Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography className={classes.AttributeNameBold}>Amount to pay:</Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h4" style={{ textDecoration: 'underline' }}>{amountIncludingVatStr}</Typography>
                                                    </Grid>
                                                </React.Fragment>
                                            }
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            }
                        </Grid>

                    </Grid>

                </Grid>
            </React.Fragment >

        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SummaryForm)));