import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';

import actions from '../redux/actions';
import { RootState } from '../redux/store';
import { OperationStatus } from '../types/OperationStatus';

import AvatarBackground from '../elements/AvatarBackground';
import Button from '../elements/Button';
import Hero from '../elements/Hero';

import theme from '../themes';


declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme { }
}


const useStyles = makeStyles((theme: Theme) => ({
    loginBox: {
        marginTop: -90,
        padding: 35,
    },
    avatarBackground: {
        paddingBottom: 97,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 0,
            height: 'calc(100vh - 40px)',
        },
    },
    smBottomSpace: {
        marginBottom: 25,
    },
    lgBottomSpace: {
        marginBottom: 50,
    },
    lgTopSpace: {
        marginTop: 50,
    },
    rememberPasswordText: {
        fontSize: 14,
    },
}));

export function ResetPasswordPage() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { resolution, sendPasswordResetEmailStatus } = useSelector((state: RootState) => state);

    const isMobile = resolution.device === 'mobile';
    const isMobileTablet = resolution.device === 'mobile-tablet';

    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<string>('');

    const clearError = () => {
        if (!!error) {
            setError(error);
        }
    };

    const resetPassword = (e) => {
        e.preventDefault();
        if (sendPasswordResetEmailStatus.status === OperationStatus.processing) {
            return;
        }
        if (!isEmail(email)) {
            return setError('A valid email address is required');
        }
        dispatch(actions.sendPasswordResetEmail(email));
    };

    useEffect(clearError, [email]);

    useEffect(() => {
        const mainEl = document.getElementsByTagName('main')[0];
        mainEl.classList.add('full-screen');
        return () => {
            mainEl.classList.remove('full-screen');
        };
    }, []);

    useEffect(() => {
        setError(sendPasswordResetEmailStatus.errorMessage);
    }, [sendPasswordResetEmailStatus]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <AvatarBackground
                    mode="blue"
                    fullScreen
                    hideMiddleAvatars
                    className={classes.avatarBackground}
                    hideAvatars={isMobile || isMobileTablet}
                >
                    <Container>
                        <Hero
                            mode="light"
                            maxWidth="sm"
                            title="Forgot your password?"
                            className={classes.loginBox}
                            description="Don't worry, Resetting your password is easy. Just type in the email you registered to Testpoint."
                        >
                            <Container maxWidth="xs" className={classes.lgTopSpace}>
                                <form noValidate autoComplete="off">
                                    <div className={classes.smBottomSpace}>
                                        <TextField
                                            required
                                            fullWidth
                                            type="email"
                                            value={email}
                                            aria-required
                                            error={!!error}
                                            variant="outlined"
                                            helperText={error}
                                            placeholder="Email"
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    </div>

                                    {sendPasswordResetEmailStatus.status === OperationStatus.success && (
                                        <Alert severity="success">Check your email to reset password</Alert>
                                    )}

                                    {sendPasswordResetEmailStatus.status !== OperationStatus.success && (
                                        <>
                                            <Button
                                                fullWidth
                                                onClick={resetPassword}
                                                disabled={sendPasswordResetEmailStatus.status === OperationStatus.processing}
                                            >
                                                Send Reset Email
                                            </Button>

                                            <Typography
                                                align="center"
                                                variant="body2"
                                                className={classNames(classes.lgTopSpace, classes.rememberPasswordText)}
                                            >
                                                Do you remember your password?
                                                <Link to="/login" className="link-info" style={{ marginLeft: 3 }}>
                                                    Try to login
                                                </Link>
                                            </Typography>
                                        </>
                                    )}
                                </form>
                            </Container>
                        </Hero>
                    </Container>
                </AvatarBackground>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}



export function ResetFBPasswordPage() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { resolution, sendPasswordResetEmailStatus } = useSelector((state: RootState) => state);

    const isMobile = resolution.device === 'mobile';
    const isMobileTablet = resolution.device === 'mobile-tablet';

    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<string>('');

    const clearError = () => {
        if (!!error) {
            setError(error);
        }
    };

    const resetPassword = (e) => {
        e.preventDefault();
        if (sendPasswordResetEmailStatus.status === OperationStatus.processing) {
            return;
        }
        if (!isEmail(email)) {
            return setError('A valid email address is required');
        }
        dispatch(actions.sendFBPasswordGenerateEmail(email));
    };

    useEffect(clearError, [email]);

    useEffect(() => {
        const mainEl = document.getElementsByTagName('main')[0];
        mainEl.classList.add('full-screen');
        return () => {
            mainEl.classList.remove('full-screen');
        };
    }, []);

    useEffect(() => {
        setError(sendPasswordResetEmailStatus.errorMessage);
    }, [sendPasswordResetEmailStatus]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <AvatarBackground
                    mode="blue"
                    fullScreen
                    hideMiddleAvatars
                    className={classes.avatarBackground}
                    hideAvatars={isMobile || isMobileTablet}
                >
                    <Container>
                        <Hero
                            mode="light"
                            maxWidth="sm"
                            title="Facebook login is no longer supported"
                            className={classes.loginBox}
                            description="Don't worry, You can still log in. Just type in the email associated with your Facebook account and we'll send you a link to generate a password for your Testpoint account."
                        >
                            <Container maxWidth="xs" className={classes.lgTopSpace}>
                                <form noValidate autoComplete="off">
                                    <div className={classes.smBottomSpace}>
                                        <TextField
                                            required
                                            fullWidth
                                            type="email"
                                            value={email}
                                            aria-required
                                            error={!!error}
                                            variant="outlined"
                                            helperText={error}
                                            placeholder="Email"
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    </div>

                                    {sendPasswordResetEmailStatus.status === OperationStatus.success && (
                                        <Alert severity="success">Check your email for the password generation</Alert>
                                    )}

                                    {sendPasswordResetEmailStatus.status !== OperationStatus.success && (
                                        <>
                                            <Button
                                                fullWidth
                                                onClick={resetPassword}
                                                disabled={sendPasswordResetEmailStatus.status === OperationStatus.processing}
                                            >
                                                Send Reset Email
                                            </Button>

                                            <Typography
                                                align="center"
                                                variant="body2"
                                                className={classNames(classes.lgTopSpace, classes.rememberPasswordText)}
                                            >
                                                Already have a password?
                                                <Link to="/login" className="link-info" style={{ marginLeft: 3 }}>
                                                    Back to login
                                                </Link>
                                            </Typography>
                                        </>
                                    )}
                                </form>
                            </Container>
                        </Hero>
                    </Container>
                </AvatarBackground>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}


export function ResetPasswordResponse() {

    const classes = useStyles();
    const dispatch = useDispatch();

    const { resolution, passwordResetStatus } = useSelector((state: RootState) => state);

    const isMobile = resolution.device === 'mobile';
    const isMobileTablet = resolution.device === 'mobile-tablet';

    useEffect(() => {
        const mainEl = document.getElementsByTagName('main')[0];
        mainEl.classList.add('full-screen');
        return () => {
            mainEl.classList.remove('full-screen');
        };
    }, []);


    const url = new URL(window.location.href);
    const email = url.searchParams.get('email');
    const code = url.searchParams.get('code');

    useEffect(() => {
        dispatch(actions.resetPassword(email, code));
    }, []);

    if (email && code) {
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <AvatarBackground
                        mode="blue"
                        fullScreen
                        hideMiddleAvatars
                        className={classes.avatarBackground}
                        hideAvatars={isMobile || isMobileTablet}
                    >
                        <Container>
                            <Hero
                                mode="light"
                                maxWidth="sm"
                                title="Password recovery"
                                className={classes.loginBox}
                            >
                                <Container maxWidth="xs" className={classes.lgTopSpace}>

                                    {passwordResetStatus.status === OperationStatus.success && (
                                        <Alert severity="success">
                                            <strong>Password reset successful</strong>
                                            <div>Please check your mail again for the new password</div>
                                        </Alert>
                                    )}

                                    {passwordResetStatus.status === OperationStatus.notStarted && (
                                        <p>...</p>
                                    )}

                                    {passwordResetStatus.status === OperationStatus.processing && (
                                        <p>Pleae wait...</p>
                                    )}

                                    {passwordResetStatus.status === OperationStatus.fail && (
                                        <Alert severity="error">
                                            <strong>Unable to reset password</strong>
                                            <div>Please try again later or contact technical support</div>
                                        </Alert>
                                    )}
                                </Container>
                            </Hero>
                        </Container>
                    </AvatarBackground>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }
    else {
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <AvatarBackground
                        mode="blue"
                        fullScreen
                        hideMiddleAvatars
                        className={classes.avatarBackground}
                        hideAvatars={isMobile || isMobileTablet}
                    >
                        <Container>
                            <Hero
                                mode="light"
                                maxWidth="sm"
                                className={classes.loginBox}
                            >
                                <Container maxWidth="xs" className={classes.lgTopSpace}>
                                    {passwordResetStatus.status !== OperationStatus.success && (
                                        <Alert severity="error">
                                            <strong>Error reset password</strong>
                                            <div>Please try again later or contact technical support</div>
                                        </Alert>
                                    )}
                                </Container>
                            </Hero>
                        </Container>
                    </AvatarBackground>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }
}