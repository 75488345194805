import React, { Component } from 'react';
import { Box, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Select } from 'semantic-ui-react';

import countryCodes from '../../../util/options/countryCodes';
import { NewWizardCycle } from '../../../types/NewWizardCycle';
import wizardActions from '../../../redux/actions/wizardActions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { searchFromStart } from '../../../util/utils';
import LabeledSwitcher from './LabeledSwitcher';
import SharedStyle, {titleBlue} from './SharedStyle';

const styles = (theme) => createStyles({
    innerTitle:{
        fontSize: '16px',
        color: titleBlue,
        fontWeight: 'normal',
        letterSpacing: '0.1px',
    }
});

const allCountryCodes = [...countryCodes];

const mapStateToProps = (store) => {
    return {
      newCycle: store.newWizardCycle as NewWizardCycle,
    };
  };


const mapDispatchToProps = (dispatch) => {
  return {
    updateCycle: (data) => dispatch(wizardActions.updateCycle(data)),      
  };
};

class LocationForm extends Component<any> {

    componentDidMount() {
        const newCycle = { ...this.props.newCycle };
        newCycle.countries = newCycle.countries.filter( country => country !== 'All' );
        if( newCycle.countries.length === 0){
            newCycle.isWorldwide = true;
        }

        this.props.updateCycle(newCycle);
    }

    handleChange = (event) =>{
        const newCycle: NewWizardCycle = { ...this.props.newCycle };
        newCycle.isWorldwide = event.target.checked; 
        
        this.props.updateCycle(newCycle);
    }

    onChange = (event, data) => {        
        const newCycle: NewWizardCycle = { ...this.props.newCycle };
        const countries = [...data.value];

        if( countries.length === 0 ){
            newCycle.isWorldwide = true;
        }            
        else{
            if( countries.length === 1 ){
                if( countries.find( country => country === 'All' ) ){
                    newCycle.isWorldwide = true;
                }
                else{
                    newCycle.isWorldwide = false;
                }
            }
            else{
                newCycle.isWorldwide = false;                
            }
        }
        
        newCycle.countries = countries;
        newCycle.relevantDevices = [];
        newCycle.specificationPerCountry = [];
        this.props.updateCycle(newCycle);
    }

    render() {

        const {classes, newCycle} = this.props;

        return(
            <Box>                                    
                <Typography variant="h2" >
                        Select test location for tester
                </Typography>
                                        
                <LabeledSwitcher 
                    label="Worldwide"
                    isChecked={newCycle.isWorldwide}
                    handleChange={this.handleChange}
                />          
                
                <div style={{maxWidth:"50%", marginTop:"20px"}}>
                    <Typography className={classes.innerTitle}>
                        Where do you want to test from
                    </Typography>
                    <Select style={{minWidth:"400px", marginTop:"20px"}}
                        disabled={newCycle.isWorldwide}
                        name="countries"
                        value={newCycle.countries}
                        placeholder="Select countries to test from"
                        options={allCountryCodes}
                        onChange={this.onChange}
                        search={searchFromStart}
                        multiple
                    />                    
                </div>                    
            </Box>                
        );    
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LocationForm)));