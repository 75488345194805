import moment from 'moment';
import Box from '@mui/material/Box';
import findIndex from 'lodash/findIndex';
import { useSelector } from 'react-redux';
import Select from '@mui/material/Select';
import { red } from '@mui/material/colors';
import MenuItem from '@mui/material/MenuItem';
import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import { useParams, useLocation, Link } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import Tabs from '../../../elements/Tabs';
import { RootState } from '../../../redux/store';
import { CycleStatus } from '../../../types/CycleStatus';
import { useHistory } from 'react-router';

const styles = {
    unreadIndicator: { width: 8, height: 8, background: red[500], borderRadius: '100%', marginLeft: 6 },
};


interface ITab {
    title: JSX.Element | string; 
    link: string;
    isActive?: boolean;
};

const createTabs = (cycle: any, isMobile?: boolean, isActive?: boolean) : ITab[] => {
    if(isMobile) {
        return (
            [
                { 
                    title: 'My Test', 
                    link: `/account/customer/cycle/${cycle?.id}/report`,
                    isActive: true,
                },
                { 
                    title: 'Active Testers', 
                    link: `/account/customer/cycle/${cycle?.id}/testers`,
                    isActive: isActive,
                },
                 { 
                    title: 'Test info', 
                    link: `/account/customer/cycle/${cycle?.id}/test-info`,
                    isActive: true,
                },
            ]
        );
    }

    return (
        [
            { 
                title: 'My Test', 
                link: `/account/customer/cycle/${cycle?.id}/report`,
                isActive: true,
            },
            { 
                title: 'Active Testers', 
                link: `/account/customer/cycle/${cycle?.id}/testers`,
                isActive: isActive,
            },
            { 
                title: (
                    <Box display="flex" alignItems="center">
                        <span>Messages</span>
                        {cycle?.totalUnreadMessages ? <span style={styles.unreadIndicator} /> : null}
                    </Box>
                ), 
                link: `/account/customer/cycle/${cycle?.id}/messages`,
                isActive: isActive,
            },
            { 
                title: 'Test info', 
                link: `/account/customer/cycle/${cycle?.id}/test-info`,
                isActive: true,
            },
        ]
    );
    
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        borderBottom: `1px solid ${theme.palette.primary.light}`,
    },
    formControl: {
        minWidth: 350,
        margin: '8px',
    },
    formControlSlim: {
        minWidth: 300,
        margin: '8px',
    },
    MenuItemActiveCycle:{
        color: `${theme.palette.primary.dark}`,
        fontWeight: 'bold'
    },
    MenuItemCompletedCycle:{
        //fontStyle: 'italic',
        color: 'black'
    },    
}));

const Navigation: React.FC<{isCycleOn: boolean, onCycleSelectionChanged: any}> = ({isCycleOn, onCycleSelectionChanged}) => {    
    const classes = useStyles();
    const location = useLocation();
    const { cycleId } = useParams<{ cycleId: string }>();
    const [activeTab, setActiveTab] = useState<number>(0);

    const history = useHistory();

    let { cyclesSlim, resolution } = useSelector((state: RootState) => state);

    cyclesSlim = cyclesSlim.filter( (c: any) => c.status != CycleStatus.Draft );

    const isMobile = resolution.device === 'mobile';
    const isTablet = resolution.device === 'mobile-tablet';

    const liveCycles = cyclesSlim.filter((c: any) => [CycleStatus.TestingAndSelection, CycleStatus.Testing].includes(c.status));

    const cycle: any = cyclesSlim.find((c: any) => c.id === +cycleId);

    const inputLabel = `${cycle?.title} - ${moment(cycle?.startDate).format('DD.MM.YYYY')}`;    

    const tabs = createTabs(cycle, false /* isMobile */, isCycleOn);

    const handleChange = (_: React.ChangeEvent<{}>, newTab: number) => {        
        setActiveTab(newTab);
    };    

    useEffect(function onLocationChange() {
        let activeTab = findIndex(tabs, ({ link }) => link === location.pathname);
        if( -1 < activeTab )
            setActiveTab(activeTab);
    }, [cycle]);    

    if( isMobile ){
        return (
            <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="column" className={classes.root}>
                <FormControl variant="outlined" className={classes.formControl}>                    
                    <Select
                        style={{color:'#717480'}}                                   
                        value={cycleId}
                        id="select-outlined"                                  
                        onChange={(e) => history.push(`/account/customer/cycle/${e.target.value}/report`)}
                        MenuProps={{disableScrollLock: true}}
                        key={cycleId}                   
                    >
                        {cyclesSlim.reverse().map((cycle: any) => (                        
                            <MenuItem value={cycle.id} key={cycle.id} className={ cycle.status != CycleStatus.Completed ? classes.MenuItemActiveCycle : classes.MenuItemCompletedCycle }>
                                {cycle.title} - {moment(cycle.startDate).format('DD.MM.YYYY')}
                            </MenuItem>                        
                        ))}
                        </Select> 
                </FormControl>
                
                <Tabs.Root 
                    value={activeTab} 
                    onChange={handleChange} 
                    aria-label="Navigation Tabs"
                >
                    {tabs.map(({ title, link, isActive }) => (
                        <Tabs.Item 
                            key={link}
                            label={title}
                            onClick={() => history.push(link)}
                            disabled={!isActive}
                        />
                    ))}
                </Tabs.Root>            
            </Box>
        );
    }
    if( isTablet ){
        return (
            <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="row" className={classes.root}>
                <FormControl variant="outlined" className={classes.formControlSlim}>                    
                    <Select 
                        style={{maxWidth: '250px', color:'#717480'}}
                        value={cycleId}
                        id="select-outlined"                                  
                        onChange={(e) => history.push(`/account/customer/cycle/${e.target.value}/report`)}
                        MenuProps={{disableScrollLock: true}}
                        key={cycleId}                    
                    >
                        {cyclesSlim.reverse().map((cycle: any) => (                            
                            <MenuItem key={cycle.id} value={cycle.id} className={ cycle.status != CycleStatus.Completed ? classes.MenuItemActiveCycle : classes.MenuItemCompletedCycle }>
                                {cycle.title} - {moment(cycle.startDate).format('DD.MM.YYYY')}
                            </MenuItem>                            
                        ))}
                        </Select> 
                </FormControl>
                
                <Tabs.Root 
                    value={activeTab} 
                    onChange={handleChange} 
                    aria-label="Navigation Tabs"
                >
                    {tabs.map(({ title, link, isActive }) => (
                        <Tabs.Item 
                            key={link}
                            label={title}
                            onClick={() => history.push(link)}
                            disabled={!isActive}
                        />
                    ))}
                </Tabs.Root>            
            </Box>
        );
    }    
    return (
        <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.root}>
            <FormControl variant="outlined" className={classes.formControl}>                
                <Select     
                    style={{maxWidth: '350px', color:'#717480'}}                 
                    value={cycleId}
                    id="select-outlined"                                  
                    onChange={(e) => history.push(`/account/customer/cycle/${e.target.value}/report`)}
                    MenuProps={{disableScrollLock: true}}
                    key={cycleId}
                >
                    {cyclesSlim.reverse().map((cycle: any) => (
                        /* <Link key={cycle.id} to={`/account/customer/cycle/${cycle.id}/report`}> */
                            <MenuItem value={cycle.id} key={cycle.id} className={ cycle.status != CycleStatus.Completed ? classes.MenuItemActiveCycle : classes.MenuItemCompletedCycle }>
                                {cycle.title} - {moment(cycle.startDate).format('DD.MM.YYYY')}
                            </MenuItem>
                        /* </Link> */
                    ))}
                    </Select> 
            </FormControl>

            <Tabs.Root 
                value={activeTab} 
                onChange={handleChange} 
                aria-label="Navigation Tabs"
            >
                {tabs.map(({ title, link, isActive }) => (
                    <Tabs.Item 
                        key={link}
                        label={title}
                        onClick={() => history.push(link)}
                        disabled={!isActive}
                    />
                ))}
            </Tabs.Root>
        </Box>
    );
}

export default Navigation;
