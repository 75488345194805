import React, { Component } from 'react';
import { Box, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import wizardActions from '../../../redux/actions/wizardActions';
import { NewWizardCycle } from '../../../types/NewWizardCycle';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SharedStyle from './SharedStyle';
import { DeliveryTimeCategory, QuestionTimeCategory } from '../../../types/WizardCategoriesTypes';



const styles = (theme) => createStyles({
      cardsBox: {        
        marginTop: '20px',
        marginLeft: '-8px',
        display: 'flex',
        flexWrap: 'wrap',    
        '& > *': {
            marginLeft: '8px',
          width: '103',
          height: '58px',          
        },
      },
    SpreadBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",        
    },
    extraPayText: {
        marginLeft: '20px',
        fontSize: '24px',
        color: theme.palette.primary.main,
    },
    cardDefault: {
        border: SharedStyle.contentCardDefault.border,
        borderRadius: SharedStyle.contentCardDefault.borderRadius,
        cursor: SharedStyle.contentCardDefault.cursor,        
    },
    cardSelected:{        
        border: SharedStyle.contentCardSelected.border,
        borderRadius: SharedStyle.contentCardSelected.borderRadius,
        cursor: SharedStyle.contentCardSelected.cursor,
    },
  });

const mapStateToProps = (store) => {
  return {
    newCycle: store.newWizardCycle as NewWizardCycle,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    updateCycle: (data) => dispatch(wizardActions.updateCycle(data)),      
  };
};

class PeriodForm extends Component<any> {    
    
    onSelectionChangeVal = (data, valueSelected) => {
        const newCycle: NewWizardCycle = { ...this.props.newCycle };
        newCycle[data] = valueSelected;
        
        this.props.updateCycle(newCycle);
    }

    render() {

        const {classes, newCycle} = this.props;

        return(
            <Box>                
                    <Typography variant="h2">
                            Set the overall time for the test
                    </Typography>
                    <Box className={classes.cardsBox}>
                        <Card 
                            onClick={() => this.onSelectionChangeVal('deliveryTime', DeliveryTimeCategory._24hours)} 
                            elevation={0} 
                            className={newCycle.deliveryTime === DeliveryTimeCategory._24hours ?  classes.cardSelected : classes.cardDefault }
                        >
                            <CardContent>                                
                                <Typography variant="h6">
                                    24 hours
                                </Typography>                        
                            </CardContent>
                        </Card>

                        <Card style={{alignItems:"center"}}
                            onClick={() => this.onSelectionChangeVal('deliveryTime', DeliveryTimeCategory._3days)} 
                            elevation={0} 
                            className={newCycle.deliveryTime === DeliveryTimeCategory._3days ?  classes.cardSelected : classes.cardDefault }
                        >
                            <CardContent>
                                <Typography variant="h6">
                                    3 Days
                                </Typography>                                
                            </CardContent>
                        </Card>

                        <Card style={{alignItems:"center"}}
                            onClick={() => this.onSelectionChangeVal('deliveryTime', DeliveryTimeCategory._7days)} 
                            elevation={0} 
                            className={newCycle.deliveryTime === DeliveryTimeCategory._7days ?  classes.cardSelected : classes.cardDefault }
                        >                
                            <CardContent>
                            <Typography variant="h6">
                                    1 Week
                                </Typography>
                            </CardContent>
                        </Card>                                
                    </Box>                

                    <Typography variant="h2" style={{marginTop:"30px"}}>
                        Set Questions time for the testers, after the overall time is complete
                    </Typography>
                    <Box className={classes.cardsBox}>
                        <Card style={{alignItems:"center"}}
                            onClick={() => this.onSelectionChangeVal('questionsTime', QuestionTimeCategory._24hours)} 
                            elevation={0} 
                            className={newCycle.questionsTime === QuestionTimeCategory._24hours ?  classes.cardSelected : classes.cardDefault }
                        >                
                            <CardContent>
                                <Typography variant="h6">
                                    24 Hours
                                </Typography>
                            </CardContent>
                        </Card>
                        
                        <Card style={{alignItems:"center"}}
                            onClick={() => this.onSelectionChangeVal('questionsTime', QuestionTimeCategory._2days)} 
                            elevation={0} 
                            className={newCycle.questionsTime === QuestionTimeCategory._2days ?  classes.cardSelected : classes.cardDefault }
                        >                
                            <CardContent>
                                <Box className={classes.SpreadBox} style={{marginTop: '-5px'}}>                                    
                                    <Typography variant="h6">
                                        2 Days
                                    </Typography>
                                    <Typography className={classes.extraPayText}>
                                        +$10
                                    </Typography>                            
                                </Box>
                            </CardContent>
                        </Card>
                        
                        <Card style={{alignItems:"center"}}
                            onClick={() => this.onSelectionChangeVal('questionsTime', QuestionTimeCategory._4days)} 
                            elevation={0}
                            className={newCycle.questionsTime === QuestionTimeCategory._4days ?  classes.cardSelected : classes.cardDefault }
                        >                
                            <CardContent>
                                <Box className={classes.SpreadBox} style={{marginTop: '-5px'}}>
                                    <Typography variant="h6">
                                        4 Days
                                    </Typography>
                                    <Typography className={classes.extraPayText}>
                                        +$15
                                    </Typography>                                    
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>   

            </Box>                
        );    
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PeriodForm)));