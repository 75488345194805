import React from 'react';
import classNames from 'classnames';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { grey, blue } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';

import avatar1 from '../public/images/avatars/1@2x.png';
import avatar2 from '../public/images/avatars/2@2x.png';
import avatar3 from '../public/images/avatars/3@2x.png';
import avatar4 from '../public/images/avatars/4@2x.png';
import avatar5 from '../public/images/avatars/5@2x.png';
import avatar6 from '../public/images/avatars/6@2x.png';
import avatar7 from '../public/images/avatars/7@2x.png';
import avatar8 from '../public/images/avatars/8@2x.png';
import avatar9 from '../public/images/avatars/9@2x.png';
import avatar10 from '../public/images/avatars/10@2x.png';

const useStyles = makeStyles(() => ({
    smAvatar: {
        height: 72,
        width: 72,
    },
    mdAvatar: {
        height: 85,
        width: 85,
    },
    lgAvatar: {
        height: 115,
        width: 115,
    },
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    containerFullScreen: {
        height: 'calc(100vh - 97px)',
    },
    colorLight: {
        color: '#ffffff',
    },
    grey: {
        backgroundColor: grey[50],
    },
    blue: {
        backgroundColor: blue[800],
    },
    light: {
        backgroundColor: '#ffffff',
    },
}));

interface IAvatarBackgroundProps {
    mode?: 'light' | 'grey' | 'blue';
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    fullScreen?: boolean;
    hideMiddleAvatars?: boolean;
    hideSideAvatars?: boolean;
    hideAvatars?: boolean;
    className?: string;
    children?: JSX.Element | string | null;
};

const AvatarBackground: React.FC<IAvatarBackgroundProps> = ({
    mode = 'light', 
    maxWidth = 'xl',
    fullScreen = false,
    hideMiddleAvatars = false,
    hideSideAvatars = false,
    hideAvatars = false,
    className = '',
    children = null,
}) => {
    const classes = useStyles();

    return (
        <Container 
            maxWidth={maxWidth}
            className={classNames(classes.container, {
                [classes.containerFullScreen]: fullScreen,
                [classes[mode]]: true,
                [className]: true,
            })}
        >
            <Container maxWidth="lg">
                {!hideAvatars && (
                    <Box display="flex" alignItems="flex-end" justifyContent="space-between">
                        <img src={avatar1} alt="Avatar 1" className={classes.lgAvatar} />
                        {!hideMiddleAvatars && (
                            <img src={avatar2} alt="Avatar 2" className={classes.mdAvatar} />
                        )}
                        <img src={avatar3} alt="Avatar 3" className={classes.lgAvatar} />
                    </Box>
                )}

                <Box display="flex" alignItems="flex-end" justifyContent="space-between">
                    {!hideAvatars && !hideSideAvatars && (
                        <Box display="flex" flexDirection="column" justifyContent="space-between">
                            <img src={avatar4} alt="Avatar 4" className={classes.smAvatar} />
                            <img src={avatar5} alt="Avatar 5" className={classes.lgAvatar} />
                            <img src={avatar6} alt="Avatar 6" className={classes.smAvatar} />
                        </Box>
                    )}

                    {children}  

                    {!hideAvatars && !hideSideAvatars && (
                        <Box display="flex" flexDirection="column" justifyContent="space-between">
                            <img src={avatar7} alt="Avatar 7" className={classes.smAvatar} />
                            <img src={avatar8} alt="Avatar 8" className={classes.lgAvatar} />
                            <img src={avatar9} alt="Avatar 9" className={classes.smAvatar} />
                        </Box>
                    )}
                </Box>

                {!hideMiddleAvatars && !hideAvatars && (
                    <Box display="flex" alignItems="flex-start" justifyContent="space-around" style={{marginTop: -50}}>
                        <img src={avatar10} alt="Avatar 10" className={classes.lgAvatar} />
                        <img src={avatar3} alt="Avatar 3" className={classes.mdAvatar} /> 
                    </Box>
                )}        
            </Container>
        </Container>
    );
};

export default AvatarBackground;