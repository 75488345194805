import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Table, Input, Message, Button } from 'semantic-ui-react';
import _ from 'lodash';
import CycleDetailsSidemenu from './Sidemenu';
import { WorkCategory } from '../../../../types/WorkCategory';
import { Gender } from '../../../../types/Gender';
import { CycleStatus } from '../../../../types/CycleStatus';
import cycleActions from '../../../../redux/actions/cycleActions';
import { getPlatformPrettyName } from '../../../../services/deviceService';
import { OperationStatus } from '../../../../types/OperationStatus';
import styled from 'styled-components';

const Wtf = styled.div`
  h1,
  h2,
  h3 {
    color: initial;
    margin: 10px;
  }
  h4,
  h5,
  h6 {
    color: initial;
    margin: 5px;
  }
`;

const mapStateToProps = (state) => {
  return {
    user: state.user,
    cycles: state.cycles,
    chatMessages: state.chatMessages,
    cycleCreateStatus: state.cycleCreateStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCycleUrls: (cycleId, urls) => dispatch(cycleActions.updateCycleUrls(cycleId, urls)),
    listCycles: () => dispatch(cycleActions.listCycles()),
    getCycle: (id) => dispatch(cycleActions.getCycle(id)),
  };
};

class CycleDetails extends Component<any> {
  public state: any;

  constructor(props) {
    super(props);

    this.state = {
      cycleId: +props.match.params.id,
      cycle: {},
      editUrlKey: null,
      editUrlValue: null,
    };
  }

  componentDidMount() {
    if (!this.props.cycles.length) {
      this.props.listCycles();
    } else {
      const cycle = this.props.cycles.find((el) => el.id === this.state.cycleId);
      if( cycle ){
        this.setState({ cycle });
      }
      else{
        //Calling the cycle...
        //this.props.getCycle(this.state.cycleId);
        this.props.listCycles();
      }
    }

    const url = new URL(window.location.href);
    const searchParms = new URLSearchParams(url.search);
    const status = Boolean(searchParms.get('created'));

    if (this.props.cycleCreateStatus.status === OperationStatus.success || status === true) {
      localStorage.removeItem('newCycle');
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.cycles, prevProps.cycles)) {
      const cycle = this.props.cycles.find((el) => el.id === this.state.cycleId);
      this.setState({ cycle });
    }    
  }

  onEnableEditUrl = (event, data) => {
    event.preventDefault();

    this.setState({
      editUrlKey: data['data-key'],
      editUrlValue: data['data-value'],
    });
  };

  onEditUrl = (event, data) => {
    event.preventDefault();

    this.setState({
      editUrlValue: data.value,
    });
  };

  onSaveEditedUrl = (event) => {
    event.preventDefault();

    this.props.updateCycleUrls(this.state.cycle.id, {
      [this.state.editUrlKey]: this.state.editUrlValue,
    });

    this.setState({
      editUrlKey: null,
      editUrlValue: null,
    });
  };

  render() {
    const cycle = this.props.cycles.find((el) => el.id === this.state.cycleId);

    if (!cycle) {
      return null;
    }

    let workCategory;

    switch (cycle.workCategory) {
      case WorkCategory.FeedbackAndRating:
        workCategory = 'Get feedback and star rating';
        break;
      case WorkCategory.FindBugsExploratory:
        workCategory = 'Find bugs - exploratory';
        break;
      case WorkCategory.FindBugsTestPlan:
        workCategory = 'Find bugs - testing plan';
        break;
    }

    const platformNames = cycle.platformUrls
      .map((el) => getPlatformPrettyName(el.platformId))
      .join(', ');

    const deviceNames = cycle.testEnvs
      .filter((el) => el.type !== 'Platform')
      .map((el) => el.name)
      .join(', ');

    let gender;

    switch (cycle.gender) {
      case Gender.Female:
        gender = 'Female';
        break;
      case Gender.Male:
        gender = 'Male';
        break;
      case Gender.Any:
        gender = 'Any';
        break;
      default:
        gender = 'Not set';
        break;
    }

    let age;

    if (!cycle.minAge && !cycle.maxAge) {
      age = 'Not set';
    } else {
      if (cycle.minAge) {
        age = `From ${cycle.minAge} `;
      }

      if (cycle.maxAge) {
        age = `To ${cycle.maxAge}`;
      }
    }

    const chatMessages = this.props.chatMessages.byCycleId[cycle.id] || {};
    let unreadMessages = 0;

    for (const participantId of Object.keys(chatMessages)) {
      if (!chatMessages[participantId]) {
        continue;
      }

      for (const message of chatMessages[participantId]) {
        if (!message.read) {
          unreadMessages += 1;
        }
      }
    }

    return (
      <div className="account-customer page">
        <div className="heading">
          <h1>Cycle Details</h1>
        </div>

        <div className="inner" style={{ display: 'flex' }}>
          <CycleDetailsSidemenu
            cycleEndDate={cycle.endDate}
            cycleId={cycle.id}
            workCategory={cycle.workCategory}
            unreadMessages={unreadMessages}
          />

          <main className="cycle-details">
            <h2>Cycle details</h2>
            <h3>
              Product: {cycle.title}, version: {cycle.version}
            </h3>

            {cycle.status === CycleStatus.Completed && (
              <Message info>
                <Message.Header>This cycle has finished</Message.Header>
                <Link to="/account/customer">Go to dashboard</Link>
              </Message>
            )}

            <Table definition className="cycle-details-table">
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    Product description,
                    <br />
                    testing guidelines
                  </Table.Cell>
                  <Table.Cell>
                    <Wtf dangerouslySetInnerHTML={{ __html: cycle.description }} />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Countries</Table.Cell>
                  <Table.Cell>{cycle.countries.join(', ')}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Deliverable</Table.Cell>
                  <Table.Cell>{workCategory}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Platforms</Table.Cell>
                  <Table.Cell>{platformNames}</Table.Cell>
                </Table.Row>

                {deviceNames.length > 0 && (
                  <Table.Row>
                    <Table.Cell>Devices</Table.Cell>
                    <Table.Cell>
                      <ul className="device-list">{deviceNames}</ul>
                    </Table.Cell>
                  </Table.Row>
                )}

                <Table.Row>
                  <Table.Cell>Testers per device/platform</Table.Cell>
                  <Table.Cell>
                    <ul className="device-list">
                      {cycle.testEnvs.map((el) => {
                        if (el.type === 'Platform') {
                          return (
                            <p>
                              {getPlatformPrettyName(el.platformId)}: {el.requiredTesters}
                            </p>
                          );
                        } else {
                          return (
                            <p>
                              {el.name}: {el.requiredTesters}
                            </p>
                          );
                        }
                      })}
                    </ul>
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>App/browser links</Table.Cell>
                  <Table.Cell>
                    {cycle.platformUrls.map((el, i) => {
                      return (
                        <div className="platform-url" key={i}>
                          <span className="name">{getPlatformPrettyName(el.platformId)}</span>

                          {this.state.editUrlKey === el.platformId ? (
                            <React.Fragment>
                              <Input
                                size="mini"
                                value={this.state.editUrlValue}
                                onChange={this.onEditUrl}
                              />
                              <Button
                                positive
                                compact
                                size="mini"
                                className="save-btn"
                                data-key={el.platformId}
                                onClick={this.onSaveEditedUrl}
                                content="Save"
                              />
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <span className="url">
                                <a target="_blank" href={el.url} rel="noopener noreferrer">
                                  {el.url}
                                </a>
                              </span>
                              <Button
                                primary
                                compact
                                size="mini"
                                className="edit-btn"
                                onClick={this.onEnableEditUrl}
                                data-key={el.platformId}
                                data-value={el.url}
                                content="Edit"
                              />
                            </React.Fragment>
                          )}
                        </div>
                      );
                    })}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Requested gender</Table.Cell>
                  <Table.Cell>{gender}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Requested age</Table.Cell>
                  <Table.Cell>{age}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Requested hours</Table.Cell>
                  <Table.Cell>{cycle.hoursPerTester}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Hourly rate</Table.Cell>
                  <Table.Cell>{cycle.hourlyRate}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Total price</Table.Cell>
                  <Table.Cell>${cycle.totalPrice}</Table.Cell>
                </Table.Row>
                {cycle.workCategory === WorkCategory.FeedbackAndRating && (
                  <Table.Row>
                    <Table.Cell>Questions</Table.Cell>
                    <Table.Cell>
                      <ul>
                        {cycle.questions.map((el, i) => (
                          <li key={i}>{el}</li>
                        ))}
                      </ul>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </main>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CycleDetails));
