import config from '../../config';
import { WorkCategory } from '../../types/WorkCategory';
import { TestPlanStepStatus } from '../../types/TestPlanStepStatus';
import { Gender } from '../../types/Gender';
import { NewCycle } from '../../types/NewCycle';
import history from '../../customHistory';
import { prepareTester } from '../../util/dataTransformer';

function switchNewCycleStep(nextStep) {
  return (dispatch, getState) => {
    const { newCycle, newCycleCurrentStep } = getState();

    if (newCycleCurrentStep > nextStep) {
      return dispatch({
        type: 'SWITCH_NEW_CYCLE_STEP',
        newCycleCurrentStep: nextStep,
      });
    }

    if (newCycleCurrentStep === 1) {
      const step1Errors = validateStep1(newCycle);

      if (step1Errors.size) {
        return dispatch({
          type: 'SWITCH_NEW_CYCLE_STEP_FAIL',
          errors: Array.from(step1Errors),
        });
      }

      dispatch({
        type: 'SWITCH_NEW_CYCLE_STEP',
        newCycleCurrentStep: 2,
      });

      if (nextStep === 3) {
        const step2errors = validateStep2(newCycle);

        if (step2errors.size) {
          return {
            type: 'SWITCH_NEW_CYCLE_STEP_FAIL',
            errors: Array.from(step2errors),
          };
        }

        return dispatch({
          type: 'SWITCH_NEW_CYCLE_STEP',
          newCycleCurrentStep: 3,
        });
      }
    }

    if (newCycleCurrentStep === 2) {
      const step2errors = validateStep2(newCycle);

      if (step2errors.size) {
        return dispatch({
          type: 'SWITCH_NEW_CYCLE_STEP_FAIL',
          errors: Array.from(step2errors),
        });
      }

      return dispatch({
        type: 'SWITCH_NEW_CYCLE_STEP',
        newCycleCurrentStep: 3,
      });
    }
  };
}

function updateCycle(newCycleData) {
  return {
    type: 'UPDATE_CYCLE',
    newCycleData,
  };
}

function listCycles4CustomerSlim(): any {
  return async (dispatch) => {
    dispatch({
      type: 'LIST_CYCLES_SLIM_START',
    });

    try {
      const response = await fetch(`${config.apiEndpoint}/api/cyclesslim`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.status !== 200) {
        if (response.status === 401 || response.status === 403) {
          return dispatch({
            type: 'UNAUTHORIZED_REQUEST',
          });
        }

        const errorMessage = await response.json();

        return dispatch({
          type: 'LIST_CYCLES_SLIM_FAIL',
          errorMessage,
        });
      }

      const cyclesSlim = await response.json();            
      dispatch({
        type: 'LIST_CYCLES_SLIM_SUCCESS',
        cyclesSlim,
      });
    } catch (e) {
      dispatch({
        type: 'LIST_CYCLES_SLIM_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });
    }
  };
}


//Shouldn't be used for Customers anymore, we use the Slim version
//We keep it for Testers only
function listCycles(): any {
  return async (dispatch) => {
    dispatch({
      type: 'LIST_CYCLES_START',      
    });    

    try {
      const response = await fetch(`${config.apiEndpoint}/api/cycles`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.status !== 200) {
        if (response.status === 401 || response.status === 403) {
          return dispatch({
            type: 'UNAUTHORIZED_REQUEST',
          });
        }

        const errorMessage = await response.json();

        return dispatch({
          type: 'LIST_CYCLES_FAIL',
          errorMessage,
        });
      }

      const cycles = await response.json();

      for (let cycle of cycles) {
        if (cycle.testers) {
          for (let tester of cycle.testers) {
            tester = prepareTester(tester);
          }
        }

        if (cycle.bugs) {
          for (let bug of cycle.bugs) {
            bug.device = JSON.parse(bug.device);
          }
        }

        // TODO remove later
        cycle.bugsFound = cycle.bugs ? cycle.bugs.length : 0;

        if (cycle.approvedDevice) {
          cycle.approvedDevice = JSON.parse(cycle.approvedDevice);
        }
      }

      dispatch({
        type: 'LIST_CYCLES_SUCCESS',
        cycles,
      });
    } catch (e) {
      dispatch({
        type: 'LIST_CYCLES_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });
    }
  };
}

function submitCycle(cycleData) {
  return async (dispatch) => {
    dispatch({ type: 'CREATE_CYCLE_START' });

    try {
      const response = await fetch(`${config.apiEndpoint}/api/cycles`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(cycleData)
          .replace(/\u2028/g, '\\u2028')
          .replace(/\u2029/g, '\\u2029'),
      });

      if (response.status !== 201) {
        if (response.status === 402) {
          const orderApproveUrl = (await response.json()).orderApproveUrl;
          window.location.href = orderApproveUrl;
          return;
        }

        if (response.status === 401 || response.status === 403) {
          return dispatch({
            type: 'UNAUTHORIZED_REQUEST',
          });
        }

        dispatch({
          type: 'CREATE_CYCLE_FAIL',
          errorMessage: await response.json(),
        });

        history.push('cycle-creation-result?status=fail');
      }

      const cycle = await response.json();

      dispatch({
        type: 'CREATE_CYCLE_SUCCESS',
        cycle,
      });

      history.push(`account/customer/cycle/${cycle.id}`);
    } catch (e) {
      dispatch({
        type: 'CREATE_CYCLE_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });

      history.push('cycle-creation-result?status=fail');
    }
  };
}

function updateCycleUrls(cycleId, urls) {
  return async (dispatch) => {
    dispatch({ type: 'UPDATE_CYCLE_URLS_START' });

    try {
      const response = await fetch(`${config.apiEndpoint}/api/cycles/${cycleId}/platformUrls`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ urls }),
      });

      if (response.status !== 200) {
        if (response.status === 401 || response.status === 403) {
          return dispatch({
            type: 'UNAUTHORIZED_REQUEST',
          });
        }

        dispatch({
          type: 'UPDATE_CYCLE_URLS_FAIL',
          errorMessage: await response.json(),
        });
      }

      dispatch({
        type: 'UPDATE_CYCLE_URLS_SUCCESS',
        cycleId,
        urls,
      });
    } catch (e) {
      dispatch({
        type: 'UPDATE_CYCLE_URLS_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });
    }
  };
}

function listPotentialTesters(criteria, page = 1) {
  const relevantData = Object.keys(criteria).reduce((result, el) => {
    // FIXME
    if (Array.isArray(criteria[el]) && !criteria[el].length) {
      return result;
    }

    if (el === 'gender' && criteria[el] === Gender.Any) {
      return result;
    }

    if (el === 'countries' && criteria[el][0] === 'All') {
      return result;
    }

    if (
      criteria[el] &&
      [
        'workCategory',
        'gender',
        'minAge',
        'maxAge',
        'platforms',
        'countries',
        'hourlyRate',
      ].includes(el)
    ) {
      result[el] = criteria[el];
    }

    return result;
  }, {});

  const url = new URL(`${config.apiEndpoint}/api/potential-testers`);

  for (const el of Object.keys(relevantData)) {
    url.searchParams.append(el, relevantData[el]);
  }

  url.searchParams.append('page', `${page}`);

  return async (dispatch) => {
    try {
      const response = await fetch(url.href, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status !== 200) {
        if (response.status === 401 || response.status === 403) {
          return dispatch({
            type: 'UNAUTHORIZED_REQUEST',
          });
        }

        return dispatch({
          type: 'GET_POTENTIAL_TESTERS_FAIL',
          errorMessage: await response.json(),
        });
      }

      const { testers, total } = await response.json();

      for (let tester of testers) {
        tester = prepareTester(tester);
      }

      return dispatch({
        type: 'GET_POTENTIAL_TESTERS_SUCCESS',
        testers,
        total,
      });
    } catch (e) {
      return dispatch({
        type: 'GET_POTENTIAL_TESTERS_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });
    }
  };
}

function paginateTesters(page) {
  return {
    type: 'POTENTIAL_TESTER_PAGINATION',
    page,
  };
}

function validateStep1(newCycle: NewCycle): Set<string> {
  const errors: Set<string> = new Set();

  ['deliveryTime', 'workCategory', 'hourlyRate'].forEach((el) => {
    if (!newCycle[el]) {
      errors.add(el);
    }
  });

  if (newCycle['hourlyRate'] < config.minimalCycleHourlyRate) {
    errors.add('hourlyRate');
  }

  if (!newCycle.testEnvs.length) {
    errors.add('platformIds');
  }

  if (newCycle.workCategory === WorkCategory.FeedbackAndRating) {
    ['gender', 'minAge', 'maxAge'].forEach((el) => {
      if (!newCycle[el]) {
        errors.add(el);
      }
    });

    if (newCycle.minAge && newCycle.maxAge) {
      if (newCycle.minAge < 16 || newCycle.minAge > newCycle.maxAge) {
        errors.add('minAge');
      }

      if (newCycle.maxAge > 120 || newCycle.minAge > newCycle.maxAge) {
        errors.add('maxAge');
      }
    }
  }

  return errors;
}

function validateStep2(cycle): Set<string> {
  const errors: Set<string> = new Set();

  for (const el of ['title', 'description']) {
    if (!cycle[el]) {
      errors.add(el);
    }
  }

  return errors;
}

function passTestPlanStep(cycleId, testPlanStepId) {
  return async (dispatch) => {
    dispatch({ type: 'TEST_PLAN_STEP_PASS_START' });

    try {
      const response = await fetch(
        `${config.apiEndpoint}/api/cycles/${cycleId}/test-plan/steps/${testPlanStepId}`,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            status: TestPlanStepStatus.Passed,
          }),
        }
      );

      if (response.status !== 200) {
        if (response.status === 401 || response.status === 403) {
          return dispatch({
            type: 'UNAUTHORIZED_REQUEST',
          });
        }

        return dispatch({
          type: 'TEST_PLAN_STEP_PASS_FAIL',
          errorMessage: (await response.json()).message,
        });
      }

      return dispatch({
        type: 'TEST_PLAN_STEP_PASS_SUCCESS',
        cycleId,
        testPlanStepId,
      });
    } catch (e) {
      console.error(e);
      return dispatch({
        type: 'TEST_PLAN_STEP_PASS_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });
    }
  };
}

function updateTestPlanStep(cycleId: number, testPlanStepId: number, status: TestPlanStepStatus) {
  return async (dispatch) => {
    dispatch({ type: 'TEST_PLAN_STEP_UPDATE_START' });

    try {
      const response = await fetch(
        `${config.apiEndpoint}/api/cycles/${cycleId}/test-plan/steps/${testPlanStepId}`,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ status }),
        }
      );

      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          return dispatch({
            type: 'UNAUTHORIZED_REQUEST',
          });
        }

        return dispatch({
          type: 'TEST_PLAN_STEP_UPDATE_FAIL',
          errorMessage: (await response.json()).message,
        });
      }

      return dispatch({
        type: 'TEST_PLAN_STEP_UPDATE_SUCCESS',
        cycleId,
        testPlanStepId,
        status,
      });
    } catch (e) {
      console.error(e);
      return dispatch({ type: 'TEST_PLAN_STEP_UPDATE_FAIL' });
    }
  };
}

function getCycle(id) {
  return async (dispatch) => {
    dispatch({ type: 'GET_CYCLE_START' });

    const response = await fetch(`${config.apiEndpoint}/api/cycles/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    const cycle = await response.json();

    return dispatch({
      type: 'GET_CYCLE_SUCCESS',
      cycle,
    });
  };
}


function getCycleFull(id) {
  return async (dispatch) => {
    dispatch({ type: 'GET_CYCLE_FULL_START' });

    const response = await fetch(`${config.apiEndpoint}/api/cyclesFull/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    const cycle = await response.json();

    if (cycle.testers) {
      for (let tester of cycle.testers) {
        tester = prepareTester(tester);
      }
    }

    if (cycle.bugs) {
      for (let bug of cycle.bugs) {
        bug.device = JSON.parse(bug.device);
      }
    }

    // TODO remove later
    cycle.bugsFound = cycle.bugs ? cycle.bugs.length : 0;

    if (cycle.approvedDevice) {
      cycle.approvedDevice = JSON.parse(cycle.approvedDevice);
    }
    
    return dispatch({
      type: 'GET_CYCLE_FULL_SUCCESS',
      cycle,
    });
  };
}


export default {
  switchNewCycleStep,
  listCycles,
  listCycles4CustomerSlim,
  updateCycle,
  updateCycleUrls,
  submitCycle,
  listPotentialTesters,
  paginateTesters,
  passTestPlanStep,
  updateTestPlanStep,
  getCycle,
  getCycleFull,  
};
