export default [
  {
    text: 'Up to 24 hours',
    key: '24hours',
    value: '24hours',
  },
  {
    text: 'Up to 3 days',
    key: '3days',
    value: '3days',
  },
  {
    text: 'Up to 7 days',
    key: '7days',
    value: '7days',
  },
  {
    text: 'Up to 30 days',
    key: '30days',
    value: '30days',
  },
];
