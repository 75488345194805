import React from 'react';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { Theme } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { green } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import BugReportIcon from '@mui/icons-material/BugReport';

import ServiceInfo from '../ServiceInfo';
import InfoCard from '../../common/InfoCard';
import { RootState } from '../../../../redux/store';
import { BugStatus } from '../../../../types/BugStatus';
import {BugSeverityPieChart, BugStatusPieChart} from '../bugs/BugSeverityPieChart';
import { getCycleBugsByStatus } from '../../../../services/bugsService';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginTop: 50,
        marginBottom: 50,
        padding: '0 25px',
        [theme.breakpoints.down('lg')] : {
            marginTop: 15,
            marginBottom: 15,
            padding: '0 0px',        
        },        
        border: `1px solid ${theme.palette.primary.light}`,
    },
    info: {
        [theme.breakpoints.up('md')] : {
            height: 260,
        },
        [theme.breakpoints.down('lg')] : {
            paddingTop: 15,
        },
    },
    bugsCount: {
        marginLeft: 4,
        color: green[400],
    },
}));

const BugsInfo: React.FC<{ cycleId: string }> = ({ cycleId }) => {
    const classes = useStyles();

    const { cycles, cycleBugs, resolution } = useSelector((state: RootState) => state);

    const fixedBugs = getCycleBugsByStatus(cycleBugs, cycleId, BugStatus.Fixed);

    const cycle: any = cycles.find((c: any) => c.id === +cycleId);

    const isMobile = resolution.device === 'mobile' || resolution.device === 'mobile-tablet';

    return (
        <Box display="flex" flexDirection="column" className={classes.root}>
            <ServiceInfo cycleId={cycleId} />

            <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.info} flexDirection={isMobile ? "column": "row"}>
                <Box display="flex">
                    <InfoCard
                        description="Testers"
                        Icon={<LockIcon fontSize="large" color="primary" />}
                        title={`${cycle?.approvedTesters}/${cycle?.totalTesters}`}
                    />
                    <InfoCard
                        subtitle=""
                        title={`${cycle?.bugsFound}`}
                        Icon={<BugReportIcon fontSize="large" color="primary" />}
                        description={
                            <div>
                                Fixed bugs
                                <strong className={classes.bugsCount}>
                                    {fixedBugs.length}
                                </strong> 
                            </div>
                        }
                    />
                </Box>
                
                <BugStatusPieChart cycleId={cycleId} />
                <BugSeverityPieChart cycleId={cycleId} />
            </Box>
        </Box>
    ); 
};  

export default BugsInfo;