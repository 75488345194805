import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import CycleDetailsSidemenu from './Sidemenu';
import * as _ from 'lodash';
import noavatar from '../../../../public/images/noavatar.png';

const mapStateToProps = (state) => {
  return {
    user: state.user,
    cycles: state.cycles,
    chatMessages: state.chatMessages,
  };
};

class CustomerTestPlan extends Component<any> {
  public state: any;

  constructor(props) {
    super(props);

    this.state = {
      cycleId: +props.match.params.id,
      cycle: {},
    };
  }

  componentDidMount() {
    const cycle = this.props.cycles.find((el) => el.id === this.state.cycleId);
    this.setState({
      ...this.state,
      cycle,
    });
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.cycles, prevProps.cycles)) {
      const cycle = this.props.cycles.find((el) => el.id === this.state.cycleId);
      this.setState({ cycle });
    }
  }

  render() {
    const cycle = this.state.cycle;

    if (!cycle || !Object.keys(cycle).length) {
      return null;
    }

    const user = this.props.user;

    const testPlan = this.props.cycles.find((el) => el.id === cycle.id)?.testPlan;

    if (!testPlan) {
      return null;
    }

    const chatMessages = this.props.chatMessages.byCycleId[cycle.id];

    let unreadMessages = 0;

    for (const participantId of Object.keys(chatMessages)) {
      if (!chatMessages[participantId]) {
        continue;
      }

      for (const message of chatMessages[participantId]) {
        if (!message.read) {
          unreadMessages += 1;
        }
      }
    }

    return (
      <div className="account-customer page">
        <div className="heading">
          <h1>Test plan</h1>
        </div>

        <div className="inner" style={{ display: 'flex' }}>
          <CycleDetailsSidemenu
            companyName={user.companyName}
            balance={user.balance}
            avatarUrl={user.avatarUrl ? user.avatarUrl : noavatar}
            cycleEndDate={cycle.endDate}
            cycleId={cycle.id}
            workCategory={cycle.workCategory}
            unreadMessages={unreadMessages}
          />
          <main className="customer-test-plan">
            <h2>Test plan</h2>
            <h4>
              {cycle.title} {cycle.version && `, version: ${cycle.version}`}
            </h4>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>Step description</Table.HeaderCell>
                  <Table.HeaderCell>Expected result</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {testPlan.map((el, i) => (
                  <Table.Row key={i} verticalAlign="top">
                    <Table.Cell collapsing> {++i} </Table.Cell>
                    <Table.Cell  style={{ "word-break":"break-all" }} >{el.description}</Table.Cell> 
                    <Table.Cell>{el.expectedResult}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </main>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(CustomerTestPlan));
