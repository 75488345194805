import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Select, Table, Message, Icon } from 'semantic-ui-react';
import actions from '../../../../redux/actions';
import { OperationStatus } from '../../../../types/OperationStatus';
import { DesktopType } from '../../../../types/DesktopType';
import { UserStatus } from '../../../../types/UserStatus';
import { DevicePlatform } from '../../../../types/DevicePlatform';

const mapStateToProps = (state) => {
  return {
    addBrowserStatus: state.addBrowserStatus,
    userSaveStatus: state.userSaveStatus,
    user: state.user,
    browserNames: state.browsers.names,
    browserVersions: state.browsers.versions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (data) => dispatch(actions.updateUser(data)),
    saveUser: (data) => dispatch(actions.saveUser(data)),
    addBrowser: (data) => dispatch(actions.addBrowser(data)),
    deleteBrowser: (data) => dispatch(actions.deleteBrowser(data)),
  };
};

class Browsers extends Component<any> {
  public state: any;
  private desktopTypeOptions: any[];
  private browserOptions: any[];

  constructor(props) {
    super(props);
    this.state = {
      newBrowserId: '',
      newBrowserVersionId: '',
      browserVersions: [],
    };

    this.desktopTypeOptions = Object.keys(DesktopType).map((el) => ({
      key: el,
      value: DesktopType[el],
      text: el,
    }));

    this.browserOptions = this.props.browserNames.map((el) => ({
      key: el.id,
      value: el.id,
      text: el.name,
    }));
  }

  componentDidMount() {
    if (!this.props.user.desktopTypes.length) {
      this.props.updateUser({
        ...this.props.user,
        desktopTypes: [DesktopType.Windows],
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.userSaveStatus.status !== prevProps.userSaveStatus.status &&
      this.props.userSaveStatus.status === OperationStatus.success
    ) {
      this.props.updateUser({ status: UserStatus.EvalCyclePending });
      this.props.history.push('/account/tester');
    }
  }

  onChange = (event, data) => {
    const user = { ...this.props.user };

    switch (data.name) {
      case 'desktopType': {
        user.desktopTypes = [data.value];
        this.props.updateUser(user);
        break;
      }

      case 'newBrowserId': {
        const browserVersions = this.props.browserVersions.filter(
          (el) => el.browserId === data.value
        );

        return this.setState({
          ...this.state,
          newBrowserId: data.value,
          newBrowserVersionId: '',
          browserVersions,
        });
      }

      case 'newBrowserVersionId': {
        return this.setState({
          ...this.state,
          newBrowserVersionId: data.value,
        });
      }

      default:
        return;
    }
  };

  onAddBrowser = () => {
    // TODO maybe remove this and call from render()?
    this.props.addBrowser({
      platformId: DevicePlatform.Desktop,
      browserId: this.state.newBrowserId,
      browserVersionId: this.state.newBrowserVersionId,
    });
  };

  isBrowserAlreadyExists = (browser) => {
    const result = this.props.user.browsers.find(
      (el) => el.browserId === browser.id && el.browserVersionId === browser.versionId
    );

    if (result) {
      return true;
    }

    return false;
  };

  onDeleteBrowser = (event, data) => {
    this.props.deleteBrowser({
      browserId: data.browserId,
      browserVersionId: data.browserVersionId,
    });
  };

  onSave = () => {
    this.props.updateUser({ status: UserStatus.EvalCyclePending });
    this.props.saveUser(this.props.user);
  };

  render() {
    const browserVersionOptions = this.props.browserVersions
      .filter((el) => el.browserId === this.state.newBrowserId)
      .map((el) => ({
        key: el.id,
        value: el.id,
        text: el.version,
      }))
      .reverse();

    const processing = this.props.addBrowserStatus.status === OperationStatus.processing;
    const disabled =
      processing ||
      !this.state.newBrowserId ||
      !this.state.newBrowserVersionId ||
      this.isBrowserAlreadyExists({
        id: this.state.newBrowserId,
        versionId: this.state.newBrowserVersionId,
      });

    const browserNames = this.props.browserNames;
    const browserVersions = this.props.browserVersions;

    const user = this.props.user;
    const saveDisabled =
      (user.desktopTypes.includes(DesktopType.Windows) ||
        user.desktopTypes.includes(DesktopType.Mac)) &&
      !user.browsers.length;

    return (
      <div className="browsers">
        <Message info icon>
          <Icon name="world" />
          <Message.Content>
            <Message.Header>Register your desktop type and browsers</Message.Header>
          </Message.Content>
        </Message>
        <h3>Your desktop computer type</h3>
        <div className="add">
          <div className="desktop-types">
            <Select
              name="desktopType"
              options={this.desktopTypeOptions}
              value={[...user.desktopTypes][0]}
              onChange={this.onChange}
            />
          </div>
        </div>

        {!user.desktopTypes.includes(DesktopType.None) && (
          <React.Fragment>
            <h3>Add desktop browser</h3>
            <div className="add">
              <div className="input-devices">
                <span>Browser</span>
                <Select
                  name="newBrowserId"
                  options={this.browserOptions}
                  value={this.state.newBrowserId}
                  onChange={this.onChange}
                />
              </div>
              <div className="input-devices">
                <span>Version</span>
                <Select
                  name="newBrowserVersionId"
                  options={browserVersionOptions}
                  value={this.state.newBrowserVersionId}
                  onChange={this.onChange}
                  search
                />
              </div>
            </div>

            <Button primary loading={processing} disabled={disabled} onClick={this.onAddBrowser}>
              Add browser
            </Button>

            <h3>My browsers</h3>
            <Table compact celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Browser</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Version</Table.HeaderCell>
                  <Table.HeaderCell width={1}></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.user.browsers.map((el, i) => {
                  return (
                    <Table.Row key={i}>
                      <Table.Cell>
                        {browserNames.find((b) => b.id === el.browserId).name}
                      </Table.Cell>
                      <Table.Cell>
                        {browserVersions.find((v) => v.id === el.browserVersionId).version}
                      </Table.Cell>
                      <Table.Cell>
                        <Button
                          negative
                          compact
                          size="mini"
                          browserId={el.browserId}
                          browserVersionId={el.browserVersionId}
                          onClick={this.onDeleteBrowser}
                        >
                          Delete
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </React.Fragment>
        )}
        <Button
          className="save-and-next"
          primary
          icon
          size="big"
          labelPosition="right"
          disabled={saveDisabled}
          onClick={this.onSave}
        >
          Save &amp; next
          <Icon name="arrow right" />
        </Button>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Browsers));
