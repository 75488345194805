import React from 'react';
import { Tab } from 'semantic-ui-react';
import Sidemenu from '../Sidemenu';
import Personal from './Personal';
import Finance from './Finance';

const MyProfile = () => {
  const panes = [
    {
      menuItem: 'Personal',
      render: () => (
        <Tab.Pane>
          {' '}
          <Personal />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Finance',
      render: () => (
        <Tab.Pane>
          <Finance />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div className="account-customer page">
      <div className="heading">
        <h1>My Profile</h1>
      </div>

      <div className="inner" style={{ display: 'flex' }}>
        <Sidemenu />
        <main>
          <Tab panes={panes} />
        </main>
      </div>
    </div>
  );
};

export default MyProfile;
