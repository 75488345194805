import classes from '*.module.sass';
import {
  StepLabel,
  StepButton,
  Stepper,
  Step,
  Button,
  Container,
  Grid,
  Typography,
  Divider,
  Box,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { titleBlue } from './InnerForms/SharedStyle';
import React, { Component } from 'react';
import theme from './theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    customLabelStyle: {
      '& .MuiStepLabel-label': {
        fontFamily: "Poppins",
        fontSize: '16px',
      },
      '& .MuiStepLabel-label.Mui-active': {
        color: titleBlue,      
      },
      '& .MuiStepLabel-label.Mui-completed': {
        color: titleBlue,     
      }
    },
  }),
);


// A stateless Functional Component
const InnerStepLegend = (props) => {
    const classes = useStyles();

    const { activeInnerStep, currentActiveStep, innerStepDetails, stepperTitle, onProceedNext, handleBack, handleStepClick, completedSteps } = props;
    return(
            <>
                <Typography variant="h5" style={{marginTop:"50px", marginBottom:"50px", }}>
                    {stepperTitle}
                </Typography>                    
                <Stepper activeStep={activeInnerStep} orientation="vertical">
                    {innerStepDetails.map((innerPage, index) => ( 
                        ( true === innerPage["visible"]) &&
                        <Step key={innerPage.title} onClick={() => handleStepClick(index)}>
                            <StepButton icon={innerPage.label}>
                              <StepLabel className={  classes.customLabelStyle } >{innerPage.title} </StepLabel>
                            </StepButton>
                        </Step>                         
                    ))}
                </Stepper>                
            </>
    );
};


export default InnerStepLegend; 