import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router';
import { useParams, useRouteMatch, Route, BrowserRouter } from 'react-router-dom';

import { RootState } from '../../../redux/store';
import ServiceInfo from '../../../_components/accounts/customer/ServiceInfo';
import CustomerAccountLayout from '../../../layouts/account/CustomerAccountLayout';
import TestersList from '../../../_components/accounts/customer/testers/TestersList';
import SpecificTabReport from './SpecificTabReport';
import cycleActions from '../../../redux/actions/cycleActions';
import { OperationStatus } from '../../../types/OperationStatus'
import { Dimmer, Loader } from 'semantic-ui-react';

const CustomerDashboardPage = () => {
    
    const { cycleId } = useParams<{ cycleId: string }>();    
    
    const { path, url } = useRouteMatch();   

    const { cycles, cycleDataFetchStatus } = useSelector((state: RootState) => state);    

    const dispatch = useDispatch();
    let nextRefreshScheduler: any;
    
    //Look it up in the cache or query the server for it
    useEffect(function fetchCycleData() {
        const cycle: any = cycles.find((c: any) => c.id === +cycleId);
        if (!cycle) {
            dispatch(cycleActions.getCycleFull(cycleId));
        }            
    }, [cycleId]);

    useEffect(function refreshCycleData() {
        if( cycleDataFetchStatus.status === OperationStatus.success ){            
            nextRefreshScheduler = setTimeout( function() {dispatch(cycleActions.getCycleFull(cycleId))}, 10000 );
        }
        return () => {            
            clearInterval(nextRefreshScheduler);
        };
    }, [cycleDataFetchStatus.status]);

    const cycleData: any = cycles.find((c: any) => c.id === +cycleId);
        
    //We show loading animation only for first fetch from server (cycleData is null), next occurances render the page        
    return (
        <CustomerAccountLayout>
            {!cycleData && cycleDataFetchStatus.status === OperationStatus.processing ? (            
              <Loader style={{marginTop: 300}} active inline='centered'></Loader>            
            ) : (
                <Switch>
                    <Route exact path={path}>
                        <Redirect to={`${url}/report`} />            
                    </Route>
                    <Route 
                        path={`${url}/:topicId`}
                        render={() => 
                            <SpecificTabReport cycleId={cycleId}
                        />}                        
                    />
                </Switch>
            )}         
        </CustomerAccountLayout> 
    );
};
export default CustomerDashboardPage;