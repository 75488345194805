import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { UserRole } from '../../types/UserRole';
import { Mixpanel } from '../../util/mixpanel';
import CheckoutForm from './InnerForms/CheckoutForm';
import DurationForm from './InnerForms/DurationForm';
import GeneralDetailsForm from './InnerForms/GeneralDetailsForm';
import LocationForm from './InnerForms/LocationForm';
import PeriodForm from './InnerForms/PeriodForm';
import PlanForm from './InnerForms/PlanForm';
import PlatformAndTestersForm from './InnerForms/PlatformAndTestersForm';
import SpecificationForm from './InnerForms/SpecificationForm';
import SummaryForm from './InnerForms/SummaryForm';
import TypeForm from './InnerForms/TypeForm';


const InnerContainerFactory = (props) => {
    const innerFormsCollection = {
        TypeForm: TypeForm,
        DurationForm: DurationForm,
        PeriodForm: PeriodForm,
        LocationForm: LocationForm,

        GeneralDetailsForm: GeneralDetailsForm,
        SpecificationForm: SpecificationForm,
        PlanForm: PlanForm,

        /* PlatformForm: PlatformForm,
        DevicesForm: DevicesForm, */
        PlatformAndTestersForm: PlatformAndTestersForm,
        SummaryForm: SummaryForm,
        CheckoutForm: CheckoutForm,
    };

    const { user } = useSelector((state: RootState) => state);

    const SpecificInnerForm = innerFormsCollection[props.TagName];

    useEffect(() => {
        let userId = user.role == UserRole.Anonymous ? -1 : user.id;
        Mixpanel.track('Entered New Cycle Wizard Form', { userId, 'wizardStep': props.TagName });
    }, [props.TagName]);

    return (
        <React.Fragment>
            <Typography align="left" variant="h3">
                {props.Title}
            </Typography>
            <Typography align="left" variant="h4" style={{ marginTop: "15px" }} >
                {props.SubTitle}
            </Typography>
            <Box mt="60px" ml="10px">
                <SpecificInnerForm onNext={props.onNext} />
            </Box>
        </React.Fragment>
    );
}

export default InnerContainerFactory;
