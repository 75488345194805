import {
	Box,
	Step,
	StepIconProps,
	StepLabel,
	Stepper,
} from '@mui/material';
import React from 'react';


import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LoupeOutlinedIcon from '@mui/icons-material/LoupeOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingCartCheckoutOutlinedIcon from '@mui/icons-material/ShoppingCartCheckoutOutlined';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 22,
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundImage:
				/* 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)', */
				'linear-gradient( 95deg,rgb(87,37,154) 0%,rgb(0,128,192) 50%,rgb(60,132,98) 100%)',
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundImage:
				/* 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)', */
				'linear-gradient( 95deg,rgb(87,37,154) 0%,rgb(0,128,192) 50%,rgb(60,132,98) 100%)',
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		height: 3,
		border: 0,
		backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
		borderRadius: 1,
	},
}));

const ColorlibStepIconRoot = styled('div')<{
	ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
	zIndex: 1,
	color: '#fff',
	width: 50,
	height: 50,
	display: 'flex',
	borderRadius: '50%',
	justifyContent: 'center',
	alignItems: 'center',
	...(ownerState.active && {
		backgroundImage:
			'linear-gradient( 136deg, rgb(87,37,154) 0%, rgb(0,128,192) 50%, rgb(60,132,98) 100%)',
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.75)',
	}),
	...(ownerState.completed && {
		backgroundImage:
			'linear-gradient( 136deg, rgb(87,37,154) 0%, rgb(0,128,192) 50%, rgb(60,132,98) 100%)',
	}),
}));

function ColorlibStepIcon(props: StepIconProps) {
	const { active, completed, className } = props;
	const icons: { [index: string]: React.ReactElement } = {
		1: <SettingsIcon />,
		2: <LoupeOutlinedIcon />,
		3: <CheckCircleIcon />,
		4: <ShoppingCartCheckoutOutlinedIcon />,
	};

	return (
		<ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
			{icons[String(props.icon)]}
		</ColorlibStepIconRoot>
	);
}

const steps = ['1. General Details', '2. Test Specification', '3. Summary', '4. Finish & Checkout'];

const MainStepper = (props) => {
	const { activeStep, pricingType } = props;

	/* useEffect(() => {
		if (pricingType === 'firstcycle') {
			steps.pop();
		}
	}, []); */

	return (
		<Box /* style={{ minWidth: '400px', width: '50%' }} */>
			<Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
				{steps.map((label) => (
					<Step key={label}>
						<StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
					</Step>
				))}
			</Stepper>
		</Box>
	);
};

export default MainStepper;