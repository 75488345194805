import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Menu, Label, Icon, Button, Popup } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import StarRatingComponent from 'react-star-rating-component';
import Countdown, { zeroPad } from 'react-countdown';
import moment from 'moment';
import { WorkCategory } from '../../../../types/WorkCategory';
import { TesterStatusInCycle } from '../../../../types/TesterStatusInCycle';
import actions from '../../../../redux/actions';
import noavatar from '../../../../public/images/noavatar.png';
import { CycleStatus } from '../../../../types/CycleStatus';

const mapStateToProps = (state) => {
  return {
    user: state.user,
    cycles: state.cycles,
    chatMessages: state.chatMessages,
    device: state.resolution.device,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    completeCycle: (cycleId) => dispatch(actions.completeCycle(cycleId)),
    listChatMessages: (cycleId, participantId) =>
      dispatch(actions.listChatMessages(cycleId, participantId)),
  };
};

const TestPlanStepCounter = ({ testPlanStepsPassed, testPlanStepsTotal }) => {
  const testPlanFinished = testPlanStepsPassed === testPlanStepsTotal;

  if (testPlanFinished) {
    return (
      <Label color="green">
        {testPlanStepsPassed} / {testPlanStepsTotal}
      </Label>
    );
  } else {
    return (
      <Label>
        {testPlanStepsPassed} / {testPlanStepsTotal}
      </Label>
    );
  }
};

const FindBugsExploratoryMenu = ({
  cycleId,
  cycleEndDate,
  bugsFound,
  unreadMessages,
  testerStatus,
}) => {
  const showCountdown = moment().isBefore(moment(cycleEndDate));

  return (
    <div>
      {showCountdown && (
        <div className="sidemenu-countdown">
          Cycle ends in
          <br />
          <Countdown
            date={cycleEndDate}
            daysInHours={false}
            renderer={({ days, hours, minutes, seconds }) => {
              return `${days} days, ${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`;
            }}
          />
        </div>
      )}

      <Menu vertical as="nav">
        <Menu.Item as={NavLink} to={`/account/tester/cycle/${cycleId}`} exact>
          Cycle details
        </Menu.Item>

        {(testerStatus === TesterStatusInCycle.Approved ||
          testerStatus === TesterStatusInCycle.AwaitingReview ||
          testerStatus === TesterStatusInCycle.Finished) && (
          <Menu.Item as={NavLink} to={`/account/tester/cycle/${cycleId}/newbug`}>
            New bug
          </Menu.Item>
        )}
        {(testerStatus === TesterStatusInCycle.Approved ||
          testerStatus === TesterStatusInCycle.AwaitingReview ||
          testerStatus === TesterStatusInCycle.Finished) && (
          <Menu.Item as={NavLink} to={`/account/tester/cycle/${cycleId}/bugs`}>
            Reported bugs
            <Label>{bugsFound}</Label>
          </Menu.Item>
        )}
        {(testerStatus === TesterStatusInCycle.Approved ||
          testerStatus === TesterStatusInCycle.AwaitingReview ||
          testerStatus === TesterStatusInCycle.Finished) && (
          <Menu.Item as={NavLink} to={`/account/tester/cycle/${cycleId}/messages`}>
            Messages
            {unreadMessages > 0 && <Label content={unreadMessages} />}
          </Menu.Item>
        )}

        <Menu.Item as={NavLink} to="/account/tester" exact>
          <Icon name="chevron circle left" /> Back to dashboard
        </Menu.Item>
      </Menu>
    </div>
  );
};

const FeedbackMenu = ({ cycleId, cycleEndDate, unreadMessages, testerStatus }) => {
  return (
    <Menu vertical as="nav">
      <Menu.Item as={NavLink} to={`/account/tester/cycle/${cycleId}`} exact>
        Cycle details
      </Menu.Item>

      <Menu.Item as={NavLink} to={`/account/tester/cycle/${cycleId}/questions`}>
        Questions
      </Menu.Item>

      {(testerStatus === TesterStatusInCycle.Approved ||
        testerStatus === TesterStatusInCycle.AwaitingReview ||
        testerStatus === TesterStatusInCycle.Finished) && (
        <Menu.Item as={NavLink} to={`/account/tester/cycle/${cycleId}/messages`}>
          Messages
          {unreadMessages > 0 && <Label content={unreadMessages} />}
        </Menu.Item>
      )}

      <Menu.Item as={NavLink} to="/account/tester" exact>
        <Icon name="chevron circle left" /> Back to dashboard
      </Menu.Item>
    </Menu>
  );
};

const FindBugsTestPlanMenu = ({
  cycleId,
  bugsFound,
  unreadMessages,
  cycleEndDate,
  testerStatus,
  testPlanStepsPassed,
  testPlanStepsTotal,
}) => {
  const showCountdown = moment().isBefore(moment(cycleEndDate));
  const stepCounterVisible = [
    TesterStatusInCycle.Approved,
    TesterStatusInCycle.AwaitingReview,
    TesterStatusInCycle.Finished,
  ].includes(testerStatus);

  return (
    <div>
      {showCountdown && (
        <div className="sidemenu-countdown">
          Cycle ends in
          <br />
          <Countdown
            date={cycleEndDate}
            daysInHours={false}
            renderer={({ days, hours, minutes, seconds }) => {
              return `${days} days, ${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`;
            }}
          />
        </div>
      )}
      <Menu vertical as="nav">
        <Menu.Item as={NavLink} to={`/account/tester/cycle/${cycleId}`} exact>
          Cycle details
        </Menu.Item>

        <Menu.Item as={NavLink} to={`/account/tester/cycle/${cycleId}/testplan`}>
          Test plan
          {stepCounterVisible && (
            <TestPlanStepCounter
              testPlanStepsPassed={testPlanStepsPassed}
              testPlanStepsTotal={testPlanStepsTotal}
            />
          )}
        </Menu.Item>

        {(testerStatus === TesterStatusInCycle.Approved ||
          testerStatus === TesterStatusInCycle.AwaitingReview ||
          testerStatus === TesterStatusInCycle.Finished) && (
          <Menu.Item as={NavLink} to={`/account/tester/cycle/${cycleId}/newbug`}>
            New bug
          </Menu.Item>
        )}
        {(testerStatus === TesterStatusInCycle.Approved ||
          testerStatus === TesterStatusInCycle.AwaitingReview ||
          testerStatus === TesterStatusInCycle.Finished) && (
          <Menu.Item as={NavLink} to={`/account/tester/cycle/${cycleId}/bugs`}>
            Reported bugs
            <Label>{bugsFound}</Label>
          </Menu.Item>
        )}

        {(testerStatus === TesterStatusInCycle.Approved ||
          testerStatus === TesterStatusInCycle.AwaitingReview ||
          testerStatus === TesterStatusInCycle.Finished) && (
          <Menu.Item as={NavLink} to={`/account/tester/cycle/${cycleId}/messages`}>
            Messages
            {unreadMessages > 0 && <Label content={unreadMessages} />}
          </Menu.Item>
        )}

        <Menu.Item as={NavLink} to="/account/tester" exact>
          <Icon name="chevron circle left" /> Back to dashboard
        </Menu.Item>
      </Menu>
    </div>
  );
};

class Sidemenu extends Component<any> {
  public state: any;
  private listInterval: any;

  constructor(props) {
    super(props);

    this.state = {
      cycleId: props.cycleId, // TODO consider taking it directly from URL
      unreadMessages: props.unreadMessages,
      testPlanStepsPassed: null,
      testPlanStepsTotal: null,
    };
  }

  componentDidMount() {
    const cycle = this.props.cycles.find((el) => el.id === this.state.cycleId);
    const participantId = cycle.ownerId;

    this.listInterval = setInterval(
      () => this.props.listChatMessages(this.state.cycleId, participantId),
      10000
    );
  }

  componentDidUpdate(prevProps) {
    const cycle = this.props.cycles.find((el) => el.id === this.state.cycleId);
    const cyclePrev = prevProps.cycles.find((el) => el.id === this.state.cycleId);
    if( cyclePrev.testerStatus != cycle.testerStatus && cycle.testerStatus == TesterStatusInCycle.AwaitingReview )
      {
        this.props.history.push('/account');
      }
  }

  componentWillUnmount() {
    clearInterval(this.listInterval);
  }

  onCompleteCycle = (event, data) => {
    event.preventDefault();

    const cycleId = parseInt(data.value);

    if (this.isFinishEnabled()) {
      this.props.completeCycle(cycleId);
    } else {
      console.error("Cycle can't be completed");
    }
  };

  isFinishEnabled = () => {
    const cycle = this.props.cycles.find((el) => el.id === this.state.cycleId);

    if (!cycle) {
      return false;
    }

    if (cycle.status === CycleStatus.Completed) {
      return false;
    }

    switch (cycle.workCategory) {
      case WorkCategory.FeedbackAndRating:
        return true;

      case WorkCategory.FindBugsExploratory:
        if (cycle.bugsFound) {
          return true;
        }

        return false;

      case WorkCategory.FindBugsTestPlan:
        if (cycle.bugsFound && cycle.testPlan.length === cycle.testPlanResults.length) {
          return true;
        }

        return false;

      default:
        console.error(`Unknown work category for cycleId ${cycle.id}:`, cycle.workCategory);
        return false;
    }
  };

  render() {
    const cycle = this.props.cycles.find((el) => el.id === this.state.cycleId);

    if (!cycle || !Object.keys(cycle).length) {
      return null;
    }

    const user = this.props.user;
    const avatarUrl = user.avatarUrl || noavatar;

    let workCategorySidmenu;

    let unreadMessages;

    const chatMessages = this.props.chatMessages.byCycleId[cycle.id] || {};

    if (chatMessages[cycle.ownerId]) {
      unreadMessages = chatMessages[cycle.ownerId].reduce((result, message) => {
        if (!message.read) {
          result += 1;
        }

        return result;
      }, 0);
    } else {
      unreadMessages = 0;
    }

    switch (cycle.workCategory) {
      case WorkCategory.FeedbackAndRating:
        workCategorySidmenu = (
          <FeedbackMenu
            cycleId={cycle.id}
            cycleEndDate={cycle.endDate}
            testerStatus={cycle.testerStatus}
            unreadMessages={unreadMessages}
          />
        );
        break;

      case WorkCategory.FindBugsExploratory:
        workCategorySidmenu = (
          <FindBugsExploratoryMenu
            cycleId={cycle.id}
            cycleEndDate={cycle.endDate}
            bugsFound={cycle.bugsFound}
            unreadMessages={unreadMessages}
            testerStatus={cycle.testerStatus}
          />
        );
        break;

      case WorkCategory.FindBugsTestPlan:
        workCategorySidmenu = (
          <FindBugsTestPlanMenu
            cycleId={cycle.id}
            cycleEndDate={cycle.endDate}
            bugsFound={cycle.bugsFound}
            unreadMessages={unreadMessages}
            testerStatus={cycle.testerStatus}
            testPlanStepsPassed={cycle.testPlanResults?.length}
            testPlanStepsTotal={cycle.testPlan.length}
          />
        );
        break;

      default:
        workCategorySidmenu = null;
        break;
    }

    const finishEnabled = this.isFinishEnabled();

    const isMobile = this.props.device === 'mobile' || this.props.device === 'mobile-tablet';

    const styleCol = {
      display: "flex",
      justifyContent: "left",
      alignItems: "center",
    };

    if( isMobile ){
      return(
        <div style={{marginLeft: 5}}>
          <div style={styleCol}>
            <img src={avatarUrl} alt="avatar" height="64" width="64" />
            <div style={{marginLeft: 15}}>
              <span>{user.firstName}</span>
              <span>{user.lastName}</span>
              <div>
                <StarRatingComponent name="rate1" starCount={5} value={parseInt(user.rating, 10)} />
                <span>{parseFloat(user.rating).toFixed(2)}</span>
              </div>
              {user.addedToFavorites > 0 && (
                <p className=" added-to-favorites">Added to favorites: {user.addedToFavorites}</p>
              )}
            </div>
          </div>

          <div style={{marginTop: 15}}>
            {workCategorySidmenu}
          </div>

          {cycle.testerStatus === TesterStatusInCycle.Approved && (
            <div
              style={{
                margin: '1rem 0',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'cender',
              }}
            >
              <Button
                className="i-have-finished"
                positive
                icon
                labelPosition="right"
                disabled={!finishEnabled}
                value={cycle.id}
                onClick={this.onCompleteCycle}
              >
                I have finished
                <Icon name="check" />
              </Button>

              {!finishEnabled && (
                <Popup
                  on="click"
                  pinned
                  trigger={
                    <a style={{ textAlign: 'center' }}>
                      Why is this
                      <br />
                      button disabled?
                    </a>
                  }
                >
                  In order to finish, please report <strong>New bug</strong>s and complete the{' '}
                  <strong>Test plan</strong> (if exists)
                </Popup>
              )}
            </div>
          )}

          {(cycle.testerStatus === TesterStatusInCycle.AwaitingReview || cycle.testerStatus === TesterStatusInCycle.Finished) /*&& !this.isFinishEnabled*/ && (
            <div
            style={{
              margin: '1rem 0',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'cender',
            }}
          >
            <Button
              className="i-have-finished"
              //positive
              icon
              labelPosition="right"
              disabled={true}        
            >
              Completed!
              <Icon name="check" />
            </Button>
            <p style={{ textAlign: 'center' }}>
              You have completed the test cycle, For your convenience you can still add/edit bugs
            </p>                      
            </div>
          )}

      </div>
      );
    }
    return (
      <aside>
        <div className="summary">
          <img className="avatar" src={avatarUrl} alt="avatar" height="64" width="64" />
          <div className="details">
            <span className="first-name">{user.firstName}</span>
            <span className="last-name">{user.lastName}</span>
            <div className="rating">
              <StarRatingComponent name="rate1" starCount={5} value={parseInt(user.rating, 10)} />
              <span>{parseFloat(user.rating).toFixed(2)}</span>
            </div>
            {user.addedToFavorites > 0 && (
              <p className=" added-to-favorites">Added to favorites: {user.addedToFavorites}</p>
            )}
          </div>
        </div>

        {workCategorySidmenu}

        {cycle.testerStatus === TesterStatusInCycle.Approved && (
          <div
            style={{
              margin: '1rem 0',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'cender',
            }}
          >
            <Button
              className="i-have-finished"
              positive
              icon
              labelPosition="right"
              disabled={!finishEnabled}
              value={cycle.id}
              onClick={this.onCompleteCycle}
            >
              I have finished
              <Icon name="check" />
            </Button>

            {!finishEnabled && (
              <Popup
                on="click"
                pinned
                trigger={
                  <a style={{ textAlign: 'center' }}>
                    Why is this
                    <br />
                    button disabled?
                  </a>
                }
              >
                In order to finish, please report <strong>New bug</strong>s and complete the{' '}
                <strong>Test plan</strong> (if exists)
              </Popup>
            )}
          </div>
        )}

        {(cycle.testerStatus === TesterStatusInCycle.AwaitingReview || cycle.testerStatus === TesterStatusInCycle.Finished) /*&& !this.isFinishEnabled*/ && (
          <div
          style={{
            margin: '1rem 0',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'cender',
          }}
        >
          <Button
            className="i-have-finished"
            //positive
            icon
            labelPosition="right"
            disabled={true}        
          >
            Completed!
            <Icon name="check" />
          </Button>
          <p style={{ textAlign: 'center' }}>
            You have completed the test cycle, For your convenience you can still add/edit bugs
          </p>                      
          </div>
        )}

      </aside>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidemenu);
