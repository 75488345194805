import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Table, Button, Icon, Message, Popup } from 'semantic-ui-react';
import CycleDetailsSidemenu from './Sidemenu';
import { WorkCategory } from '../../../../types/WorkCategory';
import { TesterStatusInCycle } from '../../../../types/TesterStatusInCycle';
import { UserStatus } from '../../../../types/UserStatus';
import {
  getPlatformPrettyName,
  getApprovedDevicePrettyName,
} from '../../../../services/deviceService';
import { CycleStatus } from '../../../../types/CycleStatus';
import _ from 'lodash';
import cycleActions from '../../../../redux/actions/cycleActions';
import styled from 'styled-components';
import config from '../../../../config';

const Wtf = styled.div`
  h1,
  h2,
  h3 {
    color: initial;
    margin: 10px;
  }
  h4,
  h5,
  h6 {
    color: initial;
    margin: 5px;
  }
`;

const mapStateToProps = (state) => ({
  user: state.user,
  cycles: state.cycles,
  chatMessages: state.chatMessages,
  devices: state.devices,
  browsers: state.browsers,
  device: state.resolution.device,
});

const mapDispatchToProps = (dispatch) => ({
  listCycles: () => dispatch(cycleActions.listCycles()),
});

const PlatformUrls = ({ data }) => {
  return data.map((el, i) => (
    <div className="platform-url" key={i}>
      <span className="name">{getPlatformPrettyName(el.platformId)}</span>
      <span className="url">
        <a target="_blank" href={el.url} rel="noopener noreferrer">
          {el.url}
        </a>
      </span>
    </div>
  ));
};

const CycleDetailsIntroduction = () => {
  return (
    <Message info icon>
      <Icon size="big" name="exclamation" />
      <Message.Content>
        <Message.Header>Please review the test cycle details below</Message.Header>
        <p>
          Notice the left navigation menu for additional details. To accept this test cycle, click
          on &quot;Proceed to device selection&quot;, select the devices or browsers you wish to use
          for this test cycle, and then click on &quot;Accept&quot;
        </p>
      </Message.Content>
    </Message>
  );
};

const ProceedToDeviceSelectionButton = ({ userStatus, cycle }) => {
  if (cycle.id === config.evalCycleId && !cycle.testerStatus) {
    return (
      <Button
        positive
        icon
        size="large"
        as={Link}
        to={`/account/tester/cycle/${cycle.id}/apply`}
        labelPosition="right"
      >
        Proceed to device selection
        <Icon name="arrow right" />
      </Button>
    );
  }

  const isRejected =
    cycle.testerStatus === TesterStatusInCycle.Rejected ||
    (typeof cycle.testerStatus === 'undefined' && cycle.status === CycleStatus.Testing);

  if (userStatus === UserStatus.Active) {
    if (isRejected) {
      return (
        <Button
          disabled
          positive
          icon
          size="large"
          as={Link}
          to={`/account/tester/cycle/${cycle.id}/apply`}
          labelPosition="right"
        >
          Bid closed
          <Icon name="arrow right" />
        </Button>
      );
    } else {
      if( cycle.testerStatus === TesterStatusInCycle.Pending ){
        return (
          <Button
          positive
          icon
          size="large"
          as={Link}
          to={`/account/tester/cycle/${cycle.id}/apply`}
          labelPosition="right"
        >
          Proceed to view my Bid
          <Icon name="arrow right" />
        </Button>
        );
      }
      else {
        return (
          <Button
          positive
          icon
          size="large"
          as={Link}
          to={`/account/tester/cycle/${cycle.id}/apply`}
          labelPosition="right"
        >
          Proceed to device selection
          <Icon name="arrow right" />
        </Button>
        );
      }      
    }
  }

  if (userStatus === UserStatus.EvalCyclePending) {
    if (cycle.testerStatus === TesterStatusInCycle.Rejected) {
      return (
        <Button
          disabled
          positive
          icon
          size="large"
          as={Link}
          to={`/account/tester/cycle/${cycle.id}/apply`}
          labelPosition="right"
        >
          Bid closed
          <Icon name="arrow right" />
        </Button>
      );
    } else {
      return (
        <Popup
            trigger={<Button icon size="large" labelPosition="right">
                          Proceed to device selection
                          <Icon name="arrow right" />
                      </Button>}
            content="You should complete the test cycle for new testers and get a rating from the Testpoint team first"
            on="hover"
            position="top left"
        />
      );
    }
  }

  return null;
};

class CycleDetails extends Component<any> {
  public state: any;

  constructor(props) {
    super(props);

    this.state = {
      cycleId: parseInt(props.match.params.id, 10),
      cycle: null,
    };
  }

  componentDidMount() {
    if (!this.props.cycles.length) {
      this.props.listCycles();
    } else {
      const cycle = this.props.cycles.find((el) => el.id === this.state.cycleId);
      this.setState({ cycle });
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.cycles, prevProps.cycles)) {
      const cycle = this.props.cycles.find((el) => el.id === this.state.cycleId);
      this.setState({ cycle });
    }
  }

  render() {
    const cycle = this.props.cycles.find((el) => el.id === this.state.cycleId);    

    if (!cycle) {
      return null;
    }

    const isMobile = this.props.device === 'mobile' || this.props.device === 'mobile-tablet';

    let approvedDeviceName = '';

    if (
      [
        TesterStatusInCycle.Approved,
        TesterStatusInCycle.AwaitingReview,
        TesterStatusInCycle.Finished,
      ].includes(cycle.testerStatus)
    ) {
      approvedDeviceName = getApprovedDevicePrettyName(cycle.approvedDevice);
    }

    let workCategory;

    switch (cycle.workCategory) {
      case WorkCategory.FeedbackAndRating:
        workCategory = 'Get feedback and star rating';
        break;
      case WorkCategory.FindBugsExploratory:
        workCategory = 'Find bugs - exploratory';
        break;
      case WorkCategory.FindBugsTestPlan:
        workCategory = 'Find bugs - test plan';
        break;
    }

    const platformNames = cycle.platformUrls
      .map((el) => getPlatformPrettyName(el.platformId))
      .join(', ');

    const deviceNames = cycle.testEnvs
      .filter((el) => el.type !== 'Platform')
      .map((el) => el.name)
      .join(', ');

    const urlsData = cycle.testEnvs.map((el) => {
      const name = el.type === 'Platform' ? getPlatformPrettyName(el.platformId) : el.name;

      return {
        name,
        url: el.url,
      };
    });

    if(isMobile) {
      return (
        <div>
          <CycleDetailsSidemenu cycleId={cycle.id} cycleEndDate={cycle.endDate} />

          <div className="cycle-details" style={{marginTop: 15, marginLeft: 5, marginRight: 5}}>
            {this.props.user.status === UserStatus.EvalCyclePending && !cycle.testerStatus && (
              <CycleDetailsIntroduction />
            )}

            <h2>Cycle details</h2>
            <h4>
              {cycle.title} {cycle.version && `, version: ${cycle.version}`}
            </h4>

            {cycle.status === CycleStatus.Completed && (
              <Message info>
                <Message.Header>This cycle has finished</Message.Header>
                <Link to="/account/tester">Go to dashboard</Link>
              </Message>
            )}

            <Table definition className="cycle-details-table">
              <Table.Body>
                {approvedDeviceName.length > 0 && (
                  <Table.Row>
                    <Table.Cell>Your chosen device</Table.Cell>
                    <Table.Cell>
                      {approvedDeviceName} <i>(you must test with this device)</i>
                    </Table.Cell>
                  </Table.Row>
                )}
                <Table.Row>
                  <Table.Cell>
                    Product description,
                    <br />
                    testing guidelines
                  </Table.Cell>
                  <Table.Cell>
                    <Wtf dangerouslySetInnerHTML={{ __html: cycle.description }} />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Customer name</Table.Cell>
                  <Table.Cell>{cycle.ownerCompanyName}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Deliverable</Table.Cell>
                  <Table.Cell>{workCategory}</Table.Cell>
                </Table.Row>
                {platformNames.length > 0 && (
                  <Table.Row>
                    <Table.Cell>Platforms</Table.Cell>
                    <Table.Cell>{platformNames}</Table.Cell>
                  </Table.Row>
                )}
                {deviceNames.length > 0 && (
                  <Table.Row>
                    <Table.Cell>Devices</Table.Cell>
                    <Table.Cell>{deviceNames}</Table.Cell>
                  </Table.Row>
                )}
                {cycle.testerStatus && (
                  <Table.Row>
                    <Table.Cell>App/browser links</Table.Cell>
                    <Table.Cell>
                      <PlatformUrls data={cycle.platformUrls} />
                    </Table.Cell>
                  </Table.Row>
                )}
                <Table.Row>
                  <Table.Cell>Priority for favorite testers</Table.Cell>
                  <Table.Cell>{cycle.useFavoriteTesters ? 'Yes' : 'No'}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Requested hours</Table.Cell>
                  <Table.Cell>{cycle.hoursPerTester}</Table.Cell>
                </Table.Row>
                {cycle.workCategory === WorkCategory.FeedbackAndRating && (
                  <Table.Row>
                    <Table.Cell>Questions</Table.Cell>
                    <Table.Cell>
                      <ul>
                        {cycle.questions.map((el, i) => (
                          <li key={i}>{el}</li>
                        ))}
                      </ul>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>

            <ProceedToDeviceSelectionButton userStatus={this.props.user.status} cycle={cycle} />
            
            {/* {!cycle.testerStatus && (
              <Button
                disabled
                positive
                icon
                size="large"
                as={Link}
                to={`/account/tester/cycle/${cycle.id}/apply`}
                labelPosition="right"
              >
                Proceed to device selection
                <Icon name="arrow right" />
              </Button>
            )} */}
          </div>
        </div>      
      );
    }
    return (
      <div className="account-tester page">
        <div className="heading">
          <h1>Cycle Details</h1>
        </div>

        <div className="inner" style={{ display: 'flex' }}>
          <CycleDetailsSidemenu cycleId={cycle.id} cycleEndDate={cycle.endDate} />

          <main className="cycle-details">
            {this.props.user.status === UserStatus.EvalCyclePending && !cycle.testerStatus && (
              <CycleDetailsIntroduction />
            )}

            <h2>Cycle details</h2>
            <h4>
              {cycle.title} {cycle.version && `, version: ${cycle.version}`}
            </h4>

            {cycle.status === CycleStatus.Completed && (
              <Message info>
                <Message.Header>This cycle has finished</Message.Header>
                <Link to="/account/tester">Go to dashboard</Link>
              </Message>
            )}

            <Table definition className="cycle-details-table">
              <Table.Body>
                {approvedDeviceName.length > 0 && (
                  <Table.Row>
                    <Table.Cell>Your chosen device</Table.Cell>
                    <Table.Cell>
                      {approvedDeviceName} <i>(you must test with this device)</i>
                    </Table.Cell>
                  </Table.Row>
                )}
                <Table.Row>
                  <Table.Cell>
                    Product description,
                    <br />
                    testing guidelines
                  </Table.Cell>
                  <Table.Cell>
                    <Wtf dangerouslySetInnerHTML={{ __html: cycle.description }} />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Customer name</Table.Cell>
                  <Table.Cell>{cycle.ownerCompanyName}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Deliverable</Table.Cell>
                  <Table.Cell>{workCategory}</Table.Cell>
                </Table.Row>
                {platformNames.length > 0 && (
                  <Table.Row>
                    <Table.Cell>Platforms</Table.Cell>
                    <Table.Cell>{platformNames}</Table.Cell>
                  </Table.Row>
                )}
                {deviceNames.length > 0 && (
                  <Table.Row>
                    <Table.Cell>Devices</Table.Cell>
                    <Table.Cell>{deviceNames}</Table.Cell>
                  </Table.Row>
                )}
                {cycle.testerStatus && (
                  <Table.Row>
                    <Table.Cell>App/browser links</Table.Cell>
                    <Table.Cell>
                      <PlatformUrls data={cycle.platformUrls} />
                    </Table.Cell>
                  </Table.Row>
                )}
                <Table.Row>
                  <Table.Cell>Priority for favorite testers</Table.Cell>
                  <Table.Cell>{cycle.useFavoriteTesters ? 'Yes' : 'No'}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Requested hours</Table.Cell>
                  <Table.Cell>{cycle.hoursPerTester}</Table.Cell>
                </Table.Row>
                {cycle.workCategory === WorkCategory.FeedbackAndRating && (
                  <Table.Row>
                    <Table.Cell>Questions</Table.Cell>
                    <Table.Cell>
                      <ul>
                        {cycle.questions.map((el, i) => (
                          <li key={i}>{el}</li>
                        ))}
                      </ul>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>

            <ProceedToDeviceSelectionButton userStatus={this.props.user.status} cycle={cycle} />

            {/* {!cycle.testerStatus && (
              <Button
                positive
                icon
                size="large"
                as={Link}
                to={`/account/tester/cycle/${cycle.id}/apply`}
                labelPosition="right"
              >
                Proceed to device selection
                <Icon name="arrow right" />
              </Button>
            )} */}
          </main>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CycleDetails));
