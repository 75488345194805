import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import WifiIcon from '@mui/icons-material/Wifi';
import { blue } from '@mui/material/colors';
import ListItem from '@mui/material/ListItem';
import FileIcon from '@mui/icons-material/FileCopy';
import SettingsIcon from '@mui/icons-material/Settings';
import BellIcon from '@mui/icons-material/Notifications';
import makeStyles from '@mui/styles/makeStyles';
import UserIcon from '@mui/icons-material/PersonOutlineRounded';
import { useHistory } from 'react-router';

const useStyles = makeStyles(() => ({
    root: {
        height: 'calc(100vh - 97px)',
    },
    logoWrapper: {
        width: 46,
        height: 46,
        margin: 'auto',
        borderTopLeftRadius: 5,
        backgroundColor: blue[900],
        borderTopRightRadius: '30%',
        borderBottomRightRadius: 5,
        borderBottomLeftRadius: '30%', 
    },
    logoInner: {
        width: 30,
        height: 30,
        fontSize: 22,
        margin: 'auto',
        color: blue[900],
        fontWeight: 'bold',
        borderRadius: '100%',
        backgroundColor: '#ffffff',
    },
    bottomList: {
        marginBottom: 20,
    },
}));

const SideMenu: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            justifyContent="space-between"
        >
            <List>
                <ListItem alignItems="center">
                    <Button>
                        <Box display="flex" alignItems="center" className={classes.logoWrapper}>
                            <Box display="flex" alignItems="center" justifyContent="center" className={classes.logoInner}>T</Box>
                        </Box>
                    </Button>
                </ListItem>
                <ListItem>
                    <Button onClick={() => history.push('/account/customer/myprofile')}>
                        <UserIcon />
                    </Button>
                </ListItem>
                {/*
                <ListItem>
                    <Button>
                        <FileIcon />
                    </Button>
                </ListItem>
                <ListItem>
                    <Button>
                        <WifiIcon />
                    </Button>
                </ListItem>
                <ListItem>
                    <Button>
                        <BellIcon />
                    </Button>
                </ListItem>
                <ListItem>
                    <Button>
                        <SettingsIcon />
                    </Button>
                </ListItem>
                */}
            </List>

            {/*
            <List className={classes.bottomList}>
                <ListItem>
                    <Button>
                        <UserIcon />
                    </Button>
                </ListItem>
            </List>
            */}
        </Box>
    );
};

export default SideMenu;