export enum DevicePlatform {
  'Iphone' = 'Iphone',
  'Ipad' = 'Ipad',
  'AndroidMobile' = 'AndroidMobile',
  'AndroidTablet' = 'AndroidTablet',
  'Desktop' = 'Desktop',
  'WindowsPhone' = 'WindowsPhone',
  'WindowsDesktop' = 'WindowsDesktop', 
  'WindowsLaptop' = 'WindowsLaptop', 
  'Windows' = 'Windows',              //For Desktop & Laptop together, supposed to replace the other two above
  'MACDesktop' = 'MACDesktop', 
  'MACNotebook' = 'MACNotebook'
}
