import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Box, MobileStepper, Button as MuiButton, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import config from '../../config';
import wizardActions from '../../redux/actions/wizardActions';
import { RootState } from '../../redux/store';
import { OperationStatus } from '../../types/OperationStatus';
import { UserRole } from '../../types/UserRole';
import { UserStatus } from '../../types/UserStatus';
import { PayoutRatesAndTestersQuota, QuestionTimeCategory, SpecificationType } from '../../types/WizardCategoriesTypes';
import { WorkCategory } from '../../types/WorkCategory';
import { Mixpanel } from '../../util/mixpanel';
import SharedStyle from '../Wizard/InnerForms/SharedStyle';
import FreeCycleSummary from './FreeCycleSummary';
import InnerSummary from './InnerSummary';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginTop: SharedStyle.innerContent.marginTop,
        marginLeft: SharedStyle.innerContent.marginLeft,
    },
    internalBox: {
        marginTop: '20px',
    },
    descriptionCardText: {
        fontSize: '14px',
        color: '#171725',
    },
    AttributeName: {
        fontSize: '14px',
        color: theme.palette.common.black,
        fontWeight: 'normal',
        letterSpacing: '0.1px',
    },

    AttributeNameBold: {
        fontSize: '14px',
        color: theme.palette.common.black,
        fontWeight: 'bold',
        letterSpacing: '0.1px',
    },
    createCycleButton: {
        border: 'none',
        marginBottom: 40,
        borderRadius: 12,
        boxShadow: 'none',
        padding: '15px 65px',
        transition: 'all 0.4s',
        '&:hover': {
            color: '#ffffff',
            boxShadow: 'none',
        },
    },
}));


async function submitCycle(cycleData) {
    const response = await fetch(`${config.apiEndpoint}/api/newcycle`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(cycleData)
            .replace(/\u2028/g, '\\u2028')
            .replace(/\u2029/g, '\\u2029'),
    });

    return response;
}


export default function StepThreeForm(props) {

    const { pricingType, next, back } = props;

    const newCycle: any = useSelector((state: RootState) => state).newWizardCycle;
    const { systemPlatforms, user } = useSelector((state: RootState) => state);

    const [formStatus, setFormStatus] = useState(OperationStatus.notStarted);

    const classes = useStyles();

    const history = useHistory();

    const location: any = useLocation();

    const dispatch = useDispatch();

    useEffect(() => {
        restructureCycleDataBeforeSubmission();
    }, []);

    useEffect(() => {
        let userId = user.role == UserRole.Anonymous ? -1 : user.id;
        Mixpanel.track('Entered Pricing Step 3', { userId, pricingType });
    }, []);


    const onPlaceOrder = async (e) => {
        e.preventDefault();

        //Not Logged-In - cache the cycleData and redirect to Login
        if (user.role === UserRole.Anonymous) {
            const newCycleJson = JSON.stringify(newCycle);
            localStorage.setItem('newCycle', newCycleJson);
            return history.push(`/login?returnTo=${location.pathname}/reload`);
        }

        if (user.role !== UserRole.Customer) {
            alert('Sorry, you need to be a Customer in order to be able to create a test cycle.');
            return history.push('/login?returnTo=/wizard');
        }

        if (user.status !== UserStatus.Active) {
            return history.push('account/customer');
        }

        try {
            setFormStatus(OperationStatus.processing);
            const response = await submitCycle(newCycle);
            if (response.status !== 201) {

                if (response.status === 402) {
                    //Here we need to receive the payment required and proceed to the "Checkout form"          
                    dispatch({
                        type: 'PAYMENT_ORDER_PROPERTIES',
                        paymentOrderProperties: await (await response.json()).orderProperties,
                    });

                    next();

                    return;
                }

                if (response.status === 401 || response.status === 403) {
                    return dispatch({
                        type: 'UNAUTHORIZED_REQUEST',
                    });
                }

                dispatch({
                    type: 'CREATE_CYCLE_FAIL',
                    errorMessage: await response.json(),
                });

            } else {

                const cycle = await response.json();

                dispatch({
                    type: 'CREATE_WIZARD_CYCLE_SUCCESS',
                    cycle,
                });

            }
        } catch (e) {
            dispatch({
                type: 'CREATE_CYCLE_FAIL',
                errorMessage: 'Unable to connect. Please try again later',
            });
        }

    };

    const retrieveExtraPayoutPerHours = (hoursPerTester) => {
        switch (hoursPerTester) {
            case 2: { return PayoutRatesAndTestersQuota._2hrsTestingExtra; }
            case 3: { return PayoutRatesAndTestersQuota._3hrsTestingExtra; }
            case 4: { return PayoutRatesAndTestersQuota._4hrsTestingExtra; }
        }
        return 0;
    }

    const retrieveExtraPayoutPerQtime = (questionsTime) => {
        switch (questionsTime) {
            case QuestionTimeCategory._2days: { return PayoutRatesAndTestersQuota._2daysQtimeExtra; }
            case QuestionTimeCategory._4days: { return PayoutRatesAndTestersQuota._4daysQtimeExtra; }
        }
        return 0;
    }

    const restructureCycleDataBeforeSubmission = () => {

        let hourlyRate = PayoutRatesAndTestersQuota.baseHrlyRate;
        if (newCycle.workCategory === WorkCategory.FindBugsTestPlan) {
            hourlyRate += PayoutRatesAndTestersQuota.testPlanExtra;
        }

        hourlyRate += retrieveExtraPayoutPerHours(newCycle.hoursPerTester);
        hourlyRate += retrieveExtraPayoutPerQtime(newCycle.questionsTime);
        newCycle.hourlyRate = hourlyRate;

        newCycle.countries = ['All'];

        newCycle.platformUrls = [{ platformId: newCycle.mainTestPlatform.id, url: newCycle.startingPointURL || '' }];

        const testEnv: any = {};
        testEnv.type = 'Platform';
        testEnv.platformId = newCycle.mainTestPlatform.id;
        testEnv.name = newCycle.mainTestPlatform.name;
        testEnv.specification = newCycle.testSpecification;
        testEnv.requiredTesters = newCycle.numberOfTesters;
        testEnv.minOSVersionId = -1;
        testEnv.maxOSVersionId = -1;
        testEnv.country = 'All';
        newCycle.relevantBrowsers = testEnv.relevantBrowsers = retrieveAllRelevantBrowsers() || [];

        newCycle.testEnvs = [];
        newCycle.testEnvs.push(testEnv);

        if (pricingType === 'firstcycle') {
            newCycle.hourlyRate = 1 / 3;
            newCycle.couponDetails.id = -2;
            newCycle.couponDetails.code = 'free';
        }

        const regexp = /^(ftp|http|https):\/\/*/;
        if (regexp.test(newCycle.startingPointURL) === false) {
            newCycle.startingPointURL = 'http://' + newCycle.startingPointURL;
        }

        dispatch(wizardActions.updateCycle(newCycle));
    }

    const retrieveAllRelevantBrowsers = () => {
        //Native app:
        if (newCycle.testSpecification === SpecificationType.NativeApp) {
            return [];
        }

        /* //WebApp: 
        //fetch all browsers belonging to the single selected mainPlatform
        const platformSelectedObject = systemPlatforms.find((p) => p['id'] === newCycle.mainTestPlatform.id);
        if (platformSelectedObject) {
            const browsers: any[] = platformSelectedObject['browsers'];
            const platfromBrowsers: any[] = [];
            if (browsers && browsers.length > 0) {
                browsers.map(browserObject => {
                    platfromBrowsers.push(({ id: browserObject.id, name: browserObject.name, minBrowserVersion: -1, maxBrowserVersion: -1 }));
                })
                return platfromBrowsers;
            }
            return [];
        } */

        // 'All'
        return [{ id: -1, name: 'Any', minBrowserVersion: -1, maxBrowserVersion: -1 }];
    }

    const calcCyclePrice = () => {
        const numberOfTesters = newCycle.testEnvs.reduce((result, el) => {
            result += el.requiredTesters;
            return result;
        }, 0);

        const price = numberOfTesters * newCycle.hoursPerTester * newCycle.hourlyRate;

        return price;
    }

    const totalPrice = calcCyclePrice();
    const userBalance: number = user.balance;
    const amountWithoutVat: number = totalPrice - userBalance;
    const isPaymentRequired = amountWithoutVat > 0;

    return (

        <>

            <React.Fragment>
                <Typography align="left" variant="h3">
                    Summary
                </Typography>
                <Typography align="left" variant="h4" style={{ marginTop: "15px" }} >
                    So here is your test cycle info, you are one click away from getting yourself covered!
                </Typography>
                <Box mt="60px" ml="10px">
                    {(pricingType === 'firstcycle') ? (<FreeCycleSummary></FreeCycleSummary>) : (<InnerSummary></InnerSummary>)}
                </Box>

                <Box sx={{ mt: 5, width: '75%' }}>
                    <MobileStepper
                        variant="text"
                        steps={4}
                        position="static"
                        activeStep={2}
                        nextButton={(pricingType === 'firstcycle') && false ?
                            (
                                <MuiButton
                                    size="large"
                                    onClick={onPlaceOrder}
                                    color="primary" variant="contained" className={classes.createCycleButton}
                                >
                                    {isPaymentRequired ? 'Proceed to Checkout' : 'Start my test'}
                                    <KeyboardArrowRight />
                                </MuiButton>
                            ) :
                            (
                                <MuiButton
                                    size="large"
                                    onClick={onPlaceOrder}
                                >
                                    {isPaymentRequired ? 'Proceed to Checkout' : 'Start my test'}
                                    <KeyboardArrowRight />
                                </MuiButton>
                            )
                        }
                        backButton={
                            <MuiButton size="large" onClick={back}>
                                <KeyboardArrowLeft />
                                Back
                            </MuiButton>
                        }
                    />
                </Box>

            </React.Fragment>
        </>

    )
}
