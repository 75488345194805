import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import CompleteProfileTester from '../components/Account/Tester/CompleteProfile';
import CompleteProfileCustomer from '../components/Account/Customer/CompleteProfile';
import { UserRole } from '../types/UserRole';

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

class CompleteProfile extends Component<any> {
  render() {
    switch (this.props.user.role) {
      case UserRole.Tester:
        return <CompleteProfileTester />;
      case UserRole.Customer:
        return <CompleteProfileCustomer />;
      case UserRole.Viewer:
        return <CompleteProfileCustomer />;
      default:
        return <Redirect to="/" />;
    }
  }
}

export default connect(mapStateToProps)(CompleteProfile);
