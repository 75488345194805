import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { Theme } from '@mui/material';
import Chip from '@mui/material/Chip';
import { IoIosSend } from 'react-icons/io';
import Button from '@mui/material/Button';
import { grey } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React, { useRef, useState, useEffect } from 'react';

import actions from '../../../../redux/actions';
import { OperationStatus } from '../../../../types/OperationStatus';
import { LoadingButton } from '@mui/lab';
import { UserRole } from '../../../../types/UserRole';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
    },
    title: {
        padding: '15px 30px',
        borderBottom: `1px solid ${theme.palette.primary.light}`,
    },
    textarea: {
        border: 'none',
        outline: 'none',
    },
    button: {
        width: 180,
    },
    chip: {
        marginLeft: 15,
        borderRadius: 6,
        padding: '4px 2px',
        backgroundColor: grey[200],
    },
}));

interface INewMessage {
    cycleId: string;
    to?: number | 'all';
}

const NewMessage: React.FC<INewMessage> = ({ cycleId, to = 'all' }) => {
    const classes = useStyles();

    const dispatch = useDispatch();

    const { resolution, chatMessageSentStatus, user, messageContent } = useSelector((state: RootState) => state);
    const { role } = user;

    const inputRef: any = useRef(null);

    const isTablet = resolution.device === 'mobile-tablet';

    //const [messageContent, setMessageContent] = useState<string>('');

    const setMessageContent = (txt) => {
        return dispatch({
            type: 'TXT_MSG',
            newContent: txt
        });
    }

    const sendMessage = () => {
        const message = trim(messageContent);
        if (!isEmpty(message)) {
            dispatch(actions.sendChatMessage(+cycleId, to, message));
            /* setMessageContent(''); */
        }
    };

    useEffect(function onMessageSentAcknowledged() {
        if (chatMessageSentStatus.status === OperationStatus.success) {
            setMessageContent('');
        }
    }, [chatMessageSentStatus]);

    useEffect(function onMessageToChange() {
        setMessageContent('');
        inputRef?.current?.focus();
    }, [inputRef, to]);

    return (
        <div className={classes.root}>
            {(to === 'all') && (
                <Box display="flex" alignItems="center" className={classes.title}>
                    <Typography variant="body2">To:</Typography>
                    <Chip label="All Testers" className={classes.chip} />
                </Box>
            )}
            <Box display="flex" flexDirection="column" justifyContent="space-between" className={classes.title}>
                <textarea
                    rows={isTablet ? 2 : 6}
                    autoFocus
                    ref={inputRef}
                    value={messageContent}
                    className={classes.textarea}
                    onChange={(e) => setMessageContent(e.target.value)}
                    disabled={chatMessageSentStatus.status === OperationStatus.processing}
                />
                <LoadingButton
                    size={isTablet ? "small" : "medium"}
                    color="primary"
                    variant="contained"
                    onClick={sendMessage}
                    startIcon={<IoIosSend />}
                    className={classes.button}
                    loading={chatMessageSentStatus.status === OperationStatus.processing}
                    loadingPosition="start"
                    disabled={isEmpty(trim(messageContent)) || role === UserRole.Viewer}
                >
                    Send message
                </LoadingButton>
            </Box>
        </div>
    );
};

export default NewMessage;