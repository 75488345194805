import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Icon, Input, Table, Message } from 'semantic-ui-react';
import cycleActions from '../../redux/actions/cycleActions';
import config from '../../config';
import Dropzone from 'react-dropzone';
import excel2json from 'js2excel';

const mapStateToProps = (state) => {
  return {
    newCycle: state.newCycle,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCycle: (data) => dispatch(cycleActions.updateCycle(data)),
  };
};

const RegularStepTableRow = (props) => {
  return (
    <Table.Row>
      <Table.Cell>{props.index + 1}</Table.Cell>
      <Table.Cell>{props.stepDescription}</Table.Cell>
      <Table.Cell>{props.expectedResult}</Table.Cell>
      <Table.Cell>
        <Button
          compact
          size="mini"
          className="edit-btn"
          icon="edit"
          onClick={props.onEnableEditPlanStep}
          data-index={props.index}
        />
        <Button
          compact
          size="mini"
          className="delete-btn"
          icon="trash alternate"
          onClick={props.onDeletePlanStep}
          data-index={props.index}
        />
      </Table.Cell>
    </Table.Row>
  );
};

const EditableStepTableRow = (props) => {
  return (
    <Table.Row>
      <Table.Cell>{props.index}</Table.Cell>
      <Table.Cell>
        <Input
          size="mini"
          name="editStepDescription"
          value={props.stepDescription}
          onChange={props.onEditPlanStep}
        />
      </Table.Cell>
      <Table.Cell>
        <Input
          size="mini"
          name="editStepExpectedResult"
          value={props.expectedResult}
          onChange={props.onEditPlanStep}
        />
      </Table.Cell>
      <Table.Cell>
        <Button
          positive
          size="mini"
          compact
          className="save-btn"
          data-key={props.index}
          onClick={props.onSaveEditedPlanStep}
          icon="check"
        />
        <Button
          size="mini"
          compact
          negative
          className="delete-btn"
          onClick={props.onCancelEditPlanStep}
          data-key={props.index}
          icon="times"
        />
      </Table.Cell>
    </Table.Row>
  );
};

class TestPlanBlock extends Component<any> {
  public state: any;

  constructor(props) {
    super(props);

    this.state = {
      stepDescription: '',
      expectedResult: '',
      editStepKey: -1,
      editStepDescription: '',
      editStepExpectedResult: '',
      uploadExcelError: '',
      jsonFromExcelFile: [],
    };
  }

  onEnableEditPlanStep = (event, data) => {
    const index = data['data-index'];
    const newCycle = this.props.newCycle;

    this.setState({
      editStepKey: data['data-index'],
      editStepDescription: newCycle.testPlan[index].stepDescription,
      editStepExpectedResult: newCycle.testPlan[index].expectedResult,
    });
  };

  onEditPlanStep = (event, data) => {
    event.preventDefault();
    this.setState({ [data.name]: data.value });
  };

  onSaveEditedPlanStep = (event) => {
    event.preventDefault();

    const newCycle = { ...this.props.newCycle };
    newCycle.testPlan[this.state.editStepKey] = {
      stepDescription: this.state.editStepDescription,
      expectedResult: this.state.editStepExpectedResult,
    };

    this.props.updateCycle(newCycle);

    this.setState({ editStepKey: -1 });
  };

  onCancelEditPlanStep = (event) => {
    event.preventDefault();
    this.setState({ editStepKey: -1 });
  };

  onDeletePlanStep = (event, data) => {
    event.preventDefault();

    const newCycle = { ...this.props.newCycle };
    newCycle.testPlan.splice(data['data-index'], 1);

    const newCycleJson = JSON.stringify(newCycle);
    localStorage.setItem('newCycle', newCycleJson);
    this.props.updateCycle(newCycle);

    this.setState({ editStepKey: -1 });
  };

  onChangeNewPlanStep = (event, data) => {
    this.setState({
      [data.name]: data.value,
    });
  };

  onAddPlanStep = (event) => {
    event.preventDefault();

    const newCycle = { ...this.props.newCycle };

    newCycle.testPlan.push({
      stepDescription: this.state.stepDescription,
      expectedResult: this.state.expectedResult,
    });

    this.setState(
      {
        stepDescription: '',
        expectedResult: '',
        editStepKey: -1,
      },
      () => {
        const newCycleJson = JSON.stringify(newCycle);
        localStorage.setItem('newCycle', newCycleJson);
        return this.props.updateCycle(newCycle);
      }
    );
  };

  onTestPlanAccepted = (arr) => {
    if (!/^[^.]+.xls$/.test(arr[0].name) && !/^[^.]+.xlsx$/.test(arr[0].name)) {
      this.setState({
        uploadExcelError: 'You can upload only files with .xls and .xlsx extension',
      });
      return;
    }

    const newCycle = { ...this.props.newCycle };

    excel2json.excel2json(
      arr,
      (testData) => {
        for (let i = 0; i < testData.Sheet1.length; i++) {
          newCycle.testPlan[i] = {
            stepDescription: testData.Sheet1[i]['Step description'],
            expectedResult: testData.Sheet1[i]['Expected result'],
          };
        }
        const newCycleJson = JSON.stringify(newCycle);
        localStorage.setItem('newCycle', newCycleJson);
        this.props.updateCycle(newCycle);
      },
      'excel2json'
    );
  };

  render() {
    const cycle = this.props.newCycle;

    return (
      <div className="cycle-questions">
        <h3>Construct your test plan</h3>

        <Button
          as="a"
          href={`${config.apiEndpoint}/test_plan_template.xls`}
          icon
          labelPosition="right"
          positive
          size="tiny"
        >
          <Icon name="download" />
          Download test cases template
        </Button>

        <Dropzone multiple={false} onDrop={this.onTestPlanAccepted}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="upload-testing-plan">
              <input {...getInputProps()} />
              <Button primary size="tiny" labelPosition="left" icon>
                <Icon name="upload" />
                Upload your test plan
              </Button>
            </div>
          )}
        </Dropzone>
        {this.state.uploadExcelError && <Message negative>{this.state.uploadExcelError}</Message>}

        <Table celled compact className="testing-plan-table" singleLine={false}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>#</Table.HeaderCell>
              <Table.HeaderCell>Step description</Table.HeaderCell>
              <Table.HeaderCell>Expected result</Table.HeaderCell>
              <Table.HeaderCell className="edit-buttons"></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {cycle.testPlan &&
              cycle.testPlan.map((el, i) =>
                this.state.editStepKey === i ? (
                  <EditableStepTableRow
                    index={i}
                    stepDescription={this.state.editStepDescription}
                    expectedResult={this.state.editStepExpectedResult}
                    onEditPlanStep={this.onEditPlanStep}
                    onSaveEditedPlanStep={this.onSaveEditedPlanStep}
                    onCancelEditPlanStep={this.onCancelEditPlanStep}
                  />
                ) : (
                  <RegularStepTableRow
                    index={i}
                    stepDescription={el.stepDescription}
                    expectedResult={el.expectedResult}
                    onEnableEditPlanStep={this.onEnableEditPlanStep}
                    onDeletePlanStep={this.onDeletePlanStep}
                  />
                )
              )}

            <Table.Row>
              <Table.Cell></Table.Cell>
              <Table.Cell>
                <Input
                  size="mini"
                  name="stepDescription"
                  value={this.state.stepDescription}
                  onChange={this.onChangeNewPlanStep}
                />
              </Table.Cell>
              <Table.Cell>
                <Input
                  size="mini"
                  name="expectedResult"
                  value={this.state.expectedResult}
                  onChange={this.onChangeNewPlanStep}
                />
              </Table.Cell>
              <Table.Cell>
                <Button positive size="mini" onClick={this.onAddPlanStep} content="Add" />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TestPlanBlock));
