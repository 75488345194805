import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import { withStyles } from '@mui/styles';
import { grey, blue, orange } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import LinearProgress from '@mui/material/LinearProgress';
import Countdown, { zeroPad } from 'react-countdown';
import { RootState } from '../../../redux/store';
import { useSelector } from 'react-redux';
import { Theme } from '@mui/material/styles';

const DarkLinearProgress = withStyles({
    colorPrimary: {
      backgroundColor: grey[300],
    },
    barColorPrimary: {
      backgroundColor: blue[800],
    }
})(LinearProgress);

const DarkLinearProgressOrange = withStyles({
    colorPrimary: {
      backgroundColor: grey[300],
    },
    barColorPrimary: {
      backgroundColor: orange[800],
    }
})(LinearProgress);

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        [theme.breakpoints.down('md')]: {
            minWidth: 220,
            marginBottom: 10,
        },
        minWidth: 490,
        marginRight: 40,
    },
    title: {
        minWidth: 180,
    },
    progress: {
        height: 8,
        width: '100%',
        [theme.breakpoints.down('lg')]: {
            width: '70%',
        },
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
        borderRadius: 4,
    },
}));

interface IDurationBar {
    title: string;
    start: string;
    end: string;
    styled?: boolean;
}

const DurationBar: React.FC<IDurationBar> = ({ title, start, end, styled = false }) => {
    const classes = useStyles();

    const now = moment();

    const passedTime = now.diff(start);    
    const leftTime = moment(end).diff(now);
    const totalTime = moment(end).diff(start);
   
    let progress = totalTime > 0 ? Math.min(passedTime / totalTime * 100, 100) : 100;
    //This time measure is in the future, didn't start yet, show 0 progress no special text

    const { resolution } = useSelector((state: RootState) => state);
    const isMobile = resolution.device === 'mobile' || resolution.device === 'mobile-tablet';

    if (passedTime < 0) {
        progress = 0;
        return(
            <Box display="flex" alignItems="center" className={classes.root} flexDirection={isMobile ? "column": "row"}>
            <Typography variant="caption" className={classes.title}>                                
                 {title}
            </Typography>
            {styled ? (
                <DarkLinearProgressOrange 
                    value={progress}
                    variant="determinate" 
                    className={classes.progress} 
                />
            ) : (
                <DarkLinearProgress 
                    value={progress}
                    variant="determinate" 
                    className={classes.progress} 
                />
            )
            }
            
        </Box>
        )
    }
        
    return (
        <Box display="flex" alignItems="center" className={classes.root} flexDirection={isMobile ? "column": "row"}>
            <Typography variant="caption" className={classes.title}>
                {/* {title} {moment(leftTime).format('HH:mm:ss')} */}
                { leftTime > 0 && 
                    <Countdown
                        date={Date.now() + leftTime}                                
                        renderer={({ days, hours, minutes, seconds }) => {
                                return days > 0 ?  `${title} ${days} days,  ${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}` 
                                            : `${title} ${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`;                            
                        }}
                    ></Countdown>
                }
                { !(leftTime > 0) &&
                 <>{`${title} Completed`}</>}
            </Typography>
            {styled ? (
                <DarkLinearProgressOrange 
                    value={progress}
                    variant="determinate" 
                    className={classes.progress} 
                />
            ) : (
                <DarkLinearProgress 
                    value={progress}
                    variant="determinate" 
                    className={classes.progress} 
                />
            )
            }
            
        </Box>
    );
}

export default DurationBar;
 