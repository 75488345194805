import { createTheme, adaptV4Theme } from '@mui/material/styles';
import { titleBlue, primaryBlue, subtitleGrey, cardsTextDark, cardsTextGrey } from './InnerForms/SharedStyle';

const theme = createTheme(adaptV4Theme({

  typography: {
    fontFamily: "Poppins",
    button: {
      textTransform: 'none',
    }
  },
  overrides: {
    MuiCardContent: {
      root: {
        padding: "16px 16px",
        '&:last-child': {
          paddingBottom: '8px',
        }
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '14px',
      }
    },
    MuiFormHelperText: {
      root: {
        margin: 4,
        color: 'red'
      },
    },
    MuiTypography: {
      h5: {
        fontSize: '18px',
        color: `${titleBlue}`,
        fontWeight: 'bolder',
        letterSpacing: '0.1px',
      },
      h4: {
        fontSize: '14px',
        color: `${subtitleGrey}`,
        fontWeight: 'normal',
        letterSpacing: '0.1px',
      },
      h3: {
        fontSize: '28px',
        color: `${titleBlue}`,
        fontWeight: 'bolder',
        letterSpacing: '0.1px',
      },
      h6: {
        fontSize: '16px',
        color: `${cardsTextDark}`,
        fontWeight: 'bolder',
        letterSpacing: '0.1px',
      },
      h2: {
        fontSize: '14px',
        color: `${titleBlue}`,
        fontWeight: 'bolder',
        letterSpacing: '0.1px',
      },
    },
  },
  palette: {
    primary: {
      main: `${primaryBlue}`
    },
    secondary: {
      main: `${cardsTextGrey}`
    },
  },
}));



export default theme;