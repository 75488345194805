import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../redux/store';
import NewMessage from './NewMessage';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        overflowY: 'auto',
        width: 'calc(100% - 350px)',
        height: 'calc(100vh - 190px)',
        borderRight: `1px solid ${theme.palette.primary.light}`,
    },
    sendToAllButton: {
        marginTop: 25,
        marginBottom: 45,
    },
    message: {
        padding: '25px 30px',
        borderBottom: `1px solid ${theme.palette.primary.light}`,
    },
    messageTop: {
        fontSize: 15,
        marginBottom: 15,
        color: `${theme.palette.primary.dark}`
    },
    rootBug: {
        width: '100%',
        height: 'auto',
        borderRight: 'none',
    },
}));

interface IConversation {
    cycleId: string;
    conversation: number | null;
}

const Conversation: React.FC<IConversation> = ({ cycleId, conversation }) => {
    const classes = useStyles();

    const newMessageRef: any = useRef(null);

    const { user, chatMessages, cycleTesters } = useSelector((state: RootState) => state);

    const tester = cycleTesters?.byCycleId[cycleId]?.find(({ id }) => id === conversation);

    const messages = conversation ? chatMessages?.byCycleId[cycleId][conversation] : [];

    const scrollToBottom = () => {
        newMessageRef?.current?.scrollIntoView({ behavior: 'auto', block: 'end', inline: 'nearest' });
    };

    useEffect(scrollToBottom, [newMessageRef, conversation]);

    if (!conversation) {
        return (
            <div className={classes.root}>
                <NewMessage cycleId={cycleId} />
            </div>
        );
    }
    return (
        <Box display="flex" flexDirection="column" className={classes.root}>
            {!isEmpty(messages) && messages?.map((message: any, i: number) => (
                <Box
                    key={`${i}-${message.senderId}`}
                    display="flex"
                    flexDirection="column"
                    className={classes.message}
                >
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="body2" className={classes.messageTop}>
                            {message.senderId === user.id
                                ? `${user?.firstName} ${user?.lastName}`
                                : (tester ? `${tester?.firstName} ${tester?.lastName}` : `TesterID: ${message.senderId}`)
                            }
                        </Typography>
                        <Typography variant="body2" className={classes.messageTop}>
                            {moment(message.createdAt).fromNow()}
                        </Typography>
                    </Box>
                    <Typography variant="caption" color="textPrimary" style={{ whiteSpace: 'pre-wrap' }}>
                        {message.text}
                    </Typography>
                </Box>
            )).reverse()}

            <NewMessage cycleId={cycleId} to={tester?.id} />
            <div ref={newMessageRef} />
        </Box>
    );
};

export default Conversation;
