import React from 'react';
import { Typography, Chip, Box } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import { NewWizardCycle } from '../../../types/NewWizardCycle';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { PayoutRatesAndTestersQuota, QuestionTimeCategory } from '../../../types/WizardCategoriesTypes';
import { WorkCategory } from '../../../types/WorkCategory';
import workCategories from '../../../util/options/workCategories';


const bgColors = ['#62687F', '#FA982B', '#2CA8F7', '#9D1DB1', '#8AC441', '#0080D1'];
const chipColors = ['#9196A6', '#FCB76B', '#6CC2F9', '#BB64C9', '#ADD67A', '#6CC2F9' ];
const titles = ['Blank', 'Speedtest', 'Before you launch', 'Before launch', 'New feature/version', 'Test it all' ]; //TODO: set names and props with Eran!!!

const useStyles =  makeStyles((theme) => ({
    BaseStyle: {
        height: '165px',
        width: '100%',        
        borderRadius:'8px',        
        padding: 4,
        flexDirection:"row",
        justifyContent:'flex-around',
        marginBottom:"25px",
    },
    title:{
        fontSize: '18px',
        color: '#ffffff',
        fontWeight: 'bold',        
    },
    testerRate:{
        fontSize: '14px',
        color: '#ffffff',                
    },
    ChipLabel: {   
        '& .MuiChip-label' :{      
            fontSize: '13px',     
            fontWeight: "normal",
            color: "#FAFAFB",
            letterSpacing: '0.2px',          
        },
      },
    chipBox: {
        marginTop: '10px',
        marginLeft: -theme.spacing(0.25),
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
          margin: theme.spacing(0.25),          
        },
      },
}));

export function retrieveExtraPayoutPerHours(hoursPerTester){
    switch( hoursPerTester ){      
      case 2: { return PayoutRatesAndTestersQuota._2hrsTestingExtra; }
      case 3: { return PayoutRatesAndTestersQuota._3hrsTestingExtra; }
      case 4: { return PayoutRatesAndTestersQuota._4hrsTestingExtra; }
    }
    return 0;
  }

export function retrieveExtraPayoutPerQtime(questionsTime){
      switch( questionsTime ){        
        case QuestionTimeCategory._2days : { return PayoutRatesAndTestersQuota._2daysQtimeExtra; }
        case QuestionTimeCategory._4days : { return PayoutRatesAndTestersQuota._4daysQtimeExtra; }
      }
      return 0;
    }

export function calcTotalAmountPerTester( newCycle ){
    let hourlyRate = PayoutRatesAndTestersQuota.baseHrlyRate;    

    if(newCycle.workCategory === WorkCategory.FindBugsTestPlan ){
      hourlyRate += PayoutRatesAndTestersQuota.testPlanExtra;
    }

    hourlyRate += retrieveExtraPayoutPerHours( newCycle.hoursPerTester );
    hourlyRate += retrieveExtraPayoutPerQtime( newCycle.questionsTime );

    return hourlyRate;

  }

export function timeTxtFromDeliveryTime(deliveryTime){
  let deliveryTimeTxt
  switch( deliveryTime )
  {
    case '24hours': deliveryTimeTxt = '24 hrs'; break;
    case '3days': deliveryTimeTxt = '3 days'; break;
    case '7days': deliveryTimeTxt = '1 week'; break;
  }
  return deliveryTimeTxt;
}

export function timeTxtFromDeliveryTimeDays(deliveryTime){
  let deliveryTimeTxt
  switch( deliveryTime )
  {
    case '24hours': deliveryTimeTxt = '1 day cycle'; break;
    case '3days': deliveryTimeTxt = '3 days cycle'; break;
    case '7days': deliveryTimeTxt = '1 week'; break;
  }
  return deliveryTimeTxt;
}

export function timeTxtFromQuestionTime(questionsTime){
    let QuestionTimeTxt
    switch( questionsTime )
    {
      case '24hours': QuestionTimeTxt = '24 hrs'; break;
      case '2days': QuestionTimeTxt = '2 days'; break;
      case '4days': QuestionTimeTxt = '4 days'; break;
    }
    return QuestionTimeTxt;
  }

export function LegendCard(props) {

    const {newCycle, currentStep, innerStep, templateId} = props;    
    const classes = useStyles();
    const hourlyRate = calcTotalAmountPerTester(newCycle);

    const typeText = newCycle.workCategory === WorkCategory.FindBugsExploratory ? 'Exploratory' : 'Test plan';
    const hrTestingTxt = `${newCycle.hoursPerTester} hr testing`;
    const overallTimeTxt = timeTxtFromDeliveryTime( newCycle.deliveryTime) + ' Testing';
    const QtimeTxt = timeTxtFromQuestionTime( newCycle.questionsTime) + ' Availability';
    const locationTxt = newCycle.isWorldwide ? 'Worldwide' : 'Specific countries';

    return(
        <Card elevation={0} className={classes.BaseStyle} style={{backgroundColor: bgColors[templateId] }}>
            <CardContent>
                <Typography className={classes.title}>{titles[templateId]}</Typography>
                <Typography className={classes.testerRate}>${hourlyRate} per tester</Typography>
                {currentStep > 0 &&
                    <Box className={classes.chipBox}>
                        <Chip style={{backgroundColor:chipColors[templateId]}} className={classes.ChipLabel} label={typeText}></Chip>
                        <Chip style={{backgroundColor:chipColors[templateId]}} className={classes.ChipLabel} label={hrTestingTxt}></Chip>
                        <Chip style={{backgroundColor:chipColors[templateId]}} className={classes.ChipLabel} label={overallTimeTxt}></Chip>
                        <Chip style={{backgroundColor:chipColors[templateId]}} className={classes.ChipLabel} label={QtimeTxt}></Chip>
                        <Chip style={{backgroundColor:chipColors[templateId]}} className={classes.ChipLabel} label={locationTxt}></Chip>
                    </Box>
                }
                {currentStep === 0 && 
                    <Box className={classes.chipBox}>
                        { innerStep === 1 &&
                            <Chip style={{backgroundColor:chipColors[templateId]}} className={classes.ChipLabel} label={typeText}></Chip>
                        }
                        { innerStep === 2 &&
                            <React.Fragment>
                                <Chip style={{backgroundColor:chipColors[templateId]}} className={classes.ChipLabel} label={typeText}></Chip>
                                <Chip style={{backgroundColor:chipColors[templateId]}} className={classes.ChipLabel} label={hrTestingTxt}></Chip>
                            </React.Fragment>
                        }
                        { innerStep === 3 &&
                            <React.Fragment>
                                <Chip style={{backgroundColor:chipColors[templateId]}} className={classes.ChipLabel} label={typeText}></Chip>
                                <Chip style={{backgroundColor:chipColors[templateId]}} className={classes.ChipLabel} label={hrTestingTxt}></Chip>
                                <Chip style={{backgroundColor:chipColors[templateId]}} className={classes.ChipLabel} label={overallTimeTxt}></Chip>
                                <Chip style={{backgroundColor:chipColors[templateId]}} className={classes.ChipLabel} label={QtimeTxt}></Chip>
                            </React.Fragment>
                        }
                    </Box>
                }
                

            </CardContent>
        </Card>                        
    );    
}
