import React, { useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import { TextArea, Button } from 'semantic-ui-react';

const Wrapper = styled.div`
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  margin: 1rem 0 0;
  padding: 1rem;
  width: 100%;
  textarea {
    background: #fff;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 4px;
    margin-bottom: 1rem;
    padding: 1rem;
  }
`;

interface Props {
  onSubmit?: (message: string) => void;
}

const CreateMessage = ({ onSubmit }: Props) => {
  const [value, setValue] = useState('');

  const handleSubmit = async () => {
    if (value && onSubmit) {
      await onSubmit(value);
      setValue('');
    }
  };

  return (
    <Wrapper>
      <TextArea
        placeholder="Your message"
        value={value}
        onInput={(evt, data) => setValue(`${data.value}`)}
      />

      <Button primary className="send" onClick={handleSubmit}>
        Send
      </Button>

      {/* <ReactQuill theme="snow" value={value} onChange={setValue} />
      <div>
        <Button fullWidth color="primary" variant="contained" onClick={handleSubmit}>
          Send
        </Button>
      </div> */}
    </Wrapper>
  );
};

export default CreateMessage;
