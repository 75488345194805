import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { TextArea, Button } from 'semantic-ui-react';
import CycleDetailsSidemenu from './Sidemenu';
import ChatMessage from '../../Common/ChatMessage';
import actions from '../../../../redux/actions';
// import Chat from '../../../../next/chat/Chat';

const mapStateToProps = (state) => {
  return {
    user: state.user,
    cycles: state.cycles,
    chatMessages: state.chatMessages,
    device: state.resolution.device,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listChatMessages: (cycleId, participantId) =>
      dispatch(actions.listChatMessages(cycleId, participantId)),
    markMessagesAsRead: (cycleId, participantId) =>
      dispatch(actions.markMessagesAsRead(cycleId, participantId)),
    sendChatMessage: (cycleId, receiverId, messageText) =>
      dispatch(actions.sendChatMessage(cycleId, receiverId, messageText)),
  };
};

class Messages extends Component<any> {
  public state: any;

  constructor(props) {
    super(props);

    this.state = {
      cycleId: +props.match.params.id,
      participants: [],
      participantId: null,
      messageText: '',
    };
  }

  componentDidMount() {
    if (!this.props.cycles.length) {
      return;
    }
    const cycle = this.props.cycles.find((el) => el.id === this.state.cycleId);
    const participantId = cycle.ownerId;

    this.setState({
      cycle,
      participantId,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // if (! _.isEqual(this.props.cycles, prevProps.cycles)) {
    //     const cycle = this.props.cycles.find(el => el.id === this.state.cycleId);
    //     const participantId = cycle.ownerId;

    //     this.setState({
    //         cycle,
    //         participantId
    //     });
    // }

    // if (this.state.participantId !== prevState.participantId) {
    //     this.props.markMessagesAsRead(this.state.cycleId, this.state.participantId);
    // }

    const currentMessages = this.props.chatMessages.byCycleId[this.state.cycleId] || [];
    const chatMessages = currentMessages[this.state.participantId] || [];

    const prevChatMessages =
      prevProps.chatMessages.byCycleId[this.state.cycleId][this.state.participantId] || [];

    if (chatMessages.length !== prevChatMessages.length) {
      this.props.markMessagesAsRead(this.state.cycleId, this.state.participantId);
    }
  }

  onInput = (event, data) => {
    this.setState({
      messageText: data.value,
    });
  };

  onSend = () => {
    if (!this.state.messageText.length) {
      return;
    }

    this.props.sendChatMessage(
      this.state.cycleId,
      this.state.participantId,
      this.state.messageText
    );
    this.setState({ messageText: '' });
  };

  getNameById = (id) => {
    if (id === this.props.user.id) {
      return `${this.props.user.firstName} ${this.props.user.lastName}`;
    }

    const name = this.props.cycles.find((el) => el.id === this.state.cycleId).ownerCompanyName;

    return name;
  };

  render() {
    const cycleId = this.state.cycleId;

    if (!this.props.cycles.length) {
      return null;
    }

    const cycle = this.props.cycles.find((cycle) => cycle.id === cycleId);
    const chatMessages = this.props.chatMessages.byCycleId[cycleId];
    const currentMessages = chatMessages?.[this.state.participantId] || [];

    // const nextMessages = currentMessages.map((m) => {
    //   console.log(m);
    // });

    // debugger;

    const isMobile = this.props.device === 'mobile' || this.props.device === 'mobile-tablet';

    if( isMobile ) {
      return (
        <div>
            <CycleDetailsSidemenu cycleId={this.state.cycleId} />
  
            <div className="cycle-messages" style={{marginLeft: 5}}>
              {/* <Chat messages={[]} /> */}
              <h2>Cycle Messages</h2>
              <h4>Contact person: {cycle.contactPerson}</h4>
  
              <div style={{ width: '80%' }}>
                {currentMessages &&
                  currentMessages.map((message, i) => {
                    return (
                      <ChatMessage
                        key={i}
                        direction={message.senderId === this.props.user.id ? 'left' : 'right'}
                        name={this.getNameById(message.senderId)}
                        text={message.text}
                        time={message.createdAt}
                        avatarUrl={message.senderAvatarUrl}
                      />
                    );
                  })}
              </div>
  
              <div className="message-form">
                <TextArea
                  placeholder="Your message"
                  value={this.state.messageText}
                  onInput={this.onInput}
                />
                <Button primary className="send" onClick={this.onSend}>
                  Send
                </Button>
              </div>
            </div>
          </div>        
      );
    }
    return (
      <div className="account-tester page">
        <div className="heading">
          <h1>Cycle Messages</h1>
        </div>

        <div className="inner" style={{ display: 'flex' }}>
          <CycleDetailsSidemenu cycleId={this.state.cycleId} />

          <main className="cycle-messages">
            {/* <Chat messages={[]} /> */}
            <h2>Cycle Messages</h2>
            <h4>Contact person: {cycle.contactPerson}</h4>

            <div style={{ width: '80%' }}>
              {currentMessages &&
                currentMessages.map((message, i) => {
                  return (
                    <ChatMessage
                      key={i}
                      direction={message.senderId === this.props.user.id ? 'left' : 'right'}
                      name={this.getNameById(message.senderId)}
                      text={message.text}
                      time={message.createdAt}
                      avatarUrl={message.senderAvatarUrl}
                    />
                  );
                })}
            </div>

            <div className="message-form">
              <TextArea
                placeholder="Your message"
                value={this.state.messageText}
                onInput={this.onInput}
              />
              <Button primary className="send" onClick={this.onSend}>
                Send
              </Button>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Messages));
