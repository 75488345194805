import React, { Component } from 'react';
import { Box, Checkbox, Typography } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';


import wizardActions from '../../../redux/actions/wizardActions';
import { NewWizardCycle } from '../../../types/NewWizardCycle';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import nativeApp from '../../../public/images/nativeApp.svg';
import website from '../../../public/images/website.svg';

import SharedStyle from './SharedStyle';
import { SpecificationType } from '../../../types/WizardCategoriesTypes';


const styles = (theme) => createStyles({
  root: {
    marginTop: SharedStyle.innerContent.marginTop,
    marginLeft: SharedStyle.innerContent.marginLeft,    
  },
  internalBox: {
    marginTop: '20px',
    marginLeft: '-8px',
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      marginLeft: '8px',
      width: '200px',
      height: '178px',
    },
  },
  mediaWebsite: {
        height: '66px',
        width: '68px',
      },

  mediaNativeApp: {
      height: '66px',
      width: '66px',
    },

  cardDefault: {
      border: SharedStyle.contentCardDefault.border,
      borderRadius: SharedStyle.contentCardDefault.borderRadius,
      cursor: SharedStyle.contentCardDefault.cursor,        
  },
  cardSelected:{        
      border: SharedStyle.contentCardSelected.border,
      borderRadius: SharedStyle.contentCardSelected.borderRadius,
      cursor: SharedStyle.contentCardSelected.cursor,
  },
  descriptionCardText: {
    fontSize: '13px',
    color: '#171725',
  },
});

const mapStateToProps = (store) => {
  return {
    newCycle: store.newWizardCycle as NewWizardCycle,
    testSpecification : store.newWizardCycle.testSpecification,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCycle: (data) => dispatch(wizardActions.updateCycle(data)),      
  };
};

class SpecificationForm extends Component<any> {
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    //Here upon change we better re-set Platform and device selection as they dependant on it
    if (prevProps.testSpecification !== this.props.testSpecification) {
        const newCycle: NewWizardCycle = { ...this.props.Wizard };
        newCycle.relevantBrowsers = [];
        newCycle.isSpecificDevices = false;
        newCycle.relevantDevices =[];
        
        this.props.updateCycle(newCycle);
    }
}

    onSelectionChangeIndex = (optionSelected) => {
      const newCycle: NewWizardCycle = { ...this.props.Wizard };      
      newCycle.testSpecification = optionSelected;

      this.props.updateCycle(newCycle);   
  }

  render() {

      const {classes, newCycle} = this.props;

      return(
              <Box>                
                <Typography variant="h2">
                      What are we going to test
                </Typography>
                <div className={classes.internalBox}>
                    <Card
                        onClick={() => this.onSelectionChangeIndex(SpecificationType.Website)} 
                        elevation={0} 
                        className={newCycle.testSpecification === SpecificationType.Website ? classes.cardSelected : classes.cardDefault }
                    >
                        <CardContent>
                        <Box style={{ display: 'flex', flexDirection:'column', alignItems: 'center', paddingTop:'15px'}}>
                            <CardMedia                          
                              className={classes.mediaWebsite}
                              image={website}                            
                            />                          
                            <Typography gutterBottom variant="h6">
                              Website
                            </Typography>
                            <Typography gutterBottom align="center" className={classes.descriptionCardText}>
                                Can be Website or web app
                            </Typography>
                        </Box>
                        </CardContent>
                    </Card>

                    <Card
                        onClick={() => this.onSelectionChangeIndex(SpecificationType.NativeApp)} 
                        elevation={0} 
                        className={newCycle.testSpecification === SpecificationType.NativeApp ? classes.cardSelected : classes.cardDefault }
                    >
                        <CardContent>
                        <Box style={{ display: 'flex', flexDirection:'column', alignItems: 'center', paddingTop:'15px'}}>
                              <CardMedia                          
                                className={classes.mediaNativeApp}
                                image={nativeApp}                            
                              />                            
                            <Typography gutterBottom variant="h6">
                                Native App
                            </Typography>
                            <Typography gutterBottom align="center" className={classes.descriptionCardText}>
                                Can be Android or iOS app
                            </Typography>
                        </Box>
                        </CardContent>
                    </Card>
                  </div>                  
              </Box>   
      );    
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SpecificationForm)));