import { adaptV4Theme, Box, Button, Modal, Typography } from '@mui/material';
import { createTheme, Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Dimmer } from 'semantic-ui-react';
import cycleActions from '../../redux/actions/cycleActions';
import wizardActions from '../../redux/actions/wizardActions';
import { NewWizardCycle } from '../../types/NewWizardCycle';
import { OperationStatus } from '../../types/OperationStatus';
import { UserRole } from '../../types/UserRole';
import { DeliveryTimeCategory, QuestionTimeCategory } from '../../types/WizardCategoriesTypes';
import { WorkCategory } from '../../types/WorkCategory';
import InnerContainerFactory from './InnerContainerFactory';
import { LegendCard } from './InnerForms/LegendCard';
import InnerStepLegend from './InnerStepLegend';
import StepHeader from './StepHeader';
import WelcomePro from './WelcomePro';



declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme { }
}


const themeBtnContact = createTheme(adaptV4Theme({
    palette: {
        secondary: {
            main: '#bf3fd3',
        },
    },
    typography: {
        button: {
            textTransform: "none"
        }
    }
}));

var wizard = [


    {
        mainPage: "Test Settings ",
        innerPage: [

            { id: 0, title: "Test type", label: "A", subTitle: "What kind of a test would you like to have", TagName: 'TypeForm', visible: true },
            { id: 1, title: "Test duration", label: "B", subTitle: "How long is your test", TagName: 'DurationForm', visible: true },
            { id: 2, title: "Test period", label: "C", subTitle: "Set the time period for the testers", TagName: 'PeriodForm', visible: true },
            { id: 3, title: "Test Location", label: "D", subTitle: "From which countries would you like to test", TagName: 'LocationForm', visible: true },
        ]
    },


    {
        mainPage: "My test brief",
        innerPage: [{ id: 4, title: "General Details", label: "A", subTitle: "So let's configure the general test cycle properties", TagName: 'GeneralDetailsForm', visible: true },
        { id: 5, title: "Test specifications", label: "B", subTitle: "Jsut so that we know what kind of testing you need", TagName: 'SpecificationForm', visible: true },
        { id: 6, title: "Test Plan", label: "C", subTitle: "Tell us more specifics please", TagName: 'PlanForm', visible: false },
        ]
    },

    {
        mainPage: "Devices & Testers",
        innerPage: [{ id: 7, title: "Platforms And Testers", label: "A", subTitle: "Now let's target what platform to focus on and set the desired amount of testers", TagName: 'PlatformAndTestersForm', visible: true },
        /* { id: 8, title: "Devices", label: "B", subTitle: "You can also set up mobile device specification per country (unavailable for Desktop)", TagName: 'DevicesForm', visible: true }, */
        { id: 9, title: "Summary", label: "C", subTitle: "So here is your test cycle info, you are one click away from getting yourself covered", TagName: 'SummaryForm', visible: true },
        ]
    },

    {
        mainPage: "Checkout",
        innerPage: [{ id: 10, title: "Checkout", label: "A", subTitle: "Your test cycle is almost ready to start, after completing the payment our system will start matching you with our finest testers", TagName: 'CheckoutForm', visible: true },]
    }
];


const styles = (theme) => createStyles({
    mainBox: {
        justifyContent: 'center',
        margin: 'auto',
        maxWidth: 1380,
        minWidth: 700,
        minHeight: 920,
    },
    mainBoxPro: {
        justifyContent: 'center',
        margin: 'auto',
        maxWidth: 1380,
        minWidth: 700,
        //minHeight: 920,
    },
    HeaderMenu: {
        position: 'absolute',
        left: '0px',
        width: '100%',
        height: '8%',
        borderBottom: '1px solid #DEEEFF',
        paddingLeft: '50px',
        paddingRight: '50px',
    },
    inner: {
        position: 'absolute',
        bottom: '0',
        width: "100%",
        height: "92%",
        margin: 'auto',
        left: '14%',
    },
    innerLeft: {
        position: 'absolute',
        bottom: '0',
        borderRight: '1px solid #DEEEFF',
        width: "28%",
        height: "92%",
    },
    innerRight: {
        position: 'absolute',
        bottom: '0',
        right: '0',
        width: '72%',
        height: "92%",
    },
});


const mapStateToProps = (state) => {
    return {
        newCycle: state.newWizardCycle as NewWizardCycle,
        workCategory: state.newWizardCycle.workCategory,
        currentStep: state.newCycleCurrentMainStep,
        innerStep: state.newCycleCurrentInnerStep,
        user: state.user,
        cycleCreateStatus: state.cycleCreateStatus,
        device: state.resolution.device,

        devices: state.devices,
        browsers: state.browsers
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        switchStep: (step) => dispatch(wizardActions.switchNewCycleStep(step)),
        switchInnerStep: (innerStep) => dispatch(wizardActions.switchNewCycleInnerStep(innerStep)),

        goNext: () => dispatch(wizardActions.switchNewCycleStepForward()),
        goBack: () => dispatch(wizardActions.switchNewCycleStepBackward()),

        updateCycle: (data) => dispatch(wizardActions.updateCycle(data)),

        restructureSystemPlatforms: () => dispatch(wizardActions.restructureSystemPlatforms()),
        listCycles4CustomerSlim: () => dispatch(cycleActions.listCycles4CustomerSlim()),

    };
};

export enum TemplateTypes {
    ScratchTemplate,
    BasicTemplate,
    OnboardingTemplate,
    PreLunchTemplate,
    OngoingTemplate,
    IsraeliTemplate
}


class NewCycleWizard extends Component<any> {

    public state: any = {
        templateId: TemplateTypes.ScratchTemplate,
        fetchedCycleData: NewWizardCycle,
        fetchedMainStep: Number,
        fetchedMinorStep: Number,
    };

    componentDidMount() {
        this.props.restructureSystemPlatforms();

        if (this.props.match.params.reload === 'reload') {
            const newCycleFromLocalStorage = localStorage.getItem('newCycle');
            if (newCycleFromLocalStorage) {
                console.log('found cached data');
                try {
                    const fetchedCycleData = JSON.parse(newCycleFromLocalStorage);
                    const fetchedMainStep = parseInt(localStorage.getItem('newCycle_mainStep') || '0');
                    const fetchedMinorStep = parseInt(localStorage.getItem('newCycle_innerStep') || '0');
                    const templateId = parseInt(localStorage.getItem('newCycle_templateId') || '0');

                    this.setState({
                        fetchedCycleData,
                        fetchedMainStep,
                        fetchedMinorStep,
                        templateId
                    });
                    this.continueFromWhereILeft();
                    return;
                } catch (e) {
                    console.error('Problem with parsing json ' + e);
                    localStorage.removeItem('newCycle');
                    localStorage.removeItem('newCycle_mainStep');
                    localStorage.removeItem('newCycle_innerStep');
                }
            }
        }

        //Logically else, New default
        this.startFromScratch();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.workCategory !== this.props.workCategory) {
            //@@ Change later to something more flexi than magic const numbers!!!
            wizard[1].innerPage[2].visible = this.props.workCategory === WorkCategory.FindBugsTestPlan;
        }

        //Doing it here since on the Checkout or the Summary I get Unmount before this takes effect
        if (prevProps.cycleCreateStatus.status != this.props.cycleCreateStatus.status) {
            if (this.props.cycleCreateStatus.status == OperationStatus.success) {
                this.props.listCycles4CustomerSlim();
                this.props.history.push(`account/customer/cycle/${this.props.cycleCreateStatus.cycleId}`);
            }
            if (this.props.cycleCreateStatus.status == OperationStatus.fail) {
                this.props.history.push('cycle-creation-result?status=fail');
            }
        }
    }

    startOver = () => {
        this.props.switchStep(-1);
    }

    continueFromWhereILeft = () => {
        if (this.state.fetchedMainStep > -1) {
            this.props.updateCycle(this.state.fetchedCycleData);
            this.props.switchStep(this.state.fetchedMainStep);
            this.props.switchInnerStep(this.state.fetchedMinorStep);
        }
    }


    //TODO: change this ugly setup for the templates data and also for the Template page texts
    //Need to declare a JSON with the params required and let the engine draw it and calculate dynaimically accordingly
    startFromScratch = () => {
        const newCycleData = new NewWizardCycle();
        this.props.updateCycle(newCycleData);

        this.setState({
            templateId: TemplateTypes.ScratchTemplate,
        });
        localStorage.setItem('newCycle_templateId', `${TemplateTypes.ScratchTemplate}`);
        this.props.switchStep(0);
    }

    //Speedtest
    LaunchBasicTemplate = () => {
        const newCycle = new NewWizardCycle();

        this.setState({
            templateId: TemplateTypes.BasicTemplate,
        });
        localStorage.setItem('newCycle_templateId', `${TemplateTypes.BasicTemplate}`);

        newCycle.workCategory = WorkCategory.FindBugsExploratory;
        newCycle.hoursPerTester = 1;

        newCycle.deliveryTime = DeliveryTimeCategory._24hours;
        newCycle.questionsTime = QuestionTimeCategory._24hours;

        newCycle.isWorldwide = true;

        this.props.updateCycle(newCycle);
        this.props.switchStep(1);
    }

    //Before you launch
    LaunchOnBoardingTemplate = () => {
        const newCycle = new NewWizardCycle();

        this.setState({
            templateId: TemplateTypes.OnboardingTemplate,
        });
        localStorage.setItem('newCycle_templateId', `${TemplateTypes.OnboardingTemplate}`);

        newCycle.workCategory = WorkCategory.FindBugsExploratory;
        newCycle.hoursPerTester = 3;

        newCycle.deliveryTime = DeliveryTimeCategory._3days;
        newCycle.questionsTime = QuestionTimeCategory._2days;

        newCycle.isWorldwide = true;

        this.props.updateCycle(newCycle);
        this.props.switchStep(1);
    }

    LaunchBeforeLaunchTemplate = () => {
        const newCycle = new NewWizardCycle();

        this.setState({
            templateId: TemplateTypes.PreLunchTemplate,
        });
        localStorage.setItem('newCycle_templateId', `${TemplateTypes.PreLunchTemplate}`);

        newCycle.workCategory = WorkCategory.FindBugsExploratory;
        newCycle.hoursPerTester = 3;

        newCycle.deliveryTime = DeliveryTimeCategory._3days;
        newCycle.questionsTime = QuestionTimeCategory._2days;

        newCycle.isWorldwide = false;
        newCycle.countries = ['Israel', 'USA'];
        this.props.updateCycle(newCycle);
        this.props.switchStep(1);
    }

    //New Feature/version
    LaunchOngoingTemplate = () => {
        const newCycle = new NewWizardCycle();

        this.setState({
            templateId: TemplateTypes.OngoingTemplate,
        });
        localStorage.setItem('newCycle_templateId', `${TemplateTypes.OngoingTemplate}`);

        newCycle.workCategory = WorkCategory.FindBugsTestPlan;
        newCycle.hoursPerTester = 2;

        newCycle.deliveryTime = DeliveryTimeCategory._24hours;
        newCycle.questionsTime = QuestionTimeCategory._4days;

        newCycle.isWorldwide = true;
        wizard[1].innerPage[2].visible = true;
        this.props.updateCycle(newCycle);
        this.props.switchStep(1);
    }

    //Test it all
    LaunchIsraeliTemplate = () => {
        const newCycle = new NewWizardCycle();

        this.setState({
            templateId: TemplateTypes.IsraeliTemplate,
        });
        localStorage.setItem('newCycle_templateId', `${TemplateTypes.IsraeliTemplate}`);

        newCycle.workCategory = WorkCategory.FindBugsTestPlan;
        newCycle.hoursPerTester = 4;

        newCycle.deliveryTime = DeliveryTimeCategory._7days;
        newCycle.questionsTime = QuestionTimeCategory._4days;

        newCycle.isWorldwide = true;

        wizard[1].innerPage[2].visible = true;
        this.props.updateCycle(newCycle);
        this.props.switchStep(1);
    }


    totalSteps = () => {
        return wizard.length;
    }

    totalInnerSteps = () => {
        const concurrentInnerSteps = wizard[this.props.currentStep].innerPage.filter(innerStep => innerStep.visible === true)
        return concurrentInnerSteps.length;
    }

    handleNext = () => {
        if (this.totalInnerSteps() - 1 > this.props.innerStep)
            this.props.goNext();
        else
            this.props.switchStep(this.props.currentStep + 1);
    };

    handleBack = () => {
        if (0 === this.props.innerStep)
            this.props.switchStep(this.props.currentStep - 1);
        else
            this.props.goBack();
    }

    mainStepClicked = (index) => {
        //Eran request to lock 'Settings' in case of a template selection other than Scratch
        //What would be the selected countries packs then? :\
        if (0 === index && this.state.templateId > TemplateTypes.ScratchTemplate) {
            return;
        }

        if (index < this.props.currentStep)
            this.props.switchStep(index);
    }

    innerStepClicked = (index) => {
        if (index < this.props.innerStep)
            this.props.switchInnerStep(index);
    }

    isCheckout = () => {
        return (this.props.currentStep === this.totalSteps() - 2 && this.props.innerStep === this.totalInnerSteps() - 1);
    }

    handleCheckout = () => {
    }

    render() {
        const { classes, currentStep, innerStep } = this.props;

        const popUpStyle = {
            position: 'absolute',
            top: '40%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70%',
            bgcolor: 'background.paper',
            border: '2px solid #FFB75A',
            boxShadow: 24,
            p: 5,
        };

        return (
            <React.Fragment>
                <Modal
                    disableScrollLock
                    open={this.props.device === 'mobile' || this.props.device === 'mobile-tablet'}
                /* onClose={handleClose} */
                >
                    <Box sx={popUpStyle}>
                        <Typography align='center' variant="h4" component="h1">
                            Sorry, not supported on Mobile
                        </Typography>
                    </Box>
                </Modal>

                <Dimmer.Dimmable blurring dimmed={this.props.user.role === UserRole.Tester}>


                    {/* TODO: CHANGE AFTER PRESENTATION TO SUPPORT BOTH WAYS */}
                    {/* { currentStep === -1 &&
                    <Box className={classes.mainBox}>
                        <Box paddingTop="60px" display="flex" justifyContent= "space-between" alignItems="center" flexDirection="column">
                            <TemplateSelectionForm 
                                //For the cached drawing
                                fetchedCycleData={this.state.fetchedCycleData} 
                                fetchedMainStep={this.state.fetchedMainStep} 
                                fetchedMinorStep={this.state.fetchedMinorStep}
                                //Functionality
                                startFromScratch={this.startFromScratch}
                                basicTemplateClick={this.LaunchBasicTemplate}
                                onBoardingTemplateClick={this.LaunchOnBoardingTemplate}
                                onBeforeLaunchTemplateClick={this.LaunchBeforeLaunchTemplate}
                                onOngoingTemplateClick={this.LaunchOngoingTemplate}
                                onIsraeliTemplateClick={this.LaunchIsraeliTemplate}
                                continueFromWhereILeft={this.continueFromWhereILeft}
                            >
                                
                            </TemplateSelectionForm>
                            
                            <Box display="flex" alignItems="center" paddingTop="25px" style={{width:"50%"}}>
                                <StyledEngineProvider injectFirst>
                                    <ThemeProvider theme={themeBtnContact}>
                                        <Link to={'/contactsales'} style={{width: "100%"}}>
                                            <Button variant="contained" style={{borderRadius: 8}}
                                                color="secondary"                                            
                                                fullWidth                                            
                                            >
                                                Need our test experts assistance or management? contact our test agency here.
                                            </Button>
                                        </Link>
                                    </ThemeProvider>
                                </StyledEngineProvider>
                            </Box>
                        </Box>                          
                    </Box>                    
                } */}

                    {currentStep === -1 &&
                        <Box className={classes.mainBoxPro}>
                            <Box paddingTop="60px" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                                <WelcomePro
                                    startFromScratch={this.startFromScratch}
                                >
                                </WelcomePro>

                                <Box display="flex" alignItems="center" paddingTop="55px" style={{ width: "50%" }}>
                                    {/* <StyledEngineProvider injectFirst>
                                        <ThemeProvider theme={themeBtnContact}> */}
                                    <Link to={'/contactsales'} style={{ width: "100%" }}>
                                        <Button variant="contained" style={{ borderRadius: 8 }}
                                            color="secondary"
                                            fullWidth
                                        >
                                            Need our test experts assistance or management? contact our test agency here.
                                        </Button>
                                    </Link>
                                    {/* </ThemeProvider>
                                    </StyledEngineProvider> */}
                                </Box>

                            </Box>
                        </Box>
                    }


                    {currentStep > -1 && currentStep < 3 &&
                        <div className={classes.mainBox}>
                            <Box display="flex" alignItems="center" flexDirection="row" justifyContent="space-around" className={classes.HeaderMenu}>
                                <StepHeader wizard={wizard} activeStep={currentStep} handleStepClick={this.mainStepClicked} startOver={this.startOver}></StepHeader>
                            </Box>

                            <Box height="100%" display="flex" alignItems="center" flexDirection="column" position="relative" className={classes.innerLeft}>
                                <InnerStepLegend activeInnerStep={innerStep}
                                    currentActiveStep={currentStep}
                                    innerStepDetails={wizard[currentStep].innerPage}
                                    stepperTitle={wizard[currentStep].mainPage}
                                    onProceedNext={this.handleNext}
                                    handleBack={this.handleBack}
                                    handleStepClick={this.innerStepClicked}>
                                </InnerStepLegend>



                                <Box style={{ position: 'absolute', bottom: "130px", width: "96%" }}>
                                    <LegendCard style={{ position: 'absolute', bottom: "230px" }}
                                        templateId={this.state.templateId}
                                        currentStep={currentStep}
                                        innerStep={innerStep}
                                        newCycle={this.props.newCycle}
                                    >
                                    </LegendCard>
                                    {!this.isCheckout() &&
                                        <Button style={{ borderRadius: 10, textTransform: "unset" }}
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            onClick={this.handleNext}
                                        >
                                            {'Proceed to ' + ((innerStep === this.totalInnerSteps() - 1) ? wizard[currentStep + 1].mainPage : wizard[currentStep].innerPage[innerStep + 1].title)}
                                        </Button>}
                                </Box>
                            </Box>

                            <Box pt="40px" pl="40px" className={classes.innerRight}>
                                <InnerContainerFactory TagName={wizard[currentStep].innerPage[innerStep].TagName}
                                    Title={wizard[currentStep].innerPage[innerStep].title}
                                    SubTitle={wizard[currentStep].innerPage[innerStep].subTitle} onNext={this.handleNext}></InnerContainerFactory>
                            </Box>
                        </div>
                    }

                    {currentStep === 3 && //Checkout, no left stepper required 
                        <div className={classes.mainBox}>
                            <Box display="flex" alignItems="center" flexDirection="row" justifyContent="space-around" className={classes.HeaderMenu}>
                                <StepHeader wizard={wizard} activeStep={currentStep} handleStepClick={this.mainStepClicked} startOver={this.startOver}></StepHeader>
                            </Box>
                            <Box pt="40px" className={classes.inner}>
                                <InnerContainerFactory TagName={wizard[currentStep].innerPage[innerStep].TagName}
                                    Title={wizard[currentStep].innerPage[innerStep].title}
                                    SubTitle={wizard[currentStep].innerPage[innerStep].subTitle} onNext={this.handleNext}></InnerContainerFactory>
                            </Box>
                        </div>
                    }

                    <Dimmer className="cycles-dimmer" active={this.props.user.role === UserRole.Tester}>
                        <p>Please log in or register as a customer to create Cycles</p>
                    </Dimmer>
                </Dimmer.Dimmable>

            </React.Fragment>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NewCycleWizard)));