import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';
import Browsers from './CompleteProfile/Browsers';
import Devices from './CompleteProfile/Devices';
import Personal from './CompleteProfile/Personal';
import Phone from './CompleteProfile/Phone';
// import Settings from 'components/Account/Tester/CompleteProfile/Settings';

const mapStateToProps = (state) => {
  return {
    device: state.resolution.device,
  };
};

class CompleteProfile extends Component<any> {
  public state: any;
  private panes: any;

  constructor(props) {
    super(props);

    this.state = {
      errors: [],
    };

    this.panes = [
      {
        menuItem: 'Personal',
        render: () => (
          <Tab.Pane>
            <Personal />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Phone',
        render: () => (
          <Tab.Pane>
            <Phone />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Devices',
        render: () => (
          <Tab.Pane>
            <Devices />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Browsers',
        render: () => (
          <Tab.Pane>
            <Browsers />
          </Tab.Pane>
        ),
      },
      // { menuItem: 'Settings',  render: () => <Tab.Pane><Settings /></Tab.Pane> },
    ];
  }

  componentDidMount() {
    this.setState({
      activeIndex: this.getActiveStep(this.props.match.params.tab),
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.step !== this.props.match.params.step) {
      this.setState({
        activeIndex: this.getActiveStep(this.props.match.params.step),
      });
    }
  }

  getActiveStep = (tab) => {
    let activeIndex;

    switch (tab) {
      case 'personal':
        activeIndex = 0;
        break;
      case 'phone':
        activeIndex = 1;
        break;
      case 'devices':
        activeIndex = 2;
        break;
      case 'browsers':
        activeIndex = 3;
        break;
      // case 'settings':
      //     activeIndex = 1;
      //     break;
      default:
        activeIndex = 0;
        break;
    }

    return activeIndex;
  };

  render() {
    const step = this.props.match.params.step;

    const isMobile = this.props.device === 'mobile' || this.props.device === 'mobile-tablet';

    if (isMobile) {
      return (
        <div className="complete-profile-tester page">
          {step === 'personal' && <Personal />}
          {step === 'phone' && <Phone />}
          {step === 'devices' && <Devices />}
          {step === 'browsers' && <Browsers />}
        </div>
      );
    }

    return (
      <div className="complete-profile-tester page">
        <div className="heading">
          <h1>Complete your profile</h1>
        </div>

        <div className="inner" style={{ display: 'flex' }}>
          <main>
            {step === 'personal' && <Personal />}
            {step === 'phone' && <Phone />}
            {step === 'devices' && <Devices />}
            {step === 'browsers' && <Browsers />}
          </main>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, null)(CompleteProfile));