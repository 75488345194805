import moment from 'moment';
import config from '../config';
import { ChatMessage } from '../types/next';

const { apiNextEndpoint: endpoint } = config;

const getHeaders = (headers = {}) => ({
  Authorization: `Bearer ${localStorage.getItem('token')}`,
  'Content-Type': 'application/json',
  ...headers,
});

const createRequest = (path = '', method = 'GET', rest = {}, headers = getHeaders()) => 
  fetch(`${endpoint}/chats/${path}`, { method, headers, ...rest })
  .then(response => ['POST', 'PATCH'].includes(method) ? true : response.json());
interface BugChat {
  chat: number;
  bug: number;
}

export const validateBugChat = async (bugId: number): Promise<any> => {
  const bugChat: BugChat = await createRequest(`?bugs=${bugId}`); 
  return bugChat[0];
};

export const getBugMessages = async (bugId: number): Promise<ChatMessage[]> => {
  const { messages } = await createRequest(`bug/${bugId}/messages`);
  return messages || [];
};

export const getBugUndreadMessagesCount = async (chatIds: number[]): Promise<Record<number, number>> => {
  const chats = await createRequest(`unread/${chatIds.join(',')}`);
  return (chats || []).reduce((acc, chatData) => ({ ...acc, [chatData.chat]: chatData.unread || 0 }), {});
};

export const setBugChatAsRead = async (chatId: number): Promise<any> => {
  return createRequest(`${chatId}/read`, 'PATCH');
};

export const createBugMessage = async (bugId: number): Promise<ChatMessage[]> => {
  const { messages } = await createRequest(`bug/${bugId}/messages`);
  return messages || [];
};

export const getChatMessages = async (chatId: number): Promise<ChatMessage[]> => {
  const messages = await createRequest(`${chatId}/messages`);
  return (messages || []).map((message): ChatMessage => ({
      id: message.id,
      user: message.user,
      content: message.content,
      creationDate: moment(message.creationDate).toDate(),
    }));
};

export const createChatMessage = async (chatId: number, content: string): Promise<void> => 
  createRequest(`${chatId}/message`, 'POST', { body: JSON.stringify({ content }) });

export const setChatLastRead = async (chatId: number): Promise<void> => 
  createRequest(`${chatId}/read`, 'PATCH');

const bugChatService = {
  getBugMessages,
  createChatMessage,
  getBugUndreadMessagesCount,
  setBugChatAsRead,
};

export default bugChatService;