import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Checkbox, Input, Message, Popup, Select, TextArea } from 'semantic-ui-react';
import noavatar from '../../../../public/images/noavatar.png';
import actions from '../../../../redux/actions';
import { Gender } from '../../../../types/Gender';
import { OperationStatus } from '../../../../types/OperationStatus';
import countryCodes from '../../../../util/options/countryCodes';
import genderOptions from '../../../../util/options/genders';
import languages from '../../../../util/options/languages';
import userSourceOptions from '../../../../util/options/userSources';
import { searchFromStart } from '../../../../util/utils';
import PasswordChangeModal from '../../../PasswordChangeModal';

const mapStateToProps = (state) => {
  return {
    userSaveStatus: state.userSaveStatus,
    userUploadAvatarStatus: state.userUploadAvatarStatus,
    passwordChangeStatus: state.passwordChangeStatus,
    user: state.user,
    devices: state.devices,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (data) => dispatch(actions.updateUser(data)),
    saveUser: (data: any, notify?: boolean) => dispatch(actions.saveUser(data, notify)),
    uploadAvatar: (avatarFile) => dispatch(actions.uploadAvatar(avatarFile)),
  };
};

const genderOptionsNoAny = genderOptions.filter((el) => el.key !== Gender.Any);

type PersonalState = {
  newAvatar: any;
  newAvatarError: string;
  errors: Set<string>;
};

class Personal extends Component<any> {
  public state: PersonalState;

  constructor(props) {
    super(props);

    this.state = {
      newAvatar: {},
      newAvatarError: '',
      errors: new Set(),
    };
  }

  resetErrorState = (event) => {
    const name = event.target.name;
    const errors = this.state.errors;

    if (errors.has(name)) {
      errors.delete(name);
      this.setState({ errors });
    }
  };

  onChange = (event, data) => {
    const user = {};

    switch (data.name) {
      case 'sendInvitations': {
        user[data.name] = data.checked;
        break;
      }

      case 'age': {
        data.value = data.value.replace(/^[0]+/g, '');
        user[data.name] = data.value;
        break;
      }

      default: {
        user[data.name] = data.value;
        break;
      }
    }

    return this.props.updateUser(user);
  };

  validateInput = (event, data) => {
    const errors = this.state.errors;
    const name = data && data.name ? data.name : event.target.name;
    const value = data && data.value ? data.value : event.target.value;

    if (name === 'age') {
      if (!value.length || parseInt(value) < 0 || isNaN(Number(value)) || parseInt(value) > 120) {
        errors.add(name);
      } else {
        errors.delete(name);
      }

      this.setState({ errors });
      return;
    }

    if (name === 'minHourlyRate') {
      if (!value.length || parseInt(value) < 0 || isNaN(Number(value))) {
        errors.add(name);
      } else {
        errors.delete(name);
      }

      this.setState({ errors });
      return;
    }

    if (name === 'source') {
      if (!value) {
        errors.add(name);
      } else {
        errors.delete(name);
      }

      this.setState({ errors });
      return;
    }

    if (!value || !value.length) {
      errors.add(name);
    } else {
      errors.delete(name);
    }

    this.setState({ errors });
  };

  onAvatarUpload = ([acceptedImageFile]) => {
    const newAvatar = Object.assign(acceptedImageFile, {
      preview: URL.createObjectURL(acceptedImageFile),
    });

    this.setState({
      newAvatar,
      newAvatarError: '',
    });
  };

  onAvatarRejected = () => {
    this.setState({ newAvatarError: 'Avatar size can be no more than 1 Mb' });
  };

  onSave = async () => {
    const userData: Record<string, any> = Object.keys(this.props.user).reduce((result, el) => {
      if (
        this.props.user[el] !== null &&
        el !== 'cycles' // cycles are managed separately
      ) {
        result[el] = this.props.user[el];
      }

      return result;
    }, {});

    delete userData.avatarUrl;

    if (this.state.newAvatar && Object.keys(this.state.newAvatar).length) {
      await this.props.uploadAvatar(this.state.newAvatar);
    }

    this.props.saveUser(userData, true);
  };

  render() {
    const userSaveProcessing = this.props.userSaveStatus.status === OperationStatus.processing || this.props.userUploadAvatarStatus.status === OperationStatus.processing;
    const passwordChangeProcessing =
      this.props.passwordChangeStatus.status === OperationStatus.processing;
    const user = this.props.user;

    let avatarUrl;

    if (this.state.newAvatar.preview) {
      avatarUrl = this.state.newAvatar.preview;
    } else if (user.avatarUrl) {
      avatarUrl = user.avatarUrl;
    } else {
      avatarUrl = noavatar;
    }

    const errors = this.state.errors;

    return (
      <div>
        <h2>Personal Information</h2>
        <table className="personal-information">
          <tbody>
            <tr>
              <td>Photo</td>
              <td className="avatar-cell">
                <img className="avatar" src={avatarUrl} alt="avatar" height="128" width="128" />
                {this.state.newAvatarError && (
                  <Message negative compact size="mini">
                    {this.state.newAvatarError}
                  </Message>
                )}
                <Dropzone
                  multiple={false}
                  maxSize={1000000}
                  accept="image/jpeg, image/png"
                  onDropAccepted={this.onAvatarUpload}
                  onDropRejected={this.onAvatarRejected}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Popup
                        trigger={
                          <Button
                            icon="question"
                            content="Change"
                            labelPosition="right"
                            primary
                            size="tiny"
                            style={{ width: '128px' }}
                          />
                        }
                        content="A good profile image makes a client feel more secure"
                        on="hover"
                        position="top left"
                      />
                    </div>
                  )}
                </Dropzone>
              </td>
            </tr>
            <tr>
              <td>First name</td>
              <td>
                <Input
                  placeholder="First name"
                  name="firstName"
                  value={user.firstName}
                  onChange={this.onChange}
                  onFocus={this.resetErrorState}
                  onBlur={this.validateInput}
                  error={errors.has('firstName')}
                />
              </td>
            </tr>
            <tr>
              <td>Last name</td>
              <td>
                <Input
                  placeholder="Last name"
                  name="lastName"
                  value={user.lastName}
                  onChange={this.onChange}
                  onFocus={this.resetErrorState}
                  onBlur={this.validateInput}
                  error={errors.has('lastName')}
                />
              </td>
            </tr>
            <tr>
              <td>Gender</td>
              <td>
                <Select
                  placeholder="Please choose"
                  name="gender"
                  value={user.gender}
                  options={genderOptionsNoAny}
                  onChange={this.onChange}
                  onFocus={this.resetErrorState}
                  onBlur={this.validateInput}
                  error={errors.has('gender')}
                />
              </td>
            </tr>
            <tr>
              <td>Age</td>
              <td>
                <Input
                  type="number"
                  min="16"
                  placeholder="Age"
                  name="age"
                  value={user.age || ''}
                  onChange={this.onChange}
                  onFocus={this.resetErrorState}
                  onBlur={this.validateInput}
                  error={errors.has('age')}
                />
              </td>
            </tr>
            <tr>
              <td>Country</td>
              <td>
                <Select
                  name="country"
                  placeholder="Country"
                  options={countryCodes}
                  value={user.country}
                  onChange={this.onChange}
                  onFocus={this.resetErrorState}
                  onBlur={this.validateInput}
                  error={errors.has('country')}
                  selectOnBlur={false}
                  search={searchFromStart}
                  disabled
                />
              </td>
            </tr>
            <tr>
              <td>Native language</td>
              <td>
                <Select
                  placeholder="Native language"
                  name="nativeLanguage"
                  value={user.nativeLanguage}
                  options={languages}
                  onChange={this.onChange}
                  onFocus={this.resetErrorState}
                  onBlur={this.validateInput}
                  error={errors.has('nativeLanguage')}
                  selectOnBlur={false}
                  search={searchFromStart}
                />
              </td>
            </tr>
            <tr>
              <td>Other language</td>
              <td>
                <Select
                  placeholder="Other language"
                  name="otherLanguage"
                  value={user.otherLanguage}
                  options={languages}
                  onChange={this.onChange}
                  selectOnBlur={false}
                  search={searchFromStart}
                />
              </td>
            </tr>
            <tr>
              <td>About yourself</td>
              <td>
                <TextArea
                  className={errors.has('about') ? 'error' : ''}
                  name="about"
                  value={user.about}
                  onChange={this.onChange}
                  onFocus={this.resetErrorState}
                  onBlur={this.validateInput}
                />
              </td>
            </tr>
            <tr>
              <td>How did you reach us?</td>
              <td>
                <Select
                  selection
                  placeholder="Please choose"
                  name="source"
                  value={user.source}
                  options={userSourceOptions}
                  onChange={this.onChange}
                  onFocus={this.resetErrorState}
                  onBlur={this.validateInput}
                  error={errors.has('source')}
                  selectOnBlur={false}
                />
              </td>
            </tr>
            <tr>
              <td>Hourly rate</td>
              <td>
                <Input
                  type="number"
                  placeholder="Hourly rate"
                  name="minHourlyRate"
                  value={user.minHourlyRate}
                  onChange={this.onChange}
                  onFocus={this.resetErrorState}
                  onBlur={this.validateInput}
                  error={errors.has('minHourlyRate')}
                />
                <p className="hourly-rate-notice">
                  <span>Notice: </span>
                  You won&apos;t get invitations to testing cycles with hourly price less than your
                  minimum hourly price.
                  <br />0 means &quot;Offer me anything&quot;
                </p>
              </td>
            </tr>
            <tr>
              <td> Send me testing invitations?</td>
              <td>
                <Checkbox
                  label="Yes"
                  name="sendInvitations"
                  checked={user.sendInvitations}
                  onChange={this.onChange}
                  toggle
                />
              </td>
            </tr>
          </tbody>
        </table>

        <Button
          primary
          loading={userSaveProcessing}
          disabled={userSaveProcessing || errors.size > 0}
          onClick={this.onSave}
        >
          Save
        </Button>
        <PasswordChangeModal />
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Personal));
