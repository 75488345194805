import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Button, Input, Icon, Table, Message } from 'semantic-ui-react';
import CycleDetailsSidemenu from './Sidemenu';
import actions from '../../../../redux/actions';
import { OperationStatus } from '../../../../types/OperationStatus';
import { DevicePlatform } from '../../../../types/DevicePlatform';
import { TestEnv } from '../../../../types/TestingEnvironment';
import { UserStatus } from '../../../../types/UserStatus';
import config from '../../../../config';

const mapStateToProps = (state) => {
  return {
    user: state.user,
    devices: state.devices,
    browsers: state.browsers,
    cycles: state.cycles,
    cycleAcceptStatus: state.cycleAcceptStatus,
    device: state.resolution.device,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    applyForCycle: (cycleId, testerBid) => dispatch(actions.applyForCycle(cycleId, testerBid)),
  };
};

type RequiredTestersPerPlatform = {
  id: number;
  name: string;
};

type TesterDevice = {
  name: string;
  platformId: string;
  manufacturerId: number;
  modelId: number;
  osId: number;
};

type TesterBrowser = {
  platformId: string;
  browserId: string;
  browserVersionId: string;
};

type CycleApplyState = {
  cycle: any;
  cycleId: number;
  suitableDeviceNames: string[];
  testerBid: string;
  errors: Set<string>;
};

class CycleApply extends Component<any> {
  public state: CycleApplyState;

  private requiredPlatforms: TestEnv[] = [];
  private requiredDevices: TestEnv[] = [];

  constructor(props) {
    super(props);

    this.state = {
      cycle: {},
      cycleId: parseInt(props.match.params.id),
      suitableDeviceNames: [],
      testerBid: '',
      errors: new Set(),
    };
  }

  componentDidMount() {
    if (!this.props.cycles.length) {
      return;
    }

    const cycle = this.props.cycles.find((el) => el.id === this.state.cycleId);

    // TODO DRY with getSuitableDeviceNames
    const nonPlatformEnvPlatformIds = cycle.testEnvs.reduce((result, el) => {
      result.add(el.platformId);
      return result;
    }, new Set());

    this.requiredPlatforms = cycle.testEnvs.filter((el) => {
      if (el.type === 'Platform' && !nonPlatformEnvPlatformIds.has(el.platformId)) {
        return true;
      }

      return false;
    });

    this.requiredDevices = cycle.testEnvs.filter((el) => el.type !== 'Platform');

    const suitableDeviceNames = this.getSuitableDeviceNames(
      cycle.testEnvs,
      this.props.user.devices,
      this.props.user.browsers
    );

    this.setState({
      cycle,
      suitableDeviceNames,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(this.props.cycles, prevProps.cycles)) {
      const cycle = this.props.cycles.find((el) => el.id === this.state.cycleId);
      this.setState({ cycle });
    }

    if (this.props.cycleAcceptStatus.status === OperationStatus.success) {
      this.props.history.push('/account/tester');
    }

    if (
      !_.isEqual(prevProps.user.devices, this.props.user.devices) ||
      !_.isEqual(prevProps.user.browsers, this.props.user.browsers) ||
      !_.isEqual(prevState.cycle, this.state.cycle)
    ) {
      const suitableDeviceNames = this.getSuitableDeviceNames(
        this.state.cycle.testEnvs,
        this.props.user.devices,
        this.props.user.browsers
      );

      this.setState({
        ...this.state,
        suitableDeviceNames,
      });
    }
  }

  onTesterBidUpdate = (event, data) => {
    this.setState({
      testerBid: data.value,
      errors: new Set(),
    });
  };

  onApply = () => {
    if (
      this.props.user.status === UserStatus.EvalCyclePending &&
      this.state.cycleId === config.evalCycleId
    ) {
      this.props.applyForCycle(this.state.cycle.id, 0);
      return;
    }

    if (this.state.testerBid === '') {
      this.setState({
        errors: new Set(["Field can't be empty"]),
      });

      return;
    }

    const testerBid: any = Number(this.state.testerBid);

    if (!Number.isInteger(testerBid)) {
      this.setState({
        errors: new Set(['Bid should be an integer']),
      });

      return;
    }

    if (isNaN(testerBid)) {
      this.setState({
        errors: new Set(['Bid should be a integer']),
      });

      return;
    }

    if (!testerBid) {
      this.setState({
        errors: new Set(['Please choose your bid']),
      });

      return;
    }

    if (testerBid < 0) {
      this.setState({
        errors: new Set(["Bid cani't be negative"]),
      });

      return;
    }

    if (testerBid > this.state.cycle.hourlyRate) {
      this.setState({
        errors: new Set([`Maximal bid allowed for this cycles is $${this.state.cycle.hourlyRate}`]),
      });

      return;
    }

    if (!this.state.errors.size) {
      this.props.applyForCycle(this.state.cycle.id, this.state.testerBid);
    }
  };

  getSuitableDeviceNames = (
    requiredTestEnvs: TestEnv[],
    testerDevices: TesterDevice[] = [],
    testerBrowsers: TesterBrowser[] = []
  ): string[] => {
    const nonPlatformEnvPlatformIds = requiredTestEnvs.reduce((result, el) => {
      if (el.type !== 'Platform') {
        result.add(el.platformId);
      }

      return result;
    }, new Set());

    const requiredDevices = requiredTestEnvs.filter((el) => el.type === 'MobileDevice');
    const requiredBrowsers = requiredTestEnvs.filter((el) => el.type === 'DesktopBrowser');
    const requiredPlatforms = requiredTestEnvs.filter((el) => {
      return el.type === 'Platform' && !nonPlatformEnvPlatformIds.has(el.platformId);
    });

    const suitableDeviceNames: Set<string> = new Set();

    for (const testerDevice of testerDevices) {
      if (
        requiredPlatforms.find((el) => el.platformId === testerDevice.platformId) ||
        requiredDevices.find((el) => (el as any).modelId === testerDevice.modelId)
      ) {
        const name = this.props.devices.models.find((el) => el.id === testerDevice.modelId).name;
        suitableDeviceNames.add(name);
      }
    }

    for (const testerBrowser of testerBrowsers) {
      if (requiredBrowsers.find((el) => (el as any).browserId === testerBrowser.browserId)) {
        const browserName = this.props.browsers.names.find(
          (el) => el.id === testerBrowser.browserId
        ).name;
        const browserVersion = this.props.browsers.versions.find(
          (el) => el.id === testerBrowser.browserVersionId
        ).version;
        suitableDeviceNames.add(`${browserName} ${browserVersion}`);
      }
    }

    if (requiredPlatforms.find((el) => el.platformId === DevicePlatform.Desktop)) {
      for (const testerBrowser of testerBrowsers) {
        const browserName = this.props.browsers.names.find(
          (el) => el.id === testerBrowser.browserId
        ).name;
        const browserVersion = this.props.browsers.versions.find(
          (el) => el.id === testerBrowser.browserVersionId
        ).version;
        suitableDeviceNames.add(`${browserName} ${browserVersion}`);
      }
    }

    return Array.from(suitableDeviceNames);
  };

  render() {
    const cycle = this.state.cycle;

    if (!Object.keys(cycle).length) {
      return null;
    }

    const suitableDeviceNames = this.state.suitableDeviceNames;

    const applyDisabled =
      suitableDeviceNames.length === 0 ||
      this.props.cycleAcceptStatus.status === OperationStatus.processing;

    const applyLoading = this.props.cycleAcceptStatus.status === OperationStatus.processing;

    const isMobile = this.props.device === 'mobile' || this.props.device === 'mobile-tablet';    
    if( isMobile ){
      return (
        <>
        <CycleDetailsSidemenu cycleId={cycle.id} />
        <div className="account-tester page">          
          <div className="inner" style={{ display: 'flex' }}>            
            <main className="cycle-accept">
              {this.requiredPlatforms.length > 0 && (
                <div className="requested-devices">
                  <h2>The customer requested the following platforms</h2>
  
                  <Table compact basic definition>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>Platforms</Table.Cell>
                        <Table.Cell>
                          <ul>
                            {this.requiredPlatforms.map((el, i) => (
                              <li key={i}>{el.name}</li>
                            ))}
                          </ul>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              )}
  
              {this.requiredDevices.length > 0 && (
                <div className="requested-devices">
                  <h2>The customer requested the following devices</h2>
  
                  <Table compact basic definition>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>Devices</Table.Cell>
                        <Table.Cell>
                          <ul>
                            {this.requiredDevices.map((el, i) => (
                              <li key={i}>{el.name}</li>
                            ))}
                          </ul>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              )}
  
              <div className="my-devices">
                <h2>The following devices from your settings are applicable for this cycle</h2>
  
                {suitableDeviceNames.length ? (
                  <Table compact basic definition>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>Devices</Table.Cell>
                        <Table.Cell>
                          <ul>
                            {suitableDeviceNames.map((el, i) => (
                              <li key={i}>{el}</li>
                            ))}
                          </ul>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                ) : (
                  <Message negative>
                    You don&apos;t have any devices applicable for this cycle
                  </Message>
                )}
              </div>
  
              <div className="cycle-accept-actions">
                <Link to="/account/tester/myprofile/devices">
                  <Button primary icon size="tiny" labelPosition="right">
                    Add more devices
                    <Icon name="plus" />
                  </Button>
                </Link>
  
                <Message info style={{ alignSelf: 'normal' }}>
                  dear tester, any of your listed devices can be chosen!
                  <br />
                  Make sure your profile is updated.
                </Message>
  
                {this.props.user.status === UserStatus.EvalCyclePending && !cycle.testerStatus ? (
                  <Button
                    positive
                    icon
                    className="accept"
                    size="large"
                    onClick={this.onApply}
                    disabled={applyDisabled}
                    loading={applyLoading}
                    labelPosition="right"
                  >
                    Accept this cycle
                    <Icon name="check" />
                  </Button>
                ) : (
                  <React.Fragment>
                    {this.state.errors.size > 0 && (
                      <Message negative>{this.state.errors.values().next().value}</Message>
                    )}
  
                  {cycle.testerStatus && cycle.testerBid ? (
                    <>                    
                      <Button
                        positive
                        disabled
                        icon
                        className="accept"
                        size="large"                        
                        labelPosition="right"
                      >
                        {`You bid $${cycle.testerBid} already`}
                        <Icon name="check" />
                      </Button>
                    </>
                    
                  ) : (
                    <>
                      <label>
                        My bid: &nbsp;
                        <Input
                          name="testerBid"
                          onChange={this.onTesterBidUpdate}
                          value={this.state.testerBid}
                          error={this.state.errors.size > 0}
                        />
                        &nbsp; (up to ${this.state.cycle.hourlyRate})
                      </label>
                      <Button
                        positive
                        icon
                        className="accept"
                        size="large"
                        onClick={this.onApply}
                        disabled={suitableDeviceNames.length === 0 || this.state.errors.size > 0}
                        labelPosition="right"
                      >
                        Accept this cycle
                        <Icon name="check" />
                      </Button>
                    </>
                  )}
                  </React.Fragment>
                )}
  
                <span className="terms">
                  By accepting I <Link to="/terms">agree to the terms</Link>
                </span>
              </div>
            </main>
          </div>
        </div>
        </>
      );
    }    
    return (
      <div className="account-tester page">
        <div className="heading">
          <h1>Accept Cycle</h1>
        </div>

        <div className="inner" style={{ display: 'flex' }}>
          <CycleDetailsSidemenu cycleId={cycle.id} />

          <main className="cycle-accept">
            {this.requiredPlatforms.length > 0 && (
              <div className="requested-devices">
                <h2>The customer requested the following platforms</h2>

                <Table compact basic definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Platforms</Table.Cell>
                      <Table.Cell>
                        <ul>
                          {this.requiredPlatforms.map((el, i) => (
                            <li key={i}>{el.name}</li>
                          ))}
                        </ul>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            )}

            {this.requiredDevices.length > 0 && (
              <div className="requested-devices">
                <h2>The customer requested the following devices</h2>

                <Table compact basic definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Devices</Table.Cell>
                      <Table.Cell>
                        <ul>
                          {this.requiredDevices.map((el, i) => (
                            <li key={i}>{el.name}</li>
                          ))}
                        </ul>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            )}

            <div className="my-devices">
              <h2>The following devices from your settings are applicable for this cycle</h2>

              {suitableDeviceNames.length ? (
                <Table compact basic definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Devices</Table.Cell>
                      <Table.Cell>
                        <ul>
                          {suitableDeviceNames.map((el, i) => (
                            <li key={i}>{el}</li>
                          ))}
                        </ul>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              ) : (
                <Message negative>
                  You don&apos;t have any devices applicable for this cycle
                </Message>
              )}
            </div>

            <div className="cycle-accept-actions">
              <Link to="/account/tester/myprofile/devices">
                <Button primary icon size="tiny" labelPosition="right">
                  Add more devices
                  <Icon name="plus" />
                </Button>
              </Link>

              <Message info style={{ alignSelf: 'normal' }}>
                dear tester, any of your listed devices can be chosen!
                <br />
                Make sure your profile is updated.
              </Message>

              {this.props.user.status === UserStatus.EvalCyclePending && !cycle.testerStatus ? (
                <Button
                  positive
                  icon
                  className="accept"
                  size="large"
                  onClick={this.onApply}
                  disabled={applyDisabled}
                  loading={applyLoading}
                  labelPosition="right"
                >
                  Accept this cycle
                  <Icon name="check" />
                </Button>
              ) : (
                <React.Fragment>
                  {this.state.errors.size > 0 && (
                    <Message negative>{this.state.errors.values().next().value}</Message>
                  )}
                  {cycle.testerStatus && cycle.testerBid ? (
                    <>                    
                      <Button
                        positive
                        disabled
                        icon
                        className="accept"
                        size="large"                        
                        labelPosition="right"
                      >
                        {`You bid $${cycle.testerBid} already`}
                        <Icon name="check" />
                      </Button>
                    </>
                    
                  ) : (
                    <>
                      <label>
                        My bid: &nbsp;
                        <Input
                          name="testerBid"
                          onChange={this.onTesterBidUpdate}
                          value={this.state.testerBid}
                          error={this.state.errors.size > 0}
                        />
                        &nbsp; (up to ${this.state.cycle.hourlyRate})
                      </label>
                      <Button
                        positive
                        icon
                        className="accept"
                        size="large"
                        onClick={this.onApply}
                        disabled={suitableDeviceNames.length === 0 || this.state.errors.size > 0}
                        labelPosition="right"
                      >
                        Accept this cycle
                        <Icon name="check" />
                      </Button>
                    </>
                  )}
                  
                </React.Fragment>
              )}

              <span className="terms">
                By accepting I <Link to="/terms">agree to the terms</Link>
              </span>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CycleApply));
