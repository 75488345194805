import { Grid, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import wizardActions from '../../redux/actions/wizardActions';
import { NewWizardCycle } from '../../types/NewWizardCycle';
import { OperationStatus } from '../../types/OperationStatus';
import { UserRole } from '../../types/UserRole';
import { PayoutRatesAndTestersQuota, QuestionTimeCategory, SpecificationType } from '../../types/WizardCategoriesTypes';
import { WorkCategory } from '../../types/WorkCategory';
import SharedStyle from '../Wizard/InnerForms/SharedStyle';

const styles = (theme) => createStyles({
    root: {
        marginTop: SharedStyle.innerContent.marginTop,
        marginLeft: SharedStyle.innerContent.marginLeft,
    },
    rightSection: {
        marginLeft: SharedStyle.innerContent.marginLeft,
    },
    SpreadBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
    },
    devider: {
        borderRight: '1px solid #DEEEFF',
    },

    AttributeName: {
        fontSize: '14px',
        color: theme.palette.common.black,
        fontWeight: 'normal',
        letterSpacing: '0.1px',
    },

    AttributeNameBold: {
        fontSize: '14px',
        color: theme.palette.common.black,
        fontWeight: 'bold',
        letterSpacing: '0.1px',
    },
    checkout: {
        position: 'absolute',
        bottom: '130px',
        right: '30px',
        width: '40%',
    },
    strikedText: {
        /* fontSize: '18px', */
        /* fontStyle: 'italic', */
        textDecoration: 'line-through',
    },
    customLabel: {
        borderRadius: 5,
        opacity: 0.75,
    }

});


const mapStateToProps = (store) => {
    return {
        newCycle: store.newWizardCycle as NewWizardCycle,
        user: store.user,
        cycleCreateStatus: store.cycleCreateStatus,
        couponFetchStatus: store.couponFetchStatus,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        updateCycle: (data) => dispatch(wizardActions.updateCycle(data)),
        submitCycle: (data) => dispatch(wizardActions.submitCycle(data)),
        fetchCoupon: (couponCode) => dispatch(wizardActions.fetchCoupon(couponCode)),
    };
};

class FreeCycleSummary extends Component<any> {
    public state: any;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    };

    componentDidUpdate(prevProps) {
        if (this.props.couponFetchStatus.status !== prevProps.couponFetchStatus.status) {
            if (this.props.couponFetchStatus.status === OperationStatus.fail) {
                toast.error(this.props.couponFetchStatus.errorMessage);
            }
            else if (this.props.couponFetchStatus.status === OperationStatus.success) {
                ;
                //Need to tie the coupon id from the response!                
            }
        }
    }

    timeTxtFromDeliveryTime = () => {
        let deliveryTimeTxt
        switch (this.props.newCycle.deliveryTime) {
            case '24hours': deliveryTimeTxt = '24 hours'; break;
            case '3days': deliveryTimeTxt = '3 days'; break;
            case '7days': deliveryTimeTxt = '7 days'; break;
        }
        return deliveryTimeTxt;
    }

    timeTxtFromQuestionTime = () => {
        let QuestionTimeTxt
        switch (this.props.newCycle.questionsTime) {
            case '24hours': QuestionTimeTxt = '24 hours'; break;
            case '2days': QuestionTimeTxt = '2 days'; break;
            case '4days': QuestionTimeTxt = '4 days'; break;
        }
        return QuestionTimeTxt;
    }

    retrieveExtraPayoutPerHours = (hoursPerTester) => {
        switch (hoursPerTester) {
            case 2: { return PayoutRatesAndTestersQuota._2hrsTestingExtra; }
            case 3: { return PayoutRatesAndTestersQuota._3hrsTestingExtra; }
            case 4: { return PayoutRatesAndTestersQuota._4hrsTestingExtra; }
        }
        return 0;
    }

    retrieveExtraPayoutPerQtime = (questionsTime) => {
        switch (questionsTime) {
            case QuestionTimeCategory._2days: { return PayoutRatesAndTestersQuota._2daysQtimeExtra; }
            case QuestionTimeCategory._4days: { return PayoutRatesAndTestersQuota._4daysQtimeExtra; }
        }
        return 0;
    }


    calcTotalAmountPerTester = () => {
        let hourlyRate = PayoutRatesAndTestersQuota.baseHrlyRate;
        const newCycle = this.props.newCycle as NewWizardCycle;

        if (newCycle.workCategory === WorkCategory.FindBugsTestPlan) {
            hourlyRate += PayoutRatesAndTestersQuota.testPlanExtra;
        }

        hourlyRate += this.retrieveExtraPayoutPerHours(newCycle.hoursPerTester);
        hourlyRate += this.retrieveExtraPayoutPerQtime(newCycle.questionsTime);

        return hourlyRate;

    }

    combineDurationTxt = () => {
        const newCycle = this.props.newCycle as NewWizardCycle;
        let txt = `${newCycle.hoursPerTester}` + (newCycle.hoursPerTester > 1 ? ' hours' : ' hour') + ' duration';

        let hoursDurationExtra = this.retrieveExtraPayoutPerHours(newCycle.hoursPerTester);
        if (hoursDurationExtra) {
            txt += ` (+$${hoursDurationExtra})`;
        }

        return txt;
    }

    combinePeriodTxt = () => {
        const newCycle = this.props.newCycle as NewWizardCycle;
        let txt = this.timeTxtFromDeliveryTime() + ' overall time, ' + this.timeTxtFromQuestionTime() + ' Questions time';
        let qTimeExtra = this.retrieveExtraPayoutPerQtime(newCycle.questionsTime)
        if (qTimeExtra) {
            txt += ` (+$${qTimeExtra})`;
        }

        return txt;
    }

    calcCyclePrice = () => {
        const newCycle = this.props.newCycle as NewWizardCycle;

        const numberOfTesters = newCycle.testEnvs.reduce((result, el) => {
            result += el.requiredTesters;
            return result;
        }, 0);

        const price = numberOfTesters * newCycle.hoursPerTester * newCycle.hourlyRate;

        return price;
    }

    onChangeCouponCode = (event, data) => {
        const newCycle = {
            ...this.props.newCycle,
            couponDetails: {
                ...this.props.newCycle.couponDetails,
                code: data.value
            }
        };
        return this.props.updateCycle(newCycle);
    };

    useCoupon = () => {
        this.props.fetchCoupon(this.props.newCycle.couponDetails.code);
    }

    removeCoupon = () => {
        const newCycle = {
            ...this.props.newCycle,
            couponDetails: {
                id: -1,
                code: '',
                discount: 0
            }
        };
        return this.props.updateCycle(newCycle);
    }

    render() {
        const { classes } = this.props;
        const newCycle = this.props.newCycle as NewWizardCycle;
        const typeTxt = (newCycle.workCategory === WorkCategory.FindBugsTestPlan) ? `Test plan (+$${PayoutRatesAndTestersQuota.testPlanExtra})` : 'Exploratory';
        const specificationTxt = (newCycle.testSpecification === SpecificationType.Website) ? 'Website' : 'Native app';
        const durationTxt = this.combineDurationTxt();
        const periodTxt = this.combinePeriodTxt();
        const countriesTxt = newCycle.isWorldwide ? (<Typography variant="h4">Worldwide</Typography>) : newCycle.countries.map(c => <Typography variant="h4">{c}</Typography>);
        const platformTxtName = newCycle.mainTestPlatform.name;

        const numberOfTesters = newCycle.testEnvs.reduce((result, el) => {
            result += el.requiredTesters;
            return result;
        }, 0);

        const hourlyRatePerTesterTxt = '$' + this.calcTotalAmountPerTester();

        const totalPrice = parseFloat((this.calcCyclePrice()).toFixed(2));                                       //Total price of the test
        const totalPriceUsingCoupon = parseFloat((totalPrice * (1 - (newCycle.couponDetails.discount / 100.0))).toFixed(2));    //Actual Total using coupon
        let userBalance: number = 0;
        if (this.props.user.role != UserRole.Anonymous) {
            userBalance = parseFloat((this.props.user.balance).toFixed(2));                            //Player wallet    
        }
        const amountWithoutVat: number = parseFloat((totalPriceUsingCoupon - userBalance).toFixed(2));                      //Payment amountWithoutVat
        const isPaymentRequired = amountWithoutVat > 0;                           //isPaymentRequired
        const discountStr = `Valid coupon, ${newCycle.couponDetails.discount}% off!`;

        const isWithVat = (this.props.user.country === 'Israel');                 //isWithVat
        const vatPercent: number = isWithVat ? 17 : 0;
        const vatAmount: number = parseFloat((amountWithoutVat * vatPercent / 100).toFixed(2));
        const amountIncludingVat: number = parseFloat((amountWithoutVat + vatAmount).toFixed(2)); //Total amount to pay:!

        const userBalanceStr = `$${userBalance}`;
        const totalPriceStr = `$${totalPrice}`;
        const totalPriceUsingCouponStr = `$${totalPriceUsingCoupon}`;
        const amountWithoutVatStr = `$${amountWithoutVat}`;
        const amountIncludingVatStr = `$${amountIncludingVat}`;
        const VatAmountStr = `$${vatAmount}`;

        const couponPreset = newCycle.couponDetails.id < 0;
        const couponSet = newCycle.couponDetails.id > 0;
        const descriptionTruncated = newCycle.description.length <= 100 ? newCycle.description : newCycle.description.substring(0, 100) + '...';

        return (
            <React.Fragment>
                <Grid container>

                    <Grid item xs={12} /* className={classes.devider} */>
                        <Grid container spacing={2}>

                            {/* LEFT PANEL */}

                            <Grid item xs={12}>
                                <Typography align="left" variant="h5">What's next</Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography className={classes.AttributeName}>
                                    Instantly after checkout you will be redirected to the test dashboard! <br />
                                    <br />
                                    The system will look for the best suitable testers out of many from all around the world to meet your needs. <br />
                                    <br />
                                    Once assigned they will start testing based on your instructions; add bugs, comments, upload images and answer your questions. <br />
                                    All communication will be available in real time on the test dashboard.
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography className={classes.AttributeNameBold}>Your free test specifications:</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography className={classes.AttributeName}>Test name</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h4">{newCycle.title}</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography className={classes.AttributeName}>Main platform</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h4">{platformTxtName} - {specificationTxt}</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography className={classes.AttributeName}>Number of testers</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h4">{numberOfTesters}</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography className={classes.AttributeName}>Test location</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                {countriesTxt}
                            </Grid>

                            <Grid item xs={6}>
                                <Typography className={classes.AttributeName}>Test duration</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h4">{durationTxt}</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography className={classes.AttributeName}>Test Cycle period</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h4">{periodTxt}</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography className={classes.AttributeName}>Starting point URL</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                {/* <Link target="_blank" to={{ pathname: newCycle.startingPointURL }}>{newCycle.startingPointURL}</Link> */}
                                <a href={newCycle.startingPointURL} target="_blank">{newCycle.startingPointURL}</a>
                            </Grid>


                            <Grid item xs={6}>
                                <Typography className={classes.AttributeName}>Test instructions</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h4" style={{ whiteSpace: 'pre-wrap' }}>{descriptionTruncated}</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography className={classes.AttributeName}>Total cost</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h4">$1</Typography>
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item xs={0} className={classes.SpreadBox}>

                    </Grid>

                </Grid>
            </React.Fragment >

        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FreeCycleSummary)));