import React from 'react';
import { Button, TextArea } from 'semantic-ui-react';
import StarRatingComponent from 'react-star-rating-component';

export const TesterReviewForm = (props) => {
  return (
    <div className="customer-review-form">
      <h4>This tester has finished working. Please give him a rating and leave a review</h4>

      <div className="rating">
        Rating:{' '}
        <StarRatingComponent
          name="rate1"
          starCount={5}
          value={props.rating}
          onStarClick={props.onRatingChange}
        />
      </div>

      <TextArea onChange={props.onReviewTextChange} />

      <Button positive disabled={!props.rating || props.isViewer} onClick={props.onSubmitReview}>
        {' '}
        Submit review
      </Button>
    </div>
  );
};
