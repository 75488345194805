import FacebookIcon from '@mui/icons-material/Facebook';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import values from 'lodash/values';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';

import actions from '../redux/actions';
import { RootState } from '../redux/store';
import { OperationStatus } from '../types/OperationStatus';
import { UserRole } from '../types/UserRole';

import { useGoogleLogin } from '@react-oauth/google';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { useHistory } from 'react-router';
import { Dimmer, Loader } from 'semantic-ui-react';
import AvatarBackground from '../elements/AvatarBackground';
import Button from '../elements/Button';
import Hero from '../elements/Hero';
import GoogleIcon from '../public/images/icons/GoogleIcon';
import theme from '../themes';

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme { }
}


const useStyles = makeStyles((theme: Theme) => ({
    loginBox: {
        padding: 35,
        marginTop: -90,
    },
    avatarBackground: {
        paddingBottom: 97,
        [theme.breakpoints.down('md')]: {
            paddingBottom: 0,
        },
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 0,
            height: '115vh',
        },
    },
    registrationButtonText: {
        marginLeft: 10,
    },
    smBottomSpace: {
        marginBottom: 25,
    },
    smTopSpace: {
        marginTop: 15,
    },
    mdTopSpace: {
        marginTop: 30,
    },
    lgTopSpace: {
        marginTop: 50,
    },
    accountText: {
        fontSize: 14,
    },
    link: {
        marginLeft: 3,
    },
}));

const validateCredentials = ({ email, password }) => {
    const errors = { email: '', password: '' };
    errors.email = !isEmail(email) ? 'Please enter a valid email' : '';
    errors.password = isEmpty(password) || password.length < 6 ? 'Password must contain at least 6 characters' : '';
    return errors;
};

const LoginPage = () => {
    const classes = useStyles();
    const location: any = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    const googleLogin = useGoogleLogin({
        onSuccess: (response) => { setGoogleResponse(response) },
        onError: (error) => console.log('Google Login Failed:', error)
    });

    const { resolution, loginStatus, user } = useSelector((state: RootState) => state);

    const isMobile = resolution.device === 'mobile';
    const isMobileTablet = resolution.device === 'mobile-tablet';

    //First - look in the querystring to see whether he was redirected from the App
    let { returnTo } = queryString.parse(location.search);

    //Didn't find it - maybe it was from the marketing site referrer to a private link target
    if (!returnTo) {
        returnTo = location?.state?.referrer;
    }

    //No referrer - go to default
    if (!returnTo) {
        returnTo = '/account';
    }

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [googleResponse, setGoogleResponse] = useState<any>(null);

    const [errors, setErrors] = useState({ email: '', password: '' });


    const popUpStyle = {
        position: 'absolute',
        top: '60%',
        left: '50%',
        transform: 'rotate(12deg) translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        border: '2px solid #FFB75A',
        boxShadow: 24,
        p: 5,
    };

    const [open, setOpen] = React.useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const clearErrors = () => {
        if (some(values(errors))) {
            setErrors({ email: '', password: '' });
        }
    };

    const login = (e) => {
        e.preventDefault();

        if (loginStatus.status === OperationStatus.processing) {
            return;
        }
        const errors = validateCredentials({ email, password });
        if (some(values(errors))) {
            return setErrors(errors);
        }
        localStorage.setItem('redirectAfterLogin', returnTo);
        dispatch(actions.login(email, password));
    };

    const handleGoogleLoginClicked = (response) => {
        if (user.role !== UserRole.Anonymous) {
            return;
        }
        googleLogin();
    }

    const onFacebookLoginResponse = (response) => {
        if (user.role !== UserRole.Anonymous) {
            return;
        }

        let avatarUrl;
        if (response.picture?.data?.url && response.picture?.data?.is_silhouette !== true) {
            avatarUrl = response.picture.data.url;
        }

        console.log('Facebook Login Response:', response);
        const { email, accessToken, userID } = response;
        dispatch(actions.loginFacebook(email, accessToken, userID, avatarUrl));
    };

    const { linkedInLogin } = useLinkedIn({
        clientId: '77qfocff6iqi3d',
        redirectUri: `${window.location.origin}/linkedin`,
        onSuccess: (code) => {
            console.log(code);
        },
        onError: (error) => {
            console.log(error);
        },
    });

    useEffect(function doAfterLogin() {
        if (loginStatus.status === OperationStatus.success)
            history.push(returnTo);
    }, [loginStatus]);

    useEffect(clearErrors, [email, password]);

    useEffect(() => {
        const mainEl = document.getElementsByTagName('main')[0];
        mainEl.classList.add('full-screen');
        return () => {
            mainEl.classList.remove('full-screen');
        };
    }, []);

    useEffect(() => {
        if (loginStatus.errorMessage) {
            setErrors({ email: '', password: loginStatus.errorMessage });
        }
    }, [loginStatus]);

    useEffect(() => {
        (async () => {
            if (googleResponse) {
                try {
                    const response = await fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleResponse.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${googleResponse.access_token}`,
                            Accept: 'application/json'
                        }
                    });
                    const userData = await response.json();

                    const { email, id, picture } = userData;
                    console.log('Google Login Response:', userData);
                    dispatch(actions.loginGoogle(email, googleResponse.access_token, id, picture));

                } catch (error) {
                    console.error('Error fetching user data from Google:', error);
                }
            }
        })();
    }, [googleResponse]);

    if (loginStatus.status === OperationStatus.processing) {
        return (
            <Dimmer active={loginStatus.status === OperationStatus.processing} page>
                <Loader></Loader>
            </Dimmer>
        );
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <AvatarBackground
                    mode="blue"
                    fullScreen
                    hideMiddleAvatars
                    className={classes.avatarBackground}
                    hideAvatars={isMobile || isMobileTablet}
                >
                    <Container>
                        <Hero
                            mode="light"
                            maxWidth="sm"
                            title="Welcome Back"
                            className={classes.loginBox}
                            description="See, hear and talk to your customers remotely, as they engage with your products, apps and messaging"
                        >
                            <Container maxWidth="xs" className={classes.lgTopSpace}>

                                {/* <Modal
                                disableScrollLock
                                open={open}
                                onClose={handleClose}
                            >
                                <Box sx={popUpStyle}>
                                    <Typography align='center' variant="h4" component="h1">
                                        Coming Soon...
                                    </Typography>                              
                                </Box>
                            </Modal> */}

                                {/* <div className={classes.smBottomSpace}>
                                    <Button
                                        fullWidth
                                        registration
                                        inverted
                                        onClick={linkedInLogin}
                                    >
                                        <Box display="flex" justifyContent="center" alignItems="center">
                                            <GoogleIcon />
                                            <Typography variant="body1" className={classes.registrationButtonText}>
                                                Continue with LinkedIn
                                            </Typography>
                                        </Box>
                                    </Button>
                                </div> */}

                                <div className={classes.smBottomSpace}>
                                    <Button
                                        fullWidth
                                        registration
                                        inverted
                                        onClick={handleGoogleLoginClicked}
                                    >
                                        <Box display="flex" justifyContent="center" alignItems="center">
                                            <GoogleIcon />
                                            <Typography variant="body1" className={classes.registrationButtonText}>
                                                Continue with Google
                                            </Typography>
                                        </Box>
                                    </Button>
                                </div>

                                <div className={classes.smBottomSpace}>
                                    {/*                                     <FacebookLogin
                                        autoLoad={false}
                                        disableMobileRedirect
                                        appId={config.facebookAppId}
                                        scope="public_profile, email"
                                        fields="name, email, picture"
                                        isMobile={isMobile || isMobile}
                                        callback={onFacebookLoginResponse}
                                        render={(renderProps) => ( */}
                                    <Link to="/reset-fb-password-req">
                                        <Button
                                            fullWidth
                                            registration
                                            inverted
                                        >

                                            <Box display="flex" justifyContent="center" alignItems="center">
                                                <FacebookIcon />
                                                <Typography variant="body1" className={classes.registrationButtonText}>
                                                    Continue with Facebook
                                                </Typography>
                                            </Box>
                                        </Button>
                                    </Link>
                                    {/* )}
                                    /> */}
                                </div>

                                <Typography align="center" className={classes.smBottomSpace}>OR</Typography>

                                <form noValidate autoComplete="off">
                                    <div className={classes.smBottomSpace}>
                                        <TextField
                                            required
                                            fullWidth
                                            type="email"
                                            aria-required
                                            value={email}
                                            variant="outlined"
                                            placeholder="Email"
                                            error={!!errors.email}
                                            helperText={errors.email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>

                                    <div>
                                        <TextField
                                            required
                                            fullWidth
                                            aria-required
                                            value={password}
                                            variant="outlined"
                                            placeholder="Password"
                                            error={!!errors.password}
                                            helperText={errors.password}
                                            type={showPassword ? 'text' : 'password'}
                                            onChange={(e) => setPassword(e.target.value)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            disabled={!password}
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowPassword(!showPassword)}
                                                            size="large">
                                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>

                                    <Typography
                                        variant="body2"
                                        align="center"
                                        style={{ fontSize: 12 }}
                                        className={classNames(classes.smTopSpace, classes.smBottomSpace)}
                                    >
                                        Forgot your password? No worry and
                                        <Link to="/reset-password-req" className={classNames(classes.link, 'link-info')}>
                                            reset your pasword
                                        </Link>
                                    </Typography>

                                    <Button
                                        fullWidth
                                        onClick={login}
                                        disabled={false/*loginStatus.status === OperationStatus.processing*/}
                                    >
                                        Let me in
                                    </Button>

                                    <Typography
                                        align="center"
                                        variant="body2"
                                        className={classNames(classes.mdTopSpace, classes.accountText)}
                                    >
                                        Don't have an account?
                                        <Link to="/signup" className={classNames(classes.link, 'link-info')}>
                                            Signup here
                                        </Link>
                                    </Typography>
                                </form>
                            </Container>
                        </Hero>
                    </Container>
                </AvatarBackground>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default LoginPage;
