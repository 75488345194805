import { UserSource } from '../../types/UserSource';

export default [
  {
    key: UserSource.Facebook,
    value: UserSource.Facebook,
    text: 'Facebook',
  },
  {
    key: UserSource.Twitter,
    value: UserSource.Twitter,
    text: 'Twitter',
  },
  {
    key: UserSource.LinkedIn,
    value: UserSource.LinkedIn,
    text: 'LinkedIn',
  },
  {
    key: UserSource.Google,
    value: UserSource.Google,
    text: 'Google',
  },
  {
    key: UserSource.Friends,
    value: UserSource.Friends,
    text: 'Friends',
  },
  {
    key: UserSource.Other,
    value: UserSource.Other,
    text: 'Other',
  },
];
