import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import shieldImg from '../public/images/shield.png';
import actions from '../redux/actions';

const mapDispatchToProps = (dispatch) => {
  return {
    registerModalOpen: () => dispatch(actions.registerModalOpen()),
  };
};

const BecomeATester = (props) => {
  return (
    <div className="becomeatester page">
      <div className="heading">
        <h1>Become A Tester</h1>
      </div>
      <div className="inner">
        <div className="makepay">
          <div>
            <h2>Make Your Testing Talent Pay</h2>
            <p>
              If you share our passion about websites, mobile applications and games, Testpoint
              invites you to join our global family of quality assurance testers, and start making
              money off it!{' '}
            </p>
          </div>
          <Button className="becomeatester-button" onClick={props.registerModalOpen}>
            Become a tester
          </Button>
        </div>
      </div>

      <div className="howitworks">
        <h3>So how does it work?</h3>

        <div className="steps">
          <div className="step step1" data-num="1">
            <div className="step-text">
              <div className="step-title">Register as a tester</div>
              <div className="step-description">
                Register as a tester, complete your profile and activate your account
              </div>
            </div>
          </div>
          <div className="step step2" data-num="2">
            <div className="step-text">
              <div className="step-title">Complete a quick evaluation test job</div>
              <div className="step-description">
                This 30min short evaluation test job will be reviewed by the Testpoint team to
                determine your initial Testpoint rating
              </div>
            </div>
          </div>
          <div className="step step3" data-num="3">
            <div className="step-text">
              <div className="step-title">Start getting paid</div>
              <div className="step-description">
                Paid test jobs which matches your profile and skills will be sent to your mailbox.
                Accept jobs and start getting paid
              </div>
            </div>
          </div>
          <div className="step step4" data-num="4">
            <div className="step-text">
              <div className="step-title">100% confidential</div>
              <div className="step-description">
                Confidentiality is of our highest importance. As a Testpoint tester, you agree not to
                share ANY information about projects you are testing. We promise to do the same!
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="getpaid grey">
        <div className="inner">
          <h3>How do I get paid?</h3>
          <p> When setting up your profile, you name your hourly rate.</p>
          <p>
            Following the completion of a project, your Testpoint account will be credited with this
            amount: Your credit = (Your hourly bid X hours worked) - financial transfer commissions
          </p>
          <p>You may withdraw your available credit any time you want, and go wild with it!</p>

          <h3>Your Success Is In Your Hands</h3>
          <p>
            Competitive rates and higher rating means higher chances of getting more jobs. Some of
            our customers may even add you to their favorite testers list, which means you’ll have
            priority for getting jobs from these customers.
          </p>
        </div>
      </div>

      <div className="becomeatester-bottom blue">
        <Button className="becomeatester-button large" onClick={props.registerModalOpen}>
          Become a tester
        </Button>
      </div>

      <div className="privacy inner">
        <img src={shieldImg} alt="shield-icon" />
        <p>
          All Testpoint testers are fully committed to maintaining complete confidentiality on each
          and every project in which they participate
        </p>
      </div>
    </div>
  );
};

export default withRouter(connect(null, mapDispatchToProps)(BecomeATester));
