import { Chip, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import wizardActions from '../../../redux/actions/wizardActions';
import { NewWizardCycle } from '../../../types/NewWizardCycle';

import { baseObject, PlatformBaseObject } from '../../../types/NewPlatformModel';

import DoneIcon from '@mui/icons-material/Done';
import { PayoutRatesAndTestersQuota, SpecificationType } from '../../../types/WizardCategoriesTypes';
import { titleBlue } from './SharedStyle';

import * as _ from 'lodash';
import DevicesFormSlim from './DevicesFormSlim';

const styles = (theme) => createStyles({
    internalBox: {
        marginTop: '15px',
        marginLeft: '-8px',
        display: 'flex',
        flexWrap: 'wrap',
        width: "80%",
        '& > *': {
            marginLeft: '8px',
            marginBottom: '8px',
        },
    },

    defaultChip: {
        '& .MuiChip-root': {
            height: "40px",
        },
        '& .MuiChip-label': {
            paddingLeft: '23px',
            paddingRight: "22px",
            fontWeight: "bolder",
            color: "#585D72",
        },
    },

    listHeader: {
        fontSize: "12px",
        fontWeight: "bold",
        color: titleBlue,
    },

    SelectText: {
        color: titleBlue,
    },

    MenuITemText: {
        color: titleBlue,
    },

    MenuItemTextDefault: {
        fontStyle: 'italic',
        color: titleBlue,
    },

});

const MenuProps = {
    disableScrollLock: true,
};


const mapStateToProps = (store) => {
    return {
        newCycle: store.newWizardCycle as NewWizardCycle,
        systemPlatforms: store.systemPlatforms,
        minOSVersion: store.newWizardCycle.mainTestPlatform.minOSVersion,
        maxOSVersion: store.newWizardCycle.mainTestPlatform.maxOSVersion,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        updateCycle: (data) => dispatch(wizardActions.updateCycle(data)),
    };
};

class PlatformAndTestersForm extends Component<any> {

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.newCycle.mainTestPlatform, this.props.newCycle.mainTestPlatform)
            || !_.isEqual(prevProps.newCycle.relevantBrowsers, this.props.newCycle.relevantBrowsers)) {

            const newCycle: NewWizardCycle = { ...this.props.newCycle };
            newCycle.relevantDevices = [];
            newCycle.isSpecificDevices = false;

            this.props.updateCycle(newCycle);

        }
    }

    //Set default Platform and browser version from index zero
    componentDidMount() {
        const newCycle: NewWizardCycle = { ...this.props.newCycle };

        //If was set already 
        if (newCycle.relevantBrowsers.length > 0) {
            return;
        }
        const firstPlatform: baseObject = (({ id, name }) => ({ id, name }))(this.props.systemPlatforms[0]);
        const firstPlatformFull: PlatformBaseObject = { id: firstPlatform.id, name: firstPlatform.name, minOSVersion: { id: -1, name: '' }, maxOSVersion: { id: -1, name: '' } };

        //Take first platform as default
        newCycle.mainTestPlatform = firstPlatformFull;

        /* const firsBrowser: baseObject = (({ id, name }) => ({ id, name }))(this.props.systemPlatforms[0].browsers[0]);
        const firsBrowserFull: BrowserBaseObject = { id: firsBrowser.id, name: firsBrowser.name, minBrowserVersion: -1, maxBrowserVersion: -1 }

        //For the default Platform pick the first browser as default
        let updatedBrowsers = [firsBrowserFull, ...newCycle.relevantBrowsers]; */


        //Another strategy - for default - take all or nothing, and we mark 'All' by id = -1
        newCycle.relevantBrowsers = newCycle.testSpecification === SpecificationType.NativeApp ? [] : [{ id: -1, name: 'Any', minBrowserVersion: -1, maxBrowserVersion: -1 }];

        this.props.updateCycle(newCycle);
    }



    handlePlatformItemSelected = (index) => {
        const newCycle: NewWizardCycle = { ...this.props.newCycle };
        const selectedPlatform = this.props.systemPlatforms[index];

        if (selectedPlatform.id !== newCycle.mainTestPlatform.id) {
            newCycle.mainTestPlatform = { id: selectedPlatform.id, name: selectedPlatform.name, minOSVersion: { id: -1, name: '' }, maxOSVersion: { id: -1, name: '' } } //Setting it as the unique default Platform  
            /* newCycle.relevantBrowsers = [{ id: selectedPlatform.browsers[0].id, name: selectedPlatform.browsers[0].name, minBrowserVersion: -1, maxBrowserVersion: -1 }]; // Selecting its first Browser in the list as Default */

            this.props.updateCycle(newCycle);
        }
    }


    render() {
        const { classes, newCycle } = this.props;

        return (
            <React.Fragment>

                {this.props.newCycle.mainTestPlatform.id !== -1 &&
                    /* WE ARE DRAWING ONLY IF WE HAVE DATA SET AS DEFAULT*/
                    <React.Fragment>

                        <Typography variant="h2">
                            Select platform to test on
                        </Typography>
                        <div className={classes.internalBox}>
                            {this.props.systemPlatforms.map((platform, index) => (
                                <Chip
                                    label={platform.name}
                                    style={{ cursor: "pointer", height: "36px" }}
                                    className={newCycle.mainTestPlatform.id === platform.id ? "" : classes.defaultChip}
                                    color={newCycle.mainTestPlatform.id === platform.id ? "primary" : "secondary"}
                                    icon={newCycle.mainTestPlatform.id === platform.id ? <DoneIcon /> : <></>}
                                    variant={newCycle.mainTestPlatform.id === platform.id ? "filled" : "outlined"}
                                    key={platform.id}
                                    onClick={() => this.handlePlatformItemSelected(index)}
                                />
                            ))}
                        </div>

                        <Typography variant="h2" style={{ marginTop: "55px" }}>
                            Number of Testers
                        </Typography>
                        <Typography align="left" variant="h4" style={{ marginTop: "10px" }} >
                            Min. {PayoutRatesAndTestersQuota.minTestersPerCycle} testers per test cycle, Min. {PayoutRatesAndTestersQuota.minTestersPerSpecification} per specific country
                        </Typography>

                    </React.Fragment>
                }

                <DevicesFormSlim></DevicesFormSlim>
            </React.Fragment>

        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PlatformAndTestersForm)));