import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Table, Button } from 'semantic-ui-react';
import StarRatingComponent from 'react-star-rating-component';
import Sidemenu from './Sidemenu';
import actions from '../../../redux/actions';
import { UserRole } from '../../../types/UserRole';

const mapStateToProps = (state) => {
  return {
    devices: state.devices,
    browsers: state.browsers,
    favoriteTesters: state.favoriteTesters,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTesterToFavorites: (testerId) => dispatch(actions.addTesterToFavorites(testerId)),
    removeTesterFromFavorites: (testerId) => dispatch(actions.removeTesterFromFavorites(testerId)),
  };
};

const Review = (props) => {
  return (
    <div className="review">
      <img className="avatar" src={props.avatarUrl} alt="" height="64" width="64" />
      <div style={{ flexDirection: 'column' }}>
        <p className="name">
          {props.ownderCompanyName} <span>said:</span>
        </p>
        <p className="review-text">{props.text}</p>
        <p className="project-details">
          <span className="project-name">{props.cycleTitle}</span>
          <span className="review-date">
            {' '}
            Posted at {moment(props.createdAt).format('DD-MM-YYYY HH:MM')}
          </span>
        </p>
      </div>
    </div>
  );
};

class FavoriteTesterDetails extends Component<any> {
  public state: any;

  constructor(props) {
    super(props);

    this.state = {
      testerId: +props.match.params.testerId,
    };
  }

  onToggleFavorite = (event, data) => {
    const testerId = this.state.testerId;

    if (this.props.favoriteTesters.byId[testerId]) {
      this.props.removeTesterFromFavorites(testerId);
      this.props.history.push('/account/customer/favorite');
    } else {
      this.props.addTesterToFavorites(testerId);
    }
  };

  render() {
    const tester = this.props.favoriteTesters.byId[this.state.testerId];

    if (!tester) {
      return null;
    }

    const devices = tester.devices.map((testerDevice) => {
      const device = this.props.devices.models.find((device) => device.id === testerDevice.modelId);
      return device.name || `Unknown device ${testerDevice.id}`;
    });

    const browsers = tester.browsers.map((testerBrowser) => {
      const name =
        this.props.browsers.names.find((el) => el.id === testerBrowser.browserId)?.name ||
        'Unknown browser';
      const version =
        this.props.browsers.versions.find((el) => el.id === testerBrowser.browserVersionId)
          ?.version || '0.0';

      return `${name} ${version}`;
    });

    const testerIsFavorite = this.state.testerId in this.props.favoriteTesters.byId;

    return (
      <div className="account-customer page">
        <div className="heading">
          <h1>Tester Profile</h1>
        </div>

        <div className="inner">
          <Sidemenu />
          <main className="favorite-tester-details">
            <h2>Tester Profile</h2>

            <div className="profile">
              <img
                className="avatar"
                src={tester.avatarUrl}
                alt="tester's avatar"
                height={64}
                width={64}
              />

              <p>
                {tester.firstName}
                <br />
                {tester.lastName}
              </p>

              <div className="rating">
                <StarRatingComponent
                  name="rate1"
                  starCount={5}
                  value={parseInt(tester.rating, 10)}
                />
                <span>{parseFloat(tester.rating).toFixed(2)}</span>
              </div>

              <p>{tester.about}</p>

              <Button
                primary
                size="tiny"
                content={testerIsFavorite ? 'Remove from favorites' : 'Add to favorites'}
                disabled={this.props.user.role === UserRole.Viewer}
                onClick={this.onToggleFavorite}
              />
            </div>

            <h3>General inforation</h3>
            <Table compact size="small" definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Country</Table.Cell>
                  <Table.Cell>{tester.country}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Age</Table.Cell>
                  <Table.Cell>{tester.age}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Gender</Table.Cell>
                  <Table.Cell>{tester.gender}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Devices</Table.Cell>
                  <Table.Cell>
                    <ul>
                      {devices.map((el, i) => (
                        <li key={i}>{el}</li>
                      ))}
                    </ul>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Browsers</Table.Cell>
                  <Table.Cell>
                    <ul>
                      {browsers.map((el, i) => (
                        <li key={i}>{el}</li>
                      ))}
                    </ul>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>

            <h3>Test skills</h3>
            <p>Participated in cycles: </p>
            <Table size="small" compact definition>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Cycle type</Table.HeaderCell>
                  <Table.HeaderCell>Number of cycles</Table.HeaderCell>
                  <Table.HeaderCell>Reported bugs</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Find bugs</Table.Cell>
                  <Table.Cell>0</Table.Cell>
                  <Table.Cell>0</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Feedback &amp; star rating</Table.Cell>
                  <Table.Cell>1</Table.Cell>
                  <Table.Cell>5</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>

            <h3>Reviews</h3>

            {tester.customerReviews.map((el, i) => (
              <Review
                key={i}
                avatarUrl={el.reviewerAvatarUrl}
                ownderCompanyName={el.ownerCompanyName}
                text={el.text}
                createdAt={el.createdAt}
              />
            ))}
          </main>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FavoriteTesterDetails));
