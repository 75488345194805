import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import CycleDetailsSidemenu from './Sidemenu';

const mapStateToProps = ({ user, cycles }) => ({ user, cycles });

class Questions extends Component<any> {
  public state: any;

  constructor(props) {
    super(props);

    this.state = {
      cycleId: +props.match.params.id,
      cycle: {},
    };
  }

  componentDidMount() {
    const cycle = this.props.cycles.find((el) => el.id === this.state.cycleId);
    this.setState({ cycle });
  }

  render() {
    if (!this.state.cycle || !Object.keys(this.state.cycle).length) {
      return null;
    }

    return (
      <div className="account-customer page">
        <div className="heading">
          <h1>Cycle Messages</h1>
        </div>

        <div className="inner" style={{ display: 'flex' }}>
          <CycleDetailsSidemenu
            cycleId={this.state.cycle.id}
            workCategory={this.state.cycle.workCategory}
            companyName={this.props.user.companyName}
            avatarUrl={this.props.user.avatarUrl}
          />
          <main className="cycle-questions">
            <h2>Cycle Questions</h2>
            <Table striped compact className="cycles-table">
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Question 1</Table.Cell>
                  <Table.Cell>Did you find it easy to use and quick to navigate?</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Question 2</Table.Cell>
                  <Table.Cell>
                    Would you recommend it to others? (Please give your reasons)
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Question 3</Table.Cell>
                  <Table.Cell>Would you use it yourself ? (Please give your reasons)</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Question 4</Table.Cell>
                  <Table.Cell>
                    What was the most frustrating thing about your experience?
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Question 5</Table.Cell>
                  <Table.Cell>
                    What other ideas do you have about how it could be improved?
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </main>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(Questions));
