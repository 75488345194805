import React from 'react';

const PageNotFound = () => {
  return (
    <div className="terms page">
      <div className="heading">
        <h1>Page Not Found</h1>
      </div>
      <div className="inner" style={{ fontSize: '4rem', padding: '12rem 2rem' }}>
        Error 404 - page not found :(
      </div>
    </div>
  );
};

export default PageNotFound;
