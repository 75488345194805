import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../redux/actions';
import { Modal, Message, Icon, Form, Input, Button } from 'semantic-ui-react';
import { OperationStatus } from '../types/OperationStatus';

const mapStateToProps = (store) => {
  return {
    modalOpen: store.changePasswordModal.open,
    passwordChangeStatus: store.passwordChangeStatus.status,
    passwordChangeErrorMessage: store.passwordChangeStatus.errorMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    open: () => dispatch(actions.changePasswordModalOpen()),
    close: () => dispatch(actions.changePasswordModalClose()),
    changePassword: (currentPassword, newPassword) =>
      dispatch(actions.changePassword(currentPassword, newPassword)),
  };
};

class PasswordChangeModal extends Component<any> {
  public state: any;

  constructor(props) {
    super(props);

    this.state = {
      currentPassword: '',
      newPassword: '',
      newPasswordAgain: '',
      errors: new Set(),
      errorMessage: '',
      currentPasswordVisible: false,
      newPasswordVisible: false,
      newPasswordAgainVisible: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.passwordChangeStatus !== prevProps.passwordChangeStatus) {
      if (this.props.passwordChangeStatus === OperationStatus.success) {
        this.setState(
          {
            currentPassword: '',
            newPassword: '',
            newPasswordAgain: '',
            errors: new Set(),
            errorMessage: '',
            currentPasswordVisible: false,
            newPasswordVisible: false,
            newPasswordAgainVisible: false,
          },
          () => {
            this.props.close();
          }
        );
      } else {
        this.setState({
          errorMessage: this.props.passwordChangeErrorMessage,
        });
      }
    }
  }

  onClose = () => {
    this.setState(
      {
        currentPassword: '',
        newPassword: '',
        newPasswordAgain: '',
        errors: new Set(),
        errorMessage: '',
        currentPasswordVisible: false,
        newPasswordVisible: false,
        newPasswordAgainVisible: false,
      },
      () => {
        this.props.close();
      }
    );
  };

  onInput = (event, data) => {
    this.setState({
      ...this.state,
      [data.name]: data.value,
    });
  };

  validateInput = (event, data) => {
    const errors = this.state.errors;
    const name = data && data.name ? data.name : event.target.name;
    const value = data && data.value ? data.value : event.target.value;

    if (name === 'currentPassword') {
      if (!value.length) {
        errors.add(name);
      } else {
        errors.delete(name);
      }
    }

    if (name === 'newPassword') {
      if (!value.length) {
        errors.add(name);
      } else {
        errors.delete(name);
      }
    }

    if (name === 'newPasswordAgain') {
      if (!value.length) {
        errors.add(name);
      } else {
        errors.delete(name);
      }
    }

    this.setState({ errors });
  };

  onSubmit = async (event) => {
    event.preventDefault();
    const errors = this.state.errors;

    if (this.state.newPassword.length < 8) {
      errors.add('newPassword');

      return this.setState({
        errorMessage: 'Password need to be at least 8 characters',
        errors,
      });
    } else {
      errors.delete('newPassword');
      this.setState({
        errorMessage: '',
        errors,
      });
    }

    if (this.state.newPassword !== this.state.newPasswordAgain) {
      errors.add('newPasswordAgain');

      return this.setState({
        errorMessage: "Passwords don't match",
        errors,
      });
    } else {
      errors.delete('newPasswordAgain');
      this.setState({
        errorMessage: '',
        errors,
      });
    }

    await this.props.changePassword(this.state.currentPassword, this.state.newPassword);
  };

  onPasswordVisibleToggle = (event, data) => {
    event.preventDefault();

    if (data['data-type'] === 'currentPassword') {
      this.setState({ currentPasswordVisible: !this.state.currentPasswordVisible });
    }

    if (data['data-type'] === 'newPassword') {
      this.setState({ newPasswordVisible: !this.state.newPasswordVisible });
    }

    if (data['data-type'] === 'newPasswordAgain') {
      this.setState({ newPasswordAgainVisible: !this.state.newPasswordAgainVisible });
    }
  };

  render() {
    const errors = this.state.errors;

    return (
      <Modal
        open={this.props.modalOpen}
        onClose={this.onClose}
        className="change-password-modal"
        trigger={<Button onClick={this.props.open}>Change password</Button>}
        size="tiny"
        closeIcon
      >
        <Modal.Header>Change password</Modal.Header>
        <Modal.Content>
          {this.state.errorMessage && (
            <Message negative>
              <Icon name="exclamation circle" />
              {this.state.errorMessage}
            </Message>
          )}

          <Form>
            <Form.Field>
              <Input
                icon="lock"
                iconPosition="left"
                type={this.state.currentPasswordVisible ? 'text' : 'password'}
                placeholder="Current password"
                name="currentPassword"
                onBlur={this.validateInput}
                onChange={this.onInput}
                error={errors.has('currentPassword')}
                required
                action={
                  <Button
                    onClick={this.onPasswordVisibleToggle}
                    data-type="currentPassword"
                    type="button"
                    tabIndex="-1"
                    icon
                  >
                    <Icon name={this.state.currentPasswordVisible ? 'eye slash' : 'eye'} />
                  </Button>
                }
              />
            </Form.Field>
            <Form.Field>
              <Input
                icon="lock"
                iconPosition="left"
                type={this.state.newPasswordVisible ? 'text' : 'password'}
                placeholder="New password"
                name="newPassword"
                onBlur={this.validateInput}
                error={errors.has('newPassword')}
                onChange={this.onInput}
                required
                action={
                  <Button
                    onClick={this.onPasswordVisibleToggle}
                    data-type="newPassword"
                    type="button"
                    tabIndex="-1"
                    icon
                  >
                    <Icon name={this.state.newPasswordVisible ? 'eye slash' : 'eye'} />
                  </Button>
                }
              />
            </Form.Field>
            <Form.Field>
              <Input
                icon="lock"
                iconPosition="left"
                type={this.state.newPasswordAgainVisible ? 'text' : 'password'}
                placeholder="Confirm password"
                name="newPasswordAgain"
                onBlur={this.validateInput}
                error={errors.has('newPasswordAgain')}
                onChange={this.onInput}
                required
                action={
                  <Button
                    onClick={this.onPasswordVisibleToggle}
                    data-type="newPasswordAgain"
                    type="button"
                    tabIndex="-1"
                    icon
                  >
                    <Icon name={this.state.newPasswordAgainVisible ? 'eye slash' : 'eye'} />
                  </Button>
                }
              />
            </Form.Field>
            <Button
              icon
              primary
              type="submit"
              disabled={
                errors.size ||
                this.props.passwordChangeStatus === OperationStatus.processing ||
                this.state.newPassword === '' ||
                this.state.newPasswordAgain === '' ||
                this.state.currentPassword === ''
              }
              loading={this.props.passwordChangeStatus === OperationStatus.processing}
              onClick={this.onSubmit}
            >
              Change password
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordChangeModal);
