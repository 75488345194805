import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Switch } from 'react-router';
import { BrowserRouter, Route, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Dimmer, Loader } from 'semantic-ui-react';
import PrivateRoute from './util/PrivateRoute';

import LoginPage from './pages/LoginPage';
import { ResetFBPasswordPage, ResetPasswordPage, ResetPasswordResponse } from './pages/ResetPasswordPage';
import SignupPage from './pages/SignupPage';
import { UnsubscribePage } from './pages/Unsubscribe';

import CustomerAccountPage from './pages/account/customer/CustomerAccountPage';

import Header from './components/Header';
import RegisterApproved from './components/RegisterApproved';
import RegisterConfirm from './components/RegisterConfirm';
import ResetPassword from './components/ResetPassword';
import SampleReports from './components/SampleReports';

import CycleCreationResult from './components/Cycles/CycleCreationResult';
import Cycles from './components/Cycles/Cycles';

import NewCycleWizard from './components/Wizard/NewCycleWizard';

import Impersonate from './components/Impersonate';
import RedirectLogin from './components/RedirectLogin';

import About from './components/About';
import Article from './components/Article';
import Articles from './components/Articles';
import BecomeATester from './components/BecomeATester';
import Contact from './components/Contact';
import PageNotFound from './components/PageNotFound';

import Account from './components/Account';
import CompleteProfile from './components/CompleteProfile';
import EmailPending from './components/EmailPending';

import AccountTester from './components/Account/Tester/AccountTester';
import AccountTesterProfile from './components/Account/Tester/MyProfile/MyProfile';

import AccountTesterCycleApply from './components/Account/Tester/Cycle/Apply';
import AccountTesterCycleBugs from './components/Account/Tester/Cycle/Bugs';
import AccountTesterCycleDetails from './components/Account/Tester/Cycle/Details';
import AccountTesterCycleEditBug from './components/Account/Tester/Cycle/EditBug';
import AccountTesterCycleMessages from './components/Account/Tester/Cycle/Messages';
import AccountTesterCycleNewBug from './components/Account/Tester/Cycle/NewBug';
import AccountTesterCycleTestPlan from './components/Account/Tester/Cycle/TesterTestPlan';

import AccountTesterReviews from './components/Account/Tester/CustomerReviews';
import AccountTesterHDIW from './components/Account/Tester/HowDoesItWork';

import AccountCustomerCycleTestPlan from './components/Account/Customer/Cycle/CustomerTestPlan';
import AccountCustomerCycleDetails from './components/Account/Customer/Cycle/Details';
import AccountCustomerCycleQuestions from './components/Account/Customer/Cycle/Questions';
import AccountCustomerCycleTesterDetails from './components/Account/Customer/Cycle/TesterDetails';
import AccountCustomerFavoriteDetails from './components/Account/Customer/FavoriteTesterDetails';
import AccountCustomerFavorite from './components/Account/Customer/FavoriteTesters';
import AccountCustomerProfile from './components/Account/Customer/MyProfile/MyProfile';
// import AccountCustomerHDIW from './components/Account/Customer/HowDoesItWork';

import Footer from './components/Footer';

import { GoogleOAuthProvider } from '@react-oauth/google';
import Affiliates from './components/Account/Tester/Affiliates';
import Pricing from './components/Pricing/Pricing';
import config from './config';
import useResolution from './hooks/useResolution';
import CustomerDashboardPage from './pages/account/customer/CustomerDashboardPage';
import actions from './redux/actions';
import { OperationStatus } from './types/OperationStatus';
import { UserRole } from './types/UserRole';

console.log(`TESTPOINT APP V${process.env.REACT_APP_VERSION}`);

// TODO too many imports, split
// TODO check if a JWT token present and valid. if it's expired, delete it
// TODO move routes to a separate file

const mapStateToProps = (state) => ({
  loginStatus: state.loginStatus.status,
  impersonateStatus: state.impersonateStatus.status,
  user: state.user,
  devices: state.devices,
  browsers: state.browsers,
});

const mapDispatchToProps = (dispatch) => ({
  getDevices: () => dispatch(actions.getDevices()),
  getBrowsers: () => dispatch(actions.getBrowsers()),
  checkLogin: () => dispatch(actions.checkLogin()),
});

const AppHooks = ({ children }) => {
  const location = useLocation();
  useEffect(function onLocationChange() {
    window.scrollTo(0, 0);
  }, [location]);
  useResolution();
  return <>{children}</>;
};

class App extends Component<any> {
  componentDidMount() {
    this.props.getDevices();
    this.props.getBrowsers();
    if (window.location.pathname.indexOf("impersonate") == -1) {
      this.props.checkLogin();
    }
  }

  render() {
    const { user } = this.props;
    const loaderActive =
      !this.props.devices.models.length ||
      !this.props.browsers.names.length ||
      this.props.impersonateStatus === OperationStatus.processing;

    const marketSiteURLBase = config.marketingSiteBaseURL;

    return (
      <GoogleOAuthProvider clientId={config.GoogleClientId}>
        <BrowserRouter>
          <div className="app">
            <ToastContainer
              rtl={false}
              pauseOnHover
              closeOnClick
              hideProgressBar
              autoClose={3000}
              draggable={false}
              newestOnTop={false}
              position="top-right"
            />
            <Header />
            <main>
              {loaderActive ? (
                <Dimmer active={loaderActive} page>
                  <Loader></Loader>
                </Dimmer>
              ) : (
                <AppHooks>
                  <Switch>

                    {/* 
              **** Those pages were removed from the app and awitched to the marketing site 
                  {
            */}

                    {/* HomePage directly is either going to the dashboard in case of user is logged-in, or to the login page if not */}
                    <Route exact path="/"/*   component={HomePage} */>
                      {(this.props.impersonateStatus === OperationStatus.success &&
                        user.role !== UserRole.Anonymous) ? <Redirect to="/account" /> :
                        <Redirect to="/login" />}
                    </Route>

                    {/* <Route path="/faqs" component={FAQsPage} /> */}
                    <Route path="/faqs" component={() => { window.location.href = `${marketSiteURLBase}/faqs`; return null; }} />

                    {/* <Route path="/tester" component={TesterPage} /> */}
                    <Route path="/tester" component={() => { window.location.href = `${marketSiteURLBase}/tester`; return null; }} />

                    {/* <Route path="/contact" component={ContactPage} /> */}
                    <Route path="/contact" component={() => { window.location.href = `${marketSiteURLBase}/contact`; return null; }} />

                    {/* <Route path="/contactsales" component={ContactSalesPage} /> */}
                    <Route path="/contactsales" component={() => { window.location.href = `${marketSiteURLBase}/contactsales`; return null; }} />

                    {/* <Route path="/contactsupport" component={ContactSupportPage} /> */}
                    <Route path="/contactsupport" component={() => { window.location.href = `${marketSiteURLBase}/contactsupport`; return null; }} />

                    {/* <Route path="/privacy" component={Privacy} /> */}
                    <Route path="/privacy" component={() => { window.location.href = `${marketSiteURLBase}/privacy`; return null; }} />

                    {/* <Route path="/terms" component={Terms} /> */}
                    <Route path="/terms" component={() => { window.location.href = `${marketSiteURLBase}/terms`; return null; }} />

                    <Route path="/wizard" component={() => { window.location.href = `${marketSiteURLBase}/pricing`; return null; }} />

                    {/* 
                  }
              **** Those pages were removed from the app and awitched to the marketing site 
            */}

                    <Route path="/login" exact component={LoginPage} />
                    <Route path="/signup" exact component={SignupPage} />

                    <Route path="/reset-password-req" exact component={ResetPasswordPage} />
                    <Route path="/reset-fb-password-req" exact component={ResetFBPasswordPage} />
                    <Route path="/reset-password" exact component={ResetPasswordResponse} />
                    <Route path="/unsubscribe" exact component={UnsubscribePage} />

                    <Route path="/registerconfirm" component={RegisterConfirm} />
                    <Route path="/register-approved" component={RegisterApproved} />
                    <Route path="/forgot-password" component={ResetPassword} />
                    <Route path="/impersonate/:token" component={Impersonate} />
                    <Route path="/redirect-login" component={RedirectLogin} />

                    <Route path="/samplereports" component={SampleReports} />
                    <Route path="/becomeatester" component={BecomeATester} />
                    <Route path="/cycles" component={Cycles} />
                    <Route path="/cycle-creation-result" component={CycleCreationResult} />
                    <Route path="/articles/single" component={Article} />
                    <Route path="/articles" exact component={Articles} />
                    <Route path="/contactus" component={Contact} />
                    <Route path="/about" component={About} />

                    {/* <Route exact path="/pricing" >
                    <Redirect to="/pricing/speedtest" />
                  </Route>
                  <Route path="/pricing/:pricingType/:reload?" component={Pricing} />
                  <Route path="/custom/:reload?" component={NewCycleWizard} /> */}

                    <PrivateRoute exact
                      path="/firstcycle"
                      role={user.role}
                      status={user.status}
                      component={Pricing}
                    >
                      <Redirect to="/pricing/firstcycle" />
                    </PrivateRoute>

                    <PrivateRoute exact
                      path="/custom"
                      role={user.role}
                      status={user.status}
                      component={NewCycleWizard} />

                    <PrivateRoute exact
                      path="/pricing"
                      role={user.role}
                      status={user.status}
                    >
                      <Redirect to="/pricing/speedtest" />
                    </PrivateRoute>

                    <PrivateRoute
                      exact
                      path="/pricing/:pricingType"
                      role={user.role}
                      component={Pricing}
                      status={user.status}
                    />

                    <PrivateRoute
                      exact
                      path="/account"
                      role={user.role}
                      component={Account}
                      status={user.status}
                    />
                    <PrivateRoute
                      exact
                      role={user.role}
                      status={user.status}
                      path="/account/customer"
                      component={CustomerAccountPage}
                    />
                    <PrivateRoute
                      role={user.role}
                      status={user.status}
                      component={CustomerDashboardPage}
                      path="/account/customer/cycle/:cycleId"
                    />

                    <PrivateRoute
                      role={user.role}
                      status={user.status}
                      path="/email-pending"
                      component={EmailPending}
                    />
                    <PrivateRoute
                      role={user.role}
                      status={user.status}
                      path="/complete-profile/:step"
                      component={CompleteProfile}
                    />
                    <PrivateRoute
                      exact
                      role={user.role}
                      status={user.status}
                      path="/account/tester"
                      component={AccountTester}
                    />
                    <PrivateRoute
                      role={user.role}
                      status={user.status}
                      component={AccountTesterProfile}
                      path="/account/tester/myprofile/:tab?"
                    />
                    <PrivateRoute
                      role={user.role}
                      status={user.status}
                      path="/account/tester/reviews"
                      component={AccountTesterReviews}
                    />
                    <PrivateRoute
                      role={user.role}
                      status={user.status}
                      component={AccountTesterHDIW}
                      path="/account/tester/howdoesitwork"
                    />
                    <PrivateRoute
                      role={user.role}
                      status={user.status}
                      component={Affiliates}
                      path="/account/tester/affiliates"
                    />
                    <PrivateRoute
                      exact
                      role={user.role}
                      status={user.status}
                      path="/account/tester/cycle/:id"
                      component={AccountTesterCycleDetails}
                    />
                    <PrivateRoute
                      role={user.role}
                      status={user.status}
                      component={AccountTesterCycleApply}
                      path="/account/tester/cycle/:id/apply"
                    />
                    <PrivateRoute
                      role={user.role}
                      status={user.status}
                      component={AccountTesterCycleTestPlan}
                      path="/account/tester/cycle/:id/testplan"
                    />
                    <PrivateRoute
                      role={user.role}
                      status={user.status}
                      component={AccountTesterCycleNewBug}
                      path="/account/tester/cycle/:id/newbug"
                    />
                    <PrivateRoute
                      exact
                      role={user.role}
                      status={user.status}
                      component={AccountTesterCycleBugs}
                      path="/account/tester/cycle/:id/bugs"
                    />
                    <PrivateRoute
                      role={user.role}
                      status={user.status}
                      component={AccountTesterCycleEditBug}
                      path="/account/tester/cycle/:cycleId/bugs/:bugId/edit"
                    />
                    <PrivateRoute
                      role={user.role}
                      status={user.status}
                      component={AccountTesterCycleMessages}
                      path="/account/tester/cycle/:id/messages"
                    />

                    <PrivateRoute
                      role={user.role}
                      status={user.status}
                      path="/account/customer/myprofile"
                      component={AccountCustomerProfile}
                    />
                    <PrivateRoute
                      exact
                      role={user.role}
                      status={user.status}
                      path="/account/customer/favorite"
                      component={AccountCustomerFavorite}
                    />
                    <PrivateRoute
                      role={user.role}
                      status={user.status}
                      component={AccountCustomerFavoriteDetails}
                      path="/account/customer/favorite/:testerId"
                    />
                    <PrivateRoute
                      exact
                      role={user.role}
                      status={user.status}
                      path="/account/customer/cycle/:id"
                      component={AccountCustomerCycleDetails}
                    />
                    <PrivateRoute
                      role={user.role}
                      status={user.status}
                      component={AccountCustomerCycleTestPlan}
                      path="/account/customer/cycle/:id/testplan"
                    />
                    <PrivateRoute
                      role={user.role}
                      status={user.status}
                      path="/account/customer/cycle/:id/questions"
                      component={AccountCustomerCycleQuestions}
                    />
                    <PrivateRoute
                      role={user.role}
                      status={user.status}
                      component={AccountCustomerCycleTesterDetails}
                      path="/account/customer/cycle/:id/testers/:testerId"
                    />

                    <Route component={PageNotFound} />
                  </Switch>
                </AppHooks>
              )}
            </main>
            <Footer />
          </div>
        </BrowserRouter>
      </GoogleOAuthProvider >
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
