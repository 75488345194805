import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import Countdown, { zeroPad } from 'react-countdown';
import searchingTestersImg from '../../../public/images/searching-testers.png';
import theme from '../../../themes';

import CropRotateIcon from '@mui/icons-material/CropRotate';
import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router';
import { Route, useParams, useRouteMatch } from 'react-router-dom';
import ServiceInfo from '../../../_components/accounts/customer/ServiceInfo';
import BugsInfo from '../../../_components/accounts/customer/bugs/BugsInfo';
import BugsTable from '../../../_components/accounts/customer/bugs/BugsTable';
import CycleTestInfo from '../../../_components/accounts/customer/cycles/CycleTestInfo';
import Conversation from '../../../_components/accounts/customer/messages/Conversation';
import MessagesSidebar from '../../../_components/accounts/customer/messages/MessagesSidebar';
import TestersList from '../../../_components/accounts/customer/testers/TestersList';
import { TESTERS_SELECTION_DURATION_HOURS } from '../../../constants/accounts/common';
import actions from '../../../redux/actions';
import { RootState } from '../../../redux/store';
import { CycleStatus } from '../../../types/CycleStatus';


const useStyles = makeStyles(() => ({
    children: {
        width: '100%',
        overflowY: 'auto',
        padding: '10px 30px',
        border: `1px solid ${theme.palette.primary.light}`,
    },
    cycleNotOnChildren: {
        width: '100%',
        height: '100%',
    },
    cycleNotOnTitle: {
        padding: 15,
        width: '100%',
        marginBottom: 60,
        borderBottom: `1px solid ${theme.palette.primary.light}`,
    },
    searchingTestersImg: {
        maxWidth: 320,
        marginTop: 60,
    },
    cycleNotOnDescription: {
        maxWidth: 530,
        marginTop: 60,
        lineHeight: 1.5,
    },
}));

const SpecificTabReport: React.FC<{ cycleId: string }> = ({ cycleId }) => {

    const classes = useStyles();

    const { topicId } = useParams<{ topicId: string }>();

    const dispatch = useDispatch();

    const { cycles, chatMessages, resolution } = useSelector((state: RootState) => state);

    const cycle: any = cycles.find((c: any) => c.id === +cycleId);

    const isCycleOn: boolean = [CycleStatus.TestingAndSelection, CycleStatus.Testing, CycleStatus.Completed].includes(cycle?.status);

    const ownerId = cycle?.ownerId;

    const { cycleTesters } = useSelector((state: RootState) => state);

    const testers = cycleTesters?.byCycleId[+cycleId] || [];

    const [activeConversation, setConversation] = useState<number>(0);

    const messages = activeConversation ?
        (chatMessages?.byCycleId[cycleId] ? chatMessages?.byCycleId[cycleId][activeConversation] : [])
        : [];


    const isMobile = resolution.device === 'mobile';
    const isTablet = resolution.device === 'mobile-tablet';

    useEffect(() => {
        if (activeConversation > 0) {
            dispatch(actions.markMessagesAsRead(cycleId, activeConversation));
        }
    }, [activeConversation, messages]);

    const { url } = useRouteMatch();

    if (isCycleOn) {
        if ('report' == topicId) {
            return (
                <>
                    <BugsInfo cycleId={cycleId} />
                    <BugsTable cycleId={cycleId} />
                </>
            );
        }

        if ('testers' == topicId) {
            return (
                <>
                    <ServiceInfo cycleId={cycleId} />
                    <TestersList testers={testers} cycleStatus={cycle.status} />
                </>
            );
        }

        /* Special treatment for messages - show on landscape only*/
        if ('messages' == topicId) {
            if (isMobile) {
                /* Need to show rotate message */
                return (
                    <Box flexDirection="column" justifyContent="center" textAlign="center" style={{ marginTop: 100 }}>
                        <Typography variant="h6">Please rotate the device to view messages</Typography>
                        <CropRotateIcon style={{ marginTop: 30, width: 40, height: 40 }} ></CropRotateIcon>
                    </Box>
                );
            }
            return (
                <Box display="flex">
                    <MessagesSidebar
                        isTablet={isTablet}
                        cycleId={cycleId}
                        participantId={ownerId}
                        setConversation={setConversation}
                        activeConversation={activeConversation}
                    />
                    <Conversation
                        cycleId={cycleId}
                        conversation={activeConversation}
                    />
                </Box>
            );
        }

        if ('test-info' == topicId) {
            return (
                <>
                    <ServiceInfo cycleId={cycleId} />
                    <CycleTestInfo cycle={cycle} />
                </>
            );
        }
    }

    else {
        if ('report' == topicId) {

            const selectionWillExpireIn = moment(cycle?.startDate).add(TESTERS_SELECTION_DURATION_HOURS, 'hour');
            const timeDiff = selectionWillExpireIn.diff(moment());
            const isCountDown = !selectionWillExpireIn.isBefore(moment());

            return (
                <>
                    <div className={classes.cycleNotOnChildren}>
                        <Box display="flex" flexDirection="column" alignItems="center" className={classes.cycleNotOnChildren}>
                            <Typography variant="h6" align="center" className={classes.cycleNotOnTitle}>
                                {cycle?.title}
                            </Typography>
                            <Typography variant="h3" align="center">
                                {timeDiff > 0 &&
                                    <Countdown
                                        date={Date.now() + timeDiff}
                                        renderer={({ hours, minutes, seconds }) => {
                                            return ` Will start in: ${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`;
                                        }}
                                    >
                                        <>Finalizing...</>
                                    </Countdown>
                                }
                                {!(timeDiff > 0) &&
                                    <>About to launch...</>
                                }
                            </Typography>
                            <img
                                alt="Searching Tester"
                                title="Searching Tester"
                                src={searchingTestersImg}
                                className={classes.searchingTestersImg}
                            />
                            <Typography variant="h3" align="center" className={classes.cycleNotOnDescription}>
                                {[CycleStatus.Selection].includes(cycle?.status) && (
                                    <>Searching for the right testers for you...</>
                                )}
                            </Typography>
                        </Box>
                    </div>
                </>
            );
        }

        if ('testers' == topicId) {
            return null;
        }

        if ('messages' == topicId) {
            return null;
        }

        if ('test-info' == topicId) {
            return (
                <div className={classes.children}>
                    <CycleTestInfo cycle={cycle} />
                </div>
            );
        }
    }

    return (
        <Switch>
            <Route>
                <Redirect to={`/pageNotFound`} />
            </Route>
        </Switch>
    )
};
export default SpecificTabReport;