import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Menu } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { SidemenuSummary } from './SidemenuSummary';
import noavatar from '../../../public/images/noavatar.png';
import cycleActions from '../../../redux/actions/cycleActions';

const mapStateToProps = (state) => {
  return {
    companyName: state.user.companyName,
    balance: state.user.balance,
    avatarUrl: state.user.avatarUrl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listCycles: () => dispatch(cycleActions.listCycles()),
  };
};

class Sidemenu extends Component<any> {
  private listCyclesInterval: any;

  componentDidMount() {
    //this.listCyclesInterval = setInterval(this.props.listCycles, 30000);
  }

  componentWillUnmount() {
    clearInterval(this.listCyclesInterval);
  }

  render() {
    const companyName = this.props.companyName;
    const balance = this.props.balance;
    const avatarUrl = this.props.avatarUrl || noavatar;

    return (
      <aside>
        <SidemenuSummary companyName={companyName} balance={balance} avatarUrl={avatarUrl} />
        <Menu vertical as="nav">
          <Menu.Item as={NavLink} to="/account/customer" exact>
            Test Cycles
          </Menu.Item>
          <Menu.Item as={NavLink} to="/account/customer/myprofile">
            My Profile
          </Menu.Item>
          <Menu.Item as={NavLink} to="/account/customer/favorite">
            Favorite Testers
          </Menu.Item>
          {/* <Menu.Item as={NavLink} to="/account/customer/howdoesitwork">
            How does it work
          </Menu.Item> */}
        </Menu>
      </aside>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidemenu);
