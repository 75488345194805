import { store } from '../redux/store';
import { DevicePlatform } from '../types/DevicePlatform';
import { StoreDeviceModel, StoreBrowser, StoreBrowserVersion } from '../types/StoreDeviceModel';

export function getPlatformPrettyName(platformId: DevicePlatform): string {
  switch (platformId) {
    case DevicePlatform.AndroidMobile:
      return 'Android Mobile';
    case DevicePlatform.AndroidTablet:
      return 'Android Tablet';
    case DevicePlatform.Desktop:
      return 'Desktop';
    case DevicePlatform.Ipad:
      return 'iPad';
    case DevicePlatform.Iphone:
      return 'iPhone';
    case DevicePlatform.WindowsPhone:
      return 'Windows Phone';
    case DevicePlatform.WindowsDesktop:
      return 'Windows Desktop';
    case DevicePlatform.WindowsLaptop:
      return 'Windows Laptop';
    case DevicePlatform.Windows:
      return 'Windows Desktop/Laptop';      
      case DevicePlatform.MACDesktop:
      return 'MAC Desktop';
    case DevicePlatform.MACNotebook:
      return 'MAC Notebook';
      
    default:
      return '';
  }
}

export function getApprovedDevicePrettyName(device): string {
  const { devices, browsers }: { devices: any; browsers: any } = store.getState();

  switch (device?.type) {
    case 'MobileDevice': {
      const model: StoreDeviceModel | undefined = devices.models.find(
        (el: StoreDeviceModel) => el.id === device.modelId
      );
      const os: any = devices.oses.find((el: any) => el.id === device.osId); // I give up with that types

      const modelName: string = model?.name || `Unknown model ${device.modelId}`;
      const osName: string = os?.name || `Unknown OS ${devices.osId}`;

      return `${modelName} with ${osName}`;
    }

    case 'DesktopBrowser': {
      const browser: StoreBrowser | undefined = browsers.names.find(
        (el: StoreBrowser) => el.id === device.browserId
      );
      const browserName = browser?.name || `Unknown browser ${browser?.id}`;

      const browserVersion: StoreBrowserVersion | undefined = browsers.versions.find(
        (el: StoreBrowserVersion) => el.id === device.browserVersionId
      );
      const browserVersionName: string =
        browserVersion?.version || `Unknown version ${browserVersion?.id}`;

      return `${browserName} ${browserVersionName}`;
    }

    default: {
      console.error('Unknown device', device);
      return 'Unknown device';
    }
  }
}

function filterModelsByPlatform(models: any[], platformId: DevicePlatform): any[] {
  return models.filter((model) => model.platformId === platformId);
}

function deprecated_filterModelsByPlatform(platformIds, models) {
  if (!platformIds.length) {
    return [];
  }

  return models.reduce((result, el) => {
    if (platformIds.includes(el.platformId)) {
      result.push(el);
    }

    return result;
  }, []);
}

function filterModelsByManufacturer(manufacturerId, models) {
  return models.filter((el) => el.manufacturerId === manufacturerId);
}

function filterManufacturersByPlatform(platformId, manufactureres, models) {
  if (!platformId) {
    return [];
  }

  const filteredModels = deprecated_filterModelsByPlatform([platformId], models);

  let includedIds: any[] = []; // TODO refactor

  const result = manufactureres.reduce((result, man: any) => {
    if (includedIds.includes(man.id)) {
      return result;
    }

    filteredModels.forEach((model) => {
      if (includedIds.includes(man.id)) {
        return result;
      }

      if (man.id === model.manufacturerId) {
        result.push(man);
        includedIds.push(man.id);
      }
    });

    return result;
  }, []);

  return result;
}

function filterOsesByPlatform(platformId, oses) {
  return oses.filter((el) => el.platformIds.includes(platformId)); // TODO do I need this oneliner?
}

const createDeviceOsField = (device, devices) => {
  const os = devices?.oses?.find(({ id }) => id === device?.osId);
  const model = devices?.models?.find(({ id }) => id === device?.modelId);
  return (
    (!model?.name && !os?.name) 
    ? getPlatformPrettyName(device?.platformId) 
    : `${model?.name || ''} ${os?.name || ''}`
  );
};

export {
  deprecated_filterModelsByPlatform,
  filterModelsByManufacturer,
  filterManufacturersByPlatform,
  filterOsesByPlatform,
  createDeviceOsField,
};
