import React from 'react';
import { Menu, Icon } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

const TesterDetailsSidemenu = ({ cycleId, avatarUrl, companyName }) => {
  return (
    <aside>
      <div className="summary">
        <img className="avatar" src={avatarUrl} alt="" height="64" width="64" />
        <div style={{ flexDirection: 'column' }}>
          <p className="name">{companyName}</p>
          {/*<span>Credit: $100</span>*/}
        </div>
      </div>

      <Menu vertical as="nav">
        <Menu.Item as={NavLink} to={`/account/customer/cycle/${cycleId}/testers`} exact>
          <Icon name="chevron circle left" /> Back to cycle
        </Menu.Item>
        <Menu.Item as={NavLink} to="/account/customer" exact>
          <Icon name="chevron circle left" /> Back to dashboard
        </Menu.Item>
      </Menu>
    </aside>
  );
};

export default TesterDetailsSidemenu;
