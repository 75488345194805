import config from '../../config';
import { Indicator } from '../../types/next';

const endpoint = config.apiNextEndpoint;

const getHeaders = () => ({
  Authorization: `Bearer ${localStorage.getItem('token')}`,
  'Content-Type': 'application/json',
});

export const getIndicatorsByType = async (type: string = 'chat'): Promise<Indicator[]> => {
  const response = await fetch(`${endpoint}/indicators/${type}`, {
    method: 'GET',
    headers: getHeaders(),
  });
  const indicators: Indicator[] = await response.json();
  return indicators || [];
};

export const resolveIndicator = async (id: number): Promise<any> => {
  const response = await fetch(`${endpoint}/indicators/${id}`, {
    method: 'PATCH',
    headers: getHeaders(),
  });
  return await response.json();
};
