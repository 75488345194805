import trim from 'lodash/trim';
import moment from 'moment';
import first from 'lodash/first';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material';
import { grey, red } from '@mui/material/colors';
import React, { useMemo, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';

import Button from '../../../../elements/Button';
import { RootState } from '../../../../redux/store';
import { getTopCycleMessages } from '../../../../services/messagesService';

import plusSvg from '../../../../public/images/icons/plus-white.svg';
import actions from '../../../../redux/actions';

const sliceText = (text: string = '', threshold: number = 75, suffix: string = '...'): string => {
    if (text.length > threshold) {
        return `${text.substring(0, threshold)}${suffix}`;
    }
    return text;
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: 350,
        padding: 20,
        height: 'calc(100vh - 170px)',
        borderRight: `1px solid ${theme.palette.primary.light}`,
    },
    sendToAllButton: {
        marginTop: 25,
        marginBottom: 45,
    },
    cyclePlus: {
        height: 18,
        marginLeft: 10,
        marginRight: 20,
    },
    unreadIndicator: {
        top: -1,
        width: 8,
        height: 8,
        marginRight: 6,
        position: 'relative',
        background: red[500],
        borderRadius: '100%',
        display: 'inline-block',
    },
    message: {
        cursor: 'pointer',
        padding: '18px 10px',
        transition: 'all 0.3s',
        borderTop: `1px solid ${theme.palette.primary.light}`,
        '&:hover': {
            //opacity: 0.85,
            //backgraoundColor: red[600],
            color: `${theme.palette.primary.main}`,

        },
    },
    activeMessage: {
        paddingLeft: 10,
        marginLeft: -10,
        borderLeft: `2px solid ${theme.palette.primary.main}`,
        color: `${theme.palette.primary.main}`,
    },
    messagesList: {
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 190px)',
    },
    smBottomSpace: {
        marginBottom: 10,
    },
}));

interface IMessagesSidebar {
    isTablet: boolean;
    cycleId: string;
    activeConversation: number;
    participantId: number;
    setConversation: (number) => void;
}

interface IMessagesInfo extends IMessagesSidebar {
    message: any;
}

export const MessageInfo: React.FC<IMessagesInfo> = ({
    message,
    cycleId,
    setConversation,
    activeConversation,
}) => {
    const classes = useStyles();

    const { cycleTesters } = useSelector((state: RootState) => state);

    const tester = cycleTesters?.byCycleId[cycleId].find(({ id }) => id === message.testerId);

    return (
        <div
            key={message.testerId}
            className={classes.message}
            onClick={() => setConversation(message.testerId)}
        >
            <div
                className={classNames({
                    [classes.activeMessage]: message.testerId === activeConversation,
                })}
            >
                {message.senderId > 0 &&
                    <>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            className={classes.smBottomSpace}
                        >
                            <Typography variant="caption" style={{ fontWeight: "bold" }}>
                                {tester?.firstName || ''} {tester?.lastName || ''}
                            </Typography>
                            <Typography variant="caption" color="textPrimary">
                                {moment(message.createdAt).format('lll')}
                            </Typography>
                        </Box>

                        <Typography variant="body2" color="textPrimary">
                            {!message.read && (
                                <span className={classes.unreadIndicator} />
                            )}
                            {sliceText(message.text)}
                        </Typography>
                    </>
                }
                {message.senderId < 0 &&
                    <>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            className={classes.smBottomSpace}
                        >
                            <Typography variant="caption" style={{ fontWeight: "bold" }}>
                                {tester?.firstName || ''} {tester?.lastName || ''}
                            </Typography>
                        </Box>
                    </>
                }
            </div>
        </div>
    );
};

const MessagesSidebar: React.FC<IMessagesSidebar> = ({
    isTablet,
    cycleId,
    participantId,
    setConversation,
    activeConversation,
}) => {
    const classes = useStyles();

    const { chatMessages } = useSelector((state: RootState) => state);
    const { cycleTesters } = useSelector((state: RootState) => state);
    const { messageContent } = useSelector((state: RootState) => state);

    const dispatch = useDispatch();
    let listChatMessagesInterval: any;

    const topMessages: object[] = useMemo(
        () => getTopCycleMessages(cycleTesters, chatMessages, cycleId),
        [chatMessages, cycleId]
    );

    useEffect(function onMessages() {
        if (!isEmpty(topMessages) && !activeConversation) {
            if (isEmpty(trim(messageContent))) {
                const firstMessage: any = first(topMessages) || { testerId: null };
                setConversation(firstMessage.testerId);
            }
        }
    }, [topMessages]);

    useEffect(function getThoseMessages() {
        listChatMessagesInterval = setInterval(function () { dispatch(actions.listChatMessages(cycleId, participantId)) }, 6000);
        return () => {
            clearInterval(listChatMessagesInterval);
        };
    });

    return (
        <Box display="flex" flexDirection="column" className={classes.root}>
            {!isTablet &&
                (<>
                    <Typography variant="h6">All Messages</Typography>
                    <Button
                        creation
                        className={classes.sendToAllButton}
                        onClick={() => setConversation(null)}
                    >
                        <Box display="flex" alignItems="center">
                            <img alt="Plus" src={plusSvg} title="Add Test" className={classes.cyclePlus} />
                            <Typography variant="body1" component="h2">Send message to all</Typography>
                        </Box>
                    </Button>
                </>)
            }
            <div className={classes.messagesList}>
                {topMessages.map((message: any) => (
                    <MessageInfo
                        isTablet
                        participantId={participantId}
                        key={message.testerId}
                        cycleId={cycleId}
                        message={message}
                        setConversation={setConversation}
                        activeConversation={activeConversation}
                    />
                ))}
            </div>
        </Box>
    );
};

export default MessagesSidebar;