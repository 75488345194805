import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { grey, blue } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: 300,
        height: 140,
        marginRight: 20,
        borderRadius: 8,
        backgroundColor: grey[50],
        padding: '15px 30px 20px 15px',
        [theme.breakpoints.down('lg')] : {
            width: 150,
            height: 100,            
            borderRadius: 3,
            backgroundColor: grey[50],
            padding: '0px 0px 0px 0px',
        },
    },
    iconContainer: {
        padding: 25,
        borderRadius: 6,
        marginRight: 25,
        backgroundColor: blue[50],
        [theme.breakpoints.down('lg')] : {
            padding: 25,
            borderRadius: 6,
            marginRight: 25,
        },
    },
    textContainer: {
        minHeight: 75,
    },  
    numbersText: {
        fontSize: '1.5rem',            
        letterSpacing: '0.1px',
    },
}));

interface IInfoCard {
    title: string;
    subtitle?: JSX.Element | string | null;
    description?: JSX.Element | string | null;
    Icon?: JSX.Element | null;
}

const InfoCard: React.FC<IInfoCard> = ({
    title,
    subtitle = '',
    description = '',
    Icon = null,
}) => {
    const classes = useStyles();

    return (
        <Box display="flex" alignItems="center" className={classes.root}>
            {Icon && (
                <Box display="flex" justifyContent="center" alignItems="center" className={classes.iconContainer}>
                    {Icon}
                </Box> 
            )}
            <Box display="flex" flexDirection="column" justifyContent="space-between" className={classes.textContainer}>
                <Typography className={classes.numbersText}/* variant="h5" */>{title}</Typography>                
                <Typography variant="body1" component="span" color="textSecondary">{description}</Typography>
                <Typography variant="subtitle2" color="textSecondary">{subtitle}</Typography>
            </Box>
        </Box>
    );
}

export default InfoCard;
