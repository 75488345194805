import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';
import Sidemenu from '../Sidemenu';
import Personal from './Personal';
import Devices from './Devices';
import Browsers from './Browsers';
// import Settings             from 'components/Account/Tester/MyProfile/Settings';
import Finance from './Finance';

const mapStateToProps = (state) => {
  return {
    device: state.resolution.device,
  };
};

class MyProfile extends Component<any> {
  public state: any;
  private panes: any;

  constructor(props) {
    super(props);

    this.state = {
      activeIndex: this.getActiveIndex(this.props.match.params.tab),
    };

    this.panes = [
      {
        menuItem: 'Personal',
        render: () => (
          <Tab.Pane>
            <Personal />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Devices',
        render: () => (
          <Tab.Pane>
            <Devices />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Browsers',
        render: () => (
          <Tab.Pane>
            <Browsers />
          </Tab.Pane>
        ),
      },
      // { menuItem: 'Settings', render: () => <Tab.Pane><Settings /></Tab.Pane> },
      {
        menuItem: 'Finance',
        render: () => (
          <Tab.Pane>
            <Finance />
          </Tab.Pane>
        ),
      },
    ];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.tab !== this.props.match.params.tab) {
      return this.setState({
        activeIndex: this.getActiveIndex(this.props.match.params.tab),
      });
    }
  }

  getActiveIndex = (tab) => {
    let activeIndex;

    switch (tab) {
      case 'personal':
        activeIndex = 0;
        break;
      case 'devices':
        activeIndex = 1;
        break;
      case 'browsers':
        activeIndex = 2;
        break;
      case 'finance':
        activeIndex = 3;
        break;
      default:
        activeIndex = 0;
        break;
    }

    return activeIndex;
  };

  onTabChange = (event, data) => {
    switch (data.activeIndex) {
      case 0:
        this.props.history.push('/account/tester/myprofile/personal');
        break;
      case 1:
        this.props.history.push('/account/tester/myprofile/devices');
        break;
      case 2:
        this.props.history.push('/account/tester/myprofile/browsers');
        break;
      case 3:
        this.props.history.push('/account/tester/myprofile/finance');
        break;
      default:
        break;
    }
  };

  render() {

    const isMobile = this.props.device === 'mobile' || this.props.device === 'mobile-tablet';
    
    if(isMobile){
      return(            
          <main>
            <Sidemenu />
            <Tab
              panes={this.panes}
              activeIndex={this.state.activeIndex}
              onTabChange={this.onTabChange}
            />
          </main>
      );
    }
    return (
      <div className="account-tester page">
        <div className="heading">
          <h1>My Profile</h1>
        </div>

        <div className="inner" style={{ display: 'flex' }}>
          <Sidemenu />
          <main>
            <Tab
              panes={this.panes}
              activeIndex={this.state.activeIndex}
              onTabChange={this.onTabChange}
            />
          </main>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, null)(MyProfile));