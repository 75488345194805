import React from 'react';
import classNames from 'classnames';
import Container from '@mui/material/Container';
import { grey, blue } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    container: {
        padding: '120px 30px',
    },
    containerPartial: {
        borderRadius: 10,
    },
    title: {
        marginBottom: 20,
        fontWeight: 600,
    },
    description: {
        maxWidth: 768,
        fontWeight: 500,
        margin: '0 auto',
    },
    colorLight: {
        color: '#ffffff',
    },
    grey: {
        backgroundColor: grey[50],
    },
    blue: {
        backgroundColor: blue[800],
        color: '#ffffff',
    },
    light: {
        backgroundColor: '#ffffff',
    },
}));

interface IHeroProps {
    title?: JSX.Element | string;
    subtitle?: JSX.Element | string;
    description?: JSX.Element | string;
    mode?: 'light' | 'grey' | 'blue';
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    className?: string;
    children?: JSX.Element | string | null;
};

const Hero: React.FC<IHeroProps> = ({
    title = '',
    subtitle = '',
    description = '',
    mode = 'grey', 
    maxWidth = 'xl',
    className = '',
    children = null,
}) => {
    const classes = useStyles();

    return (
        <Container 
            maxWidth={maxWidth}
            className={classNames(classes.container, classes[mode], {
                [classes.containerPartial]: maxWidth !== 'xl',
                [className]: true,
            })}
        >
            {title && (
                <Typography 
                    variant="h4" 
                    component="h1" 
                    align="center"
                    className={classNames(classes.title, {
                        [classes.colorLight]: mode === 'blue',
                    })}
                >
                    {title}
                </Typography>
            )}

            {subtitle && (
                <Typography 
                    variant="h6" 
                    component="h3" 
                    align="center"
                    className={classNames(classes.title, {
                        [classes.colorLight]: mode === 'blue',
                    })}
                >
                    {subtitle}
                </Typography>
            )}

            {description && (
                <Typography 
                    align="center"
                    variant="body1"
                    component={'div'}
                    className={classNames(classes.description, {
                        [classes.colorLight]: mode === 'blue',
                    })}
                >
                    {description}
                </Typography>
            )}
            
            {children}
        </Container>
    );
};

export default Hero;