import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ReactTable from 'react-table';
import { Button, Input } from 'semantic-ui-react';
import actions from '../../../redux/actions';
import Sidemenu from './Sidemenu';

const mapStateToProps = (state) => {
    return {
        user: state.user,
        acssociatedCustomers: state.acssociatedCustomers,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        registerAffiliate: (userId) => dispatch(actions.registerAffiliate(userId)),
        listAssociatedCustomers: (userId) => dispatch(actions.listAssociatedCustomers(userId))
    };
};


class Affiliates extends Component<any> {

    componentDidMount() {
        if (this.props.user.affiliatePromo) {
            this.props.listAssociatedCustomers(this.props.user.id);
        }
    }

    registerAffiliate = () => {
        this.props.registerAffiliate(this.props.user.id);
    }

    copyToClipboard = () => {
        return navigator.clipboard.writeText(this.props.user.affiliatePromo);
    }

    render() {
        const isMobile = this.props.device === 'mobile' || this.props.device === 'mobile-tablet';
        const user = this.props.user;

        const columns = [
            {
                Header: 'Your Partners:',
                columns: [
                    {
                        Header: 'Name',
                        accessor: 'name',
                    },
                    {
                        Header: 'Joined on',
                        id: 'joinedData',
                        accessor: (row) => moment(row.joinedDate).format('DD.MM.YYYY'),
                        getProps: () => ({ style: { textAlign: 'center' } }),
                        sortMethod: (a, b) => {
                            if (moment(a).isAfter(moment(b))) {
                                return 1;
                            }

                            if (moment(a).isBefore(moment(b))) {
                                return -1;
                            }

                            return 0;
                        },
                    },
                    {
                        Header: 'Total revenue',
                        accessor: 'totalRevenue',
                        getProps: () => ({ style: { textAlign: 'center' } }),

                    },
                ],
            },
        ];

        if (isMobile) {
            return (
                <div>
                    <Sidemenu />
                    <main>
                        <h2>Affiliates</h2>
                    </main>
                </div>
            );
        }
        return (
            <div className="account-tester page">
                <div className="heading">
                    <h1>Affiliates Program</h1>
                </div>

                <div className="inner">
                    <Sidemenu />
                    <main>
                        <h2>Affiliates Program</h2>
                        <h2>So, how does it work?</h2>
                        <div>
                            <div>
                                {!user.affiliatePromo &&
                                    <p>
                                        By simply clicking the button below, you'll embark on an exciting affiliate journey. Upon registration,
                                        a unique token will be swiftly generated, granting you immediate affiliate status.
                                    </p>
                                }
                                <p>
                                    This token serves as your key to success – effortlessly share it with potential customers,
                                    who will delight in receiving what appears to be a simple discount coupon.
                                </p>
                                But here's where the magic happens: as they utilize their token to initiate their inaugural test cycle within our system, a 10% discount will be theirs to enjoy.
                                Beyond this attractive benefit, a significant opportunity arises – their association with your account is established.
                                Subsequently, for every test cycle they embark upon, a rewarding <strong>10%</strong> of the total cycle price flows into your account, like a continuous stream of earnings.
                                <p>
                                    This isn't just affiliation; it's a pathway to earning with every cycle defined on the system. Your success story starts now!
                                </p>
                            </div>

                            {user.affiliatePromo &&
                                <div>
                                    <p>Your Affiliator promo code:</p>

                                    <Input
                                        action={{
                                            color: 'teal',
                                            icon: 'copy',
                                            onClick: () => this.copyToClipboard()
                                        }}
                                        value={user.affiliatePromo}
                                    />
                                    <div style={{ paddingTop: '30px' }}>
                                        <ReactTable data={this.props.acssociatedCustomers} columns={columns} />
                                    </div>
                                </div>
                            }
                            {!user.affiliatePromo &&
                                <div>
                                    <Button
                                        content="Opt In!"
                                        color='green'
                                        size="tiny"
                                        onClick={this.registerAffiliate}
                                        style={{ width: '220px' }}
                                    />
                                </div>
                            }

                        </div>
                    </main>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Affiliates));