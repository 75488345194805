import React, { useEffect, useState } from 'react';
import ChatMessageView from './ChatMessageView';
import CreateMessage from './CreateMessage';
import {
  createChatMessage,
  getChatMessages,
  setChatLastRead,
} from '../../services/next/chatService';
import { ChatMessage } from '../../types/next';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const MessagesList = styled.div`
  list-style: none;
  padding: 0;
  margin: 0;
`;

interface Props {
  user: number;
  chat: number;
}

const ChatView = ({ user, chat }: Props) => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);

  const loadMessages = async () => {
    const loaded = await getChatMessages(chat);
    setMessages(loaded);
    await setChatLastRead(chat);
  };

  useEffect(() => {
    loadMessages();
  }, []);

  const handleSubmitMessage = async (message: string) => {
    await createChatMessage(chat, message);
    loadMessages();
  };

  if (!messages) {
    return <div>No messages</div>;
  }

  return (
    <Wrapper>
      <MessagesList>
        {messages.map((message) => (
          <li key={message.id}>
            <ChatMessageView message={message} isUser={user === message.user.id} />
          </li>
        ))}
      </MessagesList>
      <CreateMessage onSubmit={handleSubmitMessage} />
    </Wrapper>
  );
};

export default ChatView;
