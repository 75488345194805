import { ReportingCategory } from "../../types/reportingCategory";

export default [
    {
        text: 'Crashes',
        key: ReportingCategory.Crashes
    },
    {
        text: 'Errors',
        key: ReportingCategory.Errors
    },
    {
        text: 'Flow dead ends',
        key: ReportingCategory.FlowDeadEnds
    },
    {
        text: 'Cut text or graphic',
        key: ReportingCategory.CutTextOrGraphic
    },
    {
        text: 'Not function correctly',
        key: ReportingCategory.NotFunctionCorrectly
    },
    {
        text: 'Broken links',
        key: ReportingCategory.BrokenLinks
    },
    {
        text: 'Long loading time',
        key: ReportingCategory.LongLoadingTimes
    },
    {
        text: 'Baterry drain',
        key: ReportingCategory.BatteryDrain
    },          
  ];
  