import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Input, Message, Select } from 'semantic-ui-react';
import noavatar from '../../../../public/images/noavatar.png';
import actions from '../../../../redux/actions';
import { OperationStatus } from '../../../../types/OperationStatus';
import { UserRole } from '../../../../types/UserRole';
import countryCodes from '../../../../util/options/countryCodes';
import { searchFromStart } from '../../../../util/utils';
import PasswordChangeModal from '../../../PasswordChangeModal';

const mapStateToProps = (state) => {
  return {
    userUploadAvatarStatus: state.userUploadAvatarStatus,
    userSaveStatus: state.userSaveStatus,
    passwordChangeStatus: state.passwordChangeStatus,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (data) => dispatch(actions.updateUser(data)),
    saveUser: (data: any, notify?: boolean) => dispatch(actions.saveUser(data, notify)),
    uploadAvatar: (avatarFile) => dispatch(actions.uploadAvatar(avatarFile)),
  };
};

class Personal extends Component<any> {
  public state: any;

  constructor(props) {
    super(props);

    this.state = {
      newAvatar: {},
      newAvatarError: '',
      errors: [],
    };
  }

  resetErrorState = (event, data) => {
    const name = event.target.name;

    if (this.state.errors.includes(name)) {
      const errors = [...this.state.errors];
      errors.splice(this.state.errors.indexOf(name), 1);
      this.setState({ errors });
    }
  };

  onChange = (event, data) => {
    const user = { ...this.props.user };
    user[data.name] = data.value;

    return this.props.updateUser(user);
  };

  validateInput = (event, data) => {
    const errors = [...this.state.errors];
    const name = data && data.name ? data.name : event.target.name;
    const value = data && data.value ? data.value : event.target.value;

    switch (name) {
      case 'firstName': {
        if (!value.length) {
          errors.push(name);
          this.setState({ errors });
        }
        break;
      }
      case 'lastName': {
        if (!value.length) {
          errors.push(name);
          this.setState({ errors });
        }
        break;
      }
      case 'companyName': {
        if (!value.length) {
          errors.push(name);
          this.setState({ errors });
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  onAvatarUpload = ([acceptedImageFile]) => {
    const newAvatar = Object.assign(acceptedImageFile, {
      preview: URL.createObjectURL(acceptedImageFile),
    });

    this.setState({
      newAvatar,
      newAvatarError: '',
    });
  };

  onAvatarRejected = () => {
    this.setState({ newAvatarError: 'Avatar size can be no more than 1 Mb' });
  };

  onSave = async () => {
    const userData: Record<string, any> = Object.keys(this.props.user).reduce((result, el) => {
      if (
        this.props.user[el] !== null &&
        el !== 'cycles' // cycles are managed separately
      ) {
        result[el] = this.props.user[el];
      }

      return result;
    }, {});

    if (this.state.newAvatar.preview) {
      userData.newAvatar = this.state.newAvatar;
    }

    const errors: any[] = [];

    ['firstName', 'lastName', 'country', 'companyName'].forEach((el) => {
      if (!userData[el]) {
        errors.push(el);
      }
    });

    if (errors.length) {
      return this.setState({ errors });
    }

    delete userData.avatarUrl;

    if (this.state.newAvatar) {
      await this.props.uploadAvatar(this.state.newAvatar);
    }

    this.props.saveUser(userData, true);
  };

  render() {
    const userSaveProcessing = this.props.userSaveStatus.status === OperationStatus.processing || this.props.userUploadAvatarStatus.status === OperationStatus.processing;
    const user = this.props.user;

    let avatarUrl;

    if (this.state.newAvatar.preview) {
      avatarUrl = this.state.newAvatar.preview;
    } else if (user.avatarUrl) {
      avatarUrl = user.avatarUrl;
    } else {
      avatarUrl = noavatar;
    }

    return (
      <div>
        <h2>Personal Information</h2>
        <table className="personal-information">
          <tbody>
            <tr>
              <td>Photo</td>
              <td className="avatar-cell">
                <img className="avatar" src={avatarUrl} alt="avatar" height="128" width="128" />
                {this.state.newAvatarError && (
                  <Message negative compact size="mini">
                    {this.state.newAvatarError}
                  </Message>
                )}
                <Dropzone
                  multiple={false}
                  maxSize={1000000}
                  accept="image/jpeg, image/png"
                  onDropAccepted={this.onAvatarUpload}
                  onDropRejected={this.onAvatarRejected}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Button primary size="tiny" style={{ width: '128px' }} content="Change" />
                    </div>
                  )}
                </Dropzone>
              </td>
            </tr>
            <tr>
              <td>First name</td>
              <td>
                <Input
                  placeholder="First name"
                  name="firstName"
                  value={this.props.user.firstName}
                  onChange={this.onChange}
                  onBlur={this.validateInput}
                  onFocus={this.resetErrorState}
                  error={this.state.errors.includes('firstName')}
                />
              </td>
            </tr>
            <tr>
              <td>Last name</td>
              <td>
                <Input
                  placeholder="Last name"
                  name="lastName"
                  value={this.props.user.lastName}
                  onChange={this.onChange}
                  onBlur={this.validateInput}
                  onFocus={this.resetErrorState}
                  error={this.state.errors.includes('lastName')}
                />
              </td>
            </tr>
            <tr>
              <td>Country</td>
              <td>
                <Select
                  name="country"
                  placeholder="Country"
                  options={countryCodes}
                  value={this.props.user.country}
                  onChange={this.onChange}
                  onBlur={this.validateInput}
                  onOpen={this.resetErrorState}
                  error={this.state.errors.includes('country')}
                  search={searchFromStart}
                />
              </td>
            </tr>
            <tr>
              <td>Company name</td>
              <td>
                <Input
                  placeholder="Company name"
                  name="companyName"
                  value={this.props.user.companyName}
                  onChange={this.onChange}
                  onBlur={this.validateInput}
                  onFocus={this.resetErrorState}
                  error={this.state.errors.includes('companyName')}
                />
              </td>
            </tr>
            <tr>
              <td>Company address</td>
              <td>
                <Input
                  placeholder="Company address"
                  name="companyAddress"
                  value={this.props.user.companyAddress}
                  onChange={this.onChange}
                />
              </td>
            </tr>
            <tr>
              <td>Company HQ address</td>
              <td>
                <Input
                  placeholder="Company HQ address"
                  name="companyHqAddress"
                  value={this.props.user.companyHqAddress}
                  onChange={this.onChange}
                />
              </td>
            </tr>
            <tr>
              <td>Position</td>
              <td>
                <Input
                  placeholder="Position"
                  name="position"
                  value={this.props.user.position}
                  onChange={this.onChange}
                />
              </td>
            </tr>
            <tr>
              <td>Company URL</td>
              <td>
                <Input
                  placeholder="Company URL"
                  name="companyUrl"
                  value={this.props.user.companyUrl}
                  onChange={this.onChange}
                />
              </td>
            </tr>
          </tbody>
        </table>

        <Button
          primary
          loading={userSaveProcessing}
          disabled={userSaveProcessing || this.state.errors.length || this.props.user.role === UserRole.Viewer}
          onClick={this.onSave}
          content="Save"
        />
        <PasswordChangeModal />
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Personal));
