import moment from 'moment';
import first from 'lodash/first';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material';
import { useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { grey, yellow } from '@mui/material/colors';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import React, { useRef, useState, useEffect } from 'react';

import NewBugsMessage from './NewBugsMessage';
import { RootState } from '../../../../redux/store';
import chatService from '../../../../services/chatService';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        overflowY: 'auto',
        height: 'calc(100vh - 190px)',
    },
    message: {
        padding: 15,
        marginTop: 20,
        borderRadius: 8,
        marginBottom: 20,
        border: `1px solid ${grey[500]}`,
    },
    title: {
        fontSize: 16,
        marginBottom: 15,
    },
    avatar: {
        width: 28,
        height: 28,
        fontSize: 14,
        marginRight: 8,
        backgroundColor: yellow[900],
    },
    avatarCurrentUser: {
        backgroundColor: theme.palette.primary.main,
    },
    clockIcon: { 
        fontSize: 15, 
        marginRight: 5,
        marginBottom: -2,
    },
}));

interface IBugMessages {
    chatId: number;
    bugId: number | null;
}

const BugMessages: React.FC<IBugMessages> = ({ bugId, chatId }) => {
    const classes = useStyles();

    const NewsBugsMessageRef: any = useRef(null);

    const [messages, setMessages] = useState<any[]>([]);

    const { user } = useSelector((state: RootState) => state);

    const scrollToBottom = () => {
        NewsBugsMessageRef?.current?.scrollIntoView({ behavior: 'auto', block: 'end', inline: 'nearest' });
    };

    useEffect(scrollToBottom, [NewsBugsMessageRef, messages]);

    const fetchBugMessages = async () => {
        if (bugId) {
            try {
                const bugMessages = await chatService.getBugMessages(bugId);
                setMessages(bugMessages);
            } catch(e) {
                setMessages([]);
            }
        } else {
            setMessages([]);
        }
    };

    useEffect(function onBugIdChange() {
        fetchBugMessages();
    }, [bugId]);

    return (
        <Box display="flex" flexDirection="column" className={classes.root}>
            <div ref={NewsBugsMessageRef} />
            <NewBugsMessage chatId={chatId} refetchMessages={fetchBugMessages} />
            {!isEmpty(messages) && messages?.map((message: any) => {
                const messageUser = message.user.id === user.id  ? user : message.user;
                const nameLetters = `${first(messageUser.firstName)}${first(messageUser.lastName)}`;
                return (
                    <Box 
                        key={message.id} 
                        display="flex" 
                        flexDirection="column"
                        className={classes.message}
                    >
                        <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.title}>
                            <Box display="flex" alignItems="center">
                                {!!messageUser.avatarUrl ? (
                                    <Avatar 
                                        src={messageUser.avatarUrl} 
                                        alt={`${messageUser.firstName}${messageUser.lastName}`} 
                                        className={classNames(classes.avatar, { 
                                            [classes.avatarCurrentUser]: messageUser.id === user.id,
                                        })} 
                                    />
                                ) : (
                                    <Avatar 
                                        className={classNames(classes.avatar, { 
                                            [classes.avatarCurrentUser]: messageUser.id === user.id,
                                        })}
                                    >
                                        {nameLetters}
                                    </Avatar>
                                )}
                                <Typography variant="body1">
                                    {messageUser.firstName} {messageUser.lastName}
                                </Typography>
                            </Box>

                            <Typography variant="body2" color="textSecondary">
                                <AccessTimeIcon className={classes.clockIcon} />
                                {moment(message.creationDate).fromNow()}  
                            </Typography>
                        </Box>

                        <Typography variant="body2" color="textSecondary" style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}>{message.content}</Typography>
                    </Box>
                );
            }).reverse()}
        </Box>
    );
};

export default BugMessages;
