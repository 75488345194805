import { useEffect } from 'react';
import debounce from 'lodash/debounce';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';

import resolutionActions from '../redux/actions/resolutionActions';

const useResolution = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
  
    useEffect(function onWindowSizeChange() {
      const onResize = () => {
        const windowWidth = window.innerWidth;

        const device = (() => {
          if (windowWidth < theme.breakpoints.values.sm) {
            return 'mobile';
          }
          if (windowWidth < theme.breakpoints.values.md) {
            return 'mobile-tablet';
          }
          if (windowWidth < theme.breakpoints.values.lg) {
            return 'tablet';
          }
          return 'desktop';
        })();
  
        dispatch(resolutionActions.setResolution(device, windowWidth));
      };
  
      const onDebouncedResize = debounce(onResize, 1000);
      window.addEventListener('resize', onDebouncedResize);

      onResize();
  
      return () => {
        window.removeEventListener('resize', onDebouncedResize);
      };
    }, [dispatch]);
};

export default useResolution;