import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Box, Button, IconButton, Table,
    TableBody, TableCell, TableContainer,
    TableHead, TableRow, Typography
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import wizardActions from '../../../redux/actions/wizardActions';
import { NewWizardCycle } from '../../../types/NewWizardCycle';
import { PayoutRatesAndTestersQuota } from '../../../types/WizardCategoriesTypes';
import countryCodes from '../../../util/options/countryCodes';
import { titleBlue } from './SharedStyle';

import { SpecificationType } from '../../../types/WizardCategoriesTypes';
import { DeviceTestObject } from './../../../types/NewPlatformModel';

const styles = (theme) => createStyles({
    internalBox: {
        marginTop: '20px',
        marginLeft: '-8px',
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: '8px',
            width: '200px',
            height: '200px',
        },
    },

    SelectText: {
        color: titleBlue,
    },

    MenuITemText: {
        color: titleBlue,
    },

    MenuItemTextDefault: {
        fontStyle: 'italic',
        color: titleBlue,
    },

    formControl: {
        //margin: theme.spacing(1),
        minWidth: 100,
    },

});

const MenuProps = {
    disableScrollLock: true,
};

const useStyles = makeStyles((theme) => ({
    textBlue: {
        color: titleBlue,
    },
    root: {
        '& .MuiButton-outlined': {
            border: "none",
            borderBottom: '1px solid rgba(0, 0, 0, 0.23);',
            borderRadius: '0px',
        },
    },
    InternalBox: {
        display: "flex",
    },
    SpreadBox: {
        display: "flex",
        justifyContent: "start",
        alignItems: "center"
    },
    counterArrows: {
        color: titleBlue,
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 'bold'
    },
    counterLabel: {
        disabledRipple: true,
        color: titleBlue,
        fontFamily: 'Poppins',
        fontSize: '15px',
        cursor: 'default',
        '&:hover': {
            background: 'none !important',
        },
    },

    formControl: {
        minWidth: 100,
    },
    MenuITemText: {
        color: titleBlue,
    },
    SelectText: {
        color: titleBlue,
    },
})
);


const StyledTableCell = withStyles((theme) =>
    createStyles({
        root: {
            //borderBottom: "none",        
        },
        head: {
            backgroundColor: theme.palette.common.white,
            color: titleBlue,
        },
        body: {
            //fontSize: 14,
        },
    }),
)(TableCell);

const StyledTableCellBordered = withStyles((theme) =>
    createStyles({
        root: {
            //borderBottom: "none",        
        },
        head: {
            backgroundColor: theme.palette.common.white,
            color: titleBlue,
        },
        body: {
            //fontSize: 14,
        },
    }),
)(TableCell);

const CounterSelector = (props) => {
    const classes = useStyles();
    const { val, onIncrement, onDecrement, minVal } = props;

    return (
        <Box className={`${classes.SpreadBox} ${classes.InternalBox}`}>
            <IconButton onClick={onIncrement} size="large"> <ExpandLessIcon /> </IconButton>
            <Button variant="text" disableRipple className={classes.counterLabel}>
                {val}
            </Button>
            <IconButton
                disabled={minVal === val ? true : false}
                onClick={onDecrement}
                size="large"> <ExpandMoreIcon /> </IconButton>
        </Box >
    );
}


const CountryTableRow = (props) => {
    const classes = useStyles();
    const rowData = props.data;

    return (
        <TableRow style={{ backgroundColor: "#32448308" }}>
            <StyledTableCellBordered>
                <Button
                    variant="contained"
                    disabled
                    size="small">
                    {props.platformName}
                </Button>
            </StyledTableCellBordered>

            {/* <StyledTableCellBordered>
        <FormControl className={classes.formControl}>

          <Select
            variant="standard"
            disabled={!props.specificationOn}
            className={classes.SelectText}
            id={rowData.key}
            name={(rowData.id).toString()}
            onChange={props.onDeviceSet}            
            MenuProps={MenuProps} value={rowData.device.id}>
            {props.devices.map((device, index) => (
              <MenuItem key={index} value={device.id} className={classes.MenuITemText}>
                {device.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </StyledTableCellBordered> */}

            <StyledTableCellBordered>
                <Typography className={classes.textBlue}>
                    {rowData.country}
                </Typography>
            </StyledTableCellBordered>

            {props.isWebsiteType &&
                <StyledTableCellBordered>
                    <Typography className={classes.textBlue}>
                        Relevant Browsers
                    </Typography>
                </StyledTableCellBordered>
            }

            <StyledTableCellBordered align="center">
                <Typography className={classes.textBlue}>
                    <CounterSelector align="center"
                        minVal={PayoutRatesAndTestersQuota.minTestersPerSpecification}
                        val={rowData.numberOfTesters}
                        onIncrement={() => props.onIncrement(rowData.id)}
                        onDecrement={() => props.onDecrement(rowData.id)}
                    />
                </Typography>
            </StyledTableCellBordered>

            <StyledTableCellBordered>

            </StyledTableCellBordered>
        </TableRow>
    );
}


const mapStateToProps = (store) => {
    return {
        newCycle: store.newWizardCycle as NewWizardCycle,
        systemPlatforms: store.systemPlatforms,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        updateCycle: (data) => dispatch(wizardActions.updateCycle(data)),
    };
};

class DevicesFormSlim extends Component<any> {
    public state: any;

    constructor(props) {
        super(props);

        this.state = {
            newDevice: new DeviceTestObject(0),
            errors: [],
        };
    }

    componentDidMount() {
        const newCycle: NewWizardCycle = { ...this.props.newCycle };
        //Default state
        if (newCycle.relevantDevices.length === 0 && newCycle.specificationPerCountry.length === 0) {
            newCycle.isSpecificDevices = false;

            if (newCycle.isWorldwide) {
                newCycle.numberOfTesters = PayoutRatesAndTestersQuota.minTestersPerCycle;
            }
            else {
                newCycle.numberOfTesters = Math.max(newCycle.countries.length * PayoutRatesAndTestersQuota.minTestersPerSpecification, PayoutRatesAndTestersQuota.minTestersPerCycle);
            }


        }

        if (newCycle.specificationPerCountry.length === 0) {
            //Build relevant devices per country in case of selected-countries
            if (!newCycle.isWorldwide) {

                const minTestersAllowed = newCycle.countries.length > 1
                    ? PayoutRatesAndTestersQuota.minTestersPerSpecification
                    : PayoutRatesAndTestersQuota.minTestersPerCycle;

                let i = 0;
                for (const country of newCycle.countries) {
                    const specificDevicePerCountry = new DeviceTestObject(i);
                    specificDevicePerCountry.country = country;
                    specificDevicePerCountry.numberOfTesters = minTestersAllowed;
                    newCycle.specificationPerCountry.push(specificDevicePerCountry);
                    i = i + 1;
                }
            }
        }

        this.props.updateCycle(newCycle);
    }

    componentWillUnmount() {
        const newCycle = { ...this.props.newCycle };
        if (newCycle.isWorldwide) {
            if (newCycle.isSpecificDevices && newCycle.relevantDevices.length === 0) {
                newCycle.isSpecificDevices = false;

                this.props.updateCycle(newCycle);
            }
        }
    }

    getAvailableDevices = () => {
        const platformIdSelected = this.props.newCycle.mainTestPlatform.id;
        const fullPlatformSystemData = this.props.systemPlatforms.find((elm) => elm.id === platformIdSelected);

        let relevantDevices = [{ id: 0, name: 'Any' }, ...fullPlatformSystemData.Devices];

        return relevantDevices;
    }

    getAvailableCountries = () => {
        if (this.props.newCycle.isWorldwide) {
            const countryNames = countryCodes.map(e => e.text);
            const availableCountries = ['Anywhere', ...countryNames];
            return availableCountries;
        }
        else {
            const availableCountries = ['', ...this.props.newCycle.countries]
            return availableCountries;
        }
    }

    getAvailableBrowsers = () => {
        const availableBrowsers = [{ id: 0, name: 'Relevant browsers' }, ...this.props.newCycle.relevantBrowsers];

        return availableBrowsers;
    }

    getNumberOfTestersOptions = () => {
        return [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 25, 30];
    }

    onDeleteSpecificDevice = (rowIndex) => {
        const newCycle: NewWizardCycle = { ...this.props.newCycle };
        var devices = newCycle.relevantDevices;

        devices?.splice(rowIndex, 1);
        newCycle.relevantDevices = devices;

        return this.props.updateCycle(newCycle);
    }

    validateNonEmptyInput = () => {
        const errors: Set<string> = new Set();
        if (!this.state.newDevice.device.id) {
            errors.add('Device');
        }
        if (this.state.newDevice.country === '') {
            errors.add('Country');
        }

        return Array.from(errors);
    }

    onAddSpecificDevice = () => {
        const newCycle: NewWizardCycle = { ...this.props.newCycle };
        if (newCycle.isWorldwide) {
            this.state.newDevice.country = 'Anywhere';
        }
        //Check to see if it's disabled Desktop case, so we select automatically
        const devices = this.getAvailableDevices();
        if (devices.length === 2) {
            const newDeviceName = this.getAvailableDevices().find(d => d.id === devices[1].id).name;
            const newData = this.state.newDevice;
            newData.device.id = devices[1].id;
            newData.device.name = newDeviceName;
            this.resetErrorStateForInput('Device');
            this.setState({
                newDevice: newData,
            })
        }
        const stepErrors = this.validateNonEmptyInput();

        if (stepErrors.length) {
            this.setState(
                {
                    ...this.state,
                    errors: stepErrors
                }
            )
            return;
        }

        newCycle.relevantDevices.push(
            this.state.newDevice
        );

        this.setState(
            {
                newDevice: new DeviceTestObject(0),
            },
            () => {
                const newCycleJson = JSON.stringify(newCycle);
                return this.props.updateCycle(newCycle);
            }
        );

    }

    handlePlatformSwitch = (event) => {
        const newCycle: NewWizardCycle = { ...this.props.newCycle };
        newCycle.isSpecificDevices = event.target.checked;

        this.props.updateCycle(newCycle);
    }

    onDeviceSelected = (e) => {
        const newDeviceId = e.target.value;
        const newDeviceName = this.getAvailableDevices().find(d => d.id === newDeviceId).name;
        const newData = this.state.newDevice;
        newData.device.id = newDeviceId;
        newData.device.name = newDeviceName;
        this.resetErrorStateForInput('Device');
        this.setState({
            newDevice: newData,
        })
    }

    onDeviceSetForCountry = (e) => {
        const relevantCountryRowIndex = parseInt(e.target.name);
        const deviceId = (e.target.value);
        const newCycle: NewWizardCycle = {
            ...this.props.newCycle,
            specificationPerCountry: [
                ...this.props.newCycle.specificationPerCountry
            ]
        };

        let updatedCountrySpecifics = newCycle.specificationPerCountry;
        const countryRowUpdated = updatedCountrySpecifics.find((countryRow) => countryRow.id === relevantCountryRowIndex);

        const specificDevicePerCountryObj = new DeviceTestObject(relevantCountryRowIndex);
        specificDevicePerCountryObj.country = countryRowUpdated ? countryRowUpdated.country : '';
        specificDevicePerCountryObj.numberOfTesters = countryRowUpdated ? countryRowUpdated.numberOfTesters : 3;
        specificDevicePerCountryObj.device.id = deviceId;

        const deviceSpec = this.getAvailableDevices().find(device => device.id === deviceId);

        specificDevicePerCountryObj.device.name = deviceSpec.name;

        updatedCountrySpecifics = [specificDevicePerCountryObj, ...newCycle.specificationPerCountry.filter((elm) => elm.id != relevantCountryRowIndex)];

        updatedCountrySpecifics.sort((b1, b2) => b1.id - b2.id);

        newCycle.specificationPerCountry = updatedCountrySpecifics;

        this.props.updateCycle(newCycle);
    }

    onCountrySelected = (e) => {
        const newCountry = e.target.value;
        const newData = this.state.newDevice;
        newData.country = newCountry;
        this.resetErrorStateForInput('Country');
        this.setState({
            newDevice: newData,
        })
    }

    resetErrorStateForInput = (targetName) => {
        if (this.state.errors.includes(targetName)) {
            const errors = [...this.state.errors];

            errors.splice(this.state.errors.indexOf(targetName), 1);
            this.setState({ errors });
        }
    }

    onBrowserSelected = (e) => {
        const newBrowserId = e.target.value;
        const newBrowserName = this.getAvailableBrowsers().find(d => d.id === newBrowserId).name;
        const newData = this.state.newDevice;
        newData.browser.id = newBrowserId;
        newData.browser.name = newBrowserName;
        this.setState({
            newDevice: newData,
        })
    }

    onNumberofTestersSelected = (e) => {
        const numberOfTesters = e.target.value;
        const newData = this.state.newDevice;
        newData.numberOfTesters = numberOfTesters;
        this.setState({
            newDevice: newData,
        })
    }

    IncrementTotalTesters = () => {
        const newCycle: NewWizardCycle = { ...this.props.newCycle };
        const newValue = newCycle.numberOfTesters + 1;

        newCycle.numberOfTesters = newValue;

        this.props.updateCycle(newCycle);
    }

    DecrementTotalTesters = () => {
        const newCycle: NewWizardCycle = { ...this.props.newCycle };
        const newValue = newCycle.numberOfTesters - 1;

        newCycle.numberOfTesters = newValue;

        this.props.updateCycle(newCycle);
    }

    IncrementTestersPerDeviceType = () => {
        const newData = this.state.newDevice;
        newData.numberOfTesters++;

        this.setState({
            newDevice: newData,
        })
    }

    DecrementTestersPerDeviceType = () => {
        const newData = this.state.newDevice;
        newData.numberOfTesters--;

        this.setState({
            newDevice: newData,
        })
    }

    IncrementTestersPerCountry = (rowId) => {
        const newCycle: NewWizardCycle = {
            ...this.props.newCycle,
            specificationPerCountry: [
                ...this.props.newCycle.specificationPerCountry
            ]
        };

        let updatedCountrySpecifics = newCycle.specificationPerCountry;
        const countryRowUpdated = updatedCountrySpecifics.find((countryRow) => countryRow.id === rowId);

        const specificDevicePerCountryObj = new DeviceTestObject(rowId);
        specificDevicePerCountryObj.country = countryRowUpdated ? countryRowUpdated.country : '';
        specificDevicePerCountryObj.numberOfTesters = countryRowUpdated ? countryRowUpdated.numberOfTesters + 1 : 3;
        specificDevicePerCountryObj.device = countryRowUpdated?.device ? countryRowUpdated?.device : { id: 0, name: '' };

        updatedCountrySpecifics = [specificDevicePerCountryObj, ...newCycle.specificationPerCountry.filter((elm) => elm.id != rowId)];

        updatedCountrySpecifics.sort((b1, b2) => b1.id - b2.id);

        newCycle.specificationPerCountry = updatedCountrySpecifics;

        this.props.updateCycle(newCycle);
    }

    DecrementTestersPerCountry = (rowId) => {
        const newCycle: NewWizardCycle = {
            ...this.props.newCycle,
            specificationPerCountry: [
                ...this.props.newCycle.specificationPerCountry
            ]
        };

        let updatedCountrySpecifics = newCycle.specificationPerCountry;
        const countryRowUpdated = updatedCountrySpecifics.find((countryRow) => countryRow.id === rowId);

        const specificDevicePerCountryObj = new DeviceTestObject(rowId);
        specificDevicePerCountryObj.country = countryRowUpdated ? countryRowUpdated.country : '';
        specificDevicePerCountryObj.numberOfTesters = countryRowUpdated ? countryRowUpdated.numberOfTesters - 1 : 3;
        specificDevicePerCountryObj.device = countryRowUpdated?.device ? countryRowUpdated?.device : { id: 0, name: '' };

        updatedCountrySpecifics = [specificDevicePerCountryObj, ...newCycle.specificationPerCountry.filter((elm) => elm.id != rowId)];

        updatedCountrySpecifics.sort((b1, b2) => b1.id - b2.id);

        newCycle.specificationPerCountry = updatedCountrySpecifics;

        this.props.updateCycle(newCycle);
    }

    totalNumberOftestersSoFar = () => {
        const newCycle: NewWizardCycle = { ...this.props.newCycle };

        const totalUserdefined = newCycle.isSpecificDevices ? newCycle.relevantDevices.reduce((result, el) => {
            result += el.numberOfTesters;
            return result;
        }, 0) : 0;

        const totalPerCountry = newCycle.specificationPerCountry.reduce((result, el) => {
            result += el.numberOfTesters;
            return result;
        }, 0);

        const numberOftesters = Math.max(PayoutRatesAndTestersQuota.minTestersPerCycle, (totalUserdefined + totalPerCountry));

        return numberOftesters;
    }

    render() {
        const { classes, newCycle } = this.props;
        const totalTestersSoFar = this.totalNumberOftestersSoFar();

        return (
            <React.Fragment>

                {/* *************************  THE DYNAMIC LIST ******************************** */}

                <Box style={{ marginTop: "15px", width: "95%", height: "550px", overflow: 'auto', }}>
                    <TableContainer style={{ maxHeight: "90%", overflow: 'auto', }}>
                        <Table stickyHeader size="small">
                            <TableHead>
                                {/* Header */}
                                <TableRow>
                                    <StyledTableCell width={newCycle.testSpecification === SpecificationType.Website ? "22%" : "30%"}>Platform</StyledTableCell>
                                    {/* <StyledTableCell width={newCycle.testSpecification === SpecificationType.Website ? "22%" : "24%"}>Specific devices</StyledTableCell> */}
                                    <StyledTableCell width={newCycle.testSpecification === SpecificationType.Website ? "22%" : "30%"}>Location</StyledTableCell>
                                    {
                                        newCycle.testSpecification === SpecificationType.Website &&
                                        <StyledTableCell width={newCycle.testSpecification === SpecificationType.Website ? "22%" : "30%"}>Browsers</StyledTableCell>
                                    }
                                    <StyledTableCell width={newCycle.testSpecification === SpecificationType.Website ? "22%" : "30%"}>Number of testers</StyledTableCell>
                                    <StyledTableCell width="10%" />
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {/* First constant checker Row */}
                                <TableRow style={{ backgroundColor: "#32448308" }}>
                                    <StyledTableCell>
                                        <Typography align="left" style={{ marginTop: '7px' }} className={classes.innerTitle}>
                                            {newCycle.mainTestPlatform.name}
                                        </Typography>
                                    </StyledTableCell>

                                    {/* <StyledTableCell>
                    <Switch
                      disabled={newCycle.testSpecification === SpecificationType.NativeApp && newCycle.mainTestPlatform.id === DevicePlatform.Windows}
                      checked={newCycle.isSpecificDevices}
                      onChange={this.handlePlatformSwitch}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </StyledTableCell> */}

                                    {newCycle.isWorldwide &&
                                        <React.Fragment>
                                            {newCycle.isSpecificDevices &&
                                                <React.Fragment>
                                                    {/* Two or Three empty cells */}
                                                    <StyledTableCell>
                                                    </StyledTableCell>

                                                    {newCycle.testSpecification === SpecificationType.Website &&
                                                        <StyledTableCell>
                                                        </StyledTableCell>
                                                    }

                                                    <StyledTableCell style={{ textAlign: "center" }}>
                                                        <Typography>
                                                            Total: {totalTestersSoFar}
                                                        </Typography>
                                                    </StyledTableCell>


                                                    <StyledTableCell></StyledTableCell>

                                                </React.Fragment>
                                            }

                                            {!newCycle.isSpecificDevices &&
                                                <React.Fragment>

                                                    <StyledTableCell>
                                                        Anywhere
                                                    </StyledTableCell>

                                                    {newCycle.testSpecification === SpecificationType.Website &&
                                                        <StyledTableCell>
                                                            Relevant Browsers
                                                        </StyledTableCell>
                                                    }

                                                    <StyledTableCell>
                                                        <CounterSelector align="center"
                                                            minVal={PayoutRatesAndTestersQuota.minTestersPerCycle}
                                                            val={newCycle.numberOfTesters}
                                                            onIncrement={this.IncrementTotalTesters}
                                                            onDecrement={this.DecrementTotalTesters}
                                                        />
                                                    </StyledTableCell>

                                                    <StyledTableCell></StyledTableCell>

                                                </React.Fragment>
                                            }

                                        </React.Fragment>
                                    }

                                    {!newCycle.isWorldwide &&
                                        <React.Fragment>
                                            <StyledTableCell>
                                            </StyledTableCell>

                                            {newCycle.testSpecification === SpecificationType.Website &&
                                                <StyledTableCell>
                                                </StyledTableCell>
                                            }

                                            <StyledTableCell>
                                                <Typography>
                                                    Total: {totalTestersSoFar}
                                                </Typography>
                                            </StyledTableCell>

                                            <StyledTableCell></StyledTableCell>

                                        </React.Fragment>
                                    }
                                </TableRow>

                                {/* Specific row per country in case of selected countries */}
                                {!newCycle.isWorldwide &&
                                    <React.Fragment>
                                        {newCycle.specificationPerCountry.map((el, i) =>
                                        (
                                            <CountryTableRow
                                                key={el.id}
                                                data={el}
                                                specificationOn={newCycle.isSpecificDevices}
                                                devices={this.getAvailableDevices()}
                                                onDeviceSet={this.onDeviceSetForCountry}
                                                onIncrement={this.IncrementTestersPerCountry}
                                                onDecrement={this.DecrementTestersPerCountry}
                                                isWebsiteType={newCycle.testSpecification === SpecificationType.Website}
                                                platformName={newCycle.mainTestPlatform.name}
                                            />
                                        )
                                        )}
                                    </React.Fragment>
                                }





                                {/* Specifications from store */}
                                {/* { newCycle.isSpecificDevices
                                      && newCycle.relevantDevices.length > 0 && 
                                      <>
                                          { newCycle.relevantDevices.map((el, i) =>
                                          (
                                            <DeviceTableRow
                                              key={i}
                                              data={el}
                                              index={i}                                              
                                              isWebsiteType={newCycle.testSpecification === SpecificationType.Website}
                                              platformName={newCycle.mainTestPlatform.name}
                                              onDeleteRow={this.onDeleteSpecificDevice}
                                            />
                                          )
                                          )}
                                      </>
                                    } */}



                                {/* Editable Row */}
                                {/* { newCycle.isSpecificDevices &&
                                      <TableRow style={{borderTop:"1px"}}>
                                        <StyledTableCell style={{verticalAlign: "center"}}>                                          
                                            <Button 
                                                variant="contained"
                                                color="primary"
                                                onClick={this.onAddSpecificDevice}
                                                size="small">
                                                  Add
                                            </Button>                                          
                                        </StyledTableCell>
                                  
                                        <StyledTableCell>
                                          <FormControl error={this.state.errors.includes('Device')} className={classes.formControl}>                                            
                                            <Select 
                                              disabled={newCycle.mainTestPlatform.id === DevicePlatform.Windows}
                                              variant="standard"
                                              className={classes.SelectText}
                                              labelId="devices-edit-row"                                            
                                              MenuProps={MenuProps} value={this.state.newDevice.device.id ? this.state.newDevice.device.id : 0} onChange={this.onDeviceSelected}>
                                              {this.getAvailableDevices().map( (device, index) =>(
                                                <MenuItem className={ device.id === 0 ? classes.MenuItemTextDefault :  classes.MenuITemText} value={device.id} key={device.id}>
                                                  { device.name }
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          </FormControl>
                                        </StyledTableCell>                                                                        
                                        
                                        { !newCycle.isWorldwide && 
                                          <StyledTableCell>
                                            <FormControl error={this.state.errors.includes('Country')} className={classes.formControl}>
                                              <InputLabel id="countries-edit-row">Country</InputLabel>
                                              <Select 
                                                variant="standard"
                                                className={classes.SelectText}
                                                labelId="countries-edit-row"
                                                MenuProps={MenuProps} value={this.state.newDevice.country} onChange={this.onCountrySelected}>                                            
                                                  {this.getAvailableCountries().map( (country, index) =>(
                                                    <MenuItem className={ country === 'Anywhere' ? classes.MenuItemTextDefault :  classes.MenuITemText} value={country} key={country + index}>
                                                      {country}
                                                    </MenuItem>
                                                  ))}
                                                </Select>
                                              </FormControl>
                                          </StyledTableCell>
                                        }
                                        { newCycle.isWorldwide &&
                                          <StyledTableCell>
                                            <Typography>
                                              Anywhere
                                            </Typography>
                                          </StyledTableCell>
                                        }

                                      { newCycle.testSpecification === SpecificationType.Website && 
                                        <StyledTableCell style={{verticalAlign:"center"}}>
                                          <Select
                                            variant="standard"
                                            className={classes.SelectText}
                                            MenuProps={MenuProps} value={this.state.newDevice.browser.id} onChange={this.onBrowserSelected}>
                                            {this.getAvailableBrowsers().map( (browser, index) =>(
                                              <MenuItem className={ browser.id === 0 ? classes.MenuItemTextDefault :  classes.MenuITemText} value={browser.id} key={browser.id}>
                                                {browser.name}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </StyledTableCell>
                                      }                                       
                                        <StyledTableCell style={{verticalAlign: "center"}}>
                                            <CounterSelector
                                              minVal={PayoutRatesAndTestersQuota.minTestersPerSpecification}
                                              val={this.state.newDevice.numberOfTesters} 
                                              onIncrement={this.IncrementTestersPerDeviceType}
                                              onDecrement={this.DecrementTestersPerDeviceType}
                                              />
                                        </StyledTableCell>

                                        <StyledTableCell />

                                     </TableRow>
                                    } */}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

            </React.Fragment>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DevicesFormSlim)));