import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Input, Checkbox } from 'semantic-ui-react';
import cycleActions from '../../redux/actions/cycleActions';

const mapStateToProps = (state) => {
  return {
    newCycle: state.newCycle,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCycle: (data) => dispatch(cycleActions.updateCycle(data)),
  };
};

const ourQuestions = [
  'Did you find it easy to use and quick to navigate?',
  'Would you recommend it to others? (Please give your reasons)',
  'Would you use it yourself ? (Please give your reasons)',
  'To what extent do you think it is viral on a scale between 1-10 (where 1 is the lowest)?',
  'What was the most frustrating thing about your experience?',
  'What other ideas do you have about how it could be improved?',
  'What did you like about it?',
];

class QuestionsBlock extends Component<any> {
  public state: any;

  constructor(props) {
    super(props);
    this.state = {
      newQustion: '',
      editQuestionKey: null,
      editQuestionValue: null,
      ourQuestionsVisible: false,
      ourQuestionsKeys: [],
    };
  }

  onEnableEditQuestion = (event, data) => {
    event.preventDefault();

    this.setState({
      editQuestionKey: data['data-key'],
      editQuestionValue: data['data-value'],
    });
  };

  onEditQuestion = (event, data) => {
    event.preventDefault();

    this.setState({
      editQuestionValue: data.value,
    });
  };

  onSaveEditedQuestion = (event) => {
    event.preventDefault();

    let newCycle = { ...this.props.newCycle };
    newCycle.questions[this.state.editQuestionKey] = this.state.editQuestionValue;
    this.props.updateCycle(newCycle);

    this.setState({
      editQuestionKey: null,
      editQuestionValue: null,
    });
  };

  onDeleteQuestion = (event, data) => {
    event.preventDefault();

    let newCycle = { ...this.props.newCycle };
    newCycle.questions.splice(data['data-key'], 1);
    this.props.updateCycle(newCycle);

    this.setState({
      editQuestionKey: null,
    });
  };

  onChangeNewQuestion = (event, data) => {
    this.setState({
      newQustion: data.value,
    });
  };

  onAddNewQuestion = (event) => {
    event.preventDefault();

    if (!this.state.newQustion.length) {
      return;
    }

    let newCycle = { ...this.props.newCycle };
    newCycle.questions.push(this.state.newQustion);

    this.setState(
      {
        newQustion: '',
      },
      () => {
        this.props.updateCycle(newCycle);
      }
    );
  };

  onOurQuestiosnVisibleToggle = (event) => {
    event.preventDefault();

    this.setState({
      ourQuestionsVisible: !this.state.ourQuestionsVisible,
    });
  };

  onOurQuestionToggle = (event, data) => {
    event.preventDefault();

    if (this.state.ourQuestionsKeys.includes(data.key)) {
      let ourQuestionsKeys = [...this.state.ourQuestionsKeys];
      ourQuestionsKeys.splice(data['data-key'], 1);
      this.setState({
        ourQuestionsKeys,
      });
    } else {
      this.setState({
        ourQuestionsKeys: [...this.state.ourQuestionsKeys, data['data-key']],
      });
    }
  };

  onAddOurQuestions = (event, data) => {
    event.preventDefault();

    let newCycle = { ...this.props.newCycle };

    this.state.ourQuestionsKeys.forEach((key) => {
      newCycle.questions.push(ourQuestions[key]);
    });

    this.setState(
      {
        ourQuestionsKeys: [],
        ourQuestionsVisible: false,
      },
      () => {
        this.props.updateCycle(newCycle);
      }
    );
  };

  render() {
    const ourQuestionsVisible = this.state.ourQuestionsVisible ? 'visible' : '';

    return (
      <div className="cycle-questions">
        <p>Specific questions for testers to answer</p>

        {this.props.newCycle.questions.map((el, i) => (
          <div key={i} className="question">
            {this.state.editQuestionKey === i ? (
              <div>
                <Input
                  size="mini"
                  value={this.state.editQuestionValue}
                  onChange={this.onEditQuestion}
                />
                <Button
                  positive
                  size="mini"
                  className="save-btn"
                  data-key={i}
                  onClick={this.onSaveEditedQuestion}
                >
                  Save
                </Button>
                <Button
                  size="mini"
                  className="delete-btn"
                  onClick={this.onDeleteQuestion}
                  data-key={i}
                >
                  Delete
                </Button>
              </div>
            ) : (
              <div>
                <span>{el}</span>
                <Button
                  size="mini"
                  className="edit-btn"
                  onClick={this.onEnableEditQuestion}
                  data-key={i}
                  data-value={el}
                >
                  Edit
                </Button>
                <Button
                  size="mini"
                  className="delete-btn"
                  onClick={this.onDeleteQuestion}
                  data-key={i}
                >
                  Delete
                </Button>
              </div>
            )}
          </div>
        ))}

        <div className="new-question">
          <Input size="mini" value={this.state.newQustion} onChange={this.onChangeNewQuestion} />
          <Button positive size="mini" onClick={this.onAddNewQuestion}>
            Add
          </Button>
        </div>

        <div className="our-questions">
          <Button
            positive
            size="mini"
            className="toggle-btn"
            onClick={this.onOurQuestiosnVisibleToggle}
          >
            Our questions
          </Button>

          <div className={`questions-list ${ourQuestionsVisible}`}>
            {ourQuestions.map((el, i) => (
              <Checkbox
                key={i}
                data-key={i}
                label={el}
                checked={this.state.ourQuestionsKeys.includes(i)}
                onClick={this.onOurQuestionToggle}
              />
            ))}

            <Button
              positive
              size="mini"
              className="add-selected-btn"
              onClick={this.onAddOurQuestions}
            >
              Add selected
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuestionsBlock));
