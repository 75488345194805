import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Dimmer, Loader, Message, Input, TextArea, Select, Button, Icon } from 'semantic-ui-react';
import _ from 'lodash';
import Dropzone from 'react-dropzone';
import CycleDetailsSidemenu from './Sidemenu';
import { BugAttachments } from '../../../Cycles/Bugs/BugAttachments';
import bugSeverities from '../../../../util/options/bugSeverities';
import { OperationStatus } from '../../../../types/OperationStatus';
import { CycleStatus } from '../../../../types/CycleStatus';
import actions from '../../../../redux/actions';
import config from '../../../../config';
import { getApprovedDevicePrettyName } from '../../../../services/deviceService';
import * as utils from '../../../../util/utils';

const mapStateToProps = (state) => {
  return {
    user: state.user,
    cycles: state.cycles,
    devices: state.devices,
    editBug: state.editBug,
    cycleBugs: state.cycleBugs,
    editBugStatus: state.editBugStatus,
    device: state.resolution.device,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateBug: (cycleId, bugId, bugData) => dispatch(actions.updateBug(cycleId, bugId, bugData)),
    updateEditBug: (editBug) => dispatch(actions.updateEditBug(editBug)),
  };
};

class EdlitBug extends Component<any> {
  public state: any;

  constructor(props) {
    super(props);

    const cycleId = parseInt(props.match.params.cycleId);
    const cycle = props.cycles.find((el) => el.id === cycleId);

    this.state = {
      cycle,
      bugId: parseInt(props.match.params.bugId),
      editBug: {
        ...props.editBug,
      },
      errors: new Set(),
      totalNewFilesSize: 0,
      totalExistingFilesSize: 0,
    };
  }

  componentDidMount() {
    const editBug = this.props.cycleBugs.byCycleId[this.state.cycle.id].find(
      (bug) => bug.id === this.state.bugId
    );
    this.setState({
      editBug: {
        ...editBug,
        imageFiles: [],
        videoFiles: [],
      },
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevState.editBug.imageFilePaths.length !== this.state.editBug.imageFilePaths.length ||
      prevState.editBug.videoFilePaths.length !== this.state.editBug.videoFilePaths.length
    ) {
      const totalExistingFilesSize = await this.recalculateExistingFilesSize();
      this.setState({ totalExistingFilesSize });
    }

    if (prevProps.editBugStatus.status !== this.props.editBugStatus.status) {
      if (this.props.editBugStatus.status === OperationStatus.success) {
        this.props.history.push(`/account/tester/cycle/${this.state.cycle.id}/bugs`);
      }
    }
  }

  recalculateExistingFilesSize = async (): Promise<number> => {
    let existingFilesSize = 0;

    for (const imageFilePath of this.state.editBug.imageFilePaths) {
      const response = await fetch(imageFilePath, { method: 'HEAD' });
      const size = response.headers.get('Content-Length');
      existingFilesSize += parseInt(size!);
    }

    for (const videoFilePath of this.state.editBug.videoFilePaths) {
      const response = await fetch(videoFilePath, { method: 'HEAD' });
      const size = response.headers.get('Content-Length');
      existingFilesSize += parseInt(size!);
    }

    return existingFilesSize;
  };

  resetErrorState = (event) => {
    const name = event.target.name;
    const errors = new Set(this.state.errors);

    if (errors.has(name)) {
      errors.delete(name);
      this.setState({ errors });
    }
  };

  onInput = (event, data) => {
    const editBug = { ...this.state.editBug };

    editBug[data.name] = data.value;
    this.setState({ editBug });
  };

  validateInput = (event, data) => {
    const errors = new Set(this.state.errors);
    const name = data && data.name ? data.name : event.target.name;
    const value = data && data.value ? data.value : event.target.value;

    if (!value || !value.length) {
      errors.add(name);
    } else {
      errors.delete(name);
    }

    this.setState({ errors });
  };

  onImageUpload = (acceptedImageFiles) => {
    const imageFiles = [...this.state.editBug.imageFiles];

    const totalImagesFileSize = imageFiles.reduce((result: number, file: any) => {
      result += file.size;
      return result;
    }, 0);

    const totalVideoFileSize = this.state.editBug.videoFiles.reduce((result: number, file: any) => {
      result += file.size;
      return result;
    }, 0);

    let totalNewFilesSize = totalImagesFileSize + totalVideoFileSize;

    for (let file of acceptedImageFiles) {
      totalNewFilesSize += file.size;

      const isImage = utils.isImage(file.name);
      const ext = utils.getFileExtension(file.name);

      file = Object.assign(file, {
        preview: isImage ? URL.createObjectURL(file) : null,
        isImage,
        lowercaseName: file.name.toLowerCase(),
        ext,
      });

      imageFiles.push(file);
    }

    this.setState({
      editBug: {
        ...this.state.editBug,
        imageFiles,
      },
      totalNewFilesSize,
    });
  };

  onUndoImageUpload = (event, data) => {
    event.stopPropagation();

    const imageFiles = [...this.state.editBug.imageFiles].filter(
      (el, i) => i !== parseInt(data['data-index'], 10)
    );

    const totalImagesFileSize = imageFiles.reduce((result: number, file: any) => {
      result += file.size;
      return result;
    }, 0);

    const totalVideoFileSize = this.state.editBug.videoFiles.reduce((result: number, file: any) => {
      result += file.size;
      return result;
    }, 0);

    let totalNewFilesSize = totalImagesFileSize + totalVideoFileSize;

    this.setState({
      editBug: {
        ...this.state.editBug,
        imageFiles,
      },
      totalNewFilesSize,
    });
  };

  onDeleteImage = (event, data) => {
    const imageFilePaths = [...this.state.editBug.imageFilePaths];
    _.pullAt(imageFilePaths, data['data-index']);

    this.setState({
      editBug: {
        ...this.state.editBug,
        imageFilePaths,
      },
    });
  };

  onVideoUpload = (acceptedVideoFiles) => {
    const videoFiles = [...this.state.editBug.videoFiles];

    const totalImagesFileSize = this.state.editBug.imageFiles.reduce(
      (result: number, file: any) => {
        result += file.size;
        return result;
      },
      0
    );

    const totalVideoFileSize = videoFiles.reduce((result: number, file: any) => {
      result += file.size;
      return result;
    }, 0);

    let totalNewFilesSize = totalImagesFileSize + totalVideoFileSize;

    acceptedVideoFiles.forEach((file) => {
      totalNewFilesSize += file.size;

      file = Object.assign(file, {
        preview: URL.createObjectURL(file),
        lowercaseName: file.name.toLowerCase(),
      });

      videoFiles.push(file);
    });

    this.setState({
      editBug: {
        ...this.state.editBug,
        videoFiles,
      },
      totalNewFilesSize,
    });
  };

  onUndoVideoUpload = (event, data) => {
    event.stopPropagation();

    const videoFiles = [...this.state.editBug.videoFiles].filter(
      (el, i) => i !== parseInt(data['data-index'], 10)
    );

    const totalImagesFileSize = this.state.editBug.imageFiles.reduce(
      (result: number, file: any) => {
        result += file.size;
        return result;
      },
      0
    );

    const totalVideoFileSize = videoFiles.reduce((result: number, file: any) => {
      result += file.size;
      return result;
    }, 0);

    let totalNewFilesSize = totalImagesFileSize + totalVideoFileSize;

    this.setState({
      editBug: {
        ...this.state.editBug,
        videoFiles,
      },
      totalNewFilesSize,
    });
  };

  onDeleteVideo = (event, data) => {
    const videoFilePaths = [...this.state.editBug.videoFilePaths];
    _.pullAt(videoFilePaths, data['data-index']);

    this.setState({
      editBug: {
        ...this.state.editBug,
        videoFilePaths,
      },
    });
  };

  onSubmitBugUpdate = () => {
    const editBug = this.state.editBug;

    const bugProps: Record<string, any> = {
      topic: editBug.topic,
      stepsToReproduce: editBug.stepsToReproduce,
      expectedResult: editBug.expectedResult,
      actualResult: editBug.actualResult,
      comment: editBug.comment,
      severity: editBug.severity,
    };

    if (editBug.testPlanStepId) {
      bugProps.testPlanStepId = editBug.testPlanStepId;
    }

    // TODO remove these two once backend is ready
    if (editBug.imageFilePaths) {
      bugProps.imageFilePaths = editBug.imageFilePaths;
    }

    if (editBug.videoFilePaths) {
      bugProps.videoFilePaths = editBug.videoFilePaths;
    }

    const bugData = {
      bugProps,
      files: {
        imageFiles: editBug.imageFiles,
        videoFiles: editBug.videoFiles,
      },
    };

    this.props.updateBug(this.state.cycle.id, this.state.bugId, bugData);
  };

  render() {
    const editBug = this.state.editBug;

    if (!editBug) {
      return null;
    }

    const cycle = this.state.cycle;

    const isCycleCompleted = cycle.status === CycleStatus.Completed;
    const deviceName = getApprovedDevicePrettyName(this.state.cycle.approvedDevice);

    const bugEditProcessing = this.props.editBugStatus.status === OperationStatus.processing;

    const bugWithStep = editBug.testPlanStepId ? true : false;

    const errors = this.state.errors;

    const totalFilesSize = this.state.totalNewFilesSize + this.state.totalExistingFilesSize;
    const totalFilesSizeMB = totalFilesSize === 0 ? 0 : (totalFilesSize / 1024 / 1024).toFixed(2);

    const maxAttachmentsSizeMB = config.maxAttachmentsSizeMB;

    const attachmentsAreTooHeavy = totalFilesSizeMB > maxAttachmentsSizeMB;

    const isMobile = this.props.device === 'mobile' || this.props.device === 'mobile-tablet';

    if( isMobile ){
      return (
        <div className="account-tester page">
          <CycleDetailsSidemenu cycleId={cycle.id} />        

        <div className="inner" style={{ display: 'flex' }}>
          {/* <CycleDetailsSidemenu cycleId={cycle.id} /> */}

          <main className="cycle-newbug">
            {isCycleCompleted && (
              <Message info>
                <Message.Header>This cycle has finished</Message.Header>
                <Link to="/account/tester">Go to dashboard</Link>
              </Message>
            )}
            <h2>Edit Bug #{editBug.publicId}</h2>
            <h4>Device: {deviceName}</h4>

            <label>
              Topic
              <Input
                name="topic"
                value={editBug.topic}
                onChange={this.onInput}
                onFocus={this.resetErrorState}
                onBlur={this.validateInput}
                error={errors.has('topic')}
              />
            </label>
            <label>
              Steps to reproduce
              <TextArea
                name="stepsToReproduce"
                value={editBug.stepsToReproduce}
                onChange={this.onInput}
                onFocus={this.resetErrorState}
                onBlur={this.validateInput}
                className={errors.has('stepsToReproduce') ? 'error' : ''}
                disabled={bugWithStep}
              />
            </label>
            <label>
              Expected result
              <TextArea
                name="expectedResult"
                value={editBug.expectedResult}
                onChange={this.onInput}
                onFocus={this.resetErrorState}
                onBlur={this.validateInput}
                className={errors.has('expectedResult') ? 'error' : ''}
                disabled={bugWithStep}
              />
            </label>
            <label>
              Actual result
              <TextArea
                name="actualResult"
                value={editBug.actualResult}
                onChange={this.onInput}
                onFocus={this.resetErrorState}
                onBlur={this.validateInput}
                className={errors.has('actualResult') ? 'error' : ''}
              />
            </label>
            <label>
              Severity
              <Select
                className="severity"
                name="severity"
                placeholder="Select"
                options={bugSeverities}
                value={editBug.severity}
                onChange={this.onInput}
                onFocus={this.resetErrorState}
                onBlur={this.validateInput}
                error={errors.has('severity')}
              />
            </label>
            <label>
              Additional info
              <TextArea name="comment" value={editBug.comment} onChange={this.onInput} />
            </label>

            {isCycleCompleted ? (
              <div
                style={{
                  borderRadius: '4px',
                  background: '#f1f1f1',
                  padding: '1rem',
                }}
              >
                <BugAttachments
                  imageFilePaths={editBug.imageFilePaths}
                  videoFilePaths={editBug.videoFilePaths}
                />
              </div>
            ) : (
              <div
                style={{
                  borderRadius: '4px',
                  background: '#f1f1f1',
                  padding: '1rem',
                }}
              >
                <BugAttachments
                  imageFilePaths={editBug.imageFilePaths}
                  videoFilePaths={editBug.videoFilePaths}
                  onDeleteImage={this.onDeleteImage}
                  onDeleteVideo={this.onDeleteVideo}
                />
              </div>
            )}

            <div className="bug-uploadsSlim">
              <Dropzone onDrop={this.onImageUpload} multiple={true}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Button className="upload" content="Upload attachment" positive />
                    {editBug.imageFiles.map((file, i) => (
                      <div className="bug-attachment" key={i}>
                        {file.preview ? (
                          <img src={file.preview} alt="bug attachment preview" />
                        ) : (
                          <Icon
                            style={{
                              margin: '1rem auto',
                            }}
                            name="file alternate outline"
                            size="huge"
                          />
                        )}

                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <Button
                            basic
                            className="undo-upload"
                            size="mini"
                            icon="close"
                            onClick={this.onUndoImageUpload}
                            data-index={i}
                          />
                          <span
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {file.lowercaseName}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </Dropzone>

              <Dropzone
                onDrop={this.onVideoUpload}
                multiple={true}
                maxSize={75000000}
                accept="video/mp4"
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Button className="upload" content="Upload video" positive />
                    {editBug.videoFiles.map((videoFile, i) => (
                      <span className="bug-attachment" key={i}>
                        <video controls width="240">
                          <source src={videoFile.preview} type="video/mp4" />
                        </video>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <Button
                            basic
                            color="red"
                            className="undo-upload"
                            size="mini"
                            icon="trash alternate"
                            onClick={this.onUndoVideoUpload}
                            data-index={i}
                          />
                          <span
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {videoFile.lowercaseName}
                          </span>
                        </div>
                      </span>
                    ))}
                  </div>
                )}
              </Dropzone>

              <p className="attachments-size">
                Attachments size:
                <br /> {totalFilesSizeMB}/{maxAttachmentsSizeMB} mb
              </p>
            </div>

            {
              <div>
                {attachmentsAreTooHeavy && (
                  <Message
                    negative
                    content={`Attachments are ${totalFilesSizeMB} MB total. Maximum ${maxAttachmentsSizeMB} MB allowed`}
                  />
                )}

                <Button
                  primary
                  disabled={
                    bugEditProcessing || attachmentsAreTooHeavy || this.state.errors.size > 0
                  }
                  onClick={this.onSubmitBugUpdate}
                  content="Save changes"
                />
              </div>
            }

            <Dimmer active={bugEditProcessing} inverted page>
              <Loader>Submitting bug...</Loader>
            </Dimmer>
          </main>
        </div>
      </div>        
      );  
    }
    return (
      <div className="account-tester page">
        <div className="heading">
          <h1>Edit Bug</h1>
        </div>

        <div className="inner" style={{ display: 'flex' }}>
          <CycleDetailsSidemenu cycleId={cycle.id} />

          <main className="cycle-newbug">
            {isCycleCompleted && (
              <Message info>
                <Message.Header>This cycle has finished</Message.Header>
                <Link to="/account/tester">Go to dashboard</Link>
              </Message>
            )}
            <h2>Edit Bug #{editBug.publicId}</h2>
            <h4>Device: {deviceName}</h4>

            <label>
              Topic
              <Input
                name="topic"
                value={editBug.topic}
                onChange={this.onInput}
                onFocus={this.resetErrorState}
                onBlur={this.validateInput}
                error={errors.has('topic')}
              />
            </label>
            <label>
              Steps to reproduce
              <TextArea
                name="stepsToReproduce"
                value={editBug.stepsToReproduce}
                onChange={this.onInput}
                onFocus={this.resetErrorState}
                onBlur={this.validateInput}
                className={errors.has('stepsToReproduce') ? 'error' : ''}
                disabled={bugWithStep}
              />
            </label>
            <label>
              Expected result
              <TextArea
                name="expectedResult"
                value={editBug.expectedResult}
                onChange={this.onInput}
                onFocus={this.resetErrorState}
                onBlur={this.validateInput}
                className={errors.has('expectedResult') ? 'error' : ''}
                disabled={bugWithStep}
              />
            </label>
            <label>
              Actual result
              <TextArea
                name="actualResult"
                value={editBug.actualResult}
                onChange={this.onInput}
                onFocus={this.resetErrorState}
                onBlur={this.validateInput}
                className={errors.has('actualResult') ? 'error' : ''}
              />
            </label>
            <label>
              Severity
              <Select
                className="severity"
                name="severity"
                placeholder="Select"
                options={bugSeverities}
                value={editBug.severity}
                onChange={this.onInput}
                onFocus={this.resetErrorState}
                onBlur={this.validateInput}
                error={errors.has('severity')}
              />
            </label>
            <label>
              Additional info
              <TextArea name="comment" value={editBug.comment} onChange={this.onInput} />
            </label>

            {isCycleCompleted ? (
              <div
                style={{
                  borderRadius: '4px',
                  background: '#f1f1f1',
                  padding: '1rem',
                }}
              >
                <BugAttachments
                  imageFilePaths={editBug.imageFilePaths}
                  videoFilePaths={editBug.videoFilePaths}
                />
              </div>
            ) : (
              <div
                style={{
                  borderRadius: '4px',
                  background: '#f1f1f1',
                  padding: '1rem',
                }}
              >
                <BugAttachments
                  imageFilePaths={editBug.imageFilePaths}
                  videoFilePaths={editBug.videoFilePaths}
                  onDeleteImage={this.onDeleteImage}
                  onDeleteVideo={this.onDeleteVideo}
                />
              </div>
            )}

            <div className="bug-uploads">
              <Dropzone onDrop={this.onImageUpload} multiple={true}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Button className="upload" content="Upload attachment" positive />
                    {editBug.imageFiles.map((file, i) => (
                      <div className="bug-attachment" key={i}>
                        {file.preview ? (
                          <img src={file.preview} alt="bug attachment preview" />
                        ) : (
                          <Icon
                            style={{
                              margin: '1rem auto',
                            }}
                            name="file alternate outline"
                            size="huge"
                          />
                        )}

                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <Button
                            basic
                            className="undo-upload"
                            size="mini"
                            icon="close"
                            onClick={this.onUndoImageUpload}
                            data-index={i}
                          />
                          <span
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {file.lowercaseName}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </Dropzone>

              <Dropzone
                onDrop={this.onVideoUpload}
                multiple={true}
                maxSize={75000000}
                accept="video/mp4"
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Button className="upload" content="Upload video" positive />
                    {editBug.videoFiles.map((videoFile, i) => (
                      <span className="bug-attachment" key={i}>
                        <video controls width="240">
                          <source src={videoFile.preview} type="video/mp4" />
                        </video>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <Button
                            basic
                            color="red"
                            className="undo-upload"
                            size="mini"
                            icon="trash alternate"
                            onClick={this.onUndoVideoUpload}
                            data-index={i}
                          />
                          <span
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {videoFile.lowercaseName}
                          </span>
                        </div>
                      </span>
                    ))}
                  </div>
                )}
              </Dropzone>

              <p className="attachments-size">
                Attachments size:
                <br /> {totalFilesSizeMB}/{maxAttachmentsSizeMB} mb
              </p>
            </div>

            {
              <div>
                {attachmentsAreTooHeavy && (
                  <Message
                    negative
                    content={`Attachments are ${totalFilesSizeMB} MB total. Maximum ${maxAttachmentsSizeMB} MB allowed`}
                  />
                )}

                <Button
                  primary
                  disabled={
                    bugEditProcessing || attachmentsAreTooHeavy || this.state.errors.size > 0
                  }
                  onClick={this.onSubmitBugUpdate}
                  content="Save changes"
                />
              </div>
            }

            <Dimmer active={bugEditProcessing} inverted page>
              <Loader>Submitting bug...</Loader>
            </Dimmer>
          </main>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EdlitBug));
