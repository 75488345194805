import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Button, Icon, Input, Radio } from 'semantic-ui-react';
import _ from 'lodash';
import QuestionsBlock from './QuestionsBlock';
import TestPlanBlock from './TestPlanBlock';
import { WorkCategory } from '../../types/WorkCategory';
import { NewCycle } from '../../types/NewCycle';
import cycleActions from '../../redux/actions/cycleActions';
import { getPlatformPrettyName } from '../../services/deviceService';
import {
  Platform,
  MobileDevice,
  DesktopBrowser,
  isMobileDevice,
  isDesktopBrowser,
} from '../../types/TestingEnvironment';
import ReactQuill from 'react-quill';

const Wtf = styled.div`
  h1,
  h2,
  h3 {
    color: initial;
    margin: 10px;
  }
  h4,
  h5,
  h6 {
    color: initial;
    margin: 5px;
  }
`;

const mapStateToProps = (store) => {
  return {
    newCycle: store.newCycle,
    errors: store.newCycleCurrentStepErrors,
    devices: store.devices,
    browsers: store.browsers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    prevStep: () => dispatch(cycleActions.switchNewCycleStep(1)),
    nextStep: () => dispatch(cycleActions.switchNewCycleStep(3)),
    updateCycle: (data) => dispatch(cycleActions.updateCycle(data)),
  };
};

class StepTwo extends Component<any> {
  public state: any;

  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      quillValue: props.cycle,
    };
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.errors, this.props.errors)) {
      this.setState({ errors: this.props.errors });
    }
  }

  resetErrorStateForInput = (event, data) => {
    if (this.state.errors.includes(data.name)) {
      const errors = [...this.state.errors];

      errors.splice(this.state.errors.indexOf(data.name), 1);
      this.setState({ errors });
    }
  };

  onChange = (event, data) => {
    const newCycle = { ...this.props.newCycle };

    switch (data.name) {
      case 'platformUrls': {
        const idx = newCycle.platformUrls.findIndex(
          (el) => el.platformId === data['data-platform-id']
        );
        newCycle.platformUrls[idx].url = data.value;
        const newCycleJson = JSON.stringify(newCycle);
        localStorage.setItem('newCycle', newCycleJson);
        return this.props.updateCycle(newCycle);
      }

      case 'workCategory':
        newCycle.workCategory = data.value;

        if (data.value === WorkCategory.FindBugsExploratory) {
          newCycle.testPlan = [];
          const newCycleJson = JSON.stringify(newCycle);
          localStorage.setItem('newCycle', newCycleJson);
          this.props.updateCycle(newCycle);
        }

        break;
      case 'hoursPerTester': {
        const hoursPerTester = parseInt(data.value, 10);
        if (hoursPerTester < 1) {
          return;
        }

        newCycle.hoursPerTester = hoursPerTester;
        const newCycleJson = JSON.stringify(newCycle);
        localStorage.setItem('newCycle', newCycleJson);
        this.props.updateCycle(newCycle);
        break;
      }

      default:
        newCycle[data.name] = data.value;
        break;
    }

    const newCycleJson = JSON.stringify(newCycle);
    localStorage.setItem('newCycle', newCycleJson);
    this.props.updateCycle(newCycle);
  };

  onUpdateDescription = (value) => {
    const newCycle = { ...this.props.newCycle };
    newCycle.description = value;

    const newCycleJson = JSON.stringify(newCycle);
    localStorage.setItem('newCycle', newCycleJson);
    this.props.updateCycle(newCycle);
    this.setState({ quillValue: value });
  };

  onRequiredTestersChange = (event, data) => {
    const requiredTesters = parseInt(data.value, 10);
    const newCycle = this.props.newCycle;

    if (requiredTesters < 1) {
      return;
    }

    const testEnvs = [...this.props.newCycle.testEnvs];

    let index;

    switch (data['data-type']) {
      case 'Platform':
        index = testEnvs.findIndex(
          (el) => el.type === data['data-type'] && el.platformId === data['data-id']
        );
        break;
      case 'MobileDevice':
        index = testEnvs.findIndex(
          (el) => el.type === data['data-type'] && el.modelId === data['data-id']
        );
        break;
      case 'DesktopBrowser':
        index = testEnvs.findIndex(
          (el) => el.type === data['data-type'] && el.browserId === data['data-id']
        );
        break;
    }

    testEnvs[index].requiredTesters = requiredTesters;
    newCycle.testEnvs = testEnvs;
    const newCycleJson = JSON.stringify(newCycle);
    localStorage.setItem('newCycle', newCycleJson);
    this.props.updateCycle({ testEnvs });
  };

  onCheckUrl = (event, data) => {
    let url = data.value;

    if (!url.length) {
      return;
    }

    const regexp = /^(ftp|http|https):\/\/*/;

    if (regexp.test(data.value) === false) {
      url = 'http://' + url;
    }

    window.open(url, '_blank');
  };

  addSampleGuideline = (event) => {
    event.preventDefault();

    const newCycle = this.props.newCycle;
    const description =
      this.props.newCycle.description.trim() +
      `<h3>Guideline:<h3><ul>
<li>Report crashes and errors</li>
<li>Report app/site not functioning as expected</li>
<li>Report when app takes far too long to load</li>
<li>Report when app drains your battery</li>
<li>Report when encountering wrong or misleading translations</li>
<li>Report cut text or graphics</li>
<li>Report on encountering broken links</li>
</ul>`;
    newCycle.description = description;
    const newCycleJson = JSON.stringify(newCycle);
    localStorage.setItem('newCycle', newCycleJson);
    this.props.updateCycle({
      description,
    });
  };

  onNextStep = () => {
    this.props.nextStep(this.props.newCycle);
  };

  onPrevStep = () => {
    this.props.prevStep(this.props.newCycle);
  };

  getTestEnvIdForRequiredTestersInput = (testEnv) => {
    let id;

    // Type assertion is required here because of TS design limitations
    // https://github.com/microsoft/TypeScript/issues/10934
    if (isMobileDevice(testEnv)) {
      id = (testEnv as MobileDevice).modelId;
    } else if (isDesktopBrowser(testEnv)) {
      id = (testEnv as DesktopBrowser).browserId;
    } else {
      id = (testEnv as Platform).platformId;
    }

    return id;
  };

  render() {
    const newCycle: NewCycle = this.props.newCycle;

    const totalTesters = newCycle.testEnvs.reduce((result, el) => {
      result += el.requiredTesters;
      return result;
    }, 0);

    // TODO consider using financeService.calculateCycleTotalCost. Or kill it
    const totalCost = totalTesters * newCycle.hoursPerTester * newCycle.hourlyRate;

    const testingApproachBlockVisible = newCycle.workCategory !== WorkCategory.FeedbackAndRating;
    const questionsBlockVisible = newCycle.workCategory === WorkCategory.FeedbackAndRating;
    const testPlanBlockVisible = newCycle.workCategory === WorkCategory.FindBugsTestPlan;

    return (
      <div className="cycle-step cycle-step2">
        <aside>
          <table className="num-testers-table">
            <thead>
              <tr>
                <th colSpan={2}>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Number of testers</td>
                <td>{totalTesters}</td>
              </tr>
              <tr>
                <td>Cost per tester</td>
                <td>${newCycle.hourlyRate}</td>
              </tr>
              <tr>
                <td>Total cost</td>
                <td>$ {totalCost}</td>
              </tr>
            </tbody>
          </table>

          <form className="left-side-form">
            <div className="how-many-testers">
              <h3>How many testers</h3>
              {newCycle.testEnvs.map((testEnv, i) => {
                const id = this.getTestEnvIdForRequiredTestersInput(testEnv);

                return (
                  <label key={i}>
                    {testEnv.name}
                    <Input
                      type="number"
                      data-type={testEnv.type}
                      data-id={id}
                      value={testEnv.requiredTesters}
                      onChange={this.onRequiredTestersChange}
                    />
                  </label>
                );
              })}

              <hr />
              <label>
                How many hours per tester
                <Input
                  type="number"
                  name="hoursPerTester"
                  value={newCycle.hoursPerTester}
                  onChange={this.onChange}
                />
              </label>
            </div>
          </form>

          <Button
            icon
            className="next-step-button nav-button"
            size="huge"
            onClick={this.onNextStep}
            labelPosition="right"
          >
            <Icon name="arrow right" />
            Next step
          </Button>

          <Button icon className="nav-button" onClick={this.onPrevStep} labelPosition="right">
            <Icon name="arrow left" />
            Back
          </Button>
        </aside>

        <div className="cycle-details">
          <h2>Project details</h2>
          <div className="cycle-details-form">
            <div style={{ display: 'flex' }}>
              <div className="project-title">
                <label>Project title</label>
                <Input
                  name="title"
                  value={newCycle.title}
                  placeholder="Provide a title for your project"
                  onChange={(event, data) => {
                    this.onChange(event, data);
                    this.resetErrorStateForInput(event, data);
                  }}
                  error={this.state.errors.includes('title')}
                />
              </div>
              <div className="version">
                <label>Version</label>
                <Input
                  name="version"
                  value={newCycle.version}
                  placeholder="Version"
                  onChange={this.onChange}
                  error={this.state.errors.includes('version')}
                />
              </div>
            </div>
            <Wtf className="">
              <label>Project description and test guidelines</label>
              <div style={{ fontSize: '80%' }}>
                Describe your project and provide test guidelines
              </div>
              <ReactQuill
                theme="snow"
                value={newCycle.description}
                onChange={(content) => this.onUpdateDescription(content)}
              />
              {/* <TextArea
                name="description"
                value={newCycle.description}
                placeholder="Describe your project and provide test guidelines"
                onChange={this.onChange}
                className={this.state.errors.includes('description') ? 'error' : ''}
              /> */}
              <div>
                <Button size="tiny" className="sample-guideline" onClick={this.addSampleGuideline}>
                  Sample guideline
                </Button>
              </div>
            </Wtf>

            <div className="download-url">
              <label>Product site or application download URL</label>

              {newCycle.platformUrls.map((el, i) => {
                return (
                  <div key={i} className="platform-url">
                    <Input
                      size="small"
                      name="platformUrls"
                      data-platform-id={el.platformId}
                      value={el.url}
                      placeholder={`Download URL for ${getPlatformPrettyName(el.platformId)}`}
                      onChange={this.onChange}
                      action={
                        <Button
                          attached="right"
                          size="small"
                          positive
                          compact
                          content="Test link"
                          onClick={this.onCheckUrl}
                          value={el.url}
                        />
                      }
                    />
                  </div>
                );
              })}
            </div>

            {testingApproachBlockVisible && (
              <div className="testing-approach">
                <label>Testing approach</label>
                <Radio
                  name="workCategory"
                  value={WorkCategory.FindBugsExploratory}
                  label="Exploratory"
                  checked={newCycle.workCategory === WorkCategory.FindBugsExploratory}
                  onChange={this.onChange}
                />
                <Radio
                  name="workCategory"
                  value={WorkCategory.FindBugsTestPlan}
                  label="Testing plan"
                  checked={newCycle.workCategory === WorkCategory.FindBugsTestPlan}
                  onChange={this.onChange}
                />
              </div>
            )}

            {questionsBlockVisible && (
              <div className={'questions-block'}>
                <QuestionsBlock />
              </div>
            )}

            {testPlanBlockVisible && (
              <div className={'questions-block'}>
                <TestPlanBlock />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StepTwo));
