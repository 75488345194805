import React from 'react';
import aboutusImg from '../public/images/aboutus.jpg';
import playtikaLogo from '../public/images/playtika.png';
import corticaLogo from '../public/images/cortica.png';
import matrixLogo from '../public/images/matrix.png';
import byxLogo from '../public/images/byx.png';
import chosenfmLogo from '../public/images/chosen.fm.png';
import dvtelLogo from '../public/images/dvtel.png';
import keeprzLogo from '../public/images/keeprz.png';
import mapaLogo from '../public/images/mapa.png';
import mysizeidLogo from '../public/images/mysizeid.png';
import holdingsLogo from '../public/images/888holdings.gif';

const About = () => {
  return (
    <div className="aboutus page">
      <div className="heading">
        <h1>About Us</h1>
      </div>
      <div className="inner">
        <div className="info">
          <img src={aboutusImg} alt="about us" />
          <div>
            <h2>About Us</h2>
            <p>
              Testpoint is a global crowd testing services which helps you to
              improve the quality of your website or mobile application product.
            </p>
            <p>
              Our mission is to help ensure that you are getting to a bug-free product faster, more
              effective and at the best price available.
            </p>
            <p>
              To do so, we have developed a platform and a technology which help you find the best
              and most suitable testers to validate your product at the best budget that best fits
              your needs, with whichever device or platform you prefer to use: desktop, mobile or
              tablet.
            </p>
          </div>
        </div>
      </div>

      <div className="partners">
        <div className="inner">
          <h3>Some of our business partners &amp; customers</h3>
          <p>Our customers expect a lot from us – and they should.</p>
          <p>
            testpoint customers include some of the best known software companies with global
            presence and recognized brand names, as well as some of the most rapidly growing startup
            companies that are just finding new opportunities in the market.
          </p>

          <div className="logos">
            <img src={matrixLogo} alt="" />
            <img src={playtikaLogo} alt="" />
            <img src={dvtelLogo} alt="" />
            <img src={mapaLogo} alt="" />
            <img src={holdingsLogo} alt="" />
            <img src={keeprzLogo} alt="" />
            <img src={byxLogo} alt="" />
            <img src={mysizeidLogo} alt="" />
            <img src={corticaLogo} alt="" />
            <img src={chosenfmLogo} alt="" />
          </div>
        </div>
      </div>

      <div className="contactus grey">
        <div className="inner">
          <h3>Get In Touch</h3>
          <p>Have a question or comment? We’d love to talk with you.</p>
          <p>Our address is: Asher Peled 11, Hadera, Israel</p>
          <p>
            Our Email: <a href="mailto:info@testpointtest.com">info@testpoint.com</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
