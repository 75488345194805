import React from 'react';
import classNames from 'classnames';
import { Box, Typography, Card, CardContent, CardMedia, CardHeader } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ScratchTemplate from './../../../src/public/images/ScratchTemplate.svg';
import MoreOptions from './../../../src/public/images/MoreOptions.svg';
import { Grid, Chip } from '@mui/material';
import * as txtUtils from './InnerForms/LegendCard';
import { WorkCategory } from '../../types/WorkCategory';



const useStyles = makeStyles((theme) => ({
    BaseCardTemplate: {
        height: '165px',
        width: '330px',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },

    CachedCardTemplate: {
        height: '165px',
        width: '328px',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'flex-around',
        cursor: 'pointer',
    },

    CachedCardTemplateEmpty: {
        height: '165px',
        width: '328px',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'flex-around',
    },

    StartScratch: {
        border: '1px dashed #585D72',
    },

    MoreOptionsMedia: {
        width: '24px',
        height: '24px',
    },

    ScratchMedia: {
        width: "44px",
        height: "44px",
        margin: 'auto',
    },

    SpreadBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },

    chipBox: {
        marginTop: '10px',
        marginLeft: -theme.spacing(0.25),
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.25),
        },
    },

    HeaderText: {
        fontSize: '18px',
        color: '#FFFFFF',
        fontWeight: 'bolder',
        letterSpacing: '0.1px',
    },
    HeaderTextContinue: {
        fontSize: '17px',
        color: '#FFFFFF',
        fontWeight: 'bolder',
        letterSpacing: '0.1px',
    },
    HeaderTextEmpty: {
        fontSize: '17px',
        color: '#555555',
        fontWeight: 'bolder',
        letterSpacing: '0.1px',
    },
    SubHeaderText: {
        fontSize: '14px',
        color: '#FFFFFF',
        fontWeight: 'bold',
        letterSpacing: '0.1px',
    },
    SubHeaderTextEmpty: {
        fontSize: '14px',
        color: '#555555',
        fontWeight: 'bold',
        letterSpacing: '0.1px',
    },
    ChipText: {
        '& .MuiChip-label': {
            fontWeight: "normal",
            color: "#FAFAFB",
        },
    }
}));


export function StartFromScratch(props) {
    const classes = useStyles();
    return (
        <Card elevation={3} onClick={props.startFromScratch}
            className={classNames({
                [
                    classes.BaseCardTemplate]:
                    true, [classes.StartScratch]: true
            })}
        >
            <CardContent>
                <CardMedia image={ScratchTemplate} className={classes.ScratchMedia}></CardMedia>
                {/* <Typography>
                    Customize your own test
                </Typography> */}
                <Typography>
                    {'Click to start >>'}
                </Typography>
            </CardContent>
        </Card>
    );
}

function ContinueFromWhereILeft(props) {
    const classes = useStyles();
    const { fetchedCycleData, fetchedMainStep, fetchedMinorStep } = props;

    if (fetchedMainStep > -1) {
        const hourlyRate = txtUtils.calcTotalAmountPerTester(fetchedCycleData);

        const typeText = fetchedCycleData.workCategory === WorkCategory.FindBugsExploratory ? 'Exploratory' : 'Test plan';
        const hrTestingTxt = `${fetchedCycleData.hoursPerTester} hrs test`;
        const overallTimeTxt = txtUtils.timeTxtFromDeliveryTimeDays(fetchedCycleData.deliveryTime);
        const QtimeTxt = txtUtils.timeTxtFromQuestionTime(fetchedCycleData.questionsTime) + ' Availability';
        const locationTxt = fetchedCycleData.isWorldwide ? 'Anywhere' : 'Selected countries';

        return (
            <Card elevation={0} onClick={props.onClick}
                className={classNames({
                    [
                        classes.CachedCardTemplate]: true,
                })}
                style={{ backgroundColor: '#cccccc' }}
            >
                <CardContent>
                    <Typography className={classes.HeaderTextContinue}>Continue from where you left off</Typography>
                    <Typography className={classes.SubHeaderText}>${hourlyRate} per tester</Typography>
                    {fetchedMainStep > 0 &&
                        <Box className={classes.chipBox}>
                            <Chip style={{ backgroundColor: '#bcbcbc', cursor: 'pointer' }} className={classes.ChipText} label={typeText}></Chip>
                            <Chip style={{ backgroundColor: '#bcbcbc', cursor: 'pointer' }} className={classes.ChipText} label={hrTestingTxt}></Chip>
                            <Chip style={{ backgroundColor: '#bcbcbc', cursor: 'pointer' }} className={classes.ChipText} label={overallTimeTxt}></Chip>
                            <Chip style={{ backgroundColor: '#bcbcbc', cursor: 'pointer' }} className={classes.ChipText} label={locationTxt}></Chip>
                            <Chip style={{ backgroundColor: '#bcbcbc', cursor: 'pointer' }} className={classes.ChipText} label={QtimeTxt}></Chip>
                        </Box>
                    }
                    {fetchedMainStep === 0 &&
                        <Box className={classes.chipBox}>
                            {fetchedMinorStep === 1 &&
                                <Chip style={{ backgroundColor: '#bcbcbc', cursor: 'pointer' }} className={classes.ChipText} label={typeText}></Chip>
                            }
                            {fetchedMinorStep === 2 &&
                                <React.Fragment>
                                    <Chip style={{ backgroundColor: '#bcbcbc', cursor: 'pointer' }} className={classes.ChipText} label={typeText}></Chip>
                                    <Chip style={{ backgroundColor: '#bcbcbc', cursor: 'pointer' }} className={classes.ChipText} label={hrTestingTxt}></Chip>
                                </React.Fragment>
                            }
                            {fetchedMinorStep === 3 &&
                                <React.Fragment>
                                    <Chip style={{ backgroundColor: '#bcbcbc', cursor: 'pointer' }} className={classes.ChipText} label={typeText}></Chip>
                                    <Chip style={{ backgroundColor: '#bcbcbc', cursor: 'pointer' }} className={classes.ChipText} label={hrTestingTxt}></Chip>
                                    <Chip style={{ backgroundColor: '#bcbcbc', cursor: 'pointer' }} className={classes.ChipText} label={overallTimeTxt}></Chip>
                                    <Chip style={{ backgroundColor: '#bcbcbc', cursor: 'pointer' }} className={classes.ChipText} label={QtimeTxt}></Chip>
                                </React.Fragment>
                            }
                        </Box>
                    }
                </CardContent>
            </Card>
        );
    }
    else {
        return (
            <Card elevation={0} onClick={props.onClick}
                className={classNames({
                    [
                        classes.CachedCardTemplateEmpty]: true,
                    /* [classes.StartScratch]: true */
                })}
                style={{ backgroundColor: '#cccccc', opacity: 0.55 }}
            >
                <CardContent>
                    <Typography className={classes.HeaderTextEmpty}>Welcome aboard newcomers.</Typography>
                    <Typography className={classes.SubHeaderTextEmpty}>Once you start setting your test we will remember it here next time</Typography>
                </CardContent>
            </Card>
        );
    }
}

function BasicTemplate(props) {
    const classes = useStyles();
    return (
        <Card elevation={0} onClick={props.onClick}
            className={classes.BaseCardTemplate}
            style={{ backgroundColor: '#FA982B' }}
        >
            <CardContent>
                <Box className={classes.SpreadBox}>
                    <Typography className={classes.HeaderText}>Speedtest</Typography>
                    <CardMedia image={MoreOptions} className={classes.MoreOptionsMedia}></CardMedia>
                </Box>
                <Typography className={classes.SubHeaderText} style={{ marginTop: '5px' }}>$15 per tester</Typography>
                <Box className={classes.chipBox}>
                    <Chip style={{ backgroundColor: '#FCB76B', cursor: 'pointer' }} className={classes.ChipText} label='Exploratory'></Chip>
                    <Chip style={{ backgroundColor: '#FCB76B', cursor: 'pointer' }} className={classes.ChipText} label='1 hr test'></Chip>
                    <Chip style={{ backgroundColor: '#FCB76B', cursor: 'pointer' }} className={classes.ChipText} label='1 day cycle'></Chip>
                    <Chip style={{ backgroundColor: '#FCB76B', cursor: 'pointer' }} className={classes.ChipText} label='Worldwide'></Chip>
                    <Chip style={{ backgroundColor: '#FCB76B', cursor: 'pointer' }} className={classes.ChipText} label='24 hrs availability'></Chip>
                </Box>
            </CardContent>
        </Card>
    );
}

function OnboardingTemplate(props) {
    const classes = useStyles();
    return (
        <Card elevation={0} onClick={props.onClick}
            className={classes.BaseCardTemplate}
            style={{ backgroundColor: '#2CA8F7' }}
        >
            <CardContent>
                <Box className={classes.SpreadBox}>
                    <Typography className={classes.HeaderText}>Before you launch</Typography>
                    <CardMedia image={MoreOptions} className={classes.MoreOptionsMedia}></CardMedia>
                </Box>
                <Typography className={classes.SubHeaderText} style={{ marginTop: '5px' }}>$35 per tester</Typography>
                <Box className={classes.chipBox}>
                    <Chip style={{ backgroundColor: '#6CC2F9', cursor: 'pointer' }} className={classes.ChipText} label='Exploratory'></Chip>
                    <Chip style={{ backgroundColor: '#6CC2F9', cursor: 'pointer' }} className={classes.ChipText} label='3 hr test'></Chip>
                    <Chip style={{ backgroundColor: '#6CC2F9', cursor: 'pointer' }} className={classes.ChipText} label='3 days cycle'></Chip>
                    <Chip style={{ backgroundColor: '#6CC2F9', cursor: 'pointer' }} className={classes.ChipText} label='Worldwide'></Chip>
                    <Chip style={{ backgroundColor: '#6CC2F9', cursor: 'pointer' }} className={classes.ChipText} label='2 days availability'></Chip>
                </Box>
            </CardContent>
        </Card>
    );
}

function BeforeLunchTemplate(props) {
    const classes = useStyles();
    return (
        <Card elevation={0} onClick={props.onClick}
            className={classes.BaseCardTemplate}
            style={{ backgroundColor: '#9D1DB1' }}
        >
            <CardContent>
                <Box className={classes.SpreadBox}>
                    <Typography className={classes.HeaderText}>Before launch</Typography>
                    <CardMedia image={MoreOptions} className={classes.MoreOptionsMedia}></CardMedia>
                </Box>
                <Typography className={classes.SubHeaderText} style={{ marginTop: '5px' }}>$35 per tester</Typography>
                <Box className={classes.chipBox}>
                    <Chip style={{ backgroundColor: '#BB64C9', cursor: 'pointer' }} className={classes.ChipText} label='Exploratory'></Chip>
                    <Chip style={{ backgroundColor: '#BB64C9', cursor: 'pointer' }} className={classes.ChipText} label='3 hrs test'></Chip>
                    <Chip style={{ backgroundColor: '#BB64C9', cursor: 'pointer' }} className={classes.ChipText} label='3 days cycle'></Chip>
                    <Chip style={{ backgroundColor: '#BB64C9', cursor: 'pointer' }} className={classes.ChipText} label='Selected countries'></Chip>
                    <Chip style={{ backgroundColor: '#BB64C9', cursor: 'pointer' }} className={classes.ChipText} label='2 days availability'></Chip>
                </Box>
            </CardContent>
        </Card>
    );
}

function OnGoingemplate(props) {
    const classes = useStyles();
    return (
        <Card elevation={0} onClick={props.onClick}
            className={classes.BaseCardTemplate}
            style={{ backgroundColor: '#8AC441' }}
        >
            <CardContent>
                <Box className={classes.SpreadBox}>
                    <Typography className={classes.HeaderText}>New feature/version</Typography>
                    <CardMedia image={MoreOptions} className={classes.MoreOptionsMedia}></CardMedia>
                </Box>
                <Typography className={classes.SubHeaderText} style={{ marginTop: '5px' }}>$40 per tester</Typography>
                <Box className={classes.chipBox}>
                    <Chip style={{ backgroundColor: '#ADD67A', cursor: 'pointer' }} className={classes.ChipText} label='Test plan'></Chip>
                    <Chip style={{ backgroundColor: '#ADD67A', cursor: 'pointer' }} className={classes.ChipText} label='2 hrs test'></Chip>
                    <Chip style={{ backgroundColor: '#ADD67A', cursor: 'pointer' }} className={classes.ChipText} label='1 day cycle'></Chip>
                    <Chip style={{ backgroundColor: '#ADD67A', cursor: 'pointer' }} className={classes.ChipText} label='Worldwide'></Chip>
                    <Chip style={{ backgroundColor: '#ADD67A', cursor: 'pointer' }} className={classes.ChipText} label='4 days availability'></Chip>
                </Box>
            </CardContent>
        </Card>
    );
}

function IsraeliTemplate(props) {
    const classes = useStyles();
    return (
        <Card elevation={0} onClick={props.onClick}
            className={classes.BaseCardTemplate}
            style={{ backgroundColor: '#0080D1' }}
        >
            <CardContent>
                <Box className={classes.SpreadBox}>
                    <Typography className={classes.HeaderText}>Test it all</Typography>
                    <CardMedia image={MoreOptions} className={classes.MoreOptionsMedia}></CardMedia>
                </Box>
                <Typography className={classes.SubHeaderText} style={{ marginTop: '5px' }}>$50 per tester</Typography>
                <Box className={classes.chipBox}>
                    <Chip style={{ backgroundColor: '#6CC2F9', cursor: 'pointer' }} className={classes.ChipText} label='Test plan'></Chip>
                    <Chip style={{ backgroundColor: '#6CC2F9', cursor: 'pointer' }} className={classes.ChipText} label='4 hrs test'></Chip>
                    <Chip style={{ backgroundColor: '#6CC2F9', cursor: 'pointer' }} className={classes.ChipText} label='1 week cycle'></Chip>
                    <Chip style={{ backgroundColor: '#6CC2F9', cursor: 'pointer' }} className={classes.ChipText} label='Worldwide'></Chip>
                    <Chip style={{ backgroundColor: '#6CC2F9', cursor: 'pointer' }} className={classes.ChipText} label='4 days availability'></Chip>
                </Box>
            </CardContent>
        </Card>
    );
}

function TemplateSelectionForm(props) {
    const classes = useStyles();
    const { fetchedCycleData, fetchedMainStep, fetchedMinorStep } = props;

    return (
        <Box display="flex" alignItems="center" flexDirection="column" position="relative">
            <Typography variant="h3">
                Select your Test needs
            </Typography>
            <Typography variant="h4" style={{ marginTop: "15px" }} >
                You can select from one of our templates or customize your own
            </Typography>
            <Grid container spacing={1} style={{ marginTop: '110px', width: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>

                <Grid item>
                    <BasicTemplate onClick={props.basicTemplateClick}></BasicTemplate>
                </Grid>
                <Grid item>
                    <OnboardingTemplate onClick={props.onBoardingTemplateClick}></OnboardingTemplate>
                </Grid>
                <Grid item>
                    <OnGoingemplate onClick={props.onOngoingTemplateClick}></OnGoingemplate>
                </Grid>
                <Grid item>
                    <IsraeliTemplate onClick={props.onIsraeliTemplateClick}></IsraeliTemplate>
                </Grid>
                <Grid item>
                    <StartFromScratch startFromScratch={props.startFromScratch}></StartFromScratch>
                </Grid>
                <Grid item>
                    <ContinueFromWhereILeft onClick={props.continueFromWhereILeft}
                        fetchedCycleData={props.fetchedCycleData}
                        fetchedMainStep={props.fetchedMainStep}
                        fetchedMinorStep={props.fetchedMinorStep}
                    >

                    </ContinueFromWhereILeft>
                </Grid>

            </Grid>

        </Box>
    );

}


export default TemplateSelectionForm;