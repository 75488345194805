import { DevicePlatform } from './DevicePlatform';
import { Gender } from './Gender';
import { BrowserBaseObject, DeviceTestObject, PlatformBaseObject } from './NewPlatformModel';
import { ReportingCategory } from './reportingCategory';
import { DesktopBrowser, MobileDevice, Platform } from './TestingEnvironment';
import { TestPlanStep } from './TestPlanStep';
import { DeliveryTimeCategory, QuestionTimeCategory, SpecificationType } from './WizardCategoriesTypes';
import { WorkCategory } from './WorkCategory';

export class NewWizardCycle {
  deliveryTime: DeliveryTimeCategory | '';
  questionsTime: QuestionTimeCategory | '';
  workCategory: WorkCategory | '';
  gender: Gender;
  minAge?: number;
  maxAge?: number;
  useFavoriteTesters: boolean;
  testEnvs: (MobileDevice | DesktopBrowser | Platform)[];
  platformUrls: {
    platformId: DevicePlatform;
    url: string;
  }[];
  countries: string[];
  hourlyRate: number;
  hoursPerTester: number;
  title: string;
  version: string;
  description: string;
  questions?: string[];
  testPlan?: TestPlanStep[];
  testSpecification: SpecificationType | '';

  isWorldwide?: boolean;
  startingPointURL?: string;
  isWithPromoCode?: boolean;
  promoCode?: string;
  isTestPlanFromFile?: boolean;
  reportingCategories: ReportingCategory[];

  mainTestPlatform: PlatformBaseObject;
  relevantBrowsers: BrowserBaseObject[];

  isSpecificDevices?: Boolean;
  numberOfTesters: number;
  relevantDevices: DeviceTestObject[];
  specificationPerCountry: DeviceTestObject[];
  couponDetails: {
    id: number;
    code: string;
    discount: number;
  };


  constructor() {
    this.deliveryTime = DeliveryTimeCategory._24hours;
    this.questionsTime = QuestionTimeCategory._24hours;
    this.workCategory = WorkCategory.FindBugsExploratory;
    this.gender = Gender.Any;
    this.useFavoriteTesters = false;

    this.testEnvs = [];
    this.platformUrls = [];
    this.countries = [];
    this.hourlyRate = 0;
    this.hoursPerTester = 1;
    this.title = '';
    this.version = '';
    this.description = '';
    this.testPlan = [];
    this.testSpecification = SpecificationType.NativeApp;

    this.isWorldwide = true;
    this.startingPointURL = '';
    this.isWithPromoCode = false;
    this.promoCode = '';
    this.isTestPlanFromFile = false;
    this.reportingCategories = Object.keys(ReportingCategory).map(key => ReportingCategory[key]);
    this.mainTestPlatform = { id: -1, name: '', minOSVersion: { id: -1, name: '' }, maxOSVersion: { id: -1, name: '' } };
    this.relevantBrowsers = [];

    this.isSpecificDevices = false;
    this.numberOfTesters = 5;
    this.relevantDevices = [];
    this.specificationPerCountry = [];
    this.couponDetails = { id: -1, code: '', discount: 0 };
  }

}
