import React from 'react';
import classNames from 'classnames';
import { blue } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    button: {
        minWidth: 140,
        minHeight: 36,
        border: 'none',
        outline: 'none',
        borderRadius: 30,
        padding: 16,
        color: '#ffffff',
        cursor: 'pointer',
        opacity: 1,
        transition: 'opacity 0.4s',
        background: `transparent linear-gradient(255deg, ${blue[600]} 0%, ${blue[800]} 100%) 0% 0% no-repeat padding-box`,
        '&:hover': {
            opacity: 0.9,
        },  
    },
    buttonDisabled:{
        opacity: 0.5,
    },
    buttonInverted: {
        color: blue[700],
        background: '#ffffff',
        border: `2px solid ${blue[700]}`,
    },
    buttonTransparent: {
        color: '#ffffff',
        background: 'transparent',
        border: '2px solid #ffffff',
    },
    buttonRegister: {
        paddingTop: 14,
        borderRadius: 10,
        minHeight: 'auto',
        paddingBottom: 14,
        boxShadow: '0 3px 6px #00000029',
    },
    buttonCreation: {
        paddingTop: 12,
        borderRadius: 6,
        minHeight: 'auto',
        paddingBottom: 12,
        border: `1px solid ${blue[700]}`,
    },
    buttonFullWidth: {
        width: '100%',
    },
}));

interface IButtonProps extends React.ButtonHTMLAttributes<any> {
    className?: string;
    inverted?: boolean;
    fullWidth?: boolean;
    transparent?: boolean;
    registration?: boolean;
    creation?: boolean;
    disabled?: boolean;
    children?: JSX.Element | string | null;
};

const Button: React.FC<IButtonProps> = ({
    className = '',
    inverted = false, 
    fullWidth = false, 
    transparent = false, 
    disabled = false,
    children = null,
    registration = false, 
    creation = false, 
    ...rest
}) => {
    const classes = useStyles();

    return (
        <button 
            className={classNames(className, classes.button, {
                [classes.buttonInverted]: inverted,
                [classes.buttonFullWidth]: fullWidth,
                [classes.buttonCreation]: creation,
                [classes.buttonRegister]: registration,
                [classes.buttonTransparent]: transparent,
                [classes.buttonDisabled]: disabled,
            })}
            {...rest}
        >
            {children}
        </button>
    );
};

export default Button;