import { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../redux/actions';
import { OperationStatus } from '../types/OperationStatus';


const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus.status,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    impersonate: (loginToken) => dispatch(actions.impersonate(loginToken)),
  };
};

class Impersonate extends Component<any> {
  public state: any;

  constructor(props) {
    super(props);

    this.state = {
      token: props.match.params.token,
    };
  }

  componentDidMount() {
    this.props.impersonate(this.state.token);
    this.props.history.push('/account');
  }

  componentDidUpdate(prevProps) {    
    /* if (this.props.loginStatus === OperationStatus.success) {
      this.props.history.push('/account');
    } */
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Impersonate);
