import React from 'react';
import { Button } from 'semantic-ui-react';
import { BugStatus } from '../../../types/BugStatus';
import { OperationStatus } from '../../../types/OperationStatus';

export const BugStatusToggle = (props) => {
  return (
    <div className="bug-status-toggle">
      Bug status &nbsp;
      <Button.Group size="tiny">
        <Button
          basic={props.bugStatus !== BugStatus.New}
          primary
          disabled={
            props.bugStatus === BugStatus.New ||
            props.editBugStatus.status === OperationStatus.processing
          }
          loading={
            props.editBugStatus.bugStatus === BugStatus.New &&
            props.editBugStatus.status === OperationStatus.processing
          }
          onClick={() => {
            props.onBugStatusChange(props.bugId, BugStatus.New);
          }}
        >
          Open
        </Button>
        <Button
          basic={props.bugStatus !== BugStatus.Fixed}
          primary
          disabled={
            props.bugStatus === BugStatus.Fixed ||
            props.editBugStatus.status === OperationStatus.processing
          }
          loading={
            props.editBugStatus.bugStatus === BugStatus.Fixed &&
            props.editBugStatus.status === OperationStatus.processing
          }
          onClick={() => {
            props.onBugStatusChange(props.bugId, BugStatus.Fixed);
          }}
        >
          Fixed
        </Button>
        <Button
          basic={props.bugStatus !== BugStatus.NotABug}
          primary
          disabled={
            props.bugStatus === BugStatus.NotABug ||
            props.editBugStatus.status === OperationStatus.processing
          }
          loading={
            props.editBugStatus.bugStatus === BugStatus.NotABug &&
            props.editBugStatus.status === OperationStatus.processing
          }
          onClick={() => {
            props.onBugStatusChange(props.bugId, BugStatus.NotABug);
          }}
        >
          Not a bug
        </Button>
        <Button
          basic={props.bugStatus !== BugStatus.Duplicated}
          primary
          loading={
            props.editBugStatus.bugStatus === BugStatus.Duplicated &&
            props.editBugStatus.status === OperationStatus.processing
          }
          disabled={
            props.bugStatus === BugStatus.Duplicated ||
            props.editBugStatus.status === OperationStatus.processing
          }
          onClick={() => {
            props.onBugStatusChange(props.bugId, BugStatus.Duplicated);
          }}
        >
          Duplicated
        </Button>
        <Button
          basic={props.bugStatus !== BugStatus.Deferred}
          primary
          disabled={
            props.bugStatus === BugStatus.Deferred ||
            props.editBugStatus.status === OperationStatus.processing
          }
          loading={
            props.editBugStatus.bugStatus === BugStatus.Deferred &&
            props.editBugStatus.status === OperationStatus.processing
          }
          onClick={() => {
            props.onBugStatusChange(props.bugId, BugStatus.Deferred);
          }}
        >
          Closed
        </Button>
      </Button.Group>
    </div>
  );
};
