import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import { Box, Typography } from '@mui/material';
import { Button } from 'semantic-ui-react';
import classNames from 'classnames';
import { StartFromScratch } from './TemplateSelectionForm';
import { subtitleGrey } from './InnerForms/SharedStyle';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const useStyles = makeStyles((theme: Theme) => ({
    paragraphTxt: {
        fontSize: 18,
        //color: 'red',
        padding: 40,
        color: `${subtitleGrey}`
    }
}));

export default function WelcomePro(props) {

    const { startFromScratch } = props;
    const classes = useStyles();
    const { user } = useSelector((state: RootState) => state);

    return (
        <Box display="flex" alignItems="center" flexDirection="column" position="relative">
            <Typography variant="h3">
                Welcome {user.firstName}!
            </Typography>
            <Box sx={{ width: '75%' }}>
                <Typography className={classes.paragraphTxt}>
                    We are thrilled to walk you through our detailed wizard in which you will be able to define a tailored made test-suite to meet your unique needs;
                    Define the required number of testers, the test's duration and service time availability, select whether to run it worldwide or locally per specific countries, and more.
                </Typography>
            </Box>
            <StartFromScratch startFromScratch={startFromScratch}></StartFromScratch>
        </Box >
    );
}