import React, { Component } from 'react';
import { Box, Checkbox, Typography, TextField } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import Chip from '@mui/material/Chip';
import DoneIcon from '@mui/icons-material/Done';
import * as _ from 'lodash';


import wizardActions from '../../../redux/actions/wizardActions';
import { NewWizardCycle } from '../../../types/NewWizardCycle';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import reportingTypes from '../../../util/options/reportingTypes';
import { ReportingCategory } from '../../../types/reportingCategory';
import { Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SharedStyle from './SharedStyle';


const styles = (theme) => createStyles({
    internalBox: {
        marginTop: '20px',
        marginLeft: '-8px',
        display: 'flex',
        flexWrap: 'wrap',
        width: "80%",
        '& > *': {
            marginLeft: '8px',
            marginBottom: '8px',
        },
    },

    defaultChip: {
        '& .MuiChip-root': {
            height: "40px",
        },
        '& .MuiChip-label': {
            paddingLeft: '23px',
            paddingRight: "22px",
            fontWeight: "bolder",
            color: "#585D72",
        },
    },
});

const StyledButton = withStyles({
    root: {
        backgroundColor: '#F1F1F5',
        borderRadius: 10,
        border: 0,
        color: '#696974',
        height: 40,
        whiteSpace: "nowrap"
    },
    text: {
        fontSize: '12px',
        paddingLeft: '10px',
        paddingRight: '10px',
    },
})(Button);

const allReportingTypes = [...reportingTypes];

const mapStateToProps = (store) => {
    return {
        newCycle: store.newWizardCycle as NewWizardCycle,
        errors: store.newCycleCurrentStepErrors,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        updateCycle: (data) => dispatch(wizardActions.updateCycle(data)),
    };
};

class GeneralDetailsForm extends Component<any> {
    public state: any;

    constructor(props) {
        super(props);

        this.state = {
            errors: [],
        };
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.errors, this.props.errors)) {
            this.setState({ errors: this.props.errors });
        };
    }
    resetErrorStateForInput = (target) => {
        if (this.state.errors.includes(target.name)) {
            const errors = [...this.state.errors];

            errors.splice(this.state.errors.indexOf(target.name), 1);
            this.setState({ errors });
        }
    }

    handleCategoryItemClicked = (categoryKey) => {
        const newCycle: NewWizardCycle = { ...this.props.newCycle };

        let updatedCategories: ReportingCategory[] = [];
        //Removing
        if (newCycle.reportingCategories.includes(categoryKey)) {
            updatedCategories = newCycle.reportingCategories.filter(categoryItem => categoryKey !== categoryItem);
        }
        //Adding
        else {
            updatedCategories = [...newCycle.reportingCategories, categoryKey];
        }
        newCycle.reportingCategories = updatedCategories;
        this.props.updateCycle(newCycle);
    }

    onChange = event => {
        const newCycle = { ...this.props.newCycle };
        newCycle[event.name] = event.value;

        this.props.updateCycle(newCycle);
    };

    onCheckUrl = () => {
        const newCycle = { ...this.props.newCycle };
        let url = newCycle.startingPointURL

        if (!url.length) {
            return;
        }

        const regexp = /^(ftp|http|https):\/\/*/;

        if (regexp.test(url) === false) {
            url = 'http://' + url;
        }

        window.open(url, '_blank');
    };

    render() {
        const { classes } = this.props;
        const newCycle: NewWizardCycle = this.props.newCycle;

        return (
            <Box>
                <Typography variant="h2">
                    Test name
                </Typography>

                <TextField
                    autoComplete='off'
                    style={{ width: "80%", minWidth: "50%", marginTop: "20px" }}
                    label="Give your test a name"
                    variant="outlined"
                    name="title"
                    value={newCycle.title}
                    onChange={e => { this.onChange(e.target); this.resetErrorStateForInput(e.target); }}
                    error={this.state.errors.includes('title')}
                />

                <Typography variant="h2" style={{ marginTop: "30px" }}>
                    Describe your test
                </Typography>

                <TextField
                    autoComplete='off'
                    multiline rows={4} style={{ width: "80%", minWidth: "50%", marginTop: "20px" }}
                    label="Tell our testers about your test"
                    variant="outlined"
                    name="description"
                    value={newCycle.description}
                    onChange={e => { this.onChange(e.target); this.resetErrorStateForInput(e.target); }}
                    error={this.state.errors.includes('description')}
                />

                <Typography variant="h2" style={{ marginTop: "30px" }}>
                    What the tester should focus on
                </Typography>

                <div className={classes.internalBox}>
                    {allReportingTypes.map(chipData => (
                        <Chip
                            label={chipData.text}
                            style={{ cursor: "pointer", height: "36px" }}
                            className={newCycle.reportingCategories.includes(chipData.key) ? "" : classes.defaultChip}
                            color={newCycle.reportingCategories.includes(chipData.key) ? "primary" : "secondary"}
                            icon={newCycle.reportingCategories.includes(chipData.key) ? <DoneIcon /> : <></>}
                            variant={newCycle.reportingCategories.includes(chipData.key) ? "filled" : "outlined"}
                            key={chipData.key}
                            onClick={() => this.handleCategoryItemClicked(chipData.key)}
                        />
                    ))}
                </div>

                <Typography variant="h2" style={{ marginTop: "30px" }}>
                    Project URL
                </Typography>

                <TextField style={{ width: "80%", minWidth: "50%", marginTop: "20px" }}
                    label="Type the URL starting point for the tester"
                    variant="outlined"
                    name="startingPointURL"
                    value={newCycle.startingPointURL}
                    onChange={e => { this.onChange(e.target); this.resetErrorStateForInput(e.target); }}
                    InputProps={{ endAdornment: <StyledButton onClick={this.onCheckUrl}>Check my URL</StyledButton> }}
                    error={this.state.errors.includes('startingPointURL')}
                />
            </Box>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GeneralDetailsForm)));