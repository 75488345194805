import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import actions from '../redux/actions';
import { RootState } from '../redux/store';
import { OperationStatus } from '../types/OperationStatus';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: theme.palette.background.default,
    }
}));

const RegisterApproved = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const { loginToken, loginStatus } = useSelector((state: RootState) => state);

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(actions.impersonate(loginToken));
        }, 4000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (loginStatus.status === OperationStatus.success) {
            history.push('/complete-profile/personal');
        }
    }, [loginStatus.status]);


    return (
        <div className="registerconfirm page">
            <div className="heading">
                <h1>Registration Approved</h1>
            </div>

            <div className="inner">
                <div className="makepay">
                    <div>
                        <h2>Your registration has been approved</h2>
                        <p>You will be redirected to your account shortly</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterApproved;