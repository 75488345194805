import React, { Component, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
  Accordion,
  Select,
  Label,
  Dropdown,
  Sidebar,
  Segment,
  Header,
  Grid,
  Menu,
  Icon,
} from 'semantic-ui-react';
import exportFromJSON from 'export-from-json';
import _ from 'lodash';
import CycleDetailsSidemenu from './Sidemenu';
import TesterReviewModal from './TesterReviewModal';
import { BugDetails } from '../../../Cycles/Bugs/BugDetails';
import { TesterStatusInCycle } from '../../../../types/TesterStatusInCycle';
import { CycleStatus } from '../../../../types/CycleStatus';
import moment from 'moment';
import { getApprovedDevicePrettyName } from '../../../../services/deviceService';
import { BugStatus } from '../../../../types/BugStatus';
import actions from '../../../../redux/actions';
import cycleActions from '../../../../redux/actions/cycleActions';
import noavatar from '../../../../public/images/noavatar.png';
import config from '../../../../config';
import {
  Badge,
  Button,
  ButtonGroup,
  Chip,
  Drawer,
  List,
  ListItem,
  Paper,
  Toolbar,
} from '@mui/material';
import BackIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import useIndicators from '../../../../util/useIndicators';
import { RootState } from '../../../../redux/store';

const DrawerStyled = styled(Drawer)`
  .MuiPaper-root {
    width: 80%;
  }
`;

const BugHeader = styled.div`
  width: 100%;
  display: flex;
  white-space: nowrap;
  text-align: left;
  text-transform: initial;
  align-items: center;
`;

const BugHeaderMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  text-align: left;
  text-transform: initial;
  align-items: left;
`;

const BugHeaderID = styled.div`
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: #444;
  font-weight: 700;
  padding: 4px 10px;
  border-radius: 4px;
  background: white;
`;

const BugHeaderTopic = styled.div`
  width: 100%;
  white-space: break-spaces;
  text-transform: initial;
  text-align: left;
  margin: 4px 8px;
`;

const Chips = styled.div`
  text-align: right;
  > *:not(:last-child) {
    margin-bottom: 4px;
  }
`;

const Topic = styled.div`
  font-weight: 500;
  font-size: 110%;
`;

const UserInfo = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 90%;
  color: #888;
  > div {
    margin: 0 10px 0 0;
  }
`;

interface Props {
  isMobile?: boolean;
  bugs: any[];
  cycle: any;
  cycleTesters: any[];
  user: any;
  onBugStatusChange?: (bugId: any, status: any) => void;
  editBugStatus: any;
  onEdit?: (id: number) => void;
  onDelete?: (id: number) => void;
}
const ReportedBugsList = ({
  isMobile,
  bugs,
  cycle,
  cycleTesters,
  user,
  onBugStatusChange,
  editBugStatus,
  onEdit,
  onDelete,
}: Props) => {
  const [bugDetails, setBugDetails] = useState<any>(undefined);
  const { indicators } = useIndicators(true);

  //Take serviceTime into account
  const isCycleOver = ( cycle.status === CycleStatus.Completed && moment(cycle.questionsTime).isBefore(moment()) );

  useEffect(() => {
    if (bugDetails) {
      const updatedBug = bugs.find((b) => b.id === bugDetails.id);
      setBugDetails(updatedBug);
    }
  }, [bugs]);

  const renderTesterInfo = (bug) => {
    const tester = (cycleTesters || []).find((tester) => tester.id === bug.testerId);
    if (!tester) {
      return;
    }
    const testerName = `${tester.firstName} ${tester.lastName}`;
    const testerCountry = tester?.country || 'Unknown country';
    const deviceName = getApprovedDevicePrettyName(bug.device);
    return (
      <UserInfo>
        <div>
          {testerName} - {testerCountry}
        </div>
        <div>[{deviceName}]</div>
      </UserInfo>
    );
  };

  const renderBugDetailsHeader = (bug, slim: boolean = false) => {
    if (!bug) {
      return;
    }

    //const status = bug.status === BugStatus.NotABug ? 'Not a bug' : _.capitalize(bug.status);
    let statusTxt;
    switch(bug.status)
    {
      case BugStatus.NotABug : statusTxt = 'Not a bug'; break;
      case BugStatus.New : statusTxt = 'Open'; break;
      case BugStatus.Fixed : statusTxt = 'Fixed'; break;
      case BugStatus.Duplicated : statusTxt = 'Duplicated'; break;
      case BugStatus.Deferred : statusTxt = 'Closed'; break;
      default: statusTxt = _.capitalize(bug.status);
    }

    const status = statusTxt;

    const step = bug.testPlanStepId ? `Step ID: ${bug.testPlanStepId}` : 'Without step';

    let indicateChatUnread: boolean = false;
    if (bug.chatId) {
      const found = indicators.find(
        (indicator) => indicator.entityType === 'chat' && indicator.entityRef === bug.chatId
      );
      indicateChatUnread = !!found;
    }

    const handleEdit = (evt, id: number) => {
      evt.preventDefault();
      evt.stopPropagation();
      if (onEdit) {
        onEdit(id);
      }
    };

    const handleDelete = (evt, id: number) => {
      evt.preventDefault();
      evt.stopPropagation();
      if (onDelete) {
        onDelete(id);
      }
    };
    
    if(isMobile) {
      return (
        <BugHeaderMobile>
          <Badge
            color="secondary"
            badgeContent={indicateChatUnread ? ' ' : 0}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <BugHeaderID>#{bug.publicId}</BugHeaderID>
          </Badge>
  
          <BugHeaderTopic>
            <Topic>{bug.topic}</Topic>
            {renderTesterInfo(bug)}
          </BugHeaderTopic>
  
          {!slim ? (
            onEdit || onDelete ? (
              <ButtonGroup variant="contained" size="small">
                {onEdit && (
                  <Button disabled={isCycleOver} color="primary" onClick={(evt) => handleEdit(evt, bug.id)}>
                    Edit
                  </Button>
                )}
                {onDelete && (
                  <Button disabled={isCycleOver} color="secondary" onClick={(evt) => handleDelete(evt, bug.id)}>
                    Delete
                  </Button>
                )}
              </ButtonGroup>
            ) : (
              <Chips>
                <div>
                  <Chip
                    label={step}
                    variant={!!bug.testPlanStepId ? 'filled' : 'outlined'}
                    size="small"
                  />
                </div>
                <div>
                  <Chip
                    label={status}
                    size="small"
                    color={
                      bug.status === BugStatus.Fixed
                        ? 'primary'
                        : bug.status === BugStatus.New
                        ? 'default'
                        : 'secondary'
                    } />
                </div>
              </Chips>
            )
          ) : null}
        </BugHeaderMobile>
      );
    }

    return (
      <BugHeader>
        <Badge
          color="secondary"
          badgeContent={indicateChatUnread ? ' ' : 0}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <BugHeaderID>#{bug.publicId}</BugHeaderID>
        </Badge>

        <BugHeaderTopic>
          <Topic>{bug.topic}</Topic>
          {renderTesterInfo(bug)}
        </BugHeaderTopic>

        {!slim ? (
          onEdit || onDelete ? (
            <ButtonGroup variant="contained" size="small">
              {onEdit && (
                <Button disabled={isCycleOver} color="primary" onClick={(evt) => handleEdit(evt, bug.id)}>
                  Edit
                </Button>
              )}
              {onDelete && (
                <Button disabled={isCycleOver} color="secondary" onClick={(evt) => handleDelete(evt, bug.id)}>
                  Delete
                </Button>
              )}
            </ButtonGroup>
          ) : (
            <Chips>
              <div>
                <Chip
                  label={step}
                  variant={!!bug.testPlanStepId ? 'filled' : 'outlined'}
                  size="small"
                />
              </div>
              <div>
                <Chip
                  label={status}
                  size="small"
                  color={
                    bug.status === BugStatus.Fixed
                      ? 'primary'
                      : bug.status === BugStatus.New
                      ? 'default'
                      : 'secondary'
                  } />
              </div>
            </Chips>
          )
        ) : null}
      </BugHeader>
    );
  };

  const renderBugDetails = (bug) => {
    if (!bug) {
      return;
    }

    const tester = cycleTesters.find((tester) => tester.id === bug.testerId);

    if (!tester) {
      console.error('Unknown tester for bug', bug);
    }

    const firstName = tester?.firstName || 'Unknown tester';
    const lastName = tester?.lastName || bug.testerId;
    const testerCountry = tester?.country || 'Unknown country';
    const deviceName = getApprovedDevicePrettyName(bug.device);

    return (
      <BugDetails
        bugId={bug.id}
        chatId={bug.chatId}
        cycleId={cycle.id}
        ownerId={cycle.ownerId}
        step={bug.testPlanStepId}
        userRole={user.role}
        testerId={bug.testerId}
        testerName={`${firstName} ${lastName}`}
        deviceName={deviceName}
        testerCountry={testerCountry}
        stepsToReproduce={bug.stepsToReproduce}
        expectedResult={bug.expectedResult}
        actualResult={bug.actualResult}
        severity={bug.severity}
        comment={bug.comment}
        imageFilePaths={bug.imageFilePaths}
        videoFilePaths={bug.videoFilePaths}
        status={bug.status}
        createdAt={bug.createdAt}
        onBugStatusChange={onBugStatusChange}
        editBugStatus={editBugStatus}
      />
    );
  };

  return (
    <>
      <List>
        {bugs.map((bug) => (
          <ListItem
            key={bug.id}
            onClick={() => {
              setBugDetails(bug);
            }}
          >
            <Button variant="outlined" fullWidth>
              <div style={{ width: '100%', display: 'flex' }}>{renderBugDetailsHeader(bug)}</div>
            </Button>
          </ListItem>
        ))}
      </List>
      {/* TODO: Bug specific route comes here */}
      <DrawerStyled anchor="right" open={!!bugDetails} onClose={() => setBugDetails(undefined)}>
        <Segment basic>
          <div>
            <div>
              <Button
                variant="text"
                startIcon={<BackIcon />}
                onClick={() => setBugDetails(undefined)}
              >
                Close
              </Button>
            </div>
            <Paper
              elevation={0}
              variant="outlined"
              style={{
                width: '100%',
                padding: '5px 15px',
                margin: '5px 0 10px',
                background: '#8badac2b',
              }}
            >
              {bugDetails && renderBugDetailsHeader(bugDetails, true)}
            </Paper>
          </div>
          {bugDetails && renderBugDetails(bugDetails)}
        </Segment>
      </DrawerStyled>
    </>
  );
};

export default ReportedBugsList;
