export enum SpecificationType {
    NativeApp = 'NativeApp',
    Website = 'Website',    
  }
  

export enum DeliveryTimeCategory {
    _24hours = '24hours',
    _3days = '3days',
    _7days = '7days',
  }
  

export enum QuestionTimeCategory {
    _24hours = '24hours',
    _2days = '2days',
    _4days = '4days',
  }

export enum PayoutRatesAndTestersQuota {
    minTestersPerCycle = 5,
    minTestersPerSpecification = 3,
    baseHrlyRate = 15,    
    testPlanExtra = 5,
    _2hrsTestingExtra = 5,
    _3hrsTestingExtra = 10,
    _4hrsTestingExtra = 15,
    _2daysQtimeExtra = 10,
    _4daysQtimeExtra = 15,
}