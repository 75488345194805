import React from 'react';
import { Segment } from 'semantic-ui-react';

const CycleTotal = (props) => {
  const total = props.total;
  const vat = props.vat;
  const balance = props.balance || 0;

  const amountToPay =
    total - balance + vat > 0 ? parseFloat((total - balance + vat).toFixed(2)) : 0;

  return (
    <Segment color="green" className="price">
      {typeof props.balance !== 'undefined' && (
        <p>
          Your current balance: <span>${balance}</span>
        </p>
      )}
      <p>
        Project total: <span>${total}</span>
      </p>
      {vat > 0 && (
        <p>
          VAT <span>${vat.toFixed(2)}</span>
        </p>
      )}
      <p className="amount-to-pay">
        Amount to pay: <span>${amountToPay}</span>
      </p>
    </Segment>
  );
};

export { CycleTotal };
