import React from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';

import CycleCard from './CycleCard';
import { CycleStatus } from '../../../../types/CycleStatus';
import { blue, yellow, red } from '@mui/material/colors';

const useStyles = makeStyles(() => ({
    cycleText: {
        padding: 10,
        marginBottom: 10,
    },
    ready: {
        color: yellow[800],
    },
    on: {
        color: red[900],
    },
    done: {
        color: blue[900],
    },
}));

interface ICycleListProps {
    cyclePhase?: CycleStatus;
    title?: string;
    description?: string;
    cycles?: Array<any>;
    activeCycleId: string;
    cycleRef: any;
};

const CycleList: React.FC<ICycleListProps> = ({ 
    cyclePhase = CycleStatus.Testing,
    title = '',
    description = '',
    cycles = [],
    activeCycleId,
    cycleRef = null,
}) => {
    const classes = useStyles();        
   
    //Sort decsending according to startDate or completedDate per list type.
    if( cyclePhase != CycleStatus.Completed ){
        cycles.sort( (a, b) => moment(a.startDate).toDate() > moment(b.startDate).toDate() ? -1 : 1 );
    }
    else{
        cycles.sort( (a, b) => moment(a.endDate).toDate() > moment(b.endDate).toDate() ? -1 : 1 );
    }    

    if (isEmpty(cycles)) {
        return null;
    }
    return (
        <>
            <div className={classes.cycleText}>
                <Typography variant="body1" component="p" className={classNames({ 
                                                                        [classes.ready]: cyclePhase === CycleStatus.Selection,
                                                                        [classes.on]: cyclePhase === CycleStatus.Testing || cyclePhase === CycleStatus.TestingAndSelection,
                                                                        [classes.done]: cyclePhase === CycleStatus.Completed })}>
                    {title}
                </Typography>                
                <Typography color="textPrimary" variant="body2" component="p">
                    {description}
                </Typography>
            </div>

            {cycles.map((cycle) => (
                <Link key={cycle.id} to={`/account/customer/cycle/${cycle.id}/report`}>
                    <CycleCard
                        cycle={cycle}
                        cyclePhase={cyclePhase}
                        title={cycle.title}
                        startDate={moment(cycle.startDate).format('DD/MM/YY HH:MM')}
                        endDate={moment(cycle.endDate).format('DD/MM/YY HH:MM')}
                        platform="Web"
                        bugsCount={cycle.bugsFound}
                        isActive={cycle.id === +activeCycleId}
                        unreadMessagesCount={cycle.totalUnreadMessages}
                        /* progress={(cycle.approvedTesters / cycle.totalTesters) * 100}                     */
                    />
                    {cycle.id === +activeCycleId &&
                        <div ref={cycleRef} />
                    }                    
                </Link>
            ))}
        </>
    );
};

export default CycleList;