export default [
  { "key": "AF", "value": "Afghanistan", "text": "Afghanistan", "dialCode": "+93" },
  { "key": "AX", "value": "Åland Islands", "text": "Åland Islands", "dialCode": "+358" },
  { "key": "AL", "value": "Albania", "text": "Albania", "dialCode": "+355" },
  { "key": "DZ", "value": "Algeria", "text": "Algeria", "dialCode": "+213" },
  { "key": "AS", "value": "American Samoa", "text": "American Samoa", "dialCode": "+1684" },
  { "key": "AD", "value": "Andorra", "text": "Andorra", "dialCode": "+376" },
  { "key": "AO", "value": "Angola", "text": "Angola", "dialCode": "+244" },
  { "key": "AI", "value": "Anguilla", "text": "Anguilla", "dialCode": "+1264" },
  { "key": "AQ", "value": "Antarctica", "text": "Antarctica", "dialCode": "+672" },
  { "key": "AG", "value": "Antigua and Barbuda", "text": "Antigua and Barbuda", "dialCode": "+1268" },
  { "key": "AR", "value": "Argentina", "text": "Argentina", "dialCode": "+54" },
  { "key": "AM", "value": "Armenia", "text": "Armenia", "dialCode": "+374" },
  { "key": "AW", "value": "Aruba", "text": "Aruba", "dialCode": "+297" },
  { "key": "AU", "value": "Australia", "text": "Australia", "dialCode": "+61" },
  { "key": "AT", "value": "Austria", "text": "Austria", "dialCode": "+43" },
  { "key": "AZ", "value": "Azerbaijan", "text": "Azerbaijan", "dialCode": "+994" },
  { "key": "BS", "value": "Bahamas", "text": "Bahamas", "dialCode": "+1242" },
  { "key": "BH", "value": "Bahrain", "text": "Bahrain", "dialCode": "+973" },
  { "key": "BD", "value": "Bangladesh", "text": "Bangladesh", "dialCode": "+880" },
  { "key": "BB", "value": "Barbados", "text": "Barbados", "dialCode": "+1246" },
  { "key": "BY", "value": "Belarus", "text": "Belarus", "dialCode": "+375" },
  { "key": "BE", "value": "Belgium", "text": "Belgium", "dialCode": "+32" },
  { "key": "BZ", "value": "Belize", "text": "Belize", "dialCode": "+501" },
  { "key": "BJ", "value": "Benin", "text": "Benin", "dialCode": "+229" },
  { "key": "BM", "value": "Bermuda", "text": "Bermuda", "dialCode": "+1441" },
  { "key": "BT", "value": "Bhutan", "text": "Bhutan", "dialCode": "+975" },
  { "key": "BO", "value": "Bolivia", "text": "Bolivia", "dialCode": "+591" },
  { "key": "BA", "value": "Bosnia and Herzegovina", "text": "Bosnia and Herzegovina", "dialCode": "+387" },
  { "key": "BW", "value": "Botswana", "text": "Botswana", "dialCode": "+267" },
  { "key": "BV", "value": "Bouvet Island", "text": "Bouvet Island", "dialCode": "+47" },
  { "key": "BR", "value": "Brazil", "text": "Brazil", "dialCode": "+55" },
  { "key": "IO", "value": "British Indian Ocean Territory", "text": "British Indian Ocean Territory", "dialCode": "+246" },
  { "key": "BN", "value": "Brunei Darussalam", "text": "Brunei Darussalam", "dialCode": "+673" },
  { "key": "BG", "value": "Bulgaria", "text": "Bulgaria", "dialCode": "+359" },
  { "key": "BF", "value": "Burkina Faso", "text": "Burkina Faso", "dialCode": "+226" },
  { "key": "BI", "value": "Burundi", "text": "Burundi", "dialCode": "+257" },
  { "key": "KH", "value": "Cambodia", "text": "Cambodia", "dialCode": "+855" },
  { "key": "CM", "value": "Cameroon", "text": "Cameroon", "dialCode": "+237" },
  { "key": "CA", "value": "Canada", "text": "Canada", "dialCode": "+1" },
  { "key": "CV", "value": "Cape Verde", "text": "Cape Verde", "dialCode": "+238" },
  { "key": "KY", "value": "Cayman Islands", "text": "Cayman Islands", "dialCode": "+ 345" },
  { "key": "CF", "value": "Central African Republic", "text": "Central African Republic", "dialCode": "+236" },
  { "key": "TD", "value": "Chad", "text": "Chad", "dialCode": "+235" },
  { "key": "CL", "value": "Chile", "text": "Chile", "dialCode": "+56" },
  { "key": "CN", "value": "China", "text": "China", "dialCode": "+86" },
  { "key": "CX", "value": "Christmas Island", "text": "Christmas Island", "dialCode": "+61" },
  { "key": "CC", "value": "Cocos (Keeling) Islands", "text": "Cocos (Keeling) Islands", "dialCode": "+61" },
  { "key": "CO", "value": "Colombia", "text": "Colombia", "dialCode": "+57" },
  { "key": "KM", "value": "Comoros", "text": "Comoros", "dialCode": "+269" },
  { "key": "CG", "value": "Congo", "text": "Congo", "dialCode": "+242" },
  { "key": "CD", "value": "Congo, The Democratic Republic of the", "text": "Congo, The Democratic Republic of the", "dialCode": "+243" },
  { "key": "CK", "value": "Cook Islands", "text": "Cook Islands", "dialCode": "+682" },
  { "key": "CR", "value": "Costa Rica", "text": "Costa Rica", "dialCode": "+506" },
  { "key": "CI", "value": "Cote D'Ivoire", "text": "Cote D\"Ivoire", "dialCode": "+225" },
  { "key": "HR", "value": "Croatia", "text": "Croatia", "dialCode": "+385" },
  { "key": "CU", "value": "Cuba", "text": "Cuba", "dialCode": "+53" },
  { "key": "CY", "value": "Cyprus", "text": "Cyprus", "dialCode": "+357" },
  { "key": "CZ", "value": "Czech Republic", "text": "Czech Republic", "dialCode": "+420" },
  { "key": "DK", "value": "Denmark", "text": "Denmark", "dialCode": "+45" },
  { "key": "DJ", "value": "Djibouti", "text": "Djibouti", "dialCode": "+253" },
  { "key": "DM", "value": "Dominica", "text": "Dominica", "dialCode": "+1767" },
  { "key": "DO", "value": "Dominican Republic", "text": "Dominican Republic", "dialCode": "+1849" },
  { "key": "EC", "value": "Ecuador", "text": "Ecuador", "dialCode": "+593" },
  { "key": "EG", "value": "Egypt", "text": "Egypt", "dialCode": "+20" },
  { "key": "SV", "value": "El Salvador", "text": "El Salvador", "dialCode": "+503" },
  { "key": "GQ", "value": "Equatorial Guinea", "text": "Equatorial Guinea", "dialCode": "+240" },
  { "key": "ER", "value": "Eritrea", "text": "Eritrea", "dialCode": "+291" },
  { "key": "EE", "value": "Estonia", "text": "Estonia", "dialCode": "+372" },
  { "key": "ET", "value": "Ethiopia", "text": "Ethiopia", "dialCode": "+251" },
  { "key": "FK", "value": "Falkland Islands (Malvinas)", "text": "Falkland Islands (Malvinas)", "dialCode": "+500" },
  { "key": "FO", "value": "Faroe Islands", "text": "Faroe Islands", "dialCode": "+298" },
  { "key": "FJ", "value": "Fiji", "text": "Fiji", "dialCode": "+679" },
  { "key": "FI", "value": "Finland", "text": "Finland", "dialCode": "+358" },
  { "key": "FR", "value": "France", "text": "France", "dialCode": "+33" },
  { "key": "GF", "value": "French Guiana", "text": "French Guiana", "dialCode": "+594" },
  { "key": "PF", "value": "French Polynesia", "text": "French Polynesia", "dialCode": "+689" },
  { "key": "TF", "value": "French Southern Territories", "text": "French Southern Territories", "dialCode": "+262" },
  { "key": "GA", "value": "Gabon", "text": "Gabon", "dialCode": "+241" },
  { "key": "GM", "value": "Gambia", "text": "Gambia", "dialCode": "+220" },
  { "key": "GE", "value": "Georgia", "text": "Georgia", "dialCode": "+995" },
  { "key": "DE", "value": "Germany", "text": "Germany", "dialCode": "+49" },
  { "key": "GH", "value": "Ghana", "text": "Ghana", "dialCode": "+233" },
  { "key": "GI", "value": "Gibraltar", "text": "Gibraltar", "dialCode": "+350" },
  { "key": "GR", "value": "Greece", "text": "Greece", "dialCode": "+30" },
  { "key": "GL", "value": "Greenland", "text": "Greenland", "dialCode": "+299" },
  { "key": "GD", "value": "Grenada", "text": "Grenada", "dialCode": "+1473" },
  { "key": "GP", "value": "Guadeloupe", "text": "Guadeloupe", "dialCode": "+590" },
  { "key": "GU", "value": "Guam", "text": "Guam", "dialCode": "+1671" },
  { "key": "GT", "value": "Guatemala", "text": "Guatemala", "dialCode": "+502" },
  { "key": "GG", "value": "Guernsey", "text": "Guernsey", "dialCode": "+44" },
  { "key": "GN", "value": "Guinea", "text": "Guinea", "dialCode": "+224" },
  { "key": "GW", "value": "Guinea-Bissau", "text": "Guinea-Bissau", "dialCode": "+245" },
  { "key": "GY", "value": "Guyana", "text": "Guyana", "dialCode": "+595" },
  { "key": "HT", "value": "Haiti", "text": "Haiti", "dialCode": "+509" },
  { "key": "HM", "value": "Heard Island and Mcdonald Islands", "text": "Heard Island and Mcdonald Islands", "dialCode": "+672" },
  { "key": "VA", "value": "Holy See (Vatican City State)", "text": "Holy See (Vatican City State)", "dialCode": "+379" },
  { "key": "HN", "value": "Honduras", "text": "Honduras", "dialCode": "+504" },
  { "key": "HK", "value": "Hong Kong", "text": "Hong Kong", "dialCode": "+852" },
  { "key": "HU", "value": "Hungary", "text": "Hungary", "dialCode": "+36" },
  { "key": "IS", "value": "Iceland", "text": "Iceland", "dialCode": "+354" },
  { "key": "IN", "value": "India", "text": "India", "dialCode": "+91" },
  { "key": "ID", "value": "Indonesia", "text": "Indonesia", "dialCode": "+62" },
  { "key": "IR", "value": "Iran, Islamic Republic Of", "text": "Iran, Islamic Republic Of", "dialCode": "+98" },
  { "key": "IQ", "value": "Iraq", "text": "Iraq", "dialCode": "+964" },
  { "key": "IE", "value": "Ireland", "text": "Ireland", "dialCode": "+353" },
  { "key": "IM", "value": "Isle of Man", "text": "Isle of Man", "dialCode": "+44" },
  { "key": "IL", "value": "Israel", "text": "Israel", "dialCode": "+972" },
  { "key": "IT", "value": "Italy", "text": "Italy", "dialCode": "+39" },
  { "key": "JM", "value": "Jamaica", "text": "Jamaica", "dialCode": "+1876" },
  { "key": "JP", "value": "Japan", "text": "Japan", "dialCode": "+81" },
  { "key": "JE", "value": "Jersey", "text": "Jersey", "dialCode": "+44" },
  { "key": "JO", "value": "Jordan", "text": "Jordan", "dialCode": "+962" },
  { "key": "KZ", "value": "Kazakhstan", "text": "Kazakhstan", "dialCode": "+77" },
  { "key": "KE", "value": "Kenya", "text": "Kenya", "dialCode": "+254" },
  { "key": "KI", "value": "Kiribati", "text": "Kiribati", "dialCode": "+686" },
  { "key": "KP", "value": "Korea, Democratic People'S Republic of", "text": "Korea, Democratic People'S Republic of", "dialCode": "+850" },
  { "key": "KR", "value": "Korea, Republic of", "text": "Korea, Republic of", "dialCode": "+82" },
  { "key": "KW", "value": "Kuwait", "text": "Kuwait", "dialCode": "+965" },
  { "key": "KG", "value": "Kyrgyzstan", "text": "Kyrgyzstan", "dialCode": "+996" },
  { "key": "LA", "value": "Lao People'S Democratic Republic", "text": "Lao People'S Democratic Republic", "dialCode": "+856" },
  { "key": "LV", "value": "Latvia", "text": "Latvia", "dialCode": "+371" },
  { "key": "LB", "value": "Lebanon", "text": "Lebanon", "dialCode": "+961" },
  { "key": "LS", "value": "Lesotho", "text": "Lesotho", "dialCode": "+266" },
  { "key": "LR", "value": "Liberia", "text": "Liberia", "dialCode": "+231" },
  { "key": "LY", "value": "Libyan Arab Jamahiriya", "text": "Libyan Arab Jamahiriya", "dialCode": "+218" },
  { "key": "LI", "value": "Liechtenstein", "text": "Liechtenstein", "dialCode": "+423" },
  { "key": "LT", "value": "Lithuania", "text": "Lithuania", "dialCode": "+370" },
  { "key": "LU", "value": "Luxembourg", "text": "Luxembourg", "dialCode": "+352" },
  { "key": "MO", "value": "Macao", "text": "Macao", "dialCode": "+853" },
  { "key": "MK", "value": "Macedonia", "text": "Macedonia", "dialCode": "+389" },
  { "key": "MG", "value": "Madagascar", "text": "Madagascar", "dialCode": "+261" },
  { "key": "MW", "value": "Malawi", "text": "Malawi", "dialCode": "+265" },
  { "key": "MY", "value": "Malaysia", "text": "Malaysia", "dialCode": "+60" },
  { "key": "MV", "value": "Maldives", "text": "Maldives", "dialCode": "+960" },
  { "key": "ML", "value": "Mali", "text": "Mali", "dialCode": "+223" },
  { "key": "MT", "value": "Malta", "text": "Malta", "dialCode": "+356" },
  { "key": "MH", "value": "Marshall Islands", "text": "Marshall Islands", "dialCode": "+692" },
  { "key": "MQ", "value": "Martinique", "text": "Martinique", "dialCode": "+596" },
  { "key": "MR", "value": "Mauritania", "text": "Mauritania", "dialCode": "+222" },
  { "key": "MU", "value": "Mauritius", "text": "Mauritius", "dialCode": "+230" },
  { "key": "YT", "value": "Mayotte", "text": "Mayotte", "dialCode": "+262" },
  { "key": "MX", "value": "Mexico", "text": "Mexico", "dialCode": "+52" },
  { "key": "FM", "value": "Micronesia, Federated States of", "text": "Micronesia, Federated States of", "dialCode": "+691" },
  { "key": "MD", "value": "Moldova, Republic of", "text": "Moldova, Republic of", "dialCode": "+373" },
  { "key": "MC", "value": "Monaco", "text": "Monaco", "dialCode": "+377" },
  { "key": "MN", "value": "Mongolia", "text": "Mongolia", "dialCode": "+976" },
  { "key": "MS", "value": "Montserrat", "text": "Montserrat", "dialCode": "+1664" },
  { "key": "MA", "value": "Morocco", "text": "Morocco", "dialCode": "+212" },
  { "key": "MZ", "value": "Mozambique", "text": "Mozambique", "dialCode": "+258" },
  { "key": "MM", "value": "Myanmar", "text": "Myanmar", "dialCode": "+95" },
  { "key": "NA", "value": "Namibia", "text": "Namibia", "dialCode": "+264" },
  { "key": "NR", "value": "Nauru", "text": "Nauru", "dialCode": "+674" },
  { "key": "NP", "value": "Nepal", "text": "Nepal", "dialCode": "+977" },
  { "key": "NL", "value": "Netherlands", "text": "Netherlands", "dialCode": "+31" },
  { "key": "AN", "value": "Netherlands Antilles", "text": "Netherlands Antilles", "dialCode": "+599" },
  { "key": "NC", "value": "New Caledonia", "text": "New Caledonia", "dialCode": "+687" },
  { "key": "NZ", "value": "New Zealand", "text": "New Zealand", "dialCode": "+64" },
  { "key": "NI", "value": "Nicaragua", "text": "Nicaragua", "dialCode": "+505" },
  { "key": "NE", "value": "Niger", "text": "Niger", "dialCode": "+227" },
  { "key": "NG", "value": "Nigeria", "text": "Nigeria", "dialCode": "+234" },
  { "key": "NU", "value": "Niue", "text": "Niue", "dialCode": "+683" },
  { "key": "NF", "value": "Norfolk Island", "text": "Norfolk Island", "dialCode": "+672" },
  { "key": "MP", "value": "Northern Mariana Islands", "text": "Northern Mariana Islands", "dialCode": "+1670" },
  { "key": "NO", "value": "Norway", "text": "Norway", "dialCode": "+47" },
  { "key": "OM", "value": "Oman", "text": "Oman", "dialCode": "+968" },
  { "key": "PK", "value": "Pakistan", "text": "Pakistan", "dialCode": "+92" },
  { "key": "PW", "value": "Palau", "text": "Palau", "dialCode": "+680" },
  { "key": "PS", "value": "Palestinian Territory, Occupied", "text": "Palestinian Territory, Occupied", "dialCode": "+970" },
  { "key": "PA", "value": "Panama", "text": "Panama", "dialCode": "+507" },
  { "key": "PG", "value": "Papua New Guinea", "text": "Papua New Guinea", "dialCode": "+675" },
  { "key": "PY", "value": "Paraguay", "text": "Paraguay", "dialCode": "+595" },
  { "key": "PE", "value": "Peru", "text": "Peru", "dialCode": "+51" },
  { "key": "PH", "value": "Philippines", "text": "Philippines", "dialCode": "+63" },
  { "key": "PN", "value": "Pitcairn", "text": "Pitcairn", "dialCode": "+872" },
  { "key": "PL", "value": "Poland", "text": "Poland", "dialCode": "+48" },
  { "key": "PT", "value": "Portugal", "text": "Portugal", "dialCode": "+351" },
  { "key": "PR", "value": "Puerto Rico", "text": "Puerto Rico", "dialCode": "+1939" },
  { "key": "QA", "value": "Qatar", "text": "Qatar", "dialCode": "+974" },
  { "key": "RE", "value": "Reunion", "text": "Reunion", "dialCode": "+262" },
  { "key": "RO", "value": "Romania", "text": "Romania", "dialCode": "+40" },
  { "key": "RU", "value": "Russia", "text": "Russia", "dialCode": "+7" },
  { "key": "RW", "value": "Rwanda", "text": "Rwanda", "dialCode": "+250" },
  { "key": "SH", "value": "Saint Helena", "text": "Saint Helena", "dialCode": "+290" },
  { "key": "KN", "value": "Saint Kitts and Nevis", "text": "Saint Kitts and Nevis", "dialCode": "+1869" },
  { "key": "LC", "value": "Saint Lucia", "text": "Saint Lucia", "dialCode": "+1758" },
  { "key": "PM", "value": "Saint Pierre and Miquelon", "text": "Saint Pierre and Miquelon", "dialCode": "+508" },
  { "key": "VC", "value": "Saint Vincent and the Grenadines", "text": "Saint Vincent and the Grenadines", "dialCode": "+1784" },
  { "key": "WS", "value": "Samoa", "text": "Samoa", "dialCode": "+685" },
  { "key": "SM", "value": "San Marino", "text": "San Marino", "dialCode": "+378" },
  { "key": "ST", "value": "Sao Tome and Principe", "text": "Sao Tome and Principe", "dialCode": "+239" },
  { "key": "SA", "value": "Saudi Arabia", "text": "Saudi Arabia", "dialCode": "+966" },
  { "key": "SN", "value": "Senegal", "text": "Senegal", "dialCode": "+221" },
  { "key": "CS", "value": "Serbia", "text": "Serbia", "dialCode": "+381" },
  { "key": "SC", "value": "Seychelles", "text": "Seychelles", "dialCode": "+248" },
  { "key": "SL", "value": "Sierra Leone", "text": "Sierra Leone", "dialCode": "+232" },
  { "key": "SG", "value": "Singapore", "text": "Singapore", "dialCode": "+65" },
  { "key": "SK", "value": "Slovakia", "text": "Slovakia", "dialCode": "+421" },
  { "key": "SI", "value": "Slovenia", "text": "Slovenia", "dialCode": "+386" },
  { "key": "SB", "value": "Solomon Islands", "text": "Solomon Islands", "dialCode": "+677" },
  { "key": "SO", "value": "Somalia", "text": "Somalia", "dialCode": "+252" },
  { "key": "ZA", "value": "South Africa", "text": "South Africa", "dialCode": "+27" },
  { "key": "GS", "value": "South Georgia and the South Sandwich Islands", "text": "South Georgia and the South Sandwich Islands", "dialCode": "+500" },
  { "key": "ES", "value": "Spain", "text": "Spain", "dialCode": "+34" },
  { "key": "LK", "value": "Sri Lanka", "text": "Sri Lanka", "dialCode": "+94" },
  { "key": "SD", "value": "Sudan", "text": "Sudan", "dialCode": "+249" },
  { "key": "SR", "value": "Suriname", "text": "Suriname", "dialCode": "+597" },
  { "key": "SJ", "value": "Svalbard and Jan Mayen", "text": "Svalbard and Jan Mayen", "dialCode": "+47" },
  { "key": "SZ", "value": "Swaziland", "text": "Swaziland", "dialCode": "+268" },
  { "key": "SE", "value": "Sweden", "text": "Sweden", "dialCode": "+46" },
  { "key": "CH", "value": "Switzerland", "text": "Switzerland", "dialCode": "+41" },
  { "key": "SY", "value": "Syrian Arab Republic", "text": "Syrian Arab Republic", "dialCode": "+963" },
  { "key": "TW", "value": "Taiwan, Province of China", "text": "Taiwan, Province of China", "dialCode": "+886" },
  { "key": "TJ", "value": "Tajikistan", "text": "Tajikistan", "dialCode": "+992" },
  { "key": "TZ", "value": "Tanzania, United Republic of", "text": "Tanzania, United Republic of", "dialCode": "+255" },
  { "key": "TH", "value": "Thailand", "text": "Thailand", "dialCode": "+66" },
  { "key": "TL", "value": "Timor-Leste", "text": "Timor-Leste", "dialCode": "+670" },
  { "key": "TG", "value": "Togo", "text": "Togo", "dialCode": "+228" },
  { "key": "TK", "value": "Tokelau", "text": "Tokelau", "dialCode": "+690" },
  { "key": "TO", "value": "Tonga", "text": "Tonga", "dialCode": "+676" },
  { "key": "TT", "value": "Trinidad and Tobago", "text": "Trinidad and Tobago", "dialCode": "+1868" },
  { "key": "TN", "value": "Tunisia", "text": "Tunisia", "dialCode": "+216" },
  { "key": "TR", "value": "Turkey", "text": "Turkey", "dialCode": "+90" },
  { "key": "TM", "value": "Turkmenistan", "text": "Turkmenistan", "dialCode": "+993" },
  { "key": "TC", "value": "Turks and Caicos Islands", "text": "Turks and Caicos Islands", "dialCode": "+1649" },
  { "key": "TV", "value": "Tuvalu", "text": "Tuvalu", "dialCode": "+688" },
  { "key": "UG", "value": "Uganda", "text": "Uganda", "dialCode": "+256" },
  { "key": "UA", "value": "Ukraine", "text": "Ukraine", "dialCode": "+380" },
  { "key": "AE", "value": "United Arab Emirates", "text": "United Arab Emirates", "dialCode": "+971" },
  { "key": "GB", "value": "United Kingdom", "text": "United Kingdom", "dialCode": "+44" },
  { "key": "US", "value": "United States", "text": "United States", "dialCode": "+1" },
  { "key": "UM", "value": "United States Minor Outlying Islands", "text": "United States Minor Outlying Islands", "dialCode": "+1" },
  { "key": "UY", "value": "Uruguay", "text": "Uruguay", "dialCode": "+598" },
  { "key": "UZ", "value": "Uzbekistan", "text": "Uzbekistan", "dialCode": "+998" },
  { "key": "VU", "value": "Vanuatu", "text": "Vanuatu", "dialCode": "+678" },
  { "key": "VE", "value": "Venezuela", "text": "Venezuela", "dialCode": "+58" },
  { "key": "VN", "value": "Viet Nam", "text": "Viet Nam", "dialCode": "+84" },
  { "key": "VG", "value": "Virgin Islands, British", "text": "Virgin Islands, British", "dialCode": "+1284" },
  { "key": "VI", "value": "Virgin Islands, U.S.", "text": "Virgin Islands, U.S.", "dialCode": "+1340" },
  { "key": "WF", "value": "Wallis and Futuna", "text": "Wallis and Futuna", "dialCode": "+681" },
  { "key": "EH", "value": "Western Sahara", "text": "Western Sahara", "dialCode": "+212" },
  { "key": "YE", "value": "Yemen", "text": "Yemen", "dialCode": "+967" },
  { "key": "ZM", "value": "Zambia", "text": "Zambia", "dialCode": "+260" },
  { "key": "ZW", "value": "Zimbabwe", "text": "Zimbabwe", "dialCode": "+263" }
];
