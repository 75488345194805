import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Sidemenu from './Sidemenu';

const mapStateToProps = (state) => {
  return {    
    device: state.resolution.device,
  };
};


class HowDoesItWork extends Component<any> {
  render() {
    const isMobile = this.props.device === 'mobile' || this.props.device === 'mobile-tablet';

    if(isMobile)
    {
      return(
        <div>
          <Sidemenu />
          <main>
            <h2>So, how does it work?</h2>
            <p>
              Once you register as a tester, complete your profile and activate your account so we
              can send appropriate projects your way Take the online testing exam so we can
              determine your initial score Once we have a relevant project that matches your score
              and profile, we will send it to you. You can either accept or reject a project. You
              need to accept a project within the pre-defined timeframe. You will be notified of a
              new project via email and it will also appear on your dashboard. We will collect all
              acceptances and assemble the group of testers for the project. We select the top-rated
              testers among those who accepted the project. If you are selected you will be notified
              and you can start working. Your work needs to be completed within 24 hours from the
              time you were notified that you were selected for the project Once your work is
              completed, you will need to submit your report. Now it’s time to get paid!
            </p>
            <h3>How Do I Get Paid?</h3>
            <p>
              When setting up your account, you name your hourly rate. Once a paid test job is
              completed, accepted and approved by Testpoint and by our client, your account will be
              credited based on your hourly rate and the hours you worked on the project, deducted
              by Testpoint commission fees. Credit is available for withdrawal by the 15th of the
              following months to your Pay Pal account.
            </p>
            <p>
              If the cycle ends before the 15th, the payment will be available for withdrawal at the
              following month at the 15th. If the cycle ends after the 15th, the payment will be
              available for withdrawal at the 15th after the following month (1.5 months). Become a
              successful Testpoint-Tester! After completing each project, the project owner will be asked
              to rate and review the work of each tester. Your rating will also be influenced by
              meeting deadlines and the accuracy of your work, based on the project requirements.
              Customers (project owners) have the option of adding you to their favorite testers
              list, meaning they want you in their future project. We will take that under
              consideration when assembling the team of testers for their next project. High rating
              means that your chances of being offered future testing projects are significantly
              higher than others. If you answer all project requirements (type of device, geography,
              etc.), rating is the first consideration when assembling the team that will work on
              the project.
            </p>
          </main>
        </div>      
      );
    }
    return (
      <div className="account-tester page">
        <div className="heading">
          <h1>How Does It Work</h1>
        </div>

        <div className="inner">
          <Sidemenu />
          <main>
            <h2>So, how does it work?</h2>
            <p>
              Once you register as a tester, complete your profile and activate your account so we
              can send appropriate projects your way Take the online testing exam so we can
              determine your initial score Once we have a relevant project that matches your score
              and profile, we will send it to you. You can either accept or reject a project. You
              need to accept a project within the pre-defined timeframe. You will be notified of a
              new project via email and it will also appear on your dashboard. We will collect all
              acceptances and assemble the group of testers for the project. We select the top-rated
              testers among those who accepted the project. If you are selected you will be notified
              and you can start working. Your work needs to be completed within 24 hours from the
              time you were notified that you were selected for the project Once your work is
              completed, you will need to submit your report. Now it’s time to get paid!
            </p>
            <h3>How Do I Get Paid?</h3>
            <p>
              When setting up your account, you name your hourly rate. Once a paid test job is
              completed, accepted and approved by Testpoint and by our client, your account will be
              credited based on your hourly rate and the hours you worked on the project, deducted
              by Testpoint commission fees. Credit is available for withdrawal by the 15th of the
              following months to your Pay Pal account.
            </p>
            <p>
              If the cycle ends before the 15th, the payment will be available for withdrawal at the
              following month at the 15th. If the cycle ends after the 15th, the payment will be
              available for withdrawal at the 15th after the following month (1.5 months). Become a
              successful Testpoint-tester! After completing each project, the project owner will be asked
              to rate and review the work of each tester. Your rating will also be influenced by
              meeting deadlines and the accuracy of your work, based on the project requirements.
              Customers (project owners) have the option of adding you to their favorite testers
              list, meaning they want you in their future project. We will take that under
              consideration when assembling the team of testers for their next project. High rating
              means that your chances of being offered future testing projects are significantly
              higher than others. If you answer all project requirements (type of device, geography,
              etc.), rating is the first consideration when assembling the team that will work on
              the project.
            </p>
          </main>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, null)(HowDoesItWork));