import { DevicePlatform } from '../../types/DevicePlatform';
import { getPlatformPrettyName } from '../../services/deviceService';

export default Object.entries(DevicePlatform).reduce((result, [key, val]) => {
  result.push({
    text: getPlatformPrettyName(val),
    key: val,
    value: val,
  });

  return result;
}, <any>[]);
