import React from 'react';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import DurationBar from '../common/DurationBar';
import { RootState } from '../../../redux/store';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: '30px 15px 15px',
        [theme.breakpoints.down('lg')] : {
            padding: '5px 15px 5px',            
        },        
        borderBottom: `1px solid ${theme.palette.primary.light}`,
    },
}));

const ServiceInfo: React.FC<{ cycleId: string }> = ({ cycleId }) => {
    const classes = useStyles();

    const { cycles, resolution } = useSelector((state: RootState) => state);

    const cycle: any = cycles.find((cycle: any) => cycle.id === +cycleId);    
    
    const isMobile = resolution.device === 'mobile' || resolution.device === 'mobile-tablet';
    /*
    if (every([testingLeftTime, serviceLeftTime], (time) => time < 0)) {
        return null;
    } */

    return (
        <Box display="flex" flexDirection={isMobile ? "column": "row"} className={classes.root}>
            <DurationBar
                title="Testing time:"
                start={cycle?.startDate}
                end={cycle?.endDate}
            />

            <DurationBar
                title="Service time:"
                start={cycle?.endDate}
                end={cycle?.questionsTime}
                styled
            />
        </Box>
    );
}

export default ServiceInfo;
