import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import authReducer from './reducers/authReducer';
import registrationReducer from './reducers/registrationReducer';
import resolutionReducer from './reducers/resolutionReducer';

import { BugSeverity } from '../types/BugSeverity';
import { Gender } from '../types/Gender';
import { NewCycle } from '../types/NewCycle';
import { NewWizardCycle } from '../types/NewWizardCycle';
import { OperationStatus } from '../types/OperationStatus';
import { ReportingCategory } from '../types/reportingCategory';
import { TesterStatusInCycle } from '../types/TesterStatusInCycle';
import { TestPlanStepStatus } from '../types/TestPlanStepStatus';
import { UserRole } from '../types/UserRole';
import { DeliveryTimeCategory, QuestionTimeCategory } from '../types/WizardCategoriesTypes';
import { WorkCategory } from '../types/WorkCategory';

import config from '../config';

const initialNewCycle: NewCycle = {
  deliveryTime: '3days',
  workCategory: '',
  gender: Gender.Any,
  useFavoriteTesters: false,
  testEnvs: [],
  platformUrls: [],
  countries: [],
  hourlyRate: 0,
  hoursPerTester: 3,
  title: '',
  version: '',
  description: '',
  testPlan: [],
};

const initialNewWizardCycle: NewWizardCycle = {
  deliveryTime: DeliveryTimeCategory._24hours,
  questionsTime: QuestionTimeCategory._24hours,
  workCategory: WorkCategory.FindBugsExploratory,
  gender: Gender.Any,
  useFavoriteTesters: false,
  testEnvs: [],
  platformUrls: [],
  countries: [],
  hourlyRate: 0,
  hoursPerTester: 1,
  title: '',
  version: '',
  description: '',
  testPlan: [],
  testSpecification: '',

  isWorldwide: true,
  startingPointURL: '',
  isWithPromoCode: false,
  promoCode: '',
  isTestPlanFromFile: false,
  reportingCategories: Object.keys(ReportingCategory).map(key => ReportingCategory[key]),
  mainTestPlatform: { id: -1, name: '', minOSVersion: { id: -1, name: '' }, maxOSVersion: { id: -1, name: '' } },
  relevantBrowsers: [],

  isSpecificDevices: false,
  numberOfTesters: 5,
  relevantDevices: [],
  specificationPerCountry: [],
  couponDetails: { id: -1, code: '', discount: 0 },
}

export const initialState = {
  resolution: {
    device: null,
    size: null,
  },
  loginStatus: {
    status: OperationStatus.notStarted,
    errorMessage: '',
  },
  impersonateStatus: {
    status: OperationStatus.notStarted,
    errorMessage: '',
  },
  registerStatus: {
    status: OperationStatus.notStarted,
    errorMessage: '',
  },
  registerVerificationStatus: {
    status: OperationStatus.notStarted,
    errorMessage: '',
  },
  sendPasswordResetEmailStatus: {
    status: OperationStatus.notStarted,
    errorMessage: '',
  },
  passwordResetStatus: {
    status: OperationStatus.notStarted,
    errorMessage: '',
  },
  passwordChangeStatus: {
    status: OperationStatus.notStarted,
    errorMessage: '',
  },
  userSaveStatus: {
    // TODO consistent order in nameing (objectOperation or operationObject everywhere)
    status: OperationStatus.notStarted,
    errorMessage: '',
  },
  userUploadAvatarStatus: {
    // TODO consistent order in nameing (objectOperation or operationObject everywhere)
    status: OperationStatus.notStarted,
    errorMessage: '',
  },
  userSavePhoneStatus: {
    status: OperationStatus.notStarted,
    errorMessage: '',
  },
  couponFetchStatus: {
    status: OperationStatus.notStarted,
    errorMessage: '',
  },
  addDeviceStatus: {
    status: OperationStatus.notStarted,
    errorMessage: '',
  },
  deleteDeviceStatus: {
    status: OperationStatus.notStarted,
    errorMessage: '',
  },
  addBrowserStatus: {
    status: OperationStatus.notStarted,
    errorMessage: '',
  },
  deleteBrowserStatus: {
    status: OperationStatus.notStarted,
    errorMessage: '',
  },
  cycleCreateStatus: {
    status: OperationStatus.notStarted,
    errorMessage: '',
    cycleId: -1,
  },
  cycleAcceptStatus: {
    status: OperationStatus.notStarted,
    errorMessage: '',
  },
  platformRestructionStatus: {
    status: OperationStatus.notStarted,
    errorMessage: '',
  },
  chatMessageSentStatus: {
    status: OperationStatus.notStarted,
    errorMessage: '',
  },
  createBugStatus: {
    status: OperationStatus.notStarted,
    errorMessage: '',
  },
  editBugStatus: {
    status: OperationStatus.notStarted,
    errorMessage: '',
    bugStatus: '',
  },
  withdrawalStatus: {
    status: OperationStatus.notStarted,
    errorMessage: '',
  },
  listOwnFinanceStatus: {
    status: OperationStatus.notStarted,
    errorMessage: '',
  },

  loginToken: null,

  user: <Record<string, any>>{
    role: UserRole.Anonymous,
  },
  userFinance: [],

  paymentOrderProperties: <Record<string, number>>{

  },

  //For a tester which is also an affiliate
  acssociatedCustomers: [],

  cycles: [],
  cycleBugs: {
    byCycleId: {},
  },
  cycleTesters: {
    byCycleId: {},
  },
  chatMessages: {
    byCycleId: {},
  },

  cyclesSlim: [],

  cycleDataFetchStatus: {
    status: OperationStatus.notStarted,
    errorMessage: '',
  },

  //For the new wizard, duplicated to support both old and new
  newWizardCycle: initialNewWizardCycle,
  newCycleCurrentMainStep: 0,
  newCycleCurrentInnerStep: 0,
  systemPlatforms: [],

  newCycle: initialNewCycle,
  newCycleCurrentStep: 1,
  newCycleCurrentStepErrors: [],
  newCycleTestersPage: 1,

  devices: {
    manufactureres: [],
    models: [],
    oses: [],
  },

  browsers: {
    names: [],
    versions: [],
  },

  potentialTesters: [],
  potentialTestersTotal: 0,

  favoriteTesters: {
    byId: {},
  },


  newBug: {
    cycleId: null,
    testPlanStepId: null,
    topic: '',
    stepsToReproduce: '',
    expectedResult: '',
    actualResult: '',
    comment: '',
    severity: BugSeverity.Low,
    imageFiles: [],
    videoFiles: [],
  },

  editBug: {
    publicId: null,
    cycleId: null,
    testPlanStepId: null,
    topic: '',
    stepsToReproduce: '',
    expectedResult: '',
    actualResult: '',
    comment: '',
    severity: BugSeverity.Low,
    imageFiles: [],
    videoFiles: [],
    imageFilePaths: [],
    videoFilePaths: [],
  },

  customerReviews: [],

  loginModal: {
    open: false,
  },
  registerModal: {
    open: false,
  },
  changePasswordModal: {
    open: false,
  },

  contactFormStatus: {
    status: OperationStatus.notStarted,
    errorMessage: '',
  },

  messageContent: '',
};

function tokenInvalid(state, action) {
  return {
    ...initialState,
    devices: { ...state.devices },
    browsers: { ...state.browsers },
  };
}

function unauthorizedRequest(state, action) {
  return { ...state }; // TODO
}

function loginDefault(state, action) {
  return {
    ...state,
    loginStatus: {
      status: OperationStatus.notStarted,
      errorMessage: '',
    },
  };
}

function loginStart(state, action) {
  return {
    ...state,
    loginStatus: authReducer(null, action),
  };
}

function loginSuccess(state, action) {
  const newState: Record<string, any> = {
    ...state,
    user: action.user,
    // loginStatus: authReducer(null, action)
    loginStatus: {
      status: OperationStatus.success,
      errorMessage: '',
    },
  };

  if (action.cycles) {
    // TODO DRY it with listCycles
    newState.cycles = action.cycles;

    const cycleTesters = {
      byCycleId: { ...state.cycleTesters.byCycleId },
    };
    const cycleBugs = {
      byCycleId: { ...state.cycleBugs.byCycleId },
    };
    const chatMessages = {
      byCycleId: { ...state.chatMessages.byCycleId },
    };

    action.cycles.forEach((cycle) => {
      if (cycle.testers) {
        cycleTesters.byCycleId[cycle.id] = cycle.testers;
        newState.cycleTesters = cycleTesters;
        delete cycle.testers;
      }

      if (cycle.bugs) {
        cycleBugs.byCycleId[cycle.id] = cycle.bugs;
        newState.cycleBugs = cycleBugs;
        delete cycle.bugs;
      }

      if (cycle.chatMessages) {
        chatMessages.byCycleId[cycle.id] = cycle.chatMessages;
        newState.chatMessages = chatMessages;
        delete cycle.chatMessages;
      }
    });
  }

  if (action.user.role === UserRole.Customer || action.user.role === UserRole.Viewer) {
    newState.newCycle.useFavoriteTesters = true;

    action.user.favoriteTesters.forEach((tester) => {
      newState.favoriteTesters.byId[tester.id] = tester;
    });
  }

  return newState;
}

function loginFail(state, action) {
  return {
    ...state,
    user: {
      role: UserRole.Anonymous,
    },
    loginStatus: authReducer(null, action),
  };
}

function impersonateStart(state, action) {
  return {
    ...state,
    impersonateStatus: {
      status: OperationStatus.processing,
      errorMessage: '',
    },
  };
}

function impersonateSuccess(state, action) {
  const newState: Record<string, any> = {
    ...state,
    user: action.user,
    loginStatus: {
      status: OperationStatus.success,
      errorMessage: '',
    },
    impersonateStatus: {
      status: OperationStatus.success,
      errorMessage: '',
    },
  };

  if (action.user.role === UserRole.Customer || action.user.role === UserRole.Viewer) {
    newState.newCycle.useFavoriteTesters = true;

    action.user.favoriteTesters.forEach((tester) => {
      newState.favoriteTesters.byId[tester.id] = tester;
    });
  }

  return newState;
}

function impersonateFail(state, action) {
  return {
    ...state,
    user: {
      role: UserRole.Anonymous,
    },
    loginStatus: {
      status: OperationStatus.notStarted,
      errorMessage: '',
    },
    impersonateStatus: {
      status: OperationStatus.fail,
      errorMessage: action.errorMessage,
    },
  };
}

function loginModalOpen(state, action) {
  return {
    ...state,
    loginModal: {
      ...state.loginModal,
      open: true,
    },
  };
}

function loginModalClose(state, action) {
  return {
    ...state,
    loginModal: {
      ...state.loginModal,
      open: false,
    },
    loginStatus: {
      ...initialState.loginStatus,
    },
  };
}

function registerModalOpen(state, action) {
  return {
    ...state,
    registerModal: {
      ...state.registerModal,
      open: true,
    },
  };
}

function registerModalClose(state, action) {
  return {
    ...state,
    registerModal: {
      ...state.registerModal,
      open: false,
    },
  };
}

function logout(state, action) {
  return {
    ...initialState,
    loginStatus: authReducer(null, action),
    devices: {
      ...state.devices,
    },
    browsers: {
      ...state.browsers,
    },
  };
}

function sendPasswordResetEmailStart(state, action) {
  return {
    ...state,
    sendPasswordResetEmailStatus: {
      status: OperationStatus.processing,
      errorMessage: '',
    },
  };
}

function sendPasswordResetEmailSuccess(state, action) {
  return {
    ...state,
    sendPasswordResetEmailStatus: {
      status: OperationStatus.success,
      errorMessage: '',
    },
  };
}

function sendPasswordResetEmailFail(state, action) {
  return {
    ...state,
    sendPasswordResetEmailStatus: {
      status: OperationStatus.fail,
      errorMessage: action.errorMessage,
    },
  };
}

function resetPasswordStart(state, action) {
  return {
    ...state,
    passwordResetStatus: {
      status: OperationStatus.processing,
      errorMessage: '',
    },
  };
}

function resetPasswordSuccess(state, action) {
  return {
    ...state,
    passwordResetStatus: {
      status: OperationStatus.success,
      errorMessage: '',
    },
  };
}

function resetPasswordFail(state, action) {
  return {
    ...state,
    passwordResetStatus: {
      status: OperationStatus.fail,
      errorMessage: action.errorMessage,
    },
  };
}

function resetPasswordRefreshStatus(state, action) {
  return {
    ...state,
    sendPasswordResetEmailStatus: {
      status: OperationStatus.notStarted,
      errorMessage: '',
    },
  };
}

function changePasswordModalOpen(state, action) {
  return {
    ...state,
    changePasswordModal: {
      ...state.changePasswordModal,
      open: true,
    },
  };
}

function changePasswordModalClose(state, action) {
  return {
    ...state,
    changePasswordModal: {
      ...state.changePasswordModal,
      open: false,
    },
    passwordChangeStatus: {
      ...state.passwordChangeStatus,
      errorMessage: '',
    },
  };
}

function changePasswordStart(state, action) {
  return {
    ...state,
    passwordChangeStatus: {
      status: OperationStatus.processing,
      errorMessage: '',
    },
  };
}

function changePasswordSuccess(state, action) {
  return {
    ...state,
    passwordChangeStatus: {
      status: OperationStatus.success,
      errorMessage: '',
    },
  };
}

function changePasswordFail(state, action) {
  return {
    ...state,
    passwordChangeStatus: {
      status: OperationStatus.fail,
      errorMessage: action.errorMessage,
    },
  };
}

function verifyRegistrationStart(state, action) {
  return {
    ...state,
    registerVerificationStatus: {
      status: OperationStatus.processing,
      errorMessage: '',
    },
  };
}

function verifyRegistrationSuccess(state, action) {
  return {
    ...state,
    registerVerificationStatus: {
      status: OperationStatus.success,
      errorMessage: '',
    },
    loginToken: action.loginToken,
  };
}

function verifyRegistrationFail(state, action) {
  return {
    ...state,
    registerVerificationStatus: {
      status: OperationStatus.fail,
      errorMessage: action.errorMessage,
    },
  };
}

function switchNewWizardMajorStep(state, action) {
  return {
    ...state,
    newCycleCurrentMainStep: action.newCycleCurrentMainStep,
    newCycleCurrentInnerStep: 0,
    newCycleCurrentStepErrors: [],
  };
}

function switchNewWizardInnerStep(state, action) {
  return {
    ...state,
    newCycleCurrentInnerStep: action.newCycleCurrentInnerStep,
    newCycleCurrentStepErrors: [],
  };
}


function switchNewCycleStep(state, action) {
  return {
    ...state,
    newCycleCurrentStep: action.newCycleCurrentStep,
    newCycleCurrentInnerStep: 0,
    newCycleCurrentStepErrors: [],
  };
}


function switchNewCycleStepFail(state, action) {
  return {
    ...state,
    newCycleCurrentStepErrors: action.errors,
  };
}

function potentialTestersPagination(state, action) {
  return {
    ...state,
    newCycleTestersPage: action.page,
  };
}

function listCyclesSlimSuccess(state, action) {
  return {
    ...state,
    cyclesSlim: action.cyclesSlim,
  };
}

function getCycleFullSuccess(state, action) {
  const cycleTesters = {
    byCycleId: { ...state.cycleTesters.byCycleId },
  };
  const cycleBugs = {
    byCycleId: { ...state.cycleBugs.byCycleId },
  };
  const chatMessages = {
    byCycleId: { ...state.chatMessages.byCycleId },
  };

  const cycle: any = action.cycle;

  let cycles = [...state.cycles];
  cycles.filter((el: any) => el.id !== action.cycle.id);

  if (cycle.testers) {
    cycleTesters.byCycleId[cycle.id] = cycle.testers;
    delete cycle.testers;
  }

  if (cycle.bugs) {
    cycleBugs.byCycleId[cycle.id] = cycle.bugs;
    delete cycle.bugs;
  }

  if (cycle.chatMessages) {
    chatMessages.byCycleId[cycle.id] = cycle.chatMessages;
    delete cycle.chatMessages;
  }

  cycle.testEnvs = cycle.testEnvs ? cycle.testEnvs : [...(cycle.testingEnvironments || [])];
  delete cycle.testingEnvironments;

  const updatedCycles = [...cycles, cycle];

  return {
    ...state,
    cycleDataFetchStatus: {
      status: OperationStatus.success,
      errorMessage: '',
    },
    cycles: updatedCycles,
    cycleTesters,
    cycleBugs,
    chatMessages,
  };
}

function listCyclesSuccess(state, action) {
  const cycleTesters = {
    byCycleId: { ...state.cycleTesters.byCycleId },
  };
  const cycleBugs = {
    byCycleId: { ...state.cycleBugs.byCycleId },
  };
  const chatMessages = {
    byCycleId: { ...state.chatMessages.byCycleId },
  };

  const cycles = action.cycles.map((cycle) => {
    if (cycle.testers) {
      cycleTesters.byCycleId[cycle.id] = cycle.testers;
      delete cycle.testers;
    }

    if (cycle.bugs) {
      cycleBugs.byCycleId[cycle.id] = cycle.bugs;
      delete cycle.bugs;
    }

    if (cycle.chatMessages) {
      chatMessages.byCycleId[cycle.id] = cycle.chatMessages;
      delete cycle.chatMessages;
    }

    cycle.testEnvs = cycle.testEnvs ? cycle.testEnvs : [...(cycle.testingEnvironments || [])];
    delete cycle.testingEnvironments;

    return cycle;
  });

  return {
    ...state,
    cycles,
    cycleTesters,
    cycleBugs,
    chatMessages,
  };
}

function updateCycle(state, action) {
  return {
    ...state,
    newCycle: {
      ...state.newCycle,
      ...action.newCycleData,
    },
  };
}

function updateWizardCycle(state, action) {
  return {
    ...state,
    newWizardCycle: {
      ...state.newWizardCycle,
      ...action.newCycleData,
    },
  };
}

function receivePaymentOrderProperties(state, action) {
  return {
    ...state,
    paymentOrderProperties: action.paymentOrderProperties,
  }
}

function getCycleFullStart(state, action) {
  return {
    ...state,
    cycleDataFetchStatus: {
      status: OperationStatus.processing,
      errorMessage: '',
    },
  };
}

function createCycleStart(state, action) {
  return {
    ...state,
    cycleCreateStatus: {
      status: OperationStatus.processing,
      errorMessage: '',
    },
  };
}

function createCycleSuccess(state, action) {
  return {
    ...state,
    newCycle: {
      ...initialState.newCycle,
    },
    newCycleCurrentStep: 1,
    newCycleCurrentStepErrors: [],
    newCycleTestersPage: 1,
    user: {
      ...state.user,
      balance: state.user.balance - action.cycle.totalPrice,
    },
    cycles: [...state.cycles, action.cycle],
    potentialTesters: [],
    potentialTestersTotal: 0,
    cycleCreateStatus: {
      status: OperationStatus.success,
      errorMessage: '',
    },
  };
}

function createWizardCycleSuccess(state, action) {
  return {
    ...state,
    newWizardCycle: {
      ...initialState.newWizardCycle,
    },
    newCycleCurrentMainStep: 0,
    newCycleCurrentInnerStep: 0,
    newCycleCurrentStepErrors: [],
    newCycleTestersPage: 1,
    user: {
      ...state.user,
      balance: state.user.balance - action.cycle.totalPrice > 0 ? state.user.balance - action.cycle.totalPrice : 0,
    },
    //cycles: [...state.cycles, action.cycle],
    cycleCreateStatus: {
      status: OperationStatus.success,
      errorMessage: '',
      cycleId: action.cycle.id,
    },
  };
}

function createCycleFail(state, action) {
  return {
    ...state,
    newCycleCurrentMainStep: 0,
    newCycleCurrentInnerStep: 0,
    newCycleCurrentStepErrors: [],
    cycleCreateStatus: {
      status: OperationStatus.fail,
      errorMessage: 'Unable to create the cycle. Please try again later',
      cycleId: -1,
    },
  };
}

function updateCycleUrlsSuccess(state, action) {
  const cycles = [...state.cycles];
  const idx: any = cycles.findIndex((el: any) => el.id === action.cycleId);

  for (const el of cycles[idx].testingEnvironments) {
    for (const name in action.urls) {
      if (el.name === name) {
        el.url = action.urls[name];
      }
    }
  }

  return {
    ...state,
    cycles,
  };
}

function getPotentialTestersSuccess(state, action) {
  return {
    ...state,
    potentialTesters: action.testers,
    potentialTestersTotal: action.total,
  };
}

function submitCustomerReviewSuccess(state, action) {
  const cycleTesters = [...state.cycleTesters.byCycleId[action.cycleId]];

  const tester: any = cycleTesters.find((el: any) => el.id === action.testerId);
  tester.statusInCycle = TesterStatusInCycle.Finished;

  return {
    ...state,
    cycleTesters: {
      byCycleId: {
        ...state.cycleTesters.byCycleId,
        [action.cycleId]: cycleTesters,
      },
    },
  };
}

function acceptCycleSuccess(state, action) {
  const cycles: any[] = state.cycles.map((el: any) => {
    if (el.id === action.cycleId) {
      if (el.id === config.evalCycleId) {
        el.testerStatus = TesterStatusInCycle.Approved;
      } else {
        el.testerStatus = TesterStatusInCycle.Pending;
      }
    }

    return el;
  });

  return {
    ...state,
    user: {
      ...state.user,
      cycles,
    },
    cycles,
    cycleAcceptStatus: {
      status: OperationStatus.success,
      errorMessage: '',
    },
  };
}

function completeCycleSuccess(state, action) {
  const completedCycle: any = state.cycles.find((el: any) => el.id === action.cycleId);

  completedCycle.testerStatus = TesterStatusInCycle.AwaitingReview;

  return {
    ...state,
    cycles: [...state.cycles, completedCycle],
  };
}

function testPlanStepUpdateSuccess(state, action) {
  const cycles = [...state.cycles];
  const cycle: any = cycles.find((el: any) => el.id === action.cycleId);
  let stepResult = cycle.testPlanResults.find(
    (result: any) => result.testPlanStepId === action.testPlanStepId
  );

  if (!stepResult) {
    stepResult = {
      testPlanStepId: action.testPlanStepId,
      status: action.status,
    };

    cycle.testPlanResults.push(stepResult);
  } else {
    stepResult.status = action.status;
  }

  const cycleBugs = [...state.cycleBugs.byCycleId[action.cycleId]];
  const attachedBug = cycleBugs.find((bug) => bug.testPlanStepId === action.testPlanStepId);

  if (attachedBug) {
    delete attachedBug.testPlanStepId;
  }

  return {
    ...state,
    cycles,
    cycleBugs: {
      byCycleId: {
        ...state.cycleBugs.byCycleId,
        [action.cycleId]: cycleBugs,
      },
    },
  };
}

function updateUser(state, action) {
  return {
    ...state,
    user: {
      ...state.user,
      ...action.user,
    },
  };
}

function updateUserPromo(state, action) {
  return {
    ...state,
    user: {
      ...state.user,
      affiliatePromo: action.affiliatePromo,
    },
  };
}

function saveUserStart(state, action) {
  return {
    ...state,
    userSaveStatus: {
      status: OperationStatus.processing,
      errorMessage: '',
    },
  };
}

function saveUserSuccess(state, action) {
  return {
    ...state,
    user: {
      ...state.user,
      ...action.userData,
    },
    userSaveStatus: {
      status: OperationStatus.success,
      errorMessage: '',
    },
  };
}

function saveUserFail(state, action) {
  return {
    ...state,
    userSaveStatus: {
      status: OperationStatus.fail,
      errorMessage: action.errorMessage,
    },
  };
}

function uploadAvatarStart(state, action) {
  return {
    ...state,
    userUploadAvatarStatus: {
      status: OperationStatus.processing,
      errorMessage: '',
    },
  };
}

function uploadAvatarSuccess(state, action) {
  const user = { ...state.user };
  user.avatarUrl = action.avatarUrl;

  return {
    ...state,
    user,
    userUploadAvatarStatus: {
      status: OperationStatus.success,
      errorMessage: '',
    },
  };
}

function withdrawalStart(state, action) {
  return {
    ...state,
    withdrawalStatus: {
      status: OperationStatus.processing,
      errorMessage: '',
    },
  };
}

function withdrawalSuccess(state, action) {
  return {
    ...state,
    withdrawalStatus: {
      status: OperationStatus.success,
      errorMessage: '',
    },
  };
}

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TOKEN_EXPIRED':
      return tokenInvalid(state, action);
    case 'TOKEN_INVALID':
      return tokenInvalid(state, action);
    case 'UNAUTHORIZED_REQUEST':
      return unauthorizedRequest(state, action);

    case 'LOGIN_START':
      return loginStart(state, action);
    case 'LOGIN_SUCCESS':
      return loginSuccess(state, action);
    case 'LOGIN_FAIL':
      return loginFail(state, action);

    case 'IMPERSONATE_START':
      return impersonateStart(state, action);
    case 'IMPERSONATE_SUCCESS':
      return impersonateSuccess(state, action);
    case 'IMPERSONATE_FAIL':
      return impersonateFail(state, action);

    case 'LOGIN_MODAL_OPEN':
      return loginModalOpen(state, action);
    case 'LOGIN_MODAL_CLOSE':
      return loginModalClose(state, action);

    case 'LOGOUT':
      return logout(state, action);

    case 'SEND_PASSWORD_RESET_EMAIL_START':
      return sendPasswordResetEmailStart(state, action);
    case 'SEND_PASSWORD_RESET_EMAIL_SUCCESS':
      return sendPasswordResetEmailSuccess(state, action);
    case 'SEND_PASSWORD_RESET_EMAIL_FAIL':
      return sendPasswordResetEmailFail(state, action);

    case 'RESET_PASSWORD_START':
      return resetPasswordStart(state, action);
    case 'RESET_PASSWORD_SUCCESS':
      return resetPasswordSuccess(state, action);
    case 'RESET_PASSWORD_FAIL':
      return resetPasswordFail(state, action);
    case 'RESET_PASSWORD_REFRESH_STATUS':
      return resetPasswordRefreshStatus(state, action);

    case 'CHANGE_PASSWORD_START':
      return changePasswordStart(state, action);
    case 'CHANGE_PASSWORD_SUCCESS':
      return changePasswordSuccess(state, action);
    case 'CHANGE_PASSWORD_FAIL':
      return changePasswordFail(state, action);

    case 'CHANGE_PASSWORD_MODAL_OPEN':
      return changePasswordModalOpen(state, action);
    case 'CHANGE_PASSWORD_MODAL_CLOSE':
      return changePasswordModalClose(state, action);

    case 'REGISTER_MODAL_OPEN':
      return registerModalOpen(state, action);
    case 'REGISTER_MODAL_CLOSE':
      return registerModalClose(state, action);

    case 'REGISTER_START':
    case 'REGISTER_FAIL':
      return {
        ...state,
        registerStatus: registrationReducer(null, action),
      };

    case 'REGISTER_SUCCESS':
      return {
        ...state,
        user: {
          ...state.user,
          accountType: action.accountType,
        },
        registerStatus: registrationReducer(null, action),
      };

    case 'SET_RESOLUTION':
      return {
        ...state,
        resolution: resolutionReducer(null, action),
      };

    case 'TXT_MSG':
      return {
        ...state,
        messageContent: action.newContent,
      };

    case 'VERIFY_REGISTRATION_START':
      return verifyRegistrationStart(state, action);
    case 'VERIFY_REGISTRATION_SUCCESS':
      return verifyRegistrationSuccess(state, action);
    case 'VERIFY_REGISTRATION_FAIL':
      return verifyRegistrationFail(state, action);



    case 'SWITCH_NEW_WIZARD_MAJOR_STEP':
      return switchNewWizardMajorStep(state, action);
    case 'SWITCH_NEW_WIZARD_INNER_STEP':
      return switchNewWizardInnerStep(state, action);
    case 'UPDATE_WIZARD_CYCLE':
      return updateWizardCycle(state, action);

    case 'PAYMENT_ORDER_PROPERTIES':
      return receivePaymentOrderProperties(state, action);


    case 'SWITCH_NEW_CYCLE_STEP':
      return switchNewCycleStep(state, action);

    case 'SWITCH_NEW_CYCLE_STEP_FAIL':
      return switchNewCycleStepFail(state, action);

    case 'POTENTIAL_TESTER_PAGINATION':
      return potentialTestersPagination(state, action);

    case 'LIST_CYCLES_SUCCESS':
      return listCyclesSuccess(state, action);

    case 'LIST_CYCLES_SLIM_SUCCESS':
      return listCyclesSlimSuccess(state, action);


    case 'UPDATE_CYCLE':
      return updateCycle(state, action);

    case 'GET_CYCLE_FULL_START':
      return getCycleFullStart(state, action);
    case 'GET_CYCLE_FULL_SUCCESS':
      return getCycleFullSuccess(state, action);

    case 'CREATE_CYCLE_START':
      return createCycleStart(state, action);
    case 'CREATE_CYCLE_SUCCESS':
      return createCycleSuccess(state, action);

    case 'CREATE_WIZARD_CYCLE_SUCCESS':
      return createWizardCycleSuccess(state, action);

    case 'CREATE_CYCLE_FAIL':
      return createCycleFail(state, action);
    case 'UPDATE_CYCLE_URLS_SUCCESS':
      return updateCycleUrlsSuccess(state, action);

    case 'GET_POTENTIAL_TESTERS_SUCCESS':
      return getPotentialTestersSuccess(state, action);

    case 'SUBMIT_CUSTOMER_REVIEW_SUCCES':
      return submitCustomerReviewSuccess(state, action);

    case 'ACCEPT_CYCLE_START':
      return {
        ...state,
        cycleAcceptStatus: {
          status: OperationStatus.processing,
          errorMessage: '',
        },
      };

    case 'ACCEPT_CYCLE_SUCCESS':
      return acceptCycleSuccess(state, action);
    case 'COMPLETE_CYCLE_SUCCESS':
      return completeCycleSuccess(state, action);

    case 'TEST_PLAN_STEP_UPDATE_SUCCESS':
      return testPlanStepUpdateSuccess(state, action);

    case 'REGISTER_AFFILIATE_SUCCESS':
      return updateUserPromo(state, action);

    case 'UPDATE_USER':
      return updateUser(state, action);
    case 'SAVE_USER_START':
      return saveUserStart(state, action);
    case 'SAVE_USER_SUCCESS':
      return saveUserSuccess(state, action);
    case 'SAVE_USER_FAIL':
      return saveUserFail(state, action);

    case 'UPLOAD_AVATAR_START':
      return uploadAvatarStart(state, action);

    case 'UPLOAD_AVATAR_SUCCESS':
      return uploadAvatarSuccess(state, action);

    case 'PHONE_NUM_SENT_START':
      return {
        ...state,
        userSavePhoneStatus: {
          status: OperationStatus.processing,
          errorMessage: '',
        },
      };

    case 'PHONE_NUM_SENT_FAIL':
      return {
        ...state,
        userSavePhoneStatus: {
          status: OperationStatus.fail,
          errorMessage: action.errorMessage,
        },
      };

    case 'PHONE_NUM_SENT_SUCCESS':
      return {
        ...state,
        userSavePhoneStatus: {
          status: OperationStatus.success,
          errorMessage: '',
        },
      };

    case 'FETCH_COUPON_START':
      return {
        ...state,
        couponFetchStatus: {
          status: OperationStatus.processing,
          errorMessage: '',
        },
        newWizardCycle: {
          ...state.newWizardCycle,
          couponDetails: {
            id: -1,
            code: '',
            discount: 0
          }
        },
      };

    case 'FETCH_COUPON_FAIL':
      return {
        ...state,
        couponFetchStatus: {
          status: OperationStatus.fail,
          errorMessage: action.errorMessage,
        },
        newWizardCycle: {
          ...state.newWizardCycle,
          couponDetails: {
            id: -1,
            code: '',
            discount: 0
          }
        },
      };

    case 'FETCH_COUPON_SUCCESS':
      return {
        ...state,
        couponFetchStatus: {
          status: OperationStatus.success,
          errorMessage: '',
        },
        couponDetails: {
          id: action.couponData.id,
          code: action.couponData.codeStr,
          discount: action.couponData.discount,
        },

        newWizardCycle: {
          ...state.newWizardCycle,
          couponDetails: {
            id: action.couponData.id,
            code: action.couponData.codeStr,
            discount: action.couponData.discount,
          }
        }
      };

    case 'LIST_ASSOCIATED_SUCCESS':
      return {
        ...state,
        acssociatedCustomers: action.acssociatedCustomers,
      };

    case 'CONFIRM_SMS_START':
      return saveUserStart(state, action); //To set the userSaveStatus
    case 'CONFIRM_SMS_FAIL':
      return saveUserFail(state, action);
    case 'CONFIRM_SMS_SUCCESS':
      return saveUserSuccess(state, action);

    case 'LIST_CUSTOMER_REVIEWS_SUCCESS':
      return {
        ...state,
        customerReviews: action.customerReviews,
      };

    case 'GET_DEVICES_SUCCESS':
      return {
        ...state,
        devices: action.devices,
      };

    case 'EXTRACT_SYSTEM_PLATFORM_COMPLETE':
      return {
        ...state,
        platformRestructionStatus: {
          status: OperationStatus.success,
          errorMessage: '',
        },
        systemPlatforms: action.systemPlatforms,
      };

    case 'ADD_DEVICE_SUCCESS': {
      const deviceData: any = state.devices.models.find(
        (el: any) => el.id === action.device.modelId
      );
      const device: Record<string, any> = {
        ...action.device,
        platformId: deviceData.platformId,
        manufacturerId: deviceData.manufacturerId,
        name: deviceData.name,
      };

      return {
        ...state,
        user: {
          ...state.user,
          devices: [...state.user.devices, device],
        },
      };
    }
    case 'DELETE_DEVICE_START':
      return {
        ...state,
        deleteDeviceStatus: {
          status: OperationStatus.processing,
          errorMessage: '',
        },
      };
    case 'DELETE_DEVICE_SUCCESS': {
      const devices = state.user.devices.filter((el: any) => {
        if (el.modelId === action.device.modelId && el.osId === action.device.osId) {
          return false;
        }

        return true;
      });

      return {
        ...state,
        user: {
          ...state.user,
          devices,
        },
        deleteDeviceStatus: {
          status: OperationStatus.success,
          errorMessage: '',
        },
      };
    }

    case 'GET_BROWSERS_SUCCESS':
      return {
        ...state,
        browsers: {
          ...action.browsers,
        },
      };
    case 'ADD_BROWSER_SUCCESS':
      return {
        ...state,
        user: {
          ...state.user,
          browsers: [...state.user.browsers, action.browser],
        },
      };
    case 'DELETE_BROWSER_START':
      return {
        ...state,
        deleteBrowserStatus: {
          status: OperationStatus.processing,
          errorMessage: '',
        },
      };
    case 'DELETE_BROWSER_SUCCESS': {
      const browsers = state.user.browsers.filter((el: any) => {
        if (
          el.browserId === action.browser.browserId &&
          el.browserVersionId === action.browser.browserVersionId &&
          el.platformId === action.browser.browserPlatformId
        ) {
          return false;
        }

        return true;
      });

      return {
        ...state,
        user: {
          ...state.user,
          browsers,
        },
        deleteBrowserStatus: {
          status: OperationStatus.success,
          errorMessage: '',
        },
      };
    }

    case 'LIST_FINANCE_START':
      return {
        ...state,
        listOwnFinanceStatus: {
          status: OperationStatus.processing,
          errorMessage: '',
        },
      };

    case 'LIST_FINANCE_SUCCESS':
      return {
        ...state,
        userFinance: action.finance,
        listOwnFinanceStatus: {
          status: OperationStatus.success,
          errorMessage: '',
        },
      };

    case 'LIST_CHAT_MESSAGES_SUCCESS':
      const chatMessages = {
        byCycleId: { ...state.chatMessages.byCycleId },
      };

      chatMessages.byCycleId[action.cycleId] = action.chatMessages;

      return {
        ...state,
        chatMessages,
      };

    case 'SEND_CHAT_MESSAGE_SUCCESS': {
      const cycleMessages = { ...state.chatMessages.byCycleId[action.cycleId] };

      if (!action.participantId) {
        for (const participantId in cycleMessages) {
          cycleMessages[participantId].push(action.message);
        }
      } else {
        cycleMessages[action.participantId].push(action.message);
      }

      return {
        ...state,
        chatMessageSentStatus: {
          status: OperationStatus.success,
          errorMessage: '',
        },
        chatMessages: {
          byCycleId: {
            ...state.chatMessages.byCycleId,
            [action.cycleId]: cycleMessages,
          },
        },
      };
    }

    case 'MARK_CHAT_MESSAGES_AS_READ_SUCCESS': {
      const chatMessages = { ...state.chatMessages };
      const cycles = [...state.cycles];
      const cycle: any = cycles.find((el: any) => el.id == action.cycleId);

      let countReadMessages = 0;

      chatMessages.byCycleId[action.cycleId][action.participantId].forEach((message: any) => {
        if (!message.read) {
          countReadMessages += 1;
        }
        message.read = true;
      });

      cycle.totalUnreadMessages -= countReadMessages;

      const cyclesSlim = [...state.cyclesSlim];
      const cycleSlim: any = cyclesSlim.find((el: any) => el.id == action.cycleId);
      if (cycleSlim) {
        cycleSlim.totalUnreadMessages -= countReadMessages;
      }

      return {
        ...state,
        cycles,
        cycleSlim,
        chatMessages,
      };
    }

    case 'SEND_CHAT_MESSAGE_START':
      return {
        ...state,
        chatMessageSentStatus: {
          status: OperationStatus.processing,
          errorMessage: '',
        },
      };

    case 'SEND_CHAT_MESSAGE_FAIL':
      return {
        ...state,
        chatMessageSentStatus: {
          status: OperationStatus.fail,
          errorMessage: action.errorMessage,
        },
      };

    case 'CREATE_BUG_START':
      return {
        ...state,
        createBugStatus: {
          status: OperationStatus.processing,
          errorMessage: '',
        },
      };
    case 'CREATE_BUG_SUCCESS': {
      const cycles = [...state.cycles];
      const cycle: any = cycles.find((el: any) => el.id === action.cycleId);

      const cycleBugs = [...state.cycleBugs.byCycleId[action.cycleId]];
      cycleBugs.push(action.bug);
      cycle.bugsFound = cycleBugs.length;

      if (action.bug.testPlanStepId) {
        let stepResult = cycle.testPlanResults.find(
          (result: any) => result.testPlanStepId === action.bug.testPlanStepId
        );

        if (!stepResult) {
          stepResult = {
            testPlanStepId: action.bug.testPlanStepId,
            status: TestPlanStepStatus.Failed,
          };

          cycle.testPlanResults.push(stepResult);
        } else {
          stepResult.status = TestPlanStepStatus.Failed;
        }
      }

      return {
        ...state,
        cycles,
        cycleBugs: {
          byCycleId: {
            ...state.cycleBugs.byCycleId,
            [action.cycleId]: cycleBugs,
          },
        },
        createBugStatus: {
          status: OperationStatus.success,
          errorMessage: '',
        },
        newBug: { ...initialState.newBug },
      };
    }
    case 'CREATE_BUG_FAIL':
      return {
        ...state,
        createBugStatus: {
          status: OperationStatus.fail,
          errorMessage: action.errorMessage,
        },
      };

    case 'EDIT_BUG_START':
      return {
        ...state,
        editBugStatus: {
          status: OperationStatus.processing,
          errorMessage: '',
          bugStatus: action.status,
        },
      };
    case 'EDIT_BUG_SUCCESS': {
      const cycle: any = state.cycles.find((el: any) => el.id === action.cycleId);
      const cycleBugsByCycleId = [...state.cycleBugs.byCycleId[action.cycleId]];
      const index = cycleBugsByCycleId.findIndex((el: any) => el.id === action.bug.id);

      cycleBugsByCycleId[index] = action.bug;

      if (action.testPlanStepId) {
        let stepResult = cycle.testPlanResults.find(
          (result: any) => result.testPlanStepId === action.testPlanStepId
        );

        if (!stepResult) {
          stepResult = {
            testPlanStepId: action.testPlanStepId,
            status: TestPlanStepStatus.Passed,
          };

          cycle.testPlanResults.push(stepResult);
        }

        stepResult.status = TestPlanStepStatus.Passed;
      }

      return {
        ...state,
        cycleBugs: {
          byCycleId: {
            [action.cycleId]: cycleBugsByCycleId,
          },
        },
        editBugStatus: {
          status: OperationStatus.success,
          errorMessage: '',
          bugStatus: '',
        },
      };
    }

    case 'EDIT_BUG_FAIL':
      return {
        ...state,
        editBugStatus: {
          status: OperationStatus.fail,
          errorMessage: action.errorMessage,
          bugStatus: '',
        },
      };

    case 'DELETE_BUG_START':
      return {
        ...state,
        createBugStatus: {
          status: OperationStatus.processing,
          errorMessage: '',
        },
      };
    case 'DELETE_BUG_SUCCESS': {
      const cycles = [...state.cycles];
      const cycle: any = cycles.find((el: any) => el.id === action.cycleId);

      const cycleBugs = [...state.cycleBugs.byCycleId[action.cycleId]].filter(
        (bug) => bug.id !== action.bugId
      );

      cycle.bugsFound = cycleBugs.length;

      // FIXME update cycle.bugsFound
      return {
        ...state,
        cycles,
        cycleBugs: {
          byCycleId: {
            ...state.cycleBugs.byCycleId,
            [action.cycleId]: cycleBugs,
          },
        },
        createBugStatus: {
          // FIXME why it's "create"?
          status: OperationStatus.success,
          errorMessage: '',
        },
      };
    }

    case 'DELETE_BUG_FAIL':
      return {
        ...state,
        createBugStatus: {
          status: OperationStatus.fail,
          errorMessage: action.errorMessage,
        },
      };

    case 'NEW_BUG_UPDATE':
      return {
        ...state,
        newBug: {
          ...state.newBug,
          ...action.newBug,
        },
      };

    case 'ADD_TESTER_TO_FAVORITES_SUCCESS': {
      const favoriteTesters = {
        byId: { ...state.favoriteTesters.byId },
      };

      favoriteTesters.byId[action.tester.id] = action.tester;

      return {
        ...state,
        favoriteTesters,
      };
    }

    case 'REMOVE_TESTER_FROM_FAVORITES_SUCCESS': {
      const favoriteTesters = {
        byId: { ...state.favoriteTesters.byId },
      };

      delete favoriteTesters.byId[action.testerId];

      return {
        ...state,
        favoriteTesters,
      };
    }

    case 'WITHDRAWAL_START':
      return withdrawalStart(state, action);
    case 'WITHDRAWAL_SUCCESS':
      return withdrawalSuccess(state, action);
    // TODO withdrawal fail

    case 'SET_STATUS': {
      return {
        ...state,
        [action.statusName]: {
          status: action.status,
          errorMessage: action.errorMessage || '',
        },
      };
    }

    case 'SUBMIT_CONTACT_FORM_START': {
      return {
        ...state,
        contactFormStatus: {
          status: OperationStatus.processing,
          errorMessage: '',
        },
      };
    }
    case 'SUBMIT_CONTACT_FORM_FAIL': {
      return {
        ...state,
        contactFormStatus: {
          status: OperationStatus.fail,
          errorMessage: action.errorMessage,
        },
      };
    }
    case 'SUBMIT_CONTACT_FORM_SUCCESS': {
      return {
        ...state,
        contactFormStatus: {
          status: OperationStatus.success,
          errorMessage: '',
        },
      };
    }

    case 'GET_CYCLE_SUCCESS': {
      const cycles = [action.cycle, ...state.cycles];
      const index = cycles.findIndex((el) => el.id === action.cycle.id);

      if (index > -1) {
        cycles[index] = action.cycle;
      } else {
        cycles.push(action.cycle);
      }

      return {
        ...state,
        cycles,
      };

    }

    default:
      return state;
  }
};

const store = createStore(
  <any>rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunk))
);

export type RootState = ReturnType<typeof store.getState>;

export { store };
export default store;
