import React from 'react';
import first from 'lodash/first';
import classNames from 'classnames';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import CardContent from '@mui/material/CardContent';
import { grey, green, yellow } from '@mui/material/colors';

import { TesterStatusInCycle } from '../../../../types/TesterStatusInCycle';
import { CycleStatus } from '../../../../types/CycleStatus';

const useStyles = makeStyles(() => ({
    root: {
      minWidth: 210,
      boxShadow: 'none',
      textAlign: 'center',
      padding: '2px 45px',
      border: `1px solid ${grey[200]}`,
    },
    cardCotent: {
        '&:last-child': {
            paddingBottom: 10,
        },
    },
    avatar: {
        fontSize: 15,
        margin: '0 auto 20px',
        backgroundColor: '#00BCD7',
    },
    testerName: {
        maxWidth: 150,
        marginBottom: 15,
    },
    chip: {
        borderRadius: 4,
        marginBottom: 5,
        backgroundColor: grey[200],
    },
    chipCompleted: {
        color: green[500],
        background: green[50],
    },
    chipInProgress: {
        color: yellow[800],
        background: '#FFF8EE',
    },
    smSpaceBottom: {
        marginBottom: 15,
    },
}));

interface ITesterCardProps {
    firstName?: string;
    lastName?: string;
    country?: string;
    status?: TesterStatusInCycle;
    avatarUrl?: string;
    className?: string;
    cycleStatus?: CycleStatus;
};

const TesterCard: React.FC<ITesterCardProps> = ({ 
    firstName = '',
    lastName = '',
    country = '',
    status = TesterStatusInCycle.Approved,
    cycleStatus,
    avatarUrl = null,
    className = '',
}) => {
    const classes = useStyles();    
    
    const testerStatus = 
        [TesterStatusInCycle.Finished, TesterStatusInCycle.AwaitingReview].includes( status ) ? 'Complete' 
                                                                                        : ( ( cycleStatus === CycleStatus.Completed ) ? 'Not finished' : 'In progress') ;    
    return (
        <Card className={classNames(classes.root, className)}>
            <CardContent className={classes.cardCotent}>
                {avatarUrl ? (
                    <Avatar className={classes.avatar} src={avatarUrl} />
                ) : (
                    <Avatar className={classes.avatar}>
                        {first(firstName)}{first(lastName)}
                    </Avatar>
                )}
                

                <Typography 
                    gutterBottom 
                    component="h2" 
                    variant="body1" 
                    color="textSecondary" 
                    className={classes.testerName}
                >
                    {firstName} {lastName}
                </Typography>

                <div className={classes.smSpaceBottom}>
                    <Chip label={country} className={classes.chip} />
                </div>

                <div>
                    {cycleStatus !== CycleStatus.Completed &&
                    <Chip 
                        label={testerStatus} 
                        className={classNames(classes.chip, {
                            [classes.chipCompleted]: testerStatus === 'Complete',
                            [classes.chipInProgress]: (testerStatus === 'In progress' || testerStatus === 'Not finished'),
                        })}
                    />}
                </div>
            </CardContent>
        </Card>
    );
};

export default TesterCard;