import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import CustomerAccountLayout from '../../../layouts/account/CustomerAccountLayout';
import { RootState } from '../../../redux/store';

import { useHistory } from 'react-router';
import desertImg from '../../../public/images/desert.png';
import { CycleStatus } from '../../../types/CycleStatus';

import { Modal } from '@mui/material';

const useStyles = makeStyles(() => ({
    desertRoot: {
        marginTop: 110,
    },
    title: {
        marginBottom: 25,
    },
    subtitle: {
        maxWidth: 450,
        marginBottom: 25,
    },
    createCycleButton: {
        border: 'none',
        marginBottom: 40,
        borderRadius: 12,
        boxShadow: 'none',
        padding: '15px 65px',
        transition: 'all 0.4s',
        '&:hover': {
            color: '#ffffff',
            boxShadow: 'none',
        },
    },
    desertImg: {
        maxWidth: 620,
    },
}));

const CustomerAccountPage = () => {
    const classes = useStyles();
    const history = useHistory();
    let { cyclesSlim, resolution } = useSelector((state: RootState) => state);
    cyclesSlim = cyclesSlim.filter((c: any) => c.status != CycleStatus.Draft);

    const popUpStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 3,
        display: 'flex',
        flexDirection: 'column'
    };
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const isMobile = resolution.device === 'mobile' || resolution.device === 'mobile-tablet';
    const dispatch = useDispatch();

    useEffect(function onCycle() {
        if (!isEmpty(cyclesSlim)) {
            //For now - take always last, default by ID, good for most cases
            const cycle: any = last(cyclesSlim);
            if (cycle?.id) {
                history.push(`/account/customer/cycle/${cycle.id}/report`);
            }
        }
    }, [cyclesSlim]);

    if (isEmpty(cyclesSlim)) {
        return (
            <CustomerAccountLayout emptyState>
                <Box display="flex" flexDirection="column" alignItems="center" className={classes.desertRoot}>
                    <Typography variant="h4" align="center" className={classes.title}>
                        It looks like a desert here
                    </Typography>
                    {isMobile &&
                        <>
                            <Typography variant="body1" align="center" className={classes.subtitle}>
                                You don't have any live test at this moment.
                            </Typography>

                            <Button onClick={handleOpen} size="large" color="primary" variant="contained" className={classes.createCycleButton}>
                                Try for $1
                            </Button>
                            <Modal
                                open={open}
                                onClose={handleClose}
                            >
                                <Box sx={popUpStyle}>
                                    <Typography variant="h6" component="h2">
                                        Almost there...
                                    </Typography>
                                    <Typography variant="h6" component="h2">
                                        Switch to your pc to submit your test
                                    </Typography>
                                </Box>
                            </Modal>

                        </>
                    }
                    {!isMobile &&
                        <>
                            <Typography variant="body1" align="center" className={classes.subtitle}>
                                You don't have any live test at this moment. Let's create a new test and boost your product
                            </Typography>
                            <Link to="/firstcycle">
                                <Button size="large" color="primary" variant="contained" className={classes.createCycleButton}>
                                    Try for $1
                                </Button>
                            </Link>
                        </>
                    }

                    <img alt="Desert" title="Desert" src={desertImg} className={classes.desertImg} />
                </Box>
            </CustomerAccountLayout>
        );
    }

    return null;
};

export default CustomerAccountPage;