import React from 'react';
import StarRatingComponent from 'react-star-rating-component';
import countryCodes from '../util/options/countryCodes';

const TesterPreview = ({
  avatarUrl,
  firstName,
  lastName,
  country,
  about,
  rating,
  cyclesCompleted,
  customerReviews,
  addedToFavorites,
}) => {
  const flag = countryCodes.find((el) => el.value === country);
  const flagIcon = flag ? flag.key.toLowerCase() : '';

  return (
    <div className="tester-preview">
      <div className="tester-summary">
        <img className="avatar" src={avatarUrl} alt="" height="64" width="64" />
        <div style={{ flexDirection: 'column' }}>
          <div className="tester-summary-heading">
            <span className={`flag-icon flag-icon-${flagIcon}`}></span>
            <span className="tester-name">
              {firstName} {lastName}
            </span>
            <StarRatingComponent name="rating" starCount={5} value={parseInt(rating, 10)} />
            <span className="tester-rating">{rating}</span>
          </div>
          <p className="about-tester">{about}</p>
        </div>
      </div>
      <div className="tester-stats">
        {cyclesCompleted} project(s) completed | {customerReviews} customer reviews | On{' '}
        {addedToFavorites} favorite lists
      </div>
    </div>
  );
};

export default TesterPreview;
