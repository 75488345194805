import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Menu, Icon, Label } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import Countdown, { zeroPad } from 'react-countdown';
import moment from 'moment';
import { WorkCategory } from '../../../../types/WorkCategory';
import { SidemenuSummary } from '../SidemenuSummary';
import actions from '../../../../redux/actions';
import config from '../../../../config';
import noavatar from '../../../../public/images/noavatar.png';

const mapStateToProps = (state) => {
  return {
    user: state.user,
    cycles: state.cycles,
    chatMessages: state.chatMessages,
    cycleTesters: state.cycleTesters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listChatMessages: (cycleId, participantId) =>
      dispatch(actions.listChatMessages(cycleId, participantId)),
  };
};

class Sidemenu extends Component<any, any> {
  private listInterval: any;

  constructor(props) {
    super(props);

    this.state = {
      cycleId: props.cycleId, // TODO consider taking it directly from URL
    };
  }

  componentDidMount() {
    const cycle = this.props.cycles.find((el) => el.id === this.state.cycleId);

    if (!cycle) {
      return null;
    }
    const participantId = cycle.ownerId;

    this.listInterval = setInterval(
      () => this.props.listChatMessages(this.state.cycleId, participantId),
      10000
    );
  }

  componentWillUnmount() {
    clearInterval(this.listInterval);
  }

  render() {
    const cycle = this.props.cycles.find((el) => el.id === this.state.cycleId);

    if (!cycle) {
      return null;
    }

    const user = this.props.user;
    const chatMessages = this.props.chatMessages.byCycleId[cycle.id] || {};

    let totalUnreadMessages = 0;

    for (const testerId of Object.keys(chatMessages)) {
      if (cycle.id === config.evalCycleId) {
        const tester = this.props.cycleTesters.byCycleId[cycle.id].find((tester) => {
          return tester.id === parseInt(testerId);
        });

        if (!tester) {
          /* Finished testers don't appear on eval cycle */
          continue;
        }
      }

      const testerUnreadMessages = chatMessages[testerId].reduce((result, message) => {
        if (!message.read) {
          result += 1;
        }

        return result;
      }, 0);

      totalUnreadMessages += testerUnreadMessages;
    }

    const showCountdown = moment().isBefore(moment(cycle.endDate));

    return (
      <aside>
        <SidemenuSummary
          companyName={user.companyName}
          balance={user.balance}
          avatarUrl={user.avatarUrl || noavatar}
        />
        {showCountdown && (
          <div className="sidemenu-countdown">
            Cycle ends in
            <br />
            <Countdown
              date={cycle.endDate}
              daysInHours={false}
              renderer={({ days, hours, minutes, seconds }) => {
                return `${days} days, ${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`;
              }}
            />
          </div>
        )}

        <Menu vertical as="nav">
          <Menu.Item as={NavLink} to={`/account/customer/cycle/${cycle.id}/report`}>
            {cycle.workCategory === WorkCategory.FeedbackAndRating
              ? 'Feedback report'
              : 'Bugs found'}
          </Menu.Item>

          <Menu.Item
            className="messages-link"
            as={NavLink}
            to={`/account/customer/cycle/${cycle.id}/messages`}
            exact
          >
            Messages
            {totalUnreadMessages > 0 && <Label content={totalUnreadMessages} />}
          </Menu.Item>
          {cycle.workCategory === WorkCategory.FeedbackAndRating && (
            <Menu.Item as={NavLink} to={`/account/customer/cycle/${cycle.id}/questions`}>
              Questions
            </Menu.Item>
          )}
          {cycle.workCategory === WorkCategory.FindBugsTestPlan && (
            <Menu.Item as={NavLink} to={`/account/customer/cycle/${cycle.id}/testplan`}>
              Test plan
            </Menu.Item>
          )}
          <Menu.Item as={NavLink} to={`/account/customer/cycle/${cycle.id}/testers`}>
            Testers In cycle
          </Menu.Item>
          <Menu.Item as={NavLink} to={`/account/customer/cycle/${cycle.id}`} exact>
            Details
          </Menu.Item>
          <Menu.Item as={NavLink} to="/account/customer" exact>
            <Icon name="chevron circle left" /> Back to dashboard
          </Menu.Item>
        </Menu>
      </aside>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidemenu);
