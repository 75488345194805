import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useGoogleLogin } from '@react-oauth/google';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import startCase from 'lodash/startCase';
import values from 'lodash/values';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import GoogleIcon from '../public/images/icons/GoogleIcon';

import actions from '../redux/actions';
import { RootState } from '../redux/store';
import { OperationStatus } from '../types/OperationStatus';
import { UserRole } from '../types/UserRole';

import { useHistory } from 'react-router';
import AvatarBackground from '../elements/AvatarBackground';
import Button from '../elements/Button';
import Hero from '../elements/Hero';

import { Icon, Message } from 'semantic-ui-react';
import theme from '../themes';


declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme { }
}


const useStyles = makeStyles((theme: Theme) => ({
    signupBox: {
        marginTop: -70,
        padding: 35,
    },
    avatarBackground: {
        paddingBottom: 37,
        height: 'calc(100vh + 37px)',
        [theme.breakpoints.down('md')]: {
            height: '110vh',
            paddingBottom: 0,
        },
        [theme.breakpoints.down('sm')]: {
            height: '100vh',
        },
    },
    registrationButtonText: {
        marginLeft: 10,
    },
    xxsBottomSpace: {
        marginBottom: 5,
    },
    smBottomSpace: {
        marginBottom: 25,
    },
    lgBottomSpace: {
        marginBottom: 50,
    },
    smTopSpace: {
        marginTop: 15,
    },
    mdTopSpace: {
        marginTop: 30,
    },
    lgTopSpace: {
        marginTop: 50,
    },
    accountText: {
        fontSize: 14,
    },
    link: {
        marginLeft: 3,
    },
    center: {
        textAlign: 'center',
    },
}));

const validateCredentials = ({ email, password, confirmPassword, typeSelected }) => {
    const errors = { email: '', password: '', confirmPassword: '', typeSelected: '' };
    errors.email = !isEmail(email) ? 'Please enter a valid email' : '';
    errors.password = isEmpty(password) || password.length < 6 ? 'Password must contain at least 6 characters' : '';
    errors.confirmPassword = isEmpty(confirmPassword) || confirmPassword !== password ? 'Passwords do not match' : '';
    errors.typeSelected = typeSelected === UserRole.Anonymous ? 'Please choose your role - tester or customer' : '';
    return errors;
};

const SignupPage = (props) => {

    const { location } = props;
    const { state } = location || {};
    const { referrer } = state || { referrer: '/' };
    const { defaultRole } = state || { defaultRole: UserRole.Anonymous };

    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const googleLogin = useGoogleLogin({
        onSuccess: (response) => { setGoogleResponse(response) },
        onError: (error) => console.log('Google Login Failed:', error)
    });


    const { resolution, registerStatus, user } = useSelector((state: RootState) => state);

    const isMobile = resolution.device === 'mobile';
    const isMobileTablet = resolution.device === 'mobile-tablet';

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [role, setRole] = useState<string>(defaultRole);
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [googleResponse, setGoogleResponse] = useState<any>(null);

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

    const [errors, setErrors] = useState({ email: '', password: '', confirmPassword: '', typeSelected: '' });

    const popUpStyle = {
        position: 'absolute',
        top: '60%',
        left: '50%',
        transform: 'rotate(12deg) translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        border: '2px solid #FFB75A',
        boxShadow: 24,
        p: 5,
    };
    const [open, setOpen] = React.useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const doboth = (obj) => {
        const errors = validateCredentials({ email, password, confirmPassword, typeSelected: role });
        if (errors.typeSelected !== '') {
            return setErrors(errors);
        }

        obj.onClick();
    }

    const clearErrors = () => {
        if (some(values(errors))) {
            setErrors({ email: '', password: '', confirmPassword: '', typeSelected: '' });
        }
    };

    const signup = (e) => {
        e.preventDefault();

        if (registerStatus.status === OperationStatus.processing) {
            return;
        }
        const errors = validateCredentials({ email, password, confirmPassword, typeSelected: role });
        if (some(values(errors))) {
            return setErrors(errors);
        }
        dispatch(actions.register(role, email, password, true));
    };

    const onFacebookSignupResponse = (response) => {
        if (user.role === UserRole.Anonymous) {
            const { email = '', accessToken, userID } = response;
            dispatch(actions.registerFacebook(role, email, accessToken, userID));
        }
    };

    const handleGoogleClicked = () => {
        const errors = validateCredentials({ email, password, confirmPassword, typeSelected: role });
        if (errors.typeSelected !== '') {
            return setErrors(errors);
        }
        googleLogin();
    }

    useEffect(clearErrors, [email, password, confirmPassword, role]);

    useEffect(() => {
        const mainEl = document.getElementsByTagName('main')[0];
        mainEl.classList.add('full-screen');
        return () => {
            mainEl.classList.remove('full-screen');
        };
    }, []);

    useEffect(() => {
        if (registerStatus.errorMessage) {
            setErrors({
                email: '',
                password: '',
                confirmPassword: registerStatus.errorMessage,
                typeSelected: '',
            });
        }
    }, [registerStatus]);

    useEffect(function postSignup() {
        if (registerStatus.status === OperationStatus.success) {
            clearErrors();
            if (user.accountType === 'facebook') {
                history.push('/registerconfirm?accountType=facebook');
            }
            else {
                if (user.accountType === 'google') {
                    history.push('/registerconfirm?accountType=google');
                }
                else {
                    history.push(`/registerconfirm?email=${email}`);
                }
            }
        }
    }, [registerStatus]);

    useEffect(() => {
        (async () => {
            if (googleResponse) {
                try {
                    const response = await fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleResponse.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${googleResponse.access_token}`,
                            Accept: 'application/json'
                        }
                    });
                    const userData = await response.json();

                    /* console.log('userData', userData); */

                    const { email, id, picture, given_name, family_name } = userData;
                    dispatch(actions.registerGoogle(role, email, googleResponse.access_token, id, picture, given_name, family_name));

                } catch (error) {
                    console.error('Error fetching user data from Google:', error);
                }
            }
        })();
    }, [googleResponse]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <AvatarBackground
                    mode="blue"
                    fullScreen
                    hideMiddleAvatars
                    className={classes.avatarBackground}
                    hideAvatars={isMobile || isMobileTablet}
                >
                    <Container>
                        <Hero
                            mode="light"
                            maxWidth="sm"
                            title="Create account"
                            className={classes.signupBox}
                            description="See, hear and talk to your customers remotely, as they engage with your products, apps and messaging"
                        >


                            <Container maxWidth="xs" className={classes.lgTopSpace}>
                                {/* <Modal
                                disableScrollLock
                                open={open}
                                onClose={handleClose}
                            >
                                <Box sx={popUpStyle}>
                                    <Typography align='center' variant="h4" component="h1">
                                        Coming Soon...
                                    </Typography>                              
                                </Box>
                            </Modal> */}
                                {!!errors.typeSelected && (
                                    <Message negative>
                                        <Icon name="exclamation circle" />
                                        {'Please choose your role - tester or customer'}
                                    </Message>
                                )}

                                <div className={classNames([classes.center, classes.smBottomSpace])}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend" className={classes.xxsBottomSpace}>
                                            Sign up as
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            name="role"
                                            value={role}
                                            aria-label="role"
                                            onChange={(e) => setRole(e.target.value)}
                                        >
                                            <FormControlLabel
                                                value={UserRole.Tester}
                                                label={startCase(UserRole.Tester)}
                                                control={<Radio color="primary" />}
                                            />
                                            <FormControlLabel
                                                value={UserRole.Customer}
                                                label={startCase(UserRole.Customer)}
                                                control={<Radio color="primary" />}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </div>


                                <div className={classes.smBottomSpace}>
                                    <Button
                                        fullWidth
                                        registration
                                        inverted
                                        disabled={registerStatus.status === OperationStatus.processing || role === UserRole.Anonymous}
                                        onClick={handleGoogleClicked}
                                    >
                                        <Box display="flex" justifyContent="center" alignItems="center">
                                            <GoogleIcon />
                                            <Typography variant="body1" className={classes.registrationButtonText}>
                                                Continue with Google
                                            </Typography>
                                        </Box>
                                    </Button>
                                </div>

                                {/*                                 <div className={classes.smBottomSpace}>
                                <div className={classes.smBottomSpace}>
                                    <FacebookLogin
                                        autoLoad={false}
                                        disableMobileRedirect
                                        appId={config.facebookAppId}
                                        scope="public_profile, email"
                                        fields="name, email, picture"
                                        isMobile={isMobile || isMobile}
                                        callback={onFacebookSignupResponse}
                                        render={(renderProps) => (
                                            <Button
                                                fullWidth
                                                registration
                                                onClick={() => doboth(renderProps)}
                                                inverted
                                                //onClick={renderProps.onClick}
                                                disabled={registerStatus.status === OperationStatus.processing || role === UserRole.Anonymous}
                                            >
                                                <Box display="flex" justifyContent="center" alignItems="center">
                                                    <FacebookIcon />
                                                    <Typography variant="body1" className={classes.registrationButtonText}>
                                                        Continue with Facebook
                                                    </Typography>
                                                </Box>
                                            </Button>
                                        )}
                                    />
                                </div>
*/}

                                <Typography align="center" className={classes.smBottomSpace}>OR</Typography>

                                <form noValidate autoComplete="off">
                                    <div className={classes.smBottomSpace}>
                                        <TextField
                                            required
                                            fullWidth
                                            type="email"
                                            aria-required
                                            value={email}
                                            variant="outlined"
                                            placeholder="Email"
                                            error={!!errors.email}
                                            helperText={errors.email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>

                                    <div className={classes.smBottomSpace}>
                                        <TextField
                                            required
                                            fullWidth
                                            aria-required
                                            value={password}
                                            variant="outlined"
                                            placeholder="Password"
                                            error={!!errors.password}
                                            helperText={errors.password}
                                            type={showPassword ? 'text' : 'password'}
                                            onChange={(e) => setPassword(e.target.value)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            disabled={!password}
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowPassword(!showPassword)}
                                                            size="large">
                                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>

                                    <div className={classes.lgBottomSpace}>
                                        <TextField
                                            required
                                            fullWidth
                                            aria-required
                                            variant="outlined"
                                            value={confirmPassword}
                                            placeholder="Confirm Password"
                                            error={!!errors.confirmPassword}
                                            helperText={errors.confirmPassword}
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            disabled={!confirmPassword}
                                                            aria-label="toggle confirm password visibility"
                                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                            size="large">
                                                            {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>


                                    <Typography
                                        variant="body2"
                                        align="center"
                                        style={{ fontSize: 12 }}
                                        className={classNames([classes.mdTopSpace, classes.smBottomSpace])}
                                    >
                                        By signing up I agree to the
                                        <Link to="/terms" className={classNames(classes.link, 'link-info')}>
                                            Terms of service
                                        </Link>
                                    </Typography>

                                    <Button
                                        fullWidth
                                        onClick={signup}
                                        disabled={registerStatus.status === OperationStatus.processing}
                                    >
                                        Create an account
                                    </Button>

                                    <Typography
                                        align="center"
                                        variant="body2"
                                        className={classNames(classes.mdTopSpace, classes.accountText)}
                                    >
                                        Already have an account?
                                        <Link to="/login" className={classNames(classes.link, 'link-info')}>
                                            Login here
                                        </Link>
                                    </Typography>
                                </form>
                            </Container>
                        </Hero>
                    </Container>
                </AvatarBackground>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default SignupPage;
