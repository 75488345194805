import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import actions from '../../../../redux/actions';

const mapStateToProps = (state) => {
  return {
    finance: state.userFinance,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listOwnFinance: () => dispatch(actions.listOwnFinance()),
  };
};

const columns = [
  {
    Header: 'Project',
    accessor: 'title',
  },
  {
    Header: 'Started On',
    id: 'createdAt',
    accessor: (row) => moment(row.cycleStartDate).format('DD.MM.YYYY'),
    getProps: () => ({ style: { textAlign: 'center' } }),
    maxWidth: 120,
  },
  {
    Header: () => (
      <span>
        Required
        <br />
        Testers
      </span>
    ),
    accessor: 'requiredTesters',
    getProps: () => ({ style: { textAlign: 'center' } }),
    maxWidth: 80,
  },
  {
    Header: () => (
      <span>
        Actual
        <br />
        Testers
      </span>
    ),
    accessor: 'acceptedTesters',
    getProps: () => ({ style: { textAlign: 'center' } }),
    maxWidth: 80,
  },
  {
    Header: () => (
      <span>
        Original
        <br />
        Price
        <br />
        (w/o VAT)
      </span>
    ),
    accessor: 'originalPrice',
    getProps: () => ({ style: { textAlign: 'center' } }),
    maxWidth: 80,
  },
  {
    Header: () => (
      <span>
        Used
        <br />
        Balance
      </span>
    ),
    accessor: 'usedBalance',
    getProps: () => ({ style: { textAlign: 'center' } }),
    maxWidth: 80,
  },
  {
    Header: () => (
      <span>
        Paid
        <br />
        (w/o VAT)
      </span>
    ),
    accessor: 'paid',
    getProps: () => ({ style: { textAlign: 'center' } }),
    maxWidth: 80,
  },
  {
    Header: 'Refunded',
    accessor: 'refunded',
    getProps: () => ({ style: { textAlign: 'center' } }),
    maxWidth: 80,
  },
];

class Finance extends Component<any> {
  componentDidMount() {
    this.props.listOwnFinance();
  }

  render() {
    const finance = this.props.finance || [];

    return (
      <div>
        <h2>Completed Cycles History</h2>
        <p className="customer-balance">Current balance: ${this.props.user.balance}</p>
        <ReactTable data={finance} columns={columns} noDataText="" />
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Finance));
