import { Grid, Typography } from '@mui/material';
import React, { Component } from 'react';

import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { NewWizardCycle } from '../../../types/NewWizardCycle';
import { UserRole } from '../../../types/UserRole';
import PayPalComp from './PayPalComp';


const styles = (theme) => createStyles({
  AttributeName: {
    fontSize: '14px',
    color: theme.palette.common.black,
    fontWeight: 'normal',
    letterSpacing: '0.1px',
  },

  AttributeNameBold: {
    fontSize: '14px',
    color: theme.palette.common.black,
    fontWeight: 'bold',
    letterSpacing: '0.1px',
  },
});

const mapStateToProps = (store) => {
  return {
    newCycle: store.newWizardCycle as NewWizardCycle,
    user: store.user,
    cycleCreateStatus: store.cycleCreateStatus,
    paymentOrderProperties: store.paymentOrderProperties,
  };
};

class CheckoutForm extends Component<any> {

  render() {

    const currentUrl = window.location.pathname;
    const { classes, newCycle, user, paymentOrderProperties } = this.props;

    const numberOfTesters = newCycle.testEnvs.reduce((result, el) => {
      result += el.requiredTesters;
      return result;
    }, 0);

    const hourlyRatePerTesterTxt = '$' + parseFloat(newCycle.hourlyRate.toFixed(2));

    const totalPrice = parseFloat((paymentOrderProperties.totalPriceWithoutVat).toFixed(2));                 //Total price of the test
    const userBalance: number = paymentOrderProperties.customerBalance;           //Player balance
    const amountWithoutVat: number = parseFloat((paymentOrderProperties.paypalOrderWithoutVat).toFixed(2));//Payment amountWithoutVat   

    const isWithVat = paymentOrderProperties.vatPercent > 0;                      //isWithVat
    const vatPercent: number = paymentOrderProperties.vatPercent;
    const vatAmount: number = parseFloat((paymentOrderProperties.vatAmount).toFixed(2));
    const amountIncludingVat: number = parseFloat((amountWithoutVat + vatAmount).toFixed(2));              //Total amount to pay!

    const userBalanceStr = `$${userBalance}`;
    const totalPriceStr = `$${totalPrice}`;
    const amountWithoutVatStr = `$${amountWithoutVat}`;
    const amountIncludingVatStr = `$${amountIncludingVat}`;
    const VatAmountStr = `$${vatAmount}`;

    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            {/* LEFT PANEL */}

            <Grid item xs={10}>
              <PayPalComp />
            </Grid>

          </Grid>
        </Grid>

        <Grid item xs={4}>

          <Grid container spacing={2} style={{ background: "#FBFBFC" }}>
            {/* RIGHT PANEL */}

            <Grid item xs={12}>
              <Typography variant="h5">Order</Typography>
            </Grid>


            <React.Fragment>
              <Grid item xs={6}>
                <Typography className={classes.AttributeName}>Amount of testers</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h4">{numberOfTesters}</Typography>
              </Grid>

              {currentUrl !== '/pricing/firstcycle' &&
                <React.Fragment>
                  <Grid item xs={6}>
                    <Typography className={classes.AttributeName}>Total per test</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h4">{hourlyRatePerTesterTxt}</Typography>
                  </Grid>
                </React.Fragment>
              }

              <Grid item xs={6}>
                <Typography className={classes.AttributeNameBold}>Total price</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h4">{totalPriceStr}</Typography>
              </Grid>
            </React.Fragment>

            {(this.props.user.role === UserRole.Customer || this.props.user.role === UserRole.Viewer) &&
              <React.Fragment>
                {/* Users with some balance */}
                {userBalance > 0 &&
                  <React.Fragment>

                    <Grid item xs={6}>
                      <Typography className={classes.AttributeNameBold}>Your balance</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h4">{userBalanceStr}</Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography className={classes.AttributeNameBold}>Amount to pay:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h4" style={{ textDecoration: 'underline' }}>{amountWithoutVatStr}</Typography>
                    </Grid>

                    {/* Payment with Vat*/}
                    {isWithVat &&
                      <React.Fragment>
                        <Grid item xs={6}>
                          <Typography className={classes.AttributeNameBold}>Vat ({vatPercent}%)</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="h4">{VatAmountStr}</Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <Typography className={classes.AttributeNameBold}>Payment total:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="h4" style={{ textDecoration: 'underline' }}>{amountIncludingVatStr}</Typography>
                        </Grid>
                      </React.Fragment>
                    }


                  </React.Fragment>
                }
                {/* Users with zero balance */}
                {(userBalance <= 0) &&
                  <React.Fragment>
                    {isWithVat &&
                      <React.Fragment>
                        <Grid item xs={6}>
                          <Typography className={classes.AttributeNameBold}>Vat ({vatPercent}%)</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="h4">{VatAmountStr}</Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <Typography className={classes.AttributeNameBold}>Payment total:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="h4">{amountIncludingVatStr}</Typography>
                        </Grid>
                      </React.Fragment>
                    }
                  </React.Fragment>
                }
              </React.Fragment>
            }

          </Grid>
        </Grid>

      </Grid>
    );
  }
}

export default withRouter(connect(mapStateToProps, null)(withStyles(styles)(CheckoutForm)));