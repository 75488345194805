export default [
  {
    text: '10',
    key: 10,
    value: 10,
  },
  {
    text: '15',
    key: 15,
    value: 15,
  },
  {
    text: '20',
    key: 20,
    value: 20,
  },
  {
    text: '25',
    key: 25,
    value: 25,
  },
  {
    text: '30',
    key: 30,
    value: 30,
  },
  {
    text: '35',
    key: 35,
    value: 35,
  },
  {
    text: '40',
    key: 40,
    value: 40,
  },
  {
    text: '45',
    key: 45,
    value: 45,
  },
  {
    text: '50',
    key: 50,
    value: 50,
  },
  {
    text: '55',
    key: 55,
    value: 55,
  },
  {
    text: '60',
    key: 60,
    value: 60,
  },
  {
    text: '65',
    key: 65,
    value: 65,
  },
  {
    text: '70',
    key: 70,
    value: 70,
  },
  {
    text: '75',
    key: 75,
    value: 75,
  },
  {
    text: '80',
    key: 80,
    value: 80,
  },
];
