const DevicePlatform = {
    'Iphone' : 'iPhone',
    'Ipad' : 'iPad',
    'AndroidMobile' : 'Android Mobile',
    'AndroidTablet' : 'Android Tablet',    
    'WindowsPhone' : 'Windows Phone',
    /* 'WindowsDesktop' : 'Windows Desktop', 
    'WindowsLaptop' : 'Windows Laptop',  */
    'Windows'   :   'Windows Desktop/Laptop',
    'MACDesktop' : 'MAC Desktop', 
    'MACNotebook' : 'MAC Notebook'
}

const platforms = Object.entries(DevicePlatform).reduce( (result, item) => {
    result.push({
        id: item[0],
        name: item[1],        
    })
    return result;
}, [] as  any );

export const platformArr = platforms;



  export class baseObject {
    id: number;
    name: string;

    constructor() {
        this.id = 0;
        this.name = '';
    }
  }

  //For main platform
  export interface PlatformBaseObject extends baseObject {
    minOSVersion?: baseObject;
    maxOSVersion?: baseObject;    
  }

  //For browser
  export interface BrowserBaseObject extends baseObject {
    minBrowserVersion: number;
    maxBrowserVersion: number;    
  }


  export class Country {
    key: string;
    value: string; 
    text: string;

    constructor(){
        this.key = '';
        this.value = '';
        this.text = '';
    }
  }

  //For Device
  export class DeviceTestObject {
    id: number;
    device: baseObject;
    country?: string;
    browser?: baseObject;
    numberOfTesters: number;
    
    constructor( index: number ) {
        this.id = index;
        this.device = new baseObject();
        this.device.name = 'Any';
        this.country = '';
        this.browser = new baseObject();
        this.browser.name = 'Relevant browsers'
        this.numberOfTesters = 5;
  }
}
  
  export type OS_Version = {
    id: number;
    version: string;
  }

  export type Browser_Version = {
    id: number;
    name: string;
    version: string;
  }



export const MOCPlatforms = [
    {
        "id" : 1, 
        "name": "iPhone", 
        "OSVersions":[         
            {
                "id": 11, 
                "name": "iOS 14.0"
            },
            {
                "id": 12,
                "name": "iOS 14.1"
            },
        ],
        "Devices":[
            {
                "id": 1, 
                "name": "iPhone6"
            },
            {
                "id": 2, 
                "name": "iPhone6 Plus"
            },
            {
                "id": 3, 
                "name": "iPhone8"
            },
            {
                "id": 4, 
                "name": "iPhoneX"
            },
        ],
        "browsers" : [
            {
                "id" : 2,
                "name" : "Safari", 
                "versionsAvailable":[
                    {
                        "id": 21, 
                        "name": "Safari 12"
                    },
                    {
                        "id": 22, 
                        "name": "Safari 13"
                    },
                    {
                        "id": 23, 
                        "name": "Safari 13"
                    },                    
                ]
            },
            {
                "id" : 3,
                "name" : "Chrome", 
                "versionsAvailable":[
                    {
                        "id": 31, 
                        "name": "87.0.42"
                    },
                    {
                        "id": 32, 
                        "name": "88.0.43"
                    },{
                        "id": 33, 
                        "name": "89.0.42"
                    },
                    {
                        "id": 35, 
                        "name": "90.0.43"
                    },      
                ]
            },            
        ]
    },
    
    {
        "id" : 4, 
        "name": "Android mobile", 
        "OSVersions":[         
            {
                "id": 41, 
                "name": "Android 5 Lollipop"
            },
            {
                "id": 42,
                "name": "Android 8 Oreo"
            },
            {
                "id": 43,
                "name": "Android 9 Pie"
            },
        ],
        "Devices":[
            {
                "id": 5, 
                "name": "Nexus 9"
            },
            {
                "id": 6, 
                "name": "Galaxy S3"
            },
            {
                "id": 7, 
                "name": "Xperia"
            },            
        ],

        "browsers" : [            
            {
                "id" : 5,
                "name" : "Chrome", 
                "versionsAvailable":[
                    {
                        "id": 51, 
                        "name": "89.0.4389"
                    },
                    {
                        "id": 52, 
                        "name": "90.0.441"
                    },      
                ]
            },            
        ]
    },

    {
        "id" : 6, 
        "name": "Windows PC", 
        "OSVersions":[         
            {
                "id": 61, 
                "name": "Windows 8.1"
            },
            {
                "id": 62,
                "name": "Windows 10"
            },
        ],
        "Devices":[
            {
                "id": 8, 
                "name": "PC"
            },            
        ],

        "browsers" : [            
            {
                "id" : 7,
                "name" : "Chrome", 
                "versionsAvailable":[
                    {
                        "id": 71, 
                        "name": "89.0.4389"
                    },
                    {
                        "id": 72, 
                        "name": "90.0.441"
                    },      
                ]
            },
            {
                "id" : 8,
                "name" : "Internet Explorer", 
                "versionsAvailable":[
                    {
                        "id": 81, 
                        "name": "9"
                    },
                    {
                        "id": 82, 
                        "name": "10"
                    },      
                ]
            },
            {
                "id" : 9,
                "name" : "Edge", 
                "versionsAvailable":[
                    {
                        "id": 91, 
                        "name": "90"
                    },
                    {
                        "id": 92, 
                        "name": "91"
                    },      
                ]
            },
        ]
    },
];

