import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Personal from './CompleteProfile/Personal';


const mapStateToProps = (state) => {
  return {
    device: state.resolution.device,
  };
};

class CompleteProfile extends Component<any> {
  public state: any;

  constructor(props) {
    super(props);

    this.state = {
      errors: [],
    };
  }

  render() {
    
    const isMobile = this.props.device === 'mobile' || this.props.device === 'mobile-tablet';

    if( isMobile ){
      return (
        <div className="complete-profile-tester page">            
            <div>
              <Personal />
            </div>          
        </div>
      );  
    }
    return (
      <div className="complete-profile-tester page">
        <div className="heading">
          <h1>Complete your profile</h1>
        </div>

        <div className="inner" style={{ display: 'flex' }}>
          <main>
            <Personal />
          </main>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(CompleteProfile);