import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { UserRole } from '../types/UserRole';
import { UserStatus } from '../types/UserStatus';

const PrivateRoute = ({ component, role, status, loginModalOpen, ...rest }: any) => {
  const Component = component;

  console.log('referer', window.location.pathname);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (role === UserRole.Anonymous) {
          if (['/pricing/speedtest', '/pricing/popular', '/custom'].includes(window.location.pathname)) {
            return (
              <Redirect
                to={{
                  pathname: '/signup',
                  state: {
                    referrer: window.location.pathname,
                    defaultRole: UserRole.Customer,
                  },
                }}
              />
            );
          }
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: {
                  referrer: window.location.pathname,
                },
              }}
            />
          );
        }

        switch (status) {
          case UserStatus.EmailPending:
            if (props.location.pathname !== '/email-pending') {
              return <Redirect to="/email-pending" />;
            }

            return <Component {...rest} {...props} />;
          case UserStatus.ProfilePending:
            if (props.location.pathname !== '/complete-profile/personal') {
              return <Redirect to="/complete-profile/personal" />;
            }

            return <Component {...rest} {...props} />;
          case UserStatus.PhonePending:
            if (props.location.pathname !== '/complete-profile/phone') {
              return <Redirect to="/complete-profile/phone" />;
            }

            return <Component {...rest} {...props} />;
          case UserStatus.DevicesPending:
            if (props.location.pathname !== '/complete-profile/devices') {
              return <Redirect to="/complete-profile/devices" />;
            }

            return <Component {...rest} {...props} />;
          case UserStatus.BrowsersPending:
            if (props.location.pathname !== '/complete-profile/browsers') {
              return <Redirect to="/complete-profile/browsers" />;
            }

            return <Component {...rest} {...props} />;
          case UserStatus.EvalCyclePending:
            if (role === UserRole.Tester) {
              if (
                !props.location.pathname.includes('/account/tester') &&
                !props.location.pathname.includes('/complete-profile/browsers') &&
                !props.location.pathname.includes('/complete-profile/settings')
              ) {
                return <Redirect to="/account/tester" />;
              }
            }

            if (role === UserRole.Customer || role === UserRole.Viewer) {
              if (!props.location.pathname.includes('/account/customer')) {
                return <Redirect to="/account/customer" />;
              }
            }

            return <Component {...rest} {...props} />;
          case UserStatus.Blocked:
            return <Redirect to="/user-blocked" />;
          case UserStatus.Deleted:
            return <Redirect to="/user-deleted" />;
          case UserStatus.Active:
            return <Component {...rest} {...props} />;
          default:
            throw new Error('Unknown routing error'); // FIXME
        }
      }}
    />
  );
};

PrivateRoute.defaultProps = { redirect: '/' };

export default PrivateRoute;
