import React, { Component } from 'react';
import { Box, Typography } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';


import wizardActions from '../../../redux/actions/wizardActions';
import { NewWizardCycle } from '../../../types/NewWizardCycle';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Exploratory from '../../../public/images/Exploritory.svg';
import TestPlan from '../../../public/images/TestPlan.svg';
import SharedStyle from './SharedStyle';
import { WorkCategory } from '../../../types/WorkCategory';


const styles = (theme) => createStyles({

  cardsBox: {
    marginTop: '20px',
    marginLeft: '-8px',
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      marginLeft: '8px',
      width: '200px',
      height: '200px',
    },
  },

  mediaExploratory: {
    height: '66px',
    width: '66px',
    },

  mediaTestPlan: {
      height: '66px',
      width: '55px',
    },

  cardDefault: {
      border: SharedStyle.contentCardDefault.border,
      borderRadius: SharedStyle.contentCardDefault.borderRadius,
      cursor: SharedStyle.contentCardDefault.cursor,       
  },

  cardSelected:{        
      border: SharedStyle.contentCardSelected.border,
      borderRadius: SharedStyle.contentCardSelected.borderRadius,
      cursor: SharedStyle.contentCardSelected.cursor,      
  },

  descriptionCardText: {
    fontSize: '13px',
    color: '#171725',    
  },

});

const mapStateToProps = (store) => {
  return {
    newCycle: store.newWizardCycle as NewWizardCycle,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    updateCycle: (data) => dispatch(wizardActions.updateCycle(data)),      
  };
};

class TypeForm extends Component<any> {
    
    onSelectionChangeIndex = (workCategory) => {
        const newCycle: NewWizardCycle = { ...this.props.newCycle };
        newCycle.workCategory = workCategory;
        this.props.updateCycle(newCycle);        
    }

    render() {

        const {classes, newCycle} = this.props;

        return(
                <Box>
                    <Typography variant="h2">
                          Select your test type
                    </Typography>

                    <Box className={classes.cardsBox}>

                        <Card elevation={0}
                            onClick={() => this.onSelectionChangeIndex(WorkCategory.FindBugsExploratory)}                             
                            className={newCycle.workCategory === WorkCategory.FindBugsExploratory ? classes.cardSelected : classes.cardDefault }
                        >
                            <CardContent>
                              <Box style={{ display: 'flex', flexDirection:'column', alignItems: 'center', padding: 6}}>
                                <CardMedia                          
                                  className={classes.mediaExploratory}
                                  image={Exploratory}                            
                                />                            
                                <Typography variant="h6">
                                    Exploratory
                                </Typography>
                                <Typography align="center" className={classes.descriptionCardText}>
                                    Testers check system on the fly
                                </Typography>                               
                              </Box>
                            </CardContent>
                        </Card>

                        <Card elevation={0}
                            onClick={() => this.onSelectionChangeIndex(WorkCategory.FindBugsTestPlan)}                             
                            className={newCycle.workCategory === WorkCategory.FindBugsTestPlan ? classes.cardSelected : classes.cardDefault }
                        >
                            <CardContent>
                              <Box style={{ display: 'flex', flexDirection:'column', alignItems: 'center', padding: 6}}>
                                <CardMedia                          
                                className={classes.mediaTestPlan}
                                image={TestPlan}                            
                                />                            
                                <Typography variant="h6">
                                    Test Plan
                                </Typography>
                                <Typography align="center" className={classes.descriptionCardText}>
                                  A Test Plan is a detailed document that describes the test strategy
                                </Typography>                                
                              </Box>
                            </CardContent>
                        </Card>

                    </Box>                                          
                </Box>   
        );    
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TypeForm)));