import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { Table } from 'semantic-ui-react';
import TesterDetailsSidemenu from './TesterDetailsSidemenu';
import { TesterReviewForm } from './TesterReviewForm';
import { TesterStatusInCycle } from '../../../../types/TesterStatusInCycle';
import { getApprovedDevicePrettyName } from '../../../../services/deviceService';
import { Gender } from '../../../../types/Gender';
import actions from '../../../../redux/actions';
import { UserRole } from '../../../../types/UserRole';

const mapStateToProps = (state) => {
  return {
    user: state.user,
    cycleTesters: state.cycleTesters,
    deviceModels: state.devices.models,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitCustomerReview: (testerId, cycleId, rating, text) =>
      dispatch(actions.createCustomerReview(cycleId, testerId, rating, text)),
  };
};

class TesterDetails extends Component<any> {
  public state: any;

  constructor(props) {
    super(props);

    this.state = {
      cycleId: +props.match.params.id,
      testerId: +props.match.params.testerId,
      addedToFavorites: false,
      review: '',
    };
  }

  componentDidMount() {
    if (!this.props.cycleTesters.byCycleId[this.state.cycleId]) {
      return null;
    }
    const tester = this.props.cycleTesters.byCycleId[this.state.cycleId].find(
      (el) => el.id === this.state.testerId
    );

    this.setState({
      ...this.state,
      tester,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.cycleTesters, this.props.cycleTesters)) {
      const tester = this.props.cycleTesters.byCycleId[this.state.cycleId].find(
        (el) => el.id === this.state.testerId
      );

      this.setState({
        ...this.state,
        tester,
      });
    }
    // if (this.state.testerId !== prevState.testerId) {
    //     const tester = this.props.cycleTesters.byCycleId[this.state.cycleId]
    //         .find(el => el.id === this.state.testerId);

    //     const deviceName = this.props.deviceModels.find(el => el.id === tester.modelId).name;

    //     this.setState({
    //         ...this.state,
    //         tester,
    //         deviceName
    //     });
    // }
  }

  onRatingChange = (nextValue) => {
    this.setState({
      rating: nextValue,
    });
  };

  onReviewTextChange = (event, data) => {
    this.setState({
      text: data.value,
    });
  };

  onSubmitReview = () => {
    this.props.submitCustomerReview(
      this.state.testerId,
      this.state.cycleId,
      this.state.rating,
      this.state.text
    );
    this.props.history.goBack();
  };

  render() {
    const tester = this.state.tester;

    if (!tester) {
      return null;
    }

    const approvedDeviceName = getApprovedDevicePrettyName(tester.approvedDevice);

    let gender;

    switch (tester.gender) {
      case Gender.Female:
        gender = 'Female';
        break;
      case Gender.Male:
        gender = 'Male';
        break;
      case Gender.Any:
        gender = 'Any';
        break;
      default:
        break;
    }

    return (
      <div className="account-customer page">
        <div className="heading">
          <h1>Cycle Details</h1>
        </div>

        <div className="inner" style={{ display: 'flex' }}>
          <TesterDetailsSidemenu
            cycleId={this.state.cycleId}
            avatarUrl={this.props.user.avatarUrl}
            companyName={this.props.user.companyName}
          />
          <main className="tester-details">
            <h2>Tester propfile</h2>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <img src={tester.avatarUrl} alt="tester's avatar" height={64} width={64} />
                <p>{tester.about}</p>
              </div>
              <Table className="tester-details-table" compact definition>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Name</Table.Cell>
                    <Table.Cell>{`${tester.firstName} ${tester.lastName}`}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Device</Table.Cell>
                    <Table.Cell>{approvedDeviceName}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Country</Table.Cell>
                    <Table.Cell>{tester.country}</Table.Cell>
                  </Table.Row>
                  {tester.otherLanguage && (
                    <Table.Row>
                      <Table.Cell>Other language</Table.Cell>
                      <Table.Cell>{tester.otherLanguage}</Table.Cell>
                    </Table.Row>
                  )}
                  {gender && (
                    <Table.Row>
                      <Table.Cell>Gender</Table.Cell>
                      <Table.Cell>{gender}</Table.Cell>
                    </Table.Row>
                  )}
                  <Table.Row>
                    <Table.Cell>Age</Table.Cell>
                    <Table.Cell>{tester.age}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Languages</Table.Cell>
                    <Table.Cell>
                      {tester.otherLanguage
                        ? `${tester.nativeLanguage}, ${tester.otherLanguage}`
                        : tester.nativeLanguage}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>

            {tester.statusInCycle === TesterStatusInCycle.AwaitingReview && (
              <TesterReviewForm
                isViewer={this.props.user.role === UserRole.Viewer}
                rating={this.state.rating}
                onRatingChange={this.onRatingChange}
                onReviewTextChange={this.onReviewTextChange}
                onSubmitReview={this.onSubmitReview}
              />
            )}
          </main>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TesterDetails));
