import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dimmer } from 'semantic-ui-react';
import cycleActions from '../../redux/actions/cycleActions';
import { UserRole } from '../../types/UserRole';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';

const mapStateToProps = (state) => {
  return {
    currentStep: state.newCycleCurrentStep,
    role: state.user.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    switchStep: (step) => dispatch(cycleActions.switchNewCycleStep(step)),
    updateCycle: (data) => dispatch(cycleActions.updateCycle(data)),
  };
};

class Cycles extends Component<any> {
  componentDidMount() {
    const newCycleFromLocalStorage = localStorage.getItem('newCycle');

    if (newCycleFromLocalStorage) {
      try {
        const newCycle = JSON.parse(newCycleFromLocalStorage);
        this.props.updateCycle(newCycle);
      } catch (e) {
        console.error('Problem with parsing json ' + e);
        localStorage.removeItem('newCycle');
      }
    } else {
      this.props.updateCycle(this.props.newCycle);
    }
  }
  render() {
    return (
      <div className="cycles page">
        <div className="heading">
          <h1>New Testing Project</h1>
        </div>

        <Dimmer.Dimmable blurring dimmed={this.props.role === UserRole.Tester}>
          <div className="cycle-steps-nav">
            <div
              className={this.props.currentStep === 1 ? ' step active' : 'step'}
              data-num="1"
              onClick={() => this.props.switchStep(1)}
            >
              <p>Get started</p>
            </div>

            <div
              className={this.props.currentStep === 2 ? ' step active' : 'step'}
              data-num="2"
              onClick={() => this.props.switchStep(2)}
            >
              <p>Project details</p>
            </div>

            <div
              className={this.props.currentStep === 3 ? ' step active' : 'step'}
              data-num="3"
              onClick={() => this.props.switchStep(3)}
            >
              <p>Confirm Order</p>
            </div>
          </div>

          {this.props.currentStep === 1 && <StepOne />}
          {this.props.currentStep === 2 && <StepTwo />}
          {this.props.currentStep === 3 && <StepThree />}

          <Dimmer inverted className="cycles-dimmer" active={this.props.role === UserRole.Tester}>
            <p>Please log in or register as a customer to create Cycles</p>
          </Dimmer>
        </Dimmer.Dimmable>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cycles));
