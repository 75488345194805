import { Gender } from '../../types/Gender';

export default [
  {
    text: 'Female',
    key: Gender.Female,
    value: Gender.Female,
  },
  {
    text: 'Male',
    key: Gender.Male,
    value: Gender.Male,
  },
  {
    text: 'Any',
    key: Gender.Any,
    value: Gender.Any,
  },
];
