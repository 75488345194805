import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Icon, Label, Menu, Tab, Button, Popup, Table, Message } from 'semantic-ui-react';
import Countdown, { zeroPad } from 'react-countdown';
import { TesterStatusInCycle } from '../../../types/TesterStatusInCycle';
import { UserStatus } from '../../../types/UserStatus';
import { CycleStatus } from '../../../types/CycleStatus';
import Sidemenu from './Sidemenu';
import { WorkCategory } from '../../../types/WorkCategory';
import cycleActions from '../../../redux/actions/cycleActions';
import _ from 'lodash';
import config from '../../../config';

const mapStateToProps = (state) => {
  return {
    user: state.user,
    cycles: state.cycles,
    cycleBugs: state.cycleBugs,
    device: state.resolution.device,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listCycles: () => dispatch(cycleActions.listCycles()),
  };
};

const ViewButton = ({ userStatus, cycle }) => {
  if (cycle.id === config.evalCycleId) {
    return (
      <Button
        primary
        size="mini"
        as={Link}
        to={`/account/tester/cycle/${cycle.id}`}
        content="View"
      />
    );
  }

  const isRejected =
    cycle.testerStatus === TesterStatusInCycle.Rejected ||
    (typeof cycle.testerStatus === 'undefined' && cycle.status === CycleStatus.Testing);

  if (userStatus === UserStatus.Active) {
    if (isRejected) {
      return <Button disabled size="mini" content="Bid closed" />;
    } else {
      return (
        <Button
          primary
          size="mini"
          as={Link}
          to={`/account/tester/cycle/${cycle.id}`}
          content="View"
        />
      );
    }
  }

  if (userStatus === UserStatus.EvalCyclePending) {
    if (cycle.testerStatus === TesterStatusInCycle.Rejected) {
      return <Button disabled size="mini" content="Bid closed" />;
    } else {
      return (
        <Popup
          trigger={<Button size="mini" content="View" />}
          content="You should complete the test cycle for new testers and get a rating from the Testpoint team first"
          on="hover"
          position="top left"
        />
      );
    }
  }

  return null;
};

const TestingOffersAndAwaitingApprovalCyclesTable = ({ userStatus, cycles, isMobile }) => {
  return (
    <Table striped compact className="cycles-table">
      
      {!isMobile && 
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Project title</Table.HeaderCell>
            <Table.HeaderCell>Ends In</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
      }
      
      <Table.Body>
        {cycles &&
          cycles.map((cycle) => (
            <Table.Row key={cycle.id}>
              <Table.Cell>{cycle.title}</Table.Cell>
              <Table.Cell>
                <Countdown
                  date={cycle.endDate}
                  daysInHours={false}
                  renderer={({ days, hours, minutes, seconds }) => {
                    return `${days} days, ${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(
                      seconds
                    )}`;
                  }}
                />
              </Table.Cell>
              <Table.Cell>
                <ViewButton userStatus={userStatus} cycle={cycle} />
              </Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  );
};

const CyclesTable = ({ cycles, isMobile }) => {
  return (
    <Table striped compact className="cycles-table">
      {!isMobile && 
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Project title</Table.HeaderCell>
            <Table.HeaderCell>Last modified</Table.HeaderCell>
            <Table.HeaderCell>Work type</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
      }
      <Table.Body>
        {cycles &&
          cycles.map((el) => {
            let workCategory;

            switch (el.workCategory) {
              case WorkCategory.FeedbackAndRating:
                workCategory = 'Get feedback and star rating';
                break;
              case WorkCategory.FindBugsExploratory:
                workCategory = 'Find bugs - exploratory';
                break;
              case WorkCategory.FindBugsTestPlan:
                workCategory = 'Find bugs - test plan';
                break;
            }

            return (
              <Table.Row key={el.id}>
                <Table.Cell>{el.title}</Table.Cell>
                <Table.Cell>
                  {el.updatedAt ? moment(el.updatedAt).format('DD.MM.YYYY HH:MM') : ''}
                </Table.Cell>
                <Table.Cell>{workCategory}</Table.Cell>
                <Table.Cell>
                  <Button primary size="mini" as={Link} to={`/account/tester/cycle/${el.id}`}>
                    View
                  </Button>
                  {el.totalUnreadMessages > 0 && 
                  ( <React.Fragment>
                      <Label>
                        <Icon name='envelope outline' /> {el.totalUnreadMessages}
                      </Label>
                    </React.Fragment> ) }
                </Table.Cell>
              </Table.Row>
            );
          })}
      </Table.Body>
    </Table>
  );
};

const NewCycleIntroduction = ({ firstName }) => {
  return (
    <Message info icon>
      <Icon size="big" name="exclamation" />
      <Message.Content>
        <Message.Header>
          Welcome {firstName}, you have completed your registration with Testpoint
        </Message.Header>
        <br />
        Before we open up your account for paid testing cycles, you need to complete a quick
        evaluation test job which will be reviewed by the Testpoint team to determine your initial
        Testpoint rating.
        <br />
        <br />
        Click on &quot;View&quot; to continue.
      </Message.Content>
    </Message>
  );
};

const AcceptedCycleIntroduction = (props) => {
  return (
    <Message info icon>
      <Icon size="big" name="exclamation" />
      <Message.Content>
        <p>
          You have accepted your first evaluation cycle.
          <br />
          Generally speaking test cycles have few statuses:
        </p>
        <ul>
          <li>
            <b>Testing offers:</b> shows all new test cycles which suit your profile
          </li>
          <li>
            <b>Awaiting approval:</b> shows test cycles you have accepted, and are pending approval
            before you can start withRouterking on them
          </li>
          <li>
            <b>In Progress:</b> shows approved test cycles which were awarded to you and you can
            start working on them
          </li>
          <li>
            <b>Completed:</b> shows test cycles you have completed
          </li>
        </ul>
        <p>
          Since this is your mandatory test cycle, it automatically appears under <b>In progress</b>{' '}
          as no approval is needed
          <br />
          Click on the <b>in progesss</b> tab, and click on &quot;view&quot; to start your work
        </p>
      </Message.Content>
    </Message>
  );
};

const CompletedCycleIntroduction = (props) => {
  return (
    <Message info icon>
      <Icon size="big" name="exclamation" />
      <Message.Content>
        <p>
          All right! You&apos;ve finished the mandatory cycle!
          <br />
          We will review your report, determine your rating and send you confirmation mail.
          <br />
          From that point on you will be able to get offers for paid testing cycles.
        </p>
      </Message.Content>
    </Message>
  );
};

class AccountTester extends Component<any> {
  public state: any;

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: -1,
    };
  }

  componentDidMount() {
    this.props.listCycles(); // TODO it always gets cycles twice. Think about performance here
  }

  sortCyclesByStatus = (cycles) => {
    const newCycles = cycles.filter((cycle) => {
      const isRightTesterStatus = typeof cycle.testerStatus === 'undefined';
      const isRightCycleStatus = [CycleStatus.Selection, CycleStatus.TestingAndSelection].includes(
        cycle.status
      );

      return isRightTesterStatus && isRightCycleStatus;
    });

    const rejectedCycles = cycles.filter((cycle) => {
      // tester explicitly rejected
      if (cycle.testerStatus === TesterStatusInCycle.Rejected) {
        return true;
      }

      // tester wasn't accepted and already won't be accepted
      if (typeof cycle.testerStatus === 'undefined' && cycle.status === CycleStatus.Testing) {
        return true;
      }

      return false;
    });

    const awaitingCycles = cycles.filter((cycle) => {
      const isRightTesterStatus = cycle.testerStatus === TesterStatusInCycle.Pending;
      const isRightCycleStatus = [CycleStatus.Selection, CycleStatus.TestingAndSelection].includes(
        cycle.status
      );

      return isRightTesterStatus && isRightCycleStatus;
    });

    const inprogressCycles = cycles.filter((cycle) => {
      const isRightTesterStatus = [
        TesterStatusInCycle.Approved,
        TesterStatusInCycle.AwaitingReview,
        TesterStatusInCycle.Finished,
      ].includes(cycle.testerStatus);
      let isRightCycleStatus;

      if (this.props.user.status === UserStatus.EvalCyclePending) {
        isRightCycleStatus = [
          CycleStatus.Selection,
          CycleStatus.TestingAndSelection,
          CycleStatus.Testing,
          CycleStatus.Completed,
        ].includes(cycle.status);
      } else {
        isRightCycleStatus = [
          CycleStatus.Selection,
          CycleStatus.TestingAndSelection,
          CycleStatus.Testing,
        ].includes(cycle.status);
      }

      return isRightTesterStatus && isRightCycleStatus;
    });

    const completedCycles = cycles.filter((cycle) => {
      const isRightTesterStatus = [
        TesterStatusInCycle.Approved,
        TesterStatusInCycle.AwaitingReview,
        TesterStatusInCycle.Finished,
      ].includes(cycle.testerStatus);
      let isRightCycleStatus;

      if (this.props.user.status === UserStatus.EvalCyclePending) {
        isRightCycleStatus = false;
      } else {
        isRightCycleStatus = cycle.status === CycleStatus.Completed;
      }

      return isRightTesterStatus && isRightCycleStatus;
    });

    const newAndRejected = [...newCycles, ...rejectedCycles];

    return {
      newAndRejected,
      awaitingCycles,
      inprogressCycles,
      completedCycles: completedCycles.reverse(),
    };
  };

  onTabChange = (event, data) => {
    this.setState({ activeIndex: data.activeIndex });
  };

  render() {
    const firstName = this.props.user.firstName || '-';
    const lastName = this.props.user.lastName || '-';

    const isMobile = this.props.device === 'mobile' || this.props.device === 'mobile-tablet';

    const sortedCycles = this.sortCyclesByStatus(this.props.cycles);

    let activeIndex;

    if (this.state.activeIndex > -1) {
      activeIndex = this.state.activeIndex;
    } else {
      if (sortedCycles.newAndRejected.length) {
        activeIndex = 0;
      } else if (sortedCycles.awaitingCycles.length) {
        activeIndex = 1;
      } else if (sortedCycles.inprogressCycles.length) {
        activeIndex = 2;
      } else if (sortedCycles.completedCycles.length) {
        activeIndex = 3;
      } else {
        activeIndex = -1;
      }
    }

    const panes = [
      {
        menuItem: (
          <Menu.Item key="newCycles" disabled={!sortedCycles.newAndRejected.length} style={isMobile ? { fontSize: '0.85rem' } : {}}>
            Testing Offers{' '}
            {sortedCycles.newAndRejected.length > 0 && !isMobile && (
              <Label>{sortedCycles.newAndRejected.length}</Label>
            )}
            {sortedCycles.newAndRejected.length > 0 && isMobile && (
              <label style={{fontWeight: 'bold', fontSize: '1.5rem', background: '#ddd'}}>{sortedCycles.newAndRejected.length}</label>
            )}
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <TestingOffersAndAwaitingApprovalCyclesTable
              isMobile
              userStatus={this.props.user.status}
              cycles={sortedCycles.newAndRejected}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="awaitingCycles" disabled={!sortedCycles.awaitingCycles.length} style={isMobile ? { fontSize: '0.85rem' } : {}}>
            Awaiting Approval{' '}
            {sortedCycles.awaitingCycles.length > 0 && !isMobile && (
              <Label>{sortedCycles.awaitingCycles.length}</Label>
            )}
          {sortedCycles.awaitingCycles.length > 0 && isMobile && (
              <label style={{fontWeight: 'bold', fontSize: '1.5rem', background: '#ddd'}}>{sortedCycles.awaitingCycles.length}</label>
            )}
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <TestingOffersAndAwaitingApprovalCyclesTable
              isMobile
              userStatus={this.props.user.status}
              cycles={sortedCycles.awaitingCycles}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="inprogressCycles" disabled={!sortedCycles.inprogressCycles.length} style={isMobile ? { fontSize: '0.85rem' } : {}}>
            In Progress{' '}
            {sortedCycles.inprogressCycles.length > 0 && !isMobile && (
              <Label>{sortedCycles.inprogressCycles.length}</Label>
            )}
            {sortedCycles.inprogressCycles.length > 0 && isMobile && (
              <label style={{fontWeight: 'bold', fontSize: '1.5rem', background: '#ddd'}}>{sortedCycles.inprogressCycles.length}</label>
            )}
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <CyclesTable cycles={sortedCycles.inprogressCycles} isMobile/>
          </Tab.Pane>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="completedCycles" disabled={!sortedCycles.completedCycles.length} style={isMobile ? { fontSize: '0.85rem' } : {}}>
            Completed{' '}
            {sortedCycles.completedCycles.length > 0 && !isMobile && (
              <Label>{sortedCycles.completedCycles.length}</Label>
            )}
          {sortedCycles.completedCycles.length > 0 && isMobile && (
              <label style={{fontWeight: 'bold', fontSize: '1.5rem', background: '#ddd'}}>{sortedCycles.completedCycles.length}</label>
            )}
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <CyclesTable cycles={sortedCycles.completedCycles} isMobile/>
          </Tab.Pane>
        ),
      },
    ];

    const showNewCycleIntro =
      this.props.user.status === UserStatus.EvalCyclePending &&
      sortedCycles.newAndRejected.length > 0 &&
      sortedCycles.inprogressCycles.length === 0 &&
      sortedCycles.completedCycles.length === 0;

    const showAcceptedCycleIntro =
      this.props.user.status === UserStatus.EvalCyclePending &&
      sortedCycles.inprogressCycles.length === 1 &&
      sortedCycles.inprogressCycles[0].testerStatus === TesterStatusInCycle.Approved;

    const showFinishedCycleIntro =
      this.props.user.status === UserStatus.EvalCyclePending &&
      sortedCycles.inprogressCycles.length === 1 &&
      sortedCycles.inprogressCycles[0].testerStatus === TesterStatusInCycle.AwaitingReview;

      if(this.props.device === 'mobile' || this.props.device === 'mobile-tablet')
      {        
        return (
          <div>
            <Sidemenu
              firstName={firstName}
              lastName={lastName}
              avatarUrl={this.props.user.avatarUrl}
            />
            {showNewCycleIntro && <NewCycleIntroduction firstName={this.props.user.firstName} />}

            {showAcceptedCycleIntro && <AcceptedCycleIntroduction />}

            {showFinishedCycleIntro && <CompletedCycleIntroduction />}
              
            <Tab
              menu={{ pointing: true }}
              panes={panes}
              activeIndex={activeIndex}
              onTabChange={this.onTabChange}
            />
          
          </div>
          );
      }
    return (
      <div className="account-tester page">
        <div className="heading">
          <h1>Tester Dashboard</h1>
        </div>

        <div className="inner" style={{ display: 'flex' }}>
          <Sidemenu
            firstName={firstName}
            lastName={lastName}
            avatarUrl={this.props.user.avatarUrl}
          />
          <main>
            {showNewCycleIntro && <NewCycleIntroduction firstName={this.props.user.firstName} />}

            {showAcceptedCycleIntro && <AcceptedCycleIntroduction />}

            {showFinishedCycleIntro && <CompletedCycleIntroduction />}

            <Tab
              menu={{ pointing: true }}
              panes={panes}
              activeIndex={activeIndex}
              onTabChange={this.onTabChange}
            />
          </main>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountTester));
