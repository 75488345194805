import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { FormLabel } from '@mui/joy';
import Radio, { radioClasses } from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Sheet from '@mui/joy/Sheet';
import { Box, IconButton, MobileStepper, Button as MuiButton, Typography } from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import nativeApp from '../../public/images/nativeApp.svg';
import website from '../../public/images/website.svg';
import { RootState } from '../../redux/store';

import { CssVarsProvider } from '@mui/joy/styles';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import wizardActions from '../../redux/actions/wizardActions';
import { PayoutRatesAndTestersQuota, SpecificationType } from '../../types/WizardCategoriesTypes';
import { titleBlue } from '../Wizard/InnerForms/SharedStyle';

import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';



import AppleIcon from '@mui/icons-material/Apple';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid';
import TabletMacIcon from '@mui/icons-material/TabletMac';
import WindowIcon from '@mui/icons-material/Window';

import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { DevicePlatform } from '../../types/DevicePlatform';
import { UserRole } from '../../types/UserRole';
import { Mixpanel } from '../../util/mixpanel';



const useStyles = makeStyles((theme: Theme) => ({
    descriptionCardText: {
        fontSize: '14px',
        color: '#171725',
    },
    platformBox: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '70%',
        '& > *': {
            marginRight: '8px',
            marginBottom: '8px',
        },
    },
    counterLabel: {
        disabledRipple: true,
        color: titleBlue,
        fontFamily: 'Poppins',
        fontSize: '15px',
        /* fontWeight: 'bold', */
        cursor: 'default',
        '&:hover': {
            background: 'none !important',
        },
    },
}));


const CounterSelector = (props) => {
    const classes = useStyles();
    const { val, onIncrement, onDecrement, minVal } = props;

    return (
        <Box>
            <IconButton onClick={onIncrement} disabled={val > 100} size="large"> <ExpandLessIcon /> </IconButton>
            {/* <CssVarsProvider> */}
            <MuiButton disableRipple /* variant="soft" color="neutral" */ className={classes.counterLabel} >
                {val}
            </MuiButton>
            {/* </CssVarsProvider> */}
            <IconButton
                disabled={minVal === val ? true : false}
                onClick={onDecrement}
                size="large"> <ExpandMoreIcon /> </IconButton>
        </Box>
    );
}

export default function StepOneForm(props) {

    const { pricingType, next, back } = props;

    const { newWizardCycle, systemPlatforms, user } = useSelector((state: RootState) => state);

    const dispatch = useDispatch();

    const selectionChanged = (e) => {
        newWizardCycle[e.target.name] = e.target.value;
        dispatch(wizardActions.updateCycle(newWizardCycle));
    }

    const onIncrement = () => {
        ++newWizardCycle.numberOfTesters;
        dispatch(wizardActions.updateCycle(newWizardCycle));
    }
    const onDecrement = () => {
        --newWizardCycle.numberOfTesters;
        dispatch(wizardActions.updateCycle(newWizardCycle));
    }

    const platformSelected = (e) => {
        const selectedPlatform = systemPlatforms.find((sysPlatform) => sysPlatform['id'] === e.target.value);
        if (selectedPlatform) {
            newWizardCycle.mainTestPlatform = { id: selectedPlatform['id'], name: selectedPlatform['name'], minOSVersion: { id: -1, name: '' }, maxOSVersion: { id: -1, name: '' } } //Setting it as the unique default Platform              

            dispatch(wizardActions.updateCycle(newWizardCycle));
        }

    }

    const platformIdToIcon = (platformId) => {
        switch (platformId) {
            case DevicePlatform.Iphone:
                return (<PhoneIphoneIcon></PhoneIphoneIcon>);
            case DevicePlatform.Ipad:
                return (<TabletMacIcon></TabletMacIcon>);
            case DevicePlatform.AndroidMobile:
                return (<PhoneAndroidIcon></PhoneAndroidIcon>);
            case DevicePlatform.WindowsPhone:
                return (<WindowIcon></WindowIcon>);
            case DevicePlatform.AndroidTablet:
                return (<TabletAndroidIcon></TabletAndroidIcon>);
            case DevicePlatform.Windows:
                return (<DesktopWindowsIcon></DesktopWindowsIcon>);
            case DevicePlatform.MACDesktop:
                return (<DesktopMacIcon></DesktopMacIcon>);
            case DevicePlatform.MACNotebook:
                return (<AppleIcon></AppleIcon>);
            default:
                return null;
        }
    }

    useEffect(() => {
        let userId = user.role == UserRole.Anonymous ? -1 : user.id;
        Mixpanel.track('Entered Pricing Step 1', { userId, pricingType });
    }, []);

    const classes = useStyles();
    return (
        <React.Fragment>
            <Box sx={{ m: 2 }} /* sx={{ m: { md: 2, lg: 5 } }} for responsiveness - per breakpoint! */>
                <Typography variant="h2">
                    What are we going to test:
                </Typography>
                <Box sx={{ mt: 2 }}>
                    <CssVarsProvider>
                        <RadioGroup
                            /* style={{ border: '2px solid #3179FF' }} */
                            aria-label="platform"
                            value={newWizardCycle.testSpecification}
                            onChange={selectionChanged}
                            overlay
                            name="testSpecification"
                            sx={{
                                flexDirection: 'row',
                                gap: 2,
                                [`& .${radioClasses.checked}`]: {
                                    [`& .${radioClasses.action}`]: {
                                        inset: -1,
                                        border: '3px solid',
                                        borderColor: 'orange',
                                    },
                                },
                                [`& .${radioClasses.radio}`]: {
                                    display: 'contents',
                                    '& > svg': {
                                        zIndex: 2,
                                        position: 'absolute',
                                        top: '-8px',
                                        right: '-8px',
                                        bgcolor: 'background.body',
                                        borderRadius: '50%',
                                    },
                                },
                            }}
                        >
                            <Sheet
                                key="native"
                                variant="outlined"
                                sx={{
                                    borderRadius: 'md',
                                    bgcolor: 'background.level1',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: 1.5,
                                    p: 2,
                                    minWidth: 200,
                                }}
                            >
                                <Radio id="native" value={SpecificationType.NativeApp} checkedIcon={<CheckCircleRoundedIcon />} />
                                <Box
                                    component="img"
                                    sx={{
                                        height: 66,
                                        width: 66,
                                    }}
                                    src={nativeApp}
                                />
                                <FormLabel htmlFor="native">Native App</FormLabel>
                                <Typography gutterBottom align="center" className={classes.descriptionCardText}>
                                    Desktop, Android or iOS apps
                                </Typography>
                            </Sheet>

                            <Sheet
                                key="website"
                                variant="outlined"
                                sx={{
                                    borderRadius: 'md',
                                    bgcolor: 'background.level1',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: 1.5,
                                    p: 2,
                                    minWidth: 200,
                                }}
                            >
                                <Radio id="website" value={SpecificationType.Website} checkedIcon={<CheckCircleRoundedIcon />} />
                                <Box
                                    component="img"
                                    sx={{
                                        height: 66,
                                        width: 68,
                                    }}
                                    src={website}
                                />
                                <FormLabel htmlFor="Web App">Website</FormLabel>
                                <Typography gutterBottom align="center" className={classes.descriptionCardText}>
                                    Websites and mobile web-apps
                                </Typography>
                            </Sheet>
                        </RadioGroup>
                    </CssVarsProvider>
                    <Box sx={{ mt: 5 }}>
                        <Typography variant="h2">
                            On which Platform:
                        </Typography>
                        <Box sx={{ mt: 1 }}>
                            <CssVarsProvider>
                                <RadioGroup aria-label="Your plan" name="people" /* defaultValue="Individual" */ onChange={platformSelected} value={newWizardCycle.mainTestPlatform.id}>
                                    <div className={classes.platformBox}>
                                        {systemPlatforms.map((platformObj, index) => (
                                            <List
                                                key={platformObj['id']}
                                                sx={{
                                                    '--List-gap': '0.5rem',
                                                    '--List-item-paddingY': '1rem',
                                                    '--List-item-radius': '8px',
                                                    '--List-decorator-size': '32px',
                                                }}
                                            >
                                                <ListItem variant="outlined" key={platformObj['id']}>
                                                    <ListItemDecorator>
                                                        {platformIdToIcon(platformObj['id'])}
                                                    </ListItemDecorator>
                                                    <Radio
                                                        overlay
                                                        color='warning'
                                                        value={platformObj['id']}
                                                        label={platformObj['name']}
                                                        sx={{ flexGrow: 1, flexDirection: 'row-reverse' }}
                                                        componentsProps={{
                                                            action: ({ checked }) => ({
                                                                sx: (theme) => ({
                                                                    ...(checked && {
                                                                        inset: -1,
                                                                        border: '2px solid',
                                                                        borderColor: 'orange', /* theme.vars.palette.primary[500] */
                                                                    }),
                                                                }),
                                                            }),
                                                        }}
                                                    />
                                                </ListItem>
                                            </List>
                                        ))}
                                    </div>
                                </RadioGroup>
                            </CssVarsProvider>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        {(pricingType === 'firstcycle') ?
                            (<Typography variant="h2">

                            </Typography>) :
                            (<>
                                <Typography variant="h2">
                                    And with how many testers:
                                </Typography>
                                <Box sx={{ mt: 0.5 }}>
                                    <CounterSelector align="center"
                                        minVal={PayoutRatesAndTestersQuota.minTestersPerCycle}
                                        val={newWizardCycle.numberOfTesters}
                                        onIncrement={() => onIncrement()}
                                        onDecrement={() => onDecrement()}
                                    >
                                    </CounterSelector>
                                </Box>
                            </>)
                        }

                    </Box>
                </Box>
            </Box >


            <Box sx={{ width: '75%' }}>
                <MobileStepper
                    variant="text"
                    steps={4}
                    position="static"
                    activeStep={0}
                    nextButton={
                        <MuiButton
                            size="large"
                            onClick={next}
                            disabled={newWizardCycle.testSpecification === '' || newWizardCycle.mainTestPlatform.id <= 0}
                        >
                            Next
                            <KeyboardArrowRight />
                        </MuiButton>
                    }
                    backButton={
                        <MuiButton disabled size="large" /* onClick={handleBack} disabled={activeStep === 0} */>
                            {/* <KeyboardArrowLeft />
                            Back */}
                        </MuiButton>
                    }
                />
            </Box>

        </React.Fragment>
    );

}
