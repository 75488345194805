import capitalize from 'lodash/capitalize';
import { BugStatus } from '../types/BugStatus';
import { BugSeverity } from '../types/BugSeverity';

export const getCycleBugById = (cycleBugs, cycleId, bugId) => {
    return cycleBugs?.byCycleId[cycleId]?.find(
        (bug: any) => bug.id === bugId
    ) || {};
};

export const getCycleBugsByStatus = (cycleBugs, cycleId, status: BugStatus = BugStatus.Fixed) => {
    return cycleBugs?.byCycleId[cycleId]?.filter(
        (bug: any) => bug.status === status
    ) || [];
};

export const getCycleBugsBySeverity = (cycleBugs, cycleId, severity: BugSeverity | 'n_a') => {
    return cycleBugs?.byCycleId[cycleId]?.filter(
        (bug: any) => bug.severity === severity
    ) || [];
};

export const getBugStatus = (bugStatus) : string => {
    switch (bugStatus) {
        case BugStatus.NotABug: 
            return 'Not a bug'; 
        case BugStatus.New: 
            return 'Open';
        case BugStatus.Fixed: 
            return 'Fixed';
        case BugStatus.Duplicated: 
            return 'Duplicated';
        case BugStatus.Deferred: 
            return 'Closed'; 
        default: 
            return capitalize(bugStatus);
    }
};

export const bugStatusOptions = [
    { value: BugStatus.NotABug, label: getBugStatus(BugStatus.NotABug) },
    { value: BugStatus.New, label: getBugStatus(BugStatus.New) },
    { value: BugStatus.Fixed, label: getBugStatus(BugStatus.Fixed) },
    { value: BugStatus.Duplicated, label: getBugStatus(BugStatus.Duplicated) },
    { value: BugStatus.Deferred, label: getBugStatus(BugStatus.Deferred) },
];

export const getBugSeverity = (bugSeverity) : string => {
    switch ( bugSeverity ) {
        case BugSeverity.Critical:
            return 'Critical';
        case BugSeverity.High:
            return 'High';
        case BugSeverity.Low:
            return 'Low';
        case BugSeverity.Medium:
            return 'Medium';
        default:
            return capitalize(bugSeverity);            
    }
};

export const bugSeverityOptions = [
    { value: BugSeverity.Low, label: getBugSeverity(BugSeverity.Low) },
    { value: BugSeverity.Medium, label: getBugSeverity(BugSeverity.Medium) },
    { value: BugSeverity.High, label: getBugSeverity(BugSeverity.High) },
    { value: BugSeverity.Critical, label: getBugSeverity(BugSeverity.Critical) },
]