
import set from 'lodash/set';
import isArray from 'lodash/isArray';
import { Button, Input } from '@mui/material';
import React, { useRef, useState, useEffect } from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { PayPalButtons, PayPalScriptProvider, usePayPalScriptReducer } from '@paypal/react-paypal-js';

import config from '../../../config';
import { RootState } from '../../../redux/store';
import wizardActions from '../../../redux/actions/wizardActions';



const sendPaymentRequest = (requestType, body) => 
  fetch(`${config.apiEndpoint}/api/${requestType}`, {
    method: 'POST',
    headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(body).replace(/\u2028/g, '\\u2028').replace(/\u2029/g, '\\u2029'),
  }).then((res)  => res.json());



const fetchclientToken = () =>
  fetch(`${config.apiEndpoint}/api/fetchClientToken`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify({}),
  }).then((res)  => res.json()).then((res)  => res?.client_token);


const PayPalComp: React.FC = () => {
// const classes = useStyles();
const dispatch = useDispatch();

const cardFormEl = useRef<any>(null);

const [card, setCard] = useState({
  cardholderName: '',
  billingAddress: {
    streetAddress: '',
    extendedAddress: '',
    region: '',
    locality: '',
    postalCode: '',
    countryCodeAlpha2: '',
  },
});

const onChangeCardData = (field) => (e) => {
  const cardData = { ...card };
  set(cardData, field, e.target.value);
  setCard(cardData);
};

//const [{ isResolved  }] = usePayPalScriptReducer();
const [tokenState, setTokenState] = useState<string>('fetched'); //const [tokenState, setTokenState] = useState<string>('Pre-fetch');
const [orderId, setOrderId] = useState<string>('');
const [clientToken, setClientToken] = useState<string | null | undefined>('');

const { user, paymentOrderProperties } = useSelector((state: RootState) => state);

const createOrder = () => 
  sendPaymentRequest('createOrder', paymentOrderProperties).then((data) => data.id);

const onApprove = (data) => 
  sendPaymentRequest('captureOrder', {
    orderID: data.orderID,
    customerId: user.id,
    cycleId: paymentOrderProperties.cycleId,
  }).then((cycleData) => {        
    dispatch(wizardActions.goToCycleDashboard(cycleData));
  });   

  const onCancel = (data, actions) => 
    console.log('On Cancel');

  const onError = (err) => 
    console.log('Error occured', err);

  const onSubmit = (cardFields) => {
    console.log('onSubmit');
    cardFormEl?.current?.addEventListener('submit', (event) => {
      event.preventDefault();
      cardFields.submit(card).then(() => onApprove({ orderID: orderId })).catch(onError);
    });
  };

  
/*    useEffect(function getClientToken() {      
    (async() => {
      try {
        setTokenState('fetching');
        const token = await fetchclientToken();          
        setClientToken(token);
        setTokenState('fetched');
      } catch(e) {
        setTokenState('fetched');
        onError(e);
      }
    })();
  }, []); */

  useEffect(function onClientToken() {            
    if (clientToken && (window as any)?.paypal?.HostedFields?.isEligible()) {        
      (window as any)?.paypal.HostedFields.render({          
        createOrder() {            
          createOrder().then((orderData) => {
            setOrderId(orderData.id);
            return orderData.id;
          });
        },
        fields: {
          number: { selector: '#card-number', placeholder: '4111 1111 1111 1111' },
          cvv: { selector: '#cvv', placeholder: '123' },
          expirationDate: { selector: '#expiration-date', placeholder: 'MM/YY' },
        },
      }).then(onSubmit);
    }
  }, [clientToken, tokenState]);

  if( tokenState == 'Pre-fetch' || tokenState == 'fetching' ){
    return(<></>);
  }
  else{
    if (!clientToken) {
      return (            
        <PayPalScriptProvider options={{ 'client-id': config.PayPalClientId }}>
          <PayPalButtons
            createOrder={createOrder}
            onApprove={onApprove}
            onCancel={onCancel}
            onError={onError}
          />
        </PayPalScriptProvider>
      );
    }
    else{
      return (          
        <PayPalScriptProvider options={{ 'components': "buttons,hosted-fields", 'client-id': config.PayPalClientId, 'disable-funding': "credit,card", 'data-client-token': clientToken }}>
          <PayPalButtons
              createOrder={createOrder}
              onApprove={onApprove}
              onCancel={onCancel}
              onError={onError}                    
          />
          { (window as any)?.paypal?.HostedFields?.isEligible() && 
            <div className='card_container'>
              <form id='card-form' ref={cardFormEl}>
                <label htmlFor='card-number'>Card Number</label>
                <div id='card-number' className='card_field'></div>
                <div>
                  <label htmlFor='expiration-date'>Expiration Date</label>
                  <div id='expiration-date' className='card_field'></div>
                </div>
                <div>
                  <label htmlFor='cvv'>CVV</label>
                  <div id='cvv' className='card_field' />
                </div>
                <label htmlFor='card-holder-name'>Name on Card</label>
                <Input 
                  type='text' 
                  autoComplete='off' 
                  id='card-holder-name' 
                  name='card-holder-name' 
                  value={card.cardholderName}
                  placeholder='card holder name'
                  onChange={onChangeCardData('cardholderName')}
                />
                <div>
                  <label htmlFor='card-billing-address-street'>Billing Address</label>
                  <Input 
                    type='text' 
                    autoComplete='off' 
                    placeholder='street address'
                    id='card-billing-address-street' 
                    name='card-billing-address-street' 
                    value={card.billingAddress.streetAddress}
                    onChange={onChangeCardData('billingAddress.streetAddress')}
                  />
                </div>
                <div>
                  <label htmlFor='card-billing-address-unit'>&nbsp;</label>
                  <Input 
                    type='text' 
                    autoComplete='off' 
                    placeholder='unit'
                    id='card-billing-address-unit' 
                    name='card-billing-address-unit' 
                    value={card.billingAddress.extendedAddress}
                    onChange={onChangeCardData('billingAddress.extendedAddress')}
                  />
                </div>
                <div>
                  <Input 
                    type='text' 
                    autoComplete='off' 
                    placeholder='city'
                    id='card-billing-address-city' 
                    name='card-billing-address-city' 
                    value={card.billingAddress.region}
                    onChange={onChangeCardData('billingAddress.region')}
                  />
                </div>
                <div>
                  <Input 
                    type='text' 
                    autoComplete='off' 
                    placeholder='state'
                    id='card-billing-address-state' 
                    name='card-billing-address-state' 
                    value={card.billingAddress.locality}
                    onChange={onChangeCardData('billingAddress.locality')}
                  />
                </div>
                <div>
                  <Input 
                    type='text' 
                    autoComplete='off' 
                    id='card-billing-address-zip' 
                    name='card-billing-address-zip' 
                    placeholder='zip / postal code'
                    value={card.billingAddress.postalCode}
                    onChange={onChangeCardData('billingAddress.postalCode')}
                  />
                </div>
                <div>
                  <Input 
                    type='text' 
                    autoComplete='off' 
                    placeholder='country code' 
                    id='card-billing-address-country' 
                    name='card-billing-address-country' 
                    value={card.billingAddress.countryCodeAlpha2}
                    onChange={onChangeCardData('billingAddress.countryCodeAlpha2')}
                  />
                </div>
                <br />
                <Button value='submit' id='submit' className='btn'>Pay</Button>
              </form>
            </div>
          }
          {/* !(clientToken && (window as any)?.paypal?.HostedFields?.isEligible()) &&
                <div>NOTHING</div>
          */}
        </PayPalScriptProvider>            
    );
    }      
  }              
};

export default PayPalComp;