import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import config from '../../../config';
import * as utils from '../../../util/utils';
import BugVideoDisplay from './BugVideoDisplay';

export const BugAttachments = (props) => {
  const editable = props.onDeleteImage && props.onDeleteVideo;

  return (
    <div className="bug-uploads">
      {props.imageFilePaths && props.imageFilePaths && (
        <div>
          <h4>Uploaded attachments</h4>
          {props.imageFilePaths.map((imageFilePath, i) => {
            const href = new URL(imageFilePath, config.apiEndpoint).href;
            const filename = imageFilePath.split('/').pop();
            const isImage = utils.isImage(filename);
            const ext = utils.getFileExtension(filename);

            return (
              <div key={i} className="bug-attachment">
                {isImage ? (
                  <a target="_blank" href={href}>
                    <img src={href} width="240" alt="Bug screenshot" />
                  </a>
                ) : (
                  <a className="non-image-attachment" target="_blank" href={href}>
                    <Icon name="file alternate outline" />
                    {ext}
                  </a>
                )}
                {editable && (
                  <Button
                    basic
                    color="red"
                    className="delete-upload"
                    size="mini"
                    icon="trash alternate"
                    onClick={props.onDeleteImage}
                    data-index={i}
                  />
                )}
              </div>
            );
          })}
        </div>
      )}

      {props.videoFilePaths && props.videoFilePaths && (
        <div>
          <h4>Uploaded video</h4>
          {props.videoFilePaths.map((videoFilePath, i) => {
            const href = new URL(videoFilePath, config.apiEndpoint).href;
            return <BugVideoDisplay key={i} manual index={i} label={`Video #${i}`} src={href} />;
          })}
        </div>
      )}
    </div>
  );
};
