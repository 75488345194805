import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { Theme } from '@mui/material';
import Button from '@mui/material/Button';
import { grey } from '@mui/material/colors';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React, { useRef, useState, useEffect } from 'react';

import actions from '../../../../redux/actions';
import chatService from '../../../../services/chatService';
import { RootState } from '../../../../redux/store';
import { UserRole } from '../../../../types/UserRole';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: 15,
        width: '100%',
        borderRadius: 8,
        border: `1px solid ${grey[500]}`,
    },
    title: {
        padding: '15px 30px',
        borderBottom: `1px solid ${theme.palette.primary.light}`,
    },
    input: {
        width: '100%',
        marginTop: 15,
        marginBottom: 15,
    },
    button: {
        borderRadius: 12,
        padding: '5px 10px',
    },
    chip: {
        marginLeft: 15,
        borderRadius: 6,
        padding: '4px 2px',
        backgroundColor: grey[200],
    },
}));

interface INewBugsMessage {
    chatId: number;
    refetchMessages: () => void;
}

const NewBugsMessage: React.FC<INewBugsMessage> = ({ chatId, refetchMessages }) => {
    const classes = useStyles();

    const { user } = useSelector((state: RootState) => state);    
    const { role } = user;

    const inputRef: any = useRef(null);

    const [messageContent, setMessageContent] = useState<string>('');

    const sendMessage = async () => {
        const message = trim(messageContent);
        if (!isEmpty(message)) {
            try {
                await chatService.createChatMessage(chatId, message);
                refetchMessages();
            } catch(e) {}
            setMessageContent('');
        } 
    };

    useEffect(function onMessageToChange() {
        setMessageContent('');
        inputRef?.current?.focus();
    }, [inputRef]);

    return (
        <div className={classes.root}>
            <Typography variant="body2" color="textSecondary" component="h3">
                What's on your mind?
            </Typography>
            <TextField 
                autoFocus
                ref={inputRef}
                multiline
                value={messageContent}
                className={classes.input}
                onChange={(e) => {
                    setMessageContent(e.target.value);
                }}
                /* This is to allow new line break in the comment */
                /* onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        sendMessage();
                    }
                }} */
            />
            <Box display="flex" justifyContent="flex-end">
                <Button 
                    color="primary" 
                    variant="contained" 
                    onClick={sendMessage}
                    className={classes.button}
                    disabled={isEmpty(trim(messageContent)) || role === UserRole.Viewer} 
                >
                    Add comment
                </Button>
            </Box>
        </div>
    );
};

export default NewBugsMessage;