import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import StarRatingComponent from 'react-star-rating-component';
import Sidemenu from './Sidemenu';
import noavatar from '../../../public/images/noavatar.png';
import actions from '../../../redux/actions';

const mapStateToProps = (state) => {
  return {
    customerReviews: state.customerReviews,
    device: state.resolution.device,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listOwnCustomerReviews: () => dispatch(actions.listOwnCustomerReviews()),
  };
};

const Review = (props) => {
  return (
    <div className="customer-review">
      <img className="avatar" src={noavatar} alt="" height="64" width="64" />
      <div className="content">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className="customer-name">{props.ownerCompanyName}</span>
          <br />
          <StarRatingComponent name="rate1" starCount={props.rating} value={props.rating} />
        </div>
        <p className="review">{props.text}</p>
        <p className="project-name">
          <span>{props.cycleTitle}</span>
          <span className="posted-at">Posted on {props.createdAt}</span>
        </p>
      </div>
    </div>
  );
};

class CustomerReviews extends Component<any> {
  componentDidMount() {
    this.props.listOwnCustomerReviews();
  }

  render() {
    const customerReviews = this.props.customerReviews || [];
    const isMobile = this.props.device === 'mobile' || this.props.device === 'mobile-tablet';

    if(isMobile)
    {
      return (
        <div>
          <Sidemenu />
          <div className="customer-reviews" style={{marginLeft: 5}}>
            <h2>Customer reviews</h2>

            {customerReviews.length ? (
              customerReviews.map((el, i) => (
                <Review
                  key={i}
                  rating={el.rating}
                  text={el.text}
                  cycleTitle={el.cycleTitle}
                  ownerCompanyName={el.ownerCompanyName}
                  createdAt={moment(el.createdAt).format('MMM DD YYYY - HH:mm')}
                />
              ))
            ) : (
              <p>No reviews so far</p>
            )}
          </div>        
      </div>
      );
    }
    return (
      <div className="account-tester page">
        <div className="heading">
          <h1>Reviews</h1>
        </div>

        <div className="inner">
          <Sidemenu />
          <main className="customer-reviews">
            <h2>Customer reviews</h2>

            {customerReviews.length ? (
              customerReviews.map((el, i) => (
                <Review
                  key={i}
                  rating={el.rating}
                  text={el.text}
                  cycleTitle={el.cycleTitle}
                  ownerCompanyName={el.ownerCompanyName}
                  createdAt={moment(el.createdAt).format('MMM DD YYYY - HH:mm')}
                />
              ))
            ) : (
              <p>No reviews so far</p>
            )}
          </main>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerReviews));
