import {
    Box, Paper
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Route, useParams } from 'react-router-dom';
import { RootState } from '../../redux/store';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Redirect, Switch, useHistory } from 'react-router';
import wizardActions from '../../redux/actions/wizardActions';
import { NewWizardCycle } from '../../types/NewWizardCycle';
import {
    DeliveryTimeCategory,
    PayoutRatesAndTestersQuota,
    QuestionTimeCategory
} from '../../types/WizardCategoriesTypes';
import { WorkCategory } from '../../types/WorkCategory';

import { Dimmer } from 'semantic-ui-react';
import cycleActions from '../../redux/actions/cycleActions';
import { baseObject, PlatformBaseObject } from '../../types/NewPlatformModel';
import { OperationStatus } from '../../types/OperationStatus';
import { UserRole } from '../../types/UserRole';
import MainStepper from './MainStepper';
import StepFourForm from './StepFourForm';
import StepOneForm from './StepOneForm';
import StepThreeForm from './StepThreeForm';
import StepTwoForm from './StepTwoForm';
import TestPropsPanel from './TestPropsPanel';

const useStyles = makeStyles((theme: Theme) => ({
    mainBox: {
        justifyContent: 'center',
        margin: 'auto',
        maxWidth: 1380,
        minWidth: 700,
        minHeight: 1024,
    },
    HeaderMenu: {
        position: 'absolute',
        left: '0px',
        width: '100%',
        height: '12%',
        borderBottom: '1px solid #DEEEFF',
        paddingLeft: '50px',
        paddingRight: '50px',
    },
    innerLeft: {
        position: 'absolute',
        bottom: '0px',
        left: '0px',
        borderRight: '1px solid #DEEEFF',
        width: "22%",
        height: "88%",
    },
    innerRight: {
        position: 'absolute',
        bottom: '0px',
        right: '0px',
        width: '78%',
        height: "88%",
    },
}));

const Pricing = (props) => {

    let pricingType = useParams<{ pricingType: string }>().pricingType;
    let reload = useParams<{ reload: string }>().reload;

    const { user, cyclesSlim, newWizardCycle, cycleCreateStatus, systemPlatforms, platformRestructionStatus } = useSelector((state: RootState) => state);

    const [activeStep, setActiveStep] = useState(0);
    const [maxSteps, setMaxSteps] = useState(3);

    const dispatch = useDispatch();

    const history = useHistory();

    const classes = useStyles();

    //Run only once for init plan
    useEffect(() => {
        dispatch(wizardActions.restructureSystemPlatforms());
    }, []);

    useEffect(() => {
        if (platformRestructionStatus.status === OperationStatus.success) {
            initialParse();
        }
    }, [platformRestructionStatus.status]);

    useEffect(() => {
        if (cycleCreateStatus.status == OperationStatus.success) {
            dispatch(cycleActions.listCycles4CustomerSlim())
            history.push(`/account/customer/cycle/${cycleCreateStatus.cycleId}`);
        }
        if (cycleCreateStatus.status == OperationStatus.fail) {
            history.push('/cycle-creation-result?status=fail');
        }
    }, [cycleCreateStatus.status]);

    if (!['speedtest', 'popular', 'firstcycle'].includes(pricingType)) {
        return (
            <Switch>
                <Route>
                    <Redirect to={`/pricing/speedtest`} />
                </Route>
            </Switch>
        );
    }

    const innerFormsCollection = [
        StepOneForm,
        StepTwoForm,
        StepThreeForm,
        StepFourForm,
    ];
    const SpecificInnerForm = innerFormsCollection[activeStep];

    const initialParse = () => {
        //Got here after log-in, restore cached data (in previous version when cycle wizard could be started from annonymous user)
        if (reload === 'reload') {
            const newCycleFromLocalStorage = localStorage.getItem('newCycle');
            if (newCycleFromLocalStorage) {
                try {
                    const newCycle = JSON.parse(newCycleFromLocalStorage);

                    //Success retrieving cached data
                    setActiveStep(2);
                    dispatch(wizardActions.updateCycle(newCycle));
                    return;

                } catch (e) {
                    console.error('Problem with parsing json ' + e);
                    localStorage.removeItem('newCycle');
                }
            }
        }

        //Logically else, continue with initial settings
        const newCycle = new NewWizardCycle();
        const firstPlatform: baseObject = (({ id, name }) => ({ id, name }))(systemPlatforms[0]);
        const firstPlatformFull: PlatformBaseObject = { id: firstPlatform.id, name: firstPlatform.name, minOSVersion: { id: -1, name: '' }, maxOSVersion: { id: -1, name: '' } };

        //Take first platform as default
        newCycle.mainTestPlatform = firstPlatformFull;
        if ('speedtest' == pricingType) {
            newCycle.hoursPerTester = 1;

            newCycle.deliveryTime = DeliveryTimeCategory._24hours;
            newCycle.questionsTime = QuestionTimeCategory._24hours;

            newCycle.isWorldwide = true;
            newCycle.isSpecificDevices = false;
            newCycle.numberOfTesters = PayoutRatesAndTestersQuota.minTestersPerCycle;
            newCycle.workCategory = WorkCategory.FindBugsExploratory;
        }
        if ('popular' == pricingType) {
            newCycle.hoursPerTester = 3;

            newCycle.deliveryTime = DeliveryTimeCategory._3days;
            newCycle.questionsTime = QuestionTimeCategory._2days;

            newCycle.isWorldwide = true;
            newCycle.isSpecificDevices = false;
            newCycle.numberOfTesters = PayoutRatesAndTestersQuota.minTestersPerCycle;
            newCycle.workCategory = WorkCategory.FindBugsTestPlan;
        }
        if ('firstcycle' == pricingType) {
            newCycle.hoursPerTester = 1;

            newCycle.deliveryTime = DeliveryTimeCategory._24hours;
            newCycle.questionsTime = QuestionTimeCategory._24hours;

            newCycle.isWorldwide = true;
            newCycle.isSpecificDevices = false;
            newCycle.numberOfTesters = PayoutRatesAndTestersQuota.minTestersPerSpecification;
            newCycle.workCategory = WorkCategory.FindBugsExploratory;
        }

        dispatch(wizardActions.updateCycle(newCycle));
    }

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    }

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    }

    return (
        <Dimmer.Dimmable blurring dimmed={(user.role === UserRole.Tester) ||
            ((pricingType == 'firstcycle') && (user.role === UserRole.Customer) && (cyclesSlim.length !== 0))}>
            <Dimmer active={user.role === UserRole.Tester}>
                <p>Please log in or register as a customer to create Cycles</p>
            </Dimmer>
            <Dimmer active={(pricingType == 'firstcycle') && (user.role === UserRole.Customer) && (cyclesSlim.length !== 0)}>
                <p>You already used your free cycle</p>
            </Dimmer>
            <div className={classes.mainBox}>
                <Box className={classes.HeaderMenu}>
                    <MainStepper activeStep={activeStep} pricingType={pricingType}></MainStepper>
                </Box>

                <Box className={classes.innerLeft}>
                    <TestPropsPanel
                        newCycle={newWizardCycle}
                        pricingType={pricingType}
                        activeStep={activeStep}
                    />
                </Box>

                <Box className={classes.innerRight}>
                    <Box sx={{ /* maxWidth: 400, */ flexGrow: 1 }}>
                        <Paper
                            square
                            elevation={0}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                /* height: 50, */
                                pl: 2,
                                bgcolor: 'background.default',
                            }}
                        >
                        </Paper>
                        <Box sx={{ /* height: 255, maxWidth: 400, */minHeight: '400px', width: '100%', p: 2 }}>
                            <SpecificInnerForm
                                pricingType={pricingType}
                                next={handleNext}
                                back={handleBack}
                            ></SpecificInnerForm>
                        </Box>

                        {/* <Box sx={{ width: '75%' }}>
                            <MobileStepper
                                variant="text"
                                steps={maxSteps}
                                position="static"
                                activeStep={activeStep}
                                nextButton={
                                    <Button
                                        size="large"
                                        onClick={handleNext}
                                        disabled={activeStep === maxSteps - 1}
                                    >
                                        Next
                                        <KeyboardArrowRight />
                                    </Button>
                                }
                                backButton={
                                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                        <KeyboardArrowLeft />
                                        Back
                                    </Button>
                                }
                            />
                        </Box> */}

                    </Box>
                </Box>
            </div>
        </Dimmer.Dimmable >

    );
};

export default Pricing;
