import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { UserRole } from '../types/UserRole';

const mapStateToProps = (state) => ({
  user: state.user,
});

class Account extends Component<any> {
  render() {
    const { user } = this.props;
    switch (user.role) {
      case UserRole.Tester:
        return <Redirect to="/account/tester" />;
      case UserRole.Customer:
        return <Redirect to="/account/customer" />;
      case UserRole.Viewer:
        return <Redirect to="/account/customer" />;
      default:
        return <Redirect to="/" />;
    }
  }
}

export default connect(mapStateToProps)(Account);
