import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Checkbox, Icon, Input, Message, Popup, Select, TextArea } from 'semantic-ui-react';
import config from '../../../../config';
import noavatar from '../../../../public/images/noavatar.png';
import actions from '../../../../redux/actions';
import { Gender } from '../../../../types/Gender';
import { OperationStatus } from '../../../../types/OperationStatus';
import genderOptions from '../../../../util/options/genders';
import languages from '../../../../util/options/languages';
import userSourceOptions from '../../../../util/options/userSources';
import { searchFromStart } from '../../../../util/utils';

const genderOptionsNoAny = genderOptions.filter((el) => el.key !== Gender.Any);

const mapStateToProps = (state) => {
  return {
    userUploadAvatarStatus: state.userUploadAvatarStatus,
    userSaveStatus: state.userSaveStatus,
    passwordChangeStatus: state.passwordChangeStatus,
    user: state.user,
    devices: state.devices,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (data) => dispatch(actions.updateUser(data)),
    saveUser: (data) => dispatch(actions.saveUser(data)),
    uploadAvatar: (avatarFile) => dispatch(actions.uploadAvatar(avatarFile)),
  };
};

class Personal extends Component<any> {
  public state: any;

  constructor(props) {
    super(props);

    this.state = {
      newAvatar: {},
      newAvatarError: '',
      errors: new Set(),
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.userSaveStatus.status !== prevProps.userSaveStatus.status &&
      this.props.userSaveStatus.status === OperationStatus.success
    ) {
      this.props.history.push('/complete-profile/phone');
    }
  }

  resetErrorState = (event) => {
    const name = event.target.name;
    const errors = new Set(this.state.errors);

    if (errors.has(name)) {
      errors.delete(name);
      this.setState({ errors });
    }
  };

  onChange = (event, data) => {
    this.resetErrorState(event);
    const user = { ...this.props.user };

    switch (data.name) {
      case 'age': {
        data.value = data.value.replace(/^[0]+/g, '');
        user[data.name] = data.value;
        break;
      }
      case 'sendInvitations': {
        user[data.name] = data.checked;
        break;
      }
      default: {
        user[data.name] = data.value;
        break;
      }
    }

    return this.props.updateUser(user);
  };

  validateInput = (event, data) => {
    const errors = new Set(this.state.errors);
    const name = data && data.name ? data.name : event.target.name;
    const value = data && data.value ? data.value : event.target.value;

    if (name === 'age') {
      if (!value.length || parseInt(value) < 0 || !Number(value) || parseInt(value) > 120) {
        errors.add(name);
      } else {
        errors.delete(name);
      }

      this.setState({ errors });
      return;
    }

    if (name === 'source') {
      if (!value) {
        errors.add(name);
      } else {
        errors.delete(name);
      }

      this.setState({ errors });
      return;
    }

    if (!value || !value.length) {
      errors.add(name);
    } else {
      errors.delete(name);
    }

    this.setState({ errors });
  };

  onAvatarUpload = ([acceptedImageFile]) => {
    const newAvatar = Object.assign(acceptedImageFile, {
      preview: URL.createObjectURL(acceptedImageFile),
    });

    this.setState({
      newAvatar,
      newAvatarError: '',
    });
  };

  onAvatarRejected = () => {
    this.setState({ newAvatarError: 'Avatar size can be no more than 1 Mb' });
  };

  onSave = async () => {
    const userData = { ...this.props.user };
    const errors = new Set();

    ['firstName', 'lastName', 'gender', 'age', 'nativeLanguage', 'source'].forEach(
      (el) => {
        if (!userData[el]) {
          errors.add(el);
        }

        if (el === 'age') {
          if (userData[el] < 16 || userData[el] > 120) {
            errors.add(el);
          }
        }
      }
    );

    if (errors.size) {
      return this.setState({ errors });
    }

    if (this.state.newAvatar && Object.keys(this.state.newAvatar).length) {
      await this.props.uploadAvatar(this.state.newAvatar);
    }

    this.props.saveUser(userData);
  };

  render() {
    const userSaveProcessing = this.props.userSaveStatus.status === OperationStatus.processing || this.props.userUploadAvatarStatus.status === OperationStatus.processing;
    const user = this.props.user;
    const errors = this.state.errors;

    let avatarUrl;

    if (this.state.newAvatar.preview) {
      avatarUrl = this.state.newAvatar.preview;
    } else if (this.props.user.avatarUrl) {
      const href = new URL(this.props.user.avatarUrl, config.apiEndpoint).href;
      avatarUrl = href;
    } else {
      avatarUrl = noavatar;
    }

    return (
      <div className="step-personal" style={{ marginLeft: 5, marginRight: 5 }}>
        <Message info icon>
          <Icon name="address card outline" />
          <Message.Content>
            <Message.Header>Welcome!</Message.Header>
            <p>Please fill the information below to complete your registration</p>
          </Message.Content>
        </Message>
        <table className="personal-information">
          <tbody>
            <tr>
              <td>Photo</td>
              <td className="avatar-cell">
                <img className="avatar" src={avatarUrl} alt="avatar" height="128" width="128" />
                {this.state.newAvatarError && (
                  <Message negative compact size="mini">
                    {this.state.newAvatarError}
                  </Message>
                )}
                <Dropzone
                  multiple={false}
                  maxSize={1000000}
                  accept="image/jpeg, image/png"
                  onDropAccepted={this.onAvatarUpload}
                  onDropRejected={this.onAvatarRejected}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Popup
                        trigger={
                          <Button
                            icon="question"
                            content="Change"
                            labelPosition="right"
                            primary
                            size="tiny"
                            style={{ width: '128px' }}
                          />
                        }
                        content="A good profile image makes a client feel more secure"
                        on="hover"
                        position="top left"
                      />
                    </div>
                  )}
                </Dropzone>
              </td>
            </tr>
            <tr>
              <td>First name</td>
              <td>
                <Input
                  placeholder="First name"
                  name="firstName"
                  value={user.firstName}
                  onChange={this.onChange}
                  onFocus={this.resetErrorState}
                  onBlur={this.validateInput}
                  error={errors.has('firstName')}
                />
              </td>
            </tr>
            <tr>
              <td>Last name</td>
              <td>
                <Input
                  placeholder="Last name"
                  name="lastName"
                  value={user.lastName}
                  onChange={this.onChange}
                  onFocus={this.resetErrorState}
                  onBlur={this.validateInput}
                  error={errors.has('lastName')}
                />
              </td>
            </tr>
            <tr>
              <td>Gender</td>
              <td>
                <Select
                  placeholder="Please choose"
                  name="gender"
                  value={user.gender}
                  options={genderOptionsNoAny}
                  onChange={this.onChange}
                  onFocus={this.resetErrorState}
                  onBlur={this.validateInput}
                  error={errors.has('gender')}
                />
              </td>
            </tr>
            <tr>
              <td>Age</td>
              <td>
                <Input
                  type="number"
                  min="16"
                  placeholder="Age"
                  name="age"
                  value={user.age || ''}
                  onChange={this.onChange}
                  onFocus={this.resetErrorState}
                  onBlur={this.validateInput}
                  error={errors.has('age')}
                />
              </td>
            </tr>
            <tr>
              <td>Native language</td>
              <td>
                <Select
                  placeholder="Native language"
                  name="nativeLanguage"
                  value={user.nativeLanguage}
                  options={languages}
                  onChange={this.onChange}
                  onFocus={this.resetErrorState}
                  onBlur={this.validateInput}
                  error={errors.has('nativeLanguage')}
                  selectOnBlur={false}
                  search={searchFromStart}
                />
              </td>
            </tr>
            <tr>
              <td>Other language</td>
              <td>
                <Select
                  placeholder="Other language"
                  name="otherLanguage"
                  value={user.otherLanguage}
                  options={languages}
                  onChange={this.onChange}
                  selectOnBlur={false}
                  search={searchFromStart}
                />
              </td>
            </tr>
            <tr>
              <td>About yourself</td>
              <td>
                <TextArea
                  className={errors.has('about') ? 'error' : ''}
                  name="about"
                  value={user.about}
                  onChange={this.onChange}
                  onFocus={this.resetErrorState}
                  onBlur={this.validateInput}
                />
              </td>
            </tr>
            <tr>
              <td>How did you reach us?</td>
              <td>
                <Select
                  selection
                  placeholder="Please choose"
                  name="source"
                  value={user.source}
                  options={userSourceOptions}
                  onChange={this.onChange}
                  onFocus={this.resetErrorState}
                  onBlur={this.validateInput}
                  error={errors.has('source')}
                  selectOnBlur={false}
                />
              </td>
            </tr>
            <tr>
              <td>Send me testing invitations?</td>
              <td>
                <Checkbox
                  label="Yes"
                  name="sendInvitations"
                  checked={user.sendInvitations}
                  onChange={this.onChange}
                  toggle
                />
              </td>
            </tr>
          </tbody>
        </table>

        <Button
          className="save-and-next"
          primary
          icon
          size="big"
          labelPosition="right"
          onClick={this.onSave}
          loading={userSaveProcessing}
          disabled={userSaveProcessing || errors.size}
        >
          Save &amp; next
          <Icon name="arrow right" />
        </Button>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Personal));
