import React from 'react';
import { Table } from 'semantic-ui-react';

const TestPlanTableRow = ({ testPlanStep, index }) => {
  return (
    <Table.Row verticalAlign="top">
      <Table.Cell collapsing> {index} </Table.Cell>
      <Table.Cell>
        <p>{testPlanStep.description}</p>
        <p>
          <strong>Expected result:</strong>
          <br />
          {testPlanStep.expectedResult}
        </p>
      </Table.Cell>
    </Table.Row>
  );
};

export const TestPlanTableViewOnly = ({ testPlan }) => {
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell>Step description</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {testPlan.map((testPlanStep, i) => {
          return <TestPlanTableRow index={++i} key={i} testPlanStep={testPlanStep} />;
        })}
      </Table.Body>
    </Table>
  );
};
