import keys from 'lodash/keys';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';

export const getTopCycleMessages = (cycleTesters, chatMessages, cycleId: string): object[] => {
    
    const messagesByTesterId = chatMessages?.byCycleId[cycleId];
    const testersAll = cycleTesters?.byCycleId[cycleId];
    const testersWithMsgs = keys(messagesByTesterId);

    const testersNonMsgs = testersAll.filter( tester => !testersWithMsgs.includes( tester.id.toString() ) )
                                    .map( (testerObj :any) => ({
                                        testerId: +testerObj.id || {},
                                        senderId: -1
                                    }));

    return testersWithMsgs
        .map((testerId) => ({
            testerId,
            topMessage: first(messagesByTesterId[testerId].sort((a, b) => 
                (a?.createdAt < b?.createdAt) ? 1 : ((a?.createdAt > b?.createdAt) ? -1 : 0)
            )),
        }))
        .filter((topMessageMap: any) => !isEmpty(topMessageMap.topMessage))
        .map((topMessageMap: any) => ({
            ...(topMessageMap.topMessage || {}),
            testerId: +topMessageMap.testerId,
        }))
        .sort((a: any, b: any) => 
            (a?.createdAt < b?.createdAt) ? 1 : ((a?.createdAt > b?.createdAt) ? -1 : 0)
        )
        .concat( testersNonMsgs );
};