import { Grid, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import uniq from 'lodash/uniq';
import moment from 'moment';
import React, { useState } from 'react';
import { Table } from 'semantic-ui-react';
import { getPlatformPrettyName } from '../../../../services/deviceService';
import { PayoutRatesAndTestersQuota } from '../../../../types/WizardCategoriesTypes';
import { WorkCategory } from '../../../../types/WorkCategory';

const tesetTypeMap = {
    findBugsExploratory: 'Exploratory',
    findBugsTestPlan: 'Test plan ' + `(+$${PayoutRatesAndTestersQuota.testPlanExtra})`,
    feedbackAndRating: 'Feedback & rating'
};

const retrieveExtraPayoutPerHours = (hoursPerTester) => {
    switch (hoursPerTester) {
        case 2: { return PayoutRatesAndTestersQuota._2hrsTestingExtra; }
        case 3: { return PayoutRatesAndTestersQuota._3hrsTestingExtra; }
        case 4: { return PayoutRatesAndTestersQuota._4hrsTestingExtra; }
    }
    return 0;
}

const retrieveExtraPayoutPerQtime = (questionsTime) => {
    switch (questionsTime) {
        case 2: { return PayoutRatesAndTestersQuota._2daysQtimeExtra; }
        case 4: { return PayoutRatesAndTestersQuota._4daysQtimeExtra; }
    }
    return 0;
}


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginTop: 30,
    },
    filterButtosContainer: {
        marginTop: 30,
        marginBottom: 50,
    },
    filterButton: {
        border: 'none',
        marginRight: 15,
        boxShadow: 'none',
        padding: '2px 20px',
        transition: 'all 0.4s',
        '&:hover': {
            color: '#ffffff',
            boxShadow: 'none',
        },
    },
    filterButtonInactive: {
        backgroundColor: '#ffffff',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
    },
    container: {
        marginLeft: 20,
    },
    urlContainer: {
        marginTop: 25,
    },
    borderedContainer: {
        marginTop: 50,
        paddingLeft: 10,
        marginLeft: 0,
        paddingBottom: 20,
        borderLeft: `2px solid ${theme.palette.primary.main}`,
    },
    subtitle: {
        marginTop: 30,
        marginLeft: 15,
    },
    limitedContainer: {
        maxWidth: 530,
    },
    limitedContainerTestPlan: {
        maxWidth: 800,
    },
    smSpaceBottom: {
        marginBottom: 20,
    },
    mdSpaceBottom: {
        marginBottom: 35,
    },

    AttributeName: {
        fontSize: '14px',
        color: theme.palette.common.black,
        fontWeight: 'normal',
        letterSpacing: '0.1px',
    },

    AttributeNameBold: {
        fontSize: '14px',
        color: theme.palette.common.black,
        fontWeight: 'bold',
        letterSpacing: '0.1px',
    },

}));

type InfoFilterType = 'brief' | 'settings' | 'devices_testers' | 'test plan';

const filterButtons: { label: string; id: InfoFilterType }[] = [
    { label: 'Brief', id: 'brief' },
    { label: 'Settings', id: 'settings' },
    { label: 'Devices & Testers', id: 'devices_testers' },
    { label: 'Test plan', id: 'test plan' },
];

export const BriefTestInfo: React.FC<{ cycle: any }> = ({ cycle }) => {
    const classes = useStyles();

    //const projectUrl: any = first((cycle?.platformUrls || []).map(({ url }) => url));

    return (
        <div>
            <div className={classes.container}>
                <Typography variant="body2">Test name</Typography>
                <Typography variant="body2" color="textSecondary" className={classes.subtitle}>
                    {cycle?.title}
                </Typography>
            </div>
            <div className={classes.borderedContainer}>
                <Typography variant="body2">Describe your test</Typography>
                <Typography variant="body2" color="textSecondary" className={classes.subtitle} style={{ whiteSpace: 'pre-wrap' }}>
                    {cycle?.description}
                </Typography>
            </div>
            {cycle?.platformUrls.length > 0 &&
                <div className={classes.container} style={{ marginTop: 80 }}>
                    <Typography variant="body2">Project URLs</Typography>

                    <Grid container className={classes.urlContainer} spacing={2}>
                        {
                            cycle?.platformUrls.map((el) => (
                                <Grid container item direction="row" key={el.platformId}>
                                    <Typography variant="body2" color="textSecondary" className={classes.subtitle} style={{ width: '150px' }}>
                                        {getPlatformPrettyName(el.platformId)}
                                    </Typography>
                                    <span className="url">
                                        <a target="_blank" href={el.url} rel="noopener noreferrer">
                                            {el.url}
                                        </a>
                                    </span>
                                </Grid>
                            ))
                        }
                    </Grid>
                </div>
            }
        </div>
    );
};

export const SettingsTestInfo: React.FC<{ cycle: any }> = ({ cycle }) => {

    const combineDurationTxt = (cycle) => {
        let txt = `${cycle?.hoursPerTester}` + (cycle?.hoursPerTester > 1 ? ' hours' : ' hour') + ' duration';

        let hoursDurationExtra = retrieveExtraPayoutPerHours(cycle.hoursPerTester);
        if (hoursDurationExtra) {
            txt += ` (+$${hoursDurationExtra})`;
        }

        return txt;
    }

    const combinePeriodTxt = (cycle) => {

        let deliveryTime = Math.round(moment.duration(moment(cycle?.endDate).diff(cycle?.startDate)).asDays());
        let questionsTime = Math.round(moment.duration(moment(cycle?.questionsTime).diff(cycle?.endDate)).asDays());
        let txt = deliveryTime + ' days overall time ' + (questionsTime > 0 ? `, ${questionsTime} days Service time` : '');
        let qTimeExtra = retrieveExtraPayoutPerQtime(questionsTime);
        if (qTimeExtra) {
            txt += ` (+$${qTimeExtra})`;
        }

        return txt;
    }

    const classes = useStyles();
    const testDuration = moment.duration(moment(cycle?.endDate).diff(cycle?.startDate)).asHours();
    const countries = cycle?.countries?.includes('All') ? ['Worldwide'] : cycle?.countries;

    const durationTxt = combineDurationTxt(cycle);
    const periodTxt = combinePeriodTxt(cycle);

    const countriesTxt = cycle?.countries?.includes('All') ?
        (<Typography variant="body2" color="textSecondary">Worldwide</Typography>) :
        cycle.countries.map(c => <Typography variant="body2" color="textSecondary">{c}</Typography>);

    const numberOfTesters = cycle?.testEnvs.reduce((result, el) => {
        result += (el?.requiredTesters || 0);
        return result;
    }, 0);

    const browsers = cycle?.testEnvs.reduce((result, el) => {
        result += (el?.specification === 'Website' ? 1 : 0);
        return result;
    }, 0);

    return (
        <div className={classes.limitedContainer}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography className={classes.AttributeName}>Test type</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2" color="textSecondary">{tesetTypeMap[cycle?.workCategory]}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography className={classes.AttributeName}>Test duration</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2" color="textSecondary">{durationTxt}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography className={classes.AttributeName}>Test period</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2" color="textSecondary">{periodTxt}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography className={classes.AttributeName}>Test locations</Typography>
                </Grid>
                <Grid item xs={6}>
                    {countriesTxt}
                </Grid>
            </Grid>

            <div className={classes.borderedContainer}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography className={classes.AttributeName}>Test specification</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2" color="textSecondary">{browsers ? 'Website' : 'Native app'}</Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography className={classes.AttributeName}>Number of testers</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2" color="textSecondary">{numberOfTesters}</Typography>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export const DevicesTestersTestInfo: React.FC<{ cycle: any }> = ({ cycle }) => {
    const classes = useStyles();

    const platforms = uniq((cycle?.testEnvs || []).map(({ platformId }) => getPlatformPrettyName(platformId)));
    const isWorldwide = cycle?.countries?.includes('All');

    const hourlyRatePerTesterTxt = '$' + cycle.hourlyRate;
    const numberOfTesters = cycle.testEnvs.reduce((result, el) => {
        result += el.requiredTesters;
        return result;
    }, 0);

    const totalPrice = `$${cycle.totalPrice}`;

    return (
        <div className={classes.limitedContainer}>
            <Grid container spacing={2} className={classes.mdSpaceBottom}>
                <Grid item xs={6}>
                    <Typography className={classes.AttributeName}>Platforms</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2" color="textSecondary">{platforms?.join(', ')}</Typography>
                </Grid>
            </Grid>

            <Grid container spacing={2} style={{ paddingLeft: 15 }}>
                <Grid item xs={4}>
                    <Typography variant="caption" color="primary">Platform\Device</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="caption" color="primary">Country</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="caption" color="primary">Number of testers</Typography>
                </Grid>
            </Grid>

            <div className={classes.borderedContainer} style={{ marginTop: 15, marginBottom: 35, paddingBottom: 5 }}>
                <Grid container spacing={2}>
                    {(cycle?.testEnvs || []).map((testEnv, i) => (
                        <React.Fragment key={`${testEnv?.platformId}-${i}`}>
                            <Grid item xs={4}>
                                <Typography variant="body2" className={classes.smSpaceBottom}>
                                    {testEnv?.type == 'Platform' ? getPlatformPrettyName(testEnv?.platformId) : testEnv?.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body2" className={classes.smSpaceBottom}>
                                    {testEnv?.country == 'All' ? 'Worldwide' : testEnv?.country}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} alignItems='center'>
                                <Typography variant="body2" className={classes.smSpaceBottom}>
                                    {testEnv?.requiredTesters}
                                </Typography>
                            </Grid>
                        </React.Fragment>
                    ))}
                </Grid>
            </div>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography className={classes.AttributeNameBold}>Total amount of testers</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2" color="textSecondary">{numberOfTesters} testers</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography className={classes.AttributeNameBold}>Tester hourly rate</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2" color="textSecondary">{hourlyRatePerTesterTxt}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography className={classes.AttributeNameBold}>{cycle?.vatPercent > 0 ? `Total price including VAT(${cycle?.vatPercent}%)` : 'Total price'}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2" color="textSecondary" style={{ textDecoration: 'underline' }}>{totalPrice}</Typography>
                </Grid>
            </Grid>
        </div>
    );
};

export const TestPlanTestInfo: React.FC<{ cycle: any }> = ({ cycle }) => {
    const classes = useStyles();
    const testPlan = (cycle?.testPlan || []);

    return (
        <div className={classes.limitedContainerTestPlan}>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell><Typography variant="body2" color="primary">No.</Typography></Table.HeaderCell>
                        <Table.HeaderCell><Typography variant="body2" color="primary">Description</Typography></Table.HeaderCell>
                        <Table.HeaderCell><Typography variant="body2" color="primary">Expected result</Typography></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {testPlan.map((el, i) => (
                        <Table.Row key={i} verticalAlign="top">
                            <Table.Cell collapsing> {++i} </Table.Cell>
                            <Table.Cell style={{ "word-break": "break-all" }} >{el.description}</Table.Cell>
                            <Table.Cell>{el.expectedResult}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            {/* <Grid container spacing={2} style={{paddingLeft: 15}}>
                <Grid item xs={2}>
                    <Typography variant="caption" color="primary">Step number</Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="caption" color="primary">Description</Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="caption" color="primary">Expected result</Typography>
                </Grid>

                {(cycle?.testPlan || []).map((step, i) => (
                    <React.Fragment key={`${step?.id}`}>
                        <Grid item xs={2}>
                            <Typography variant="body2" className={classes.smSpaceBottom}>
                                {step.publicId}
                            </Typography>
                        </Grid>
                            <Grid item xs={5}>
                            <Typography variant="body2" className={classes.smSpaceBottom}>
                                {step.description}
                            </Typography>
                        </Grid>
                        <Grid item xs={5} alignItems='center'>
                            <Typography variant="body2" className={classes.smSpaceBottom}>
                                {step.expectedResult}
                            </Typography>
                        </Grid>
                    </React.Fragment>
                ))}

            </Grid> */}
        </div>
    );
};

const CycleTestInfo: React.FC<{ cycle: any }> = ({ cycle }) => {
    const classes = useStyles();

    const [filter, setFilter] = useState<InfoFilterType>('brief');

    let buttons = filterButtons;
    if (cycle?.workCategory != WorkCategory.FindBugsTestPlan) {
        buttons = filterButtons.filter((btn) => btn.id != 'test plan')
    }

    return (
        <div className={classes.root}>
            <Typography variant="h6" component="h2">Test Info</Typography>

            <Box display="flex" className={classes.filterButtosContainer}>
                {buttons.map(({ label, id }) => (
                    <Button
                        key={label}
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => setFilter(id)}
                        className={classNames(classes.filterButton, {
                            [classes.filterButtonInactive]: id !== filter,
                        })}
                    >
                        {label}
                    </Button>
                ))}
            </Box>

            {filter === 'brief' && (
                <BriefTestInfo cycle={cycle} />
            )}
            {filter === 'settings' && (
                <SettingsTestInfo cycle={cycle} />
            )}
            {filter === 'devices_testers' && (
                <DevicesTestersTestInfo cycle={cycle} />
            )}
            {filter === 'test plan' && (
                <TestPlanTestInfo cycle={cycle} />
            )}
        </div>
    );
};

export default CycleTestInfo;
