import React, { Component } from 'react';
import {
    Button,
    Box,
    Typography,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Chip,
    IconButton,
    Link,
} from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import wizardActions from '../../../redux/actions/wizardActions';
import { NewWizardCycle } from '../../../types/NewWizardCycle';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Dropzone from 'react-dropzone';
import excel2json from 'js2excel';
import { makeStyles } from '@mui/styles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LabeledSwitcher from './LabeledSwitcher';
import SharedStyle, { titleBlue } from './SharedStyle';
import { Message } from 'semantic-ui-react';
import { Tooltip } from '@mui/material';
import config from '../../../config';
import * as _ from 'lodash';


const styles = (theme) => createStyles({
    checker: {
        display: 'flex',
        marginTop: '20px',
    },
});

const StyledTableRow = withStyles((theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: "#32448308"
            },
        },
    }),
)(TableRow);

const StyledTableCell = withStyles((theme) =>
    createStyles({
        root: {
            borderBottom: "none"
        },
        head: {
            backgroundColor: theme.palette.common.white,
            color: titleBlue,
        },
    }),
)(TableCell);


const mapStateToProps = (store) => {
    return {
        newCycle: store.newWizardCycle as NewWizardCycle,
        emptyTestPlanError: store.newCycleCurrentStepErrors,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        updateCycle: (data) => dispatch(wizardActions.updateCycle(data)),
    };
};

const useStyles = makeStyles((theme) =>
    createStyles({
        noBorder: {
            border: "none",
            '&::placeholder': {
                fontSize: '14px',
            },
        },
        errorBorder: {
            '&::placeholder': {
                fontSize: '14px',
            },
        },
        customChipIndex: {
            minWidth: "32px",
            '& .MuiChip-root': {
                display: "flex",
            },
            '& .MuiChip-label': {
                fontFamily: "Poppins",
                fontSize: '14px',
            },
        },
        textInputPlaceHolder: {
            width: "100%",
            fontSize: "14px",
            '& .MuiOutlinedInput-multiline': {
                paddingLeft: '0px',
            }
        },
    }),
);

const PlanStepTableRow = (props) => {
    const classes = useStyles();
    return (
        <StyledTableRow>
            <StyledTableCell>
                <Chip label={props.index + 1} className={classes.customChipIndex} color="primary" />
            </StyledTableCell>
            <StyledTableCell>
                {props.stepDescription}
            </StyledTableCell>
            <StyledTableCell>
                {props.expectedResult}
            </StyledTableCell>
            <StyledTableCell>
                <IconButton onClick={() => props.onDeletePlanStep(props.index)} size="large">
                    <DeleteOutlineIcon />
                </IconButton>
            </StyledTableCell>
        </StyledTableRow>
    );
};

const EditableTableRow = (props) => {
    const classes = useStyles();

    return (
        <StyledTableRow>
            <StyledTableCell>
                <Chip label={props.index + 1} className={classes.customChipIndex} color="primary" />
            </StyledTableCell>
            <StyledTableCell>
                <TextField className={classes.textInputPlaceHolder}
                    autoComplete='off'
                    multiline rows={2}
                    variant="outlined"
                    placeholder="Write here the description"
                    name="stepDescription"
                    value={props.stepDescriptionValue}
                    onChange={e => { props.onChange(e.target); props.resetErrorStateForInput(e.target); }}
                    error={props.errors.includes('stepDescription')}
                    InputProps={props.errors.includes('stepDescription') ?
                        { classes: { input: classes.errorBorder, notchedOutline: classes.errorBorder } }
                        : { classes: { input: classes.noBorder, notchedOutline: classes.noBorder } }}
                />

            </StyledTableCell>
            <StyledTableCell>
                <TextField className={classes.textInputPlaceHolder}
                    autoComplete='off'
                    multiline rows={2}
                    variant="outlined"
                    placeholder="Write your expected result"
                    name="expectedResult"
                    value={props.expectedResultValue}
                    onChange={e => { props.onChange(e.target); props.resetErrorStateForInput(e.target); }}
                    error={props.errors.includes('expectedResult')}
                    InputProps={props.errors.includes('expectedResult') ?
                        { classes: { input: classes.errorBorder, notchedOutline: classes.errorBorder } }
                        : { classes: { input: classes.noBorder, notchedOutline: classes.noBorder } }}
                />
            </StyledTableCell>
            <StyledTableCell>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={props.onAdd}
                    size="small">
                    Add</Button>
            </StyledTableCell>
        </StyledTableRow>
    );
};


class PlanFormSlim extends Component<any> {
    public state: any;

    constructor(props) {
        super(props);

        this.state = {
            stepDescription: '',
            expectedResult: '',
            errors: [],
            uploadExcelError: '',
            jsonFromExcelFile: [],
        };
    }

    componentDidUpdate(prevProps) {

        if (prevProps.emptyTestPlanError !== this.props.emptyTestPlanError) {
            if (this.props.emptyTestPlanError[0].length) {
                this.setState({
                    uploadExcelError: 'Your test plan is empty, please include at least one item',
                })
            }
        }

    }

    handlePromoToggle = (event) => {
        const newCycle: NewWizardCycle = { ...this.props.newCycle };
        newCycle.isWithPromoCode = event.target.checked;

        this.props.updateCycle(newCycle);
    }

    onChangePromoCode = event => {
        const newCycle = { ...this.props.newCycle };
        newCycle[event.name] = event.value;

        this.props.updateCycle(newCycle);
    };

    handleUploadFileToggle = (event) => {
        const newCycle: NewWizardCycle = { ...this.props.newCycle };
        newCycle.isTestPlanFromFile = event.target.checked;

        this.setState({
            uploadExcelError: '',
        });

        this.props.updateCycle(newCycle);
    }

    onDeletePlanStep = (rowIndex) => {
        const newCycle: NewWizardCycle = { ...this.props.newCycle };
        var plans = newCycle.testPlan;

        plans?.splice(rowIndex, 1);
        newCycle.testPlan = plans;

        return this.props.updateCycle(newCycle);

    };

    resetErrorStateForInput = (target) => {
        if (this.state.errors.includes(target.name)) {
            const errors = [...this.state.errors];

            errors.splice(this.state.errors.indexOf(target.name), 1);
            this.setState({ errors });
        }
    }

    validateNonEmptyInput = () => {
        const errors: Set<string> = new Set();

        ['stepDescription', 'expectedResult'].forEach((el) => {
            if (!this.state[el]) {
                errors.add(el);
            }
        });

        return Array.from(errors);
    }


    onAddStepPlan = () => {
        const stepErrors = this.validateNonEmptyInput();
        if (stepErrors.length) {
            this.setState(
                {
                    ...this.state,
                    errors: stepErrors
                }
            )
            return;
        }

        const newCycle = { ...this.props.newCycle };

        newCycle.testPlan.push({
            stepDescription: this.state.stepDescription,
            expectedResult: this.state.expectedResult,
        });

        this.setState(
            {
                stepDescription: '',
                expectedResult: '',
                uploadExcelError: '',
            },
            () => {
                const newCycleJson = JSON.stringify(newCycle);
                return this.props.updateCycle(newCycle);
            }
        );
    }

    onChangeNewPlanStep = (event) => {
        this.setState({
            [event.name]: event.value,
        });
    };

    onTestPlanAccepted = (arr) => {
        if (!/^[^.]+.xls$/.test(arr[0].name) && !/^[^.]+.xlsx$/.test(arr[0].name)) {
            this.setState({
                uploadExcelError: 'You can upload only files with .xls and .xlsx extension',
            });
            return;
        }
        const newCycle = { ...this.props.newCycle };

        excel2json.excel2json(
            arr,
            (testData) => {

                if (!testData.Sheet1) {
                    this.setState({
                        uploadExcelError: 'Unable to read \'Sheet1\' from your Excel file',
                    });
                    return;
                }
                if (!testData.Sheet1[0]['Step description']) {
                    this.setState({
                        uploadExcelError: 'No \'Step description\' header found',
                    });
                    return;
                }
                if (!testData.Sheet1[0]['Expected result']) {
                    this.setState({
                        uploadExcelError: 'No \'Expected result\' header found',
                    });
                    return;
                }

                //Ready to read, let's reset existing test plan before parsing the file
                newCycle.testPlan = [];

                for (let i = 0; i < testData.Sheet1.length; i++) {
                    const testPlan = {
                        stepDescription: testData.Sheet1[i]['Step description'],
                        expectedResult: testData.Sheet1[i]['Expected result'],
                    };

                    if (testPlan.stepDescription.trim() && testPlan.expectedResult.trim()) {
                        newCycle.testPlan[newCycle.testPlan.length] = testPlan;
                    }
                }
                const newCycleJson = JSON.stringify(newCycle);
                localStorage.setItem('newCycle', newCycleJson);
                this.props.updateCycle(newCycle);
            },
            ''  //Default value
        );
        this.setState({
            uploadExcelError: '',
        });
    };

    render() {
        const { classes } = this.props;
        const newCycle: NewWizardCycle = this.props.newCycle;

        return (
            <Box>

                <Typography variant="h2" style={{ marginTop: "30px" }}>
                    Define your test plan, either manually or upload from a file:
                </Typography>

                <LabeledSwitcher
                    label="Upload test file"
                    isChecked={this.props.newCycle.isTestPlanFromFile}
                    handleChange={this.handleUploadFileToggle}
                />

                <Box style={{ marginTop: "10px" }}>
                    {this.props.newCycle.isTestPlanFromFile &&
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: "80%" }}>
                            <Dropzone multiple={false} onDrop={this.onTestPlanAccepted}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <Tooltip title="Make sure your file is table structured with first 'Step description' and 'Expected result' header columns, or simply download and use our template example file">
                                            <Button
                                                style={{ borderRadius: 5, textTransform: "unset", marginBottom: "30px" }}
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                startIcon={<CloudUploadIcon />}
                                            >Upload from file</Button>
                                        </Tooltip>
                                    </div>
                                )}
                            </Dropzone>

                            {/* <Link href={`${config.apiEndpoint}/test_plan_template.xls`}> */}
                            <Button
                                style={{ borderRadius: 5, textTransform: "unset", marginBottom: "30px" }}
                                /* variant="outlined" */
                                /* component={Link} */
                                target="_blank"
                                color="secondary"
                                size="small"
                                endIcon={<CloudDownloadIcon />}
                                href={`${config.apiEndpoint}/test_plan_template.xls`}
                            >Template example
                            </Button>
                            {/* </Link> */}

                        </div>
                    }

                    {this.state.uploadExcelError.length > 0 &&
                        <Message style={{ width: "95%" }} negative>
                            {this.state.uploadExcelError}
                        </Message>
                    }

                    {true  /*!this.props.newCycle.isTestPlanFromFile */ &&

                        <Box style={{ width: "95%", maxHeight: "350px", overflow: 'auto', }}>
                            <TableContainer style={{ maxHeight: "80%", overflow: 'auto', }}>
                                <Table stickyHeader size="small">
                                    <TableHead>
                                        <StyledTableRow>
                                            <StyledTableCell width="10%">#</StyledTableCell>
                                            <StyledTableCell width="40%">Step description</StyledTableCell>
                                            <StyledTableCell width="40%">Expected result</StyledTableCell>
                                            <StyledTableCell width="10%"></StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.props.newCycle.testPlan.map((el, i) =>
                                        (
                                            <PlanStepTableRow
                                                key={i}
                                                index={i}
                                                stepDescription={el.stepDescription}
                                                expectedResult={el.expectedResult}
                                                onDeletePlanStep={this.onDeletePlanStep}
                                            />
                                        )
                                        )}
                                        <EditableTableRow
                                            key={this.props.newCycle.testPlan.length}
                                            index={this.props.newCycle.testPlan.length}
                                            stepDescriptionValue={this.state.stepDescription}
                                            expectedResultValue={this.state.expectedResult}
                                            onChange={this.onChangeNewPlanStep}
                                            onAdd={this.onAddStepPlan}
                                            errors={this.state.errors}
                                            resetErrorStateForInput={this.resetErrorStateForInput}
                                        />
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    }
                </Box>
            </Box>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PlanFormSlim)));