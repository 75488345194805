import React from 'react';
import { Table, Button, Label, Popup } from 'semantic-ui-react';
import { TestPlanStepStatus } from '../../../../types/TestPlanStepStatus';

const TestPlanTableRow = ({
  testPlanStep,
  index,
  status,
  onTestPlanStepUpdate,
  onTestPlanStepFail,
}) => {
  let labelColor: any = 'white';
  let labelText = '';

  switch (status) {
    case TestPlanStepStatus.Passed:
      labelColor = 'green';
      labelText = 'Passed';
      break;
    case TestPlanStepStatus.Failed:
      labelColor = 'red';
      labelText = 'Failed';
      break;
    case TestPlanStepStatus.Blocked:
      labelColor = 'grey';
      labelText = 'Blocked';
      break;
    default:
      break;
  }

  return (
    <Table.Row verticalAlign="top">
      <Table.Cell collapsing> {index} </Table.Cell>
      <Table.Cell>
        <p>{testPlanStep.description}</p>
        <p>
          <strong>Expected result:</strong>
          <br />
          {testPlanStep.expectedResult}
        </p>
      </Table.Cell>
      <Table.Cell>
        {status && <Label className="step-status" color={labelColor} content={labelText} />}

        <div className="step-action-buttons">
          <Button.Group compact basic vertical size="mini">
            <Button
              content="Passed"
              disabled={status === TestPlanStepStatus.Passed}
              data-id={testPlanStep.id}
              data-status={TestPlanStepStatus.Passed}
              onClick={onTestPlanStepUpdate}
            />

            <Button
              content="Failed"
              disabled={status === TestPlanStepStatus.Failed}
              data-id={testPlanStep.id}
              onClick={onTestPlanStepFail}
            />

            <Popup
              trigger={
                <Button
                  icon="question"
                  labelPosition="right"
                  content="Blocked"
                  disabled={status === TestPlanStepStatus.Blocked}
                  data-id={testPlanStep.id}
                  data-status={TestPlanStepStatus.Blocked}
                  onClick={onTestPlanStepUpdate}
                />
              }
              content="Blocked is a test step  that cannot run because the preconditions for its execution are not fulfilled"
              on="hover"
              position="top right"
            />
          </Button.Group>
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export const TestPlanTable = ({
  testPlan,
  testPlanResults,
  onTestPlanStepUpdate,
  onTestPlanStepFail,
}) => {
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell>Step description</Table.HeaderCell>
          <Table.HeaderCell className="step-actions">Result</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {testPlan.map((testPlanStep, i) => {
          const testPlanStepResult = testPlanResults.find(
            (el) => el.testPlanStepId === testPlanStep.id
          );
          const status = testPlanStepResult ? testPlanStepResult.status : '';

          return (
            <TestPlanTableRow
              index={++i}
              key={i}
              testPlanStep={testPlanStep}
              status={status}
              onTestPlanStepUpdate={onTestPlanStepUpdate}
              onTestPlanStepFail={onTestPlanStepFail}
            />
          );
        })}
      </Table.Body>
    </Table>
  );
};
