import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Dimmer, Loader, Message, Input, TextArea, Select, Button, Icon } from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import CycleDetailsSidemenu from './Sidemenu';
import { BugSeverity } from '../../../../types/BugSeverity';
import bugSeverities from '../../../../util/options/bugSeverities';
import { OperationStatus } from '../../../../types/OperationStatus';
import { CycleStatus } from '../../../../types/CycleStatus';
import actions from '../../../../redux/actions';
import { getApprovedDevicePrettyName } from '../../../../services/deviceService';
import config from '../../../../config';
import * as utils from '../../../../util/utils';
import moment from 'moment';

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    cycles: state.cycles,
    devices: state.devices,
    browsers: state.browsers,
    newBug: state.newBug,
    createBugStatus: state.createBugStatus,
    device: state.resolution.device,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    createBug: (cycleId: number, bugData: any) => dispatch(actions.createBug(cycleId, bugData)),
    updateNewBug: (newBug: any) => dispatch(actions.updateNewBug(newBug)),
  };
};

class NewBug extends Component<any> {
  public state: any;

  constructor(props: any) {
    super(props);

    this.state = {
      isTestPlanBug: !!props.match.params.testPlan,
      newBug: {
        ...props.newBug,
      },
      cycleId: parseInt(props.match.params.id, 10),
      errors: new Set(),
      totalFilesSize: 0,
    };
  }

  componentDidMount() {
    if (!this.state.isTestPlanBug) {
      this.props.updateNewBug({
        testPlanStepId: null,
        topic: '',
        stepsToReproduce: '',
        expectedResult: '',
        actualResult: '',
        comment: '',
        severity: BugSeverity.Low,
        imageFiles: [],
        videoFiles: [],
      });
    }
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.createBugStatus.status !== this.props.createBugStatus.status) {
      if (this.props.createBugStatus.status === OperationStatus.success) {
        this.props.history.push(`/account/tester/cycle/${this.state.cycleId}/bugs`);
      }
    }
  }

  resetErrorState = (event: any) => {
    const name = event.target.name;
    const errors = new Set(this.state.errors);

    if (errors.has(name)) {
      errors.delete(name);
      this.setState({ errors });
    }
  };

  onInput = (event: any, data: any) => {
    const lengthRestrictions = {
      topic: 500,
      stepsToReproduce: 5000,
      expectedResult: 5000,
      actualResult: 5000,
      comment: 5000,
    };

    const newBug = { ...this.state.newBug };

    if (lengthRestrictions[data.name] && data.value.length > lengthRestrictions[data.name]) {
      newBug[data.name] = data.value.substring(0, lengthRestrictions[data.name] - 1);
    } else {
      newBug[data.name] = data.value;
    }

    this.setState({ newBug });
  };

  validateInput = (event: any, data: any) => {
    const errors = new Set(this.state.errors);
    const name = data && data.name ? data.name : event.target.name;
    const value = data && data.value ? data.value : event.target.value;

    if (!value || !value.length) {
      errors.add(name);
    } else {
      errors.delete(name);
    }

    this.setState({ errors });
  };

  onImageUpload = (acceptedImageFiles: any, rejectedImageFiles: any) => {
    const imageFiles = [...this.state.newBug.imageFiles];

    const totalImagesFileSize = imageFiles.reduce((result: number, file: any) => {
      result += file.size;
      return result;
    }, 0);

    const totalVideoFileSize = this.state.newBug.videoFiles.reduce((result: number, file: any) => {
      result += file.size;
      return result;
    }, 0);

    let totalFilesSize = totalImagesFileSize + totalVideoFileSize;

    for (let file of acceptedImageFiles) {
      totalFilesSize += file.size;

      const isImage = utils.isImage(file.name);
      const ext = utils.getFileExtension(file.name);

      file = Object.assign(file, {
        preview: isImage ? URL.createObjectURL(file) : null,
        isImage,
        lowercaseName: file.name.toLowerCase(),
        ext,
      });

      imageFiles.push(file);
    }

    this.setState({
      newBug: {
        ...this.state.newBug,
        imageFiles,
      },
      totalFilesSize,
    });
  };

  onUndoImageUpload = (event, data) => {
    event.stopPropagation();

    const imageFiles = [...this.state.newBug.imageFiles].filter(
      (el, i) => i !== parseInt(data['data-index'], 10)
    );

    const totalImagesFileSize = imageFiles.reduce((result: number, file: any) => {
      result += file.size;
      return result;
    }, 0);

    const totalVideoFileSize = this.state.newBug.videoFiles.reduce((result: number, file: any) => {
      result += file.size;
      return result;
    }, 0);

    let totalFilesSize = totalImagesFileSize + totalVideoFileSize;

    this.setState({
      newBug: {
        ...this.state.newBug,
        imageFiles,
      },
      totalFilesSize,
    });
  };

  onVideoUpload = (acceptedVideoFiles) => {
    const videoFiles = [...this.state.newBug.videoFiles];

    const totalImagesFileSize = this.state.newBug.imageFiles.reduce((result: number, file: any) => {
      result += file.size;
      return result;
    }, 0);

    const totalVideoFileSize = videoFiles.reduce((result: number, file: any) => {
      result += file.size;
      return result;
    }, 0);

    let totalFilesSize = totalImagesFileSize + totalVideoFileSize;

    for (let file of acceptedVideoFiles) {
      totalFilesSize += file.size;

      file = Object.assign(file, {
        preview: URL.createObjectURL(file),
        lowercaseName: file.name.toLowerCase(),
      });

      videoFiles.push(file);
    }

    this.setState({
      newBug: {
        ...this.state.newBug,
        videoFiles,
      },
      totalFilesSize,
    });
  };

  onUndoVideoUpload = (event, data) => {
    event.stopPropagation();

    const videoFiles = [...this.state.newBug.videoFiles].filter(
      (el, i) => i !== data['data-index']
    );

    const totalImagesFileSize = this.state.newBug.imageFiles.reduce((result: number, file: any) => {
      result += file.size;
      return result;
    }, 0);

    const totalVideoFileSize = videoFiles.reduce((result: number, file: any) => {
      result += file.size;
      return result;
    }, 0);

    let totalFilesSize = totalImagesFileSize + totalVideoFileSize;

    this.setState({
      newBug: {
        ...this.state.newBug,
        videoFiles,
      },
      totalFilesSize,
    });
  };

  onSubmitBug = () => {
    const newBug = this.state.newBug;

    const bugProps: Record<string, any> = {
      topic: newBug.topic,
      stepsToReproduce: newBug.stepsToReproduce,
      expectedResult: newBug.expectedResult,
      actualResult: newBug.actualResult,
      comment: newBug.comment,
      severity: newBug.severity,
    };

    if (newBug.testPlanStepId) {
      bugProps.testPlanStepId = newBug.testPlanStepId;
    }

    const bugData = {
      bugProps,
      files: {
        imageFiles: newBug.imageFiles,
        videoFiles: newBug.videoFiles,
      },
    };

    this.props.createBug(this.state.cycleId, bugData);
  };

  isSaveEnabled = () => {
    const mandatoryFields = [
      'topic',
      'stepsToReproduce',
      'expectedResult',
      'actualResult',
      'severity',
    ];

    for (const el of mandatoryFields) {
      if (!this.state.newBug[el] || !this.state.newBug[el].length) {
        return false;
      }
    }

    if (this.state.errors.size) {
      return false;
    }

    if (this.props.createBugStatus.status === OperationStatus.processing) {
      return false;
    }

    return true;
  };

  render() {
    if (!this.props.cycles.length) {
      return null;
    }

    const cycle = this.props.cycles.find((el) => el.id === this.state.cycleId);

    const isCycleOver = ( cycle.status === CycleStatus.Completed && moment(cycle.questionsTime).isBefore(moment()) );

    const newBug = this.state.newBug;

    const approvedDeviceName = getApprovedDevicePrettyName(cycle.approvedDevice);

    const bugCreateProcessing = this.props.createBugStatus.status === OperationStatus.processing;
    const bugWithStep = newBug.testPlanStepId ? true : false;

    const errors = this.state.errors;
    const bugSaveEnabled = this.isSaveEnabled();

    const totalFilesSizeMB =
      this.state.totalFilesSize === 0 ? 0 : (this.state.totalFilesSize / 1024 / 1024).toFixed(2);

    const maxAttachmentsSizeMB = config.maxAttachmentsSizeMB;

    const attachmentsAreTooHeavy = totalFilesSizeMB > maxAttachmentsSizeMB;

    const isMobile = this.props.device === 'mobile' || this.props.device === 'mobile-tablet';

    if( isMobile ) {
      return (
        <>
        <CycleDetailsSidemenu cycleId={cycle.id} />
        <div className="account-tester page">
          {/* <div className="heading">
            <h1>New Bug</h1>
          </div> */}
  
          <div className="inner" style={{ display: 'flex' }}>
            {/* <CycleDetailsSidemenu cycleId={cycle.id} /> */}
  
            <main className="cycle-newbug" style={{marginTop: -25}}>
              {cycle.status === CycleStatus.Completed && (
                <Message info>
                  <Message.Header>This cycle has finished</Message.Header>
                  <Link to="/account/tester">Go to dashboard</Link>
                </Message>
              )}
              <h2>New Bug</h2>
              <h4>{cycle.title}</h4>
              <h4>Device: {approvedDeviceName}</h4>
  
              <label>
                Topic
                <Input
                  disabled={isCycleOver}
                  name="topic"
                  value={newBug.topic}
                  onChange={this.onInput}
                  onFocus={this.resetErrorState}
                  onBlur={this.validateInput}
                  error={errors.has('topic')}
                />
              </label>
              <label>
                Steps to reproduce
                <TextArea
                  name="stepsToReproduce"
                  value={newBug.stepsToReproduce}
                  onChange={this.onInput}
                  onFocus={this.resetErrorState}
                  onBlur={this.validateInput}
                  className={errors.has('stepsToReproduce') ? 'error' : ''}
                  disabled={bugWithStep || isCycleOver}
                />
              </label>
              <label>
                Expected result
                <TextArea
                  name="expectedResult"
                  value={newBug.expectedResult}
                  onChange={this.onInput}
                  onFocus={this.resetErrorState}
                  onBlur={this.validateInput}
                  className={errors.has('expectedResult') ? 'error' : ''}
                  disabled={bugWithStep || isCycleOver}
                />
              </label>
              <label>
                Actual result
                <TextArea
                  disabled={isCycleOver}
                  name="actualResult"
                  value={newBug.actualResult}
                  onChange={this.onInput}
                  onFocus={this.resetErrorState}
                  onBlur={this.validateInput}
                  className={errors.has('actualResult') ? 'error' : ''}
                />
              </label>
              <label>
                Severity
                <Select
                  disabled={isCycleOver}
                  className="severity"
                  name="severity"
                  placeholder="Select"
                  options={bugSeverities}
                  value={newBug.severity}
                  onChange={this.onInput}
                  onFocus={this.resetErrorState}
                  onBlur={this.validateInput}
                  error={errors.has('severity')}
                />
              </label>
              <label>
                Additional info
                <TextArea name="comment" value={newBug.comment} onChange={this.onInput}  disabled={isCycleOver}/>
              </label>
              {
                <div className="bug-uploadsSlim">
                  <Dropzone disabled={isCycleOver} onDrop={this.onImageUpload} multiple={true}>
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button className="upload" content="Upload attachment" positive disabled={isCycleOver}/>
                        {newBug.imageFiles.map((file, i) => (
                          <div className="bug-attachment" key={i}>
                            {file.isImage ? (
                              <img src={file.preview} alt="bug attachment preview" />
                            ) : (
                              <div className="non-image-attachment">
                                <Icon name="file alternate outline" />
                                {file.ext}
                              </div>
                            )}
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                              }}
                            >
                              <Button
                                disabled={isCycleOver}
                                basic
                                color="red"
                                className="undo-upload"
                                size="mini"
                                icon="trash alternate"
                                onClick={this.onUndoImageUpload}
                                data-index={i}
                              />
                              <span
                                style={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {file.lowercaseName}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </Dropzone>
  
                  <Dropzone disabled={isCycleOver} onDrop={this.onVideoUpload} multiple={true} accept="video/mp4">
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button className="upload" content="Upload video" positive disabled={isCycleOver}/>
                        {newBug.videoFiles.map((videoFile, i) => (
                          <div className="bug-attachment" key={i}>
                            <video controls width="240">
                              <source src={videoFile.preview} type="video/mp4" />
                            </video>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                              }}
                            >
                              <Button
                                disabled={isCycleOver}
                                basic
                                color="red"
                                className="undo-upload"
                                size="mini"
                                icon="trash alternate"
                                onClick={this.onUndoVideoUpload}
                                data-index={i}
                              />
                              <span
                                style={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {videoFile.lowercaseName}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </Dropzone>
                  <p className="attachments-size">
                    Attachments size:
                    <br /> {totalFilesSizeMB}/{maxAttachmentsSizeMB} mb
                  </p>
                </div>
              }
  
              {
                <div>
                  {attachmentsAreTooHeavy && (
                    <Message
                      negative
                      content={`Attachments are ${totalFilesSizeMB} MB total. Maximum ${maxAttachmentsSizeMB} MB allowed`}
                    />
                  )}
  
                  <Button                    
                    primary
                    disabled={!bugSaveEnabled || attachmentsAreTooHeavy || isCycleOver}
                    onClick={this.onSubmitBug}
                  >
                    Create
                  </Button>
                </div>
              }
  
              <Dimmer active={bugCreateProcessing} inverted page>
                <Loader>Submitting bug...</Loader>
              </Dimmer>
            </main>
          </div>
        </div>
        </>
      );
    }
    return (
      <div className="account-tester page">
        <div className="heading">
          <h1>New Bug</h1>
        </div>

        <div className="inner" style={{ display: 'flex' }}>
          <CycleDetailsSidemenu cycleId={cycle.id} />

          <main className="cycle-newbug">
            {cycle.status === CycleStatus.Completed && (
              <Message info>
                <Message.Header>This cycle has finished</Message.Header>
                <Link to="/account/tester">Go to dashboard</Link>
              </Message>
            )}
            <h2>New Bug</h2>
            <h4>{cycle.title}</h4>
            <h4>Device: {approvedDeviceName}</h4>

            <label>
              Topic              
              <Input
                disabled={isCycleOver}
                name="topic"
                value={newBug.topic}
                onChange={this.onInput}
                onFocus={this.resetErrorState}
                onBlur={this.validateInput}
                error={errors.has('topic')}
              />
            </label>
            <label>
              Steps to reproduce
              <TextArea                
                name="stepsToReproduce"
                value={newBug.stepsToReproduce}
                onChange={this.onInput}
                onFocus={this.resetErrorState}
                onBlur={this.validateInput}
                className={errors.has('stepsToReproduce') ? 'error' : ''}
                disabled={bugWithStep || isCycleOver}
              />
            </label>
            <label>
              Expected result
              <TextArea
                name="expectedResult"
                value={newBug.expectedResult}
                onChange={this.onInput}
                onFocus={this.resetErrorState}
                onBlur={this.validateInput}
                className={errors.has('expectedResult') ? 'error' : ''}
                disabled={bugWithStep || isCycleOver}
              />
            </label>
            <label>
              Actual result
              <TextArea
                name="actualResult"
                value={newBug.actualResult}
                onChange={this.onInput}
                onFocus={this.resetErrorState}
                onBlur={this.validateInput}
                className={errors.has('actualResult') ? 'error' : ''}
                disabled={isCycleOver}
              />
            </label>
            <label>
              Severity
              <Select
                className="severity"
                name="severity"
                placeholder="Select"
                options={bugSeverities}
                value={newBug.severity}
                onChange={this.onInput}
                onFocus={this.resetErrorState}
                onBlur={this.validateInput}
                error={errors.has('severity')}
                disabled={isCycleOver}
              />
            </label>
            <label>
              Additional info
              <TextArea name="comment" value={newBug.comment} onChange={this.onInput} disabled={isCycleOver}/>
            </label>
            {
              <div className="bug-uploads">
                <Dropzone disabled={isCycleOver} onDrop={this.onImageUpload} multiple={true}>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Button className="upload" content="Upload attachment" positive disabled={isCycleOver}/>
                      {newBug.imageFiles.map((file, i) => (
                        <div className="bug-attachment" key={i}>
                          {file.isImage ? (
                            <img src={file.preview} alt="bug attachment preview" />
                          ) : (
                            <div className="non-image-attachment">
                              <Icon name="file alternate outline" />
                              {file.ext}
                            </div>
                          )}
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              width: '100%',
                            }}
                          >
                            <Button
                              basic
                              disabled={isCycleOver}
                              color="red"
                              className="undo-upload"
                              size="mini"
                              icon="trash alternate"
                              onClick={this.onUndoImageUpload}
                              data-index={i}
                            />
                            <span
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {file.lowercaseName}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </Dropzone>

                <Dropzone disabled={isCycleOver} onDrop={this.onVideoUpload} multiple={true} accept="video/mp4">
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Button className="upload" content="Upload video" positive disabled={isCycleOver}/>
                      {newBug.videoFiles.map((videoFile, i) => (
                        <div className="bug-attachment" key={i}>
                          <video controls width="240">
                            <source src={videoFile.preview} type="video/mp4" />
                          </video>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              width: '100%',
                            }}
                          >
                            <Button
                              basic
                              disabled={isCycleOver}
                              color="red"
                              className="undo-upload"
                              size="mini"
                              icon="trash alternate"
                              onClick={this.onUndoVideoUpload}
                              data-index={i}
                            />
                            <span
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {videoFile.lowercaseName}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </Dropzone>
                <p className="attachments-size">
                  Attachments size:
                  <br /> {totalFilesSizeMB}/{maxAttachmentsSizeMB} mb
                </p>
              </div>
            }

            {
              <div>
                {attachmentsAreTooHeavy && (
                  <Message
                    negative
                    content={`Attachments are ${totalFilesSizeMB} MB total. Maximum ${maxAttachmentsSizeMB} MB allowed`}
                  />
                )}

                <Button                  
                  primary
                  disabled={!bugSaveEnabled || attachmentsAreTooHeavy || isCycleOver}
                  onClick={this.onSubmitBug}
                >
                  Create
                </Button>
              </div>
            }

            <Dimmer active={bugCreateProcessing} inverted page>
              <Loader>Submitting bug...</Loader>
            </Dimmer>
          </main>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewBug));
