import React, { Component } from 'react';
import { Box, Typography } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';


import wizardActions from '../../../redux/actions/wizardActions';
import { NewWizardCycle } from '../../../types/NewWizardCycle';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SharedStyle from './SharedStyle';
import { PayoutRatesAndTestersQuota } from '../../../types/WizardCategoriesTypes';


const styles = (theme) => createStyles({
    
    cardsBox: {
        marginTop: '20px',
        marginLeft: '-8px',
        display: 'flex',
        flexWrap: 'wrap',    
        '& > *': {
          margin: '8px',
          width: '285px',
          height: '80px',
        },
      },
    cardDefault: {
        border: SharedStyle.contentCardDefault.border,
        borderRadius: SharedStyle.contentCardDefault.borderRadius,
        cursor: SharedStyle.contentCardDefault.cursor,        
    },
    cardSelected:{        
        border: SharedStyle.contentCardSelected.border,
        borderRadius: SharedStyle.contentCardSelected.borderRadius,
        cursor: SharedStyle.contentCardSelected.cursor,
    },
    descriptionCardText: {
        fontSize: '12px',
        color: '#171725',
      },
    
    SpreadBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    extraPayText: {
        fontSize: '24px',        
        color: theme.palette.primary.main,
    },
    strikedText: {
        fontSize: '18px',
        fontStyle: 'italic',
        textDecoration: 'line-through',
    }

  });

const mapStateToProps = (store) => {
  return {
    newCycle: store.newWizardCycle as NewWizardCycle,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    updateCycle: (data) => dispatch(wizardActions.updateCycle(data)),      
  };
};

class DurationForm extends Component<any> {    
    
    onSelectionChangeVal = (valueSelected) => {
        const newCycle: NewWizardCycle = { ...this.props.newCycle };
        newCycle.hoursPerTester = valueSelected;
        
        this.props.updateCycle(newCycle);
    }

    render() {

        const {classes, newCycle} = this.props;

        return(
                <Box>
                    <Typography variant="h2">
                          {`Set time check per tester ($${PayoutRatesAndTestersQuota.baseHrlyRate} base hourly rate)`}
                    </Typography>
                    <Box className={classes.cardsBox}>
                        <Card 
                            onClick={() => this.onSelectionChangeVal(1)} 
                            elevation={0} 
                            className={newCycle.hoursPerTester === 1 ?  classes.cardSelected : classes.cardDefault }                            
                        >
                            <CardContent>
                                <Box className={classes.SpreadBox}>
                                    <Box style={{ display: 'flex', flexDirection:'column', alignItems: 'left'}}>
                                        <Typography variant="h6">
                                            1 hour testing
                                        </Typography>
                                        <Typography className={classes.descriptionCardText} variant="h6">
                                            AVG.bugs/flows/ etc.
                                        </Typography>
                                    </Box>                                        
                                </Box>
                            </CardContent>
                        </Card>

                        <Card
                            onClick={() => this.onSelectionChangeVal(2)} 
                            elevation={0} 
                            className={newCycle.hoursPerTester === 2 ?  classes.cardSelected : classes.cardDefault }
                        >                
                            <CardContent>
                                <Box className={classes.SpreadBox}>
                                    <Box style={{ display: 'flex', flexDirection:'column', alignItems: 'left'}}>
                                        <Typography variant="h6">
                                            2 hour testing
                                        </Typography>
                                        <Typography className={classes.descriptionCardText} variant="h6">
                                            AVG.bugs/flows/ etc.
                                        </Typography>
                                    </Box>
                                        <Typography className={classes.strikedText}>
                                                +$10
                                        </Typography>
                                        <Typography className={classes.extraPayText}>
                                                +$5
                                        </Typography>
                                </Box>
                            </CardContent>
                        </Card>

                        <Card
                            onClick={() => this.onSelectionChangeVal(3)} 
                            elevation={0} 
                            className={newCycle.hoursPerTester === 3 ?  classes.cardSelected : classes.cardDefault }
                        >                
                            <CardContent>
                                <Box className={classes.SpreadBox}>
                                    <Box style={{ display: 'flex', flexDirection:'column', alignItems: 'left'}}>
                                        <Typography variant="h6">
                                            3 hour testing
                                        </Typography>
                                        <Typography className={classes.descriptionCardText} variant="h6">
                                            AVG.bugs/flows/ etc.
                                        </Typography>
                                    </Box>
                                        <Typography className={classes.strikedText}>
                                                +$20
                                        </Typography>
                                        <Typography className={classes.extraPayText}>
                                                +$10
                                        </Typography>
                                </Box>
                            </CardContent>
                        </Card>

                        <Card
                            onClick={() => this.onSelectionChangeVal(4)}  
                            elevation={0} 
                            className={newCycle.hoursPerTester === 4 ?  classes.cardSelected : classes.cardDefault }
                        >                
                            <CardContent>
                                <Box className={classes.SpreadBox}>
                                    <Box style={{ display: 'flex', flexDirection:'column', alignItems: 'left'}}>
                                        <Typography variant="h6">
                                            4 hour testing
                                        </Typography>
                                        <Typography className={classes.descriptionCardText} variant="h6">
                                            AVG.bugs/flows/ etc.
                                        </Typography>
                                    </Box>
                                        <Typography className={classes.strikedText}>
                                                +$30
                                        </Typography>
                                        <Typography className={classes.extraPayText}>
                                                +$15
                                        </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>                
                </Box>                
        );    
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DurationForm)));