export enum UserStatus {
  EmailPending = 'email_pending',
  ProfilePending = 'profile_pending',
  PhonePending = 'phone_pending',
  DevicesPending = 'devices_pending',
  BrowsersPending = 'browsers_pending',
  EvalCyclePending = 'eval_cycle_pending',
  Active = 'active',
  Blocked = 'blocked',
  Deleted = 'deleted',
}
