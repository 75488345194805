import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Message, Button, Icon, Select, Table } from 'semantic-ui-react';
import {
  filterManufacturersByPlatform,
  filterOsesByPlatform,
} from '../../../../services/deviceService';
import actions from '../../../../redux/actions';
import { DevicePlatform } from '../../../../types/DevicePlatform';
import { getPlatformPrettyName } from '../../../../services/deviceService';
import { OperationStatus } from '../../../../types/OperationStatus';
import { UserStatus } from '../../../../types/UserStatus';
import { searchFromStart } from '../../../../util/utils';

const mapStateToProps = (state) => {
  return {
    addDeviceStatus: state.addDeviceStatus,
    userSaveStatus: state.userSaveStatus,
    user: state.user,
    manufacturers: state.devices.manufacturers,
    models: state.devices.models,
    oses: state.devices.oses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addDevice: (data) => dispatch(actions.addDevice(data)),
    deleteDevice: (data) => dispatch(actions.deleteDevice(data)),
    updateUser: (data) => dispatch(actions.updateUser(data)),
    saveUser: (data) => dispatch(actions.saveUser(data)),
  };
};

const platformOptions: any[] = Object.entries(DevicePlatform).reduce((result, [key, val]) => {
  if (val !== DevicePlatform.Desktop && val !== DevicePlatform.WindowsDesktop && val !== DevicePlatform.WindowsLaptop ) {
    result.push({
      text: getPlatformPrettyName(val),
      key: DevicePlatform[key],
      value: DevicePlatform[key],
    });
  }

  return result;
}, [] as any[]);

class Devices extends Component<any> {
  public state: any;

  constructor(props) {
    super(props);
    this.state = {
      newPlatformId: '',
      newManufacturerId: '',
      newModelId: '',
      newOsId: '',
      manufacturers: [],
      models: [],
      oses: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.userSaveStatus.status !== prevProps.userSaveStatus.status &&
      this.props.userSaveStatus.status === OperationStatus.success
    ) {
      this.props.history.push('/complete-profile/browsers');
    }
  }

  onChange = (event, data) => {
    switch (data.name) {
      case 'newPlatformId': {
        const manufacturers = filterManufacturersByPlatform(
          data.value,
          this.props.manufacturers,
          this.props.models
        );

        let newManufacturerId = '';
        let models: any[] = [];
        let newModelId = '';   

        if (manufacturers.length === 1) {
          newManufacturerId = manufacturers[0].id;
          models = this.props.models
            .filter((el) => el.platformId === data.value)
            .filter((el) => el.manufacturerId === newManufacturerId);
            
          if( models.length === 1 ) {            
            newModelId = models[0].id;            
          }
        }                        

        const oses = filterOsesByPlatform(data.value, this.props.oses);

        const newState: Record<string, any> = {
          ...this.state,
          newPlatformId: data.value,
          newManufacturerId,
          newModelId,
          newOsId: '',
          manufacturers,
          models,
          oses,
        };
        
        return this.setState(newState);
      }

      case 'newManufacturerId': {
        const models = this.props.models
          .filter((el) => el.platformId === this.state.newPlatformId)
          .filter((el) => el.manufacturerId === data.value);

          let newModelId = '';

          if( models.length === 1 ) {            
            newModelId = models[0].id;
          }

        const newState: Record<string, any> = {
          ...this.state,
          newManufacturerId: data.value,
          newModelId,
          newOsId: '',
          models,
        };

        return this.setState(newState);
      }

      case 'newModelId':
        return this.setState({
          ...this.state,
          newModelId: data.value,
          newOsId: '',
        });

      case 'newOsId':
        return this.setState({
          ...this.state,
          newOsId: data.value,
        });

      default:
        return;
    }
  };

  onAddDevice = () => {
    this.props.addDevice({
      platformId: this.state.newPlatformId,
      manufacturerId: this.state.newManufacturerId,
      modelId: this.state.newModelId,
      osId: this.state.newOsId,
    });
  };

  isDeviceAlreadyExists = (device) => {
    const result = this.props.user.devices.find(
      (el) => el.modelId === device.modelId && el.osId === device.osId
    );

    if (result) {
      return true;
    }

    return false;
  };

  onDeleteDevice = (event, data) => {
    this.props.deleteDevice({
      modelId: data.devicemodelid,
      osId: data.deviceosid,
    });
  };

  onSave = () => {
    this.props.updateUser({ status: UserStatus.BrowsersPending });

    const userData = Object.keys(this.props.user).reduce((result, el) => {
      if (
        this.props.user[el] !== null &&
        el !== 'cycles' // cycles are managed separately
      ) {
        result[el] = this.props.user[el];
      }

      if (['desktopTypes', 'preferredWork', 'preferredPlatforms'].includes(el)) {
        result[el] = [...this.props.user[el]];
      }

      return result;
    }, {});

    this.props.saveUser(userData);
  };

  render() {
    // TODO don't do it on each render
    const vendorOptions = this.state.manufacturers.map((el) => ({
      key: el.id,
      value: el.id,
      text: el.name,
    }));

    const modelOptions = this.state.models.map((el) => ({
      key: el.id,
      value: el.id,
      text: el.name,
    }));

    const osOptions = this.state.oses
      .map((el) => ({
        key: el.id,
        value: el.id,
        text: el.name,
      }))
      .reverse();

    const processing = this.props.addDeviceStatus.status === OperationStatus.processing;
    const disabled =
      processing ||
      !this.state.newPlatformId ||
      !this.state.newManufacturerId ||
      !this.state.newModelId ||
      !this.state.newOsId ||
      this.isDeviceAlreadyExists({
        modelId: this.state.newModelId,
        osId: this.state.newOsId,
      });

    const manufacturers = this.props.manufacturers ? this.props.manufacturers : [];
    const models = this.props.models ? this.props.models : [];
    const oses = this.props.oses ? this.props.oses : [];

    return (
      <div className="devices">
        <Message info icon>
          <Icon name="mobile alternate" />
          <Message.Content>
            <Message.Header>Register your device/s</Message.Header>
            <p>
              You must have at least one device registered, but more registered devices means more
              jobs which you could qualify for
            </p>
          </Message.Content>
        </Message>

        <div className="add">
          <div className="input-devices">
            <span>Device type</span>
            <Select
              name="newPlatformId"
              options={platformOptions}
              value={this.state.newPlatformId}
              onChange={this.onChange}
              search={searchFromStart}
            />
          </div>
          <div className="input-devices">
            <span>Device brand</span>
            <Select
              name="newManufacturerId"
              options={vendorOptions}
              disabled={vendorOptions.length === 1}
              value={this.state.newManufacturerId}
              onChange={this.onChange}
              search={searchFromStart}
            />
          </div>
          <div className="input-devices">
            <span>Device model</span>
            <Select
              name="newModelId"
              options={modelOptions}
              disabled={modelOptions.length === 1}
              value={this.state.newModelId}
              onChange={this.onChange}
              search={searchFromStart}
            />
          </div>
          <div className="input-devices">
            <span>Device OS</span>
            <Select
              name="newOsId"
              options={osOptions}
              value={this.state.newOsId}
              onChange={this.onChange}
              search={searchFromStart}
            />
          </div>
          <Button primary loading={processing} disabled={disabled} onClick={this.onAddDevice}>
            Add device
          </Button>
        </div>

        <div className="my-devices">
          <h3>My devices</h3>
          <Table compact celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Brand</Table.HeaderCell>
                <Table.HeaderCell>Model</Table.HeaderCell>
                <Table.HeaderCell>OS</Table.HeaderCell>
                <Table.HeaderCell width="1"></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.user.devices.map((el, i) => (
                <Table.Row key={i}>
                  <Table.Cell>
                    {(() => {
                      const manufacturerId = models.find((model) => model.id === el.modelId)
                        .manufacturerId;
                      return manufacturers.find((man) => man.id === manufacturerId).name;
                    })()}
                  </Table.Cell>
                  <Table.Cell>{models.find((mod) => mod.id === el.modelId).name}</Table.Cell>
                  <Table.Cell>{oses.find((os) => os.id === el.osId).name}</Table.Cell>
                  <Table.Cell>
                    <Button
                      negative
                      compact
                      size="mini"
                      devicemodelid={el.modelId}
                      deviceosid={el.osId}
                      onClick={this.onDeleteDevice}
                    >
                      Delete
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>

          <Button
            className="save-and-next"
            primary
            icon
            size="big"
            labelPosition="right"
            disabled={!this.props.user.devices.length}
            onClick={this.onSave}
          >
            Save &amp; next
            <Icon name="arrow right" />
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Devices));
