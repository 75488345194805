import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Select, Table } from 'semantic-ui-react';
import actions from '../../../../redux/actions';
import { OperationStatus } from '../../../../types/OperationStatus';
import { DesktopType } from '../../../../types/DesktopType';
import { DevicePlatform } from '../../../../types/DevicePlatform';
import { searchFromStart } from '../../../../util/utils';
import { getPlatformPrettyName } from '../../../../services/deviceService';

const mapStateToProps = (state) => {
  return {
    addBrowserStatus: state.addBrowserStatus,
    userSaveStatus: state.userSaveStatus,
    user: state.user,
    browserNames: state.browsers.names,
    browserVersions: state.browsers.versions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (data) => dispatch(actions.updateUser(data)),
    saveUser: (data) => dispatch(actions.saveUser(data)),
    addBrowser: (data) => dispatch(actions.addBrowser(data)),
    deleteBrowser: (data) => dispatch(actions.deleteBrowser(data)),
  };
};

class Browsers extends Component<any> {
  public state: any;
  private platformTypeOptions: any[];  

  constructor(props) {
    super(props);
    this.state = {
      newBrowserId: '',
      newBrowserVersionId: '',
      browserVersions: [],
    };

    this.platformTypeOptions = Object.entries(DevicePlatform).reduce((result, [key, val]) => {
      if (val !== DevicePlatform.Desktop && val !== DevicePlatform.WindowsDesktop && val !== DevicePlatform.WindowsLaptop ) {
        result.push({
          text: getPlatformPrettyName(val),
          key: DevicePlatform[key],
          value: DevicePlatform[key],
        });
      }        
      return result;
    }, [] as any[]);
    
  }

  onChange = (event, data) => {
    const user = { ...this.props.user };

    switch (data.name) {
      case 'desktopType': {
        user.desktopTypes = [data.value];
        this.props.updateUser(user);
        break;
      }

      case 'newBrowserId': {
        const browserVersions = this.props.browserVersions.filter(
          (el) => el.browserId === data.value
        );

        return this.setState({
          ...this.state,
          newBrowserId: data.value,
          newBrowserVersionId: '',
          browserVersions,
        });
      }

      case 'newBrowserVersionId': {
        return this.setState({
          ...this.state,
          newBrowserVersionId: data.value,
        });
      }

      default:
        return;
    }
  };

  onAddBrowser = () => {
    // TODO maybe remove this and call from render()?
    this.props.addBrowser({
      platformId: [...this.props.user.desktopTypes][0],
      browserId: this.state.newBrowserId,
      browserVersionId: this.state.newBrowserVersionId,
    });
  };

  isBrowserAlreadyExists = (browser) => {
    const result = this.props.user.browsers.find(
      (el) => el.browserId === browser.id && el.browserVersionId === browser.versionId && el.platformId === browser.platformId
    );

    if (result) {
      return true;
    }

    return false;
  };

  onDeleteBrowser = (event, data) => {
    
    this.props.deleteBrowser({
      browserPlatformId: data.platformid,
      browserId: data.browserid,
      browserVersionId: data.browserversionid,
    });
  };

  onSave = () => {
    const user = { ...this.props.user };

    if (user.desktopTypes.includes(DesktopType.None)) {
      user.browsers = [];
    }

    delete user.avatarUrl;
    this.props.saveUser(user);
    this.props.updateUser(user);
  };

  render() {
    const browserOptions = this.props.browserNames.map((el) => ({
      key: el.id,
      value: el.id,
      text: el.name,
    }));

    const browserVersionOptions = this.props.browserVersions
      .filter((el) => el.browserId === this.state.newBrowserId)
      .map((el) => ({
        key: el.id,
        value: el.id,
        text: el.version,
      }))
      .reverse();

    const addBrowserProcessing = this.props.addBrowserStatus.status === OperationStatus.processing;
    const addBrowserDisabled =
      addBrowserProcessing ||
      !this.state.newBrowserId ||
      !this.state.newBrowserVersionId ||
      this.isBrowserAlreadyExists({
        id: this.state.newBrowserId,
        versionId: this.state.newBrowserVersionId,
        platformId: [...this.props.user.desktopTypes][0],
      });

    const browserNames = this.props.browserNames;
    const browserVersions = this.props.browserVersions;

    const user = this.props.user;

    const saveDisabled =
      (user.desktopTypes.includes(DesktopType.Windows) ||
        user.desktopTypes.includes(DesktopType.Mac)) &&
      !user.browsers.length;

    const userSaveProcessing = this.props.userSaveStatus.status === OperationStatus.processing;

    return (
      <div>
        <div className="browsers">
          <h3>Your platform</h3>
          <div className="add">
            <div className="desktop-types">
              <Select
                name="desktopType"
                options={this.platformTypeOptions}
                value={[...user.desktopTypes][0]}
                onChange={this.onChange}
              />
            </div>
          </div>

          {!user.desktopTypes.includes(DesktopType.None) && (
            <React.Fragment>
              <h3>Add browser</h3>
              <div className="add">
                <div className="input-devices">
                  <span>Browser</span>
                  <Select
                    name="newBrowserId"
                    options={browserOptions}
                    value={this.state.newBrowserId}
                    onChange={this.onChange}
                  />
                </div>
                <div className="input-devices">
                  <span>Version</span>
                  <Select
                    name="newBrowserVersionId"
                    options={browserVersionOptions}
                    value={this.state.newBrowserVersionId}
                    onChange={this.onChange}
                    search={searchFromStart}
                  />
                </div>
              </div>

              <Button
                primary
                loading={addBrowserProcessing}
                disabled={addBrowserDisabled}
                onClick={this.onAddBrowser}
              >
                Add browser
              </Button>

              <h3>My browsers</h3>
              <Table compact celled className="browsers-table">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Platform</Table.HeaderCell>
                    <Table.HeaderCell width={5}>Browser</Table.HeaderCell>
                    <Table.HeaderCell>Version</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {user.browsers.map((el, i) => {
                    const browserPlatformName = el.platformId;
                    const browser = browserNames.find((b) => b.id === el.browserId);                                        
                    
                    const browserName =
                      browser && browser.name ? browser.name : `Unknown browser ${el.browserId}`;

                    const browserVersionHolder = browserVersions.find(
                      (v) => v.id === el.browserVersionId
                    );
                    const browserVersion =
                      browserVersionHolder && browserVersionHolder.version
                        ? browserVersionHolder.version
                        : `Unknown browser version ${el.browserVersionId}`;

                    return (
                      <Table.Row key={i}>
                        <Table.Cell>{browserPlatformName}</Table.Cell>
                        <Table.Cell>{browserName}</Table.Cell>
                        <Table.Cell>{browserVersion}</Table.Cell>
                        <Table.Cell>
                          <Button
                            negative
                            compact
                            size="mini"
                            browserid={el.browserId}
                            browserversionid={el.browserVersionId}
                            platformid={el.platformId}
                            onClick={this.onDeleteBrowser}
                          >
                            Delete
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </React.Fragment>
          )}
        </div>

        <Button
          primary
          loading={userSaveProcessing}
          disabled={userSaveProcessing || saveDisabled}
          onClick={this.onSave}
        >
          Save
        </Button>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Browsers));
