import jwt from 'jsonwebtoken';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import Cookies from 'universal-cookie';
import config from '../config';
import { OperationStatus } from '../types/OperationStatus';
import { UserRole } from '../types/UserRole';
import { Result } from '../util/Result';
import { prepareCustomer, prepareTester } from '../util/dataTransformer';
import { sleep } from '../util/utils';

function setStatus(statusName, status, errorMessage = '') {
  return {
    type: 'SET_STATUS',
    statusName,
    status,
    errorMessage,
  };
}

function toggleSidebar() {
  return { type: 'TOGGLE_SIDEBAR' };
}

function login(email: string, password: string) {
  email = email.trim();

  return async (dispatch: Dispatch) => {
    dispatch({ type: 'LOGIN_START' });

    try {
      // TODO why it's x-form-urlencoded?
      const jwtResponse = await fetch(`${config.apiEndpoint}/api/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
          password,
        }),
      });

      if (jwtResponse.status > 399) {
        return dispatch({
          type: 'LOGIN_FAIL',
          errorMessage: (await jwtResponse.json()).message,
        });
      }

      const { token } = await jwtResponse.json();
      localStorage.setItem('token', token);
      const cookies = new Cookies();
      cookies.set('token', token, { path: '/', domain: '.testpoint.com' });

      const result = await Promise.all([
        fetch(`${config.apiEndpoint}/api/users/me`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }),
        fetch(`${config.apiEndpoint}/api/cyclesslim`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }),
      ]);


      const [profileResponse, cyclesResponse] = result;

      if (profileResponse.status > 399) {
        return dispatch({
          type: 'LOGIN_FAIL',
          errorMessage: await profileResponse.json(),
        });
      }

      let { user } = await profileResponse.json();
      let display_name = (user.firstName || '') + ' ' + (user.lastName || '');
      cookies.set('display_name', display_name, { path: '/', domain: '.testpoint.com' });

      if (user.role === UserRole.Tester) {
        user = prepareTester(user);
      }

      if (user.role === UserRole.Customer || user.role === UserRole.Viewer) {
        user = prepareCustomer(user);
      }

      const cyclesSlim = await cyclesResponse.json();

      dispatch({
        type: 'LOGIN_SUCCESS',
        user,
      });

      dispatch({
        type: 'LIST_CYCLES_SLIM_SUCCESS',
        cyclesSlim,
      });

    } catch (e) {
      return dispatch({
        type: 'LOGIN_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });
    }
  };
}

function loginGoogle(
  email: string,
  accessToken: string,
  googleUserId: string,
  avatarUrl: string
) {
  return async (dispatch) => {
    dispatch({ type: 'LOGIN_START' });

    try {
      const jwtResponse = await fetch(`${config.apiEndpoint}/api/login/google`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
          accessToken,
          googleUserId,
        }),
      });

      if (!jwtResponse.ok) {
        return dispatch({
          type: 'LOGIN_FAIL',
          errorMessage: await jwtResponse.json(),
        });
      }

      const { token } = await jwtResponse.json();
      localStorage.setItem('token', token);
      const cookies = new Cookies();
      cookies.set('token', token, { path: '/', domain: '.testpoint.com' });

      const profileResponseResult = await authorizedFetch(`${config.apiEndpoint}/api/users/me`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });

      if (!profileResponseResult.isSuccess) {
        return dispatch({
          type: 'LOGIN_FAIL',
          errorMessage: profileResponseResult.error,
        });
      }

      let { user } = await profileResponseResult.getValue().json();
      let display_name = (user.firstName || '') + ' ' + (user.lastName || '');
      cookies.set('display_name', display_name, { path: '/', domain: '.testpoint.com' });


      if (user.avatarUrl) {
        if (user.avatarUrl.includes(config.apiEndpoint)) {
          // user has local avatar, no need to do anything
        } else {
          dispatch(saveUser({ avatarUrl }, false));
        }
      } else {
        dispatch(saveUser({ avatarUrl }, false));
      }

      if (user.role === UserRole.Tester) {
        user = prepareTester(user);
      }

      dispatch({
        type: 'LOGIN_SUCCESS',
        user,
      });

    } catch (e) {
      return dispatch({
        type: 'LOGIN_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });
    }
  };
}

function loginFacebook(
  email: string,
  accessToken: string,
  facebookUserId: string,
  avatarUrl: string
) {
  return async (dispatch) => {
    dispatch({ type: 'LOGIN_START' });

    try {
      const jwtResponse = await fetch(`${config.apiEndpoint}/api/login/facebook`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
          accessToken,
          facebookUserId,
        }),
      });

      if (!jwtResponse.ok) {
        return dispatch({
          type: 'LOGIN_FAIL',
          errorMessage: await jwtResponse.json(),
        });
      }

      const { token } = await jwtResponse.json();
      localStorage.setItem('token', token);
      const cookies = new Cookies();
      cookies.set('token', token, { path: '/', domain: '.testpoint.com' });

      const profileResponseResult = await authorizedFetch(`${config.apiEndpoint}/api/users/me`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });

      if (!profileResponseResult.isSuccess) {
        return dispatch({
          type: 'LOGIN_FAIL',
          errorMessage: profileResponseResult.error,
        });
      }

      let { user } = await profileResponseResult.getValue().json();
      let display_name = (user.firstName || '') + ' ' + (user.lastName || '');
      cookies.set('display_name', display_name, { path: '/', domain: '.testpoint.com' });


      if (user.avatarUrl) {
        if (user.avatarUrl.includes(config.apiEndpoint)) {
          // user has local avatar, no need to do anything
        } else {
          dispatch(saveUser({ avatarUrl }, false));
        }
      } else {
        dispatch(saveUser({ avatarUrl }, false));
      }

      if (user.role === UserRole.Tester) {
        user = prepareTester(user);
      }

      dispatch({
        type: 'LOGIN_SUCCESS',
        user,
      });

    } catch (e) {
      return dispatch({
        type: 'LOGIN_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });
    }
  };
}

function impersonate(loginToken) {
  return async (dispatch) => {
    dispatch({ type: 'LOGOUT' });
    dispatch({ type: 'IMPERSONATE_START' });

    try {
      localStorage.setItem('token', loginToken);
      const cookies = new Cookies();
      cookies.set('token', loginToken, { path: '/', domain: '.testpoint.com' });

      const result = await Promise.all([
        fetch(`${config.apiEndpoint}/api/users/me`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${loginToken}`,
          },
        }),
        fetch(`${config.apiEndpoint}/api/cyclesslim`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${loginToken}`,
          },
        }),
      ]);

      const [profileResponse, cyclesResponse] = result;

      if (!profileResponse.ok) {
        const errorMessage = await profileResponse.json();

        //Let's also clear the cookie here as probably it's not valid
        logout();

        return dispatch({
          type: 'IMPERSONATE_FAIL',
          errorMessage,
        });
      }

      let { user } = await profileResponse.json();
      let display_name = (user.firstName || '') + ' ' + (user.lastName || '');
      cookies.set('display_name', display_name, { path: '/', domain: '.testpoint.com' });

      if (user.role === UserRole.Tester) {
        user = prepareTester(user);
      }

      if (user.role === UserRole.Customer || user.role === UserRole.Viewer) {
        user = prepareCustomer(user);
      }

      const cyclesSlim = await cyclesResponse.json();

      dispatch({
        type: 'IMPERSONATE_SUCCESS',
        user,
      });

      dispatch({
        type: 'LIST_CYCLES_SLIM_SUCCESS',
        cyclesSlim,
      });

    } catch (e) {
      console.error('Impersonate fail', e);
      return dispatch({
        type: 'IMPERSONATE_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });
    }
  };
}

function checkLogin() {
  if (localStorage.getItem('token')) {
    return impersonate(localStorage.getItem('token'));
  } else {
    return { type: 'LOGIN_DEFAULT' };
  }
}

function loginModalOpen() {
  return { type: 'LOGIN_MODAL_OPEN' };
}

function loginModalClose() {
  return { type: 'LOGIN_MODAL_CLOSE' };
}

function registerModalOpen() {
  return { type: 'REGISTER_MODAL_OPEN' };
}

function registerModalClose() {
  return { type: 'REGISTER_MODAL_CLOSE' };
}

function logout() {
  localStorage.removeItem('token');
  const pastDate = new Date(0);
  const cookies = new Cookies();
  cookies.remove('token', { domain: '.testpoint.com', path: '/', expires: pastDate });
  cookies.remove('display_name', { domain: '.testpoint.com', path: '/', expires: pastDate });
  localStorage.removeItem('newCycle');
  localStorage.removeItem('redirectAfterLogin');

  return { type: 'LOGOUT' };
}

function register(role, email, password, isTestpoint?) {
  email = email.trim();
  const testPoint = isTestpoint || false;

  return async (dispatch) => {
    dispatch({ type: 'REGISTER_START' });

    try {
      const response = await fetch(`${config.apiEndpoint}/api/users`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          role,
          email,
          password,
          testPoint
        }),
      });

      if (!response.ok) {
        return dispatch({
          type: 'REGISTER_FAIL',
          errorMessage: (await response.json()).errorMessage,
        });
      }

      return dispatch({
        type: 'REGISTER_SUCCESS',
        accountType: 'regular', // TODO enum
      });
    } catch (e) {
      console.error(e);
      return dispatch({
        type: 'REGISTER_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });
    }
  };
}

function registerGoogle(role, email, accessToken, googleUserId, avatarUrl, firstName, lastName) {
  email = email.trim();

  return async (dispatch) => {
    dispatch({ type: 'REGISTER_START' });

    let source = 'google';

    try {
      const response = await fetch(`${config.apiEndpoint}/api/users`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          role,
          source,
          email,
          accessToken,
          googleUserId,
          avatarUrl,
          firstName,
          lastName
        }),
      });

      if (!response.ok) {
        return dispatch({
          type: 'REGISTER_FAIL',
          errorMessage: (await response.json()).errorMessage,
        });
      }

      return dispatch({
        type: 'REGISTER_SUCCESS',
        accountType: 'google',
      });
    } catch (e) {
      console.error(e);
      return dispatch({
        type: 'REGISTER_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });
    }
  };
}

function registerFacebook(role, email, accessToken, facebookUserId) {
  email = email.trim();

  return async (dispatch) => {
    dispatch({ type: 'REGISTER_START' });

    let source = 'facebook';

    try {
      const response = await fetch(`${config.apiEndpoint}/api/users`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          role,
          source,
          email,
          accessToken,
          facebookUserId,
        }),
      });

      if (!response.ok) {
        return dispatch({
          type: 'REGISTER_FAIL',
          errorMessage: (await response.json()).errorMessage,
        });
      }

      return dispatch({
        type: 'REGISTER_SUCCESS',
        accountType: 'facebook',
      });
    } catch (e) {
      console.error(e);
      return dispatch({
        type: 'REGISTER_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });
    }
  };
}

function verifyRegistration(email: string, verificationCode: string) {
  return async (dispatch) => {
    dispatch({ type: 'VERIFY_REGISTRATION_START' });

    try {
      const response = await fetch(`${config.apiEndpoint}/api/users/verify`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
          verificationCode,
        }),
      });

      if (!response.ok) {
        return dispatch({
          type: 'VERIFY_REGISTRATION_FAIL',
          errorMessage: (await response.json()).message,
        });
      }

      const loginToken = (await response.json()).token;

      return dispatch({
        type: 'VERIFY_REGISTRATION_SUCCESS',
        loginToken,
      });
    } catch (e) {
      console.error(e);
      return dispatch({
        type: 'VERIFY_REGISTRATION_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });
    }
  };
}

function sendPasswordResetEmail(email: string) {
  return async (dispatch) => {
    dispatch({ type: 'SEND_PASSWORD_RESET_EMAIL_START' });

    try {
      const response = await fetch(
        `${config.apiEndpoint}/api/password/send-reset-email?email=${email}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (!response.ok) {
        return dispatch({
          type: 'SEND_PASSWORD_RESET_EMAIL_FAIL',
          errorMessage: (await response.json()).message,
        });
      }

      dispatch({ type: 'SEND_PASSWORD_RESET_EMAIL_SUCCESS' });
    } catch (e) {
      console.error(e);
      return dispatch({
        type: 'SEND_PASSWORD_RESET_EMAIL_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });
    }
  };
}


function sendFBPasswordGenerateEmail(email: string) {
  return async (dispatch) => {
    dispatch({ type: 'SEND_PASSWORD_RESET_EMAIL_START' });

    try {
      const response = await fetch(
        `${config.apiEndpoint}/api/password/send-fb-email?email=${email}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (!response.ok) {
        return dispatch({
          type: 'SEND_PASSWORD_RESET_EMAIL_FAIL',
          errorMessage: (await response.json()).message,
        });
      }

      dispatch({ type: 'SEND_PASSWORD_RESET_EMAIL_SUCCESS' });
    } catch (e) {
      console.error(e);
      return dispatch({
        type: 'SEND_PASSWORD_RESET_EMAIL_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });
    }
  };
}


function resetPassword(email, code) {
  return async (dispatch) => {
    dispatch({ type: 'RESET_PASSWORD_START' });

    const url = new URL('api/password/reset', config.apiEndpoint);
    url.searchParams.set('email', email);
    url.searchParams.set('code', code);

    try {
      const response = await fetch(url.href, { method: 'GET' });

      if (!response.ok) {
        return dispatch({
          type: 'RESET_PASSWORD_FAIL',
          errorMessage: (await response.json()).message,
        });
      }

      dispatch({ type: 'RESET_PASSWORD_SUCCESS' });
    } catch (e) {
      console.error(e);
      return dispatch({
        type: 'RESET_PASSWORD_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });
    }
  };
}

function changePasswordModalOpen() {
  return { type: 'CHANGE_PASSWORD_MODAL_OPEN' };
}

function changePasswordModalClose() {
  return { type: 'CHANGE_PASSWORD_MODAL_CLOSE' };
}

function changePassword(currentPassword, newPassword) {
  return async (dispatch) => {
    dispatch({ type: 'CHANGE_PASSWORD_START' });

    try {
      const response = await fetch(`${config.apiEndpoint}/api/users/me/password`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          currentPassword,
          newPassword,
        }),
      });

      if (!response.ok) {
        return dispatch({
          type: 'CHANGE_PASSWORD_FAIL',
          errorMessage: (await response.json()).message,
        });
      }

      dispatch({ type: 'CHANGE_PASSWORD_SUCCESS' });
      toast.success('Password changed successfully');
    } catch (e) {
      console.error(e);
      return dispatch({
        type: 'CHANGE_PASSWORD_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });
    }
  };
}

function refreshPasswordResetStatus() {
  return {
    type: 'RESET_PASSWORD_REFRESH_STATUS',
  };
}

function createCustomerReview(cycleId, testerId, rating, text) {
  return async (dispatch) => {
    dispatch({ type: 'SUBMIT_CUSTOMER_REVIEW_START' });

    const responseResult = await authorizedFetch(
      `${config.apiEndpoint}/api/cycles/${cycleId}/testers/${testerId}/customerReviews`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          rating,
          text,
        }),
      }
    );

    if (!responseResult.isSuccess) {
      return dispatch({
        type: 'SUBMIT_CUSTOMER_REVIEW_FAIL',
        errorMessage: responseResult.error,
      });
    }

    dispatch({
      type: 'SUBMIT_CUSTOMER_REVIEW_SUCCES',
      cycleId,
      testerId,
    });

    toast.success('Review sumbitted successfully');
  };
}

function applyForCycle(cycleId: number, testerBid: number) {
  return async (dispatch) => {
    const userIdResult = getUserIdFromToken();

    if (!userIdResult.isSuccess) {
      toast.error('Missing session token. Please re-login');
      return dispatch({
        type: 'TOKEN_INVALID',
      });
    }

    const userId = userIdResult.getValue();

    dispatch({ type: 'ACCEPT_CYCLE_START' });

    // TODO /me instead of /users/userId
    const responseResult = await authorizedFetch(
      `${config.apiEndpoint}/api/users/${userId}/apply-for-cycle`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          cycleId,
          testerBid,
        }),
      }
    );

    if (!responseResult.isSuccess) {
      return dispatch({
        type: 'ACCEPT_CYCLE_FAIL',
        errorMessage: responseResult.error,
      });
    }

    dispatch({
      type: 'ACCEPT_CYCLE_SUCCESS',
      cycleId,
    });

    dispatch(setStatus('cycleAcceptStatus', OperationStatus.notStarted));
  };
}

function completeCycle(cycleId) {
  return async (dispatch) => {
    const userIdResult = getUserIdFromToken();

    if (!userIdResult.isSuccess) {
      toast.error('Missing session token. Please re-login');
      return dispatch({
        type: 'TOKEN_INVALID',
      });
    }

    const userId = userIdResult.getValue();

    const responseResult = await authorizedFetch(
      `${config.apiEndpoint}/api/users/${userId}/complete-cycle`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cycleId }),
      }
    );

    if (!responseResult.isSuccess) {
      return dispatch({
        type: 'COMPLETE_CYCLE_FAIL',
        errorMessage: responseResult.error,
      });
    }

    dispatch({
      type: 'COMPLETE_CYCLE_SUCCESS',
      cycleId,
    });

  };
}

function updateUser(user) {
  return {
    type: 'UPDATE_USER',
    user,
  };
}

function requestVerifyPhone(userData: any) {
  return async (dispatch) => {
    const userIdResult = getUserIdFromToken();

    if (!userIdResult.isSuccess) {
      toast.error('Missing session token. Please re-login');
      return dispatch({
        type: 'TOKEN_INVALID',
      });
    }

    const userId = userIdResult.getValue();

    dispatch({ type: 'PHONE_NUM_SENT_START' });

    try {
      const response = await fetch(
        `${config.apiEndpoint}/api/users/${userId}/setPhoneNumber`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
            mode: 'cors',
          },
          body: JSON.stringify(userData),
        }
      );

      if (response.status !== 201 && response.status !== 200) {

        if (response.status === 401 || response.status === 403) {
          return dispatch({
            type: 'UNAUTHORIZED_REQUEST',
          });
        }

        return dispatch({
          type: 'PHONE_NUM_SENT_FAIL',
          errorMessage: (await response.json()).errorMessage,
        });

      }

      const phoneSetRes = await response.json();

      dispatch({
        type: 'PHONE_NUM_SENT_SUCCESS',
        phoneSetRes
      });

    }
    catch {
      dispatch({
        type: 'PHONE_NUM_SENT_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });
    }
  }
}


function confirmSMS(token: number) {
  return async (dispatch) => {
    const userIdResult = getUserIdFromToken();

    if (!userIdResult.isSuccess) {
      toast.error('Missing session token. Please re-login');
      return dispatch({
        type: 'TOKEN_INVALID',
      });
    }

    const userId = userIdResult.getValue();

    dispatch({ type: 'CONFIRM_SMS_START' });

    try {
      const response = await fetch(
        `${config.apiEndpoint}/api/users/${userId}/confirmSMS`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
            mode: 'cors',

          },
          body: JSON.stringify({ token }),
        }
      );

      if (response.status !== 201 && response.status !== 200) {

        if (response.status === 401 || response.status === 403) {
          return dispatch({
            type: 'UNAUTHORIZED_REQUEST',
          });
        }

        dispatch({
          type: 'CONFIRM_SMS_FAIL',
          errorMessage: (await response.json()).errorMessage
        });

      }

      const responseResult = await response.json();

      dispatch({
        type: 'CONFIRM_SMS_SUCCESS',
        userData: responseResult,
      });

    }
    catch {
      dispatch({
        type: 'CONFIRM_SMS_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });
    }
  }
}

function saveUser(userData: any, notify: boolean = false) {
  return async (dispatch) => {
    const userIdResult = getUserIdFromToken();

    if (!userIdResult.isSuccess) {
      toast.error('Missing session token. Please re-login');
      return dispatch({
        type: 'TOKEN_INVALID',
      });
    }

    const userId = userIdResult.getValue();

    dispatch({ type: 'SAVE_USER_START' });

    const responseResult = await authorizedFetch(`${config.apiEndpoint}/api/users/${userId}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(userData),
    });

    if (!responseResult.isSuccess) {
      return dispatch({
        type: 'SAVE_USER_FAIL',
        errorMessage: responseResult.error,
      });
    }

    dispatch({
      type: 'SAVE_USER_SUCCESS',
      userData: await responseResult.getValue().json(),
    });

    if (notify) {
      toast.success('Profile saved');
    }
  };
}

function uploadAvatar(avatarFile) {
  return async (dispatch) => {
    const userIdResult = getUserIdFromToken();

    if (!userIdResult.isSuccess) {
      toast.error('Missing session token. Please re-login');
      return dispatch({
        type: 'TOKEN_INVALID',
      });
    }

    const userId = userIdResult.getValue();

    const formData = new FormData();
    formData.append('avatarFile', avatarFile);

    dispatch({ type: 'UPLOAD_AVATAR_START' });

    const responseResult = await authorizedFetch(
      `${config.apiEndpoint}/api/users/${userId}/avatar`,
      {
        method: 'PATCH',
        body: formData,
      }
    );

    if (!responseResult.isSuccess) {
      return dispatch({
        type: 'UPLOAD_AVATAR_FAIL',
        errorMessage: responseResult.error,
      });
    }

    dispatch({
      type: 'UPLOAD_AVATAR_SUCCESS',
      avatarUrl: avatarFile.preview,
    });
  };
}

function listAssociatedCustomers(userId) {
  return async (dispatch) => {

    dispatch({ type: 'LIST_ASSOCIATED_START' });

    const responseResult = await authorizedFetch(
      `${config.apiEndpoint}/api/affiliate/${userId}/associated`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });

    if (!responseResult.isSuccess) {
      return dispatch({
        type: 'LIST_ASSOCIATED_FAIL',
        errorMessage: responseResult.error,
      });
    }

    const associated = await responseResult.getValue().json();

    dispatch({
      type: 'LIST_ASSOCIATED_SUCCESS',
      acssociatedCustomers: associated,
    });
  };
}

function registerAffiliate(userId) {
  return async (dispatch) => {

    dispatch({ type: 'REGISTER_AFFILIATE_START' });

    const responseResult = await authorizedFetch(
      `${config.apiEndpoint}/api/affiliate/${userId}/register`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });

    if (!responseResult.isSuccess) {
      return dispatch({
        type: 'REGISTER_AFFILIATE_FAIL',
        errorMessage: responseResult.error,
      });
    }

    let { couponCode } = await responseResult.getValue().json();

    dispatch({
      type: 'REGISTER_AFFILIATE_SUCCESS',
      affiliatePromo: couponCode,
    });
  };
}



function listOwnCustomerReviews() {
  const token = localStorage.getItem('token'); // TODO DRY token extraction

  return async (dispatch) => {
    try {
      const response = await fetch(`${config.apiEndpoint}/api/users/me/customerReviews`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          mode: 'cors',
        },
      });

      if (response.status > 299) {
        if (response.status === 401 || response.status === 403) {
          return dispatch({
            type: 'UNAUTHORIZED_REQUEST',
          });
        }

        return dispatch({
          type: 'LIST_CUSTOMER_REVIEWS_FAIL', // TODO handle
          errorMessage: (await response.json()).message,
        });
      }

      return dispatch({
        type: 'LIST_CUSTOMER_REVIEWS_SUCCESS',
        customerReviews: await response.json(),
      });
    } catch (e) {
      return dispatch({
        type: 'LIST_CUSTOMER_REVIEWS_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });
    }
  };
}

function listOwnFinance() {
  const token = localStorage.getItem('token'); // TODO DRY token extraction

  return async (dispatch) => {
    dispatch({ type: 'LIST_FINANCE_START' });

    try {
      const response = await fetch(`${config.apiEndpoint}/api/users/me/finance`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          mode: 'cors',
        },
      });

      if (response.status > 299) {
        if (response.status === 401 || response.status === 403) {
          return dispatch({
            type: 'UNAUTHORIZED_REQUEST',
          });
        }

        return dispatch({
          type: 'LIST_FINANCE_FAIL',
          errorMessage: (await response.json()).message,
        });
      }

      return dispatch({
        type: 'LIST_FINANCE_SUCCESS',
        finance: await response.json(),
      });
    } catch (e) {
      return dispatch({
        type: 'LIST_FINANCE_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });
    }
  };
}

function getDevices() {
  return async (dispatch) => {
    const response = await fetch(`${config.apiEndpoint}/api/devices`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
        mode: 'cors',
      },
    });

    if (!response.ok) {
      return dispatch({
        type: 'GET_DEVICES_FAIL', // TODO handle
        errorMessage: await response.json(),
      });
    }

    return dispatch({
      type: 'GET_DEVICES_SUCCESS',
      devices: await response.json(),
    });
  };
}

function addDevice(data) {
  return async (dispatch) => {
    const userIdResult = getUserIdFromToken();

    if (!userIdResult.isSuccess) {
      toast.error('Missing session token. Please re-login');
      return dispatch({
        type: 'TOKEN_INVALID',
      });
    }

    const userId = userIdResult.getValue();

    dispatch({ type: 'ADD_DEVICE_START' });

    try {
      const response = await fetch(`${config.apiEndpoint}/api/users/${userId}/devices`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.status !== 200) {
        if (response.status === 401 || response.status === 403) {
          return dispatch({
            type: 'UNAUTHORIZED_REQUEST',
          });
        }

        return dispatch({
          type: 'ADD_DEVICE_FAIL',
          errorMessage: (await response.json()).message,
        });
      }

      return dispatch({
        type: 'ADD_DEVICE_SUCCESS',
        device: data,
      });
    } catch (e) {
      return dispatch({
        type: 'ADD_DEVICE_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });
    }
  };
}

function deleteDevice(data) {
  return async (dispatch) => {
    const userIdResult = getUserIdFromToken();

    if (!userIdResult.isSuccess) {
      toast.error('Missing session token. Please re-login');
      return dispatch({
        type: 'TOKEN_INVALID',
      });
    }

    const userId = userIdResult.getValue();

    dispatch({ type: 'DELETE_DEVICE_START' });

    try {
      const response = await fetch(`${config.apiEndpoint}/api/users/${userId}/devices`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.status !== 200) {
        if (response.status === 401 || response.status === 403) {
          return dispatch({
            type: 'UNAUTHORIZED_REQUEST',
          });
        }

        return dispatch({
          type: 'DELETE_DEVICE_FAIL',
          errorMessage: (await response.json()).message,
        });
      }

      return dispatch({
        type: 'DELETE_DEVICE_SUCCESS',
        device: data,
      });
    } catch (e) {
      return dispatch({
        type: 'DELETE_DEVICE_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });
    }
  };
}

function getBrowsers() {
  return async (dispatch) => {
    dispatch({ type: 'GET_BROWSERS_START' });

    try {
      const response = await fetch(`${config.apiEndpoint}/api/browsers`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        return dispatch({
          type: 'GET_BROWSERS_FAIL', // TODO handle
          errorMessage: (await response.json()).message,
        });
      }

      return dispatch({
        type: 'GET_BROWSERS_SUCCESS',
        browsers: await response.json(),
      });
    } catch (e) {
      return dispatch({
        type: 'GET_BROWSERS_FAIL', // TODO again, handle
        errorMessage: 'Unable to connect. Please try again later',
      });
    }
  };
}

function addBrowser(data) {
  return async (dispatch) => {
    const userIdResult = getUserIdFromToken();

    if (!userIdResult.isSuccess) {
      toast.error('Missing session token. Please re-login');
      return dispatch({
        type: 'TOKEN_INVALID',
      });
    }

    const userId = userIdResult.getValue();

    dispatch({ type: 'ADD_BROWSER_START' });

    try {
      const response = await fetch(`${config.apiEndpoint}/api/users/${userId}/browsers`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.status !== 200) {
        if (response.status === 401 || response.status === 403) {
          return dispatch({
            type: 'UNAUTHORIZED_REQUEST',
          });
        }

        return dispatch({
          type: 'ADD_BROWSER_FAIL',
          errorMessage: (await response.json()).message,
        });
      }

      return dispatch({
        type: 'ADD_BROWSER_SUCCESS',
        browser: data,
      });
    } catch (e) {
      return dispatch({
        type: 'ADD_BROWSER_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });
    }
  };
}

function deleteBrowser(data) {
  return async (dispatch) => {
    const userIdResult = getUserIdFromToken();

    if (!userIdResult.isSuccess) {
      toast.error('Missing session token. Please re-login');
      return dispatch({
        type: 'TOKEN_INVALID',
      });
    }

    const userId = userIdResult.getValue();

    dispatch({ type: 'DELETE_BROWSER_START' });

    try {
      const response = await fetch(`${config.apiEndpoint}/api/users/${userId}/browsers`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.status !== 200) {
        if (response.status === 401 || response.status === 403) {
          return dispatch({
            type: 'UNAUTHORIZED_REQUEST',
          });
        }

        return dispatch({
          type: 'DELETE_BROWSER_FAIL',
          errorMessage: (await response.json()).message,
        });
      }

      return dispatch({
        type: 'DELETE_BROWSER_SUCCESS',
        browser: data,
      });
    } catch (e) {
      return dispatch({
        type: 'DELETE_BROWSER_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });
    }
  };
}

function listChatMessages(cycleId, participantId) {
  return async (dispatch) => {
    dispatch({ type: 'LIST_CHAT_MESSAGES_START' });

    try {
      const response = await fetch(
        `${config.apiEndpoint}/api/cycles/${cycleId}/messages?participantId=${participantId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status !== 200) {
        if (response.status === 401 || response.status === 403) {
          return dispatch({
            type: 'UNAUTHORIZED_REQUEST',
          });
        }

        return dispatch({
          type: 'LIST_CHAT_MESSAGES_FAIL',
          errorMessage: (await response.json()).message,
        });
      }

      const chatMessages = (await response.json()) || [];

      return dispatch({
        type: 'LIST_CHAT_MESSAGES_SUCCESS',
        cycleId,
        chatMessages,
      });
    } catch (e) {
      return dispatch({
        type: 'LIST_CHAT_MESSAGES_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });
    }
  };
}

function markMessagesAsRead(cycleId, participantId) {
  return async (dispatch) => {
    dispatch({ type: 'MARK_CHAT_MESSAGES_AS_READ' });

    try {
      const response = await fetch(
        `${config.apiEndpoint}/api/cycles/${cycleId}/messages?participantId=${participantId}`,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            read: true,
          }),
        }
      );

      if (response.status !== 200) {
        if (response.status === 401 || response.status === 403) {
          return dispatch({
            type: 'UNAUTHORIZED_REQUEST',
          });
        }

        return dispatch({
          type: 'MARK_CHAT_MESSAGES_AS_READ_FAIL',
          errorMessage: (await response.json()).message,
        });
      }

      return dispatch({
        type: 'MARK_CHAT_MESSAGES_AS_READ_SUCCESS',
        participantId,
        cycleId,
      });
    } catch (e) {
      return dispatch({
        type: 'LIST_CHAT_MESSAGES_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });
    }
  };
}

function sendChatMessage(cycleId: number, participantId: number | 'all', messageText: string) {
  return async (dispatch) => {
    dispatch({ type: 'SEND_CHAT_MESSAGE_START' });

    const payload: Record<string, any> = { messageText };

    if (participantId !== 'all') {
      payload.receiverId = participantId;
    }

    try {
      const response = await fetch(`${config.apiEndpoint}/api/cycles/${cycleId}/messages`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.status !== 200) {
        if (response.status === 401 || response.status === 403) {
          return dispatch({
            type: 'UNAUTHORIZED_REQUEST',
          });
        }

        return dispatch({
          type: 'SEND_CHAT_MESSAGE_FAIL',
          errorMessage: (await response.json()).message,
        });
      }

      if (participantId !== 'all') {
        const message = await response.json();

        dispatch({
          type: 'SEND_CHAT_MESSAGE_SUCCESS',
          participantId,
          message,
          cycleId,
        });
      } else {
        const messages = await response.json();
        dispatch({
          type: 'SEND_CHAT_MESSAGE_SUCCESS',
          message: messages[0],
          cycleId,
        });
      }
    } catch (e) {
      dispatch({
        type: 'SEND_CHAT_MESSAGE_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });
    }
  };
}

function createBug(cycleId, bugData) {
  return async (dispatch) => {
    dispatch({ type: 'CREATE_BUG_START' });

    try {
      const formData = new FormData();
      formData.append('data', JSON.stringify(bugData.bugProps));

      if (bugData.files.imageFiles && bugData.files.imageFiles.length) {
        bugData.files.imageFiles.forEach((file) => {
          formData.append('imageFiles', file);
        });
      }

      if (bugData.files.videoFiles && bugData.files.videoFiles.length) {
        bugData.files.videoFiles.forEach((file) => {
          formData.append('videoFiles', file);
        });
      }

      const response = await fetch(`${config.apiEndpoint}/api/cycles/${cycleId}/bugs`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          /* Don't do 'Content-Type': 'multipart/form-data', it breaks fetch */
        },
        body: formData,
      });

      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          return dispatch({
            type: 'UNAUTHORIZED_REQUEST',
          });
        }

        dispatch({
          type: 'CREATE_BUG_FAIL',
          errorMessage: await response.json(),
        });
        toast.error('Bug report submission failed');
        return;
      }

      dispatch({
        type: 'CREATE_BUG_SUCCESS',
        bug: await response.json(),
        cycleId,
      });

      toast.success('Bug report submitted successfully');
    } catch (e) {
      console.error(e);
      dispatch({
        type: 'CREATE_BUG_FAIL',
        errorMessage: 'Unable to connect. Please try again later',
      });
      toast.error('Bug report submission failed');
    }
  };
}

function updateBug(cycleId, bugId, bugData) {
  return async (dispatch) => {
    dispatch({
      type: 'EDIT_BUG_START',
      status: bugData.bugProps.status,
    });

    try {
      const formData = new FormData();
      formData.append('data', JSON.stringify(bugData.bugProps));

      if (bugData.files) {
        if (bugData.files.imageFiles && bugData.files.imageFiles.length) {
          bugData.files.imageFiles.forEach((file) => {
            formData.append('imageFiles', file);
          });
        }

        if (bugData.files.videoFiles && bugData.files.videoFiles.length) {
          bugData.files.videoFiles.forEach((file) => {
            formData.append('videoFiles', file);
          });
        }
      }

      const response = await fetch(`${config.apiEndpoint}/api/cycles/${cycleId}/bugs/${bugId}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          /* Don't do 'Content-Type': 'multipart/form-data', it breaks fetch */
        },
        body: formData,
      });

      if (!response.ok) {
        const errorPayload = await response.json();

        if (response.status === 403) {
          if (errorPayload?.message === 'Token expired') {
            toast.error('Session expired. Please login again');
            return dispatch({
              type: 'TOKEN_EXPIRED',
            });
          } else {
            toast.error('Unauthorized request');
            return dispatch({
              type: 'UNAUTHORIZED_REQUEST',
            });
          }
        }

        if (response.status === 500) {
          toast.error('An error occured ');
          return dispatch({
            type: 'SERVER_ERROR',
          });
        }

        return dispatch({
          type: 'EDIT_BUG_FAIL',
          errorMessage: await response.json(),
        });
      }

      const bug = await response.json();

      toast.success('Bug saved successfully');

      dispatch({
        type: 'EDIT_BUG_SUCCESS',
        cycleId,
        bug,
      });
    } catch (e) {
      console.error(e);
      return dispatch({ type: 'EDIT_BUG_FAIL' });
    }
  };
}

function deleteBug(cycleId, bugId) {
  return async (dispatch) => {
    dispatch({ type: 'DELETE_BUG_START' });

    try {
      const response = await fetch(`${config.apiEndpoint}/api/cycles/${cycleId}/bugs/${bugId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          return dispatch({
            type: 'UNAUTHORIZED_REQUEST',
          });
        }

        return dispatch({
          type: 'DELETE_BUG_FAIL',
          errorMessage: await response.json(),
        });
      }

      return dispatch({
        type: 'DELETE_BUG_SUCCESS',
        cycleId,
        bugId,
      });
    } catch (e) {
      return dispatch({ type: 'DELETE_BUG_FAIL' });
    }
  };
}

function addTesterToFavorites(testerId) {
  return async (dispatch) => {
    const userIdResult = getUserIdFromToken();

    if (!userIdResult.isSuccess) {
      toast.error('Missing session token. Please re-login');
      return dispatch({
        type: 'TOKEN_INVALID',
      });
    }

    const userId = userIdResult.getValue();

    dispatch({ type: 'ADD_TESTER_TO_FAVORITES_START' });

    try {
      const response = await fetch(
        `${config.apiEndpoint}/api/users/${userId}/favoriteTesters/${testerId}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      if (response.status !== 201) {
        if (response.status === 401 || response.status === 403) {
          return dispatch({
            type: 'UNAUTHORIZED_REQUEST',
          });
        }

        return dispatch({
          type: 'ADD_TESTER_TO_FAVORITES_FAIL',
          errorMessage: await response.json(),
        });
      }

      return dispatch({
        type: 'ADD_TESTER_TO_FAVORITES_SUCCESS',
        tester: await response.json(),
      });
    } catch (e) {
      return dispatch({ type: 'ADD_TESTER_TO_FAVORITES_FAIL' });
    }
  };
}

function removeTesterFromFavorites(testerId) {
  return async (dispatch) => {
    const userIdResult = getUserIdFromToken();

    if (!userIdResult.isSuccess) {
      toast.error('Missing session token. Please re-login');
      return dispatch({
        type: 'TOKEN_INVALID',
      });
    }

    const userId = userIdResult.getValue();

    dispatch({ type: 'REMOVE_TESTER_FROM_FAVORITES_START' });

    try {
      const response = await fetch(
        `${config.apiEndpoint}/api/users/${userId}/favoriteTesters/${testerId}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      if (response.status !== 200) {
        if (response.status === 401 || response.status === 403) {
          return dispatch({
            type: 'UNAUTHORIZED_REQUEST',
          });
        }

        return dispatch({
          type: 'REMOVE_TESTER_FROM_FAVORITES_FAIL',
          errorMessage: await response.json(),
        });
      }

      return dispatch({
        type: 'REMOVE_TESTER_FROM_FAVORITES_SUCCESS',
        testerId,
      });
    } catch (e) {
      return dispatch({ type: 'REMOVE_TESTER_FROM_FAVORITES_FAIL' });
    }
  };
}

function updateNewBug(newBug) {
  return {
    type: 'NEW_BUG_UPDATE',
    newBug,
  };
}

function updateEditBug(newBug) {
  return {
    type: 'EDIT_BUG_UPDATE',
    newBug,
  };
}

function withdraw() {
  return async (dispatch) => {
    dispatch({ type: 'WITHDRAWAL_START' });

    try {
      const response = await fetch(`${config.apiEndpoint}/api/users/me/payouts`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        toast.success('Withdrawal failed. Please contact Customer Support');
        dispatch({ type: 'WITHDRAWAL_FAIL' });
      } else {
        dispatch(listOwnFinance());
        toast.success('Withdrawal successful! Check your Paypal account');
        dispatch({ type: 'WITHDRAWAL_SUCCESS' });
      }
    } catch (e) {
      dispatch({ type: 'WITHDRAWAL_FAIL' });
    }
  };
}

function sendContactFormMessage(payload) {
  return async (dispatch) => {
    dispatch({ type: 'SUBMIT_CONTACT_FORM_START' });

    await sleep(2000);

    try {
      const response = await fetch(`${config.apiEndpoint}/api/contactFormMessage`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        dispatch({ type: 'SUBMIT_CONTACT_FORM_FAIL' });
        toast.error('Unable to send message');
      } else {
        dispatch({ type: 'SUBMIT_CONTACT_FORM_SUCCESS' });
        toast.success('Message sent');
        dispatch({ type: 'SUBMIT_CONTACT_FORM_SUCCESS' });
      }
    } catch (e) {
      console.error(e);
      toast.error('Unable to send message');
      dispatch({ type: 'SUBMIT_CONTACT_FORM_FAIL' });
    }
  };
}

function getUserIdFromToken(): Result<number> {
  try {
    const token = <string>localStorage.getItem('token');

    if (!token) {
      toast.error('Missing session token. Please re-login');
      throw new Error('Missing token');
    }

    const decoded = <Record<string, any>>jwt.decode(token);
    return Result.ok(decoded.id!);
  } catch (e) {
    console.error(e);
    return Result.fail(e);
  }
}

async function authorizedFetch(url: string, params?: any): Promise<Result<any>> {
  const token = <string>localStorage.getItem('token');

  if (!token) {
    return Result.fail('No session token');
  }

  try {
    if (!params) {
      params = {};
    }

    if (!params.headers) {
      params.headers = {};
    }

    if (!params.headers['Authorization']) {
      params.headers['Authorization'] = `Bearer ${token}`;
    }

    const response = await fetch(url, params);

    if (!response.ok) {
      const errorPayload = await response.json();

      if (response.status === 403) {
        if (errorPayload?.message === 'Token expired') {
          toast.error('Session expired. Please login again');
          return Result.fail('TOKEN_EXPIRED');
        } else {
          toast.error('Unauthorized request');
          return Result.fail('UNAUTHORIZED_REQUEST');
        }
      }

      if (response.status === 500) {
        toast.error('An error occured');
        return Result.fail('SERVER_ERROR');
      }

      return Result.fail(response);
    }

    return Result.ok(response);
  } catch (e) {
    console.error('authorizedFetch:', e);
    toast.error('An error occured');
    return Result.fail('SERVER_ERROR');
  }
}

export default {
  toggleSidebar,
  login,
  loginFacebook,
  loginGoogle,
  loginModalOpen,
  loginModalClose,
  registerModalOpen,
  registerModalClose,
  changePasswordModalOpen,
  changePasswordModalClose,
  impersonate,
  checkLogin,
  logout,
  register,
  registerFacebook,
  registerGoogle,
  verifyRegistration,
  sendPasswordResetEmail,
  sendFBPasswordGenerateEmail,
  resetPassword,
  changePassword,
  refreshPasswordResetStatus,
  updateUser,
  saveUser,
  uploadAvatar,
  listOwnCustomerReviews,
  listOwnFinance,
  getDevices,
  addDevice,
  deleteDevice,
  getBrowsers,
  addBrowser,
  deleteBrowser,
  createCustomerReview,
  applyForCycle,
  completeCycle,
  listChatMessages,
  markMessagesAsRead,
  sendChatMessage,
  createBug,
  updateBug,
  deleteBug,
  updateNewBug,
  updateEditBug,
  addTesterToFavorites,
  removeTesterFromFavorites,
  withdraw,
  sendContactFormMessage,
  confirmSMS,
  registerAffiliate,
  listAssociatedCustomers,
  requestVerifyPhone,
};
