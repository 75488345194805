import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Loader, Message } from 'semantic-ui-react';
import actions from '../redux/actions';
import { OperationStatus } from '../types/OperationStatus';
import { UserRole } from '../types/UserRole';
import { UserStatus } from '../types/UserStatus';

const mapStateToProps = (state) => {
  return {
    registerVerificationStatus: state.registerVerificationStatus,
    loginStatus: state.loginStatus,
    loginToken: state.loginToken,
    user: state.user,
    device: state.resolution.device,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifyRegistration: (email, code) => dispatch(actions.verifyRegistration(email, code)),
    impersonate: (loginToken) => dispatch(actions.impersonate(loginToken)),
  };
};

class RegisterConfirm extends Component<any> {
  public state: any;

  constructor(props) {
    super(props);
    this.state = {
      accountType: '',
      email: '',
      code: '',
      verificationStatus: '',
    };
  }

  componentDidMount() {
    const url = new URL(window.location.href);
    const accountType = url.searchParams.get('accountType');
    const email = url.searchParams.get('email');
    const code = url.searchParams.get('code');

    this.setState({
      accountType,
      email,
      code,
    });

    if (
      this.props.user.role === UserRole.Anonymous ||
      this.props.user.status === UserStatus.EmailPending
    ) {
      if (email && code) {
        this.props.verifyRegistration(email, code);
      }
    } else {
      this.props.history.push('/account');
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.registerVerificationStatus.status !== prevProps.registerVerificationStatus.status
    ) {
      if (this.props.registerVerificationStatus.status === OperationStatus.success) {
        this.props.history.push('/register-approved');
        //this.props.impersonate(this.props.loginToken);
        return;
      }
    }

    if (this.props.loginStatus.status !== prevProps.loginStatus.status) {
      if (this.props.loginStatus.status === OperationStatus.success) {
        this.props.history.push('/complete-profile/personal');
      }
    }
  }

  render() {

    const isMobile = this.props.device === 'mobile' || this.props.device === 'mobile-tablet';

    //For FB verification both the Mobile and the regular view contains the extra header
    if (this.state.accountType === 'facebook') {
      return (
        <div className="registerconfirm page">
          <div className="heading">
            <h1>Account created</h1>
          </div>
          <div className="inner">
            <Message positive>
              <Message.Header>You Facebook account verified successfully!</Message.Header>
              <Message.Content>You can now login</Message.Content>
            </Message>
          </div>
        </div>
      );
    }

    if (this.state.accountType === 'google') {
      return (
        <div className="registerconfirm page">
          <div className="heading">
            <h1>Account created</h1>
          </div>
          <div className="inner">
            <Message positive>
              <Message.Header>You Google account verified successfully!</Message.Header>
              <Message.Content>You can now login</Message.Content>
            </Message>
          </div>
        </div>
      );
    }

    const email = this.state.email;
    const code = this.state.code;
    const status = this.props.registerVerificationStatus.status;
    const errorMessage = this.props.registerVerificationStatus.errorMessage;

    if (isMobile) {
      if (email && code) {
        return (
          <div className="registerconfirm page">
            <div className="inner">
              {status === OperationStatus.notStarted && <p>...</p>}

              {status === OperationStatus.processing && <Loader>Verifying...</Loader>}

              {status === OperationStatus.fail && (
                <Message negative>
                  <Message.Header>Unable to verify account</Message.Header>
                  <Message.Content>{errorMessage}</Message.Content>
                </Message>
              )}
            </div>
          </div>
        );
      } else if (email) {
        return (
          <div className="registerconfirm page">
            <div className="inner" style={{ marginLeft: 5, marginRight: 5 }}>
              <p className="email">
                Verification email has been sent to
                <span>{email}</span>
              </p>
              <p>Please check your inbox and follow the link in the message</p>
              <p>Double check your spam folder</p>
            </div>
          </div>
        );
      } else {
        return null;
      }
    }

    if (email && code) {
      return (
        <div className="registerconfirm page">
          <div className="heading">
            <h1>Account verification</h1>
          </div>
          <div className="inner">
            {status === OperationStatus.notStarted && <p>...</p>}

            {status === OperationStatus.processing && <Loader>Verifying...</Loader>}

            {status === OperationStatus.fail && (
              <Message negative>
                <Message.Header>Unable to verify account</Message.Header>
                <Message.Content>{errorMessage}</Message.Content>
              </Message>
            )}
          </div>
        </div>
      );
    } else if (email) {
      return (
        <div className="registerconfirm page">
          <div className="heading">
            <h1>Your email needs verification</h1>
          </div>
          <div className="inner">
            <p className="email">
              Verification email has been sent to
              <span>{email}</span>
            </p>
            <p>Please check your inbox and follow the link in the message</p>
            <p>Double check your spam folder</p>
          </div>
        </div>
      );
    } else {
      return null;

      // return (
      //     <div className = 'registerconfirm page'>
      //         <div className = 'heading'>
      //             <h1>Account verification</h1>
      //         </div>
      //         <div className = 'inner'>
      //             <Message negative>
      //                 <Icon name = 'exclamation circle' />
      //                 Email not provided
      //             </Message>
      //         </div>
      //     </div>
      // );
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterConfirm));
