import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'semantic-ui-react';
import { TesterReviewForm } from './TesterReviewForm';
import actions from '../../../../redux/actions';
import { UserRole } from '../../../../types/UserRole';

const mapStateToProps = (state) => {
  return {
    user: state.user,
    cycleTesters: state.cycleTesters,
    deviceModels: state.devices.models,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitCustomerReview: (testerId, cycleId, rating, text) =>
      dispatch(actions.createCustomerReview(cycleId, testerId, rating, text)),
  };
};

class TesterReviewModal extends Component<any> {
  public state: any;

  constructor(props) {
    super(props);

    this.state = {
      cycleId: props.cycleId,
      testerId: props.testerId,
      rating: 0,
      reviewText: '',
      testerName: '',
    };
  }

  componentDidMount() {
    if (Object.keys(this.props.cycleTesters.byCycleId[this.state.cycleId]).length) {
      const tester = this.props.cycleTesters.byCycleId[this.state.cycleId].find(
        (tester) => tester.id === this.props.testerId
      );
      this.setState({
        testerName: tester.firstName + ' ' + tester.lastName,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.testerId !== this.props.testerId) {
      const tester = this.props.cycleTesters.byCycleId[this.state.cycleId].find(
        (tester) => tester.id === this.props.testerId
      );
      this.setState({
        testerId: this.props.testerId,
        testerName: tester.firstName + ' ' + tester.lastName,
      });
    }
  }

  onOpen = () => {
    this.setState({ open: true });
  };

  onClose = () => {
    this.setState({
      open: false,
      rating: 0,
      reviewText: '',
    });
  };

  onInput = (event, data) => {
    this.setState({
      ...this.state,
      [data.name]: data.value,
    });
  };

  onRatingChange = (nextValue) => {
    this.setState({
      rating: nextValue,
    });
  };

  onReviewTextChange = (event, data) => {
    this.setState({
      reviewText: data.value,
    });
  };

  onSubmitReview = () => {
    this.props.submitCustomerReview(
      this.state.testerId,
      this.state.cycleId,
      this.state.rating,
      this.state.reviewText
    );
    this.onClose();
  };

  render() {
    return (
      <Modal
        open={this.state.open}
        onClose={this.onClose}
        size="small"
        trigger={<Button primary size="tiny" onClick={this.onOpen} content="Add review" />}
        closeIcon
      >
        <Modal.Header>Review tester {this.state.testerName}</Modal.Header>
        <Modal.Content>
          <TesterReviewForm
            isViewer={this.props.user.role === UserRole.Viewer}
            rating={this.state.rating}
            onRatingChange={this.onRatingChange}
            onReviewTextChange={this.onReviewTextChange}
            onSubmitReview={this.onSubmitReview}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TesterReviewModal);
