import Box from '@mui/material/Box';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import Button from '../../elements/Button';
import { RootState } from '../../redux/store';
import theme from '../../themes';
import { CycleStatus } from '../../types/CycleStatus';
import CycleList from '../../_components/accounts/customer/cycles/CycleList';
import Navigation from '../../_components/accounts/customer/Navigation';
import SideMenu from '../../_components/accounts/customer/SideMenu';

import { TESTERS_SELECTION_DURATION_HOURS } from '../../constants/accounts/common';
import plusSvg from '../../public/images/icons/plus-blue.svg';
import liveTestsImg from '../../public/images/live-tests.png';
import cycleActions from '../../redux/actions/cycleActions';


declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme { }
}


const useStyles = makeStyles(() => ({
    body: {
        height: 'calc(100vh - 97px)',
    },
    root: {
        height: 'calc(100vh - 97px)',
        border: `1px solid ${theme.palette.primary.light}`,
    },
    sidebar: {
        width: 380,
        padding: '15px 15px 0',
        borderLeft: `1px solid ${theme.palette.primary.light}`,
        borderRight: `1px solid ${theme.palette.primary.light}`,
    },
    sidebarCyclePlus: {
        height: 18,
        marginLeft: 10,
        marginRight: 20,
    },
    sidebarCreateCycle: {
        marginBottom: 20,
    },
    sidebarCyclesList: {
        width: '100%',
        overflowY: 'auto',
        padding: '0 10px 5px 0',
        maxHeight: 'calc(100% - 222px)',
    },
    liveTestsImg: {
        width: '100%',
        borderRadius: 8,
        marginBottom: 15,
    },
    children: {
        width: '100%',
        overflowY: 'auto',
        padding: '10px 30px',
        border: `1px solid ${theme.palette.primary.light}`,
    },
    cycleNotOnChildren: {
        width: '100%',
        height: '100%',
    },
    cycleNotOnTitle: {
        padding: 15,
        width: '100%',
        marginBottom: 60,
        borderBottom: `1px solid ${theme.palette.primary.light}`,
    },
    searchingTestersImg: {
        maxWidth: 320,
        marginTop: 60,
    },
    cycleNotOnDescription: {
        maxWidth: 530,
        marginTop: 60,
        lineHeight: 1.5,
    },
}));

interface ICustomerAccountLayout {
    renderNavigation?: boolean;
    renderSideMenu?: boolean;
    renderCycles?: boolean;
    emptyState?: boolean;
    children?: JSX.Element | string | null;
}

const CustomerAccountLayout: React.FC<ICustomerAccountLayout> = ({
    renderNavigation = true,
    renderSideMenu = true,
    renderCycles = true,
    emptyState = false,
    children = null,
}) => {
    const classes = useStyles();
    const cycleRef: any = useRef(null);

    const { cycleId } = useParams<{ cycleId: string }>();

    let { cyclesSlim, resolution } = useSelector((state: RootState) => state);
    cyclesSlim = cyclesSlim.filter((c: any) => c.status != CycleStatus.Draft);

    const cycle: any = cyclesSlim?.find((c: any) => c.id === +cycleId);

    const liveCycles = cyclesSlim.filter((c: any) => [CycleStatus.TestingAndSelection, CycleStatus.Testing].includes(c.status));

    const selectionCycles = cyclesSlim.filter((c: any) => [CycleStatus.Selection].includes(c.status));

    const completedCycles = cyclesSlim.filter((c: any) => c.status === CycleStatus.Completed);

    //Show the Cycle Navigation and stats, otherwise - the searching message
    const isCycleOn: boolean = [CycleStatus.TestingAndSelection, CycleStatus.Testing, CycleStatus.Completed].includes(cycle?.status);

    const isMobile = resolution.device === 'mobile' || resolution.device === 'mobile-tablet';
    if (isMobile) {
        renderCycles = false;
        renderSideMenu = false;
    }

    //if(!isCycleOn){
    const selectionWillExpireIn = moment(cycle?.startDate).add(TESTERS_SELECTION_DURATION_HOURS, 'hour');
    const timeDiff = selectionWillExpireIn.diff(moment());
    const isCountDown = !selectionWillExpireIn.isBefore(moment());
    //}

    const dispatch = useDispatch();
    let listCyclesInterval: any;

    useEffect(function setBodyHeight() {
        const bodyEl = document.body || document.querySelector('body');
        bodyEl.classList.add(classes.body);
        return () => {
            bodyEl.classList.remove(classes.body);
        };
    }, [classes.body]);

    useEffect(function fetchThoseCycles() {
        listCyclesInterval = setInterval(function () { dispatch(cycleActions.listCycles4CustomerSlim()) }, 10000);
        return () => {
            clearInterval(listCyclesInterval);
        };
    }, [cycleId]);

    const scrollToView = () => {
        cycleRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    };

    useEffect(scrollToView, [cycleId]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Box display="flex" className={classes.root}>
                    {renderSideMenu && (
                        <SideMenu />
                    )}

                    {renderCycles && (
                        <div className={classes.sidebar}>
                            <img
                                alt="Live Tests"
                                title="Live Tests"
                                src={liveTestsImg}
                                className={classes.liveTestsImg}
                            />

                            <Link to="/wizard">
                                <Button
                                    fullWidth inverted creation
                                    className={classes.sidebarCreateCycle}
                                >
                                    <Box display="flex" alignItems="center">
                                        <img
                                            alt="Plus"
                                            src={plusSvg}
                                            title="Add Test"
                                            className={classes.sidebarCyclePlus}
                                        />
                                        <Typography variant="body1" component="h2" color="primary">
                                            Create New Test
                                        </Typography>
                                    </Box>
                                </Button>
                            </Link>

                            <div className={classes.sidebarCyclesList}>
                                <CycleList
                                    cyclePhase={CycleStatus.Selection}
                                    cycles={selectionCycles}
                                    activeCycleId={cycleId}
                                    title="About to start"
                                    description={`You have ${selectionCycles.length} pending tests `}
                                    cycleRef={cycleRef}
                                />
                                <CycleList
                                    cyclePhase={CycleStatus.Testing}
                                    cycles={liveCycles}
                                    activeCycleId={cycleId}
                                    title="My live tests"
                                    description={`You have ${liveCycles.length} live tests`}
                                    cycleRef={cycleRef}
                                />
                                <CycleList
                                    cyclePhase={CycleStatus.Completed}
                                    activeCycleId={cycleId}
                                    cycles={completedCycles}
                                    title="Completed tests"
                                    description={`You have ${completedCycles.length} completed tests`}
                                    cycleRef={cycleRef}
                                />
                            </div>
                        </div>
                    )}

                    {emptyState && (
                        <div className={classes.children}>
                            {children}
                        </div>
                    )}

                    {!emptyState && isCycleOn && (
                        <div className={classes.children}>
                            {renderNavigation && (
                                <Navigation isCycleOn={isCycleOn} onCycleSelectionChanged={scrollToView} />
                            )}
                            {children}
                        </div>
                    )}

                    {/* Assuming it's a "Selection" cycle phase, or missed-deadline */}
                    {!emptyState && !isCycleOn && (
                        <div className={classes.cycleNotOnChildren}>
                            {renderNavigation && (
                                <Navigation isCycleOn={isCycleOn} onCycleSelectionChanged={scrollToView} />
                            )}
                            {children}
                        </div>
                    )}
                </Box>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default CustomerAccountLayout;