import { Box, Typography, Switch } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { Component } from 'react';
import { titleBlue } from './SharedStyle';

const useStyles = makeStyles((theme) =>
    createStyles({
        checker: {
            display: 'flex',
            marginTop: '10px',
        },
        innerTitle: {
            fontSize: '16px',
            color: titleBlue,
            fontWeight: 'normal',
            letterSpacing: '0.1px',
        }
    }),
);

const LabeledSwitcher = (props) => {
    const classes = useStyles();
    const { label, isChecked, handleChange } = props;
    return (
        <Box className={classes.checker}>
            <Typography align="center" style={{ marginTop: '7px' }} className={classes.innerTitle}>
                {label}
            </Typography>
            <Switch
                checked={isChecked}
                onChange={handleChange}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
        </Box>
    )
}

export default LabeledSwitcher;