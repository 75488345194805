import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Theme } from '@mui/material/styles';

import withStyles from '@mui/styles/withStyles';

const AntTabs = withStyles({
    indicator: {
      backgroundColor: '#1890ff',
    },
})(Tabs);

const AntTab = withStyles((theme: Theme) => ({
    root: {
      minWidth: 80,
      fontSize: '1rem',
      marginBottom: 8,
      marginRight: theme.spacing(6),      
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(0),        
      },
      fontWeight: theme.typography.fontWeightRegular,
      '&:hover': {
        color: '#40a9ff',
        opacity: 1,
      },
      '&$selected': {
        color: '#1890ff',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#40a9ff',
      },
      '&:disabled': {
        opacity: '0.4',
      },
    },
    selected: {},
  }),
)(Tab);

export default {
    Root: AntTabs,
    Item: AntTab,
};