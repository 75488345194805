import { blue, orange } from '@mui/material/colors';
import { createTheme, adaptV4Theme } from '@mui/material/styles';

const shared = {
    typography: {
        marginBottom: 4,
        marginTop: 4,
        letterSpacing: '0.1px',
        color: '#000000',
    },
};

const theme = createTheme(adaptV4Theme({ 
  palette: {
      primary: {
          main: blue[700],
          light: blue[50],
      },
      secondary: {
          main: orange[700],
      },
  },
  breakpoints: {
    values: {
      xs: 450,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: "Poppins",
    button: {
      textTransform: 'none',
    }
  },
  overrides: {
    MuiAccordion: {
      root: {
        '&::before': {
          display: 'none',
        },
      },
    },
    // MuiCardContent: {
    //   root: {
    //     padding: "8px 16px",
    //     '&:last-child': {
    //       paddingBottom: '8px',
    //     }
    //   },
    // },
    // MuiTooltip: {
    //   tooltip: {
    //     fontSize: '14px',
    //     //color: "yellow",
    //     //backgroundColor: "red"
    //   }
    // },    
    MuiTypography: {
        h1: {
            ...shared.typography,
            fontWeight: 700,
        },
        h2: {
            ...shared.typography,
            fontWeight: 700,
        },
        h3: {
            ...shared.typography,
            fontWeight: 600,
        },
        h4: {
          ...shared.typography,
          fontWeight: 500,
        },
        h5: {
          ...shared.typography,
        },
        h6: {
          ...shared.typography,
        },
    },
  },
}));
   


export default theme;