import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { NewWizardCycle } from '../../types/NewWizardCycle';
import { PayoutRatesAndTestersQuota, QuestionTimeCategory, SpecificationType } from '../../types/WizardCategoriesTypes';
import { WorkCategory } from '../../types/WorkCategory';


const bgColors = ['#62687F', '#FA982B', '#2CA8F7', '#9D1DB1', '#8AC441', '#0080D1'];
const chipColors = ['#9196A6', '#FCB76B', '#6CC2F9', '#BB64C9', '#ADD67A', '#6CC2F9'];
const titles = ['Blank', 'Speedtest', 'Before you launch', 'Before launch', 'New feature/version', 'Test it all']; //TODO: set names and props with Eran!!!

const useStyles = makeStyles((theme) => ({
	BaseStyle: {
		height: '165px',
		width: '100%',
		borderRadius: '8px',
		padding: 4,
		flexDirection: "row",
		justifyContent: 'flex-around',
		marginBottom: "25px",
	},
	title: {
		fontSize: '18px',
		color: '#ffffff',
		fontWeight: 'bold',
	},
	testerRate: {
		fontSize: '14px',
		color: '#ffffff',
	},
	ChipLabel: {
		'& .MuiChip-label': {
			fontSize: '13px',
			fontWeight: "normal",
			color: "#FAFAFB",
			letterSpacing: '0.2px',
		},
	},
	chipBox: {
		marginTop: '10px',
		marginLeft: -theme.spacing(0.25),
		display: 'flex',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.25),
		},
	},
}));

export function retrieveExtraPayoutPerHours(hoursPerTester) {
	switch (hoursPerTester) {
		case 2: { return PayoutRatesAndTestersQuota._2hrsTestingExtra; }
		case 3: { return PayoutRatesAndTestersQuota._3hrsTestingExtra; }
		case 4: { return PayoutRatesAndTestersQuota._4hrsTestingExtra; }
	}
	return 0;
}

export function retrieveExtraPayoutPerQtime(questionsTime) {
	switch (questionsTime) {
		case QuestionTimeCategory._2days: { return PayoutRatesAndTestersQuota._2daysQtimeExtra; }
		case QuestionTimeCategory._4days: { return PayoutRatesAndTestersQuota._4daysQtimeExtra; }
	}
	return 0;
}

export function calcTotalAmountPerTester(newCycle) {
	let hourlyRate = PayoutRatesAndTestersQuota.baseHrlyRate;

	if (newCycle.workCategory === WorkCategory.FindBugsTestPlan) {
		hourlyRate += PayoutRatesAndTestersQuota.testPlanExtra;
	}

	hourlyRate += retrieveExtraPayoutPerHours(newCycle.hoursPerTester);
	hourlyRate += retrieveExtraPayoutPerQtime(newCycle.questionsTime);

	return hourlyRate;

}

export function timeTxtFromDeliveryTime(deliveryTime) {
	let deliveryTimeTxt
	switch (deliveryTime) {
		case '24hours': deliveryTimeTxt = '24 hrs'; break;
		case '3days': deliveryTimeTxt = '3 days'; break;
		case '7days': deliveryTimeTxt = '1 week'; break;
	}
	return deliveryTimeTxt;
}

export function timeTxtFromDeliveryTimeDays(deliveryTime) {
	let deliveryTimeTxt
	switch (deliveryTime) {
		case '24hours': deliveryTimeTxt = '1 day cycle'; break;
		case '3days': deliveryTimeTxt = '3 days cycle'; break;
		case '7days': deliveryTimeTxt = '1 week'; break;
	}
	return deliveryTimeTxt;
}

export function timeTxtFromQuestionTime(questionsTime) {
	let QuestionTimeTxt
	switch (questionsTime) {
		case '24hours': QuestionTimeTxt = '24 hrs'; break;
		case '2days': QuestionTimeTxt = '2 days'; break;
		case '4days': QuestionTimeTxt = '4 days'; break;
	}
	return QuestionTimeTxt;
}

const TestPropsPanel = (props) => {

	const { activeStep, pricingType } = props;
	const newCycle: NewWizardCycle = props.newCycle;

	const titles = {
		'speedtest': 'Speed Test',
		'popular': 'Before You Launch',
		'firstcycle': 'Try out for only $1!',
		'pro': 'Test It All'
	};

	const typeText = newCycle.workCategory === WorkCategory.FindBugsExploratory ? 'Exploratory' : 'Test plan';
	const hrTestingTxt = `${newCycle.hoursPerTester} hr testing`;
	const overallTimeTxt = timeTxtFromDeliveryTimeDays(newCycle.deliveryTime) + ' Testing';
	const QtimeTxt = timeTxtFromQuestionTime(newCycle.questionsTime) + ' Availability';
	const locationTxt = newCycle.isWorldwide ? 'Worldwide' : 'Specific countries';

	const specificationTxt = (newCycle.testSpecification === SpecificationType.Website) ? 'Website' : 'Native app';
	const platformTxtName = newCycle.mainTestPlatform.name;

	/* const numberOfTesters = newCycle.testEnvs.reduce((result, el) => {
		result += el.requiredTesters;
		return result;
	}, 0); */

	const numberOfTesters = newCycle.numberOfTesters;

	const classes = useStyles();

	return (
		<Box sx={{ mt: 3, ml: 4, display: 'flex', flexDirection: 'column', alignItems: 'left', gap: 1, }}>
			<Typography variant="h6" >{titles[pricingType]}</Typography>

			<Box
				sx={{
					flexDirection: 'row',
					display: 'flex',
					alignItems: 'right',
					gap: 2,
				}}>
				<CheckCircleIcon sx={{ color: "rgba(64, 192, 87, 1.0)" }} />
				<Typography>{typeText}</Typography>
			</Box>

			<Box
				sx={{
					flexDirection: 'row',
					display: 'flex',
					alignItems: 'right',
					gap: 2,
				}}>
				<CheckCircleIcon sx={{ color: "rgba(64, 192, 87, 1.0)" }} />
				<Typography>{hrTestingTxt}</Typography>
			</Box>

			<Box
				sx={{
					flexDirection: 'row',
					display: 'flex',
					alignItems: 'right',
					gap: 2,
				}}>
				<CheckCircleIcon sx={{ color: "rgba(64, 192, 87, 1.0)" }} />
				<Typography>{overallTimeTxt}</Typography>
			</Box>

			<Box
				sx={{
					flexDirection: 'row',
					display: 'flex',
					alignItems: 'right',
					gap: 2,
				}}>
				<CheckCircleIcon sx={{ color: "rgba(64, 192, 87, 1.0)" }} />
				<Typography>{QtimeTxt}</Typography>
			</Box>

			<Box
				sx={{
					flexDirection: 'row',
					display: 'flex',
					alignItems: 'right',
					gap: 2,
				}}>
				<CheckCircleIcon sx={{ color: "rgba(64, 192, 87, 1.0)" }} />
				<Typography>{locationTxt}</Typography>
			</Box>




			{activeStep > 0 &&
				<>
					<Box
						sx={{
							flexDirection: 'row',
							display: 'flex',
							alignItems: 'right',
							gap: 2,
						}}>
						<CheckCircleIcon sx={{ color: "rgba(64, 192, 87, 1.0)" }} />
						<Typography>{specificationTxt}</Typography>
					</Box>
					<Box
						sx={{
							flexDirection: 'row',
							display: 'flex',
							alignItems: 'right',
							gap: 2,
						}}>
						<CheckCircleIcon sx={{ color: "rgba(64, 192, 87, 1.0)" }} />
						<Typography>{platformTxtName}</Typography>
					</Box>

					<Box
						sx={{
							flexDirection: 'row',
							display: 'flex',
							alignItems: 'right',
							gap: 2,
						}}>
						<CheckCircleIcon sx={{ color: "rgba(64, 192, 87, 1.0)" }} />
						<Typography>{numberOfTesters} Testers</Typography>
					</Box>


				</>
			}
			{/* {activeStep > 1 &&
                <>
                    <Box
                        sx={{
                            flexDirection: 'row',
                            display: 'flex',
                            alignItems: 'right',
                            gap: 2,
                        }}>
                        <CheckCircleIcon sx={{ color: "rgba(64, 192, 87, 1.0)" }} />
                        <Typography>{numberOfTesters} Testers</Typography>
                    </Box>
                </>
            }
 */}

		</Box>
	);
};

export default TestPropsPanel;
