import mixpanel from "mixpanel-browser";

mixpanel.init('74b4d0621db70b6f6c5c264e252cff3f');

let env_check = true; //process.env.NODE_ENV === 'production';

let actions = {
    identify: (id) => {
        if (env_check) mixpanel.identify(id);
    },
    alias: (id) => {
        if (env_check) mixpanel.alias(id);
    },
    track: (name, props) => {
        if (env_check) mixpanel.track(name, props);
    },
    people: {
        set: (props) => {
            if (env_check) mixpanel.people.set(props);
        },
    },
};

export let Mixpanel = actions;