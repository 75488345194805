import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Icon, Input, Message, Select } from 'semantic-ui-react';
import actions from '../../../../redux/actions';
import { Gender } from '../../../../types/Gender';
import { OperationStatus } from '../../../../types/OperationStatus';
import countryCodes from '../../../../util/options/countryCodes';
import genderOptions from '../../../../util/options/genders';
import statesUS from '../../../../util/options/statesUS';
import { searchFromStart } from '../../../../util/utils';


const genderOptionsNoAny = genderOptions.filter((el) => el.key !== Gender.Any);

const mapStateToProps = (state) => {
    return {
        userSavePhoneStatus: state.userSavePhoneStatus,     //For the Phone save only
        userSaveStatus: state.userSaveStatus,               //After the confirmation ends - the real thing
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateUser: (data) => dispatch(actions.updateUser(data)),   //Just to update local User Model - not going to the server
        requestVerifyPhone: (data) => dispatch(actions.requestVerifyPhone(data)),   // After the 'Next' - see that the phone\user is legit\not taken
        confirmSMS: (data) => dispatch(actions.confirmSMS(data)),           // For the SMS token reply
    };
};

class Personal extends Component<any> {
    public state: any;

    constructor(props) {
        super(props);

        this.state = {
            errors: new Set(),
            token: '',   //Holds the token data
            errorMsg: ''
        };
    }

    componentDidMount() {
        const user = { ...this.props.user };
        if (user.country) {
            const found = countryCodes.find(o => o.value === user.country);
            if (found) {
                const { dialCode } = found;
                user["dialCode"] = dialCode;
                this.props.updateUser(user);
            }
        }
    }
    componentDidUpdate(prevProps) {
        if (
            this.props.userSaveStatus.status !== prevProps.userSaveStatus.status &&
            this.props.userSaveStatus.status === OperationStatus.success
        ) {
            this.props.history.push('/complete-profile/devices');
        }

        if (
            this.props.userSaveStatus.status !== prevProps.userSaveStatus.status &&
            this.props.userSaveStatus.status === OperationStatus.fail
        ) {
            this.setState({ errorMsg: this.props.userSaveStatus.errorMessage });
        }
        if (
            this.props.userSavePhoneStatus.status !== prevProps.userSavePhoneStatus.status &&
            this.props.userSavePhoneStatus.status === OperationStatus.success
        ) {
            this.setState({ errorMsg: '' });
        }
        if (
            this.props.userSavePhoneStatus.status !== prevProps.userSavePhoneStatus.status &&
            this.props.userSavePhoneStatus.status === OperationStatus.fail
        ) {
            this.setState({ errorMsg: this.props.userSavePhoneStatus.errorMessage });
        }
    }

    resetErrorState = (event) => {
        const name = event.target.name;
        const errors = new Set(this.state.errors);

        if (errors.has(name)) {
            errors.delete(name);
            this.setState({ errors });
        }
    };

    onChange = (event, data) => {
        this.resetErrorState(event);
        const user = { ...this.props.user };

        switch (data.name) {
            case 'token': {
                const token = data.value;
                this.setState({ token });
                break;
            }
            case 'country': {
                user[data.name] = data.value;
                const { dialCode } = data.options.find(o => o.value === data.value);
                user["dialCode"] = dialCode;
                if (user.country === 'United States') {
                    user.stateInUS = '';
                }
                break;
            }
            case 'stateInUS': {
                user[data.name] = data.value;
                break;
            }
            default: {
                user[data.name] = data.value;
                break;
            }
        }

        return this.props.updateUser(user);
    };

    validateInput = (event, data) => {
        const errors = new Set(this.state.errors);
        const name = data && data.name ? data.name : event.target.name;
        const value = data && data.value ? data.value : event.target.value;

        if (name === 'age') {
            if (!value.length || parseInt(value) < 0 || !Number(value) || parseInt(value) > 120) {
                errors.add(name);
            } else {
                errors.delete(name);
            }

            this.setState({ errors });
            return;
        }

        if (name == 'token') {
            if (!value.length || parseInt(value) < 0 || !Number(value) || value.length !== 6) {
                errors.add(name);
            } else {
                errors.delete(name);
            }

            this.setState({ errors });
            return;
        }

        if (name == 'phoneNumber') {
            if (!value.length || value.length < 8 || value.length > 15) {
                errors.add(name);
            } else {
                errors.delete(name);
            }

            this.setState({ errors });
            return;
        }

        if (name === 'stateInUS ') {
            if (this.props.user.country === 'United States') {
                if (!value || !value.length) {
                    errors.add(name);
                }
            }
        }

        if (!value || !value.length) {
            errors.add(name);
        } else {
            errors.delete(name);
        }

        this.setState({ errors });
    };

    onNext = () => {
        const userData = { ...this.props.user };
        this.props.requestVerifyPhone(userData);
    }

    onBack = () => {
        window.location.reload();
    }

    onSave = () => {
        this.props.confirmSMS(this.state.token);
    };

    render() {

        const prePhoneSaved = this.props.userSavePhoneStatus.status === OperationStatus.notStarted;
        const phoneSaving = this.props.userSavePhoneStatus.status === OperationStatus.processing;
        const postPhoneSaved = this.props.userSavePhoneStatus.status === OperationStatus.success;

        const goodToNext = this.props.user.phoneNumber.length > 6;
        const goodToSave = this.state.token.length === 6;

        const userSaveProcessing = this.props.userSaveStatus.status === OperationStatus.processing;
        const user = this.props.user;
        const errors = this.state.errors;

        return (
            <div className="step-personal" style={{ marginLeft: 5, marginRight: 5 }}>
                <Message info icon>
                    <Icon name="address card outline" />
                    <Message.Content>
                        <Message.Header>Phone verification</Message.Header>
                        <p>Dear tester, please fill in the required information below to continue, thanks.</p>
                    </Message.Content>
                </Message>
                <table className="personal-information">
                    <tbody>
                        <tr>
                            <td>Country:</td>
                            <td>
                                <Select
                                    placeholder="Select Country..."
                                    name="country"
                                    value={user.country}
                                    options={countryCodes}
                                    onChange={this.onChange}
                                    onFocus={this.resetErrorState}
                                    onBlur={this.validateInput}
                                    error={errors.has('country')}
                                    selectOnBlur={false}
                                    search={searchFromStart}
                                    disabled={postPhoneSaved}
                                />
                            </td>
                        </tr>
                        {user.country === 'United States' && <React.Fragment>
                            <tr>
                                <td>State:</td>
                                <td>
                                    <Select
                                        placeholder="Select State..."
                                        name="stateInUS"
                                        value={user.stateInUS}
                                        options={statesUS}
                                        onChange={this.onChange}
                                        onFocus={this.resetErrorState}
                                        onBlur={this.validateInput}
                                        error={errors.has('stateInUS')}
                                        selectOnBlur={false}
                                        search={searchFromStart}
                                        disabled={postPhoneSaved}
                                    />
                                </td>
                            </tr>
                        </React.Fragment>}
                        <tr>
                            <td>Phone number:</td>
                            <td>
                                <Input
                                    label={user.dialCode}
                                    type="text"
                                    placeholder="Phone number"
                                    name="phoneNumber"
                                    value={user.phoneNumber}
                                    onChange={this.onChange}
                                    onFocus={this.resetErrorState}
                                    onBlur={this.validateInput}
                                    error={errors.has('phoneNumber')}
                                    maxLength="15"
                                    disabled={!user.country || postPhoneSaved}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </td>
                        </tr>

                        {/* SAVES THE COUNTRY AND THE PHONE IN THE MODEL */}
                        <tr>
                            <td>
                                <Button
                                    className="save-and-next"
                                    primary
                                    icon
                                    size="big"
                                    labelPosition="right"
                                    onClick={this.onNext}
                                    loading={phoneSaving}
                                    disabled={phoneSaving || postPhoneSaved || errors.size || !goodToNext}
                                >
                                    Next
                                    <Icon name="arrow right" />
                                </Button>
                            </td>
                        </tr>
                        {/* USER STATUS CHANGE IN CASE OF SUCCESS */}
                        {postPhoneSaved && <React.Fragment>
                            <tr>
                                <td>Verification code:</td>
                                <td>
                                    <Input
                                        type="text"
                                        placeholder="******"
                                        name="token"
                                        value={this.state.token}
                                        onChange={this.onChange}
                                        onFocus={this.resetErrorState}
                                        onBlur={this.validateInput}
                                        error={errors.has('token')}
                                        maxLength="6"
                                        /* hidden={!postPhoneSaved} */
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <Button
                                        className="save-and-next"
                                        primary
                                        icon
                                        size="big"
                                        labelPosition="right"
                                        onClick={this.onSave}
                                        loading={userSaveProcessing}
                                        disabled={userSaveProcessing || errors.size || !goodToSave}
                                    >
                                        Verify
                                        <Icon name="arrow right" />
                                    </Button>
                                </td>
                            </tr>
                        </React.Fragment>}
                    </tbody>
                </table>
                {this.state.errorMsg != '' && <React.Fragment>

                    <Message negative>
                        <Message.Header>Error occured</Message.Header>
                        <p>{this.state.errorMsg}</p>
                    </Message>

                    {this.state.errorMsg != 'We apologize, but you have made too many attempts. Please try again in 24 hours' && <React.Fragment>

                        <table className="personal-information">
                            <tbody>
                                <tr>
                                    <td><Button size='mini' labelPosition='left' icon='left chevron' content='Back' onClick={this.onBack} /></td>
                                </tr>
                            </tbody>
                        </table>

                    </React.Fragment>}

                </React.Fragment>}



            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Personal));
