import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import last from 'lodash/last';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material';
import Chip from '@mui/material/Chip';
import Drawer from '@mui/material/Drawer';
import Avatar from '@mui/material/Avatar';
import Select from '@mui/material/Select';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import MenuItem from '@mui/material/MenuItem';
import PublicIcon from '@mui/icons-material/Public';
import TextField from '@mui/material/TextField';
import DevicesIcon from '@mui/icons-material/Devices';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import FormControl from '@mui/material/FormControl';
import DescriptionIcon from '@mui/icons-material/Description';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';

import BugMessages from './BugMessages';
import Tabs from '../../../../elements/Tabs';
import {BugSeverityLabelOutlined, BugStatusLabelOutlined} from './BugSeverity';
import actions from '../../../../redux/actions';
import { RootState } from '../../../../redux/store';
import { BugStatus } from '../../../../types/BugStatus';
import { createDeviceOsField, getApprovedDevicePrettyName } from '../../../../services/deviceService';
import { getCycleBugById, bugStatusOptions, bugSeverityOptions, getBugStatus } from '../../../../services/bugsService';
import { BugSeverity } from '../../../../types/BugSeverity';
import { yellow, green, blue } from '@mui/material/colors';
import { UserRole } from '../../../../types/UserRole';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: 30,
        width: 630,
        [theme.breakpoints.down('md')]: {
            width: '85%'
        },
    },
    chip: {
        borderRadius: 8,
        background: theme.palette.grey[200],
    },
    closeIcon: {
        opacity: 0.7,
        cursor: 'pointer',  
        transition: 'opacity 0.4s',
        '&:hover': {
            opacity: 1,
        },
    },
    testerBox: {
        borderRadius: 8,
        marginBottom: 20,
        padding: '15px 20px',
        backgroundColor: theme.palette.grey[100],
    },
    testerName: {
        marginLeft: 18,
    },
    testerIcon: {
        fontSize: 13,
        marginLeft: 16,
        marginRight: 4,
        marginBottom: -2,
    },
    inputDisabled: {
        '&$disabled': {
            color: theme.palette.grey[700],
        },
    },
    disabled: {},
    title: {
        display: 'block',
        marginBottom: 12,
    },
    fileIcon: {
        marginRight: 8,
    },
    xsBottomSpace: {
        marginBottom: 10,
    },
    smBottomSpace: {
        marginBottom: 25,
    },
    mdBottomSpace: {
        marginBottom: 35,
    },
    mdTopSpace: {
        marginTop: 35,
    },    
}));

interface IBugDrawer {
    cycleId: string;
    openedTab?: number;
    bugId: number | null;
    onClose?: () => void; 
    setOpenedTab?: (number) => void;
    anchor? : 'right' | 'left' | 'top' | 'bottom';
};

const BugDrawer: React.FC<IBugDrawer> = ({ 
    bugId,
    cycleId,
    openedTab = 0,
    anchor = 'right',
    onClose = () => {},
    setOpenedTab = () => {},
}) => {
    const classes = useStyles();

    const dispatch = useDispatch();

    const { cycleBugs, cycleTesters, devices, user } = useSelector((state: RootState) => state);
    const { role } = user;

    const { 
        topic, 
        status,
        device,
        chatId,
        testerId, 
        severity,
        createdAt,
        actualResult,
        expectedResult, 
        stepsToReproduce,
        comment,
        imageFilePaths = [],
        videoFilePaths = [],
    } = getCycleBugById(cycleBugs, cycleId, bugId);

    const tester = cycleTesters?.byCycleId[cycleId]?.find((tester) => tester.id === testerId) || {} ;

    const handleChange = (_: React.ChangeEvent<{}>, newTab: number) => {
        setOpenedTab(newTab);
    };

    const updateBugStatus = (status: BugStatus) => {
        dispatch(actions.updateBug(cycleId, bugId, { bugProps: { status } }));
    };

    const updateBugSeverity = (status: BugStatus, severity: BugSeverity) => {
        dispatch(actions.updateBug(cycleId, bugId, { bugProps: { status, severity } }));
    };

    return (
        <Drawer anchor={anchor} open={!!bugId} onClose={onClose} className={classes.root}>
            <div className={classes.root}>
                <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.mdBottomSpace}>
                    <CloseIcon fontSize="small" className={classes.closeIcon} onClick={onClose} />
                    <Chip 
                        className={classes.chip} 
                        label="Mark bug as closed" 
                        onClick={() => updateBugStatus(BugStatus.Deferred)}
                    />
                </Box>

                <div className={classes.mdBottomSpace}>
                    <Typography variant="h6" component="h2">{topic}</Typography>

                    <Typography variant="caption" color="textSecondary">
                        ID {bugId} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Posted at &nbsp;
                        {moment(createdAt).format('hh:mm')} - {moment(createdAt).format('DD MMMM YYYY')}
                    </Typography>
                </div>

                <Tabs.Root 
                    value={openedTab}
                    onChange={handleChange} 
                    aria-label="Bug Navigation Tabs"
                >
                    <Tabs.Item 
                        label={
                            <Box display="flex" alignItems="center" justifyContent="center">
                                <InfoIcon /> &nbsp;&nbsp; 
                                <Typography variant="subtitle2">Bug Information</Typography>
                            </Box>
                        }
                    />
                    <Tabs.Item 
                        label={
                            <Box display="flex" alignItems="center" justifyContent="center">
                                <QuestionAnswerIcon /> &nbsp;&nbsp; 
                                <Typography variant="subtitle2">Comments</Typography>
                            </Box>
                        }
                    /> 
                </Tabs.Root>

                <div
                    role="tabpanel"
                    id="simple-tabpanel-0"
                    hidden={openedTab !== 0}
                    aria-labelledby="Bug Info Tab"
                    className={classes.mdTopSpace}
                >
                    <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.smBottomSpace}>                        
                            {/* <Typography variant="caption" color="textSecondary" className={classes.title}>Severity</Typography>
                            <BugSeverityLabel severity={severity} /> */}                        
                        <FormControl variant="standard" style={{minWidth: 160}}>
                            <InputLabel id="select-status-label">Status</InputLabel>
                            <Select 
                                value={status}
                                label="Status" 
                                id="select-status" 
                                labelId="select-status-label"
                                disabled={role === UserRole.Viewer}
                                onChange={(e: any) => {
                                    updateBugStatus(e?.target?.value);
                                }}
                            >
                                {bugStatusOptions.map(({ value, label }) => (
                                    <MenuItem key={value} value={value}>
                                        <BugStatusLabelOutlined status={value} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" style={{minWidth: 160}}>
                            <InputLabel id="select-severity-label">Severity</InputLabel>
                            <Select 
                                value={severity}
                                label="Severity" 
                                id="select-severity" 
                                labelId="select-severity-label"
                                disabled={role === UserRole.Viewer}
                                onChange={(e: any) => {
                                    updateBugSeverity(status, e?.target?.value);
                                }}
                            >
                                {bugSeverityOptions.map(({ value, label }) => (
                                    <MenuItem key={value} value={value}>
                                        <BugSeverityLabelOutlined severity={value}/>
                                    </MenuItem>
                                ))}
                            </Select>                            
                        </FormControl>                        
                    </Box>
                    <Box display="flex" alignItems="center" className={classes.testerBox}>
                        {tester.avatarUrl ? (
                            <Avatar src={tester.avatarUrl} alt={`${tester.firstName}${tester.lastName}`} />
                        ) : (
                            <Avatar>{first(tester.firstName)}{first(tester.lastName)}</Avatar>
                        )}
                        <div>
                            <Typography variant="subtitle1" className={classes.testerName}>
                                {tester.firstName} {tester.lastName}
                            </Typography>
                            <Box display="flex">
                                <Typography variant="caption">
                                    <PublicIcon className={classes.testerIcon} />
                                    {tester.country}
                                </Typography>
                                <Typography variant="caption">
                                    <DevicesIcon className={classes.testerIcon} />
                                    {getApprovedDevicePrettyName(device)}
                                </Typography>
                            </Box>
                        </div>
                    </Box>
                    <TextField
                        /* disabled */
                        variant="standard"
                        fullWidth
                        multiline
                        value={stepsToReproduce}
                        label="Description"
                        className={classes.smBottomSpace}
                        InputProps={{ classes: { root: classes.inputDisabled, disabled: classes.disabled }}}
                    />
                    <TextField
                        /* disabled */
                        variant="standard"
                        fullWidth
                        multiline                       
                        value={expectedResult}
                        label="Expected result"
                        className={classes.smBottomSpace}
                        InputProps={{ classes: { root: classes.inputDisabled, disabled: classes.disabled }}}
                    />
                    <TextField
                        /* disabled */
                        variant="standard"
                        fullWidth
                        multiline
                        value={actualResult}
                        label="Actual result"
                        className={classes.smBottomSpace}
                        InputProps={{ classes: { root: classes.inputDisabled, disabled: classes.disabled }}}
                    />
                    <TextField
                        /* disabled */
                        variant="standard"
                        fullWidth
                        multiline
                        value={comment}
                        label="Additional info"
                        className={classes.smBottomSpace}
                        InputProps={{ classes: { root: classes.inputDisabled, disabled: classes.disabled }}}
                    />

                    {(!isEmpty(imageFilePaths) || !isEmpty(videoFilePaths)) && (
                        <div>
                            <Typography variant="caption" color="textSecondary" className={classes.title}>
                                Attachments
                            </Typography>
                            {!isEmpty(imageFilePaths) && imageFilePaths.map((filePath) => (
                                <a key={filePath} href={filePath} title="Bug Image File" target="_blank" rel="noopener">
                                    <Box display="flex" alignItems="center" className={classes.xsBottomSpace}>
                                        <DescriptionIcon color="primary" className={classes.fileIcon} />
                                        <Typography variant="caption" paragraph>{last(filePath?.split('/'))}</Typography>
                                    </Box>
                                </a>
                            ))} 
                            {!isEmpty(videoFilePaths) && videoFilePaths.map((filePath) => (
                                <a key={filePath} href={filePath} title="Bug Video File" target="_blank" rel="noopener">
                                    <Box display="flex" alignItems="center" className={classes.xsBottomSpace}>
                                        <VideoLibraryIcon color="primary" className={classes.fileIcon} />
                                        <Typography variant="caption" paragraph>{last(filePath?.split('/'))}</Typography>
                                    </Box>
                                </a>
                            ))}
                        </div>
                    )}
                </div>

                <div 
                    role="tabpanel"
                    id="simple-tabpanel-1"
                    hidden={openedTab !== 1}
                    aria-labelledby="Comments Tab"
                    className={classes.mdTopSpace}
                >
                    <BugMessages bugId={bugId} chatId={chatId} />
                </div>
            </div>
        </Drawer>
    );
};

export default BugDrawer;