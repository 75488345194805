import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import StarRatingComponent from 'react-star-rating-component';
import { Label, Menu } from 'semantic-ui-react';
import noavatar from '../../../public/images/noavatar.png';
import cycleActions from '../../../redux/actions/cycleActions';

const mapStateToProps = (state) => {
  return {
    firstName: state.user.firstName,
    lastName: state.user.lastName,
    avatarUrl: state.user.avatarUrl,
    rating: state.user.rating,
    addedToFavorites: state.user.addedToFavorites,
    device: state.resolution.device,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listCycles: () => dispatch(cycleActions.listCycles()),
  };
};

// TODO both Tester and Customer sidemenus should be either smart or dumb. Decide on it
class Sidemenu extends Component<any> {
  private listCyclesInterval: any;

  componentDidMount() {
    this.listCyclesInterval = setInterval(this.props.listCycles, 30000);
  }

  componentWillUnmount() {
    clearInterval(this.listCyclesInterval);
  }


  render() {
    const avatarUrl = this.props.avatarUrl || noavatar;

    const isMobile = this.props.device === 'mobile' || this.props.device === 'mobile-tablet';

    if (isMobile) {

      const styleCenter = {
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
      };

      return (
        <div style={{ marginBottom: 30, marginLeft: 5 }}>
          <div style={styleCenter}>
            <img src={avatarUrl} alt="avatar" height="64" width="64" />
            <div style={{ marginLeft: 15 }}>
              <span>{this.props.firstName}</span>
              <span>{this.props.lastName}</span>
              <div>
                <StarRatingComponent name="rate1" starCount={5} value={+this.props.rating} />
                <span>{parseFloat(this.props.rating).toFixed(2)}</span>
              </div>
              {this.props.addedToFavorites > 0 && (
                <p className=" added-to-favorites">
                  Added to favorites: {this.props.addedToFavorites}
                </p>
              )}
            </div>
          </div>

          <Menu horizontal as="nav" >
            <Menu.Item as={NavLink} to="/account/tester" exact>
              Test Cycles
            </Menu.Item>
            <Menu.Item as={NavLink} to="/account/tester/myprofile">
              My Profile
            </Menu.Item>
            <Menu.Item as={NavLink} to="/account/tester/reviews">
              Customer Reviews
            </Menu.Item>
            <Menu.Item as={NavLink} to="/account/tester/affiliates">
              Affiliate Program
            </Menu.Item>
          </Menu>
        </div>
      )
    }
    return (
      <aside>
        <div className="summary">
          <img className="avatar" src={avatarUrl} alt="avatar" height="64" width="64" />
          <div className="details">
            <span className="first-name">{this.props.firstName}</span>
            <span className="last-name">{this.props.lastName}</span>
            <div className="rating">
              <StarRatingComponent name="rate1" starCount={5} value={+this.props.rating} />
              <span>{parseFloat(this.props.rating).toFixed(2)}</span>
            </div>
            {this.props.addedToFavorites > 0 && (
              <p className=" added-to-favorites">
                Added to favorites: {this.props.addedToFavorites}
              </p>
            )}
          </div>
        </div>

        <Menu vertical as="nav">
          <Menu.Item as={NavLink} to="/account/tester" exact>
            Test Cycles
          </Menu.Item>
          <Menu.Item as={NavLink} to="/account/tester/myprofile">
            My Profile
          </Menu.Item>
          <Menu.Item as={NavLink} to="/account/tester/reviews">
            Customer Reviews
          </Menu.Item>
          {/* <Menu.Item as={NavLink} to="/account/tester/howdoesitwork">
            How does it work
          </Menu.Item> */}
          <Menu.Item as={NavLink} to="/account/tester/affiliates">
            <Label color='teal'>New</Label>
            Affiliate Program
          </Menu.Item>
        </Menu>
      </aside>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidemenu);
