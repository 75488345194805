import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { UserRole } from '../../types/UserRole';

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const CycleCreationResult = (props) => {
  if (props.user.role !== UserRole.Customer) {
    return <Redirect to="/" />;
  }

  const url = new URL(window.location.href);
  const searchParms = new URLSearchParams(url.search);
  const status = searchParms.get('status');

  return (
    <div className="cycle-created page">
      <div className="heading">
        {status === 'success' && <h1>Cycle created successfuly!</h1>}

        {status === 'fail' && <h1>Unable to create the cycle</h1>}
      </div>

      <div className="inner">
        {status === 'success' && (
          <div>
            <p>Congratulations! Your cycle created successfuly.</p>
            <p>You can track it&apos;s status from your account page.</p>
          </div>
        )}

        {status === 'fail' && (
          <div>
            <p>Please try again later or contact customer support</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(CycleCreationResult);
