import React from 'react';
import { Pagination } from 'semantic-ui-react';
import TesterPreview from './TesterPreview';

const TesterList = ({ testers, total, page, onPageChange }) => {
  const testersPerPage = 4;
  const totalPages = Math.ceil(total / testersPerPage);

  const startNum = page * testersPerPage - testersPerPage + 1;
  const endNum = page * testersPerPage;

  return (
    <div className="tester-list">
      <h3>Your potential testers</h3>

      {testers.length > 0 &&
        testers.map((el, i) => (
          <TesterPreview
            key={i}
            avatarUrl={el.avatarUrl}
            firstName={el.firstName}
            lastName={el.lastName}
            country={el.country}
            about={el.about}
            rating={el.rating}
            cyclesCompleted={el.cyclesCompleted}
            customerReviews={el.customerReviews}
            addedToFavorites={el.addedToFavorites}
          />
        ))}

      <p>
        Showing {startNum} - {endNum} of {total} testers
      </p>

      {testers.length > 0 && (
        <Pagination activePage={page} totalPages={totalPages} onPageChange={onPageChange} />
      )}
    </div>
  );
};

export default TesterList;
