import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import { Box, MobileStepper, Button as MuiButton, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from '../../redux/store';
import { NewWizardCycle } from '../../types/NewWizardCycle';
import { OperationStatus } from '../../types/OperationStatus';
import { UserRole } from '../../types/UserRole';
import { Mixpanel } from '../../util/mixpanel';
import CheckoutForm from '../Wizard/InnerForms/CheckoutForm';
import SharedStyle from '../Wizard/InnerForms/SharedStyle';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginTop: SharedStyle.innerContent.marginTop,
        marginLeft: SharedStyle.innerContent.marginLeft,
    },
    internalBox: {
        marginTop: '20px',
    },
    descriptionCardText: {
        fontSize: '14px',
        color: '#171725',
    },
    AttributeName: {
        fontSize: '14px',
        color: theme.palette.common.black,
        fontWeight: 'normal',
        letterSpacing: '0.1px',
    },

    AttributeNameBold: {
        fontSize: '14px',
        color: theme.palette.common.black,
        fontWeight: 'bold',
        letterSpacing: '0.1px',
    },
}));


export default function StepFourForm(props) {

    const { pricingType, next, back } = props;

    const newCycle: NewWizardCycle = useSelector((state: RootState) => state).newWizardCycle;
    const { systemPlatforms, user } = useSelector((state: RootState) => state);

    const [formStatus, setFormStatus] = useState(OperationStatus.notStarted);

    const classes = useStyles();

    const history = useHistory();

    const dispatch = useDispatch();

    useEffect(() => {
        let userId = user.role == UserRole.Anonymous ? -1 : user.id;
        Mixpanel.track('Entered Pricing Step 4', { userId, pricingType });
    }, []);

    return (

        <>

            <React.Fragment>
                <Typography align="left" variant="h3">
                    Checkout
                </Typography>
                <Typography align="left" variant="h4" style={{ marginTop: "15px" }} >
                    Your test cycle is almost ready to start, after completing the payment our system will start matching you with our finest testers
                </Typography>
                <Box mt="60px" ml="10px">
                    <CheckoutForm></CheckoutForm>
                </Box>

                <Box sx={{ mt: 5, width: '75%' }}>
                    <MobileStepper
                        variant="text"
                        steps={4}
                        position="static"
                        activeStep={3}
                        nextButton={
                            <MuiButton
                                size="large"
                                disabled
                            >
                                {/* Next
                                <KeyboardArrowRight /> */}
                            </MuiButton>
                        }
                        backButton={
                            <MuiButton size="large" onClick={back}>
                                <KeyboardArrowLeft />
                                Back
                            </MuiButton>
                        }
                    />
                </Box>

            </React.Fragment>
        </>

    )
}
