import {
  StepLabel,
  StepButton,
  Stepper,
  Step,
  Button,
  Typography,
  Box,
  adaptV4Theme,
} from '@mui/material';
import React from 'react';
import { createTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { titleBlue } from './InnerForms/SharedStyle';
import { orange } from '@mui/material/colors';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}


const themeLocal = createTheme(adaptV4Theme({
  palette: {
    secondary: {
      main: orange[800],
    },
  },
}));

const useStyles = makeStyles(() =>
  createStyles({
    customLabelStyle: {
      '& .MuiStepLabel-label': {
        fontFamily: "Poppins",
        fontSize: '14px',
      },
      '& .MuiStepLabel-label.Mui-active': {
        color: titleBlue,
      },
      '& .MuiStepLabel-label.Mui-completed': {
        color: titleBlue,
      }
    },
  }),
);


const StepHeader = (props) => {

  const classes = useStyles();
  const { activeStep, wizard, handleStepClick } = props;

  return (
    <React.Fragment>
      <Typography variant="h5">
        Create new Test
      </Typography>
      <Stepper activeStep={activeStep} style={{ minWidth: "800px", height: '90%' }}>
        {wizard.map((page, index) => (
          <Step key={page.mainPage} onClick={() => handleStepClick(index)}>
            <StepButton>
              <StepLabel className={classes.customLabelStyle} >{page.mainPage}</StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
      {/* <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themeLocal}>
              <Button onClick={props.startOver} variant="contained" color="secondary" size="small">Start over</Button>
            </ThemeProvider>
          </StyledEngineProvider> */}
    </React.Fragment>
  );
};


export default StepHeader; 