import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import actions from '../redux/actions';

const mapStateToProps = (state: any) => {
  return {
    modalOpen: state.loginModal.open,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loginModalOpen: () => dispatch(actions.loginModalOpen()),
  };
};

class RedirectLogin extends Component<any> {
  public state: any;

  componentDidMount() {
    const redirectAfterLogin = this.props.location.state.referrer;
    localStorage.setItem('redirectAfterLogin', redirectAfterLogin);
    this.props.loginModalOpen();
  }

  componentDidUpdate(prevProps) {
    if (this.props.modalOpen === false) {
      this.props.history.push('/');
    }
  }

  render() {
    return null;
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RedirectLogin));
