import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import isEmail from 'validator/lib/isEmail';
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { ThemeProvider, StyledEngineProvider, Theme } from '@mui/material/styles';
import config from '../config';
import actions from '../redux/actions';
import { RootState } from '../redux/store';
import { OperationStatus } from '../types/OperationStatus';

import Hero from '../elements/Hero';
import Button from '../elements/Button';
import AvatarBackground from '../elements/AvatarBackground';

import theme from '../themes';
import { toast } from 'react-toastify';



declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}



const useStyles = makeStyles((theme: Theme) => ({
    loginBox: {
        marginTop: -90,
        padding: 35,
    },
    avatarBackground: {
        paddingBottom: 97,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 0,
            height: 'calc(100vh - 40px)',
        },
    },
    smBottomSpace: {
        marginBottom: 25,
    },
    lgBottomSpace: {
        marginBottom: 50,
    },
    lgTopSpace: {
        marginTop: 50,
    },
    rememberPasswordText: {
        fontSize: 14,
    },
}));


async function submitUnsubscribe(email){  
    const url = new URL('api/unsubscribe', config.apiEndpoint);
    url.searchParams.set('email', email);
    
    const response = await fetch(url.href, { method: 'GET' });
    
    return response;
  }


export function UnsubscribePage() {
    
    const classes = useStyles();            

    const [formStatus, setFormStatus] = useState(OperationStatus.notStarted);
  
    const { resolution } = useSelector((state: RootState) => state);
  
    const isMobile = resolution.device === 'mobile';
    const isMobileTablet = resolution.device === 'mobile-tablet';        

    useEffect(() => {
        const mainEl = document.getElementsByTagName('main')[0];
        mainEl.classList.add('full-screeninde');
        return () => {
            mainEl.classList.remove('full-screen');
        };
      }, []);

    const fireRequest = async (email) => {
        if(email){
            try{
                setFormStatus(OperationStatus.processing);
                const response = await submitUnsubscribe(email);
                if (!response.ok) {
                  toast.error('Failed to unsubscribe');
                  setFormStatus(OperationStatus.fail);
                } else {
                  toast.success('Successfully Unsubscribed!');
                  setFormStatus(OperationStatus.success);              
                }
              }catch(e) {
                setFormStatus(OperationStatus.fail);
                toast.error('Failed to unsubscribe');
              }
        }        
    };

    const url = new URL(window.location.href);
    const email = url.searchParams.get('email');    

    useEffect(() => {
        fireRequest(email);
      }, []);
    
      if(email) {
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                <AvatarBackground 
                    mode="blue" 
                    fullScreen 
                    hideMiddleAvatars
                    className={classes.avatarBackground}
                    hideAvatars={isMobile || isMobileTablet}
                >
                    <Container>
                        <Hero 
                            mode="light" 
                            maxWidth="sm"               
                            title="Unsubscribe"         
                            className={classes.loginBox}                 
                        >
                            <Container maxWidth="xs" className={classes.lgTopSpace}>                            
        
                                {formStatus === OperationStatus.success && (
                                        <Alert severity="success">
                                            <strong>You have successfully unsubscribed</strong>
                                            <div>You will no longer receive testing invitations.</div>
                                            <div>If you change your mind you can always go and revert this in your user profile settings page</div>
                                        </Alert>
                                    )}
        
                                {formStatus === OperationStatus.notStarted && (
                                        <p>...</p>
                                    )}
                                
                                {formStatus === OperationStatus.processing && (
                                        <p>Pleae wait...</p>
                                    )}
        
                                    {formStatus === OperationStatus.fail && (
                                        <Alert severity="error">
                                            <strong>Unable to Unsubscribe</strong>
                                            <div>Please try again later or contact technical support</div>                                        
                                        </Alert>
                                    )}
                            </Container>
                        </Hero>
                    </Container>
                </AvatarBackground>
            </ThemeProvider>
            </StyledEngineProvider>
        );
    }
    else{
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                <AvatarBackground 
                    mode="blue" 
                    fullScreen 
                    hideMiddleAvatars
                    className={classes.avatarBackground}
                    hideAvatars={isMobile || isMobileTablet}
                >
                    <Container>
                        <Hero 
                            mode="light" 
                            maxWidth="sm"
                            title="Unsubscribe"                          
                            className={classes.loginBox}                        
                        >
                            <Container maxWidth="xs" className={classes.lgTopSpace}>                                                               
                                    {formStatus !== OperationStatus.success && (
                                        <Alert severity="error">
                                            <strong>Error Unsubscribing</strong>
                                            <div>Please try again later or contact technical support</div>
                                        </Alert>
                                    )}                            
                            </Container>
                        </Hero>
                    </Container>
                </AvatarBackground>
            </ThemeProvider>
            </StyledEngineProvider>
        );
    }     
}