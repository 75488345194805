import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Loader, Message } from 'semantic-ui-react';
import actions from '../redux/actions';
import { OperationStatus } from '../types/OperationStatus';

const mapStateToProps = (state) => {
  return {
    passwordResetStatus: state.passwordResetStatus.status,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (email, code) => dispatch(actions.resetPassword(email, code)),
  };
};

class ResetPassword extends Component<any> {
  public state: any;

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      code: '',
    };
  }

  componentDidMount() {
    const url = new URL(window.location.href);
    const email = url.searchParams.get('email');
    const code = url.searchParams.get('code');

    this.setState({
      email,
      code,
    });

    if (email && code) {
      this.props.resetPassword(email, code);
    }
  }

  render() {
    const email = this.state.email;
    const code = this.state.code;
    const status = this.props.passwordResetStatus;

    if (email && code) {
      return (
        <div className="password-reset page">
          <div className="heading">
            <h1>Reset password</h1>
          </div>
          <div className="inner">
            {status === OperationStatus.notStarted && <p>...</p>}

            {status === OperationStatus.processing && <Loader>Pleae wait...</Loader>}

            {status === OperationStatus.fail && (
              <Message negative>
                <Message.Header>Unable to reset password</Message.Header>
                <Message.Content>
                  Please try again later or contact technical support
                </Message.Content>
              </Message>
            )}

            {status === OperationStatus.success && (
              <Message positive>
                <Message.Header>Password reset successful</Message.Header>
                <Message.Content>Please check your mail again for the new password</Message.Content>
              </Message>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="password-reset page">
          <div className="heading">
            <h1>Password reset error</h1>
          </div>
          <div className="inner">
            <Message negative>
              <Message.Header>Unable to reset password</Message.Header>
              <Message.Content>Please try again later or contact technical support</Message.Content>
            </Message>
          </div>
        </div>
      );
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));
