import React from 'react';
import moment from 'moment';

const ChatMessage = ({ direction, time, name, avatarUrl, text }) => {
  return (
    <div className={'chat-message ' + direction}>
      <span className="info">
        {moment(time).format('DD-MM-YYYY HH:MM')} - {name}
      </span>
      <div>
        <img className="avatar" src={avatarUrl} alt="" height="64" width="64" />
        <p> {text} </p>
      </div>
    </div>
  );
};

export default ChatMessage;
