import React from 'react';
import map from 'lodash/map';
import round from 'lodash/round';
import { useSelector } from 'react-redux';
import { Doughnut } from 'react-chartjs-2';
import makeStyles from '@mui/styles/makeStyles';
import { blueGrey, red, yellow, green, blue } from '@mui/material/colors';

import { RootState } from '../../../../redux/store';
import { BugSeverity } from '../../../../types/BugSeverity';
import { getCycleBugsBySeverity, getCycleBugsByStatus } from '../../../../services/bugsService';
import { BugStatus } from '../../../../types/BugStatus';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
    doughnut: {
        maxWidth: 360,
        marginRight: 20,
        [theme.breakpoints.down('lg')]: {
            height: 190,
        },
    },
}));

const pieOptions = {
    responsive: true,
    maintainAspectRatio: true,
    animation: {
        duration: 0,
    },
    plugins: {
        legend: {
            position: "right" as const,
        },
    },

    /* responsive: true,
    plugins: { legend: { position: 'right' } },    
    animation: {
        duration: 0,
    }, */
};

const createPieData = (params: { data: string[]; labels: string[] }): any => ({
    labels: params.labels,
    datasets: [{
        cutout: '70%',
        hoverOffset: 2,
        borderWidth: 5,
        data: params.data,
        backgroundColor: [blueGrey[800], red[400], yellow[800], green[400]],
    }],
});

const createPieDataStatuses = (params: { data: string[]; labels: string[] }): any => ({
    labels: params.labels,
    datasets: [{
        cutout: '70%',
        hoverOffset: 2,
        borderWidth: 5,
        data: params.data,
        backgroundColor: [blue[700], green[800], yellow[800], green[600], green[400]],
    }],
});



export const BugSeverityPieChart: React.FC<{ cycleId: string }> = ({ cycleId }) => {
    const classes = useStyles();

    const { cycleBugs, resolution } = useSelector((state: RootState) => state);

    const isMobile = resolution.device === 'mobile' || resolution.device === 'mobile-tablet';

    const criticalSeverity = getCycleBugsBySeverity(cycleBugs, cycleId, BugSeverity.Critical).length;
    const highSeverity = getCycleBugsBySeverity(cycleBugs, cycleId, BugSeverity.High).length;
    const mediumSeverity = getCycleBugsBySeverity(cycleBugs, cycleId, BugSeverity.Medium).length;
    const lowSeverity = getCycleBugsBySeverity(cycleBugs, cycleId, BugSeverity.Low).length;
    const totalSeverity = criticalSeverity + highSeverity + mediumSeverity + lowSeverity;

    const severities = [
        { label: 'Critical', value: criticalSeverity },
        { label: 'High', value: highSeverity },
        { label: 'Medium', value: mediumSeverity },
        { label: 'Low', value: lowSeverity },
    ];

    const chartData = createPieData({
        data: map(severities, 'value'),
        labels: severities.map(({ label, value }) =>
            `${round(value / totalSeverity * 100)}% ${label}`
        ),
    });

    if (totalSeverity > 0) {
        return (
            <div className={classes.doughnut}>
                <Doughnut width={isMobile ? 340 * 0.75 : 340} data={chartData} options={pieOptions} />
            </div>
        );
    }
    else {
        return null;
    }

};


export const BugStatusPieChart: React.FC<{ cycleId: string }> = ({ cycleId }) => {
    const classes = useStyles();

    const { cycleBugs, resolution } = useSelector((state: RootState) => state);

    const isMobile = resolution.device === 'mobile' || resolution.device === 'mobile-tablet';

    const newStatus = getCycleBugsByStatus(cycleBugs, cycleId, BugStatus.New).length;
    const fixedStatus = getCycleBugsByStatus(cycleBugs, cycleId, BugStatus.Fixed).length;
    const notAbugStatus = getCycleBugsByStatus(cycleBugs, cycleId, BugStatus.NotABug).length;
    const duplicatedStatus = getCycleBugsByStatus(cycleBugs, cycleId, BugStatus.Duplicated).length;
    const deferredStatus = getCycleBugsByStatus(cycleBugs, cycleId, BugStatus.Deferred).length;
    const totalStatuses = newStatus + fixedStatus + duplicatedStatus + deferredStatus + notAbugStatus;


    const statuses = [
        { label: 'Open', value: newStatus },
        { label: 'Fixed', value: fixedStatus },
        { label: 'Not a bug', value: notAbugStatus },
        { label: 'Duplicated', value: duplicatedStatus },
        { label: 'Closed', value: deferredStatus },
    ];

    const chartData = createPieDataStatuses({
        data: map(statuses, 'value'),
        labels: statuses.map(({ label, value }) =>
            `${round(value / totalStatuses * 100)}% ${label}`
        ),
    });

    if (totalStatuses > 0) {
        return (
            <div className={classes.doughnut}>
                <Doughnut width={isMobile ? 350 * 0.75 : 350} data={chartData} options={pieOptions} />
            </div>
        );
    }
    else {
        return null;
    }

};  