import { useEffect, useState } from 'react';
import { getIndicatorsByType, resolveIndicator } from '../services/next/indicatorsService';
import { Indicator } from '../types/next';

const useIndicators = (autoRefresh: boolean = false) => {
  const [indicators, setIndicators] = useState<Indicator[]>([]);

  const loadIndicators = async () => {
    const loaded = await getIndicatorsByType();
    setIndicators(loaded || []);
  };

  const forceRefresh = async () => {
    await loadIndicators();
  };

  const done = async (id: number) => {
    await resolveIndicator(id);
    await loadIndicators();
  };

  useEffect(() => {
    loadIndicators();

    if (autoRefresh) {
      const indicatorsInterval = setInterval(() => {
        loadIndicators();
      }, 10000);

      return () => {
        clearInterval(indicatorsInterval);
      };
    }
  }, [autoRefresh]);

  return { indicators, forceRefresh, done };
};

export default useIndicators;
