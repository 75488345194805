import { DevicePlatform } from './DevicePlatform';

export type TestEnv = {
  platformId: DevicePlatform;
  name: string;
  type: 'Platform' | 'MobileDevice' | 'DesktopBrowser';
  requiredTesters: number;
  country?: string;
};

export interface Platform extends TestEnv {}

export type MobileDevice = TestEnv & {
  vendorId: number;
  modelId: number;
};

export type DesktopBrowser = TestEnv & {
  browserId: number;
};

export function isPlatform(testEnv): testEnv is Platform {
  return testEnv.type === 'Platform';
}

export function isMobileDevice(testEnv): testEnv is MobileDevice {
  return testEnv.type === 'MobileDevice';
}

export function isDesktopBrowser(testEnv): testEnv is DesktopBrowser {
  return testEnv.type === 'DesktopBrowser';
}
