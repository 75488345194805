import React from 'react';
import moment from 'moment';
import styled, { css } from 'styled-components';
import { Avatar } from '@mui/material';
import { ChatMessage } from '../../types/next';

const Wrapper = styled.div<{ isUser: boolean }>`
  background: #f5f5f5;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin: 1rem;
  padding: 1rem;
  max-width: 80%;
  ${({ isUser }) =>
    isUser
      ? css`
          background: #ccffcc;
          margin-left: auto;
        `
      : ''}
`;

const Header = styled.div<{ isUser: boolean }>`
  display: flex;
  flex-direction: row;

  ${({ isUser }) =>
    isUser
      ? css`
          justify-content: flex-end;
        `
      : ''}
`;

const HeaderDate = styled.div`
  font-size: 70%;
`;

const HeaderName = styled.div`
  font-size: 80%;
  font-weight: 500;
  &:before {
    content: '-';
    margin: 0 8px;
  }
`;

const Content = styled.div<{ isUser: boolean }>`
  display: flex;
  flex-direction: ${({ isUser }) => (isUser ? 'row-reverse' : 'row')};
  align-items: flex-start;
  .MuiAvatar-root {
    flex: 0 0 64px;
    width: 64px;
    height: 64px;
    margin: 10px;
  }
`;

const MessageContent = styled.div`
  width: 100%;
  margin-top: 10px;
`;

interface Props {
  message: ChatMessage;
  isUser?: boolean;
}

const ChatMessageView = ({ message, isUser }: Props) => {
  const fullname = [message.user.firstName, message.user.lastName].join(' ');
  return (
    <Wrapper isUser={!!isUser}>
      <Header isUser={!!isUser}>
        <HeaderDate>{moment(message.creationDate).format('DD-MM-YYYY HH:mm')}</HeaderDate>
        <HeaderName>{fullname}</HeaderName>
      </Header>
      <Content isUser={!!isUser}>
        <Avatar src={message.user.avatarUrl}>AA</Avatar>
        <MessageContent>{message.content}</MessageContent>
      </Content>
    </Wrapper>
  );
};

export default ChatMessageView;
