import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Icon, SemanticICONS } from 'semantic-ui-react';

import Button from '../elements/Button';
import { RootState } from '../redux/store';

import config from '../config';
import logo from '../public/images/icons/wetest-logo.svg';

interface SocialLink {
  name: SemanticICONS;
  link: string;
};

const socialLinks: SocialLink[] = require('../constants/social-links.json');

const websiteLinks = [{ name: 'Home', link: '/' }, /* { name: 'Blog', link: '/blog' }, */ { name: 'Contact us', link: '/contact' }];

const useStyles = makeStyles((theme: Theme) => ({
  center: {
    textAlign: 'center',
  },
  link: {
    width: 135,
    marginLeft: 15,
    color: '#000000 !important',
  },
  middleLink: {
    width: 96,
  },
  list: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  listItem: {
    padding: '0 4px',
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
      marginBottom: 35,
    },
  },
  listItemSocialLinkMobile: {
    flexDirection: 'row',
  },
  xsBottomSpace: {
    marginBottom: 10,
  },
  smBottomSpace: {
    marginBottom: 20,
  },
  mdBottomSpace: {
    marginBottom: 35,
  },
  footer: {
    marginTop: 110,
    paddingTop: 30,
    paddingBottom: 80,
  },
}));

const Footer: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();

  const { resolution } = useSelector((state: RootState) => state);
  const isMobile = resolution.device === 'mobile';
  const isTablet = resolution.device === 'mobile-tablet';

  const isRegistrationPage = ['/login', '/login/', '/signup', '/reset-password-req', '/reset-fb-password-req', '/reset-password', '/unsubscribe'].includes(location.pathname);

  const isCompleteProfile = ['/complete-profile/personal', '/complete-profile/devices', '/complete-profile/browsers', '/registerconfirm'].includes(location.pathname);
  const mobileCompatible = isCompleteProfile && (isMobile || isTablet);

  const isAccountPage =
    location.pathname.includes('/account/customer')
    || location.pathname.includes('/account/tester')

  if (isRegistrationPage || isAccountPage || mobileCompatible) {
    return null;
  }

  const marketingSiteHomePage = config.marketingSiteBaseURL;

  return (
    <footer className={classes.footer}>
      <Container>
        {!isMobile && (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              className={classes.mdBottomSpace}
            >
              <div>
                <div className={classes.xsBottomSpace}>
                  <a href={marketingSiteHomePage}>
                    <img src={logo} alt="Testpoint Logo" title="Testpoint Logo" />
                  </a>
                </div>
                <Typography
                  component="p"
                  variant="subtitle2"
                  className={classes.mdBottomSpace}
                >
                  Copyright &copy; Testpoint &nbsp; All rights reserved
                </Typography>
                <Link to="/tester">
                  <Button>Get paid to test</Button>
                </Link>
              </div>

              <Box display="flex" flexDirection="column" alignItems="flex-end">
                <List
                  aria-label="social icons"
                  className={classNames({
                    [classes.list]: true,
                    [classes.mdBottomSpace]: true,
                  })}
                >
                  {socialLinks.map(({ name, link }) => (
                    <ListItem key={name} className={classes.listItem}>
                      <a href={link} target="_blank" rel="noopener noreferrer" className="link-info">
                        <Icon name={name} />
                      </a>
                    </ListItem>
                  ))}
                </List>

                <List className={classes.list} aria-label="website links">
                  {websiteLinks.map(({ name, link }) => (
                    <ListItem key={name} className={classes.listItem}>
                      <Link key={link} to={link}
                        className={classNames({
                          'link-info': true,
                          [classes.link]: true,
                        })}
                      >
                        {name}
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Box>

            <Divider className={classes.xsBottomSpace} />

            <Box display="flex" alignItems="center" justifyContent="center">
              <List className={classes.list} aria-label="social icons">
                <ListItem className={classes.listItem}>
                  <Link key={"/terms"} to={`/terms`}
                    className={classNames({
                      'link-info': true,
                      [classes.link]: true,
                    })}
                  >
                    Terms & conditions
                  </Link>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Link key={"/privacy"} to={`/privacy`}
                    className={classNames({
                      'link-info': true,
                      [classes.link]: true,
                    })}
                  >
                    Privacy Policy
                  </Link>
                </ListItem>
              </List>
            </Box>
          </>
        )}

        {isMobile && (
          <>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
              className={classNames({
                [classes.center]: true,
                [classes.mdBottomSpace]: true,
              })}
            >
              <div>
                <div className={classes.mdBottomSpace}>
                  <a href={marketingSiteHomePage}>
                    <img src={logo} alt="Testpoint Logo" title="Testpoint Logo" />
                  </a>
                </div>

                <List
                  aria-label="social icons"
                  className={classNames({
                    [classes.list]: true,
                    [classes.listItemSocialLinkMobile]: true,
                  })}
                >
                  {socialLinks.map(({ name, link }) => (
                    <ListItem key={name} className={classes.listItem}>
                      <a href={link} target="_blank" rel="noopener noreferrer" className="link-info">
                        <Icon name={name} />
                      </a>
                    </ListItem>
                  ))}
                </List>

                <List className={classes.list} aria-label="website links">
                  {websiteLinks.map(({ name, link }) => (
                    <ListItem key={name} className={classes.listItem}>
                      <Link key={link} to={link}
                        className={classNames({
                          'link-info': true,
                          [classes.link]: true,
                        })}
                      >
                        {name}
                      </Link>
                    </ListItem>
                  ))}
                </List>

                <Link to="/tester">
                  <Button>Get paid to test</Button>
                </Link>
              </div>
            </Box>

            <Divider className={classes.mdBottomSpace} />

            <List className={classes.list} aria-label="social icons">
              <ListItem className={classes.listItem}>
                <a
                  href="/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classNames({
                    'link-info': true,
                    [classes.link]: true,
                  })}
                >
                  Terms & conditions
                </a>
              </ListItem>
              <ListItem className={classes.listItem}>
                <a
                  href="/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classNames({
                    'link-info': true,
                    [classes.link]: true,
                  })}
                >
                  Privacy Policy
                </a>
              </ListItem>
            </List>

            <Typography
              component="p"
              variant="subtitle2"
              className={classNames({
                [classes.center]: true,
                [classes.mdBottomSpace]: true,
              })}
            >
              Copyright &copy; Testpoint &nbsp; All rights reserved
            </Typography>
          </>
        )}
      </Container>
    </footer>
  );
}

export default Footer;
