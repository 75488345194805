import React from 'react';
import classNames from 'classnames';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { Theme } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import BugIcon from '@mui/icons-material/BugReport';
import withStyles from '@mui/styles/withStyles';
import { blue, grey, lightBlue, lightGreen, orange, yellow } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import LinearProgress from '@mui/material/LinearProgress';
import { CycleStatus } from '../../../../types/CycleStatus';
import { red } from '@mui/material/colors';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { TesterStatusInCycle } from '../../../../types/TesterStatusInCycle';
import moment from 'moment';

const styles = {
    unreadIndicator: { width: 8, height: 8, background: red[500], borderRadius: '100%', marginLeft: 6 },
};

const LightLinearProgress = withStyles({
    colorPrimary: {
      backgroundColor: blue[300],
    },
    barColorPrimary: {
      backgroundColor: '#ffffff',
    }
})(LinearProgress);

const DarkLinearProgress = withStyles({
    colorPrimary: {
      backgroundColor: grey[300],
    },
    barColorPrimary: {
      backgroundColor: blue[800],
    }
})(LinearProgress);

const DarkLinearProgressStyled = withStyles({
    colorPrimary: {
      backgroundColor: grey[300],
    },
    barColorPrimary: {
      backgroundColor: orange[800],
    }
})(LinearProgress);

const useStyles = makeStyles((theme: Theme) => ({
    root: {
      marginBottom: 25,
      border: `1px solid ${grey[300]}`,
    },
    active: {
      color: '#ffffff',
      backgroundColor: theme.palette.primary.main,
    },
    nonActiveReady: {
        backgroundColor: lightGreen[50],
    }, 
    nonActiveOn: {
        backgroundColor: red[50],
    }, 
    nonActiveCompleted: {
        backgroundColor: lightBlue[50],
    }, 
    progress: {
        width: '100%',
        borderRadius: 8,
    },
    xsSpaceLeft: {
        marginLeft: 2,
    },
    smSpaceLeft: {
        marginLeft: 15,
    },
    xsSpaceBottom: {
        marginBottom: 5,
    },
    smSpaceBottom: {
        marginBottom: 10,
    },
    light: {
        color: '#ffffff',
    },
    bold: {
        fontWeight: 'bold',
    },
}));

interface ICycleCardProps {
    cyclePhase?: CycleStatus;
    title?: string;
    startDate?: string;
    endDate?: string;
    platform?: string;
    bugsCount?: number;
    unreadMessagesCount?: number;
    progress?: number;
    isActive?: boolean;
    cycle?: any;
};

const CycleCard: React.FC<ICycleCardProps> = ({ 
    cyclePhase = CycleStatus.Testing,
    title = '',
    startDate = '',
    endDate = '',
    platform,
    bugsCount = 0,
    unreadMessagesCount = 0,
    progress = 0,
    isActive = false,
    cycle = {},
}) => {
    const classes = useStyles();
    
    const isRunning = [CycleStatus.TestingAndSelection, CycleStatus.Testing ].includes(cyclePhase);
    const isCompleted = [CycleStatus.Completed].includes(cyclePhase);
    const isSelection = [CycleStatus.Selection].includes(cyclePhase);

    const { cycleTesters } = useSelector((state: RootState) => state);

    const calcProgressByTime = (cycle) => {
        const now = moment();        
        
        if( isRunning ){                        
            const passedTime = now.diff(cycle?.startDate);                
            const totalTime = moment(cycle?.endDate).diff(cycle?.startDate);
        
            const progress = Math.min(passedTime / totalTime * 100, 100);
            return progress;
        }

        //In this case we show Progress assuming we're in Service time
        else if( isCompleted ){
            const serviceEndDate = cycle?.questionsTime || now;
            if( moment(serviceEndDate).diff(now) > 0 ){
                const passedTime = now.diff(cycle?.endDate); 
                const totalTime = moment(cycle?.questionsTime).diff(cycle?.endDate);

                const progress = passedTime / totalTime * 100;
                return progress;
            }
        }

        return -1;
    }
    
    progress = calcProgressByTime(cycle);

    return (
        <Card className={classNames(classes.root, { [classes.active]: isActive, 
                                                /* [classes.nonActiveReady]: isSelection && !isActive,
                                                [classes.nonActiveOn]: isRunning && !isActive, */
                                                [classes.nonActiveCompleted]: isCompleted && !isActive })}>
            <CardActionArea>
                <CardContent>
                    <Typography 
                        gutterBottom 
                        component="h2" 
                        variant="body1" 
                        className={classes.smSpaceBottom}
                    >
                        {title}
                    </Typography>
                    
                    <Typography 
                        gutterBottom 
                        component="h4" 
                        variant="body2" 
                        //className={classes.smSpaceBottom}
                    >
                        {isRunning && 'Started On: ' + `${startDate}`}
                        {isCompleted && 'Completed On: ' + `${endDate}`}
                        {isSelection && 'Collecting testers...'}
                    </Typography>

                    <Box display="flex" className={classes.smSpaceBottom}>
                        <Box 
                            display="flex"
                            alignItems="center" 
                            justifyContent="flex-start" 
                        >
                            <BugIcon 
                                fontSize="small" 
                                color="disabled"
                                className={classNames({ [classes.light]: isActive })}
                            /> 
                            <Typography 
                                variant="body2"
                                color="textSecondary" 
                                className={classNames(classes.xsSpaceLeft, { 
                                    [classes.light]: isActive,
                                })}
                            >
                                {bugsCount}
                            </Typography>
                        </Box>
                        <Box 
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start" 
                            className={classes.smSpaceLeft}
                        >
                            <EmailIcon 
                                fontSize="small" 
                                color="disabled"
                                className={classNames({ [classes.light]: isActive })}
                            /> 
                            <Typography 
                                variant="body2"                                                                 
                                color="textSecondary" 
                                className={classNames(classes.xsSpaceLeft, { 
                                    [classes.light]: isActive,
                                    [classes.bold]: unreadMessagesCount > 0,
                                })}
                            >
                                {unreadMessagesCount} unread
                            </Typography>
                            {unreadMessagesCount ? <span style={styles.unreadIndicator} /> : null}                            
                        </Box>
                    </Box>
                    {!isSelection && progress > 0 &&
                        <>
                        <Typography 
                            component="p"
                            variant="body2"
                            color="textSecondary"
                            className={classNames(classes.xsSpaceBottom, { 
                                [classes.light]: isActive,
                            })}                    
                        >
                            Progress
                        </Typography>

                        {isActive ? (
                            <LightLinearProgress
                                value={progress}
                                variant="determinate" 
                                className={classes.progress} 
                            />
                        ) : isRunning ?
                                (
                                    <DarkLinearProgress 
                                        value={progress}
                                        variant="determinate" 
                                        className={classes.progress} 
                                    />
                                ) : (
                                    <DarkLinearProgressStyled 
                                        value={progress}
                                        variant="determinate" 
                                        className={classes.progress} 
                                    />
                                )}
                        </>
                    }
   
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default CycleCard;