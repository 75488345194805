import moment from 'moment';
import config from '../../config';
import { ChatMessage } from '../../types/next';

const endpoint = config.apiNextEndpoint;

const getHeaders = () => ({
  Authorization: `Bearer ${localStorage.getItem('token')}`,
  'Content-Type': 'application/json',
});

// export const getChat = async (chatId: number): Promise<ChatMessage[]> => {
//   const response = await fetch(
//     `${endpoint}/api/cycles/${cycleId}/messages?participantId=${participantId}`,
//     {
//       method: 'GET',
//       headers: getHeaders(),
//     }
//   );

//   return [];
// };

interface BugChat {
  chat: number;
  bug: number;
}

export const validateBugChat = async (bugId: number): Promise<any> => {
  const response = await fetch(`${endpoint}/chats/?bugs=${bugId}`, {
    method: 'GET',
    headers: getHeaders(),
  });
  const bugChat: BugChat = await response.json();
  return bugChat[0];
};

export const getBugMessages = async (bugId: number): Promise<ChatMessage[]> => {
  const response = await fetch(`${endpoint}/chats/bug/${bugId}/messages`, {
    method: 'GET',
    headers: getHeaders(),
  });

  const { messages } = await response.json();

  return messages || [];
};

export const getChatMessages = async (chatId: number): Promise<ChatMessage[]> => {
  const response = await fetch(`${endpoint}/chats/${chatId}/messages`, {
    method: 'GET',
    headers: getHeaders(),
  });

  const messages = await response.json();

  return (messages || []).map(
    (message): ChatMessage => {
      return {
        id: message.id,
        content: message.content,
        creationDate: moment(message.creationDate).toDate(),
        user: message.user,
      };
    }
  );
};

export const createChatMessage = async (chatId: number, content: string): Promise<void> => {
  await fetch(`${endpoint}/chats/${chatId}/message`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({
      content,
    }),
  });
};

export const setChatLastRead = async (chatId: number): Promise<void> => {
  await fetch(`${endpoint}/chats/${chatId}/read`, {
    method: 'PATCH',
    headers: getHeaders(),
  });
};

const bugChatService = {
  getAll: getBugMessages,
};

export default bugChatService;