import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import CycleDetailsSidemenu from './Sidemenu';
import { Message } from 'semantic-ui-react';
import { CycleStatus } from '../../../../types/CycleStatus';
import actions from '../../../../redux/actions';
import { TesterStatusInCycle } from '../../../../types/TesterStatusInCycle';
import ReportedBugsList from '../../Customer/Cycle/ReportedBugsList';

const mapStateToProps = (state) => {
  return {
    user: state.user,
    cycles: state.cycles,
    cycleBugs: state.cycleBugs,
    device: state.resolution.device,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteBug: (cycleId, bugId) => dispatch(actions.deleteBug(cycleId, bugId)),
  };
};

class Bugs extends Component<any> {
  public state: any;

  constructor(props) {
    super(props);

    this.state = {
      cycleId: parseInt(props.match.params.id),
      activeIndex: -1,
    };
  }

  onOpenBug = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  onEditBug = (event, data) => {
    event.stopPropagation();
    this.props.history.push(
      `/account/tester/cycle/${this.state.cycleId}/bugs/${data['data-bug-id']}/edit`
    );
  };

  onDeleteBug = (event, data) => {
    event.stopPropagation();
    this.props.deleteBug(this.state.cycleId, data['data-bug-id']);
  };

  handleEditBug = (id: number) => {
    this.props.history.push(`/account/tester/cycle/${this.state.cycleId}/bugs/${id}/edit`);
  };

  handleDeleteBug = (id: number) => {
    this.props.deleteBug(this.state.cycleId, id);
  };

  render() {
    const cycle = this.props.cycles.find((el) => el.id === this.state.cycleId);

    if (!cycle) {
      return null;
    }

    const bugs = this.props.cycleBugs.byCycleId[this.state.cycleId];
    const bugEditingEnabled = [
      TesterStatusInCycle.Approved,
      TesterStatusInCycle.AwaitingReview,
    ].includes(cycle.testerStatus);

    const isMobile = this.props.device === 'mobile' || this.props.device === 'mobile-tablet';

    if( isMobile ) {
      return(
        <div>
          <CycleDetailsSidemenu cycleId={cycle.id} />
          <div className="cycle-bugs" style={{marginLeft: 5}}>
            {cycle.status === CycleStatus.Completed && (
              <Message info>
                <Message.Header>This cycle has finished</Message.Header>
                <Link to="/account/tester">Go to dashboard</Link>
              </Message>
            )}
            <h2>Reported Bugs ({bugs.length})</h2>

            {bugs.length ? (
              <ReportedBugsList
                isMobile
                bugs={bugs}
                cycle={cycle}
                user={this.props.user}
                cycleTesters={[]}
                onBugStatusChange={() => alert('onBugStatusChange')}
                editBugStatus={this.props.editBugStatus}
                onEdit={bugEditingEnabled ? (id) => this.handleEditBug(id) : undefined}
                onDelete={bugEditingEnabled ? (id) => this.handleDeleteBug(id) : undefined}
              />
            ) : (
              <p>You have not found any bugs yet</p>
            )}
          </div>
        </div>      
      );      
    }
    return (
      <div className="account-tester page">
        <div className="heading">
          <h1>Cycle Bugs</h1>
        </div>

        <div className="inner">
          <CycleDetailsSidemenu cycleId={cycle.id} />
          <main className="cycle-bugs">
            {cycle.status === CycleStatus.Completed && (
              <Message info>
                <Message.Header>This cycle has finished</Message.Header>
                <Link to="/account/tester">Go to dashboard</Link>
              </Message>
            )}
            <h2>Reported Bugs ({bugs.length})</h2>

            {bugs.length ? (
              <ReportedBugsList
                bugs={bugs}
                cycle={cycle}
                user={this.props.user}
                cycleTesters={[]}
                onBugStatusChange={() => alert('onBugStatusChange')}
                editBugStatus={this.props.editBugStatus}
                onEdit={bugEditingEnabled ? (id) => this.handleEditBug(id) : undefined}
                onDelete={bugEditingEnabled ? (id) => this.handleDeleteBug(id) : undefined}
              />
            ) : (
              <p>You have not found any bugs yet</p>
            )}
          </main>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Bugs));
