export default (state = null, action) => {
  switch (action.type) {
    case 'SET_RESOLUTION':
      return {
        device: action.payload.device,
        size: action.payload.size,
      };
    default:
      return state;
  }
};
