import React from 'react';
import classNames from 'classnames';
import capitalize from 'lodash/capitalize';
import Chip from '@mui/material/Chip';
import makeStyles from '@mui/styles/makeStyles';
import { yellow, green, grey, red, blueGrey, blue, orange } from '@mui/material/colors';

import { BugSeverity } from '../../../../types/BugSeverity';
import { getBugStatus } from '../../../../services/bugsService';
import { BugStatus } from '../../../../types/BugStatus';

const useStyles = makeStyles(() => ({
    chip: {
      fontSize: 14,
      borderRadius: 6,
      color: '#ffffff',  
    },

    chipOutlined: {
      fontSize: 14,
      borderRadius: 6,
      fontWeight: 'bold',
    },

    n_aSeverity: {
      backgroundColor: grey[500],
    },
    [`${BugSeverity.Low}Severity`]: {      
      backgroundColor: green[600],
    },
    [`${BugSeverity.Medium}Severity`]: {
      backgroundColor: yellow[700],
    },
    [`${BugSeverity.High}Severity`]: {
      backgroundColor: red[500],
    },
    [`${BugSeverity.Critical}Severity`]: {
      backgroundColor: blueGrey[800],
    },    

    
    [`${BugSeverity.Low}SeverityOutlined`]: {
      color: green[600],
      borderColor: green[600],
    },
    [`${BugSeverity.Medium}SeverityOutlined`]: {
      color: yellow[700],
      borderColor: yellow[700],
    },
    [`${BugSeverity.High}SeverityOutlined`]: {
      color: red[500],
      borderColor: red[500],
    },
    [`${BugSeverity.Critical}SeverityOutlined`]: {
      color: blueGrey[800],
      borderColor: blueGrey[800],
    },


    [`${BugStatus.NotABug}Status`]: {
      backgroundColor: yellow[800],
    },
    [`${BugStatus.New}Status`]: {
      backgroundColor: blue[700],
    },
    [`${BugStatus.Fixed}Status`]: {
      backgroundColor: green[800],
    },    
    /* [`${BugStatus.Duplicated}Status`]: {
      backgroundColor: orange[600],
    },
    [`${BugStatus.Deferred}Status`]: {
      backgroundColor: blueGrey[800],
    }, */

    [`${BugStatus.Duplicated}Status`]: {
      backgroundColor: green[600],
    },
    [`${BugStatus.Deferred}Status`]: {
      backgroundColor: green[400],
    },

    [`${BugStatus.NotABug}StatusOutlined`]: {
      color: yellow[800],
      borderColor: yellow[800],
    },
    [`${BugStatus.New}StatusOutlined`]: {
      color: blue[700],
      borderColor: blue[700],
    },
    [`${BugStatus.Fixed}StatusOutlined`]: {
      color: green[800],
      borderColor: green[800],
    },
    /* [`${BugStatus.Duplicated}StatusOutlined`]: {
      color: orange[600],
      borderColor: orange[600],
    },
    [`${BugStatus.Deferred}StatusOutlined`]: {
      color: blueGrey[800],
      borderColor: blueGrey[800],
    }, */

    [`${BugStatus.Duplicated}StatusOutlined`]: {
      color: green[600],
      borderColor: green[600],
    },
    [`${BugStatus.Deferred}StatusOutlined`]: {
      color: green[400],
      borderColor: green[400],
    },
    
}));

export function BugSeverityLabel(props) {
    const {severity} = props;
    const classes = useStyles();
    return (
        <Chip
            label={severity === 'n_a' ? 'N / A' : capitalize(severity as string)}
            className={classNames(classes.chip,classes[`${severity}Severity`])}
        />
    );
};

export function BugSeverityLabelOutlined(props) {
  const {severity} = props;
  const classes = useStyles();
  return (
      <Chip variant="outlined"
          label={severity === 'n_a' ? 'N / A' : capitalize(severity as string)}
          className={classNames(classes.chipOutlined, classes[`${severity}SeverityOutlined`])}
      />
  );
};

export function BugStatusLabel(props) {
    const {status} = props;
    const label = getBugStatus(status);
    const classes = useStyles();
    return (
        <Chip
            label = {capitalize(label) as string}
            className={classNames(classes.chip,classes[`${status}Status`])}
        />
    );
}

export function BugStatusLabelOutlined(props) {
    const {status} = props;
    const label=getBugStatus(status);
    const classes = useStyles();
    return (
        <Chip variant="outlined"
            label = {capitalize(label) as string}
            className={classNames(classes.chipOutlined, classes[`${status}StatusOutlined`])}
        />
    );
}