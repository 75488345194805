import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { BugStatusToggle } from '../../../components/Cycles/Bugs/BugStatusToggle';
import { BugAttachments } from '../../../components/Cycles/Bugs/BugAttachments';
import { Message } from 'semantic-ui-react';
import { UserRole } from '../../../types/UserRole';
import { Paper } from '@mui/material';
import ChatView from '../../../next/chat/ChatView';
import { validateBugChat } from '../../../services/next/chatService';
import styled from 'styled-components';
import { connect } from 'react-redux';
import cycleActions from '../../../redux/actions/cycleActions';

const DetailsContainer = styled.div`
  > h5 {
    font-weight: 700;
  }
  > p {
    white-space: pre-wrap;
    margin-bottom: 1rem;
  }
`;

const _BugDetails = ({ bugId, chatId, step, listCycles, ...props }) => {
  const [actualBugId, setActualBugId] = useState(chatId);
  const hasAttachments = props.imageFilePaths?.length > 0 || props.videoFilePaths?.length > 0;

  const init = async () => {
    const res = await validateBugChat(+bugId);
    if (!chatId) {
      setActualBugId(res?.chat);
    }
  };

  useEffect(() => {
    init();
  }, [bugId, chatId]);

  return (
    <div>
      <Paper
        elevation={0}
        variant="outlined"
        style={{
          width: '100%',
          padding: '5px 15px',
          margin: '5px 0 10px',
        }}
      >
        {step && <p className="created-at">Step ID: {step}</p>}
        <p className="created-at">
          Posted at {moment(props.createdAt).format('HH:MM - DD MMM YYYY')}
        </p>
        {props.userRole === UserRole.Customer && (
          <Message className="tester-device-details" compact positive size="small">
            <p>
              Author:{' '}
              <span className="tester-name">
                {props.testerName} ({props.testerCountry})
              </span>
            </p>
            <p>{props.deviceName}</p>
          </Message>
        )}

        {!!props.onBugStatusChange && props.userRole === UserRole.Customer && (
          <BugStatusToggle
            bugId={bugId}
            bugStatus={props.status}
            editBugStatus={props.editBugStatus}
            onBugStatusChange={props.onBugStatusChange}
          />
        )}
      </Paper>

      <InnerPanel title="Details">
        <DetailsContainer>
          {props.userRole === UserRole.Tester && (
            <>
              <h5>Device</h5>
              <p>{props.deviceName}</p>
            </>
          )}
          <h5>Steps to reproduce</h5>
          <p>{props.stepsToReproduce}</p>
          <h5>Expected result</h5>
          <p>{props.expectedResult}</p>
          <h5>Actual result</h5>
          <p>{props.actualResult}</p>
          <h5>Severity</h5>
          <p>{props.severity}</p>
          <h5>Additional Info</h5>
          <p>{props.comment}</p>
        </DetailsContainer>
      </InnerPanel>

      <InnerPanel title="Attachments">
        {hasAttachments && (
          <BugAttachments
            imageFilePaths={props.imageFilePaths}
            videoFilePaths={props.videoFilePaths}
          />
        )}
      </InnerPanel>

      <InnerPanel title="Comments">
        {actualBugId && <ChatView user={props.ownerId} chat={actualBugId} />}
      </InnerPanel>
    </div>
  );
};

const InnerPanel = ({ title, children }) => {
  return (
    <div>
      <h3>{title}</h3>
      <div>{children}</div>
    </div>
  );
};

export const BugDetails = connect(
  (state) => ({}),
  (dispatch) => ({
    listCycles: () => dispatch(cycleActions.listCycles()),
  })
)(_BugDetails);
