export default [
  {
    key: 'ab',
    value: 'Abkhaz',
    text: 'Abkhaz',
  },
  {
    key: 'aa',
    value: 'Afar',
    text: 'Afar',
  },
  {
    key: 'af',
    value: 'Afrikaans',
    text: 'Afrikaans',
  },
  {
    key: 'ak',
    value: 'Akan',
    text: 'Akan',
  },
  {
    key: 'sq',
    value: 'Albanian',
    text: 'Albanian',
  },
  {
    key: 'am',
    value: 'Amharic',
    text: 'Amharic',
  },
  {
    key: 'ar',
    value: 'Arabic',
    text: 'Arabic',
  },
  {
    key: 'an',
    value: 'Aragonese',
    text: 'Aragonese',
  },
  {
    key: 'hy',
    value: 'Armenian',
    text: 'Armenian',
  },
  {
    key: 'as',
    value: 'Assamese',
    text: 'Assamese',
  },
  {
    key: 'av',
    value: 'Avaric',
    text: 'Avaric',
  },
  {
    key: 'ae',
    value: 'Avestan',
    text: 'Avestan',
  },
  {
    key: 'ay',
    value: 'Aymara',
    text: 'Aymara',
  },
  {
    key: 'az',
    value: 'Azerbaijani',
    text: 'Azerbaijani',
  },
  {
    key: 'bm',
    value: 'Bambara',
    text: 'Bambara',
  },
  {
    key: 'ba',
    value: 'Bashkir',
    text: 'Bashkir',
  },
  {
    key: 'eu',
    value: 'Basque',
    text: 'Basque',
  },
  {
    key: 'be',
    value: 'Belarusian',
    text: 'Belarusian',
  },
  {
    key: 'bn',
    value: 'Bengali',
    text: 'Bengali',
  },
  {
    key: 'bh',
    value: 'Bihari',
    text: 'Bihari',
  },
  {
    key: 'bi',
    value: 'Bislama',
    text: 'Bislama',
  },
  {
    key: 'bs',
    value: 'Bosnian',
    text: 'Bosnian',
  },
  {
    key: 'br',
    value: 'Breton',
    text: 'Breton',
  },
  {
    key: 'bg',
    value: 'Bulgarian',
    text: 'Bulgarian',
  },
  {
    key: 'my',
    value: 'Burmese',
    text: 'Burmese',
  },
  {
    key: 'ca',
    value: 'Catalan; Valencian',
    text: 'Catalan; Valencian',
  },
  {
    key: 'ch',
    value: 'Chamorro',
    text: 'Chamorro',
  },
  {
    key: 'ce',
    value: 'Chechen',
    text: 'Chechen',
  },
  {
    key: 'ny',
    value: 'Chichewa; Chewa; Nyanja',
    text: 'Chichewa; Chewa; Nyanja',
  },
  {
    key: 'zh',
    value: 'Chinese',
    text: 'Chinese',
  },
  {
    key: 'cv',
    value: 'Chuvash',
    text: 'Chuvash',
  },
  {
    key: 'kw',
    value: 'Cornish',
    text: 'Cornish',
  },
  {
    key: 'co',
    value: 'Corsican',
    text: 'Corsican',
  },
  {
    key: 'cr',
    value: 'Cree',
    text: 'Cree',
  },
  {
    key: 'hr',
    value: 'Croatian',
    text: 'Croatian',
  },
  {
    key: 'cs',
    value: 'Czech',
    text: 'Czech',
  },
  {
    key: 'da',
    value: 'Danish',
    text: 'Danish',
  },
  {
    key: 'dv',
    value: 'Divehi; Dhivehi; Maldivian;',
    text: 'Divehi; Dhivehi; Maldivian;',
  },
  {
    key: 'nl',
    value: 'Dutch',
    text: 'Dutch',
  },
  {
    key: 'en',
    value: 'English',
    text: 'English',
  },
  {
    key: 'eo',
    value: 'Esperanto',
    text: 'Esperanto',
  },
  {
    key: 'et',
    value: 'Estonian',
    text: 'Estonian',
  },
  {
    key: 'ee',
    value: 'Ewe',
    text: 'Ewe',
  },
  {
    key: 'fo',
    value: 'Faroese',
    text: 'Faroese',
  },
  {
    key: 'fj',
    value: 'Fijian',
    text: 'Fijian',
  },
  {
    key: 'fi',
    value: 'Finnish',
    text: 'Finnish',
  },
  {
    key: 'fr',
    value: 'French',
    text: 'French',
  },
  {
    key: 'ff',
    value: 'Fula; Fulah; Pulaar; Pular',
    text: 'Fula; Fulah; Pulaar; Pular',
  },
  {
    key: 'gl',
    value: 'Galician',
    text: 'Galician',
  },
  {
    key: 'ka',
    value: 'Georgian',
    text: 'Georgian',
  },
  {
    key: 'de',
    value: 'German',
    text: 'German',
  },
  {
    key: 'el',
    value: 'Greek, Modern',
    text: 'Greek, Modern',
  },
  {
    key: 'gn',
    value: 'Guaraní',
    text: 'Guaraní',
  },
  {
    key: 'gu',
    value: 'Gujarati',
    text: 'Gujarati',
  },
  {
    key: 'ht',
    value: 'Haitian; Haitian Creole',
    text: 'Haitian; Haitian Creole',
  },
  {
    key: 'ha',
    value: 'Hausa',
    text: 'Hausa',
  },
  {
    key: 'he',
    value: 'Hebrew',
    text: 'Hebrew',
  },
  {
    key: 'hz',
    value: 'Herero',
    text: 'Herero',
  },
  {
    key: 'hi',
    value: 'Hindi',
    text: 'Hindi',
  },
  {
    key: 'ho',
    value: 'Hiri Motu',
    text: 'Hiri Motu',
  },
  {
    key: 'hu',
    value: 'Hungarian',
    text: 'Hungarian',
  },
  {
    key: 'ia',
    value: 'Interlingua',
    text: 'Interlingua',
  },
  {
    key: 'id',
    value: 'Indonesian',
    text: 'Indonesian',
  },
  {
    key: 'ie',
    value: 'Interlingue',
    text: 'Interlingue',
  },
  {
    key: 'ga',
    value: 'Irish',
    text: 'Irish',
  },
  {
    key: 'ig',
    value: 'Igbo',
    text: 'Igbo',
  },
  {
    key: 'ik',
    value: 'Inupiaq',
    text: 'Inupiaq',
  },
  {
    key: 'io',
    value: 'Ido',
    text: 'Ido',
  },
  {
    key: 'is',
    value: 'Icelandic',
    text: 'Icelandic',
  },
  {
    key: 'it',
    value: 'Italian',
    text: 'Italian',
  },
  {
    key: 'iu',
    value: 'Inuktitut',
    text: 'Inuktitut',
  },
  {
    key: 'ja',
    value: 'Japanese',
    text: 'Japanese',
  },
  {
    key: 'jv',
    value: 'Javanese',
    text: 'Javanese',
  },
  {
    key: 'kl',
    value: 'Kalaallisut, Greenlandic',
    text: 'Kalaallisut, Greenlandic',
  },
  {
    key: 'kn',
    value: 'Kannada',
    text: 'Kannada',
  },
  {
    key: 'kr',
    value: 'Kanuri',
    text: 'Kanuri',
  },
  {
    key: 'ks',
    value: 'Kashmiri',
    text: 'Kashmiri',
  },
  {
    key: 'kk',
    value: 'Kazakh',
    text: 'Kazakh',
  },
  {
    key: 'km',
    value: 'Khmer',
    text: 'Khmer',
  },
  {
    key: 'ki',
    value: 'Kikuyu, Gikuyu',
    text: 'Kikuyu, Gikuyu',
  },
  {
    key: 'rw',
    value: 'Kinyarwanda',
    text: 'Kinyarwanda',
  },
  {
    key: 'ky',
    value: 'Kirghiz, Kyrgyz',
    text: 'Kirghiz, Kyrgyz',
  },
  {
    key: 'kv',
    value: 'Komi',
    text: 'Komi',
  },
  {
    key: 'kg',
    value: 'Kongo',
    text: 'Kongo',
  },
  {
    key: 'ko',
    value: 'Korean',
    text: 'Korean',
  },
  {
    key: 'ku',
    value: 'Kurdish',
    text: 'Kurdish',
  },
  {
    key: 'kj',
    value: 'Kwanyama, Kuanyama',
    text: 'Kwanyama, Kuanyama',
  },
  {
    key: 'la',
    value: 'Latin',
    text: 'Latin',
  },
  {
    key: 'lb',
    value: 'Luxembourgish, Letzeburgesch',
    text: 'Luxembourgish, Letzeburgesch',
  },
  {
    key: 'lg',
    value: 'Luganda',
    text: 'Luganda',
  },
  {
    key: 'li',
    value: 'Limburgish, Limburgan, Limburger',
    text: 'Limburgish, Limburgan, Limburger',
  },
  {
    key: 'ln',
    value: 'Lingala',
    text: 'Lingala',
  },
  {
    key: 'lo',
    value: 'Lao',
    text: 'Lao',
  },
  {
    key: 'lt',
    value: 'Lithuanian',
    text: 'Lithuanian',
  },
  {
    key: 'lu',
    value: 'Luba-Katanga',
    text: 'Luba-Katanga',
  },
  {
    key: 'lv',
    value: 'Latvian',
    text: 'Latvian',
  },
  {
    key: 'gv',
    value: 'Manx',
    text: 'Manx',
  },
  {
    key: 'mk',
    value: 'Macedonian',
    text: 'Macedonian',
  },
  {
    key: 'mg',
    value: 'Malagasy',
    text: 'Malagasy',
  },
  {
    key: 'ms',
    value: 'Malay',
    text: 'Malay',
  },
  {
    key: 'ml',
    value: 'Malayalam',
    text: 'Malayalam',
  },
  {
    key: 'mt',
    value: 'Maltese',
    text: 'Maltese',
  },
  {
    key: 'mi',
    value: 'Māori',
    text: 'Māori',
  },
  {
    key: 'mr',
    value: 'Marathi (Marāṭhī)',
    text: 'Marathi (Marāṭhī)',
  },
  {
    key: 'mh',
    value: 'Marshallese',
    text: 'Marshallese',
  },
  {
    key: 'mn',
    value: 'Mongolian',
    text: 'Mongolian',
  },
  {
    key: 'na',
    value: 'Nauru',
    text: 'Nauru',
  },
  {
    key: 'nv',
    value: 'Navajo, Navaho',
    text: 'Navajo, Navaho',
  },
  {
    key: 'nb',
    value: 'Norwegian Bokmål',
    text: 'Norwegian Bokmål',
  },
  {
    key: 'nd',
    value: 'North Ndebele',
    text: 'North Ndebele',
  },
  {
    key: 'ne',
    value: 'Nepali',
    text: 'Nepali',
  },
  {
    key: 'ng',
    value: 'Ndonga',
    text: 'Ndonga',
  },
  {
    key: 'nn',
    value: 'Norwegian Nynorsk',
    text: 'Norwegian Nynorsk',
  },
  {
    key: 'no',
    value: 'Norwegian',
    text: 'Norwegian',
  },
  {
    key: 'ii',
    value: 'Nuosu',
    text: 'Nuosu',
  },
  {
    key: 'nr',
    value: 'South Ndebele',
    text: 'South Ndebele',
  },
  {
    key: 'oc',
    value: 'Occitan',
    text: 'Occitan',
  },
  {
    key: 'oj',
    value: 'Ojibwe, Ojibwa',
    text: 'Ojibwe, Ojibwa',
  },
  {
    key: 'cu',
    value: 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
    text: 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
  },
  {
    key: 'om',
    value: 'Oromo',
    text: 'Oromo',
  },
  {
    key: 'or',
    value: 'Oriya',
    text: 'Oriya',
  },
  {
    key: 'os',
    value: 'Ossetian, Ossetic',
    text: 'Ossetian, Ossetic',
  },
  {
    key: 'pa',
    value: 'Panjabi, Punjabi',
    text: 'Panjabi, Punjabi',
  },
  {
    key: 'pi',
    value: 'Pāli',
    text: 'Pāli',
  },
  {
    key: 'fa',
    value: 'Persian',
    text: 'Persian',
  },
  {
    key: 'pl',
    value: 'Polish',
    text: 'Polish',
  },
  {
    key: 'ps',
    value: 'Pashto, Pushto',
    text: 'Pashto, Pushto',
  },
  {
    key: 'pt',
    value: 'Portuguese',
    text: 'Portuguese',
  },
  {
    key: 'qu',
    value: 'Quechua',
    text: 'Quechua',
  },
  {
    key: 'rm',
    value: 'Romansh',
    text: 'Romansh',
  },
  {
    key: 'rn',
    value: 'Kirundi',
    text: 'Kirundi',
  },
  {
    key: 'ro',
    value: 'Romanian, Moldavian, Moldovan',
    text: 'Romanian, Moldavian, Moldovan',
  },
  {
    key: 'ru',
    value: 'Russian',
    text: 'Russian',
  },
  {
    key: 'sa',
    value: 'Sanskrit (Saṁskṛta)',
    text: 'Sanskrit (Saṁskṛta)',
  },
  {
    key: 'sc',
    value: 'Sardinian',
    text: 'Sardinian',
  },
  {
    key: 'sd',
    value: 'Sindhi',
    text: 'Sindhi',
  },
  {
    key: 'se',
    value: 'Northern Sami',
    text: 'Northern Sami',
  },
  {
    key: 'sm',
    value: 'Samoan',
    text: 'Samoan',
  },
  {
    key: 'sg',
    value: 'Sango',
    text: 'Sango',
  },
  {
    key: 'sr',
    value: 'Serbian',
    text: 'Serbian',
  },
  {
    key: 'gd',
    value: 'Scottish Gaelic; Gaelic',
    text: 'Scottish Gaelic; Gaelic',
  },
  {
    key: 'sn',
    value: 'Shona',
    text: 'Shona',
  },
  {
    key: 'si',
    value: 'Sinhala, Sinhalese',
    text: 'Sinhala, Sinhalese',
  },
  {
    key: 'sk',
    value: 'Slovak',
    text: 'Slovak',
  },
  {
    key: 'sl',
    value: 'Slovene',
    text: 'Slovene',
  },
  {
    key: 'so',
    value: 'Somali',
    text: 'Somali',
  },
  {
    key: 'st',
    value: 'Southern Sotho',
    text: 'Southern Sotho',
  },
  {
    key: 'es',
    value: 'Spanish; Castilian',
    text: 'Spanish; Castilian',
  },
  {
    key: 'su',
    value: 'Sundanese',
    text: 'Sundanese',
  },
  {
    key: 'sw',
    value: 'Swahili',
    text: 'Swahili',
  },
  {
    key: 'ss',
    value: 'Swati',
    text: 'Swati',
  },
  {
    key: 'sv',
    value: 'Swedish',
    text: 'Swedish',
  },
  {
    key: 'ta',
    value: 'Tamil',
    text: 'Tamil',
  },
  {
    key: 'te',
    value: 'Telugu',
    text: 'Telugu',
  },
  {
    key: 'tg',
    value: 'Tajik',
    text: 'Tajik',
  },
  {
    key: 'th',
    value: 'Thai',
    text: 'Thai',
  },
  {
    key: 'ti',
    value: 'Tigrinya',
    text: 'Tigrinya',
  },
  {
    key: 'bo',
    value: 'Tibetan Standard, Tibetan, Central',
    text: 'Tibetan Standard, Tibetan, Central',
  },
  {
    key: 'tk',
    value: 'Turkmen',
    text: 'Turkmen',
  },
  {
    key: 'tl',
    value: 'Tagalog',
    text: 'Tagalog',
  },
  {
    key: 'tn',
    value: 'Tswana',
    text: 'Tswana',
  },
  {
    key: 'to',
    value: 'Tonga (Tonga Islands)',
    text: 'Tonga (Tonga Islands)',
  },
  {
    key: 'tr',
    value: 'Turkish',
    text: 'Turkish',
  },
  {
    key: 'ts',
    value: 'Tsonga',
    text: 'Tsonga',
  },
  {
    key: 'tt',
    value: 'Tatar',
    text: 'Tatar',
  },
  {
    key: 'tw',
    value: 'Twi',
    text: 'Twi',
  },
  {
    key: 'ty',
    value: 'Tahitian',
    text: 'Tahitian',
  },
  {
    key: 'ug',
    value: 'Uighur, Uyghur',
    text: 'Uighur, Uyghur',
  },
  {
    key: 'uk',
    value: 'Ukrainian',
    text: 'Ukrainian',
  },
  {
    key: 'ur',
    value: 'Urdu',
    text: 'Urdu',
  },
  {
    key: 'uz',
    value: 'Uzbek',
    text: 'Uzbek',
  },
  {
    key: 've',
    value: 'Venda',
    text: 'Venda',
  },
  {
    key: 'vi',
    value: 'Vietnamese',
    text: 'Vietnamese',
  },
  {
    key: 'vo',
    value: 'Volapük',
    text: 'Volapük',
  },
  {
    key: 'wa',
    value: 'Walloon',
    text: 'Walloon',
  },
  {
    key: 'cy',
    value: 'Welsh',
    text: 'Welsh',
  },
  {
    key: 'wo',
    value: 'Wolof',
    text: 'Wolof',
  },
  {
    key: 'fy',
    value: 'Western Frisian',
    text: 'Western Frisian',
  },
  {
    key: 'xh',
    value: 'Xhosa',
    text: 'Xhosa',
  },
  {
    key: 'yi',
    value: 'Yiddish',
    text: 'Yiddish',
  },
  {
    key: 'yo',
    value: 'Yoruba',
    text: 'Yoruba',
  },
  {
    key: 'za',
    value: 'Zhuang, Chuang',
    text: 'Zhuang, Chuang',
  },
];
